import React, { useState, useEffect } from "react";
import { FormControl } from "@mui/material";
import { Tabs, Tab, Button, Form, Row } from "react-bootstrap";
import AdminDashboard from "./AdminDashboard";
import axios from "axios";
import ExamVerificationtable from "./Exam_Verification_table";

function Exam_Verification() {
  const [courseData, setCourseData] = useState({});
  const [selectedType, setSelectedType] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [loading, setLoading] = useState(false);
  const [subbranches, setSubbranches] = useState([]);
  const [selectedSubbranch, setSelectedSubbranch] = useState("");
  const session = new Date().getFullYear();
  const [selectedSemester, setSelectedSemester] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [key, setKey] = useState("first");
  const [sess, setSess] = useState([]);
  const [selectedSession, setSelectedSession] = useState("");
  const [tableMode, setTableMode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedExamType, setSelectedExamType] = useState("");

  useEffect(() => {
    const fetchSess = async () => {
      try {
        const response = await axios.get(
          "https://sssutms.ac.in/apitest/v1/admin/all-exam-sessions"
        );
        const sessionData = response.data.Sessions || [];
        setSess(sessionData);
      } catch (error) {
        console.error("Error fetching sess:", error);
      }
    };
    fetchSess();
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://sssutms.ac.in/apitest/v2/admin/courses?session=${session}`
        );
        const data = await response.json();
        const formattedData = {};

        data.forEach((course) => {
          if (!formattedData[course.courseType]) {
            formattedData[course.courseType] = {
              courses: [],
              branches: {},
            };
          }

          course.courseNames.forEach((courseName) => {
            formattedData[course.courseType].courses.push(
              courseName.courseName
            );
            formattedData[course.courseType].branches[courseName.courseName] =
              courseName.branches.map((branch) => branch.branchName);
          });
        });

        setCourseData(formattedData);
      } catch (error) {
        console.error("Error fetching course data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, [session]);

  useEffect(() => {
    const fetchSubbranches = async () => {
      if (
        selectedType &&
        selectedCourse &&
        selectedBranch &&
        selectedSemester
      ) {
        try {
          const response = await fetch(
            `https://sssutms.ac.in/apitest/admin/search-semester-scheme?courseType=${selectedType}&courseName=${selectedCourse}&courseBranch=${selectedBranch}&semesterNumber=${selectedSemester}`
          );
          const data = await response.json();

          const options = data.subBranch.map((item) => item.SubBranch);
          setSubbranches(options);
        } catch (error) {
          console.error("Error fetching subbranch data:", error);
        }
      }
    };

    fetchSubbranches();
  }, [selectedType, selectedCourse, selectedBranch, selectedSemester]);

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    setSelectedCourse("");
    setSelectedBranch("");
    setErrorMessage("");
  };

  const handleCourseChange = (e) => {
    setSelectedCourse(e.target.value);
    setErrorMessage("");
  };

  const handleBranchChange = (e) => {
    setSelectedBranch(e.target.value);
    setErrorMessage("");
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setErrorMessage("");
  };

  const handleExamTypeChange = (e) => {
    setSelectedExamType(e.target.value);
    setErrorMessage("");
  };

  const validateFields = () => {
    if (
      !selectedType &&
      !selectedCourse &&
      !selectedBranch &&
      !selectedSemester &&
      !selectedSession &&
      !selectedCategory &&
      !selectedExamType
    ) {
      return "Please select options from the dropdown.";
    }

    if (!selectedType) return "Please select a Course Type.";
    if (!selectedCourse) return "Please select a Course.";
    if (!selectedBranch) return "Please select a Branch.";
    if (!selectedSemester) return "Please select a Semester.";
    if (!selectedSession) return "Please select an Exam Session.";
    if (!selectedCategory) return "Please select a Category.";
    if (!selectedExamType) return "Please select an Exam Type.";
    return "";
  };

  const handleSearch = () => {
    const error = validateFields();
    if (error) {
      setErrorMessage(error);
      return;
    }
    setTableMode("forwarded");
    setShowTable(true);
    setErrorMessage("");
  };

  const handleVerifiedSearch = () => {
    const error = validateFields();
    if (error) {
      setErrorMessage(error);
      return;
    }
    setTableMode("verified");
    setShowTable(true);
    setErrorMessage("");
  };

  return (
    <>
      <AdminDashboard />
      <style>
        {`
          * {
            font-family: 'Poppins', Sans-serif;
            font-size: 14px;
          }
          .shadow-box {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
            border-radius: 10px;
            padding: 8px;
            margin-top: 5px;
            width: 100%;
            max-width: 350px;
            margin-left: 75px;
            min-height: fit-content;
            overflow-y: visible;
          }
          .custom-tabs .nav-item {
            height: 25px;
          }
          .custom-tabs .nav-link {
            padding: 0px;
            font-size: 13px;
          }
          .custom-tab {
            padding: 5px 8px;
            font-size: 13px;
          }
          #admission-tabs .nav-link {
            padding: 5px 8px;
            font-size: 13px;
          }
          .header {
            height: 35px;
            background-color: #001d3d;
            border-radius: 10px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-family: italic;
          }
          .login-input {
            margin-bottom: 5px;
            height: 30px;
          }
          .login-input:focus {
            outline: none;
            border-bottom: 2px solid #344e41;
          }
          .error-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
          }
          .error-box {
            background: white;
            padding: 15px;
            border-radius: 10px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
            text-align: center;
            max-width: 350px;
            width: 90%;
          }
          .error-message {
            color: red;
            font-size: 15px;
            margin-bottom: 10px;
          }
          .error-close-btn {
            background-color: #dc3545;
            color: white;
            border: none;
            padding: 6px 15px;
            border-radius: 5px;
            cursor: pointer;
            font-size: 13px;
          }
          @media (max-width: 768px) {
            .shadow-box {
              padding: 8px;
              margin-top: 10px;
              width: 90%;
              margin-left: auto;
              margin-right: auto;
            }
            .header {
              font-size: 16px;
              height: 30px;
            }
            .login-input {
              font-size: 0.85rem;
              height: 28px;
            }
          }
          @media (max-width: 576px) {
            .shadow-box {
              margin: 10px auto;
              width: 95%;
            }
            .container-fluid {
              width: 100%;
              padding-right: 0;
              padding-left: 0;
            }
          }
          @media (min-width: 992px) {
            .custom-laptop-style {
              margin-left: 5%;
            }
          }
        `}
      </style>

      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <div
          style={{
            width: "20%",
            minWidth: "250px",
            padding: "10px",
            marginTop: "60px",
          }}
        >
          <div className="shadow-box">
            <h6
              style={{
                fontSize: "14px",
                fontWeight: 700,
                color: "#A02334",
                marginBottom: "6px",
                fontFamily: "'Rubik', sans-serif",
              }}
            >
              Exam Form Verification
            </h6>
            <Tabs
              id="admission-tabs"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="first" tabClassName="custom-tab">
                <form style={{ padding: "5px" }}>
                  <Row>
                    <FormControl>
                      <label>Course Type</label>
                      <Form.Select
                        value={selectedType}
                        onChange={handleTypeChange}
                        style={{ height: "30px", fontSize: "13px" }}
                      >
                        <option value="">Select Type</option>
                        {Object.keys(courseData).map((type) => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </Form.Select>
                    </FormControl>
                  </Row>

                  <Row>
                    <FormControl>
                      <label>Course</label>
                      <Form.Select
                        value={selectedCourse}
                        onChange={handleCourseChange}
                        style={{ height: "30px", fontSize: "13px" }}
                        disabled={!selectedType}
                      >
                        <option value="">Select Course</option>
                        {selectedType &&
                          courseData[selectedType].courses
                            .filter(
                              (course) =>
                                course !==
                                "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)"
                            )
                            .map((course) => (
                              <option key={course} value={course}>
                                {course}
                              </option>
                            ))}
                      </Form.Select>
                    </FormControl>
                  </Row>

                  <Row>
                    <FormControl>
                      <label>Branch</label>
                      <Form.Select
                        value={selectedBranch}
                        onChange={handleBranchChange}
                        style={{ height: "30px", fontSize: "13px" }}
                        disabled={!selectedCourse}
                      >
                        <option value="">Select Branch</option>
                        {selectedCourse &&
                          courseData[selectedType].branches[selectedCourse].map(
                            (branch) => (
                              <option key={branch} value={branch}>
                                {branch}
                              </option>
                            )
                          )}
                      </Form.Select>
                    </FormControl>
                  </Row>

                  <Row>
                    <FormControl>
                      <label>Semester</label>
                      <Form.Select
                        value={selectedSemester}
                        onChange={(e) => setSelectedSemester(e.target.value)}
                        style={{ height: "30px", fontSize: "13px" }}
                        disabled={!selectedBranch}
                      >
                        <option value="">Select Semester</option>
                        {["1", "2", "3", "4", "5", "6", "7", "8"].map(
                          (sem, index) => (
                            <option key={index} value={sem}>
                              {sem} Semester
                            </option>
                          )
                        )}
                      </Form.Select>
                    </FormControl>
                  </Row>

                  <Row>
                    <FormControl>
                      <label>Sub Branch</label>
                      <Form.Select
                        value={selectedSubbranch}
                        onChange={(e) => setSelectedSubbranch(e.target.value)}
                        style={{ height: "30px", fontSize: "13px" }}
                        disabled={!subbranches.length}
                      >
                        <option value="">Select Sub Branch</option>
                        {subbranches.map((subbranch, index) => (
                          <option key={index} value={subbranch}>
                            {subbranch}
                          </option>
                        ))}
                      </Form.Select>
                    </FormControl>
                  </Row>

                  <Row>
                    <FormControl>
                      <label>Exam Session</label>
                      <Form.Select
                        value={selectedSession}
                        onChange={(e) => setSelectedSession(e.target.value)}
                        style={{ height: "30px", fontSize: "13px" }}
                      >
                        <option value="">Select Session</option>
                        {sess.map((session) => (
                          <option key={session._id} value={session.session}>
                            {session.session}
                          </option>
                        ))}
                      </Form.Select>
                    </FormControl>
                  </Row>

                  <Row>
                    <FormControl>
                      <label>Category</label>
                      <select
                        className="form-select form-select-sm login-input"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Regular">Regular</option>
                      </select>
                    </FormControl>
                  </Row>

                  <Row>
                    <FormControl>
                      <label>Exam Type</label>
                      <select
                        className="form-select form-select-sm login-input"
                        value={selectedExamType}
                        onChange={handleExamTypeChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Main">Main Exam</option>
                      </select>
                    </FormControl>
                  </Row>

                  <Row style={{ marginTop: "8px", gap: "8px" }}>
                    <Button
                      type="button"
                      style={{
                        backgroundColor: "#004e92",
                        width: "120px",
                        height: "28px",
                        padding: "2px",
                        fontSize: "13px",
                      }}
                      onClick={handleSearch}
                    >
                      View Records
                    </Button>
                    <Button
                      type="button"
                      style={{
                        backgroundColor: "#28a745",
                        width: "120px",
                        height: "28px",
                        padding: "2px",
                        fontSize: "13px",
                      }}
                      onClick={handleVerifiedSearch}
                    >
                      Verified
                    </Button>
                  </Row>
                </form>
              </Tab>
            </Tabs>
          </div>
        </div>

        <div style={{ flex: 1, padding: "10px" }}>
          {showTable && (
            <ExamVerificationtable
              courseType={selectedType}
              courseBranch={selectedBranch}
              courseName={selectedCourse}
              semester={selectedSemester}
              SubBranch={selectedSubbranch}
              mode={tableMode}
              category={selectedCategory}
              examType={selectedExamType}
              session={selectedSession}
            />
          )}
        </div>

        {errorMessage && (
          <div className="error-overlay">
            <div className="error-box">
              <div className="error-message">{errorMessage}</div>
              <button
                className="error-close-btn"
                onClick={() => setErrorMessage("")}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Exam_Verification;