import React, { useEffect, useState } from "react";
import {
  TableCell,
  Box,
  CircularProgress,
  Checkbox,
  Select,
  MenuItem,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Table,
  Paper,
  CardContent
} from "@mui/material";
import axios from "axios";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";

const BranchMapping = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const [sessions, setSessions] = useState([]);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        setLoading(true);
        const url = "https://sssutms.ac.in/apitest/v1/getallsession";
        const response = await axios.get(url);
        const data = response.data;

        if (data.data && data.data.length > 0) {
          setSessions(data.data);
          setDataNotFound(false);
        } else {
          setDataNotFound(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setDataNotFound(true);
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <AdminDashboard />
      <Box sx={{ width: "93%", marginLeft: "90px", marginTop: "80px" }}>
        {loading ? (
          <CircularProgress color="inherit" style={{ marginLeft: "45%" }} />
        ) : (
          <CardContent>
            {dataNotFound ? (
              <div>
                <h2>Oops! No Sessions Available!</h2>
              </div>
            ) : (
              <Paper sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: "440px" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#001d3d",
                            color: "white",
                            position: "sticky",
                            left: "0",
                            top: 0,
                            zIndex: 2,
                          }}
                        >
                          S.No.
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#001d3d",
                            color: "white",
                            minWidth: "50px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          Select
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#001d3d",
                            color: "white",
                            minWidth: "50px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          Sem
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#001d3d",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          Session
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#001d3d",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          Session Name
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#001d3d",
                            color: "white",
                            minWidth: "100px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          Annual / Semester
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sessions.map((session, index) => (
                        <TableRow key={index}>
                          <TableCell
                            align="left"
                            style={{
                              position: "sticky",
                              top: 0,
                              left: 0,
                              backgroundColor: "white",
                              zIndex: 1,
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell align="left">
                            <Checkbox />
                          </TableCell>
                          <TableCell align="left">
                            <Select
                              displayEmpty
                              style={{ width: "100px", height: "40px" }}
                            >
                              <MenuItem value="-Select-">
                                <em>Select Sem</em>
                              </MenuItem>
                              <MenuItem value="1">1</MenuItem>
                              <MenuItem value="2">2</MenuItem>
                              <MenuItem value="3">3</MenuItem>
                              <MenuItem value="4">4</MenuItem>
                              <MenuItem value="5">5</MenuItem>
                              <MenuItem value="6">6</MenuItem>
                              <MenuItem value="7">7</MenuItem>
                              <MenuItem value="8">8</MenuItem>
                            </Select>
                          </TableCell>
                          <TableCell align="left">{session.session}</TableCell>
                          <TableCell align="left">
                            {session.sessionName}
                          </TableCell>
                          <TableCell align="left">
                            {session.IsAnnual}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={sessions.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            )}
          </CardContent>
        )}
        <br />
      </Box>
    </>
  );
};

export default BranchMapping;
