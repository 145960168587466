
import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { TableCell, Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CardContent from "@mui/material/CardContent";
import SearchIcon from "@mui/icons-material/Search";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";
import * as XLSX from "xlsx";



function TotalEnrolledAdmin() {
  const [studentdata, setStudentData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(process.env.REACT_APP_STD_ENROLLEDLIST);
        const data = await response.json();
        if (Array.isArray(data)) {
          setStudentData(data);
          setFilteredData(data);
        } else {
          console.error("Fetched data is not an array:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    filterData();
  }, [searchQuery, studentdata]);

  const filterData = () => {
    if (!Array.isArray(studentdata)) {
      console.error("studentdata is not an array:", studentdata);
      setFilteredData([]);
      return;
    }
    const filtered = studentdata.filter(
      (student) =>
        student.randomId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const getSortedAndSlicedData = () => {
    if (!Array.isArray(filteredData)) {
      console.error("filteredData is not an array:", filteredData);
      return [];
    }
    const sortedData = [...filteredData].sort((a, b) => {
      if (sortBy === "name") {
        return sortOrder === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      }
      return 0;
    });

    const startIndex = page * rowsPerPage;
    return sortedData.slice(startIndex, startIndex + rowsPerPage);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (column) => {
    if (column === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const ExportToExcel = () => {
    if (Array.isArray(studentdata)) {
      const Excel = studentdata.map((student) => ({
        Admission_Session: student.admissionSession,
        Enrollment_No: student.enrollmentNumber || "Not Generated",
        Enrollement_Status: student.isEnrolled,
        Random_Id: student.randomId,
        Random_Password: student.randomPassword,
        Admitted_Date: new Date(student.createdAt).toLocaleDateString("en-IN", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
        Name: student.name,
        Fathers_Name: student.fathersname,
        Mothers_Name: student.mothersname,
        Email: student.email,
        Mobile: student.mobile,
        Course_Type: student.courseType,
        Course: student.courseName,
        Branch: student.courseBranch,
        StudentType: student.StudentType || "Normal",
      }));

      const worksheet = XLSX.utils.json_to_sheet(Excel);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Enrolled Student");
      XLSX.writeFile(workbook, "Enrolled_Std_Data.xlsx");
    } else {
      console.error("Student data is not an array. Cannot export to Excel.");
    }
  };

  return (
    <>
      <>
        <AdminDashboard />

        <Box
          sx={{
            width: "auto",
            marginLeft: "5%",
            marginTop: "75px",
            height: "auto",
          }}
        >
          {loading ? (
            <CircularProgress
              color="success"
              style={{ marginTop: "80px", marginLeft: "50%" }}
            />
          ) : (
            <CardContent>
              <Paper sx={{ width: "100%", overflow: "auto" }}>
                <Button
                  style={{ float: "right", marginRight: "10px" }}
                  variant="outlined"
                  onClick={ExportToExcel}
                >
                  Export to Excel
                </Button>
                <Box sx={{ p: 2 }}>
                  <SearchIcon sx={{ mr: 1 }} />
                  <input
                    type="text"
                    placeholder="Search  by ID or Name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Box>
                <TableContainer sx={{ maxHeight: "400px" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: "2px",
                            left: 0,
                            color: "white",
                          }}
                        >
                          S.No.
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            color: "white",
                            zIndex: 2,
                            left: 50,
                            minWidth: "50px",
                          }}
                        >
                          Enrollment Status
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                            minWidth: "50px",
                          }}
                        >
                          Registration ID
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            minWidth: "100px",
                            color: "white",
                          }}
                        >
                          Registration Password
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                            minWidth: "200px",
                            position: "sticky",
                            top: 0,
                            left: "180px",
                            zIndex: 2,
                            color: "white",
                          }}
                          // /////////////for sorting name//////////////////
                          onClick={() => handleSort("name")}
                        >
                          Candidate Name
                          {/* /////////////name sort////////////////////////// */}
                          {sortBy === "name" && (
                            <span>{sortOrder === "asc" ? " ↑" : " ↓"}</span>
                          )}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            minWidth: "170px",
                            color: "white",
                          }}
                        >
                          Father's Name
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            minWidth: "170px",
                            color: "white",
                          }}
                        >
                          Mother's Name
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Email
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            minWidth: "130px",
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Contact
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Course Type
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            minWidth: "250px",
                            color: "white",
                          }}
                        >
                          Course Name
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            minWidth: "250px",
                            color: "white",
                          }}
                        >
                          Branch
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getSortedAndSlicedData().map((student, index) => (
                        <TableRow key={index}>
                          <TableCell
                            align="center"
                            style={{
                              position: "sticky",
                              left: 0, // Ensure this matches the left position of other sticky columns
                              top: 0,
                              zIndex: 1,
                              backgroundColor: "white", // Ensure background color to avoid overlap issues
                              // Adjust width as needed
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          {/* <TableCell align="center">
                          {student?.IsEnrollGenerated ? <Button variant="contained" color="success"> Generated</Button>:<Button variant="contained" color="error" >Not generated</Button> }
                          
                        </TableCell> */}

                          <TableCell
                            align="left"
                            style={{
                              position: "sticky",
                              left: 50, // Ensure this matches the left position of other sticky columns
                              top: 0,
                              zIndex: 1,
                              backgroundColor: "white", // Ensure background color to avoid overlap issues
                              color: "#e85d04",
                              fontWeight: "bold",
                              minWidth: "200px", // Adjust width as needed
                            }}
                          >
                            {student?.enrollmentNumber ? (
                              <span
                                style={{
                                  color: "green",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                }}
                              >
                                {student?.enrollmentNumber}
                              </span>
                            ) : (
                              <span
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                }}
                              >
                                Not generated
                              </span>
                            )}
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ fontWeight: 700, color: "#780000" }}
                          >
                            {student?.randomId}
                          </TableCell>
                          <TableCell align="left">
                            {student?.randomPassword}
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{
                              position: "sticky",
                              left: 180, // Adjust as needed
                              top: 0,
                              fontSize: "15px",
                              zIndex: 1,
                              backgroundColor: "white", // Ensure background color to avoid overlap issues
                              // color: '#e85d04',
                              fontWeight: "bold",
                              minWidth: "200px", // Adjust width as needed
                            }}
                          >
                            {student?.name}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              minWidth: "200px", // Adjust width as needed
                            }}
                          >
                            {student?.fathersname}
                          </TableCell>
                          <TableCell align="left">
                            {student?.mothersname}
                          </TableCell>
                          <TableCell align="left">{student?.email}</TableCell>
                          <TableCell align="left">{student?.mobile}</TableCell>
                          <TableCell
                            style={{
                              fontWeight: 700,
                              color:
                                student.courseType === "UG"
                                  ? "blue"
                                  : student.courseType === "PG"
                                    ? "#8d0801"
                                    : student.courseType === "Diploma"
                                      ? "purple"
                                      : student.courseType === "PG_Diploma"
                                        ? "green" // different color for pg_diploma
                                        : "black", // default color if none of the values match
                            }}
                          >
                            {student?.courseType}
                          </TableCell>

                          <TableCell align="center" style={{ fontWeight: 700 }}>
                            {student?.courseName}
                          </TableCell>

                          <TableCell align="center" style={{ fontWeight: 700 }}>
                            {student?.courseBranch}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 100, 500, 1000]}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </CardContent>
          )}
          <br></br>
        </Box>
      </>
    </>
  );
}

export default TotalEnrolledAdmin;