// import React, { useState, useEffect } from "react";
// import { FormControl } from "@mui/material";
// import { Tabs, Tab, Button, Form, Row, Spinner } from "react-bootstrap";
// import axios from "axios";
// import swal from "sweetalert";
// import ExamFormCheckStatus from "../../../../Exam/HOD_EXAM/ExamStatusTabel";
// import AdminDashboard from "../AdminDashboard";

// const Admin_ExamformStatusSearch = () => {
//   const [courseData, setCourseData] = useState({});
//   const [selectedType, setSelectedType] = useState("");
//   const [selectedCourse, setSelectedCourse] = useState("");
//   const [selectedBranch, setSelectedBranch] = useState("");
//   const [loading, setLoading] = useState(false); // Loader state

//   const [subbranches, setSubbranches] = useState([]);
//   const [selectedSubbranch, setSelectedSubbranch] = useState("");
//   const session = new Date().getFullYear();
//   const [selectedSemester, setSelectedSemester] = useState("");
//   const [showTable, setShowTable] = useState(false);
//   const [key, setKey] = useState("first");
//   const [sess, setSess] = useState([]);
//   const [selectedSession, setSelectedSession] = useState("");

//   const userData = JSON.parse(sessionStorage.getItem("AnkitHOD"));
//   const department = userData.department;

//   useEffect(() => {
//     const fetchSess = async () => {
//       try {
//         const response = await axios.get(
//           "https://sssutms.ac.in/apitest/v1/admin/all-exam-sessions"
//         );
//         const sessionData = response.data.Sessions || [];
//         setSess(sessionData); // Assuming `setSess` updates the session state
//       } catch (error) {
//         console.error("Error fetching sess:", error);
//       }
//     };
//     fetchSess();
//   }, []);
//   useEffect(() => {
//     const fetchCourses = async () => {
//       setLoading(true);
//       try {
//         const response = await fetch(
//           `https://sssutms.ac.in/apitest/v2/admin/courses?session=${session}`
//         );
//         const data = await response.json();
//         const formattedData = {};

//         data.forEach((course) => {
//           if (!formattedData[course.courseType]) {
//             formattedData[course.courseType] = {
//               courses: [],
//               branches: {},
//             };
//           }

//           course.courseNames.forEach((courseName) => {
//             formattedData[course.courseType].courses.push(
//               courseName.courseName
//             );
//             formattedData[course.courseType].branches[courseName.courseName] =
//               courseName.branches.map((branch) => branch.branchName);
//           });
//         });

//         setCourseData(formattedData);
//       } catch (error) {
//         console.error("Error fetching course data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchCourses();
//   }, [session]);


//   useEffect(() => {
//     const fetchSubbranches = async () => {
//       if (
//         selectedType &&
//         selectedCourse &&
//         selectedBranch &&
//         selectedSemester
//       ) {
//         try {
//           const response = await fetch(
//             `https://sssutms.ac.in/apitest/admin/search-semester-scheme?courseType=${selectedType}&courseName=${selectedCourse}&courseBranch=${selectedBranch}&semesterNumber=${selectedSemester}`
//           );
//           const data = await response.json();

//           const options = data.subBranch.map((item) => item.SubBranch);
//           setSubbranches(options);
//         } catch (error) {
//           console.error("Error fetching subbranch data:", error);
//         }
//       }
//     };

//     fetchSubbranches();
//   }, [
//     selectedType,
//     selectedCourse,
//     selectedBranch,
//     selectedSemester,
//     selectedSubbranch,
//   ]);

//   const handleTypeChange = (e) => {
//     setSelectedType(e.target.value);
//     setSelectedCourse("");
//     setSelectedBranch("");
//   };
//   // const handleTypeChange = (e) => {
//   //   const type = e.target.value;
//   //   setSelectedType(type);

//   //   if (type && courseData[type]) {
//   //     const availableCourses = courseData[type].courses;
//   //     const firstCourse = availableCourses.length === 1 ? availableCourses[0] : "";
//   //     setSelectedCourse(firstCourse);

//   //     if (firstCourse) {
//   //       const availableBranches = courseData[type].branches[firstCourse];
//   //       const firstBranch = availableBranches.length === 1 ? availableBranches[0] : "";
//   //       setSelectedBranch(firstBranch);

//   //       if (firstBranch) {
//   //         setSelectedSemester("1"); // Default semester
//   //       }
//   //     }
//   //   } else {
//   //     setSelectedCourse("");
//   //     setSelectedBranch("");
//   //     setSelectedSemester("");
//   //   }
//   // };

//   const handleCourseChange = (e) => {
//     setSelectedCourse(e.target.value);
//   };

//   const handleBranchChange = (e) => {
//     setSelectedBranch(e.target.value);
//   };

//   const handleSearch = () => {
//     if (
//       !selectedType ||
//       !selectedCourse ||
//       !selectedBranch ||
//       !selectedSemester ||
//       !selectedSubbranch ||
//       !selectedSession
//     ) {
//       swal({
//         icon: "warning",
//         title: "Error! Form Forwarding",
//         text: "Please fill all fields first.",
//         buttons: "OK",
//       });
//       return;
//     }

//     setShowTable(true); // Show the table only if all fields are filled
//   };

//   return (
//     <>
//     <AdminDashboard/>

//       <style>
//         {`

//          *{
//           font-family:'Poppins',Sans-serif;
//           font-size:15px;
//           }
//           .shadow-box {
//             box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
//             border-radius: 10px;
//             padding: 10px;
//             margin-top: 5px;
//             width: 110%;
//             margin-left:75px;
//             height:550px;
//           }


      
        
//           .custom-tabs .nav-item {
//             height: 30px;
//           }
//           .custom-tabs .nav-link {
//             padding: 0px;
//             font-size: 14px;
//           }
//           .custom-tab {
//             padding: 5px 10px;
//             font-size: 14px;
//           }
//           #admission-tabs .nav-link {
//             padding: 5px 10px;
//             font-size: 14px;
//           }
//           .header {
//             height: 40px;
//             background-color: #001d3d;
//             border-radius: 10px;
//             color: white;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             font-size: 20px;
//             font-family: italic;
//           }
//           .login-input {
//             margin-bottom:8px;
//           }
//           .login-input:focus {
//             outline: none;
//             border-bottom: 2px solid #344e41;
//           }
//           @media (max-width: 768px) {
//             .shadow-box {
//               padding: 10px;
//               margin-top: 10px;
//             }
//             .header {
//               font-size: 16px;
//               height: 40px;
//             }
//             .login-input {
//               font-size: 0.9rem;
//             }
//           }
//           @media (max-width: 576px) {
//             .shadow-box {
//               margin: 10px;
//               margin-Left:76px;
//             }
//             .container-fluid {
//               width: 100%;
//               padding-right: 0;
//               padding-left: 0;
//             }
//           }
//           @media (min-width: 992px) {
//             .custom-laptop-style {
//               margin-left: 5%;
//             }
//           }
//         `}
//       </style>

//       <div style={{ display: "flex" }}>
//         <div
//           style={{
//             width: "20%",
//             height: "450px",
//             padding: "10px",
//             marginTop: "60px",
//           }}
//         >
//           <div className="shadow-box">
//             <h6
//               style={{
//                 fontSize: "15px",
//                 fontWeight: 700,
//                 color: "#A02334",
//                 marginBottom: "8px",
//                 fontFamily: "'Rubik', sans-serif",
//               }}
//             >
//               Exam Form Status
//             </h6>
//             {loading ? ( // Conditionally render the loader
//               <div style={{ textAlign: "center", marginTop: "20px" }}>
//                 <Spinner animation="border" variant="primary" />
//                 <p>Loading...</p>
//               </div>
//             ) : (
//               <Tabs
//                 id="admission-tabs"
//                 activeKey={key}
//                 onSelect={(k) => setKey(k)}
//               >
//                 <Tab eventKey="first" tabClassName="custom-tab">
//                   <form style={{ padding: "10px" }}>
//                     <Row>
//                       <FormControl>
//                         <label>Course Type</label>
//                         <Form.Select
//                           value={selectedType}
//                           onChange={handleTypeChange}
//                           style={{ height: "35px", fontSize: "14px" }}
//                         >
//                           <option value="">Select Type</option>
//                           {Object.keys(courseData).map((type) => (
//                             <option
//                               className="option_keys"
//                               key={type}
//                               value={type}
//                             >
//                               {type}
//                             </option>
//                           ))}
//                         </Form.Select>
//                       </FormControl>
//                     </Row>

//                     <Row>
//                       <FormControl>
//                         <label>Course</label>
//                         <Form.Select
//                           value={selectedCourse}
//                           onChange={handleCourseChange}
//                           style={{ height: "35px", fontSize: "14px" }}
//                           disabled={!selectedType}
//                         >
//                           <option value="">Select Course</option>
//                           {selectedType &&
//                             courseData[selectedType].courses
//                               .filter(
//                                 (course) =>
//                                   course !==
//                                   "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)"
//                               ) // You can adjust this filter as needed
//                               .map((course) => (
//                                 <option
//                                   className="option_keys"
//                                   key={course}
//                                   value={course}
//                                 >
//                                   {course}
//                                 </option>
//                               ))}
//                         </Form.Select>
//                       </FormControl>
//                     </Row>

//                     <Row>
//                       <FormControl>
//                         <label>Branch</label>
//                         <Form.Select
//                           value={selectedBranch}
//                           onChange={handleBranchChange}
//                           style={{ height: "35px", fontSize: "14px" }}
//                           disabled={!selectedCourse}
//                         >
//                           <option value="">Select Branch</option>
//                           {selectedCourse &&
//                             courseData[selectedType].branches[
//                               selectedCourse
//                             ].map((branch) => (
//                               <option
//                                 className="option_keys"
//                                 key={branch}
//                                 value={branch}
//                               >
//                                 {branch}
//                               </option>
//                             ))}
//                         </Form.Select>
//                       </FormControl>
//                     </Row>
//                     <Row>
//                       <FormControl>
//                         <label>Semester</label>
//                         <Form.Select
//                           value={selectedSemester}
//                           onChange={(e) => setSelectedSemester(e.target.value)}
//                           disabled={!selectedBranch}
//                         >
//                           <option value="">Select Semester</option>
//                           {["1", "2", "3", "4", "5", "6", "7", "8"].map(
//                             (sem, index) => (
//                               <option
//                                 className="option_keys"
//                                 key={index}
//                                 value={sem}
//                               >
//                                 {sem} Semester
//                               </option>
//                             )
//                           )}
//                         </Form.Select>
//                       </FormControl>
//                     </Row>

//                     <Row>
//                       <FormControl>
//                         <label>Sub Branch</label>
//                         <Form.Select
//                           value={selectedSubbranch}
//                           onChange={(e) => setSelectedSubbranch(e.target.value)}
//                           disabled={!subbranches.length}
//                         >
//                           <option value="">Select Sub Branch</option>
//                           {subbranches.map((subbranch, index) => (
//                             <option
//                               className="option_keys"
//                               key={index}
//                               value={subbranch}
//                             >
//                               {subbranch}
//                             </option>
//                           ))}
//                         </Form.Select>
//                       </FormControl>
//                     </Row>

//                     <Row>
//                       <FormControl>
//                         <label>Exam Session</label>
//                         <Form.Select
//                           value={selectedSession}
//                           onChange={(e) => setSelectedSession(e.target.value)}
//                           style={{ height: "35px", fontSize: "14px" }}
//                         >
//                           <option value="">Select Session</option>
//                           {sess.map((session) => (
//                             <option
//                               className="option_keys"
//                               key={session._id}
//                               value={session.session}
//                             >
//                               {session.session}
//                             </option>
//                           ))}
//                         </Form.Select>
//                       </FormControl>
//                     </Row>

//                     <Row>
//                       <FormControl>
//                         <label> Category</label>
//                         <select
//                           className="form-select form-select-sm login-input"
//                           required
//                         >
//                           <option
//                             style={{
//                               fontFamily: "'Rubik', sans-serif",
//                               fontWeight: 700,
//                             }}
//                             value=""
//                           >
//                             Select
//                           </option>
//                           <option
//                             style={{
//                               fontFamily: "'Rubik', sans-serif",
//                               fontWeight: 700,
//                             }}
//                             value="2023"
//                           >
//                             Regular
//                           </option>
//                         </select>
//                       </FormControl>
//                     </Row>

//                     <Row>
//                       <FormControl>
//                         <label> Exam Type</label>
//                         <select
//                           className="form-select form-select-sm login-input"
//                           required
//                         >
//                           <option
//                             style={{
//                               fontFamily: "'Rubik', sans-serif",
//                               fontWeight: 700,
//                             }}
//                             value=""
//                           >
//                             Select
//                           </option>
//                           <option
//                             style={{
//                               fontFamily: "'Rubik', sans-serif",
//                               fontWeight: 700,
//                             }}
//                             value="2023"
//                           >
//                             Main Exam
//                           </option>
//                         </select>
//                       </FormControl>
//                     </Row>

//                     <Row>
//                       <Button
//                         type="button"
//                         style={{
//                           marginLeft: "50px",
//                           backgroundColor: "#004e92",
//                           width: "150px",
//                           height: "30px",
//                           padding: "2px",
//                         }}
//                         onClick={handleSearch}
//                       >
//                         View Records
//                       </Button>
//                     </Row>
//                   </form>
//                 </Tab>
//               </Tabs>
//             )}
//           </div>
//         </div>

//         <div>
//           {showTable > 0 && (
//             <div>
//               <ExamFormCheckStatus
//                 courseType={selectedType}
//                 courseBranch={selectedBranch}
//                 courseName={selectedCourse}
//                 semester={selectedSemester}
//                 SubBranch={subbranches}
//               />
//             </div>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default Admin_ExamformStatusSearch;


import React, { useState, useEffect } from "react";
import { FormControl } from "@mui/material";
import { Tabs, Tab, Button, Form, Row, Spinner } from "react-bootstrap";
import axios from "axios";
import swal from "sweetalert";
import ExamFormCheckStatus from "../../../../Exam/HOD_EXAM/ExamStatusTabel";
import AdminDashboard from "../AdminDashboard";
import ExamSidebarnavbar from "../../../../Exam_controller/Exam_Sidebar";

const Admin_ExamformStatusSearch = () => {
  const [courseData, setCourseData] = useState({});
  const [selectedType, setSelectedType] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [loading, setLoading] = useState(false); // Loader state

  const [subbranches, setSubbranches] = useState([]);
  const [selectedSubbranch, setSelectedSubbranch] = useState("");
  const session = new Date().getFullYear();
  const [selectedSemester, setSelectedSemester] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [key, setKey] = useState("first");
  const [sess, setSess] = useState([]);
  const [selectedSession, setSelectedSession] = useState("");

  const userData = JSON.parse(sessionStorage.getItem("AnkitHOD"));


  useEffect(() => {
    const fetchSess = async () => {
      try {
        const response = await axios.get(
          "https://sssutms.ac.in/apitest/v1/admin/all-exam-sessions"
        );
        const sessionData = response.data.Sessions || [];
        setSess(sessionData); // Assuming `setSess` updates the session state
      } catch (error) {
        console.error("Error fetching sess:", error);
      }
    };
    fetchSess();
  }, []);
  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://sssutms.ac.in/apitest/v2/admin/courses?session=${session}`
        );
        const data = await response.json();
        const formattedData = {};

        data.forEach((course) => {
          if (!formattedData[course.courseType]) {
            formattedData[course.courseType] = {
              courses: [],
              branches: {},
            };
          }

          course.courseNames.forEach((courseName) => {
            formattedData[course.courseType].courses.push(
              courseName.courseName
            );
            formattedData[course.courseType].branches[courseName.courseName] =
              courseName.branches.map((branch) => branch.branchName);
          });
        });

        setCourseData(formattedData);
      } catch (error) {
        console.error("Error fetching course data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, [session]);


  useEffect(() => {
    const fetchSubbranches = async () => {
      if (
        selectedType &&
        selectedCourse &&
        selectedBranch &&
        selectedSemester
      ) {
        try {
          const response = await fetch(
            `https://sssutms.ac.in/apitest/admin/search-semester-scheme?courseType=${selectedType}&courseName=${selectedCourse}&courseBranch=${selectedBranch}&semesterNumber=${selectedSemester}`
          );
          const data = await response.json();

          const options = data.subBranch.map((item) => item.SubBranch);
          setSubbranches(options);
        } catch (error) {
          console.error("Error fetching subbranch data:", error);
        }
      }
    };

    fetchSubbranches();
  }, [
    selectedType,
    selectedCourse,
    selectedBranch,
    selectedSemester,
    selectedSubbranch,
  ]);

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    setSelectedCourse("");
    setSelectedBranch("");
  }

  const handleCourseChange = (e) => {
    setSelectedCourse(e.target.value);
  };

  const handleBranchChange = (e) => {
    setSelectedBranch(e.target.value);
  };

  const handleSearch = () => {
    if (
      !selectedType ||
      !selectedCourse ||
      !selectedBranch ||
      !selectedSemester ||
      !selectedSubbranch ||
      !selectedSession
    ) {
      swal({
        icon: "warning",
        title: "Error! Form Forwarding",
        text: "Please fill all fields first.",
        buttons: "OK",
      });
      return;
    }
    setShowTable(true);
  };

  return (
    <>
    
      {userData?.role === "admin" ? <AdminDashboard /> : <ExamSidebarnavbar />}

      <style>
        {`

         *{
          font-family:'Poppins',Sans-serif;
          font-size:15px;
          }
          .shadow-box {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
            border-radius: 10px;
            padding: 10px;
            margin-top: 5px;
            width: 110%;
            margin-left:75px;
            height:550px;
          }


      
        
          .custom-tabs .nav-item {
            height: 30px;
          }
          .custom-tabs .nav-link {
            padding: 0px;
            font-size: 14px;
          }
          .custom-tab {
            padding: 5px 10px;
            font-size: 14px;
          }
          #admission-tabs .nav-link {
            padding: 5px 10px;
            font-size: 14px;
          }
          .header {
            height: 40px;
            background-color: #001d3d;
            border-radius: 10px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-family: italic;
          }
          .login-input {
            margin-bottom:8px;
          }
          .login-input:focus {
            outline: none;
            border-bottom: 2px solid #344e41;
          }
          @media (max-width: 768px) {
            .shadow-box {
              padding: 10px;
              margin-top: 10px;
            }
            .header {
              font-size: 16px;
              height: 40px;
            }
            .login-input {
              font-size: 0.9rem;
            }
          }
          @media (max-width: 576px) {
            .shadow-box {
              margin: 10px;
              margin-Left:76px;
            }
            .container-fluid {
              width: 100%;
              padding-right: 0;
              padding-left: 0;
            }
          }
          @media (min-width: 992px) {
            .custom-laptop-style {
              margin-left: 5%;
            }
          }
        `}
      </style>

      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "20%",
            height: "450px",
            padding: "10px",
            marginTop: "60px",
          }}
        >
          <div className="shadow-box">
            <h6
              style={{
                fontSize: "15px",
                fontWeight: 700,
                color: "#A02334",
                marginBottom: "8px",
                fontFamily: "'Rubik', sans-serif",
              }}
            >
              Exam Form Status
            </h6>
            {loading ? ( // Conditionally render the loader
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Spinner animation="border" variant="primary" />
                <p>Loading...</p>
              </div>
            ) : (
              <Tabs
                id="admission-tabs"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="first" tabClassName="custom-tab">
                  <form style={{ padding: "10px" }}>
                    <Row>
                      <FormControl>
                        <label>Course Type</label>
                        <Form.Select
                          value={selectedType}
                          onChange={handleTypeChange}
                          style={{ height: "35px", fontSize: "14px" }}
                        >
                          <option value="">Select Type</option>
                          {Object.keys(courseData).map((type) => (
                            <option
                              className="option_keys"
                              key={type}
                              value={type}
                            >
                              {type}
                            </option>
                          ))}
                        </Form.Select>
                      </FormControl>
                    </Row>

                    <Row>
                      <FormControl>
                        <label>Course</label>
                        <Form.Select
                          value={selectedCourse}
                          onChange={handleCourseChange}
                          style={{ height: "35px", fontSize: "14px" }}
                          disabled={!selectedType}
                        >
                          <option value="">Select Course</option>
                          {selectedType &&
                            courseData[selectedType].courses
                              .filter(
                                (course) =>
                                  course !==
                                  "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)"
                              ) // You can adjust this filter as needed
                              .map((course) => (
                                <option
                                  className="option_keys"
                                  key={course}
                                  value={course}
                                >
                                  {course}
                                </option>
                              ))}
                        </Form.Select>
                      </FormControl>
                    </Row>

                    <Row>
                      <FormControl>
                        <label>Branch</label>
                        <Form.Select
                          value={selectedBranch}
                          onChange={handleBranchChange}
                          style={{ height: "35px", fontSize: "14px" }}
                          disabled={!selectedCourse}
                        >
                          <option value="">Select Branch</option>
                          {selectedCourse &&
                            courseData[selectedType].branches[
                              selectedCourse
                            ].map((branch) => (
                              <option
                                className="option_keys"
                                key={branch}
                                value={branch}
                              >
                                {branch}
                              </option>
                            ))}
                        </Form.Select>
                      </FormControl>
                    </Row>
                    <Row>
                      <FormControl>
                        <label>Semester</label>
                        <Form.Select
                          value={selectedSemester}
                          onChange={(e) => setSelectedSemester(e.target.value)}
                          disabled={!selectedBranch}
                        >
                          <option value="">Select Semester</option>
                          {["1", "2", "3", "4", "5", "6", "7", "8"].map(
                            (sem, index) => (
                              <option
                                className="option_keys"
                                key={index}
                                value={sem}
                              >
                                {sem} Semester
                              </option>
                            )
                          )}
                        </Form.Select>
                      </FormControl>
                    </Row>

                    <Row>
                      <FormControl>
                        <label>Sub Branch</label>
                        <Form.Select
                          value={selectedSubbranch}
                          onChange={(e) => setSelectedSubbranch(e.target.value)}
                          disabled={!subbranches.length}
                        >
                          <option value="">Select Sub Branch</option>
                          {subbranches.map((subbranch, index) => (
                            <option
                              className="option_keys"
                              key={index}
                              value={subbranch}
                            >
                              {subbranch}
                            </option>
                          ))}
                        </Form.Select>
                      </FormControl>
                    </Row>

                    <Row>
                      <FormControl>
                        <label>Exam Session</label>
                        <Form.Select
                          value={selectedSession}
                          onChange={(e) => setSelectedSession(e.target.value)}
                          style={{ height: "35px", fontSize: "14px" }}
                        >
                          <option value="">Select Session</option>
                          {sess.map((session) => (
                            <option
                              className="option_keys"
                              key={session._id}
                              value={session.session}
                            >
                              {session.session}
                            </option>
                          ))}
                        </Form.Select>
                      </FormControl>
                    </Row>

                    <Row>
                      <FormControl>
                        <label> Category</label>
                        <select
                          className="form-select form-select-sm login-input"
                          required
                        >
                          <option
                            style={{
                              fontFamily: "'Rubik', sans-serif",
                              fontWeight: 700,
                            }}
                            value=""
                          >
                            Select
                          </option>
                          <option
                            style={{
                              fontFamily: "'Rubik', sans-serif",
                              fontWeight: 700,
                            }}
                            value="2023"
                          >
                            Regular
                          </option>
                        </select>
                      </FormControl>
                    </Row>

                    <Row>
                      <FormControl>
                        <label> Exam Type</label>
                        <select
                          className="form-select form-select-sm login-input"
                          required
                        >
                          <option
                            style={{
                              fontFamily: "'Rubik', sans-serif",
                              fontWeight: 700,
                            }}
                            value=""
                          >
                            Select
                          </option>
                          <option
                            style={{
                              fontFamily: "'Rubik', sans-serif",
                              fontWeight: 700,
                            }}
                            value="2023"
                          >
                            Main Exam
                          </option>
                        </select>
                      </FormControl>
                    </Row>

                    <Row>
                      <Button
                        type="button"
                        style={{
                          marginLeft: "50px",
                          backgroundColor: "#004e92",
                          width: "150px",
                          height: "30px",
                          padding: "2px",
                        }}
                        onClick={handleSearch}
                      >
                        View Records
                      </Button>
                    </Row>
                  </form>
                </Tab>
              </Tabs>
            )}
          </div>
        </div>

        <div>
          {showTable > 0 && (
            <div>
              <ExamFormCheckStatus
                courseType={selectedType}
                courseBranch={selectedBranch}
                courseName={selectedCourse}
                semester={selectedSemester}
                SubBranch={subbranches}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Admin_ExamformStatusSearch;
