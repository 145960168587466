import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import {
  Box,
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import sssutms from "../../Main_Admin/images/sssutms.jpg";
import StudentDashboard from "../../AdminComponent/StudentDashboard";
import jsPDF from "jspdf";
import "jspdf-autotable";

const AdmitCardContent = ({
  loading,
  userData,
  getSortedAndSlicedData,
  handleShow,
}) => {
  const isSmallScreen = useMediaQuery("(max-width: 768px)"); // Hook at top level

  return (
    <>
      <style>
        {`
    *{
font-family:'Poppins',sans-serif;
}`}
      </style>
      <Box
        sx={{
          width: "90%",
          marginLeft: { xs: "0", sm: "80px" },
          marginTop: "50px",
          background: "linear-gradient(135deg, #f0f4f8 0%, #e1e8ed 100%)",
          padding: "20px",
          borderRadius: "15px",
          boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
          fontFamily: "'Poppins', sans-serif",
          color: "#333",
          boxSizing: "border-box",
        }}
      >
        {loading ? (
          <CircularProgress
            color="success"
            sx={{ marginTop: "80px", marginLeft: "50%", display: "block" }}
          />
        ) : (
          <CardContent
            sx={{
              background: "rgba(255, 255, 255, 0.95)",
              borderRadius: "10px",
              padding: "15px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                fontSize: "1.6rem",
                color: "#0d47a1",
                fontWeight: 600,
                mb: 2,
              }}
            >
              ADMIT CARD
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                padding: "12px",
                background: "rgba(0, 0, 0, 0.05)",
                borderRadius: "8px",
                marginBottom: "20px",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "center", sm: "flex-start" },
                gap: { xs: "12px", sm: "0" },
                boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.05)",
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: "0.95rem", color: "#274c77" }}
              >
                <strong style={{ fontWeight: 600 }}>Enrollment No:</strong>{" "}
                {userData?.enrollmentNumber}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "0.95rem", color: "#274c77" }}
              >
                <strong style={{ fontWeight: 600 }}>Name:</strong>{" "}
                {userData?.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "0.95rem", color: "#274c77" }}
              >
                <strong style={{ fontWeight: 600 }}>Father:</strong>{" "}
                {userData?.fathersname}
              </Typography>
            </Box>

            {isSmallScreen ? (
              // Improved vertical layout for small screens
              <Box sx={{ maxHeight: 450, overflowY: "auto", px: 1 }}>
                {getSortedAndSlicedData().map((form, index) => (
                  <Box
                    key={index}
                    sx={{
                      background: "#fff",
                      borderRadius: "12px",
                      padding: "15px",
                      marginBottom: "15px",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
                      borderLeft: "4px solid #0d47a1",
                      transition: "transform 0.2s ease",
                      "&:hover": {
                        transform: "translateY(-2px)",
                        backgroundColor: "rgba(0, 121, 107, 0.05)",
                        boxShadow: "0 6px 16px rgba(0, 0, 0, 0.1)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontWeight: 600,
                          color: "#0d47a1",
                        }}
                      >
                        #{index + 1}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.9rem",
                          color: form.isAdmitCardAppraoved
                            ? "#2ecc71"
                            : "#e74c3c",
                          fontWeight: 500,
                        }}
                      >
                        {form.isAdmitCardAppraoved
                          ? "Approved"
                          : "Not Approved"}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "grid", gap: "8px" }}>
                      <Typography sx={{ fontSize: "0.9rem" }}>
                        <strong style={{ color: "#274c77" }}>Session:</strong>{" "}
                        <span style={{ color: "red", fontWeight: 600 }}>
                          {form.examFormId.session || "N/A"}
                        </span>
                      </Typography>
                      <Typography sx={{ fontSize: "0.9rem" }}>
                        <strong style={{ color: "#274c77" }}>
                          Session Name:
                        </strong>{" "}
                        {form.examFormId.sessionName || "N/A"}
                      </Typography>
                      <Typography sx={{ fontSize: "0.9rem" }}>
                        <strong style={{ color: "#274c77" }}>
                          Semester/Year:
                        </strong>{" "}
                        {form.examFormId.semester || "N/A"}
                      </Typography>
                      <Typography sx={{ fontSize: "0.9rem" }}>
                        <strong style={{ color: "#274c77" }}>Category:</strong>{" "}
                        {form.examFormId.category || "N/A"}
                      </Typography>
                    </Box>
                    <Button
                      variant="outlined"
                      sx={{
                        mt: "12px",
                        padding: "6px 16px",
                        border: "2px solid #0d47a1",
                        color: "#0d47a1",
                        borderRadius: "20px",
                        fontSize: "0.85rem",
                        fontWeight: 500,
                        textTransform: "none",
                        background: form.isAdmitCardAppraoved
                          ? "rgba(13, 71, 161, 0.05)"
                          : "none",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          background: "#0d47a1",
                          color: "#fff",
                          boxShadow: "0 0 12px rgba(0, 121, 107, 0.4)",
                          border: "2px solid #0d47a1",
                        },
                      }}
                      onClick={() => {
                        if (!form.isAdmitCardAppraoved) {
                          swal({
                            icon: "warning",
                            title: "Admit card not approved",
                            text: "Your admit card has not been approved yet. Please contact to the Department.",
                            buttons: "OK",
                          });
                        } else if (form.isAdmitCardAppraoved) {
                          handleShow(form.examFormId, form.studentId);
                        }
                      }}
                    >
                      {form.isFilled && form.isPaid ? "Download" : "Download"}
                    </Button>
                  </Box>
                ))}
              </Box>
            ) : (
              // Horizontal table layout for larger screens
              <TableContainer
                component={Paper}
                sx={{ maxHeight: 450, overflowX: "auto", borderRadius: "10px" }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {[
                        {
                          label: "S.No.",
                          minWidth: "auto",
                          left: 0,
                          zIndex: 2,
                        },
                        {
                          label: "Session",
                          minWidth: "100px",
                          left: 50,
                          zIndex: 2,
                        },
                        { label: "Session Name", minWidth: "auto" },
                        { label: "Semester/Year", minWidth: "70px" },
                        { label: "Category", minWidth: "100px" },
                        { label: "Admit Card Status", minWidth: "200px" },
                        { label: "Click to Show", minWidth: "150px" },
                      ].map((col, idx) => (
                        <TableCell
                          key={idx}
                          align="left"
                          sx={{
                            backgroundColor: "#0d47a1",
                            color: "#fff",
                            position: "sticky",
                            top: 0,
                            left: col.left || "auto",
                            zIndex: col.zIndex || 1,
                            fontWeight: 600,
                            padding: "12px",
                            minWidth: col.minWidth,
                          }}
                        >
                          {col.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getSortedAndSlicedData().map((form, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(0, 121, 107, 0.1)",
                          },
                        }}
                      >
                        <TableCell
                          align="left"
                          sx={{
                            position: "sticky",
                            top: 0,
                            left: 0,
                            zIndex: 1,
                            backgroundColor: "white",
                            padding: "10px",
                            fontSize: "0.9rem",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            position: "sticky",
                            left: 50,
                            top: 0,
                            fontWeight: 700,
                            zIndex: 1,
                            backgroundColor: "white",
                            color: "red",
                            padding: "10px",
                            fontSize: "0.9rem",
                          }}
                        >
                          {form.examFormId.session || "N/A"}
                        </TableCell>
                        <TableCell sx={{ padding: "10px", fontSize: "0.9rem" }}>
                          {form.examFormId.sessionName || "N/A"}
                        </TableCell>
                        <TableCell sx={{ padding: "10px", fontSize: "0.9rem" }}>
                          {form.examFormId.semester || "N/A"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            position: "sticky",
                            left: 210,
                            backgroundColor: "white",
                            padding: "10px",
                            fontSize: "0.9rem",
                          }}
                        >
                          {form.examFormId.category || "N/A"}
                        </TableCell>
                        <TableCell sx={{ padding: "10px", fontSize: "0.9rem" }}>
                          {form.isAdmitCardAppraoved
                            ? "Approved"
                            : "Not Approved"}
                        </TableCell>
                        <TableCell sx={{ padding: "10px" }}>
                          <Button
                            variant="outlined"
                            sx={{
                              padding: "6px 12px",
                              border: "2px solid #0d47a1",
                              color: "#0d47a1",
                              borderRadius: "10px",
                              fontSize: "0.85rem",
                              background: "none",
                              transition: "all 0.3s ease",
                              "&:hover": {
                                background: "#0d47a1",
                                color: "#fff",
                                boxShadow: "0 0 15px rgba(0, 121, 107, 0.5)",
                                border: "2px solid #0d47a1",
                              },
                            }}
                            onClick={() => {
                              if (!form.isAdmitCardAppraoved) {
                                swal({
                                  icon: "warning",
                                  title: "Admit card not approved",
                                  text: "Your admit card has not been approved yet. Please contact to the Department.",
                                  buttons: "OK",
                                });
                              } else if (form.isAdmitCardAppraoved) {
                                handleShow(form.examFormId, form.studentId);
                              }
                            }}
                          >
                            {form.isFilled && form.isPaid
                              ? "Download"
                              : "Download"}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </CardContent>
        )}
        <br />
      </Box>
    </>
  );
};

function Std_AdmitCard() {
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [examForms, setExamForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSubjects, setSelectedSubjects] = useState({
    theory: null,
    practical: null,
  });
  const [compulsorySubjects, setCompulsorySubjects] = useState([]);
  const [centerdetail, setCenterDetails] = useState(null);
  const userData = JSON.parse(sessionStorage.getItem("currentUser"));

  useEffect(() => {
    const checkedCompulsorySubjects = compulsorySubjects
      .filter((subject) => subject.isChecked)
      .map((subject) => ({
        subjectName: subject.subjectName,
        subjectType: subject.subjectType,
        subjectCode: subject.subjectCode,
      }));
    setSelectedSubjects((prev) => ({
      ...prev,
      compulsory: checkedCompulsorySubjects,
    }));
  }, [compulsorySubjects]);

  useEffect(() => {
    const fetchExamForms = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://sssutms.ac.in/apitest/v1/getExamForms?studentId=${userData._id}`
        );
        const result = await response.json();
        if (response.ok) {
          setExamForms(result.data);
        } else {
          console.error(result.message);
        }
      } catch (error) {
        console.error("Error fetching exam forms:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchExamForms();
  }, [userData._id]);

  const getSortedAndSlicedData = () => {
    const sortedData = [...examForms].sort((a, b) => {
      if (sortBy === "name") {
        return sortOrder === "asc"
          ? a.branchName.localeCompare(b.branchName)
          : b.branchName.localeCompare(a.branchName);
      }
      return 0;
    });
    const startIndex = page * rowsPerPage;
    return sortedData.slice(startIndex, startIndex + rowsPerPage);
  };

  const handleShow = async (examId) => {
    console.log(examId, "examID");
    const fetchExamCenter = async () => {
      try {
        const response = await fetch(
          `https://sssutms.ac.in/apitest/admin/get-exam-centers?courseBranch=${userData.courseBranch}&courseName=${userData.courseName}&semester=${userData.currentSemester}&Exam_Session=${examId.sessionName}`
        );
        const result = await response.json();
        console.log(result.centers[0], "dekh result");
        if (response.ok) {
          setCenterDetails(result.centers[0]);
          console.log(result.centers[0], "center");
          return result;
        } else {
          console.error(result.message);
        }
      } catch (error) {
        console.error("Error fetching exam forms:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchExamCenter().then((result) => {
      console.log(result.centers[0], "center ki detail");
      generatePDF(examForms[0], result.centers[0]);
    });
  };

  const generatePDF = async (examData, centerdetail) => {
    const pdf = new jsPDF();
    console.log(centerdetail, "center detail inside pdf");

    pdf.setDrawColor(0);
    pdf.rect(5, 5, 200, 287);

    pdf.addImage(sssutms, "JPEG", 8, 10, 20, 20);
    pdf.setFont("helvetica", "sans-serif");
    pdf.setFontSize(12);
    pdf.text(
      "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
      32,
      15
    );

    pdf.setFont("helvetica", "serif");
    pdf.setFontSize(11);
    pdf.text(userData.courseName.toUpperCase(), 71, 22);
    pdf.text(`(${userData.courseBranch}) EXAM FORM: (${examData.examFormId.sessionName})`, 60, 28);

    pdf.setFontSize(13);
    pdf.setFont("helvetica", "Georgia");
    pdf.text("ADMIT CARD", 85, 37);

    const admitCardX = 80;
    const admitCardY = 38;
    const admitCardWidth = 38;
    pdf.line(admitCardX, admitCardY, admitCardX + admitCardWidth, admitCardY);

    pdf.line(5, 45, 205, 45);

    const details = [
      { label: "Enrollment No", value: userData.enrollmentNumber },
      { label: "Candidate Name", value: userData.name },
      { label: "Father's Name", value: userData.fathersname },
      { label: "Mother's Name", value: userData.mothersname },
      { label: "Semester/Year", value: examData.examFormId.semester },
      { label: "Status", value: examData.examFormId.category },
      { label: "Center Name", value: centerdetail?.CenterName },
      { label: "Center Code", value: centerdetail?.centerCode },
    ];

    const boxStartX = 10;
    const boxStartY = 50;
    const columnWidth = 90;
    const rowHeight = 9;

    let yPos = boxStartY + 10;
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(8.5);

    details.forEach((detail, index) => {
      const columnX = index < 4 ? boxStartX + 5 : boxStartX + columnWidth + 10;
      const rowIndex = index % 4;
      const yPos = boxStartY + 10 + rowIndex * rowHeight;
      pdf.text(`${detail.label}: ${detail.value}`, columnX, yPos);
    });

    const photoX = boxStartX + 165;
    const photoY = boxStartY + 5;
    pdf.addImage(
      userData.Documents.applicantPhoto,
      "JPEG",
      photoX,
      photoY,
      27,
      35
    );
    pdf.addImage(
      userData.Documents.applicantSignature,
      "JPEG",
      photoX,
      photoY + 36,
      27,
      10
    );

    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(12);
    pdf.text(
      "Name of the Papers in Which Candidate is Appearing in the Examination",
      25,
      115
    );

    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(11);
    pdf.text(
      `Exam Time: ${centerdetail?.StartTime}- ${centerdetail?.EndTime}`,
      62,
      122
    );

    const allowedCourses = [
      {
        courseName: "BACHELOR OF PHYSICAL EDUCATION(B. P. Ed.)",
        courseBranch: "BACHELOR OF PHYSICAL EDUCATION",
      },
    ];

    const isAllowed = allowedCourses.some(
      (course) =>
        course.courseName === userData.courseName &&
        course.courseBranch === userData.courseBranch
    );

    const subjects = examData.subjects.map((subject, index) => {
      let modifiedCode = subject.subjectCode;
      if (!isAllowed) {
        modifiedCode = modifiedCode.replace(/\s*\[T\]|\s*\[P\]/g, "");
      }
      return [
        `${index + 1}`,
        modifiedCode,
        subject.subjectType,
        subject.subjectName,
      ];
    });

    pdf.autoTable({
      head: [["S.No", "Subject Code", "Subject Type", "Subject Name"]],
      body: subjects,
      startY: 130,
      styles: { fontSize: 10, textColor: 0 },
      headStyles: { fillColor: [230, 230, 230] },
      margin: { left: 15 },
      theme: "grid",
    });

    const footerY = pdf.autoTable.previous.finalY + 11;
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(10);
    pdf.text("Instructions:", 15, footerY);
    const instructions = [
      "1. Bring your examination admit card and original college ID card to the examination hall.",
      "2. Arrive at least 30 minutes before the start of the examination.",
      "3. Follow all instructions given by the invigilators during the examination.",
    ];
    instructions.forEach((instruction, i) => {
      pdf.text(instruction, 15, footerY + (i + 1) * 5);
    });

    const examControllerSignatureImage =
      "https://cdn.sssutms.ac.in/media/static/Exam_Controller_Signature.jpeg";
    pdf.addImage(
      examControllerSignatureImage,
      "JPEG",
      150,
      footerY + 16,
      40,
      10
    );
    pdf.text("Exam Controller", 160, footerY + 30);

    pdf.save(`Admit_Card_${userData.name}.pdf`);
  };

  return (
    <>
      <StudentDashboard />
      <AdmitCardContent
        loading={loading}
        userData={userData}
        getSortedAndSlicedData={getSortedAndSlicedData}
        handleShow={handleShow}
      />
    </>
  );
}

export default Std_AdmitCard;
