import { Button } from "@mui/material";
import React, { useState } from "react";

function Mathametics_Day() {
  const [text,setText] = useState("")
 
  // function pp(){
  // alert("heloo ")
  // }

  return (
    <div>
      
      <Button varient="success" onClick={()=>setText("Hello World")}>Hello</Button>
      <p>{text}</p>
    </div>
  );
}

export default Mathametics_Day;
