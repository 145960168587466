import React, { useState } from 'react';
import { FaHome, FaBook, FaCalendar,FaMoneyCheckAlt, FaUser, FaBars, FaTimes, FaBell, FaSignOutAlt, FaSearch } from 'react-icons/fa';
import posImage from "./12.png";
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const StudentDashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const navigate= useNavigate()

    const handleClick = () => {
      if (userData.courseName) {
        if (userData.isEnrolled === false) {
          swal({
            icon: "warning",
            title: "Enrollement form",
            text: "Please fill enrollement form first",
            buttons: {
              cancel: "Cancel",
              confirm: "OK",
            },
          }).then((value) => {
            if (value) {
              navigate("/student/Enrollement/Form");
            }
          });
        } else if (
          userData.isEnrolled === true &&
          userData.isEnrollPaid === false
        ) {
          swal({
            icon: "warning",
            title: "Enrollement form",
            text: "You haven't paid enrollment Fee yet please by click on OK ",
            buttons: {
              cancel: "Cancel",
              confirm: "OK",
            },
          }).then((value) => {
            if (value) {
              navigate("/Epravesh/Student/EnrollementFee");
            }
          });
        } else if (
          userData.isEnrolled === true &&
          userData.isEnrollPaid === true
        ) {
          swal({
            icon: "success",
            title: "Enrollment form",
            text: "Your have already filled Enrollment Form",
            buttons: {
              cancel: "Cancel",
              confirm: "OK",
            },
          });
        } else {
          swal({
            icon: "success",
            title: "Enrollment form",
            text: "Your have already filled Enrollment Form",
            buttons: {
              cancel: "Cancel",
              confirm: "OK",
            },
          });
        }
      }
    };

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const userData = JSON.parse(sessionStorage.getItem("currentUser") || '{}');
  const sidebarItems = [
    { icon: <FaHome />, name: 'Home', path: '/studentalldetail' },
    { icon: <FaBook />, name: 'Enrollment' , path: '/student/Enrollement/Form' , 
      // onClick: handleClick()
    }, 
    { icon: <FaCalendar />, name: 'Examination Form', path: '/erp/student/studentexamform' },
    { icon: <FaUser />, name: 'Admit Card', path: '/erp/student/admit-card' },
    { icon: <FaMoneyCheckAlt />, name: 'Payment Verification', path: '/student/slip' },
  ];

  return (
    <>
      <style>
        {`
          :root {
            --primary: #1565c0;    /* Navbar color */
            --sidebar-bg: #0D47A1;  /* Sidebar background */
            --accent: #48bb78;     /* Highlight color */
            --text: white;
            --hover: rgba(255, 255, 255, 0.1);
          }

          * {
            font-family: 'Poppins', sans-serif;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }

          .top-navbar {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            height: 65px;
            background: var(--primary);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
            z-index: 1000;
          }

          .navbar-brand {
            display: flex;
            align-items: center;
            gap: 12px;
          }

          .navbar-logo {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            object-fit: cover;
            border: 2px solid var(--text);
            transition: transform 0.3s ease;
          }

          .navbar-brand h5 {
            color: var(--text);
            font-size: 1.2rem;
            font-weight: 600;
            max-width: 800px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .sidebar-toggle {
            background: none;
            border: none;
            color: var(--text);
            font-size: 1.6rem;
            cursor: pointer;
            padding: 8px;
            transition: all 0.3s ease;
          }

          .sidebar-toggle:hover {
            background: var(--hover);
            border-radius: 50%;
          }

          .navbar-actions {
            display: flex;
            align-items: center;
            gap: 15px;
          }

          .search-bar {
            display: flex;
            align-items: center;
            background: rgba(255, 255, 255, 0.15);
            border-radius: 20px;
            padding: 6px 10px;
          }

          .search-icon {
            color: var(--text);
            font-size: 1.3rem;
            margin-right: 8px;
          }

          .search-bar input {
            border: none;
            background: transparent;
            color: var(--text);
            width: 140px;
            font-size: 0.9rem;
            outline: none;
          }

          .search-bar input::placeholder {
            color: rgba(255, 255, 255, 0.7);
          }

          .notification-btn {
            background: none;
            border: none;
            color: var(--text);
            font-size: 1.4rem;
            padding: 8px;
            cursor: pointer;
            position: relative;
          }

          .notification-btn:hover {
            background: var(--hover);
            border-radius: 50%;
          }

          .notification-badge {
            position: absolute;
            top: 4px;
            right: 4px;
            background: #f56565;
            color: var(--text);
            border-radius: 50%;
            width: 16px;
            height: 16px;
            font-size: 0.7rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .user-profile img {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            border: 2px solid var(--text);
            cursor: pointer;
            transition: all 0.3s ease;
          }

          .user-profile img:hover {
            border-color: var(--accent);
            transform: scale(1.05);
          }

          /* Sidebar */
          .sidebar {
            width: 70px;
            height: calc(100vh - 65px);
            background: var(--sidebar-bg);
            position: fixed;
            top: 65px;
            left: 0;
            transition: width 0.3s ease, transform 0.3s ease; /* Added transform for sliding */
            padding: 15px 0;
            z-index: 999;
            display: flex;
            flex-direction: column;
            overflow: hidden;
          }

          .sidebar.open {
            width: 220px;
          }

          .sidebar-item {
            display: flex;
            align-items: center;
            padding: 15px 20px;
            color: var(--text);
            text-decoration: none;
            transition: all 0.3s ease;
            width: 100%;
            cursor: pointer;
          }

          .sidebar-item:hover {
            background: var(--hover);
            color: var(--accent);
          }

          .sidebar-item.active {
            background: var(--hover);
            color: var(--accent);
            border-left: 3px solid var(--accent);
          }

          .sidebar-item .icon {
            font-size: 1.5rem;
            min-width: 30px;
            text-align: center;
          }

          .sidebar-item .item-text {
            font-size: 1rem;
            margin-left: 15px;
            opacity: 0;
            white-space: nowrap;
            transition: opacity 0.2s ease;
          }

          .sidebar.open .item-text {
            opacity: 1;
          }

          .sidebar-footer {
            margin-top: auto;
            padding: 0 15px 15px;
            width: 100%;
          }

          .logout-btn {
            width: 100%;
            padding: 10px;
            background: var(--accent);
            color: var(--text);
            border: none;
            border-radius: 6px;
            cursor: pointer;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            transition: all 0.3s ease;
            opacity: 0;
          }

          .sidebar.open .logout-btn {
            opacity: 1;
          }

          .logout-btn:hover {
            background: #2f855a;
          }

          /* Responsive */
          @media (max-width: 768px) {
            .navbar-brand h5 {
              display: none; /* Hide h5 text on small width screens */
            }

            .search-bar {
              display: none;
            }

            .sidebar-toggle {
              display: block;
            }

            /* Sidebar hidden by default on small screens */
            .sidebar {
              transform: translateX(-100%); /* Slide sidebar out of view */
              width: 220px; /* Full width when open */
            }

            .sidebar.open {
              transform: translateX(0); /* Slide sidebar into view */
            }
          }

          @media (min-width: 769px) {
            .sidebar-toggle {
              display: none;
            }

            .sidebar {
              transform: translateX(0); /* Always visible on larger screens */
            }

            .sidebar:hover {
              width: 220px;
            }

            .sidebar:hover .item-text,
            .sidebar:hover .logout-btn {
              opacity: 1;
            }
          }
        `}
      </style>

      <nav className="top-navbar">
        <div className="navbar-brand">
          <button className="sidebar-toggle" onClick={toggleSidebar}>
            {isSidebarOpen ? <FaTimes /> : <FaBars />}
          </button>
          <img src={posImage} alt="University Logo" className="navbar-logo" />
          <h5>SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES</h5>
        </div>

        <div className="navbar-actions">
          <div className="search-bar">
            <FaSearch className="search-icon" />
            <input type="text" placeholder="Search here" />
          </div>
          <button className="notification-btn">
            <FaBell />
            <span className="notification-badge">3</span>
          </button>
          <div className="user-profile">
            <img 
              src={userData?.Documents?.applicantPhoto} 
              alt="User" 
              onError={(e) => e.target.src = 'default-avatar.png'}
            />
          </div>
        </div>
      </nav>

      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        {sidebarItems.map((item, index) => (
          <a
            href={item.path}
            key={index}
            className={`sidebar-item ${window.location.pathname === item.path ? 'active' : ''}`}
          >
            <span className="icon">{item.icon}</span>
            <span className="item-text">{item.name}</span>
          </a>
        ))}
        <div className="sidebar-footer">
          <Link to='/erp/studentlogin'>
          <button className="logout-btn">
            <FaSignOutAlt /> Log Out
          </button></Link>
        </div>
      </div>
    </>
  );
};

export default StudentDashboard;

