import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  TextField,
  MenuItem,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  createTheme,
  ThemeProvider,
  CssBaseline,
  Card,
  CardContent,
  FormControl,
  LinearProgress,
  Tabs,
  Tab,
  styled,
  InputLabel,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { State, City } from "country-state-city";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import jsPDF from "jspdf";
import logo from "../AdminComponent/12.png";
import "jspdf-autotable";
import StudentDashboard from "./StudentDashboard";

// Custom Theme
const theme = createTheme({
  palette: {
    primary: { main: "#1976d2" },
    secondary: { main: "#e0f7fa" },
    error: { main: "#d32f2f" },
  },
  typography: {
    h5: { fontWeight: 700, color: "#0d3b66", fontFamily: "'Lato', Sans-serif" },
    h6: { fontWeight: 600, color: "#1565c0" },
    // body1: { color: "#424242" },
    caption: { color: "#757575" },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: "0 6px 20px rgba(0,0,0,0.05)",
          padding: { xs: "10px", sm: "20px" },
          backgroundColor: "#fff",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: "none",
          padding: { xs: "8px 16px", sm: "10px 20px" },
          fontWeight: 500,
          width: { xs: "100%", sm: "auto" },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            borderRadius: 8,
            backgroundColor: "#e9ecef",
            "&:hover fieldset": { borderColor: "#1976d2" },
            "&.Mui-focused fieldset": { borderColor: "#1976d2" },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: { borderBottom: "2px solid #e0e0e0", flexWrap: "wrap" },
        indicator: { backgroundColor: "#1976d2", height: 3 },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 600,
          fontFamily: "'Poppins', Sans-serif",
          fontSize: { xs: "1.9rem", sm: "2rem" },
          minWidth: { xs: "auto", sm: 72 },
          padding: { xs: "6px 12px", sm: "12px 16px" },
          "&.Mui-selected": { color: "#1976d2" },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: { overflowX: "auto" },
      },
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 600,
  padding: { xs: "4px", sm: "12px" },
  fontSize: { xs: "0.75rem", sm: "0.875rem" },
  whiteSpace: "normal",
  wordBreak: "break-word",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": { backgroundColor: "#f9fafb" },
  "&:hover": { backgroundColor: "#eef5ff" },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: { xs: 1, sm: 3 } }}>{children}</Box>}
    </div>
  );
};

// File Upload Component with Input
const FileUploadField = ({
  label,
  name,
  required,
  isUploading,
  touched,
  errors,
  handleFileChange,
  setFieldValue,
}) => (
  <Grid item xs={12} sm={6} md={4}>
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <InputLabel
        sx={{
          fontWeight: 400,
          color: "#0d3b66",
          fontFamily: "'Poppins', Sans-serif",
        }}
      >
        {label} {required && <span style={{ color: "#d32f2f" }}>*</span>}
      </InputLabel>
      <TextField
        type="file"
        inputProps={{ accept: "image/jpeg,image/png,image/jpg" }}
        onChange={(e) => handleFileChange(name, e, setFieldValue)}
        size="small"
        InputLabelProps={{ shrink: true }}
        sx={{ "& .MuiInputBase-input": { padding: "10px" } }}
      />
      <Typography variant="caption" color="#344e41">
        (Max 1MB, Image only)
      </Typography>
      {isUploading[name] && <LinearProgress />}
      {touched[name] && errors[name] && (
        <Typography variant="body2" color="error">
          {errors[name]}
        </Typography>
      )}
    </Box>
  </Grid>
);

// Validation Schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  fatherName: Yup.string().required("Father's Name is required"),
  motherName: Yup.string().required("Mother's Name is required"),
  dob: Yup.string().required("Date of Birth is required"),
  AbcId: Yup.string()
    .matches(/^[0-9]{12}$/, "ABC ID must be exactly 12 digits")
    .required("ABC ID is required"),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile must be 10 digits")
    .required("Mobile is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  gender: Yup.string().required("Gender is required"),
  Handicapped: Yup.string().required("Handicapped status is required"),
  Medium: Yup.string().required("Medium is required"),
  Nationality: Yup.string().required("Nationality is required"),
  Domicile: Yup.string().required("Domicile is required"),
  FathersIncome: Yup.number()
    .required("Father's Income is required")
    .min(0, "Father's Income cannot be negative"), // Explicitly allows 0 or greater
  MothersIncome: Yup.number()
    .required("Mother's Income is required")
    .min(0, "Mother's Income cannot be negative"),
  FathersIncome: Yup.number().required("Father's Income is required"),
  MothersIncome: Yup.number().required("Mother's Income is required"),
  AadharNumber: Yup.string()
    .matches(/^[0-9]{12}$/, "Aadhar must be 12 digits")
    .required("Aadhar Number is required"),
  ParentMobile: Yup.string()
    .matches(/^[0-9]{10}$/, "Parent Mobile must be 10 digits")
    .required("Parent Mobile is required"),
  address1: Yup.string().required("Local Address is required"),
  address2: Yup.string().required("Permanent Address is required"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  district: Yup.string().required("District is required"),
  pinCode: Yup.string()
    .matches(/^[0-9]{6}$/, "Pin Code must be 6 digits")
    .required("Pin Code is required"),
  tenthSchool: Yup.string().required("10th School is required"),
  tenthPassingYear: Yup.string().required("10th Passing Year is required"),
  tenthRollNo: Yup.string().required("10th Roll No is required"),
  tenthBoard: Yup.string().required("10th Board is required"),
  tenthMaxMarks: Yup.number()
    .required("10th Max Marks is required")
    .min(0, "Marks must be positive"),
  tenthMarksObtain: Yup.number()
    .required("10th Marks Obtained is required")
    .min(0, "Marks must be positive")
    .test(
      "max-marks-check",
      "Marks Obtained cannot exceed Total Marks",
      function (value) {
        return value <= this.parent.tenthMaxMarks;
      }
    ),
  tenthPercentage: Yup.string(),
  twelfthMaxMarks: Yup.number().min(0, "Marks must be positive"),
  twelfthMarksObtain: Yup.number()
    .min(0, "Marks must be positive")
    .test(
      "max-marks-check",
      "Marks Obtained cannot exceed Total Marks",
      function (value) {
        return !value || value <= this.parent.twelfthMaxMarks;
      }
    ),
  twelfthPercentage: Yup.string(), // Optional and calculated
  graduationMaxMarks: Yup.number().min(0, "Marks must be positive"),
  graduationMarksObtain: Yup.number()
    .min(0, "Marks must be positive")
    .test(
      "max-marks-check",
      "Marks Obtained cannot exceed Total Marks",
      function (value) {
        return !value || value <= this.parent.graduationMaxMarks;
      }
    ),
  graduationPercentage: Yup.string(), // Optional and calculated

  postGraduationMaxMarks: Yup.number().min(0, "Marks must be positive"),
  postGraduationMarksObtain: Yup.number()
    .min(0, "Marks must be positive")
    .test(
      "max-marks-check",
      "Marks Obtained cannot exceed Total Marks",
      function (value) {
        return !value || value <= this.parent.postGraduationMaxMarks;
      }
    ),
  postGraduationPercentage: Yup.string(), // Optional and calculated

  applicantPhoto: Yup.string().required("Applicant's Photo is required"),
  applicantSignature: Yup.string().required(
    "Applicant's Signature is required"
  ),
  aadharCard: Yup.string().required("Aadhar Card is required"),
  marksheet10th: Yup.string().required("10th Marksheet is required"),
  marksheet12th: Yup.string(),
  diplomaMarksheet: Yup.string(),
  undergraduateCertificate: Yup.string(),
  postgraduateCertificate: Yup.string(),
  domicileCertificate: Yup.string(),
  transferCertificate: Yup.string(),
  incomeCertificate: Yup.string(),
  migrationCertificate: Yup.string(),
  otherCertificate: Yup.string(),
});

export default function EnrollmentForm() {
  const navigate = useNavigate();
  const userData = JSON.parse(sessionStorage.getItem("currentUser"));
  const studentId = userData._id;
  const courseName = userData?.courseName;

  const [tabValue, setTabValue] = useState(0);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState({});

  const [fileUrls, setFileUrls] = useState({
    applicantPhoto: "",
    applicantSignature: "",
    aadharCard: "",
    SamagraId: "",
    marksheet10th: "",
    marksheet12th: "",
    diplomaMarksheet: "",
    undergraduateCertificate: "",
    postgraduateCertificate: "",
    domicileCertificate: "",
    transferCertificate: "",
    incomeCertificate: "",
    migrationCertificate: "",
    otherCertificate: "",
  });

  useEffect(() => {
    const fetchStates = async () => {
      const statesData = await State.getStatesOfCountry("IN");
      setStates(statesData);
    };
    fetchStates();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleNextTab = () => {
    setTabValue((prev) => prev + 1);
  };

  const handlePrevTab = () => {
    setTabValue((prev) => Math.max(prev - 1, 0));
  };
  const handleStateChange = (setFieldValue, value) => {
    setFieldValue("state", value);
    const stateCities = City.getCitiesOfState("IN", value);
    setCities(stateCities);
    setFieldValue("district", "");
  };

  const handleFileChange = async (fieldName, e, setFieldValue) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size / 1024 > 1000) {
      swal("Error", "File size must be less than 1MB", "error");
      return;
    }

    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (!validImageTypes.includes(file.type)) {
      swal("Error", "Only image files (JPEG, PNG, JPG) are allowed", "error");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "upload");

    setIsUploading((prev) => ({ ...prev, [fieldName]: true }));
    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dszawxz7y/image/upload",
        { method: "POST", body: formData }
      );
      const data = await response.json();
      setFileUrls((prev) => ({ ...prev, [fieldName]: data.secure_url }));
      setFieldValue(fieldName, data.secure_url);
    } catch (error) {
      swal("Error", "File upload failed", "error");
    } finally {
      setIsUploading((prev) => ({ ...prev, [fieldName]: false }));
    }
  };
  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  };
  const generatePDF = async () => {
    const pdf = new jsPDF();

    // Add the university logo
    pdf.addImage(logo, "JPEG", 8, 5, 20, 20);

    // Header text
    pdf.setTextColor(165, 42, 42);
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(12);
    pdf.text(
      "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
      115,
      10,
      { align: "center" }
    );
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(11);
    pdf.text(
      "[Established Under Govt. of (M.P.) & Registered UGC 2(F),1956]",
      110,
      16,
      { align: "center" }
    );
    pdf.text(
      "Sh-18 Bhopal-Indore Road, Pin Code - 466001 Pachama, Sehore,",
      110,
      22,
      {
        align: "center",
      }
    );

    // Academic year
    const nextYear = parseInt(userData?.admissionSession) + 1;
    pdf.setFont("helvetica", "bold");
    pdf.text(
      `Academic Year ${userData?.admissionSession} - ${nextYear}`,
      115,
      34,
      { align: "center" }
    );

    // Enrollment form title
    pdf.setDrawColor(0);
    pdf.setFillColor(230, 230, 230);
    pdf.rect(65, 38, 85, 10, "F");
    pdf.setFontSize(12);
    pdf.setFont("helvetica", "bold");
    pdf.setTextColor(0, 0, 0);
    pdf.text("Enrollment Form", 105, 45, { align: "center" });

    // Enrollment details
    const detailsData = [
      ["Enrollment No.", userData?.enrollmentNumber || "Not Generated"],
      ["Name", userData.name],
      ["Father's Name", userData.fathersname],
      ["Mother's Name", userData.mothersname],
      ["Gender", userData.gender],
      ["Date of Birth", formatDate(userData.dob)],
      ["Domicile", userData.domicile],
      ["Category", userData.category],
      ["Mobile No.", userData.mobile],
      ["Email", userData.email],
      ["Course Name", userData.courseName],
      ["Course Type", userData.courseType],
      ["Specialization", userData.courseBranch],
      ["Institute", userData.assignedCollege || "Not assigned"],
      ["ABC ID", userData.AbcId || "Not Upload"],
      ["Last Passed Qualafication", userData.qualification || "Not Upload"],
      [
        "Last Passed Subject",
        userData.lastPassedSubject || userData.QualifiedCourse || "Not Upload",
      ],
    ];

    if (courseName === "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)") {
      detailsData.push(
        ["Neet Qualified Percentage", `${userData?.NeetPercentage}%` || ""],
        ["Admission Category", userData?.Concession || ""]
      );
    }

    let currentY = 50;
    pdf.autoTable({
      body: detailsData,
      startY: currentY,
      margin: { left: 10 },
      tableWidth: "wrap",
      columnStyles: {
        0: { cellWidth: 80 },
        1: { cellWidth: 110 },
      },
    });

    // Add student image without background lines
    const studentImage = userData?.Documents?.applicantPhoto;
    if (studentImage) {
      pdf.addImage(studentImage, "JPEG", 162, 50, 28, 34);
    }

    // Address Details with reduced space
    currentY = pdf.lastAutoTable.finalY + 10; // Reduced from 10 to 5
    // pdf.setFont("helvetica", "bold");
    // pdf.setFontSize(12);
    pdf.text("Address Details", 12, currentY);

    const addressData = [
      ["Local Address", userData?.address?.address1 || ""],
      ["Permanent Address", userData?.address?.address2 || ""],
      ["Country", userData?.address?.country || ""],
      ["State", userData?.address?.state || ""],
      ["District", userData?.address?.district || ""],
      ["Pin Code", userData?.address?.pinCode || ""],
    ];

    pdf.autoTable({
      body: addressData,
      startY: currentY + 5,
      margin: { left: 12 },
      tableWidth: "wrap",
      columnStyles: {
        0: { cellWidth: 80 },
        1: { cellWidth: 110 },
      },
    });

    // Professional Details with reduced space
    currentY = pdf.lastAutoTable.finalY + 5; // Reduced from 10 to 5
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(12);
    pdf.text("Professional Details", 12, currentY);

    const professionalData = [
      ["Handicapped", userData?.professional?.Handicapped || "No"],
      ["Medium", userData?.professional?.Medium || ""],
      ["Father's Occupation", userData?.professional?.FathersOccupation || ""],
      ["Mother's Income", userData?.professional?.MothersIncome || ""],
      ["Aadhar Number", userData?.professional?.AadharNumber || ""],
      ["Parent Mobile", userData?.professional?.ParentMobile || ""],
    ];

    pdf.autoTable({
      body: professionalData,
      startY: currentY + 5,
      margin: { left: 12 },
      tableWidth: "wrap",
      columnStyles: {
        0: { cellWidth: 80 },
        1: { cellWidth: 110 },
      },
    });

    // Academic Details with only 10th and 12th if available
    currentY = pdf.lastAutoTable.finalY + 5; // Reduced from 15 to 5
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(12);
    pdf.text("Academic Details", 13, currentY);

    const academicData = [];
    // Add 10th details if available
    if (userData?.academicDetails?.tenthPassingYear) {
      academicData.push([
        "Tenth Details",
        `${userData?.academicDetails?.tenthPassingYear}`,
        `${userData?.academicDetails?.tenthRollNo || "N/A"}`,
        `${userData?.academicDetails?.tenthBoard || "N/A"}`,
        `${userData?.academicDetails?.tenthMarksObtain || "CGPA"}`,
        `${userData?.academicDetails?.tenthMaxMarks || "CGPA"}`,
        `${
          userData?.academicDetails?.tenthPercentage
            ? userData?.academicDetails?.tenthPercentage
            : userData?.academicDetails?.cgpa || "N/A"
        }`,
      ]);
    }
    // Add 12th details if available
    if (userData?.academicDetails?.twelfthPassingYear) {
      academicData.push([
        "Twelfth/Diploma Details",
        `${userData?.academicDetails?.twelfthPassingYear}`,
        `${userData?.academicDetails?.twelfthRollNo || "N/A"}`,
        `${userData?.academicDetails?.twelfthBoard || "N/A"}`,
        `${userData?.academicDetails?.twelfthMarksObtain || "N/A"}`,
        `${userData?.academicDetails?.twelfthMaxMarks || "CGPA"}`,
        `${
          userData?.academicDetails?.twelfthPercentage
            ? userData?.academicDetails?.twelfthPercentage
            : userData?.academicDetails?.cgpa || "N/A"
        }`,
      ]);
    }

    pdf.autoTable({
      head: [
        [
          "Qualification",
          "Passing Year",
          "Roll No.",
          "Board",
          "Obtained Marks",
          "Max Marks",
          "Percentage/CGPA",
        ],
      ],
      body: academicData,
      startY: currentY + 5,
      theme: "grid",
      styles: { fontSize: 9 },
      margin: { left: 14 },
    });

    // New Documents Section
    currentY = pdf.lastAutoTable.finalY + 5;
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(12);
    pdf.text("Documents", 13, currentY);

    const documentData = [];
    if (userData?.Documents) {
      Object.entries(userData.Documents).forEach(([key, value]) => {
        if (value && key !== "_id") {
          documentData.push([key, "Uploaded"]);
        }
      });
    }

    pdf.autoTable({
      body: documentData,
      startY: currentY + 5,
      margin: { left: 14 },
      tableWidth: "wrap",
      columnStyles: {
        0: { cellWidth: 80 },
        1: { cellWidth: 110 },
      },
    });

    // Declaration and rest of the code remains unchanged
    currentY = pdf.lastAutoTable.finalY + 10;
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(10);
    pdf.text("Declaration:", 12, currentY);
    currentY += 5;
    const declarationText = [
      "I hereby declare that the information provided in this form and its attachments is accurate to the best of my knowledge.",
      "I understand that any false information may result in disqualification.",
      "If selected for admission, I agree to abide by the rules and regulations of the university.",
      "I understand that the admission is provisional, subject to confirmation by the concerned authorities, and that the fee paid",
      " is non-refundable in case of cancellation.",
    ];
    declarationText.forEach((line, index) => {
      pdf.text(line, 12, currentY + index * 5);
    });

    currentY += 25;
    pdf.text("Certificate ………", 12, currentY);

    currentY += 10;
    pdf.setFont("helvetica", "italic");
    pdf.setFontSize(10);
    pdf.text(
      "Certified that the above student has been admitted to this institution as per the procedure laid down by regulatory authorities.",
      12,
      currentY
    );
    currentY += 10;
    pdf.text(
      "The entries above have been checked and verified by me from the records.",
      12,
      currentY
    );
    currentY += 10;
    pdf.text(
      "Therefore, I recommend that he/she should be enrolled as requested by the student.",
      12,
      currentY
    );

    const applicantSignature = userData?.Documents?.applicantSignature;
    if (applicantSignature) {
      pdf.addImage(applicantSignature, "JPEG", 20, currentY + 20, 40, 15);
      pdf.text("Applicant's Signature", 20, currentY + 40);
    }

    pdf.text("Signature of the Dean with seal", 140, currentY + 40);

    pdf.save(`${userData?.name} Enrollment Form.pdf`);
  };
  const updateStudentData = async (values) => {
    const requiredDocs = [
      "applicantPhoto",
      "applicantSignature",
      "aadharCard",
      "marksheet10th",
    ];
    const missingDocs = requiredDocs.filter((doc) => !values[doc]);

    if (missingDocs.length > 0) {
      swal("Error", "Please upload all required documents", "error");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        "https://sssutms.ac.in/apitest/updatestudent",
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            studentId,
            AbcId: values.AbcId,
            personalFormData: {
              name: values.name,
              fatherName: values.fatherName,
              motherName: values.motherName,
              mobile: values.mobile,
              passingYear: values.passingYear,
              email: values.email,
              gender: values.gender,
              dob: values.dob,
              category: values.category,
              qualification: values.qualification,
              admissionSession: values.admissionSession,
              LastPercentage: values.LastPercentage,
              cgpa: values.cgpa,
              board: values.board,
              lastPassedSubject: values.lastPassedSubject,
            },
            professionalFormData: {
              Handicapped: values.Handicapped,
              Medium: values.Medium,
              Nationality: values.Nationality,
              Domicile: values.Domicile,
              FathersOccupation: values.FathersOccupation,
              MothersOccupation: values.MothersOccupation,
              FathersIncome: values.FathersIncome,
              MothersIncome: values.MothersIncome,
              AadharNumber: values.AadharNumber,
              SamagraId: values.SamagraId,
              ParentMobile: values.ParentMobile,
            },
            addressFormData: {
              address1: values.address1,
              address2: values.address2,
              country: values.country,
              state: values.state,
              district: values.district,
              pinCode: values.pinCode,
            },
            educationFormData: {
              tenthSchool: values.tenthSchool,
              tenthPassingYear: values.tenthPassingYear,
              tenthRollNo: values.tenthRollNo,
              tenthBoard: values.tenthBoard,
              tenthMaxMarks: values.tenthMaxMarks,
              tenthMarksObtain: values.tenthMarksObtain,
              tenthPercentage: values.tenthPercentage,
              twelfthSchool: values.twelfthSchool,
              twelfthPassingYear: values.twelfthPassingYear,
              twelfthRollNo: values.twelfthRollNo,
              twelfthBoard: values.twelfthBoard,
              twelfthMaxMarks: values.twelfthMaxMarks,
              twelfthMarksObtain: values.twelfthMarksObtain,
              twelfthPercentage: values.twelfthPercentage,
              graduationCollege: values.graduationCollege,
              graduationPassingYear: values.graduationPassingYear,
              graduationRollNo: values.graduationRollNo,
              graduationUniversity: values.graduationUniversity,
              graduationMaxMarks: values.graduationMaxMarks,
              graduationMarksObtain: values.graduationMarksObtain,
              graduationPercentage: values.graduationPercentage,

              postGraduationCollege: values.postGraduationCollege,
              postGraduationPassingYear: values.postGraduationPassingYear,
              postGraduationRollNo: values.postGraduationRollNo,
              postGraduationUniversity: values.postGraduationUniversity,
              postGraduationMaxMarks: values.postGraduationMaxMarks,
              postGraduationMarksObtain: values.postGraduationMarksObtain,
              postGraduationPercentage: values.postGraduationPercentage,
            },
            fileUrls,
          }),
        }
      );

      if (!response.ok) throw new Error("API Error");
      const data = await response.json();
      sessionStorage.setItem(
        "currentUser",
        JSON.stringify(data.updatedStudent)
      );

      swal("Success", "Enrolled Successfully!", "success").then(() => {
        generatePDF(data.updatedStudent);
        navigate("/Epravesh/Student/EnrollementFee");
      });
    } catch (error) {
      swal("Error", "Something went wrong!", "error");
    } finally {
      setLoading(false);
    }
  };

  const renderAcademicDetails = (qualification, { setFieldValue, values }) => {
    const headers = [
      "Exam",
      "School/College",
      "Passing Year",
      "Roll No.",
      "Board/Uni",
      "Marks Obtained",
      "Total Marks",
      "Percentage",
    ];

    const academicData = [
      {
        condition: ["10th", "12th", "UG", "Diploma", "PG"].includes(
          qualification
        ),
        exam: "10th",
        fields: [
          { name: "tenthSchool", type: "text" },
          { name: "tenthPassingYear", type: "number" },
          { name: "tenthRollNo", type: "text" },
          { name: "tenthBoard", type: "text" },
          { name: "tenthMarksObtain", type: "number" },
          { name: "tenthMaxMarks", type: "number" },
          { name: "tenthPercentage", type: "text", readOnly: true },
        ],
      },
      {
        condition: ["12th", "UG", "PG"].includes(qualification),
        exam: "12th",
        fields: [
          { name: "twelfthSchool", type: "text" },
          { name: "twelfthPassingYear", type: "number" },
          { name: "twelfthRollNo", type: "text" },
          { name: "twelfthBoard", type: "text" },
          { name: "twelfthMarksObtain", type: "number" },
          { name: "twelfthMaxMarks", type: "number" },

          { name: "twelfthPercentage", type: "text", readOnly: true },
        ],
      },
      {
        condition: ["UG", "PG"].includes(qualification),
        exam: "Graduation",
        fields: [
          { name: "graduationCollege", type: "text" },
          { name: "graduationPassingYear", type: "number" },
          { name: "graduationRollNo", type: "text" },
          { name: "graduationUniversity", type: "text" },
          { name: "graduationMarksObtain", type: "number" },
          { name: "graduationMaxMarks", type: "number" },

          { name: "graduationPercentage", type: "text", readOnly: true },
        ],
      },
      {
        condition: qualification === "Diploma",
        exam: "Diploma",
        fields: [
          { name: "twelfthSchool", type: "text" },
          { name: "twelfthPassingYear", type: "number" },
          { name: "twelfthRollNo", type: "text" },
          { name: "twelfthBoard", type: "text" },
          { name: "twelfthMarksObtain", type: "number" },
          { name: "twelfthMaxMarks", type: "number" },

          { name: "twelfthPercentage", type: "text", readOnly: true },
        ],
      },
      {
        condition: qualification === "PG",
        exam: "Post Graduation",
        fields: [
          { name: "postGraduationCollege", type: "text" },
          { name: "postGraduationPassingYear", type: "number" },
          { name: "postGraduationRollNo", type: "text" },
          { name: "postGraduationUniversity", type: "text" },
          { name: "postGraduationMarksObtain", type: "number" },
          { name: "postGraduationMaxMarks", type: "number" },

          { name: "postGraduationPercentage", type: "text", readOnly: true },
        ],
      },
    ];
    // Function to calculate percentage
    const calculatePercentage = (maxMarks, marksObtained, fieldName) => {
      if (maxMarks && marksObtained && marksObtained <= maxMarks) {
        const percentage = ((marksObtained / maxMarks) * 100).toFixed(2);
        console.log(percentage, "percentage calculated successfully");
        setFieldValue(fieldName, `${percentage}%`);
      } else {
        setFieldValue(fieldName, "%");
      }
    };

    return (
      <Box sx={{ mt: 2 }}>
        <TableContainer
          component={Paper}
          sx={{
            display: { xs: "none", sm: "block" },
            borderRadius: 2,
            boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
          }}
        >
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <StyledTableRow>
                {headers.map((header, index) => (
                  <StyledTableCell key={index}>{header}</StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {academicData.map(
                (row, rowIndex) =>
                  row.condition && (
                    <StyledTableRow key={rowIndex}>
                      <TableCell
                        sx={{ fontSize: { sm: "0.875rem" }, fontWeight: 500 }}
                      >
                        {row.exam}
                      </TableCell>
                      {row.fields.map((field, fieldIndex) => (
                        <TableCell key={fieldIndex} sx={{ padding: "12px" }}>
                          <Field
                            name={field.name}
                            as={TextField}
                            type={field.type}
                            fullWidth
                            variant="outlined"
                            size="small"
                            InputProps={{
                              readOnly: field.readOnly || false,
                            }}
                            onChange={(e) => {
                              const value =
                                field.type === "number"
                                  ? parseFloat(e.target.value)
                                  : e.target.value;
                              setFieldValue(field.name, value);

                              // Calculate percentage when total marks or marks obtained change
                              // Calculate percentage when MaxMarks or MarksObtain changes
                              if (
                                field.name.includes("MaxMarks") ||
                                field.name.includes("MarksObtain")
                              ) {
                                const prefix =
                                  field.name.split("MaxMarks")[0] ||
                                  field.name.split("MarksObtain")[0];
                                const maxMarksField = `${prefix}MaxMarks`;
                                const marksObtainField = `${prefix}MarksObtain`;
                                const percentageField = `${prefix}Percentage`;

                                const maxMarks =
                                  field.name === maxMarksField
                                    ? value
                                    : values[maxMarksField];
                                const marksObtained =
                                  field.name === marksObtainField
                                    ? value
                                    : values[marksObtainField];

                                calculatePercentage(
                                  maxMarks,
                                  marksObtained,
                                  percentageField
                                );
                              }
                            }}
                            value={values[field.name] || ""}
                          />
                        </TableCell>
                      ))}
                    </StyledTableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Grid Layout for Small Screens */}
        <Box sx={{ display: { xs: "block", sm: "none" }, mt: 2 }}>
          {academicData.map(
            (row, rowIndex) =>
              row.condition && (
                <Paper
                  key={rowIndex}
                  sx={{
                    mb: 2,
                    p: 2,
                    borderRadius: 2,
                    boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "1rem", fontWeight: 600, mb: 1 }}
                  >
                    {row.exam}
                  </Typography>
                  <Grid container spacing={1}>
                    {row.fields.map((field, fieldIndex) => (
                      <Grid item xs={12} key={fieldIndex}>
                        <Field
                          name={field.name}
                          as={TextField}
                          label={headers[fieldIndex + 1]} // +1 to skip "Exam" header
                          type={field.type}
                          fullWidth
                          variant="outlined"
                          size="small"
                          InputProps={{
                            readOnly: field.readOnly || false,
                          }}
                          onChange={(e) => {
                            const value =
                              field.type === "number"
                                ? parseFloat(e.target.value)
                                : e.target.value;
                            setFieldValue(field.name, value);

                            // Calculate percentage when MaxMarks or MarksObtain changes
                            if (
                              field.name.includes("MaxMarks") ||
                              field.name.includes("MarksObtain")
                            ) {
                              const prefix =
                                field.name.split("MaxMarks")[0] ||
                                field.name.split("MarksObtain")[0];
                              const maxMarksField = `${prefix}MaxMarks`;
                              const marksObtainField = `${prefix}MarksObtain`;
                              const percentageField = `${prefix}Percentage`;

                              const maxMarks =
                                field.name === maxMarksField
                                  ? value
                                  : values[maxMarksField];
                              const marksObtained =
                                field.name === marksObtainField
                                  ? value
                                  : values[marksObtainField];

                              calculatePercentage(
                                maxMarks,
                                marksObtained,
                                percentageField
                              );
                            }
                          }}
                          value={values[field.name] || ""}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              )
          )}
        </Box>
      </Box>
    );
  };

  return (
    <>
      <style>
        {`* { font-family: 'Poppins', Sans-serif;
       }`}
      </style>
      <ThemeProvider theme={theme}>
        <StudentDashboard />

        <Container
          maxWidth="lg"
          style={{ height: "500px", marginTop: "-10px" }}
          sx={{ py: { xs: 3, sm: 10 } }}
        >
          <Box
            display="flex"
            alignItems="center"
            mb={{ xs: 1, sm: 3 }}
            mt={{ xs: 6, sm: 0 }} // Margin-top 20px for small screens
            flexDirection={{ xs: "row", sm: "row" }}
            justifyContent={{ xs: "space-between", sm: "center" }}
          >
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate("/student/dashboard")}
              sx={{ display: { xs: "flex", sm: "none" } }} // Hidden on larger screens
            >
              Back
            </Button>
            <Typography
              variant="h5"
              align="center"
              sx={{ flexGrow: 1, fontFamily: "'Poppins', Sans-serif" }}
            >
              ENROLLMENT FORM
            </Typography>
          </Box>

          <Formik
            initialValues={{
              name: userData?.name || "",
              fatherName: userData?.fathersname || "",
              motherName: userData?.mothersname || "",
              dob: userData?.dob || "",
              category: userData?.category || "",
              qualification: userData?.qualification || "",
              admissionSession: userData?.admissionSession || "",
              LastPercentage: userData?.LastPercentage || "",
              board: userData?.board || "",
              passingYear: userData?.passingYear || "",
              cgpa: userData?.cgpa || "",
              lastPassedSubject: userData?.lastPassedSubject || "",
              mobile: userData?.mobile || "",
              email: userData?.email || "",
              gender: userData?.gender || "",
              AbcId: "",
              Handicapped: "",
              Medium: "",
              Nationality: userData?.nationality || "",
              Domicile: userData?.domicile || "",
              FathersOccupation: "",
              MothersOccupation: "",
              FathersIncome: "",
              MothersIncome: "",
              AadharNumber: "",
              SamagraId: "",
              ParentMobile: "",
              address1: "",
              address2: "",
              country: "",
              state: "",
              district: "",
              pinCode: "",
              tenthSchool: "",
              tenthPassingYear: "",
              tenthRollNo: "",
              tenthBoard: "",
              tenthMaxMarks: "",
              tenthMarksObtain: "",
              tenthPercentage: "",
              twelfthSchool: "",
              twelfthPassingYear: "",
              twelfthRollNo: "",
              twelfthBoard: "",
              twelfthMaxMarks: "",
              twelfthMarksObtain: "",
              twelfthPercentage: "",
              graduationCollege: "",
              graduationPassingYear: "",
              graduationRollNo: "",
              graduationUniversity: "",
              graduationMaxMarks: "",
              graduationMarksObtain: "",
              graduationPercentage: "",
              postGraduationCollege: "",
              postGraduationPassingYear: "",
              postGraduationRollNo: "",
              postGraduationUniversity: "",
              postGraduationMarksObtain: "",
              postGraduationMaxMarks: "",
              postGraduationPercentage: "",
              applicantPhoto: "",
              applicantSignature: "",
              aadharCard: "",
              SamagraId: "",
              marksheet10th: "",
              marksheet12th: "",
              diplomaMarksheet: "",
              undergraduateCertificate: "",
              postgraduateCertificate: "",
              domicileCertificate: "",
              transferCertificate: "",
              incomeCertificate: "",
              migrationCertificate: "",
              otherCertificate: "",
            }}
            validationSchema={validationSchema}
            onSubmit={updateStudentData}
          >
            {({ values, errors, touched, setFieldValue }) => (
              <Form>
                <Card>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    centered
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label="Personal" />
                    <Tab label="Professional" />
                    <Tab label="Address" />
                    <Tab label="Academic" />
                    <Tab label="Documents" />
                  </Tabs>

                  {/* Personal Tab */}
                  <TabPanel value={tabValue} index={0}>
                    <Grid container spacing={{ xs: 1, sm: 2.0 }}>
                      {[
                        { name: "name", label: "Name *" },
                        { name: "fatherName", label: "Father's Name *" },
                        { name: "motherName", label: "Mother's Name *" },
                        { name: "mobile", label: "Mobile *" },
                        { name: "email", label: "Email *" },
                        { name: "dob", label: "Date Of Birth *" },
                        { name: "gender", label: "Gender *" },
                        { name: "category", label: "Category *" },
                        {
                          name: "admissionSession",
                          label: "Admission Session *",
                        },
                        {
                          name: "qualification",
                          label: "Last Qualification *",
                        },
                        { name: "passingYear", label: "Last Passing Year *" },
                        {
                          name: "lastPassedSubject",
                          label: "Last Passed Subject *",
                        },
                        ...(values.LastPercentage
                          ? [
                              {
                                name: "LastPercentage",
                                label: "Last Percentage *",
                              },
                            ]
                          : values.cgpa
                          ? [
                              { name: "board", label: "Passed Board *" }, // CGPA ke sath board bhi aayega
                              { name: "cgpa", label: "CGPA *" },
                            ]
                          : []),
                      ].map((field) => (
                        <Grid item xs={12} sm={6} md={4} key={field.name}>
                          <Field
                            name={field.name}
                            as={TextField}
                            label={field.label}
                            fullWidth
                            variant="outlined"
                            error={touched[field.name] && !!errors[field.name]}
                            helperText={
                              touched[field.name] && errors[field.name]
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNextTab}
                      >
                        Next
                      </Button>
                    </Box>
                  </TabPanel>

                  {/* Professional Tab */}
                  <TabPanel value={tabValue} index={1}>
                    <Grid container spacing={{ xs: 1, sm: 2.0 }}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Field
                          name="Handicapped"
                          as={TextField}
                          select
                          label="Handicapped *"
                          fullWidth
                          variant="outlined"
                          error={touched.Handicapped && !!errors.Handicapped}
                          helperText={touched.Handicapped && errors.Handicapped}
                        >
                          <MenuItem value="">Select</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Field>
                      </Grid>
                      <Grid item xs={10} sm={4} md={4}>
                        <Field
                          name="Medium"
                          as={TextField}
                          select
                          label="Medium *"
                          fullWidth
                          variant="outlined"
                          error={touched.Medium && !!errors.Medium}
                          helperText={touched.Medium && errors.Medium}
                        >
                          <MenuItem value="">Select</MenuItem>
                          <MenuItem value="English">English</MenuItem>
                          <MenuItem value="Hindi">Hindi</MenuItem>
                        </Field>
                      </Grid>
                      {[
                        { name: "Nationality", label: "Nationality *" },
                        { name: "Domicile", label: "Domicile *" },
                        {
                          name: "FathersOccupation",
                          label: "Father's Occupation *",
                        },
                        {
                          name: "MothersOccupation",
                          label: "Mother's Occupation *",
                        },
                        {
                          name: "FathersIncome",
                          label: "Father's Income *",
                          type: "number",
                        },
                        {
                          name: "MothersIncome",
                          label: "Mother's Income *",
                          type: "number",
                        },
                        {
                          name: "AadharNumber",
                          label: "Aadhar Number *",
                          type: "number",
                        },
                        {
                          name: "SamagraId",
                          label: "Samagra ID",
                          type: "number",
                        },
                        {
                          name: "ParentMobile",
                          label: "Parent Mobile *",
                          type: "number",
                        },
                        { name: "AbcId", label: "ABC ID *", type: "number" },
                      ].map((field) => (
                        <Grid item xs={12} sm={6} md={4} key={field.name}>
                          <Field
                            name={field.name}
                            as={TextField}
                            label={field.label}
                            type={field.type || "text"}
                            fullWidth
                            variant="outlined"
                            error={touched[field.name] && !!errors[field.name]}
                            helperText={
                              touched[field.name] && errors[field.name]
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 2,
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handlePrevTab}
                      >
                        Previous
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNextTab}
                      >
                        Next
                      </Button>
                    </Box>
                  </TabPanel>

                  {/* Address Tab */}
                  <TabPanel value={tabValue} index={2}>
                    <Grid container spacing={{ xs: 1, sm: 2.0 }}>
                      <Grid item xs={12}>
                        <Field
                          name="address1"
                          as={TextField}
                          label="Local Address *"
                          autoComplete="off"
                          fullWidth
                          variant="outlined"
                          error={touched.address1 && !!errors.address1}
                          helperText={touched.address1 && errors.address1}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="address2"
                          as={TextField}
                          label="Permanent Address *"
                          autoComplete="off"
                          fullWidth
                          variant="outlined"
                          error={touched.address2 && !!errors.address2}
                          helperText={touched.address2 && errors.address2}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          name="country"
                          as={TextField}
                          select
                          label="Country *"
                          fullWidth
                          variant="outlined"
                          error={touched.country && !!errors.country}
                          helperText={touched.country && errors.country}
                          onChange={(e) => {
                            setFieldValue("country", e.target.value);
                            if (e.target.value === "Other") {
                              setFieldValue("state", "");
                              setFieldValue("district", "");
                            }
                          }}
                          //   sx={{ height: 35, fontSize: 14, padding: "4px 8px" }}
                        >
                          <MenuItem value="">Select</MenuItem>
                          <MenuItem value="India">India</MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {values.country === "Other" ? (
                          <Field
                            name="state"
                            as={TextField}
                            label="State *"
                            fullWidth
                            variant="outlined"
                            error={touched.state && !!errors.state}
                            helperText={touched.state && errors.state}
                          />
                        ) : (
                          <Field
                            name="state"
                            as={TextField}
                            select
                            label="State *"
                            fullWidth
                            variant="outlined"
                            error={touched.state && !!errors.state}
                            helperText={touched.state && errors.state}
                            onChange={(e) =>
                              handleStateChange(setFieldValue, e.target.value)
                            }
                          >
                            <MenuItem value="">Select</MenuItem>
                            {states.map((state) => (
                              <MenuItem
                                key={state.isoCode}
                                value={state.isoCode}
                              >
                                {state.name}
                              </MenuItem>
                            ))}
                          </Field>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {values.country === "Other" ? (
                          <Field
                            name="district"
                            as={TextField}
                            label="District *"
                            fullWidth
                            variant="outlined"
                            error={touched.district && !!errors.district}
                            helperText={touched.district && errors.district}
                          />
                        ) : (
                          <Field
                            name="district"
                            as={TextField}
                            select
                            label="District *"
                            fullWidth
                            variant="outlined"
                            error={touched.district && !!errors.district}
                            helperText={touched.district && errors.district}
                          >
                            <MenuItem value="">Select</MenuItem>
                            {cities.map((city) => (
                              <MenuItem key={city.name} value={city.name}>
                                {city.name}
                              </MenuItem>
                            ))}
                          </Field>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          name="pinCode"
                          as={TextField}
                          label="Pin Code *"
                          autoComplete="off"
                          fullWidth
                          variant="outlined"
                          error={touched.pinCode && !!errors.pinCode}
                          helperText={touched.pinCode && errors.pinCode}
                        />
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 2,
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handlePrevTab}
                      >
                        Previous
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNextTab}
                      >
                        Next
                      </Button>
                    </Box>
                  </TabPanel>

                  {/* Academic Tab */}
                  <TabPanel value={tabValue} index={3}>
                    {renderAcademicDetails(userData?.qualification, {
                      setFieldValue,
                      values,
                    })}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 2,
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handlePrevTab}
                      >
                        Previous
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNextTab}
                      >
                        Next
                      </Button>
                    </Box>
                  </TabPanel>

                  {/* Documents Tab */}
                  <TabPanel value={tabValue} index={4}>
                    <Grid container spacing={{ xs: 1, sm: 1.5, gap: 1 }}>
                      {[
                        {
                          name: "applicantPhoto",
                          label: "Applicant's Photo",
                          required: true,
                        },
                        {
                          name: "applicantSignature",
                          label: "Applicant's Signature",
                          required: true,
                        },
                        {
                          name: "aadharCard",
                          label: "Aadhar Card",
                          required: true,
                        },
                        {
                          name: "marksheet10th",
                          label: "10th Marksheet",
                          required: true,
                        },
                        {
                          name: "marksheet12th",
                          label: "12th Marksheet",
                          required: false,
                        },
                        {
                          name: "diplomaMarksheet",
                          label: "Diploma Marksheet",
                          required: false,
                        },
                        {
                          name: "undergraduateCertificate",
                          label: "Undergraduate Certificate",
                          required: false,
                        },
                        {
                          name: "postgraduateCertificate",
                          label: "Postgraduate Certificate",
                          required: false,
                        },
                        {
                          name: "domicileCertificate",
                          label: "Domicile Certificate",
                          required: false,
                        },
                        {
                          name: "transferCertificate",
                          label: "Transfer Certificate",
                          required: false,
                        },
                        {
                          name: "incomeCertificate",
                          label: "Income Certificate",
                          required: false,
                        },
                        {
                          name: "migrationCertificate",
                          label: "Migration Certificate",
                          required: false,
                        },
                        {
                          name: "otherCertificate",
                          label: "Other Certificate",
                          required: false,
                        },
                      ].map((field) => (
                        <FileUploadField
                          key={field.name}
                          label={field.label}
                          name={field.name}
                          required={field.required}
                          isUploading={isUploading}
                          touched={touched}
                          errors={errors}
                          handleFileChange={handleFileChange}
                          setFieldValue={setFieldValue}
                        />
                      ))}
                    </Grid>

                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handlePrevTab}
                      >
                        Previous
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        sx={{ px: { xs: 4, sm: 6 }, py: 1.5 }}
                      >
                        {loading ? "Submitting..." : "Submit"}
                      </Button>
                    </Box>
                  </TabPanel>
                </Card>
              </Form>
            )}
          </Formik>
        </Container>
      </ThemeProvider>
    </>
  );
}
