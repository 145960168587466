// import React, { useState, useEffect } from "react";
// import Spinner from "react-bootstrap/Spinner";
// import axios from "axios";
// import { Form, Row, Col, Button } from "react-bootstrap";
// import { CardContent } from "@mui/material";
// import jsPDF from "jspdf";
// import StudentDashboard from "../../../AdminComponent/StudentDashboard";



// const EStudent_Slip = () => {
//   const [selectedOption, setSelectedOption] = useState("");

//   const handleOptionChange = (event) => {
//     setSelectedOption(event.target.value);
//   };
//   const userData = JSON.parse(sessionStorage.getItem("currentUser"));
//   const isEnrolledPaid = userData.isEnrollPaid;

//   const studentId = userData._id;
//   const [approvedStudents, setApprovedStudents] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

  
//   useEffect(() => {
//     const fetchPaymentDetails = async () => {
//       try {
//         const { data } = await axios.get(
//           "https://sssutms.ac.in/apitest/student/get-payment-details2",
//           {
//             params: { id: studentId },
//           }
//         );

//         const paymentDetails = {};

//         if (data.Epravesh_payment_details) {
//           paymentDetails.epravesh = data.Epravesh_payment_details;
//         }
//         console.log(
//           data.Epravesh_payment_details,
//           " data.Epravesh_payment_details;"
//         );
//         if (data.enrollment_fee_details) {
//           paymentDetails.enrollment = [data.enrollment_fee_details];
//         }

//         setApprovedStudents(paymentDetails);
//         setLoading(false);
//       } catch (err) {
//         console.error("Error while fetching payment details:", err);
//         setError("Failed to fetch payment details");
//         setLoading(false);
//       }
//     };

//     fetchPaymentDetails();
//   }, [studentId]);

//   const logo = "https://cdn.sssutms.ac.in/media/static/images/sssutms.jpg";

//   const generatePDF = () => {
//     const doc = new jsPDF();
//     doc.addImage(logo, "JPEG", 3, 5, 30, 28);
//     doc.setFont("helvetica", "bold");
//     doc.setFontSize(16);
//     doc.setTextColor(0, 0, 0);
//     doc.text("SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL ", 118, 10, {
//       align: "center",
//     });
//     doc.text("SCIENCES SEHORE", 118, 17, { align: "center" });
//     doc.setFontSize(13);
//     doc.text(
//       "[Established Under Govt. of (M.P.) & Registered UGC 2(F), 1956]",
//       118,
//       24,
//       { align: "center" }
//     );
//     doc.text(
//       "Sh-18 Bhopal-Indore Road, Opposite Oilfed Plant, Pachama,",
//       118,
//       30,
//       { align: "center" }
//     );
//     doc.setFontSize(14);
//     doc.setTextColor(255, 0, 0); // Set text color to red for the title
//     doc.text("EPravesh Fee Receipt", 118, 40, { align: "center" });

//     doc.setFontSize(12);
//     doc.setTextColor(0, 0, 0); // Set text color back to black

//     // Set the initial y position
//     let yPos = 60;

//     // Define the line height
//     const lineHeight = 10;

//     doc.text(
//       `                                     Name of Candidate  :  ${approvedStudents.epravesh[0].name}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Father's Name          :  ${approvedStudents.epravesh[0].fathersname}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Email                         :  ${approvedStudents.epravesh[0].email}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Mobile                       :  ${approvedStudents.epravesh[0].mobile}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Course Type            :  ${approvedStudents.epravesh[0].courseType}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Course Name           :  ${approvedStudents.epravesh[0].courseName}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Course Branch        :  ${approvedStudents.epravesh[0].courseBranch}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     TXN ID                      :   ${approvedStudents.epravesh[0].txnId}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     ORDER ID                :  ${approvedStudents.epravesh[0].orderId}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     PAYMENT MODE    :  ${approvedStudents.epravesh[0].paymentMode}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     BANKTXN ID           :  ${approvedStudents.epravesh[0].bankTxnId}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Paid Amount           :  ${approvedStudents.epravesh[0].txnAmount}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Date & Time            :  ${approvedStudents.epravesh[0].txnDate}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.save(`${approvedStudents.epravesh[0].name} EPravesh Fee`);
//   };


//   const generatePDF1 = () => {
//     const doc = new jsPDF();
//     doc.addImage(logo, "JPEG", 3, 5, 30, 28);
//     doc.setFont("helvetica", "bold");
//     doc.setFontSize(16);
//     doc.setTextColor(0, 0, 0);
//     doc.text("SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL ", 118, 10, {
//       align: "center",
//     });
//     doc.text("SCIENCES SEHORE", 118, 17, { align: "center" });
//     doc.setFontSize(13);
//     doc.text(
//       "[Established Under Govt. of (M.P.) & Registered UGC 2(F), 1956]",
//       118,
//       24,
//       { align: "center" }
//     );
//     doc.text(
//       "Sh-18 Bhopal-Indore Road, Opposite Oilfed Plant, Pachama,",
//       118,
//       30,
//       { align: "center" }
//     );
//     doc.setFontSize(14);
//     doc.setTextColor(255, 0, 0);
//     doc.text("Enrollment Fee Receipt", 118, 40, { align: "center" });

//     doc.setFontSize(12);
//     doc.setTextColor(0, 0, 0);

//     let yPos = 60;

//     const lineHeight = 10;

//     doc.text(
//       `                                    Name of Candidate  :   ${approvedStudents.enrollment[0].name}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Father's Name          :  ${approvedStudents.enrollment[0].fathersname}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Email                         :  ${approvedStudents.enrollment[0].email}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Mobile                       :  ${approvedStudents.enrollment[0].mobile}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Course Type            :  ${approvedStudents.enrollment[0].courseType}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Course Name           :  ${approvedStudents.enrollment[0].courseName}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Course Branch        :  ${approvedStudents.enrollment[0].courseBranch}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    TXN ID                      :  ${approvedStudents.enrollment[0].txnId}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    ORDER ID                :  ${approvedStudents.enrollment[0].orderId}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    PAYMENT MODE    :  ${approvedStudents.enrollment[0].paymentMode}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    BANKTXN ID           :  ${approvedStudents.enrollment[0].bankTxnId}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Paid Amount           :  ${approvedStudents.enrollment[0].txnAmount}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Date & Time            :  ${approvedStudents.enrollment[0].txnDate}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.save(`${approvedStudents.enrollment[0].name} Enrollement Fee`);
//   };
//   return (
//     <>
  
//         <StudentDashboard />

//         <style>
//           {`


// *{
// font-family:'Poppins'sans-serif;

// }
// .radd{
//  margin-left: 120px;
//   margin-top: 80px;
//   }
// .form-input{
// padding:5px;
// font-family:'Poppins',sans-serif;
// font-size:15px;
// border-color:#0d47a1;
// }
//   .smallh{
//   font-size:13px;
//   color:#16325B;
//   margin-top:-20px}
//         .conttable{
//             background-color: #e8e5d5;
//             width: 90%;
//             margin-left: 120px;
//             margin-right: auto;
//             margin-top: 80px;
//             text-align: center;
//             max-width: 1200px;
//         }
// .h4d{
//   font-weight: inherit;
//   height:60px;
// margin-top:-15px;
//                       // background-color: #003049;
//                       border-radius: 10px;
//                       color: black;
//                       font-weight:700;
//                       padding: 5px;

//                       }

//         .shadow{
//      margin-top: 10px;
//       margin-left:8%}

//         @media screen and (max-width: 480px){
//           .custom-card-content{
//             width: 100%; /* Adjust the percentage as needed */
//             max-width: 1200px; /* Set a maximum width */
//             margin: 0 auto; /* Center align */
//           }

// .radd{
//  margin-left: 20px;
//   margin-top: 80px;
//   }

//             .shadow{
//   margin-top: -60px;
//       margin-left:0%}

//         .h4d{
//   font-weight: inherit;
//   height:60px;

//                       // background-color: #003049;
//                       border-radius: 10px;
//                       color: black;
//                       font-weight:700;
//                       font-size:18px;
//                       padding: 5px;
//                       margin-top:70px;

//                       }

//          .form-group-custom {
//     flex: 0 0 100%; /* Make each form group take full width */
//     max-width: 100%;
//   }

//         .forum{
//         margin-left:10px;}              }
//     `}
//         </style>

//         {loading ? (
//           <div
//             className="d-flex justify-content-center align-items-center"
//             style={{ minHeight: "200px", marginTop: "40px" }}
//           >
//             <Spinner animation="border" role="status">
//               <span className="visually-hidden">Loading...</span>
//             </Spinner>
//           </div>
//         ) : error ? (
//           <span>{error}</span>
//         ) : Object.keys(approvedStudents).length === 0 ? (
//           <span>No Slip Available</span>
//         ) : (
//           <>
           
//             {approvedStudents.epravesh &&
//               approvedStudents.epravesh.length > 0 && (
//                 <>
//                   <div className="radd">
//                     <h4 style={{ fontFamily: "'Poppins',Sans-serif" }}>
//                       Select Fee Receipt Type :
//                     </h4>
//                     <div>
//                       <label
//                         style={{
//                           marginRight: "20px",
//                           fontFamily: "'Poppins',Sans-serif",
//                         }}
//                       >
//                         <input
//                           type="radio"
//                           value="epravesh"
//                           checked={selectedOption === "epravesh"}
//                           onChange={handleOptionChange}
//                         />
//                         Epravesh Fee Receipt
//                       </label>
//                       <label
//                         style={{
//                           marginRight: "20px",
//                           fontFamily: "'Poppins',Sans-serif",
//                         }}
//                       >
//                         <input
//                           style={{ fontFamily: "'Poppins',Sans-serif" }}
//                           type="radio"
//                           value="enrollment"
//                           checked={selectedOption === "enrollment"}
//                           onChange={handleOptionChange}
//                         />
//                         Provisional Fee Receipt
//                       </label>
//                       <label>
//                         <input
//                           style={{ fontFamily: "'Poppins',Sans-serif" }}
//                           type="radio"
//                           value="enrollment"
//                           checked={selectedOption === "enrollment"}
//                           onChange={handleOptionChange}
//                         />
//                         Enrollment Fee Receipt
//                       </label>
//                     </div>
//                   </div>
//                   <hr></hr>
//                   {/* E-Pravesh Receipt */}
//                   {selectedOption === "epravesh" && (
//                     <CardContent className="shadow p-3 bg-body rounded">
//                       <h4
//                         className="h4d"
//                         style={{
//                           color: "#0D47A1",
//                           fontFamily: "'Poppins',Sans-serif",
//                         }}
//                       >
//                         E-Pravesh Registration Receipt
//                       </h4>
//                       <h6 className="smallh">
//                         Check the below details to download your receipt.{" "}
//                       </h6>
//                       <Form
//                         style={{
//                           marginTop: "1%",
//                           fontFamily: "'Poppins',Sans-serif",
//                           padding: "1px",
//                         }}
//                       >
//                         <Row>
//                           <Form.Group
//                             as={Col}
//                             controlId="courseType"
//                             className="form-group-custom"
//                           >
//                             <Form.Label>Course Type</Form.Label>
//                             <Form.Control
//                               className="form-input"
//                               type="text"
//                               name="courseType"
//                               value={
//                                 approvedStudents.epravesh[0].courseType || ""
//                               }
//                             />
//                           </Form.Group>
//                           <Form.Group
//                             as={Col}
//                             controlId="courseName"
//                             className="form-group-custom"
//                           >
//                             <Form.Label>Course Name</Form.Label>
//                             <Form.Control
//                               className="form-input"
//                               type="text"
//                               name="courseName"
//                               value={
//                                 approvedStudents.epravesh[0].courseName || ""
//                               }
//                             />
//                           </Form.Group>
//                           <Form.Group
//                             as={Col}
//                             controlId="courseBranch"
//                             className="form-group-custom"
//                           >
//                             <Form.Label>Course Branch</Form.Label>
//                             <Form.Control
//                               className="form-input"
//                               type="text"
//                               name="courseBranch"
//                               value={
//                                 approvedStudents.epravesh[0].courseBranch || ""
//                               }
//                             />
//                           </Form.Group>
//                         </Row>

//                         <Row>
//                           <Form.Group
//                             as={Col}
//                             controlId="name"
//                             className="form-group-custom"
//                           >
//                             <Form.Label>Name</Form.Label>
//                             <Form.Control
//                               className="form-input"
//                               type="text"
//                               name="name"
//                               value={approvedStudents.epravesh[0].name || ""}
//                             />
//                           </Form.Group>
//                           <Form.Group
//                             as={Col}
//                             controlId="fathersname"
//                             className="form-group-custom"
//                           >
//                             <Form.Label>Father's Name</Form.Label>
//                             <Form.Control
//                               className="form-input"
//                               type="text"
//                               name="fathersname"
//                               value={
//                                 approvedStudents.epravesh[0].fathersname || ""
//                               }
//                             />
//                           </Form.Group>

//                           <Form.Group as={Col} controlId="randomId">
//                             <Form.Label>Student ID</Form.Label>
//                             <Form.Control
//                               className="form-input"
//                               type="text"
//                               name="randomId"
//                               value={
//                                 approvedStudents.epravesh[0].randomId || ""
//                               }
//                             />
//                           </Form.Group>
//                         </Row>

//                         <Row>
//                           <Form.Group
//                             as={Col}
//                             controlId="email"
//                             className="form-group-custom"
//                           >
//                             <Form.Label>Email</Form.Label>
//                             <Form.Control
//                               className="form-input"
//                               type="email"
//                               name="email"
//                               value={approvedStudents.epravesh[0].email || ""}
//                             />
//                           </Form.Group>
//                           <Form.Group
//                             as={Col}
//                             controlId="mobile"
//                             className="form-group-custom"
//                           >
//                             <Form.Label>Mobile</Form.Label>
//                             <Form.Control
//                               className="form-input"
//                               type="text"
//                               name="mobile"
//                               value={approvedStudents.epravesh[0].mobile || ""}
//                             />
//                           </Form.Group>
//                           <Form.Group as={Col} controlId="paymentMode">
//                             <Form.Label>Payment Mode</Form.Label>
//                             <Form.Control
//                               className="form-input"
//                               type="text"
//                               name="paymentMode"
//                               value={
//                                 approvedStudents.epravesh[0].paymentMode || ""
//                               }
//                             />
//                           </Form.Group>
//                         </Row>
//                         <Row>
//                           <Form.Group
//                             as={Col}
//                             controlId="txnId"
//                             className="form-group-custom"
//                           >
//                             <Form.Label>Transaction ID</Form.Label>
//                             <Form.Control
//                               className="form-input"
//                               type="text"
//                               name="txnId"
//                               value={approvedStudents.epravesh[0].txnId || ""}
//                             />
//                           </Form.Group>
//                           <Form.Group
//                             as={Col}
//                             controlId="orderId"
//                             className="form-group-custom"
//                           >
//                             <Form.Label>Order ID</Form.Label>
//                             <Form.Control
//                               className="form-input"
//                               type="text"
//                               name="orderId"
//                               value={approvedStudents.epravesh[0].orderId || ""}
//                             />
//                           </Form.Group>

//                           <Form.Group
//                             as={Col}
//                             controlId="bankTxnId"
//                             className="form-group-custom"
//                           >
//                             <Form.Label>Bank Transaction ID</Form.Label>
//                             <Form.Control
//                               className="form-input"
//                               type="text"
//                               name="bankTxnId"
//                               value={
//                                 approvedStudents.epravesh[0].bankTxnId || ""
//                               }
//                             />
//                           </Form.Group>
//                         </Row>
//                         <Row>
//                           <Form.Group
//                             as={Col}
//                             controlId="txnAmount"
//                             className="form-group-custom"
//                           >
//                             <Form.Label>Transaction Amount</Form.Label>
//                             <Form.Control
//                               className="form-input"
//                               type="text"
//                               name="txnAmount"
//                               value={
//                                 approvedStudents.epravesh[0].txnAmount || ""
//                               }
//                             />
//                           </Form.Group>

//                           <Form.Group
//                             as={Col}
//                             controlId="txnDate"
//                             className="form-group-custom"
//                           >
//                             <Form.Label>Transaction Date</Form.Label>
//                             <Form.Control
//                               className="form-input"
//                               type="dd-mm-yyyy"
//                               name="txnDate"
//                               value={approvedStudents.epravesh[0].txnDate || ""}
//                             />
//                           </Form.Group>

//                           <Form.Group
//                             as={Col}
//                             controlId="status"
//                             className="form-group-custom"
//                           >
//                             <Form.Label>Status</Form.Label>
//                             <Form.Control
//                               className="form-input"
//                               type="text"
//                               name="status"
//                               value={
//                                 approvedStudents.epravesh[0].status ||
//                                 "TXN_SUCCESS"
//                               }
//                             />
//                           </Form.Group>
//                         </Row>

//                         <Button
//                           type="submit"
//                           style={{
//                             marginLeft: "10px",
//                             marginTop: "10px",
//                             background: "none",
//                             border: "2px solid #0d47a1",
//                             color: "#0d47a1",
//                             padding: "6px 12px",
//                             borderRadius: "10px",
//                             fontFamily: "'Poppins', sans-serif",
//                             fontSize: "0.9rem",
//                             transition: "all 0.3s ease",
//                           }}
//                           onClick={generatePDF}
//                           onMouseOver={(e) => {
//                             e.target.style.backgroundColor = "#0d47a1";
//                             e.target.style.color = "#fff";
//                             e.target.style.boxShadow =
//                               "0 0 15px rgba(0, 121, 107, 0.5)";
//                           }}
//                           onMouseOut={(e) => {
//                             e.target.style.backgroundColor = "transparent";
//                             e.target.style.color = "#0d47a1";
//                             e.target.style.boxShadow = "none";
//                           }}
//                         >
//                           Download Receipt
//                         </Button>
//                       </Form>
//                     </CardContent>
//                   )}
//                 </>
//               )}

//             {/* Enrollment Receipt */}
//             {isEnrolledPaid &&
//               approvedStudents.enrollment &&
//               approvedStudents.enrollment.length > 0 &&
//               selectedOption === "enrollment" && (
//                 <CardContent className="shadow p-3 bg-body rounded">
//                   <h4
//                     className="h4d"
//                     style={{
//                       color: "#0D47A1",
//                       fontFamily: "'Poppins',Sans-serif",
//                     }}
//                   >
//                     Enrollment Fee Payment Receipt
//                   </h4>
//                   <h6 className="smallh">
//                     Check the below details to download your receipt.{" "}
//                   </h6>
//                   <Form
//                     className="forum"
//                     style={{
//                       marginTop: "1%",
//                       fontFamily: "'Poppins',Sans-serif",
//                     }}
//                   >
//                     <Row>
//                       <Form.Group
//                         as={Col}
//                         controlId="courseType"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Course Type</Form.Label>
//                         <Form.Control
//                           className="form-input"
//                           type="text"
//                           name="courseType"
//                           value={
//                             approvedStudents.enrollment[0].courseType || ""
//                           }
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="courseName"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Course Name</Form.Label>
//                         <Form.Control
//                           className="form-input"
//                           type="text"
//                           name="courseName"
//                           value={
//                             approvedStudents.enrollment[0].courseName || ""
//                           }
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="courseBranch"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Course Branch</Form.Label>
//                         <Form.Control
//                           className="form-input"
//                           type="text"
//                           name="courseBranch"
//                           value={
//                             approvedStudents.enrollment[0].courseBranch || ""
//                           }
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row>
//                       <Form.Group as={Col} controlId="randomId">
//                         <Form.Label>Student ID</Form.Label>
//                         <Form.Control
//                           className="form-input"
//                           type="text"
//                           name="randomId"
//                           value={approvedStudents.enrollment[0].randomId || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="name"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Name</Form.Label>
//                         <Form.Control
//                           className="form-input"
//                           type="text"
//                           name="name"
//                           value={approvedStudents.enrollment[0].name || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="fathersname"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Father's Name</Form.Label>
//                         <Form.Control
//                           className="form-input"
//                           type="text"
//                           name="fathersname"
//                           value={
//                             approvedStudents.enrollment[0].fathersname || ""
//                           }
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row>
//                       <Form.Group
//                         as={Col}
//                         controlId="email"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Email</Form.Label>
//                         <Form.Control
//                           className="form-input"
//                           type="email"
//                           name="email"
//                           value={approvedStudents.enrollment[0].email || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="mobile"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Mobile</Form.Label>
//                         <Form.Control
//                           className="form-input"
//                           type="text"
//                           name="mobile"
//                           value={approvedStudents.enrollment[0].mobile || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group as={Col} controlId="paymentMode">
//                         <Form.Label>Payment Mode</Form.Label>
//                         <Form.Control
//                           className="form-input"
//                           type="text"
//                           name="paymentMode"
//                           value={
//                             approvedStudents.enrollment[0].paymentMode || ""
//                           }
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row>
//                       <Form.Group
//                         as={Col}
//                         controlId="orderId"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Order ID</Form.Label>
//                         <Form.Control
//                           className="form-input"
//                           type="text"
//                           name="orderId"
//                           value={approvedStudents.enrollment[0].orderId || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="txnId"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Transaction ID</Form.Label>
//                         <Form.Control
//                           className="form-input"
//                           type="text"
//                           name="txnId"
//                           value={approvedStudents.enrollment[0].txnId || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group as={Col} controlId="bankTxnId">
//                         <Form.Label>Bank Transaction ID</Form.Label>
//                         <Form.Control
//                           className="form-input"
//                           type="text"
//                           name="bankTxnId"
//                           value={approvedStudents.enrollment[0].bankTxnId || ""}
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row>
//                       <Form.Group
//                         as={Col}
//                         controlId="session"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Admission Session</Form.Label>
//                         <Form.Control
//                           className="form-input"
//                           type="text"
//                           name="session"
//                           value={
//                             approvedStudents.enrollment[0].session || "2024"
//                           }
//                         />
//                       </Form.Group>
//                       <Form.Group as={Col} controlId="txnAmount">
//                         <Form.Label>Transaction Amount</Form.Label>
//                         <Form.Control
//                           className="form-input"
//                           type="text"
//                           name="txnAmount"
//                           value={approvedStudents.enrollment[0].txnAmount || ""}
//                         />
//                       </Form.Group>

//                       <Form.Group as={Col} controlId="status">
//                         <Form.Label>Status</Form.Label>
//                         <Form.Control
//                           className="form-input"
//                           type="text"
//                           name="status"
//                           value={
//                             approvedStudents.enrollment[0].status ||
//                             "TXN_SUCCESS"
//                           }
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Button
//                       type="submit"
//                       style={{
//                         marginLeft: "10px",
//                         marginTop: "10px",
//                         background: "none",
//                         border: "2px solid #0d47a1",
//                         color: "#0d47a1",
//                         padding: "6px 12px",
//                         borderRadius: "10px",
//                         fontFamily: "'Poppins', sans-serif",
//                         fontSize: "0.9rem",
//                         transition: "all 0.3s ease",
//                       }}
//                       onClick={generatePDF1}
//                       onMouseOver={(e) => {
//                         e.target.style.backgroundColor = "#0d47a1";
//                         e.target.style.color = "#fff";
//                         e.target.style.boxShadow =
//                           "0 0 15px rgba(0, 121, 107, 0.5)";
//                       }}
//                       onMouseOut={(e) => {
//                         e.target.style.backgroundColor = "transparent";
//                         e.target.style.color = "#0d47a1";
//                         e.target.style.boxShadow = "none";
//                       }}
//                     >
//                       Download Receipt
//                     </Button>
//                   </Form>
//                 </CardContent>
//               )}
//           </>
//         )}
   
//     </>
//   );
// };

// export default EStudent_Slip;


import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { Form, Row, Col, Button } from "react-bootstrap";
import { CardContent } from "@mui/material";
import jsPDF from "jspdf";
import StudentDashboard from "../../../AdminComponent/StudentDashboard";

const EStudent_Slip = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [approvedStudents, setApprovedStudents] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const userData = JSON.parse(sessionStorage.getItem("currentUser"));
  const isEnrolledPaid = userData.isEnrollPaid;
  const studentId = userData._id;

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setApprovedStudents({}); // Reset previous data
    setError(null); // Reset error
    fetchData(event.target.value); // Fetch data for selected option
  };

  const fetchData = async (option) => {
    setLoading(true);
    try {
      let url = "";
      if (option === "epravesh") {
        url = "https://sssutms.ac.in/apitest/student/get-payment-details2"; // Epravesh API
      } else if (option === "provisional") {
        url = "https://sssutms.ac.in/apitest/student/get-provisional-details"; // Provisional API (dummy, replace with actual)
      } else if (option === "enrollment") {
        url = "https://sssutms.ac.in/apitest/student/get-enrollment-details"; // Enrollment API (dummy, replace with actual)
      }

      const { data } = await axios.get(url, {
        params: { id: studentId },
      });

      const paymentDetails = {};

      if (option === "epravesh" && data.Epravesh_payment_details) {
        paymentDetails.epravesh = data.Epravesh_payment_details;
      } else if (option === "provisional" && data.provisional_details) {
        paymentDetails.provisional = data.provisional_details; // Adjust based on actual API response
      } else if (option === "enrollment" && data.enrollment_fee_details) {
        paymentDetails.enrollment = [data.enrollment_fee_details];
      }

      setApprovedStudents(paymentDetails);
      setLoading(false);
    } catch (err) {
      console.error(`Error while fetching ${option} details:`, err);
      setError(`Failed to fetch ${option} details`);
      setLoading(false);
    }
  };

  const logo = "https://cdn.sssutms.ac.in/media/static/images/sssutms.jpg";

  const generatePDF = (type) => {
    const doc = new jsPDF();
    doc.addImage(logo, "JPEG", 3, 5, 30, 28);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.text("SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL ", 118, 10, {
      align: "center",
    });
    doc.text("SCIENCES SEHORE", 118, 17, { align: "center" });
    doc.setFontSize(13);
    doc.text(
      "[Established Under Govt. of (M.P.) & Registered UGC 2(F), 1956]",
      118,
      24,
      { align: "center" }
    );
    doc.text(
      "Sh-18 Bhopal-Indore Road, Opposite Oilfed Plant, Pachama,",
      118,
      30,
      { align: "center" }
    );
    doc.setFontSize(14);
    doc.setTextColor(255, 0, 0);

    let title = "";
    let dataSource = [];
    if (type === "epravesh") {
      title = "EPravesh Fee Receipt";
      dataSource = approvedStudents.epravesh;
    } else if (type === "provisional") {
      title = "Provisional Fee Receipt";
      dataSource = approvedStudents.provisional;
    } else if (type === "enrollment") {
      title = "Enrollment Fee Receipt";
      dataSource = approvedStudents.enrollment;
    }

    doc.text(title, 118, 40, { align: "center" });
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);

    let yPos = 60;
    const lineHeight = 10;

    doc.text(`Name of Candidate  :  ${dataSource[0].name}`, 10, yPos);
    yPos += lineHeight;
    doc.text(`Father's Name      :  ${dataSource[0].fathersname}`, 10, yPos);
    yPos += lineHeight;
    doc.text(`Email             :  ${dataSource[0].email}`, 10, yPos);
    yPos += lineHeight;
    doc.text(`Mobile            :  ${dataSource[0].mobile}`, 10, yPos);
    yPos += lineHeight;
    doc.text(`Course Type       :  ${dataSource[0].courseType}`, 10, yPos);
    yPos += lineHeight;
    doc.text(`Course Name       :  ${dataSource[0].courseName}`, 10, yPos);
    yPos += lineHeight;
    doc.text(`Course Branch     :  ${dataSource[0].courseBranch}`, 10, yPos);
    yPos += lineHeight;
    doc.text(`TXN ID            :  ${dataSource[0].txnId}`, 10, yPos);
    yPos += lineHeight;
    doc.text(`ORDER ID          :  ${dataSource[0].orderId}`, 10, yPos);
    yPos += lineHeight;
    doc.text(`PAYMENT MODE      :  ${dataSource[0].paymentMode}`, 10, yPos);
    yPos += lineHeight;
    doc.text(`BANKTXN ID        :  ${dataSource[0].bankTxnId}`, 10, yPos);
    yPos += lineHeight;
    doc.text(`Paid Amount       :  ${dataSource[0].txnAmount}`, 10, yPos);
    yPos += lineHeight;
    doc.text(`Date & Time       :  ${dataSource[0].txnDate}`, 10, yPos);

    doc.save(`${dataSource[0].name} ${title}`);
  };

  return (
    <>
      <StudentDashboard />

      <style>
        {`
          * {
            font-family: 'Poppins', sans-serif;
          }
          .radd {
            margin-left: 120px;
            margin-top: 80px;
            position: sticky;
            top: 0;
            background-color: #fff;
            z-index: 1000;
            padding-bottom: 20px;
          }
          .form-input {
            padding: 5px;
            font-family: 'Poppins', sans-serif;
            font-size: 15px;
            border-color: #0d47a1;
          }
          .smallh {
            font-size: 13px;
            color: #16325B;
            margin-top: -20px;
          }
          .shadow {
            margin-top: 10px;
            margin-left: 8%;
          }
          .data-not-found {
            text-align: center;
            margin-top: 20px;
            color: #ff0000;
            font-size: 18px;
          }
          @media screen and (max-width: 480px) {
            .radd {
              margin-left: 20px;
              margin-top: 80px;
            }
            .shadow {
              margin-top: -60px;
              margin-left: 0%;
            }
            .h4d {
              font-weight: 700;
              height: 60px;
              border-radius: 10px;
              color: black;
              font-size: 18px;
              padding: 5px;
              margin-top: 70px;
            }
            .form-group-custom {
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
        `}
      </style>

      <div className="radd">
        <h4 style={{ fontFamily: "'Poppins',Sans-serif" }}>
          Select Fee Receipt Type :
        </h4>
        <div>
          <label
            style={{
              marginRight: "20px",
              fontFamily: "'Poppins',Sans-serif",
            }}
          >
            <input
              type="radio"
              value="epravesh"
              checked={selectedOption === "epravesh"}
              onChange={handleOptionChange}
            />
            Epravesh Fee Receipt
          </label>
          <label
            style={{
              marginRight: "20px",
              fontFamily: "'Poppins',Sans-serif",
            }}
          >
            <input
              type="radio"
              value="provisional"
              checked={selectedOption === "provisional"}
              onChange={handleOptionChange}
            />
            Provisional Fee Receipt
          </label>
          <label
            style={{
              marginRight: "20px",
              fontFamily: "'Poppins',Sans-serif",
            }}
          >
            <input
              type="radio"
              value="enrollment"
              checked={selectedOption === "enrollment"}
              onChange={handleOptionChange}
            />
            Enrollment Fee Receipt
          </label>
        </div>
      </div>

      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "200px", marginTop: "40px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : error ? (
        <div className="data-not-found">{error}</div>
      ) : !selectedOption ? (
        <div className="data-not-found">Please select a receipt type</div>
      ) : (
        <>
          {/* E-Pravesh Receipt */}
          {selectedOption === "epravesh" && (
            approvedStudents.epravesh && approvedStudents.epravesh.length > 0 ? (
              <CardContent className="shadow p-3 bg-body rounded">
                <h4
                  className="h4d"
                  style={{
                    color: "#0D47A1",
                    fontFamily: "'Poppins',Sans-serif",
                  }}
                >
                  E-Pravesh Registration Receipt
                </h4>
                {/* <h6 className="smallh">
                  Check the below details to download your receipt.
                </h6> */}
                <Form
                  style={{
                    marginTop: "1%",
                    fontFamily: "'Poppins',Sans-serif",
                    padding: "1px",
                  }}
                >
                  <Row>
                    <Form.Group as={Col} controlId="courseType" className="form-group-custom">
                      <Form.Label>Course Type</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].courseType || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="courseName" className="form-group-custom">
                      <Form.Label>Course Name</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].courseName || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="courseBranch" className="form-group-custom">
                      <Form.Label>Course Branch</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].courseBranch || ""}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="name" className="form-group-custom">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].name || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="fathersname" className="form-group-custom">
                      <Form.Label>Father's Name</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].fathersname || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="randomId">
                      <Form.Label>Student ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].randomId || ""}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="email" className="form-group-custom">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="email"
                        value={approvedStudents.epravesh[0].email || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="mobile" className="form-group-custom">
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].mobile || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="paymentMode">
                      <Form.Label>Payment Mode</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].paymentMode || ""}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="txnId" className="form-group-custom">
                      <Form.Label>Transaction ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].txnId || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="orderId" className="form-group-custom">
                      <Form.Label>Order ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].orderId || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="bankTxnId" className="form-group-custom">
                      <Form.Label>Bank Transaction ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].bankTxnId || ""}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="txnAmount" className="form-group-custom">
                      <Form.Label>Transaction Amount</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].txnAmount || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="txnDate" className="form-group-custom">
                      <Form.Label>Transaction Date</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].txnDate || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="status" className="form-group-custom">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].status || "TXN_SUCCESS"}
                      />
                    </Form.Group>
                  </Row>
                  <Button
                    type="button"
                    style={{
                      marginLeft: "10px",
                      marginTop: "10px",
                      background: "none",
                      border: "2px solid #0d47a1",
                      color: "#0d47a1",
                      padding: "6px 12px",
                      borderRadius: "10px",
                      fontFamily: "'Poppins', sans-serif",
                      fontSize: "0.9rem",
                      transition: "all 0.3s ease",
                    }}
                    onClick={() => generatePDF("epravesh")}
                    onMouseOver={(e) => {
                      e.target.style.backgroundColor = "#0d47a1";
                      e.target.style.color = "#fff";
                      e.target.style.boxShadow = "0 0 15px rgba(0, 121, 107, 0.5)";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.backgroundColor = "transparent";
                      e.target.style.color = "#0d47a1";
                      e.target.style.boxShadow = "none";
                    }}
                  >
                    Download Receipt
                  </Button>
                </Form>
              </CardContent>
            ) : (
              <div className="data-not-found">Epravesh Data Not Found</div>
            )
          )}

          {/* Provisional Receipt */}
          {selectedOption === "provisional" && (
            approvedStudents.provisional && approvedStudents.provisional.length > 0 ? (
              <CardContent className="shadow p-3 bg-body rounded">
                <h4
                  className="h4d"
                  style={{
                    color: "#0D47A1",
                    fontFamily: "'Poppins',Sans-serif",
                  }}
                >
                  Provisional Fee Receipt
                </h4>
                <h6 className="smallh">
                  Check the below details to download your receipt.
                </h6>
                <Form
                  style={{
                    marginTop: "1%",
                    fontFamily: "'Poppins',Sans-serif",
                    padding: "1px",
                  }}
                >
                  <Row>
                    <Form.Group as={Col} controlId="courseType" className="form-group-custom">
                      <Form.Label>Course Type</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].courseType || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="courseName" className="form-group-custom">
                      <Form.Label>Course Name</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].courseName || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="courseBranch" className="form-group-custom">
                      <Form.Label>Course Branch</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].courseBranch || ""}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="name" className="form-group-custom">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].name || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="fathersname" className="form-group-custom">
                      <Form.Label>Father's Name</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].fathersname || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="randomId">
                      <Form.Label>Student ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].randomId || ""}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="email" className="form-group-custom">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="email"
                        value={approvedStudents.provisional[0].email || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="mobile" className="form-group-custom">
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].mobile || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="paymentMode">
                      <Form.Label>Payment Mode</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].paymentMode || ""}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="txnId" className="form-group-custom">
                      <Form.Label>Transaction ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].txnId || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="orderId" className="form-group-custom">
                      <Form.Label>Order ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].orderId || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="bankTxnId" className="form-group-custom">
                      <Form.Label>Bank Transaction ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].bankTxnId || ""}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="txnAmount" className="form-group-custom">
                      <Form.Label>Transaction Amount</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].txnAmount || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="txnDate" className="form-group-custom">
                      <Form.Label>Transaction Date</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].txnDate || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="status" className="form-group-custom">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].status || "TXN_SUCCESS"}
                      />
                    </Form.Group>
                  </Row>
                  <Button
                    type="button"
                    style={{
                      marginLeft: "10px",
                      marginTop: "10px",
                      background: "none",
                      border: "2px solid #0d47a1",
                      color: "#0d47a1",
                      padding: "6px 12px",
                      borderRadius: "10px",
                      fontFamily: "'Poppins', sans-serif",
                      fontSize: "0.9rem",
                      transition: "all 0.3s ease",
                    }}
                    onClick={() => generatePDF("provisional")}
                    onMouseOver={(e) => {
                      e.target.style.backgroundColor = "#0d47a1";
                      e.target.style.color = "#fff";
                      e.target.style.boxShadow = "0 0 15px rgba(0, 121, 107, 0.5)";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.backgroundColor = "transparent";
                      e.target.style.color = "#0d47a1";
                      e.target.style.boxShadow = "none";
                    }}
                  >
                    Download Receipt
                  </Button>
                </Form>
              </CardContent>
            ) : (
              <div className="data-not-found">Provisional Data Not Found</div>
            )
          )}

          {/* Enrollment Receipt */}
          {selectedOption === "enrollment" && (
            isEnrolledPaid && approvedStudents.enrollment && approvedStudents.enrollment.length > 0 ? (
              <CardContent className="shadow p-3 bg-body rounded">
                <h4
                  className="h4d"
                  style={{
                    color: "#0D47A1",
                    fontFamily: "'Poppins',Sans-serif",
                  }}
                >
                  Enrollment Fee Payment Receipt
                </h4>
                <h6 className="smallh">
                  Check the below details to download your receipt.
                </h6>
                <Form
                  style={{
                    marginTop: "1%",
                    fontFamily: "'Poppins',Sans-serif",
                  }}
                >
                  <Row>
                    <Form.Group as={Col} controlId="courseType" className="form-group-custom">
                      <Form.Label>Course Type</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].courseType || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="courseName" className="form-group-custom">
                      <Form.Label>Course Name</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].courseName || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="courseBranch" className="form-group-custom">
                      <Form.Label>Course Branch</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].courseBranch || ""}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="name" className="form-group-custom">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].name || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="fathersname" className="form-group-custom">
                      <Form.Label>Father's Name</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].fathersname || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="randomId">
                      <Form.Label>Student ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].randomId || ""}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="email" className="form-group-custom">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="email"
                        value={approvedStudents.enrollment[0].email || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="mobile" className="form-group-custom">
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].mobile || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="paymentMode">
                      <Form.Label>Payment Mode</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].paymentMode || ""}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="txnId" className="form-group-custom">
                      <Form.Label>Transaction ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].txnId || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="orderId" className="form-group-custom">
                      <Form.Label>Order ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].orderId || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="bankTxnId" className="form-group-custom">
                      <Form.Label>Bank Transaction ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].bankTxnId || ""}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="txnAmount" className="form-group-custom">
                      <Form.Label>Transaction Amount</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].txnAmount || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="txnDate" className="form-group-custom">
                      <Form.Label>Transaction Date</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].txnDate || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="status" className="form-group-custom">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].status || "TXN_SUCCESS"}
                      />
                    </Form.Group>
                  </Row>
                  <Button
                    type="button"
                    style={{
                      marginLeft: "10px",
                      marginTop: "10px",
                      background: "none",
                      border: "2px solid #0d47a1",
                      color: "#0d47a1",
                      padding: "6px 12px",
                      borderRadius: "10px",
                      fontFamily: "'Poppins', sans-serif",
                      fontSize: "0.9rem",
                      transition: "all 0.3s ease",
                    }}
                    onClick={() => generatePDF("enrollment")}
                    onMouseOver={(e) => {
                      e.target.style.backgroundColor = "#0d47a1";
                      e.target.style.color = "#fff";
                      e.target.style.boxShadow = "0 0 15px rgba(0, 121, 107, 0.5)";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.backgroundColor = "transparent";
                      e.target.style.color = "#0d47a1";
                      e.target.style.boxShadow = "none";
                    }}
                  >
                    Download Receipt
                  </Button>
                </Form>
              </CardContent>
            ) : (
              <div className="data-not-found">Enrollment Data Not Found</div>
            )
          )}
        </>
      )}
    </>
  );
};

export default EStudent_Slip;