// import React from "react";
// import Nav from "../../HomePage/Nav";
// import Footerpage from "../../HomePage/Footerpage";
// // import Trans from "../../images/Amemities/Transprotation.jpg";
// import { Link } from "react-router-dom";

// function EContent() {
//   return (
//     <>
//       <Nav />
//       <br />
//       <style>
//         {`
    
    
//     .section_all {
//         position: relative;
//         padding-top: 20px;
//         margin-left:-100px;
//         padding-bottom: 80px;
//         min-height: 100vh;
    
//     }
   
    
//     .about_icon i {
//         font-size: 22px;
//         height: 65px;
//         width: 65px;
//         line-height: 65px;
//         display: inline-block;
//         background: #fff;
//         border-radius: 35px;
//         color: #00bd2a;
//         box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
         
//     }
    
   
//     .about_icon span {
//         position: relative;
//         top: -10px;
        
//     }
    
//     .about_content_box_all {
//         padding: 28px;
//     }
// h1 {
//   position: relative;
//   padding: 0;
//   margin: 0;
//   font-family: "Raleway", sans-serif;
//   font-weight: 700;
//   font-size: 30px;
//   color: #243642;
//   -webkit-transition: all 0.4s ease 0s;
//   -o-transition: all 0.4s ease 0s;
//   transition: all 0.4s ease 0s;
// }


// h1 em {
//   font-style: normal;
//   font-weight: 600;
// }
// .two h1 {
//   text-transform: capitalize;
// }
// .two h1:before {
//   position: absolute;
//   left: 0;
//   bottom: 0;
//   width: 60px;
//   height: 2px;
//   content: "";
//   background-color: #F09319;
// }


// .alt-two h1 {
//   text-align:center;
// }

//  .uul {
//             list-style-type: none;
//             padding: 0;
//             margin: 0;
//             list-style-type: square;
//             margin-left: 10px;
//           }
// .alt-two h1:before {
//   left:50%; margin-left:-30px;
// }


// .lli{
// font-size:16px; 
// margin-left:40px;
// margin-top:10px;
//  list-style-type: none; /* Removes bullets */}


// .list-icon li i {
//     color: #555555;
//     font-size: 14px;
//     height: 28px;
//     line-height: 28px;
//     margin: 5px 10px 5px 0;
//     text-align: center;
//     width: 28px;
 
// }
// .fa {
//     display: inline-block;
//     font: normal normal normal 14px / 1 FontAwesome;
//     font-size: inherit;
//     text-rendering: auto;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
// }

//    .list-icon {
//         list-style-type: none; /* Removes bullets */
//         padding: 0; 
//         margin: 0;
//         display: flex;
//         flex-direction: column; /* Default to vertical */
        
//     }


//    @media (max-width: 480px) {
//    .section_all{
//    margin-left:6px;
//    padding:5px;}
   
//     .list-icon {
//     margin-left:-50px;
//             flex-direction: row; /* Makes the list horizontal */
//             flex-wrap: wrap; /* Allows wrapping if items are too wide */
//             // justify-content: space-between; /* Adds spacing between items */
//               flex: 1 1 50%; /* Makes each item take about 45% width */
                
//         }
   
//    }
        
    
//     `}
//       </style>
//       <section className="section_all " id="about">
//         <div className="container">
//           <div class="two">
//             <h1>E - Content</h1>
//           </div>
//           <br></br>

//           <div className="row">
//             <div className="col-lg-9">
//               <p
//                 className="mt-3"
//                 style={{ textAlign: "justify", color: "black" }}
//               >
//                 <br />

//                 <div className="tann">
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link
//                       to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS Vol 6 Issue 2 16 to 30 November 2023.pdf"
//                       target="_blank"
//                     >
//                       <b> Vol 6 Issue 2 16 to 30 November 2023 </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link
//                       to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS Vol 6 Issue 1 01 to 15 November 2023.pdf"
//                       target="_blank"
//                     >
//                       <b> - Vol 6 Issue 1 01 to 15 November 2023 </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link
//                       to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/elarning_opt.pdf"
//                       target="_blank"
//                     >
//                       <b> Vol 5 Issue 2 16 to 31 October 2023 </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link
//                       to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS Vol 5 Issue 1 01 to 15 October 2023.pdf"
//                       target="_blank"
//                     >
//                       <b> Vol 5 Issue 1 01 to 15 October 2023 </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link
//                       to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS Vol 4 Issue 2 16 to 30 Sep 2023.pdf"
//                       target="_blank"
//                     >
//                       <b> - Vol 4 Issue 2 16 to 30 Sep 2023 </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS Vol 4 Issue 1 01 to 15 September 2023.pdf" target="_blank" >
//                       <b> - Vol 4 Issue 1 01 to 15 September 2023 </b>
//                     </Link>
//                   </h5>
                
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS Vol 3 Issue 2 01 to 15 August 2023.pdf" target="_blank" >
//                       <b> - Vol 3 Issue 2 16 to 31 August 2023 </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS Vol 3 Issue 1 01 to 15 August 2023.pdf"  target="_blank">
//                       <b> - Vol 3 Issue 1 01 to 15 August 2023 </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS Vol 2 Issue 2 16 to 31 July 2023.pdf"
//                       target="_blank">
//                       <b> - Vol 2 Issue 2 16 to 31 July 2023 </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS Vol 2 Issue 1 01 to 15 July 2023.pdf"
//                       target="_blank">
//                       <b> - Vol 02/ Issue 1/ 01 -15 July 2023 </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS Vol 1 Issue 2 16 to 30 June 2023.pdf"
//                       target="_blank">
//                       <b> - Vol.01/Issue 02/16-30 June2023 </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS (1).pdf"
//                       target="_blank">
//                       <b> - Vol.01/Issue 1/1-15 June2023 </b>
//                     </Link>
//                   </h5>{" "}
//                   <br />
//                   <h5
//                     style={{
//                       color: "#bc6c25",
//                       textAlign: "center",
//                       textDecoration: "underline",
//                     }}
//                   >
//                     <b>SSSUTMS E-Report</b>
//                   </h5>{" "}
//                   <br />
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/NASSCOM Visit.pdf"
//                       target="_blank">
//                       <b> Visit of NASSCOM Regional Head </b>
//                     </Link>
//                   </h5>{" "}
//                   <br />
//                   <h5
//                     style={{
//                       color: "#bc6c25",
//                       textAlign: "center",
//                       textDecoration: "underline",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <b>Students Forum</b>
//                   </h5>{" "}
//                   <br />
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/Preamble 2.pdf"
//                       target="_blank">
//                       <b> Cultural and Technical </b>
//                     </Link>
//                   </h5>{" "}
//                   <br />
//                   <h5
//                     style={{
//                       color: "#bc6c25",
//                       textAlign: "center",
//                       textDecoration: "underline",
//                     }}
//                   >
//                     <b>Online Study Material for Students</b>
//                   </h5>{" "}
//                   <br />
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/definitionandtypesofresearch-100801181630-phpapp02.pdf"
//                       target="_blank">
//                       <b> Research Methodology </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/research Report.pdf"
//                       target="_blank">
//                       <b> Research Report Writing </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SURVEY.pdf"
//                       target="_blank">
//                       <b> Survey Research </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/Women.pdf"
//                       target="_blank">
//                       <b> Women in Sports </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/3- Physical Fitness.pdf"
//                       target="_blank">
//                       <b> Physical Fitness </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/Hypotheses PPT.pdf"
//                       target="_blank">
//                       <b> Hypotheses </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SOURCES_OF_INVALIDITY12.pdf"
//                       target="_blank">
//                       <b> SOURCES OF INVALIDITY </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SAMPLING.pdf"
//                       target="_blank">
//                       <b> SAMPLING </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/Components of Fitness & Tests TO GO ON.pdf"
//                       target="_blank">
//                       <b> Components of Fitness </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/ANATOMY_PHYSIOLOGY.pdf"
//                       target="_blank">
//                       <b> Notes on Anatomy and Physiology </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/Foundation_physical_edcation.pdf"
//                       target="_blank">
//                       <b>
//                         {" "}
//                         Notes on History, Principles & Foundation of Physical
//                         Education{" "}
//                       </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/Complete Guide to Sports Training.pdf"
//                       target="_blank">
//                       <b> Online Complete Guide to Sports Training </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/Computer  System Architecture.pdf"
//                       target="_blank">
//                       <b> Online Notes on Computer System Architecture </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/Digital electronics.pdf"
//                       target="_blank">
//                       <b> Online Notes on Digital electronics </b>
//                     </Link>
//                   </h5>
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/RCC.pdf"
//                       target="_blank">
//                       <b> Online Noes Structural Design & Drawing-I (RCC) </b>
//                     </Link>
//                   </h5>
//                   {/* <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/Building Material.pdf"
//                       target="_blank">
//                       <b> Online Notes on Building Material </b>
//                     </Link>
//                   </h5> */}
//                   <h5
//                     style={{
//                       color: "#023e8a",
//                       marginLeft: "10%",
//                       fontFamily: "Poppins, sans-serif",
//                     }}
//                   >
//                     <i
//                       class="fa fa-share"
//                       aria-hidden="true"
//                       style={{ marginRight: "30px", color: "#9b2226" }}
//                     >
//                       {" "}
//                     </i>{" "}
//                     <Link to="https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/BOOK - SPRITUAL MANAGEMENT.pdf"
//                       target="_blank">
//                       <b> SPRITUAL MANAGEMENT </b>
//                     </Link>
//                   </h5>
//                 </div>
//               </p>
//               <br />
//             </div>
//             <div className="col-lg-3">
            
//               <ul className="list-icon">
//               <h5 style={{textAlign:"center", textDecoration:"underline"}}><b>E - Content</b></h5> 
//               <br/>
              
//                 <Link to = "/forms">
//                 <li className="lli">
//                   <Link to ="/forms">
//                     <i class="fa fa-adjust" aria-hidden="true"></i>Forms
//                   </Link>
//                 </li>
//                 </Link>
//                 <Link to = "/E-Content">
//                 <li className="lli">
//                   <Link to ="/E-Content">
//                     <i class="fa fa-adjust" aria-hidden="true"></i>E-Content
//                   </Link>
//                 </li>
//                 </Link>
             
//               </ul>
//             </div>
//           </div>
//         </div>
//       </section>
//       <Footerpage />
//     </>
//   );
// }

// export default EContent;


import React from 'react'

const E_Content = () => {
  return (
    <div>E_Content</div>
  )
}

export default E_Content