import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  InputAdornment,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import HodDashboard from "../../AdminComponent/HodDashboard";
import * as XLSX from "xlsx";
import axios from "axios";

function TotalFeePaidHod() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [studentData, setStudentData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [branches, setBranches] = useState([]);
  const [allBranches, setAllBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedCollege, setSelectedCollege] = useState("");


  const [searchQuery, setSearchQuery] = useState("");

  const hod = JSON.parse(sessionStorage.getItem("AnkitHOD"));
  const branch1 = hod.Branch || "";
  const course1 = hod.courseName || "";
  const courseName = hod?.courseName || "";
  const college = hod.college || "";
  const associate = hod?.Associated_branch || [];
  const colleges = hod?.colleges || [];

  useEffect(() => {
    const associatedBranches = JSON.parse(
      sessionStorage.getItem("Associated_branch")
    );
    if (associatedBranches) {
      setAllBranches(associatedBranches);
    }
  }, []);

  const fetchData = async (branch = branch1,
    course = course1,
    college = selectedCollege) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_STD_BRANCH_PAID_LIST}${branch}&course=${course}&college=${college}`
      );

      const data = response.data;
      setStudentData(data.students);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleCollegeChange = (event) => {
    const college2 = event.target.value;
    setSelectedCollege(college2);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let filtered = studentData;

    filtered = studentData.filter(
      (student) =>
        student.randomId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.courseType.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.courseName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    if (selectedCollege) {
      filtered = filtered.filter(
        (student) => student.assignedCollege === selectedCollege
      );
    }
    setFilteredData(filtered);
    setPage(0); // Reset to first page on new search
  }, [searchQuery, studentData]);

  // Handler for search input changes
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleChangeBranch = (event) => {
    setSelectedBranch(event.target.value);
  };

  const handleChangeCourse = (e) => {
    const courseName = e.target.value;
    setSelectedCourse(courseName);

    const filteredCourse = associate.find(
      (course) => course.courseName === courseName
    );
    const filteredBranches = filteredCourse
      ? filteredCourse.branches.map((branch) => branch.branchName)
      : [];

    setBranches(filteredBranches);
    setSelectedBranch("");
  };

  const handleSearch = () => {
    fetchData(selectedBranch, selectedCourse);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const excelData = filteredData.map((student) => ({
    Random_Id: student.randomId,
    Random_Password: student.randomPassword,
    Admitted_Date: new Date(student.createdAt).toLocaleDateString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }),
    Name: student.name,
    Enrollment_Number: student.enrollmentNumber || "Not generated",
    Registration_Fee: student.isRegistered,
    Enrollment_Fee: student.isEnrollPaid,
    Enrollment_Form: student.isEnrolled,
    Fathers_Name: student.fathersname,
    Mothers_Name: student.mothersname,
    Email: student.email,
    Mobile: student.mobile,
    Course_Type: student.courseType,
    Course: student.courseName,
    Branch: student.courseBranch,
  }));

  const ExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Enrolled Student");
    XLSX.writeFile(workbook, "Std_Fee_Data.xlsx");
  };

  return (
    <>
      <style>
        {`
        .conttable{

            background-color: #e8e5d5;
            width: 90%;
            margin-left: 120px;
            margin-right: auto;
            margin-top: 80px;
            text-align: center;
            max-width: 1200px;

        }


        .login-input{
        width:200px;
        margin-bottom:20px;
        height:35px;}
        @media screen and (max-width: 500px){
          .custom-card-content{
            width: 100%; /* Adjust the percentage as needed */
            max-width: 1200px; /* Set a maximum width */
            margin: 0 auto; /* Center align */
          }

          h4{
            margin-top:90px;
          }
        }

    `}
      </style>
      <HodDashboard />
      <Box sx={{ width: "90%", marginLeft: "100px", marginTop: "100px" }}>
        <Paper sx={{ width: "100%", overflow: "auto" }}>
          <TextField
            style={{
              float: "right",
              marginRight: "10px",
              marginBottom: "10px",
            }}
            variant="outlined"
            placeholder="Search by Name or ID"
            value={searchQuery}
            onChange={handleSearchChange}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ width: 300 }}
          />
          <Button
            style={{
              float: "right",
              marginRight: "10px",
              marginBottom: "10px",
            }}
            variant="outlined"
            onClick={ExportToExcel}
          >
            Export to Excel
          </Button>
          {/*/////dopdwn select/////////*/}
          <div style={{ display: "flex", alignItems: "center" }}>
            <select
              className="form-select form-select-sm login-input"
              required={true}
              value={selectedCourse}
              onChange={handleChangeCourse} // Update the selected course
            >
              <option value="">Select Course</option>
              {associate.map((course, index) => (
                <option key={index} value={course.courseName}>
                  {course.courseName}
                </option>
              ))}
            </select>

            <select
              className="form-select form-select-sm login-input"
              required={true}
              value={selectedBranch}
              onChange={handleChangeBranch} // Update the selected branch
              disabled={!selectedCourse} // Disable until a course is selected
            >
              <option value="">Select Branch</option>
              {branches.map((branch, index) => (
                <option key={index} value={branch}>
                  {branch}
                </option>
              ))}
            </select>

            {(courseName === "BACHELOR OF EDUCATION" || courseName === "BACHELOR OF PHARMACY") && (
              <>
                <select
                  className="form-select form-select-sm login-input"
                  required={true}
                  value={selectedCollege}
                  onChange={handleCollegeChange}
                >
                  <option value="">Select College</option>
                  {colleges.map((college, index) => (
                    <option key={index} value={college.collegeName}>
                      {college.collegeName}
                    </option>
                  ))}
                </select>
              </>
            )}

            <Button
              type="button"
              variant="contained"
              // color="primary"
              onClick={handleSearch}
              disabled={!selectedBranch}
              sx={{
                height: "35px",
                backgroundColor: "#023047",
                color: "white",
                marginBottom: "20px",
                marginLeft: "3px",
              }}
            >
              Submit
            </Button>
          </div>
          {/*/////////////////////////end////////////////////////////////////*/}
          <TableContainer sx={{ maxHeight: "440px" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{
                      backgroundColor: "#023047",
                      color: "white",
                      position: "sticky",
                      top: 0,
                      zIndex: 2,
                      left: 0,
                    }}
                  >
                    S.No.
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      backgroundColor: "#023047",
                      minWidth: "150px",
                      position: "sticky",
                      top: 0,
                      left: 50,
                      color: "white",
                      zIndex: 2,
                    }}
                  >
                    Enrollment Status
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      backgroundColor: "#023047",
                      position: "sticky",
                      top: 0,
                      color: "white",
                      zIndex: 1,
                    }}
                  >
                    Registration ID
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      backgroundColor: "#023047",
                      position: "sticky",
                      top: 0,
                      color: "white",
                      zIndex: 1,
                    }}
                  >
                    Registration Password
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      backgroundColor: "#023047",
                      position: "sticky",
                      top: 0,
                      color: "white",
                      minWidth: "160px",
                      left: "200px",
                      zIndex: 2,
                    }}
                  >
                    Student Name
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      backgroundColor: "#023047",
                      position: "sticky",
                      top: 0,
                      color: "white",
                      minWidth: "160px",
                      zIndex: 1,
                    }}
                  >
                    Father's Name
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      backgroundColor: "#023047",
                      position: "sticky",
                      top: 0,
                      color: "white",
                      minWidth: "160px",
                      zIndex: 1,
                    }}
                  >
                    Mother's Name
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      backgroundColor: "#023047",
                      position: "sticky",
                      top: 0,
                      color: "white",
                      minWidth: "200px",
                      zIndex: 1,
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      backgroundColor: "#023047",
                      position: "sticky",
                      top: 0,
                      color: "white",
                      minWidth: "120px",
                      zIndex: 1,
                    }}
                  >
                    Contact
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      backgroundColor: "#023047",
                      position: "sticky",
                      top: 0,
                      color: "white",
                      zIndex: 1,
                    }}
                  >
                    Course Type
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      backgroundColor: "#023047",
                      position: "sticky",
                      top: 0,
                      color: "white",
                      minWidth: "200px",
                      zIndex: 1,
                    }}
                  >
                    Course Name
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      backgroundColor: "#023047",
                      position: "sticky",
                      top: 0,
                      color: "white",
                      minWidth: "200px",
                      zIndex: 1,
                    }}
                  >
                    Branch
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      backgroundColor: "#023047",
                      position: "sticky",
                      top: 0,
                      color: "white",
                      minWidth: "200px",
                      zIndex: 1,
                    }}
                  >
                    Assigned Clg
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      backgroundColor: "#023047",
                      position: "sticky",
                      top: 0,
                      color: "white",
                      minWidth: "100px",
                      zIndex: 1,
                    }}
                  >
                    Enrollment Form
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      backgroundColor: "#023047",
                      position: "sticky",
                      top: 0,
                      color: "white",
                      zIndex: 1,
                    }}
                  >
                    Registration Fee
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      backgroundColor: "#023047",
                      position: "sticky",
                      top: 0,
                      color: "white",
                      zIndex: 1,
                    }}
                  >
                    Enrollment Fee
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.length > 0 ? (
                  filteredData
                    .slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((student, index) => (
                      <TableRow key={student.randomId}>
                        <TableCell
                          align="left"
                          style={{
                            fontWeight: "bold",
                            position: "sticky",
                            // minWidth: "250px",
                            left: "0px",
                            backgroundColor: "white",
                            zIndex: 1,
                          }}
                        >
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            backgroundColor: "white",
                            position: "sticky",
                            left: 50,
                            zIndex: 1,
                            top: 0,
                          }}
                        >
                          <span
                            style={{
                              color: student?.enrollmentNumber
                                ? "green"
                                : "red",
                              fontWeight: 700,
                            }}
                          >
                            {student?.enrollmentNumber
                              ? student?.enrollmentNumber
                              : "Not Generated"}
                          </span>
                        </TableCell>
                        <TableCell align="left" sx={{ fontWeight: 700 }}>
                          {student?.randomId}
                        </TableCell>
                        <TableCell align="left">
                          {student?.randomPassword}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            color: "#e85d04",
                            fontWeight: "bold",
                            position: "sticky",
                            left: "200px",
                            zIndex: 1,
                            top: 0,
                            minWidth: "200px",
                            backgroundColor: "white",
                          }}
                        >
                          {student?.name}
                        </TableCell>
                        <TableCell align="left">
                          {student?.fathersname}
                        </TableCell>
                        <TableCell align="left">
                          {student?.mothersname}
                        </TableCell>
                        <TableCell align="left" sx={{ fontWeight: 700 }}>
                          {student?.email}
                        </TableCell>
                        <TableCell align="left">{student?.mobile}</TableCell>
                        <TableCell align="center">
                          {student?.courseType}
                        </TableCell>
                        <TableCell align="left" sx={{ color: "#283618" }}>
                          {student?.courseName}
                        </TableCell>
                        <TableCell align="left" sx={{ color: "#283618" }}>
                          {student?.courseBranch}
                        </TableCell>
                        <TableCell align="left" sx={{ color: "#283618" }}>
                          {student?.assignedCollege || "Not assigned"}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            color: student?.isEnrolled ? "green" : "red",
                            fontWeight: 700,
                          }}
                        >
                          {student?.isEnrolled ? "Filled" : "Not Fill"}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            color: student?.isPaid ? "green" : "red",
                            fontWeight: 700,
                          }}
                        >
                          {student?.isPaid ? "Paid" : "Not Paid"}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            color: student?.isEnrollPaid ? "green" : "red",
                            fontWeight: 700,
                          }}
                        >
                          {student?.isEnrollPaid ? "Paid" : "Not Paid"}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  // Display a message when no data matches the search criteria
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      No students found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination Controls */}
          <TablePagination
            rowsPerPageOptions={[100, 200, 500]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <br></br>
      </Box>

    </>
  );
}

export default TotalFeePaidHod;