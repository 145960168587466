import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import posImage from "../AdminComponent/12.png"
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";


function ExamSidebarnavbar() {
  useEffect(() => {
    let arrow = document.querySelectorAll(".arrow");
    for (var i = 0; i < arrow.length; i++) {
      arrow[i].addEventListener("click", (e) => {
        let arrowParent = e.target.parentElement.parentElement;
        arrowParent.classList.toggle("showMenu");
      });
    }
  }, []);
  return (
    <>
      {/* ===============Navbar================================= */}

 
        <Box sx={{ display: "flex" }}>
          <AppBar
            position="fixed"
            sx={{
              width: "100%",
              top: 0,
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundColor: "#071952",
            }}
          >
            <Toolbar>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Link to="/admin/erpdasboard">
                  <img
                    style={{
                      height: "50px",
                      width: "50px",
                      marginRight: "20px",
                    }}
                    src={posImage}
                  />
                </Link>
                <Typography
                  className="heading"
                  variant="h6"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    textShadow: "2px 2px 10px cadetblue",
                    marginLeft: "25px",
                  }}
                >
                  SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES
                </Typography>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
     
      <>
        <style>
          {`
      @import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@250&family=Tinos&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Tinos', serif;
}
.sidebar {
  margin-Top:60px;
  position: fixed;
  top: 0;
  left: 0;
  height: 150%;
  width: 260px;
  background: #000428;
        background-color:#071952;
  // background: -webkit-linear-gradient(to right, #004e92, #000428);
  // background: linear-gradient(to right, #004e92, #990033);
  z-index: 100;
  transition: all 0.5s ease;
}
.sidebar.close {
  width: 78px;
}
.sidebar .logo-details {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}
.sidebar .logo-details i {
  font-size: 25px;
  color: #fff;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
}
.sidebar .logo-details .logo_name {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}
.sidebar.close .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links {
  height: 100%;
  padding: 0 0 150px 0;
  overflow: auto;
}
.sidebar.close .nav-links {
  overflow: visible;
}
.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}
.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}
.sidebar .nav-links li:hover {
  background: #1d1b31;
}
.sidebar .nav-links li .icon-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar.close .nav-links li .icon-link {
  display: block;
}
.sidebar .nav-links li i {
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.sidebar .nav-links li.showMenu i.arrow {
  transform: rotate(-180deg);
}
.sidebar.close .nav-links i.arrow {
  display: none;
}
.sidebar .nav-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sidebar .nav-links li a .link_name {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  transition: all 0.4s ease;
}
.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #1D2B53;
  display: none;
}
.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}
.sidebar .nav-links li .sub-menu a {
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
}
.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}


@media (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
}

/* Add some basic styling to the container */
.scrollable-list-container {
  max-height: 500px; /* Set a maximum height for the container */
  overflow-y: auto; /* Enable vertical scrollbar */
  border: 1px solid #ccc; /* Add a border for better visibility */
  padding: 10px; /* Add some padding for better spacing */
}

/* Remove default list styles */
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Style your list items as needed */
li {
  margin-bottom: 4px;
}

/* Style your links */
a {
  text-decoration: none;
  color: #333;
  font-size:15px
}

/* Add hover effect to links */
a:hover {
  text-decoration: underline;
}

      `}
        </style>

        <link
          href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css"
          rel="stylesheet"
        />
        <div className="sidebar close">
          <ul
            className="nav-links"
            style={{ marginTop: "25px", borderRadius: "20px" }}
          >
            {/* <Link to="/admin/erpdasboard"> */}
              <li>
                <img
                  width="24"
                  height="22"
                  style={{
                    filter: "invert(1)",
                    marginLeft: "23px",
                    marginTop: "6px",
                  }}
                  src="https://img.icons8.com/material-sharp/24/dashboard-layout.png"
                  alt="student-center"
                />

                <ul className="sub-menu blank">
                  <li>
                    <Link className="link_name" >
                      Dashboard
                    </Link>
                  </li>
                </ul>
              </li>
            {/* </Link> */}
            <li>
              <Link to="#">
                <img
                  width="28"
                  height="27"
                  style={{
                    filter: "invert(1)",
                    marginLeft: "23px",
                    marginTop: "20px",
                  }}
                  src="https://img.icons8.com/ios/50/search-contacts.png"
                  alt="student-center"
                />
              </Link>
              <ul className="sub-menu blank" style={{ marginTop: "30%" }}>
                <li>
                  <Link className="link_name">Examination</Link>
                </li>
                <div class="scrollable-list-container">
                  <ul>
                    <li>
                      <Link to="/erp/student/rawa">
                        Attendance Sheet (Raba)
                      </Link>
                    </li>
                    <li>
                      <Link to="/erp/admin/ExamForm/Status">
                        Exam From Status
                      </Link>
                    </li>
                  </ul>
                </div>
              </ul>
            </li>

            <li>
              <Link to="#">
                <img
                  width="25"
                  height="25"
                  style={{
                    filter: "invert(1)",
                    marginLeft: "23px",
                    marginTop: "20px",
                  }}
                  src="https://img.icons8.com/windows/32/data-center--v3.png"
                  alt="student-center"
                />
              </Link>
              <ul className="sub-menu blank" style={{ marginTop: "30%" }}>
                <li>
                  <Link className="link_name">Student</Link>
                </li>

                <div class="scrollable-list-container">
                  <ul>
                    <li>
                      <Link to="/student/history">
                        Student History
                      </Link>
                    </li>

                    <li>
                      <Link to="/erp/verify/admit-card">
                        Admit Card
                      </Link>
                    </li>
                  </ul>
                </div>
              </ul>
            </li>
            <li>
              <img
                width="25"
                height="25"
                style={{
                  filter: "invert(1)",
                  marginLeft: "23px",
                  marginTop: "20px",
                }}
                src="https://img.icons8.com/hatch/64/administrator-male.png"
                alt="student-center"
              />

              <Link to="/adminlogin">
                <ul className="sub-menu blank" style={{ marginTop: "-6px" }}>
                  <li>
                    <Link className="link_name">Logout</Link>
                  </li>
                </ul>
              </Link>
            </li>
          </ul>
        </div>
      </>
    </>
  );
}

export default ExamSidebarnavbar;

