// import React, { useState } from "react";
// import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import { Grid,Tab, Tabs } from "@mui/material";
// import "jspdf-autotable";
// import DatePicker from "react-datepicker";
// import { useLocation } from "react-router-dom";
// import AdminDashboard from "./AdminDashboard";
// import ExamSidebarnavbar from "../../../Exam_controller/Exam_Sidebar";
// import Id_Sidebar from "../../../Id_Card/Id_Sidebar";
// import HodDashboard from "../../../AdminComponent/HodDashboard";

// const Item = styled(Paper)(({ theme }) => ({
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
// }));

// const Show_Student_History = () => {
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [tabValue, setTabValue] = useState(0);

//   const Storage = JSON.parse(sessionStorage.getItem("AnkitHOD"))
//   let DashboardComponent;

//   if (Storage?.role === "admin") {
//     DashboardComponent = <AdminDashboard />;
//   } else if (Storage?.role === "idcard") {
//     DashboardComponent = <Id_Sidebar/>;
//   } else if (Storage?.role === "HOD") {
//     DashboardComponent = <HodDashboard/>;
//   } else {
//     DashboardComponent = <ExamSidebarnavbar />;
//   }



//   const location = useLocation();
//   const { userData } = location.state;

//   const isEnrolled = userData.isEnrolled;
//   const isRegistered = userData.isRegistered;
//   const handleTabChange = (event, newValue) => {

//     if (newValue === 1 && !(isRegistered && isEnrolled)) return; // Professional
//     if (newValue === 2 && !(isRegistered && isEnrolled)) return; // Academic
//     if (newValue === 3 && !(isRegistered && isEnrolled)) return; // Docs


//     setTabValue(newValue);
//   };

//   const isPersonalTabEnabled = true; // Always enabled
//   const isProfessionalTabEnabled = isRegistered && isEnrolled; // Enabled if both true
//   const isAcademicTabEnabled = isRegistered && isEnrolled; // Enabled if both true
//   const isDocsTabEnabled = isRegistered && isEnrolled; // Enabled if both true

//   const shouldDisplaySection = (data) => {
//     return data !== undefined && data !== null;
//   };

//   return (
//     <>
//       <style>
//         {`

//          @import url("https://fonts.googleapis.com/css?family=Josefin+Sans|Mountains+of+Christmas&display=swap");


// .table th, .table td {
// font-size: 15px;
// padding: 2px 5px;
// color: #000;
// // font-weight:700;
// }
// .theadd{

//                                   color: #2E236C;
//                                   font-size: 15px;
//                                   font-weight: 700;
//                              }
// .carad{
//  height: 70%;
//   border-radius: 20px;
//    margin-left: 60px;
//     margin-top: 50px;
//     }




//     .info-row {
//   display: flex;
//   justify-content: space-between;
//   margin-bottom: 5px;
// }

// .info-row strong {
//   flex: 0 0 40%; /* Adjust the width as needed */
// }

// .info-row span {
//   flex: 1;
//   text-align: left;
//    font-family: "Roboto Condensed", sans-serif;
// }
// .tabu{
// margin-top: 25px;
//  margin-left: 20px;
//  }
//     .diss{
// display: flex;
//  gap: 30px;
//  position: sticky;
//   top: 550px;
//  margin-top: 10px;
//   margin-left: 60px;
//   }
//           .student-profile .card {
//             border-radius: 10px;
//             height:80px;
//             width:80px;
//           }

//           .infoo{
//           margin-left:20px;
//           font-size:15px}

// .card {
//               width: 80%;
//               margin-Top:40px;
//               height:auto;
//            }

//           .student-profile .card h3 {
//             font-size: 20px;
//             font-weight: 700;
//           }

//           .student-profile .card p {
//             font-size: 26px;
//             color: #000;
//           }


//           .strong{
//     font-size:18px;
//     margin-left:-8px;
//     font-weight:700;
//     }
// .boox{

//             margin-top: 3%;
//             // background-color: #b0c4b1;
//             box-shadow: 2px solid black;
//             margin-left: 30px;
//           }



//           .profile_img{

//                       width: 120px;
//                       height: 120px;
//                       object-fit: cover;
//                       border-radius: 50%;
//                     }
//           .student-profile .table th,
//           .student-profile .table td {
//             font-size: 40px;
//             padding: 0px 10px;
//             color: #000;
//           }

//           @media (max-width: 800px) {
//             .card {
//               width: 100%;
//               margin-Top:75px;
//             }
//           }



//           @media screen and (max-width: 480px) {


//          .card {
//               width: 80%;
//               margin-Top:40px;
//            }

//     .diss{
// display: flex;
//  gap: 30px;

//  position:sticky;


//  margin-top: 50px;
//   margin-left:-2px;
//   }

//   .tabu{
// margin-top: 25px;
//  margin-left: -30px;
//  }

//    .profile_img{

//                       // width: 80px;
//                       // height: 80px;
//                       // object-fit: cover;
//                       // border-radius: 50%;
//                       display:none
//                     }

//   .student-profile .table th,
//           .student-profile .table td {
//             font-size: 25px;
//             padding: 0px 5px;
//             color: #000;
//           }




//              .card-body{
//              margin-left:10px}

//         .carad{
//  height: auto;
//  width:auto;
//   border-radius: 20px;
//     // margin-left: -5px;
//     margin-left:-40px;
//     margin-top: 50px;
//     }

//      .card-header {
//       flex-direction: row;
//       justify-content: space-between;
//       align-items: center;
//     }
//     .card-header img {
//       margin-right: 10px;
//       margin-right: auto; /* Pushes the image to the right */
//     }
//     .card-header h3 {
//       margin-top: -50px;
//       text-align: right;

//     }    

//        . table-bordered{
//        margin-left:-10px}
//           .bunnn{
//           height:30px;
//           width:160px;
//            margin-top:-20px;
//             font-size: 9px; /* Reduce the font size for smaller screens */
//     padding: 5px 10px; /* Optionally adjust padding for a better fit */
//           }
//   .MuiTab-root {
//     width: 80%; /* Make each tab take up the full width */

//     font-size: 11px; /* Reduce the font size */
//     padding: 0px ; /* Adjust padding to match the smaller text */

//   }

//   .MuiTabs-flexContainer {
//     flex-direction: row; /* Stack tabs vertically */
//   }

//   .MuiTabs-root {
//     width: 100%; /* Ensure the Tabs container takes up the full width */
//   }
// }


//         `}
//       </style>

//       {DashboardComponent}
//         <Box className="boox">
//           <Grid container sx={{ justifyContent: "center" }}>
//             <Grid item xs={12} sm={5}>
//               <div
//                 class="card shadow-lg carad"
//                 style={{
//                   position: "sticky", 
//                   top: "70px", 
//                   zIndex: 10, 
//                 }}
//               >
//                 <div
//                   className="card-header bg-transparent text-right"
//                   style={{
//                     display: "flex",
//                     flexDirection: "row",
//                     alignItems: "center",
//                   }}
//                 >
//                   <img
//                     className="profile_img"
//                     src={userData?.Documents?.applicantPhoto}
//                     alt="img"
//                     style={{ marginRight: "10px" }}
//                   />
//                   <h3
//                     style={{
//                       color: "#921A40",
//                       margin: 0,
//                       fontWeight: "700",
//                       fontFamily: "'Lato', sans-serif",
//                     }}
//                   >
//                     {userData?.name}
//                   </h3>
//                 </div>

//                 <div class="card-body">
//                   <div class="info-row">
//                     <strong className="strong" style={{ fontWeight: "700" }}>
//                       Admission Session:
//                     </strong>

//                     <span className="infoo">{userData?.admissionSession}</span>
//                   </div>
//                   <div class="info-row">
//                     <strong className="strong">Student Type:</strong>
//                     <span className="infoo">
//                       {userData?.StudentType || "Normal Admission"}
//                     </span>
//                   </div>
//                   <div class="info-row">
//                     <strong className="strong">Course Type:</strong>
//                     <span className="infoo">{userData?.courseType}</span>
//                   </div>
//                   <div class="info-row">
//                     <strong className="strong">Course Name:</strong>
//                     <span className="infoo">{userData?.courseName}</span>
//                   </div>
//                   <div class="info-row">
//                     <strong className="strong">Branch:</strong>
//                     <span className="infoo">{userData?.courseBranch}</span>
//                   </div>
//                   <div class="info-row">
//                     <strong className="strong">Fee Status:</strong>
//                     <span
//                       className="infoo"
//                       style={{ color: userData?.isPaid ? "green" : "red" }}
//                     >
//                       {userData?.isPaid ? "Paid" : "Not Paid"}
//                     </span>
//                   </div>
//                   <div class="info-row">
//                     <strong className="strong">Enrollment Form Status:</strong>
//                     <span
//                       className="infoo"
//                       style={{ color: userData?.isEnrolled ? "green" : "red" }}
//                     >
//                       {userData?.isEnrolled ? "Filled" : "Pending"}
//                     </span>
//                   </div>
//                   <div class="info-row">
//                     <strong className="strong">
//                       Enrollment Form Fee Status:
//                     </strong>
//                     <span
//                       className="infoo"
//                       style={{
//                         color: userData?.isEnrollPaid ? "green" : "red",
//                       }}
//                     >
//                       {userData?.isEnrollPaid ? "Paid" : "Pending"}
//                     </span>
//                   </div>
//                 </div>
//               </div>
//             </Grid>
//             <Grid item xs={12} sm={7}>
//               <Box className="tabu">
//                 <Tabs
//                   value={tabValue}
//                   onChange={handleTabChange}
//                   indicatorColor="primary"
//                   textColor="primary"
//                   variant="fullWidth"
//                 >
//                   <Tab
//                     label={
//                       window.innerWidth <= 480
//                         ? "Personal"
//                         : "Personal Information"
//                     }
//                   />
//                   <Tab
//                     label={
//                       window.innerWidth <= 480
//                         ? "Professional"
//                         : "Professional Information"
//                     }
//                   />
//                   <Tab
//                     label={
//                       window.innerWidth <= 480
//                         ? "Academic"
//                         : "Academic Information"
//                     }
//                   />
//                   <Tab
//                     label={window.innerWidth <= 480 ? "Docs" : "Documents"}
//                   />
//                 </Tabs>

//                 {tabValue === 0 && (
//                   <Box p={1}>
//                     <hr></hr>

//                     <div className="card-body pt-0">
//                       <table className="table table-bordered">
//                         <tbody>
//                           <tr>
//                             <th
//                               style={{
//                                 color: "#433878",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Gender
//                             </th>
//                             <td width="2%">:</td>
//                             <td
//                               style={{
//                                 fontFamily: "'Roboto Condensed', sans-serif",
//                               }}
//                             >
//                               {userData?.gender}
//                             </td>
//                           </tr>
//                           <tr>
//                             <th
//                               style={{
//                                 color: "#433878",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Category
//                             </th>
//                             <td width="2%">:</td>
//                             <td
//                               style={{
//                                 fontFamily: "'Roboto Condensed', sans-serif",
//                               }}
//                             >
//                               {userData?.category}
//                             </td>
//                           </tr>
//                           <tr>
//                             <th
//                               style={{
//                                 color: "#433878",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Father's Name
//                             </th>
//                             <td width="2%">:</td>
//                             <td
//                               style={{
//                                 fontFamily: "'Roboto Condensed', sans-serif",
//                               }}
//                             >
//                               {userData?.fathersname}
//                             </td>
//                           </tr>
//                           <tr>
//                             <th
//                               style={{
//                                 color: "#433878",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Mother's Name
//                             </th>
//                             <td width="2%">:</td>
//                             <td
//                               style={{
//                                 fontFamily: "'Roboto Condensed', sans-serif",
//                               }}
//                             >
//                               {userData?.mothersname}
//                             </td>
//                           </tr>
//                           <tr>
//                             <th
//                               style={{
//                                 color: "#433878",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Contact
//                             </th>
//                             <td width="2%">:</td>
//                             <td
//                               style={{
//                                 fontFamily: "'Roboto Condensed', sans-serif",
//                               }}
//                             >
//                               {userData?.mobile}
//                             </td>
//                           </tr>{" "}
//                           <tr>
//                             <th
//                               width="30%"
//                               style={{
//                                 color: "#433878",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Nationality
//                             </th>
//                             <td width="2%">:</td>
//                             <td
//                               style={{
//                                 fontFamily: "'Roboto Condensed', sans-serif",
//                               }}
//                             >
//                               {userData?.nationality}
//                             </td>
//                           </tr>
//                           <tr>
//                             <th
//                               style={{
//                                 color: "#433878",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               DOB
//                             </th>
//                             <td width="2%">:</td>
//                             <td
//                               style={{
//                                 fontFamily: "'Roboto Condensed', sans-serif",
//                               }}
//                             >
//                               <DatePicker
//                                 dateFormat="dd/MM/yyyy"
//                                 selected={
//                                   selectedDate ||
//                                   (userData?.dob
//                                     ? new Date(userData?.dob)
//                                     : null)
//                                 }
//                                 readOnly
//                               />
//                             </td>
//                           </tr>
//                           <tr>
//                             <th
//                               style={{
//                                 color: "#433878",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Email
//                             </th>
//                             <td width="2%">:</td>
//                             <td
//                               style={{
//                                 fontFamily: "'Roboto Condensed', sans-serif",
//                               }}
//                             >
//                               {userData?.email}
//                             </td>
//                           </tr>
//                           <tr>
//                             <th
//                               style={{
//                                 color: "#433878",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Domicile
//                             </th>
//                             <td width="2%">:</td>
//                             <td
//                               style={{
//                                 fontFamily: "'Roboto Condensed', sans-serif",
//                               }}
//                             >
//                               {userData?.domicile}
//                             </td>
//                           </tr>
//                           <tr>
//                             <th
//                               style={{
//                                 color: "#433878",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Last Exam Type
//                             </th>
//                             <td width="2%">:</td>
//                             <td
//                               style={{
//                                 fontFamily: "'Roboto Condensed', sans-serif",
//                               }}
//                             >
//                               {userData?.lastExamType}
//                             </td>
//                           </tr>
//                           <tr>
//                             <th
//                               style={{
//                                 color: "#433878",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Scholarship Required
//                             </th>

//                             <td width="2%">:</td>

//                             <td
//                               style={{
//                                 fontFamily: "'Roboto Condensed', sans-serif",
//                               }}
//                             >
//                               {userData?.professional?.ScholarshipRequired
//                                 ? userData.professional.ScholarshipRequired
//                                 : "Not Eligible"}
//                             </td>
//                           </tr>{" "}
//                           <tr>
//                             <th
//                               width="30%"
//                               style={{
//                                 color: "#433878",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Qualification
//                             </th>
//                             <td width="2%">:</td>
//                             <td
//                               style={{
//                                 fontFamily: "'Roboto Condensed', sans-serif",
//                               }}
//                             >
//                               {userData?.qualification}
//                             </td>
//                           </tr>
//                           <tr>
//                             <th
//                               width="30%"
//                               style={{
//                                 color: "#433878",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Last Percentage
//                             </th>
//                             <td width="2%">:</td>
//                             <td
//                               style={{
//                                 fontFamily: "'Roboto Condensed', sans-serif",
//                               }}
//                             >
//                               {userData?.qualificationPercentage ||
//                                 userData?.LastPercentage}
//                               %
//                             </td>
//                           </tr>
//                           <tr>
//                             <th
//                               width="30%"
//                               style={{
//                                 color: "#433878",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Last Passed Subject
//                             </th>
//                             <td width="2%">:</td>
//                             <td
//                               style={{
//                                 fontFamily: "'Roboto Condensed', sans-serif",
//                               }}
//                             >
//                               {userData?.lastPassedSubject}
//                             </td>
//                           </tr>
//                           <tr>
//                             <th
//                               width="30%"
//                               style={{
//                                 color: "#433878",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               ABC Id
//                             </th>
//                             <td width="2%">:</td>
//                             <td
//                               style={{
//                                 fontFamily: "'Roboto Condensed', sans-serif",
//                               }}
//                             >
//                               {userData?.AbcId || "not available"}
//                             </td>
//                           </tr>
//                         </tbody>
//                       </table>
//                     </div>
//                   </Box>
//                 )}

//                 {tabValue === 1 && isProfessionalTabEnabled && (
//                   <Box p={3}>
//                     <div className="card-header bg-transparent border-0 ">
//                       {/* </div> */}
//                       <hr style={{ marginTop: "-15px" }}></hr>

//                       <div className="card-body pt-0 ">
//                         <table className="table table-bordered">
//                           <tbody>
//                             <tr>
//                               <th
//                                 width="50%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Handicapped
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData?.professional?.Handicapped}
//                               </td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Medium
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData?.professional?.Medium}
//                               </td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Aadhar Number
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData?.professional?.AadharNumber}
//                               </td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Samagra ID
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData?.professional?.SamagraId
//                                   ? userData.professional.SamagraId
//                                   : "Not Available"}
//                               </td>
//                             </tr>

//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Father's Occupation
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData?.professional?.FathersOccupation}
//                               </td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Mother's Occupation
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData?.professional?.MothersOccupation}
//                               </td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Father's Income
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData?.professional?.FathersIncome}
//                               </td>
//                             </tr>

//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Mother's Income
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData?.professional?.MothersIncome}
//                               </td>
//                             </tr>

//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Parent Mobile
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData?.professional?.ParentMobile}
//                               </td>
//                             </tr>

//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Current Address{" "}
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData?.address?.address1
//                                   ? userData?.address?.address1
//                                   : "Not Fill"}
//                               </td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Permanent Address{" "}
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData?.address?.address2}
//                               </td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Country
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData?.address?.country}
//                               </td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 State
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData?.address?.state}
//                               </td>
//                             </tr>

//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 District
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData?.address?.district}
//                               </td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Pin Code
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData?.address?.pinCode}
//                               </td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Last Qualification
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData?.qualification}
//                               </td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Last Passing Year
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData?.passingYear}
//                               </td>
//                             </tr>
//                           </tbody>
//                         </table>
//                       </div>
//                     </div>
//                   </Box>
//                 )}

//                 {tabValue === 2 && isAcademicTabEnabled && (
//                   <Box p={3}>
//                     <div className="card-header bg-transparent border-0 ">
//                       {/* </div> */}
//                       <hr style={{ marginTop: "-10px" }}></hr>

//                       <div className="card-body pt-0">
//                         <table className="table table-bordered">
//                           {shouldDisplaySection(userData?.academicDetails) && (
//                             <>
//                               <th style={{ fontSize: "20px" }}>
//                                 <b>10th</b>
//                               </th>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#433878",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   School
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td
//                                   style={{
//                                     fontFamily:
//                                       "'Roboto Condensed', sans-serif",
//                                   }}
//                                 >
//                                   {userData?.academicDetails?.tenthSchool}
//                                 </td>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#433878",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Board
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td
//                                   style={{
//                                     fontFamily:
//                                       "'Roboto Condensed', sans-serif",
//                                   }}
//                                 >
//                                   {userData?.academicDetails?.tenthBoard}
//                                 </td>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#433878",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Passing Year
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td
//                                   style={{
//                                     fontFamily:
//                                       "'Roboto Condensed', sans-serif",
//                                   }}
//                                 >
//                                   {userData?.academicDetails?.tenthPassingYear}
//                                 </td>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#433878",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Max. Marks
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td
//                                   style={{
//                                     fontFamily:
//                                       "'Roboto Condensed', sans-serif",
//                                   }}
//                                 >
//                                   {userData?.academicDetails?.tenthMaxMarks}
//                                 </td>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#433878",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Marks Obtained
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td
//                                   style={{
//                                     fontFamily:
//                                       "'Roboto Condensed', sans-serif",
//                                   }}
//                                 >
//                                   {userData?.academicDetails?.tenthMarksObtain}
//                                 </td>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#433878",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Percentage
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td
//                                   style={{
//                                     fontFamily:
//                                       "'Roboto Condensed', sans-serif",
//                                   }}
//                                 >
//                                   {userData?.academicDetails?.tenthPercentage}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#433878",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Roll No
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td
//                                   style={{
//                                     fontFamily:
//                                       "'Roboto Condensed', sans-serif",
//                                   }}
//                                 >
//                                   {userData?.academicDetails?.tenthRollNo}
//                                 </td>
//                               </tr>
//                               <br></br>
//                               <tr style={{ fontSize: "20px" }}>
//                                 <b>12th</b>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#433878",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   School
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td
//                                   style={{
//                                     fontFamily:
//                                       "'Roboto Condensed', sans-serif",
//                                   }}
//                                 >
//                                   {userData?.academicDetails?.twelfthSchool}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#433878",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Board
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td
//                                   style={{
//                                     fontFamily:
//                                       "'Roboto Condensed', sans-serif",
//                                   }}
//                                 >
//                                   {userData?.academicDetails?.twelfthBoard}
//                                 </td>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#433878",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Passing Year
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td
//                                   style={{
//                                     fontFamily:
//                                       "'Roboto Condensed', sans-serif",
//                                   }}
//                                 >
//                                   {
//                                     userData?.academicDetails
//                                       ?.twelfthPassingYear
//                                   }
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#433878",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Max. Marks
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td
//                                   style={{
//                                     fontFamily:
//                                       "'Roboto Condensed', sans-serif",
//                                   }}
//                                 >
//                                   {userData?.academicDetails?.twelfthMaxMarks}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#433878",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Marks Obtained
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td
//                                   style={{
//                                     fontFamily:
//                                       "'Roboto Condensed', sans-serif",
//                                   }}
//                                 >
//                                   {
//                                     userData?.academicDetails
//                                       ?.twelfthMarksObtain
//                                   }
//                                 </td>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#433878",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Percentage
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td
//                                   style={{
//                                     fontFamily:
//                                       "'Roboto Condensed', sans-serif",
//                                   }}
//                                 >
//                                   {userData?.academicDetails?.twelfthPercentage}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#433878",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Roll No
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td
//                                   style={{
//                                     fontFamily:
//                                       "'Roboto Condensed', sans-serif",
//                                   }}
//                                 >
//                                   {userData?.academicDetails?.twelfthRollNo}
//                                 </td>
//                               </tr>

//                               <br></br>

//                               <tr style={{ fontSize: "20px" }}>
//                                 <b>Graduation</b>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#433878",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   College
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td
//                                   style={{
//                                     fontFamily:
//                                       "'Roboto Condensed', sans-serif",
//                                   }}
//                                 >
//                                   {userData?.academicDetails?.graduationCollege}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#433878",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   University
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td
//                                   style={{
//                                     fontFamily:
//                                       "'Roboto Condensed', sans-serif",
//                                   }}
//                                 >
//                                   {
//                                     userData?.academicDetails
//                                       ?.graduationUniversity
//                                   }
//                                 </td>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#433878",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Passing Year
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td
//                                   style={{
//                                     fontFamily:
//                                       "'Roboto Condensed', sans-serif",
//                                   }}
//                                 >
//                                   {
//                                     userData?.academicDetails
//                                       ?.graduationPassingYear
//                                   }
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#433878",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Max. Marks
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td
//                                   style={{
//                                     fontFamily:
//                                       "'Roboto Condensed', sans-serif",
//                                   }}
//                                 >
//                                   {userData?.academicDetails?.twelfthMaxMarks}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#433878",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Marks Obtained
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td
//                                   style={{
//                                     fontFamily:
//                                       "'Roboto Condensed', sans-serif",
//                                   }}
//                                 >
//                                   {
//                                     userData?.academicDetails
//                                       ?.graduationMarksObtain
//                                   }
//                                 </td>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#433878",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Percentage
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td
//                                   style={{
//                                     fontFamily:
//                                       "'Roboto Condensed', sans-serif",
//                                   }}
//                                 >
//                                   {
//                                     userData?.academicDetails
//                                       ?.graduationPercentage
//                                   }
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#433878",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Roll No
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td
//                                   style={{
//                                     fontFamily:
//                                       "'Roboto Condensed', sans-serif",
//                                   }}
//                                 >
//                                   {userData?.academicDetails?.graduationRollNo}
//                                 </td>
//                               </tr>
//                             </>
//                           )}
//                         </table>
//                       </div>
//                     </div>
//                   </Box>
//                 )}

//                 {tabValue === 3 && isDocsTabEnabled && (
//                   <Box p={3}>
//                     <div className="card-header bg-transparent border-0 ">
//                       <hr />

//                       <div class="card-body pt-0">
//                         <table className="table table-bordered">
//                           <tbody>
//                             <tr>
//                               <th
//                                 width="50%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                   paddingTop: "5px",
//                                 }}
//                               >
//                                 Aadhar card
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData && userData.Documents?.aadharCard
//                                   ? userData &&
//                                     userData.Documents?.aadharCard && (
//                                       <a
//                                         href={userData?.Documents?.aadharCard}
//                                         target="_blank"
//                                         rel="noopener noreferrer"
//                                       >
//                                         View Aadhar
//                                       </a>
//                                     )
//                                   : "Not Uploaded"}
//                               </td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                   paddingTop: "5px",
//                                 }}
//                               >
//                                 Applicant Signature
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData &&
//                                 userData?.Documents?.applicantSignature
//                                   ? userData &&
//                                     userData?.Documents?.applicantSignature && (
//                                       <a
//                                         href={
//                                           userData?.Documents
//                                             ?.applicantSignature
//                                         }
//                                         target="_blank"
//                                         rel="noopener noreferrer"
//                                       >
//                                         View Signature
//                                       </a>
//                                     )
//                                   : "Not Uploaded"}
//                               </td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                   paddingTop: "5px",
//                                 }}
//                               >
//                                 Domicile Certificate
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData &&
//                                 userData?.Documents?.domicileCertificate
//                                   ? userData &&
//                                     userData?.Documents
//                                       ?.domicileCertificate && (
//                                       <a
//                                         href={
//                                           userData?.Documents
//                                             ?.domicileCertificate
//                                         }
//                                         target="_blank"
//                                         rel="noopener noreferrer"
//                                       >
//                                         View Ceretificate
//                                       </a>
//                                     )
//                                   : "Not Uploaded"}
//                               </td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                   paddingTop: "5px",
//                                 }}
//                               >
//                                 Income Certificate
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData &&
//                                 userData?.Documents?.incomeCertificate
//                                   ? userData &&
//                                     userData?.Documents?.incomeCertificate && (
//                                       <a
//                                         href={
//                                           userData?.Documents?.incomeCertificate
//                                         }
//                                         target="_blank"
//                                         rel="noopener noreferrer"
//                                       >
//                                         View Certificate
//                                       </a>
//                                     )
//                                   : "Not Uploaded"}
//                               </td>
//                             </tr>

//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                   paddingTop: "5px",
//                                 }}
//                               >
//                                 10th Marksheet
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData && userData.Documents?.marksheet10th
//                                   ? userData &&
//                                     userData.Documents?.marksheet10th && (
//                                       <a
//                                         href={
//                                           userData?.Documents?.marksheet10th
//                                         }
//                                         target="_blank"
//                                         rel="noopener noreferrer"
//                                       >
//                                         View Marksheet
//                                       </a>
//                                     )
//                                   : "Not Uploaded"}
//                               </td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                   paddingTop: "5px",
//                                 }}
//                               >
//                                 12th Marksheet
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData && userData.Documents?.marksheet12th
//                                   ? userData &&
//                                     userData.Documents?.marksheet12th && (
//                                       <a
//                                         href={
//                                           userData?.Documents?.marksheet12th
//                                         }
//                                         target="_blank"
//                                         rel="noopener noreferrer"
//                                       >
//                                         View Marksheet
//                                       </a>
//                                     )
//                                   : "Not Uploaded"}
//                               </td>
//                             </tr>

//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                   paddingTop: "5px",
//                                 }}
//                               >
//                                 Ungraduation Marksheet
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData &&
//                                 userData.Documents?.undergraduateCertificate
//                                   ? userData &&
//                                     userData.Documents
//                                       ?.undergraduateCertificate && (
//                                       <a
//                                         href={
//                                           userData?.Documents
//                                             ?.undergraduateCertificate
//                                         }
//                                         target="_blank"
//                                         rel="noopener noreferrer"
//                                       >
//                                         View Marksheet
//                                       </a>
//                                     )
//                                   : "Not Uploaded"}
//                               </td>
//                             </tr>

//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                   paddingTop: "5px",
//                                 }}
//                               >
//                                 Transfer Certificate
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData &&
//                                 userData?.Documents?.transferCertificate
//                                   ? userData &&
//                                     userData?.Documents
//                                       ?.transferCertificate && (
//                                       <a
//                                         href={
//                                           userData?.Documents
//                                             ?.transferCertificate
//                                         }
//                                         target="_blank"
//                                         rel="noopener noreferrer"
//                                       >
//                                         View Certificate
//                                       </a>
//                                     )
//                                   : "Not Uploaded"}
//                               </td>
//                             </tr>

//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                   paddingTop: "5px",
//                                 }}
//                               >
//                                 Migration Certificate
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData &&
//                                 userData?.Documents?.migrationCertificate
//                                   ? userData &&
//                                     userData?.Documents
//                                       ?.migrationCertificate && (
//                                       <a
//                                         href={
//                                           userData?.Documents
//                                             ?.migrationCertificate
//                                         }
//                                         target="_blank"
//                                         rel="noopener noreferrer"
//                                       >
//                                         View Certificate
//                                       </a>
//                                     )
//                                   : "Not Uploaded"}
//                               </td>
//                             </tr>

//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#433878",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                   paddingTop: "5px",
//                                 }}
//                               >
//                                 Other Certificate
//                               </th>
//                               <td width="2%">:</td>
//                               <td
//                                 style={{
//                                   fontFamily: "'Roboto Condensed', sans-serif",
//                                 }}
//                               >
//                                 {userData &&
//                                 userData?.Documents?.otherCertificate
//                                   ? userData &&
//                                     userData?.Documents?.otherCertificate && (
//                                       <a
//                                         href={
//                                           userData?.Documents?.otherCertificate
//                                         }
//                                         target="_blank"
//                                         rel="noopener noreferrer"
//                                       >
//                                         View Certificate
//                                       </a>
//                                     )
//                                   : "Not Uploaded"}
//                               </td>
//                             </tr>
//                           </tbody>
//                         </table>
//                       </div>
//                     </div>
//                   </Box>
//                 )}
//               </Box>
//             </Grid>
//           </Grid>
//         </Box>

//     </>
//   );
// };

// export default Show_Student_History;




















import React, { useState } from "react";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
import { Grid, Tab, Tabs, Box, Paper } from "@mui/material";
import "jspdf-autotable";
import swal from "sweetalert";
import axios from "axios";
import DatePicker from "react-datepicker";
import { useLocation } from "react-router-dom";
import Id_Sidebar from "../../../Id_Card/Id_Sidebar";
import HodDashboard from "../../../AdminComponent/HodDashboard";
import AdminDashboard from "./AdminDashboard";
import ExamSidebarnavbar from "../../../Exam_controller/Exam_Sidebar";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
}));


const Show_Student_History = () => {
  const [tabValue, setTabValue] = useState(0);
  const [profileImage, setProfileImage] = useState(null);
  const location = useLocation();
  const { userData } = location.state;

  const isEnrolled = userData.isEnrolled || "not found";
  const isRegistered = userData.isRegistered;

  const handleTabChange = (event, newValue) => {
    if (newValue === 1 && !(isRegistered && isEnrolled)) return;
    if (newValue === 2 && !(isRegistered && isEnrolled)) return;
    if (newValue === 3 && !(isRegistered && isEnrolled)) return;

    setTabValue(newValue);
  };

  const isProfessionalTabEnabled = isRegistered && isEnrolled;
  const isAcademicTabEnabled = isRegistered && isEnrolled;
  const isDocsTabEnabled = isRegistered && isEnrolled;

  const shouldDisplaySection = (data) => {
    return data !== undefined && data !== null;
  };

  const handleImageClick = () => {
    document.getElementById("imageUpload").click();
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "upload"); // Replace with your Cloudinary upload preset

    try {
      const cloudinaryResponse = await fetch(
        `https://api.cloudinary.com/v1_1/dszawxz7y/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!cloudinaryResponse.ok) {
        throw new Error("Image upload failed");
      }

      const cloudinaryData = await cloudinaryResponse.json();
      const fileUrl = cloudinaryData.secure_url;


      // Update backend with Cloudinary URL
      const response = await axios.put(
        "https://sssutms.ac.in/apitest/v2/admin/updateApplicantPhoto",
        {
          userID: userData._id,
          applicantPhoto: fileUrl,
        }
      );

      if (response.data) {
        // Update the local state and UI
        setProfileImage(fileUrl); // Update profile image locally
        userData.Documents.applicantPhoto = fileUrl; // Update userData to reflect the new image

        swal({
          title: "Success",
          text: "Updated successfully",
          icon: "success",
          buttons: "OK",
        });
      }
    } catch (error) {
      console.error("Error updating photo:", error);
      alert("Failed to update photo. Please try again.");
    }
  };

  const Storage = JSON.parse(sessionStorage.getItem("AnkitHOD"))
  let DashboardComponent;

  if (Storage?.role === "admin") {
    DashboardComponent = <AdminDashboard />;
  } else if (Storage?.role === "idcard") {
    DashboardComponent = <Id_Sidebar />;
  } else if (Storage?.role === "HOD") {
    DashboardComponent = <HodDashboard />;
  } else {
    DashboardComponent = <ExamSidebarnavbar />;
  }

  return (
    <>
      <style>
        {`

        
 

.table th, .table td {
font-size: 15px;
padding: 2px 5px;
color: #000;
// font-weight:700;
}
.theadd{

                                  color: #2E236C;
                                  font-size: 15px;
                                  font-weight: 700;
                             }
.carad{
 height: 70%;
  border-radius: 20px;
   margin-left: 60px;
    margin-top: 50px;
    }




    .info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.info-row strong {
  flex: 0 0 40%; /* Adjust the width as needed */
}

.info-row span {
  flex: 1;
  text-align: left;
   fontFamily:"'Poppins',Sans-serif" 
}
.tabu{
margin-top: 25px;
 margin-left: 20px;
 }
    .diss{
display: flex;
 gap: 30px;
 position: sticky;
  top: 550px;
 margin-top: 10px;
  margin-left: 60px;
  }
          .student-profile .card {
            border-radius: 10px;
            height:80px;
            width:80px;
          }

          .infoo{
          margin-left:20px;
          font-size:15px}

.card {
              width: 80%;
              margin-Top:40px;
              height:auto;
           }

          .student-profile .card h3 {
            font-size: 20px;
            font-weight: 700;
          }

          .student-profile .card p {
            font-size: 26px;
            color: #000;
          }


          .strong{
    font-size:15px;
    margin-left:-8px;
 fontFamily:"'Poppins',Sans-serif" ;
    // font-weight:700;
    }
.boox{

            margin-top: 3%;
            // background-color: #b0c4b1;
            box-shadow: 2px solid black;
            margin-left: 30px;
          }



          .profile_img{
       
                      width: 120px;
                      height: 120px;
                      object-fit: cover;
                      border-radius: 50%;
                    }
          .student-profile .table th,
          .student-profile .table td {
            font-size: 40px;
            padding: 0px 10px;
            color: #000;
          }

          @media (max-width: 800px) {
            .card {
              width: 100%;
              margin-Top:75px;
            }
          }

       

          @media screen and (max-width: 480px) {
       
     
         .card {
              width: 80%;
              margin-Top:40px;
           }

    .diss{
display: flex;
 gap: 30px;

 position:sticky;
 
 
 margin-top: 50px;
  margin-left:-2px;
  }
       
  .tabu{
margin-top: 25px;
 margin-left: -30px;
 }
 
   .profile_img{
       
                      // width: 80px;
                      // height: 80px;
                      // object-fit: cover;
                      // border-radius: 50%;
                      display:none
                    }
 
  .student-profile .table th,
          .student-profile .table td {
            font-size: 25px;
            padding: 0px 5px;
            color: #000;
             font-family:'Poppins',Sans-serif";
          }




             .card-body{
             margin-left:10px}

        .carad{
 height: auto;
 width:auto;
  border-radius: 20px;
    // margin-left: -5px;
    margin-left:-40px;
    margin-top: 50px;
    }
       
     .card-header {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .card-header img {
      margin-right: 10px;
      margin-right: auto; /* Pushes the image to the right */
    }
    .card-header h3 {
      margin-top: -50px;
      text-align: right;
     
    }    
       
       . table-bordered{
       margin-left:-10px}
          .bunnn{
          height:30px;
          width:160px;
           margin-top:-20px;
            font-size: 9px; /* Reduce the font size for smaller screens */
    padding: 5px 10px; /* Optionally adjust padding for a better fit */
          }
  .MuiTab-root {
    width: 80%; /* Make each tab take up the full width */
 
    font-size: 11px; /* Reduce the font size */
    padding: 0px ; /* Adjust padding to match the smaller text */

  }

  .MuiTabs-flexContainer {
    flex-direction: row; /* Stack tabs vertically */
  }

  .MuiTabs-root {
    width: 100%; /* Ensure the Tabs container takes up the full width */
  }
}

         
        `}
      </style>


      {DashboardComponent}
      <Box className="boox">
        <Grid container sx={{ justifyContent: "center" }}>
          <Grid item xs={12} sm={5}>
            <div
              class="card shadow-lg carad"
              style={{
                position: "sticky",
                top: "70px",
                zIndex: 10,
              }}
            >
              <div
                className="card-header bg-transparent text-right"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  className="profile_image_container"
                  style={{
                    position: "relative",
                    display: "inline-block",
                    cursor: "pointer",
                  }}
                  onClick={handleImageClick}
                >
                  <img
                    className="profile_img"
                    src={profileImage || userData?.Documents?.applicantPhoto}
                    alt="img"
                    style={{
                      marginRight: "10px",
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%",
                      objectFit: "cover",
                      border: "2px solid #ccc",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      bottom: "0",
                      background: "rgba(0, 0, 0, 0.6)",
                      color: "#fff",
                      width: "100%",
                      textAlign: "center",
                      padding: "5px 0",
                      fontSize: "12px",
                      borderBottomLeftRadius: "50%",
                      borderBottomRightRadius: "50%",
                    }}
                  >
                    Update Photo
                  </div>
                </div>
                <input
                  type="file"
                  id="imageUpload"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />

                <h3
                  style={{
                    color: "#921A40",
                    margin: 0,
                    fontWeight: "700",
                    fontFamily: "'Poppins',Sans-serif",
                  }}
                >
                  {userData?.name}
                </h3>
              </div>

              <div class="card-body">
                <div class="info-row">
                  <strong
                    className="strong"
                    style={{
                      fontWeight: 700,
                      fontFamily: "'Poppins',Sans-serif",
                    }}
                  >
                    Admission Session:
                  </strong>

                  <span
                    className="infoo"
                    style={{ fontFamily: "'Poppins',Sans-serif" }}
                  >
                    {userData?.admissionSession}
                  </span>
                </div>
                <div class="info-row">
                  <strong
                    className="strong"
                    style={{
                      fontWeight: 700,
                      fontFamily: "'Poppins',Sans-serif",
                    }}
                  >
                    Student Type:
                  </strong>
                  <span
                    className="infoo"
                    style={{ fontFamily: "'Poppins',Sans-serif" }}
                  >
                    {userData?.StudentType || "Normal Admission"}
                  </span>
                </div>
                <div class="info-row">
                  <strong
                    className="strong"
                    style={{
                      fontWeight: 700,
                      fontFamily: "'Poppins',Sans-serif",
                    }}
                  >
                    Course Type:
                  </strong>
                  <span
                    className="infoo"
                    style={{ fontFamily: "'Poppins',Sans-serif" }}
                  >
                    {userData?.courseType}
                  </span>
                </div>
                <div class="info-row">
                  <strong
                    className="strong"
                    style={{
                      fontWeight: 700,
                      fontFamily: "'Poppins',Sans-serif",
                    }}
                  >
                    Course Name:
                  </strong>
                  <span
                    className="infoo"
                    style={{ fontFamily: "'Poppins',Sans-serif" }}
                  >
                    {userData?.courseName}
                  </span>
                </div>
                <div class="info-row">
                  <strong
                    className="strong"
                    style={{
                      fontWeight: 700,
                      fontFamily: "'Poppins',Sans-serif",
                    }}
                  >
                    Branch:
                  </strong>
                  <span
                    className="infoo"
                    style={{ fontFamily: "'Poppins',Sans-serif" }}
                  >
                    {userData?.courseBranch}
                  </span>
                </div>
                <div class="info-row">
                  <strong
                    className="strong"
                    style={{
                      fontWeight: 700,
                      fontFamily: "'Poppins',Sans-serif",
                    }}
                  >
                    Fee Status:
                  </strong>
                  <span
                    className="infoo"
                    style={{
                      color: userData?.isPaid ? "green" : "red",
                      fontFamily: "'Poppins',Sans-serif",
                    }}
                  >
                    {userData?.isPaid ? "Paid" : "Not Paid"}
                  </span>
                </div>
                <div class="info-row">
                  <strong
                    className="strong"
                    style={{
                      fontWeight: 700,
                      fontFamily: "'Poppins',Sans-serif",
                    }}
                  >
                    Enrollment Status:
                  </strong>
                  <span
                    className="infoo"
                    style={{
                      color: userData?.isEnrolled ? "green" : "red",
                      fontFamily: "'Poppins',Sans-serif",
                    }}
                  >
                    {userData?.isEnrolled ? "Filled" : "Pending"}
                  </span>
                </div>
                <div class="info-row">
                  <strong
                    className="strong"
                    style={{
                      fontWeight: 700,
                      fontFamily: "'Poppins',Sans-serif",
                    }}
                  >
                    Enrollment Fee Status:
                  </strong>
                  <span
                    className="infoo"
                    style={{
                      color: userData?.isEnrollPaid ? "green" : "red",
                      fontFamily: "'Poppins',Sans-serif",
                    }}
                  >
                    {userData?.isEnrollPaid ? "Paid" : "Pending"}
                  </span>
                </div>
                <div class="info-row">
                  <strong
                    className="strong"
                    style={{
                      fontWeight: 700,
                      fontFamily: "'Poppins',Sans-serif",
                    }}
                  >
                    Enrollment Number:
                  </strong>
                  <span
                    className="infoo"
                    style={{
                      fontFamily: "'Poppins',Sans-serif",
                      color: userData?.enrollmentNumber ? "green" : "red",
                    }}
                  >
                    {userData?.enrollmentNumber
                      ? userData?.enrollmentNumber
                      : "Pending"}
                  </span>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Box className="tabu">
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab
                  label={
                    window.innerWidth <= 480
                      ? "Personal"
                      : "Personal Information"
                  }
                />
                <Tab
                  label={
                    window.innerWidth <= 480
                      ? "Professional"
                      : "Professional Information"
                  }
                />
                <Tab
                  label={
                    window.innerWidth <= 480
                      ? "Academic"
                      : "Academic Information"
                  }
                />
                <Tab
                  label={window.innerWidth <= 480 ? "Docs" : "Documents"}
                />
              </Tabs>

              {tabValue === 0 && (
                <Box p={1}>
                  <hr></hr>

                  <div className="card-body pt-0">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <th
                            style={{
                              color: "#433878",
                              fontSize: "15px",
                              fontWeight: 700,
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            Gender
                          </th>
                          <td width="2%">:</td>
                          <td
                            style={{
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            {userData?.gender}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              color: "#433878",
                              fontSize: "15px",
                              fontWeight: 700,
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            Category
                          </th>
                          <td width="2%">:</td>
                          <td
                            style={{
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            {userData?.category}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              color: "#433878",
                              fontSize: "15px",
                              fontWeight: 700,
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            Father's Name
                          </th>
                          <td width="2%">:</td>
                          <td
                            style={{
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            {userData?.fathersname}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              color: "#433878",
                              fontSize: "15px",
                              fontWeight: 700,
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            Mother's Name
                          </th>
                          <td width="2%">:</td>
                          <td
                            style={{
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            {userData?.mothersname}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              color: "#433878",
                              fontSize: "15px",
                              fontWeight: 700,
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            Contact
                          </th>
                          <td width="2%">:</td>
                          <td
                            style={{
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            {userData?.mobile}
                          </td>
                        </tr>{" "}
                        <tr>
                          <th
                            width="30%"
                            style={{
                              color: "#433878",
                              fontSize: "15px",
                              fontWeight: 700,
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            Nationality
                          </th>
                          <td width="2%">:</td>
                          <td
                            style={{
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            {userData?.nationality}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              color: "#433878",
                              fontSize: "15px",
                              fontWeight: 700,
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            DOB
                          </th>
                          <td width="2%">:</td>
                          <td
                            style={{
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              selected={
                                userData?.dob ? new Date(userData?.dob) : null
                              }
                              readOnly
                            />
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              color: "#433878",
                              fontSize: "15px",
                              fontWeight: 700,
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            Email
                          </th>
                          <td width="2%">:</td>
                          <td
                            style={{
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            {userData?.email}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              color: "#433878",
                              fontSize: "15px",
                              fontWeight: 700,
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            Domicile
                          </th>
                          <td width="2%">:</td>
                          <td
                            style={{
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            {userData?.domicile}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              color: "#433878",
                              fontSize: "15px",
                              fontWeight: 700,
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            Last Exam Type
                          </th>
                          <td width="2%">:</td>
                          <td
                            style={{
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            {userData?.lastExamType}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              color: "#433878",
                              fontSize: "15px",
                              fontWeight: 700,
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            Scholarship Required
                          </th>

                          <td width="2%">:</td>

                          <td
                            style={{
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            {userData?.professional?.ScholarshipRequired
                              ? userData.professional.ScholarshipRequired
                              : "Not Eligible"}
                          </td>
                        </tr>{" "}
                        <tr>
                          <th
                            width="30%"
                            style={{
                              color: "#433878",
                              fontSize: "15px",
                              fontWeight: 700,
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            Qualification
                          </th>
                          <td width="2%">:</td>
                          <td
                            style={{
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            {userData?.qualification}
                          </td>
                        </tr>
                        <tr>
                          <th
                            width="30%"
                            style={{
                              color: "#433878",
                              fontSize: "15px",
                              fontWeight: 700,
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            Last Percentage
                          </th>
                          <td width="2%">:</td>
                          <td
                            style={{
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            {userData?.qualificationPercentage ||
                              userData?.LastPercentage}
                            %
                          </td>
                        </tr>
                        <tr>
                          <th
                            width="30%"
                            style={{
                              color: "#433878",
                              fontSize: "15px",
                              fontWeight: 700,
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            Last Passed Subject
                          </th>
                          <td width="2%">:</td>
                          <td
                            style={{
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            {userData?.lastPassedSubject}
                          </td>
                        </tr>
                        <tr>
                          <th
                            width="30%"
                            style={{
                              color: "#433878",
                              fontSize: "15px",
                              fontWeight: 700,
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            ABC Id
                          </th>
                          <td width="2%">:</td>
                          <td
                            style={{
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            {userData?.AbcId || "not available"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Box>
              )}

              {tabValue === 1 && isProfessionalTabEnabled && (
                <Box p={3}>
                  <div className="card-header bg-transparent border-0 ">
                    <hr style={{ marginTop: "-15px" }}></hr>

                    <div className="card-body pt-0 ">
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <th
                              width="50%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Handicapped
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData?.professional?.Handicapped}
                            </td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Medium
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData?.professional?.Medium}
                            </td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Aadhar Number
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData?.professional?.AadharNumber}
                            </td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Samagra ID
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData?.professional?.SamagraId
                                ? userData.professional.SamagraId
                                : "Not Available"}
                            </td>
                          </tr>

                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Father's Occupation
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData?.professional?.FathersOccupation}
                            </td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Mother's Occupation
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData?.professional?.MothersOccupation}
                            </td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Father's Income
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData?.professional?.FathersIncome}
                            </td>
                          </tr>

                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Mother's Income
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData?.professional?.MothersIncome}
                            </td>
                          </tr>

                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Parent Mobile
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData?.professional?.ParentMobile}
                            </td>
                          </tr>

                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Current Address{" "}
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData?.address?.address1
                                ? userData?.address?.address1
                                : "Not Fill"}
                            </td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Permanent Address{" "}
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData?.address?.address2}
                            </td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Country
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData?.address?.country}
                            </td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              State
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData?.address?.state}
                            </td>
                          </tr>

                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              District
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData?.address?.district}
                            </td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Pin Code
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData?.address?.pinCode}
                            </td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Last Qualification
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData?.qualification}
                            </td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Last Passing Year
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData?.passingYear}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Box>
              )}

              {tabValue === 2 && isAcademicTabEnabled && (
                <Box p={3}>
                  <div className="card-header bg-transparent border-0 ">
                    {/* </div> */}
                    <hr style={{ marginTop: "-10px" }}></hr>

                    <div className="card-body pt-0">
                      <table className="table table-bordered">
                        {shouldDisplaySection(userData?.academicDetails) && (
                          <>
                            <th style={{ fontSize: "20px" }}>
                              <b>10th</b>
                            </th>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#433878",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                School
                              </th>
                              <td width="2%">:</td>
                              <td
                                style={{
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {userData?.academicDetails?.tenthSchool}
                              </td>
                            </tr>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#433878",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                Board
                              </th>
                              <td width="2%">:</td>
                              <td
                                style={{
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {userData?.academicDetails?.tenthBoard}
                              </td>
                            </tr>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#433878",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                Passing Year
                              </th>
                              <td width="2%">:</td>
                              <td
                                style={{
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {userData?.academicDetails?.tenthPassingYear}
                              </td>
                            </tr>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#433878",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                Max. Marks
                              </th>
                              <td width="2%">:</td>
                              <td
                                style={{
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {userData?.academicDetails?.tenthMaxMarks}
                              </td>
                            </tr>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#433878",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                Marks Obtained
                              </th>
                              <td width="2%">:</td>
                              <td
                                style={{
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {userData?.academicDetails?.tenthMarksObtain}
                              </td>
                            </tr>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#433878",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                Percentage
                              </th>
                              <td width="2%">:</td>
                              <td
                                style={{
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {userData?.academicDetails?.tenthPercentage}
                              </td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#433878",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                Roll No
                              </th>
                              <td width="2%">:</td>
                              <td
                                style={{
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {userData?.academicDetails?.tenthRollNo}
                              </td>
                            </tr>
                            <br></br>
                            <tr style={{ fontSize: "20px" }}>
                              <b>12th</b>
                            </tr>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#433878",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                School
                              </th>
                              <td width="2%">:</td>
                              <td
                                style={{
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {userData?.academicDetails?.twelfthSchool}
                              </td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#433878",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                Board
                              </th>
                              <td width="2%">:</td>
                              <td
                                style={{
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {userData?.academicDetails?.twelfthBoard}
                              </td>
                            </tr>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#433878",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                Passing Year
                              </th>
                              <td width="2%">:</td>
                              <td
                                style={{
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {
                                  userData?.academicDetails
                                    ?.twelfthPassingYear
                                }
                              </td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#433878",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                Max. Marks
                              </th>
                              <td width="2%">:</td>
                              <td
                                style={{
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {userData?.academicDetails?.twelfthMaxMarks}
                              </td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#433878",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                Marks Obtained
                              </th>
                              <td width="2%">:</td>
                              <td
                                style={{
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {
                                  userData?.academicDetails
                                    ?.twelfthMarksObtain
                                }
                              </td>
                            </tr>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#433878",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                Percentage
                              </th>
                              <td width="2%">:</td>
                              <td
                                style={{
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {userData?.academicDetails?.twelfthPercentage}
                              </td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#433878",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                Roll No
                              </th>
                              <td width="2%">:</td>
                              <td
                                style={{
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {userData?.academicDetails?.twelfthRollNo}
                              </td>
                            </tr>

                            <br></br>

                            <tr style={{ fontSize: "20px" }}>
                              <b>Graduation</b>
                            </tr>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#433878",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                College
                              </th>
                              <td width="2%">:</td>
                              <td
                                style={{
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {userData?.academicDetails?.graduationCollege}
                              </td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#433878",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                University
                              </th>
                              <td width="2%">:</td>
                              <td
                                style={{
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {
                                  userData?.academicDetails
                                    ?.graduationUniversity
                                }
                              </td>
                            </tr>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#433878",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                Passing Year
                              </th>
                              <td width="2%">:</td>
                              <td
                                style={{
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {
                                  userData?.academicDetails
                                    ?.graduationPassingYear
                                }
                              </td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#433878",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                Max. Marks
                              </th>
                              <td width="2%">:</td>
                              <td
                                style={{
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {userData?.academicDetails?.twelfthMaxMarks}
                              </td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#433878",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                Marks Obtained
                              </th>
                              <td width="2%">:</td>
                              <td
                                style={{
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {
                                  userData?.academicDetails
                                    ?.graduationMarksObtain
                                }
                              </td>
                            </tr>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#433878",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                Percentage
                              </th>
                              <td width="2%">:</td>
                              <td
                                style={{
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {
                                  userData?.academicDetails
                                    ?.graduationPercentage
                                }
                              </td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#433878",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                Roll No
                              </th>
                              <td width="2%">:</td>
                              <td
                                style={{
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {userData?.academicDetails?.graduationRollNo}
                              </td>
                            </tr>
                          </>
                        )}
                      </table>
                    </div>
                  </div>
                </Box>
              )}

              {tabValue === 3 && isDocsTabEnabled && (
                <Box p={3}>
                  <div className="card-header bg-transparent border-0 ">
                    <hr />

                    <div class="card-body pt-0">
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <th
                              width="50%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                paddingTop: "5px",
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Aadhar card
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData && userData.Documents?.aadharCard
                                ? userData &&
                                userData.Documents?.aadharCard && (
                                  <a
                                    href={userData?.Documents?.aadharCard}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Aadhar
                                  </a>
                                )
                                : "Not Uploaded"}
                            </td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                paddingTop: "5px",
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Applicant Signature
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData &&
                                userData?.Documents?.applicantSignature
                                ? userData &&
                                userData?.Documents?.applicantSignature && (
                                  <a
                                    href={
                                      userData?.Documents
                                        ?.applicantSignature
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Signature
                                  </a>
                                )
                                : "Not Uploaded"}
                            </td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                paddingTop: "5px",
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Domicile Certificate
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData &&
                                userData?.Documents?.domicileCertificate
                                ? userData &&
                                userData?.Documents
                                  ?.domicileCertificate && (
                                  <a
                                    href={
                                      userData?.Documents
                                        ?.domicileCertificate
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Ceretificate
                                  </a>
                                )
                                : "Not Uploaded"}
                            </td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                paddingTop: "5px",
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Income Certificate
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData &&
                                userData?.Documents?.incomeCertificate
                                ? userData &&
                                userData?.Documents?.incomeCertificate && (
                                  <a
                                    href={
                                      userData?.Documents?.incomeCertificate
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Certificate
                                  </a>
                                )
                                : "Not Uploaded"}
                            </td>
                          </tr>

                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                paddingTop: "5px",
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              10th Marksheet
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData && userData.Documents?.marksheet10th
                                ? userData &&
                                userData.Documents?.marksheet10th && (
                                  <a
                                    href={
                                      userData?.Documents?.marksheet10th
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Marksheet
                                  </a>
                                )
                                : "Not Uploaded"}
                            </td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                paddingTop: "5px",
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              12th Marksheet
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData && userData.Documents?.marksheet12th
                                ? userData &&
                                userData.Documents?.marksheet12th && (
                                  <a
                                    href={
                                      userData?.Documents?.marksheet12th
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Marksheet
                                  </a>
                                )
                                : "Not Uploaded"}
                            </td>
                          </tr>

                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                fontFamily: "'Poppins',Sans-serif",
                                paddingTop: "5px",
                              }}
                            >
                              Ungraduation Marksheet
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData &&
                                userData.Documents?.undergraduateCertificate
                                ? userData &&
                                userData.Documents
                                  ?.undergraduateCertificate && (
                                  <a
                                    href={
                                      userData?.Documents
                                        ?.undergraduateCertificate
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Marksheet
                                  </a>
                                )
                                : "Not Uploaded"}
                            </td>
                          </tr>

                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                paddingTop: "5px",
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Transfer Certificate
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData &&
                                userData?.Documents?.transferCertificate
                                ? userData &&
                                userData?.Documents
                                  ?.transferCertificate && (
                                  <a
                                    href={
                                      userData?.Documents
                                        ?.transferCertificate
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Certificate
                                  </a>
                                )
                                : "Not Uploaded"}
                            </td>
                          </tr>

                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                paddingTop: "5px",
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Migration Certificate
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData &&
                                userData?.Documents?.migrationCertificate
                                ? userData &&
                                userData?.Documents
                                  ?.migrationCertificate && (
                                  <a
                                    href={
                                      userData?.Documents
                                        ?.migrationCertificate
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Certificate
                                  </a>
                                )
                                : "Not Uploaded"}
                            </td>
                          </tr>

                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#433878",
                                fontSize: "15px",
                                fontWeight: 700,
                                paddingTop: "5px",
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              Other Certificate
                            </th>
                            <td width="2%">:</td>
                            <td
                              style={{
                                fontFamily: "'Poppins',Sans-serif",
                              }}
                            >
                              {userData &&
                                userData?.Documents?.otherCertificate
                                ? userData &&
                                userData?.Documents?.otherCertificate && (
                                  <a
                                    href={
                                      userData?.Documents?.otherCertificate
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Certificate
                                  </a>
                                )
                                : "Not Uploaded"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

    </>
  );
};

export default Show_Student_History;
