import React from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Box, 
  Container,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Link } from 'react-router-dom';

const OnlyHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AppBar 
      position="fixed" 
      sx={{
        background: 'linear-gradient(135deg, #1e3c72 0%, #2a5298 100%)',
        boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/erp" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
            <Box
              component="img"
              src="https://cdn.sssutms.ac.in/media/static/images/SSSUTMS_LOGO.png"
              alt="SSSUTMS Logo"
              sx={{
                height: isMobile ? 40 : 50,
                width: 'auto',
                mr: 2,
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)'
                }
              }}
            />
          </Link>
          
          <Typography
            variant={isMobile ? "subtitle1" : "h6"}
            component="h1"
            sx={{
              flexGrow: 1,
              color: '#ffffff',
              fontWeight: 600,
              letterSpacing: '0.5px',
              textAlign: isMobile ? 'center' : 'left',
              fontSize: isMobile ? '0.9rem' : '1.2rem',
              textShadow: '2px 2px 4px rgba(0,0,0,0.2)',
              lineHeight: 1.2,
              fontFamily: '"Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif'
            }}
          >
            SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default OnlyHeader;