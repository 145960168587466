import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { TableCell, Box, CircularProgress } from "@mui/material";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useParams } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import swal from "sweetalert";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";
// import Sidebar from "./Sidebar";
import Form from "react-bootstrap/Form";

const SearchPen = ({ session, courseType, courseName, branch }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [studentData, setStudentData] = useState([]);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [colleges, setColleges] = useState([]);
  const [selectedCollege, setSelectedCollege] = useState({});

  console.log(session, "session From starting");
  useEffect(() => {
    const fetchStudents = async () => {
      try {
        setLoading(true);
        const url = "https://sssutms.ac.in/apitest/admin/pendingstudentlist";
        const requestData = {
          admissionSession: session,
          courseType,
          courseName,
          courseBranch: branch,
        };
        const response = await axios.post(url, requestData);
        const data = response.data;

        if (!data.students || data.students.length === 0) {
          setDataNotFound(true);
        } else {
          setDataNotFound(false);
          setStudentData(data.students);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setDataNotFound(true);
      } finally {
        setLoading(false);
      }
    };

    fetchStudents();
  }, [session, courseType, courseName, branch]);

  useEffect(() => {
    const fetchColleges = async () => {
      try {
        const response = await axios.get(
          `https://sssutms.ac.in/apitest/v1/hod/colleges/course?courseName=${courseName}`
        );
        setColleges(response.data);
      } catch (error) {
        console.error("Error fetching colleges:", error);
      }
    };

    if (courseName) {
      fetchColleges();
    }
  }, [courseName]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCollegeChange = (studentId, event) => {
    setSelectedCollege({
      ...selectedCollege,
      [studentId]: event.target.value,
    });
  };

  const handleApprove = async (studentId) => {
    if (selectedCollege[studentId]) {
      try {
        await axios.put(process.env.REACT_APP_STD_APPROVE, {
          studentId,
          collegeName: selectedCollege[studentId],
        });
        swal({
          title: "Success",
          text: "Approved Successfully!",
          icon: "success",
          buttons: "OK",
        });
        setStudentData(
          studentData.filter((student) => student._id !== studentId)
        );
      } catch (error) {
        console.error("Error approving student:", error);
      }
    } else {
      swal({
        title: "Error",
        text: "Please assign a college first!",
        icon: "error",
        buttons: "OK",
      });
    }
  };

  const handleCancel = async (studentId) => {
    try {
      await axios.post(process.env.REACT_APP_STD_CANCEL, { studentId });
      swal({
        title: "Success",
        text: "Request Cancelled Successfully!",
        icon: "success",
        buttons: "OK",
      });
      setStudentData(
        studentData.filter((student) => student._id !== studentId)
      );
    } catch (error) {
      console.error("Error canceling request:", error);
    }
  };

  return (
    <>
      <style>
        {`
        .butt{
        width:80px;
        height:35px;
        padding:5px}
        `}
      </style>
      <AdminDashboard />
      <Box sx={{ width: "90%", marginLeft: "100px", marginTop: "100px" }}>
        {loading ? (
          <CircularProgress
            color="inherit"
            style={{ marginTop: "70px", marginLeft: "50%" }}
          />
        ) : dataNotFound ? (
          <div>
            <h2>Oops! No Student Available in this Course!!!!</h2>
          </div>
        ) : (
          <Paper sx={{ width: "100%", overflow: "auto" }}>
            <TableContainer sx={{ maxHeight: "440px" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        position: "sticky",
                        left: 0,
                        top: 0,
                        zIndex: "2",
                      }}
                    >
                      S.No.
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                      }}
                    >
                      Session
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                      }}
                    >
                      Student Type
                    </TableCell>

                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                      }}
                    >
                      Registration No.
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "200px",
                        position: "sticky",
                        zIndex: "2",
                        top: 0,
                        left: 50,
                      }}
                    >
                      Student Name
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                        minWidth: "150px",
                      }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                      }}
                    >
                      DOB
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                        minWidth: "150px",
                      }}
                    >
                      Father's Name
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "150px",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                      }}
                    >
                      Mother's Name
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                      }}
                    >
                      Contact
                    </TableCell>

                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                      }}
                    >
                      Qualification
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        minWidth: "150px",
                        color: "white",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                      }}
                    >
                      Qualification %
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                      }}
                    >
                      Course Type
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "200px",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                      }}
                    >
                      Course Name
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        minWidth: "200px",
                        color: "white",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                      }}
                    >
                      Course Branch
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        minWidth: "200px",
                        color: "white",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                      }}
                    >
                      Assign College
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{
                        backgroundColor: "#001d3d",
                        minWidth: "200px",
                        color: "white",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                      }}
                    >
                      View Marksheet
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{
                        backgroundColor: "#001d3d",
                        minWidth: "200px",
                        color: "white",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentData.map((student, index) => (
                    <TableRow key={student._id}>
                      <TableCell
                        align="left"
                        style={{
                          // position: "sticky",

                          fontWeight: "bold",
                          backgroundColor: "#fff",
                          position: "sticky",
                          left: 0,
                          top: 0,
                          zIndex: "1",
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell align="left">
                        {student?.admissionSession}
                      </TableCell>
                      <TableCell align="center">
                        {/* {student.StudentType} */}

                        {student.StudentType || "Normal "}
                      </TableCell>

                      <TableCell align="center" style={{ fontWeight: 700 }}>
                        {student.randomId}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: "#e85d04",
                          fontWeight: 700,
                          backgroundColor: "#fff",
                          position: "sticky",
                          left: 50,
                          top: 0,
                          zIndex: "1",
                        }}
                      >
                        {student.name}
                      </TableCell>
                      <TableCell align="left">{student.email}</TableCell>
                      <TableCell align="left">{student.dob}</TableCell>
                      <TableCell align="left">{student.fathersname}</TableCell>
                      <TableCell align="left">{student.mothersname}</TableCell>
                      <TableCell align="left">{student.mobile}</TableCell>
                      <TableCell align="left">
                        {student.qualification}
                      </TableCell>
                      <TableCell align="left">
                        {`${
                          student.qualificationPercentage ||
                          student.LastPercentage
                        }%`}
                      </TableCell>

                      <TableCell align="left">{student.courseType}</TableCell>
                      <TableCell align="left">{student.courseName}</TableCell>
                      <TableCell align="left">{student.courseBranch}</TableCell>

                      {/* Add your TableCells here */}
                      <TableCell align="center">
                        <Form.Select
                          style={{ height: "40px", width: "45vh" }}
                          value={selectedCollege[student._id] || ""}
                          onChange={(event) =>
                            handleCollegeChange(student._id, event)
                          }
                        >
                          <option value="">Select College</option>
                          {colleges.map((college) => (
                            <option key={college._id} value={college.name}>
                              {college.name}
                            </option>
                          ))}
                        </Form.Select>
                      </TableCell>
                      <TableCell align="center">
                        {student && student.Documents ? (
                          student.Documents.marksheet12th ? (
                            <a
                              href={student.Documents.marksheet12th}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "blue" }}
                            >
                              View 12th Marksheet
                            </a>
                          ) : student.Documents.undergraduateCertificate ? (
                            <a
                              href={student.Documents.undergraduateCertificate}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "blue" }}
                            >
                              View Undergraduate Marksheet
                            </a>
                          ) : student.Documents.ItImarksheet ? (
                            <a
                              href={student.Documents.ItImarksheet}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "blue" }}
                            >
                              View ITI Marksheet
                            </a>
                          ) : student.Documents.postgraduateCertificate ? (
                            <a
                              href={student.Documents.postgraduateCertificate}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "blue" }}
                            >
                              View Postgraduate Marksheet
                            </a>
                          ) : student.Documents.marksheet10th ? (
                            <a
                              href={student.Documents.marksheet10th}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "blue" }}
                            >
                              View 10th Marksheet
                            </a>
                          ) : student.Documents.diplomaMarksheet ? (
                            <a
                              href={student.Documents.diplomaMarksheet}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "blue" }}
                            >
                              View Diploma Marksheet
                            </a>
                          ) : (
                            "No Marksheet Available"
                          )
                        ) : (
                          "No Marksheet Available"
                        )}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <Button
                          variant="success"
                          className="butt"
                          onClick={() => handleApprove(student._id)}
                        >
                          Approve
                        </Button>
                        <Button
                          variant="danger"
                          className="butt"
                          onClick={() => handleCancel(student._id)}
                        >
                          Cancel
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[8, 25, 50]}
              component="div"
              count={studentData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Paper>
        )}
      </Box>
    </>
  );
};
export default SearchPen;
