import React, { useState, useEffect } from "react";
import {
  Paper,
  CircularProgress,
  Container,
  ThemeProvider,
  createTheme,
  TableRow,
  Button,
  Table,
  TablePagination,
  TableBody,
  TableCell,
  TableHead,
  Box,
  TableContainer,
} from "@mui/material";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";
import * as XLSX from "xlsx";
import { SearchIcon } from "@chakra-ui/icons";

const theme = createTheme();
const EnrolledStudent_list = ({
  session,
  courseType,
  course,
  branch,
  college,
  searchResult,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [studentdata, setStudentData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const Excel = studentdata.map((student) => ({
    EnrollmentNumber: student.enrollmentNumber,
    Name: student.name,
    Fathers_Name: student.fathersname,
    Mothers_Name: student.mothersname,
    Branch: student.courseBranch,
    Course: student.courseName,
    Course_Type: student.courseType,
    College_Name: student.assignedCollege,
    Fee_Status: student.isPaid ? "Paid" : "Not Paid",
  }));

  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (searchResult && searchResult.length > 0) {
          setStudentData(searchResult);
        } else {
          const response = await fetch(
            `https://sssutms.ac.in/apitest/v2/listofenrolledstudent?session=${session}&courseType=${courseType}&course=${course}&branch=${branch}&college=${college}`
          );

          const data = await response.json();

          if (data.length === 0) {
            setStudentData([]);
          } else {
            setStudentData(data);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [session, courseType, course, branch, college, searchResult]);

  const filterData = () => {
    const filtered = studentdata.filter(
      (student) =>
        student.randomId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.enrollmentNumber
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        student.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  };
  useEffect(() => {
    filterData();
  }, [searchQuery, studentdata]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // /////////////////////////////////name sort/////////////////////////
  const handleSort = (column) => {
    if (column === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  // Sorted and Sliced Data (Updated)
  const getSortedAndSlicedData = () => {
    const sortedStudents = [...filteredData].sort((a, b) => {
      if (sortBy === "name") {
        return sortOrder === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else if (sortBy === "enrollmentNumber") {
        return sortOrder === "asc"
          ? a.enrollmentNumber.localeCompare(b.enrollmentNumber)
          : b.enrollmentNumber.localeCompare(a.enrollmentNumber);
      }

      return 0;
    });

    const startIndex = page * rowsPerPage;
    return sortedStudents.slice(startIndex, startIndex + rowsPerPage);
  };

  //////////////////////export data to excel ////////////////////////
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(Excel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Enrolled Students");
    XLSX.writeFile(workbook, "Enrollment_Generated_Std.xlsx");
  };

  // ///////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <style>
        {`
  .custom-tablecell{
    font-family:'Poppins',Sans-serif;
    color: white;
    background-color: #023047;
    font-size:13px;
    padding:5px;

    font-weight:700;
    }`}
      </style>
      <ThemeProvider theme={theme}>
        <AdminDashboard />
        {loading ? (
          <CircularProgress
            color="inherit"
            style={{ marginTop: "80px", marginLeft: "50%" }}
          />
        ) : (
          <Container
            className="shadow p-3 bg-body rounded"
            style={{
              width: "90%",
              height: "80%",
              backgroundColor: "#00cdac",
              marginTop: "80px",
              marginLeft: "9%",
            }}
          >
            <Button
              style={{
                float: "right",
                marginRight: "10px",
                marginBottom: "10px",
              }}
              variant="outlined"
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
            <Box style={{ float: "left" }}>
              <SearchIcon sx={{ mr: 1 }} />
              <input
                type="text"
                placeholder="Search by ID or Name"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Box>
            <TableContainer
              component={Paper}
              sx={{ maxHeight: "400px", width: "100%" }}
            >
              <Table stickyHeader aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell className="custom-tablecell">
                      {" "}
                      S.No.
                    </TableCell>

                    <TableCell className="custom-tablecell">
                      {" "}
                      Session{" "}
                    </TableCell>
                    <TableCell
                      className="custom-tablecell"
                      style={{
                        position: "sticky",
                        left: 0,
                        zIndex: 2,
                        backgroundColor: " #023047",
                      }}
                      onClick={() => handleSort("enrollmentNumber")}
                    >
                      {" "}
                      Enrollment No.
                      {sortBy === "enrollmentNumber" &&
                        (sortOrder === "asc" ? "▲" : "▼")}
                    </TableCell>

                    <TableCell
                      className="custom-tablecell"
                      style={{ position: "sticky", top: 0, zIndex: 1 }}
                    >
                      {" "}
                      Registration No.{" "}
                    </TableCell>
                    <TableCell
                      className="custom-tablecell"
                      style={{
                        color: "white",
                        minWidth: "200px",
                        position: "sticky",
                        left: "150px", // Adjust based on the width of the Enrollment Number column
                        top: 0,
                        zIndex: 2,
                      }}
                      // /////////////for sorting name//////////////////
                      onClick={() => handleSort("name")}
                    >
                      {" "}
                      Student Name
                      {/* /////////////name sort////////////////////////// */}
                      {sortBy === "name" && (
                        <span>{sortOrder === "asc" ? " ↑" : " ↓"}</span>
                      )}
                    </TableCell>
                    <TableCell
                      className="custom-tablecell"
                      style={{ position: "sticky", top: 0, zIndex: 1 }}
                    >
                      {" "}
                      Father's Name{" "}
                    </TableCell>
                    <TableCell
                      className="custom-tablecell"
                      style={{ position: "sticky", top: 0, zIndex: 1 }}
                    >
                      {" "}
                      Mother's Name{" "}
                    </TableCell>
                    <TableCell
                      className="custom-tablecell"
                      style={{ position: "sticky", top: 0, zIndex: 1 }}
                    >
                      {" "}
                      Course Branch{" "}
                    </TableCell>

                    <TableCell
                      className="custom-tablecell"
                      style={{ position: "sticky", top: 0, zIndex: 1 }}
                    >
                      {" "}
                      Course Name{" "}
                    </TableCell>
                    <TableCell
                      className="custom-tablecell"
                      style={{ position: "sticky", top: 0, zIndex: 1 }}
                    >
                      {" "}
                      College Name{" "}
                    </TableCell>
                    <TableCell
                      className="custom-tablecell"
                      style={{
                        minWidth: "100px",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                      }}
                    >
                      {" "}
                      Registration Fee{" "}
                    </TableCell>
                    <TableCell
                      className="custom-tablecell"
                      style={{ position: "sticky", top: 0, zIndex: 1 }}
                    >
                      {" "}
                      Enrollment Fee{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {getSortedAndSlicedData().map((student, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ minWidth: "50px" }}>
                        {index + 1}
                      </TableCell>
                      <TableCell style={{ minWidth: "80px" }}>
                        {student.admissionSession}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#A94A4A",
                          minWidth: "150px",
                          fontWeight: 700,
                          position: "sticky",
                          left: "0px",
                          top: 0,
                          zIndex: "1",
                          backgroundColor: "white",
                        }}
                      >
                        {student.enrollmentNumber || "not generated"}
                      </TableCell>
                      <TableCell
                        style={{
                          minWidth: "130px",
                          fontSize: "13px",
                          padding: "5px",
                        }}
                      >
                        {student.randomId}
                      </TableCell>
                      <TableCell
                        style={{
                          minWidth: "220px",
                          fontSize: "14px",
                          padding: "5px",
                          color: "#16404D",
                          fontWeight: 700,
                          position: "sticky",
                          left: "150px",
                          top: 0,
                          zIndex: "1",
                          backgroundColor: "white",
                        }}
                      >
                        {student.name}
                      </TableCell>
                      <TableCell
                        style={{
                          minWidth: "200px",
                          fontSize: "12px",
                          padding: "5px",
                        }}
                      >
                        {student.fathersname}
                      </TableCell>
                      <TableCell
                        style={{
                          minWidth: "200px",
                          fontSize: "12px",
                          padding: "5px",
                        }}
                      >
                        {student.mothersname}
                      </TableCell>
                      <TableCell
                        style={{
                          minWidth: "200px",
                          fontSize: "12px",
                          padding: "5px",
                        }}
                      >
                        {student.courseBranch}
                      </TableCell>
                      <TableCell
                        style={{
                          minWidth: "220px",
                          fontSize: "12px",
                          padding: "5px",
                        }}
                      >
                        {student.courseName}
                      </TableCell>
                      <TableCell
                        style={{
                          minWidth: "220px",
                          textTransform: "uppercase",
                          fontSize: "12px",
                          padding: "5px",
                        }}
                      >
                        {student.assignedCollege}
                      </TableCell>
                      <TableCell style={{ color: "green" }}>
                        {student.isPaid ? "Paid" : "unpaid"}
                      </TableCell>
                      <TableCell style={{ color: "green" }}>
                        {student.isEnrollPaid ? "Paid" : "unpaid"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[4, 10, 25, 100]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}

            />
          </Container>
        )}
      </ThemeProvider>
    </>
  );
};

export default EnrolledStudent_list;
