

import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  FormControl,
  Col,
  Button,
  Spinner,
  Alert,
  Card,
} from "react-bootstrap";
import swal from "sweetalert";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Users } from "lucide-react";
import { Box } from "@mui/material";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";

const Updatestudentinfo = () => {
  const [courseData, setCourseData] = useState({});
  const [selectedType, setSelectedType] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [loading, setLoading] = useState(false);
  const session = new Date().getFullYear();
  const { randomId } = useParams();
  const [userData, setUserData] = useState(null);
  const [enteredValue, setEnteredValue] = useState("");
  const [showUpdateComponent, setShowUpdateComponent] = useState(false);

  const handleSearchById = async () => {
    if (!enteredValue) {
      alert("Please enter a Registration No.");
      return;
    }
    try {
      const response = await axios.get(
        `https://sssutms.ac.in/apitest/admin/search2?search=${enteredValue}`
      );

      if (response.data && response.data.length > 0) {
        setUserData(response.data[0]);
        setShowUpdateComponent(true);
      } else {
        alert("No results found.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("An error occurred while fetching student data.");
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    fathersname: "",
    mothersname: "",
    gender: "",
    email: "",
    dob: "",
    courseType: "",
    courseName: "",
    courseBranch: "",
    qualification: "",
    passingYear: "",
    mobile: "",
    category: "",
    adhar: "",
    samargid: "",
    domicile: "",
    obtainMarks: "",
    lastPassedSubject: "",
    LastPercentage: "",
    academicDetails: {}, // Initialize as an object
    abcId: "",
    assignedCollege: "",
  });
  const [collegeOptions, setCollegeOptions] = useState([]);
  const [loadingColleges, setLoadingColleges] = useState(false);

  useEffect(() => {
    const fetchColleges = async () => {
      if (!selectedCourse) {
        setCollegeOptions([]);
        return;
      }
      setLoadingColleges(true);
      try {
        const response = await fetch(
          `https://sssutms.ac.in/apitest/admin/search-colleges?courseName=${selectedCourse}`
        );
        const data = await response.json();
        setCollegeOptions(data.college);
      } catch (error) {
        console.error("Error fetching colleges:", error);
      } finally {
        setLoadingColleges(false);
      }
    };

    fetchColleges();
  }, [selectedCourse]);

  // Fetch course data on component mount
  useEffect(() => {
    const fetchColleges = async () => {
      if (!selectedCourse) {
        setCollegeOptions([]);
        return;
      }
      setLoadingColleges(true);
      try {
        const response = await fetch(
          `https://sssutms.ac.in/apitest/admin/search-colleges?courseName=${selectedCourse}`
        );
        const data = await response.json();
        setCollegeOptions(data.college);
      } catch (error) {
        console.error("Error fetching colleges:", error);
      } finally {
        setLoadingColleges(false);
      }
    };

    fetchColleges();
  }, [selectedCourse]);

  // Fetch course data on component mount
  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://sssutms.ac.in/apitest/v2/admin/courses?session=${session}`
        );
        const data = await response.json();
        const formattedData = {};
        data.forEach((course) => {
          if (!formattedData[course.courseType]) {
            formattedData[course.courseType] = {
              courses: [],
              branches: {},
            };
          }
          course.courseNames.forEach((courseName) => {
            formattedData[course.courseType].courses.push(
              courseName.courseName
            );
            formattedData[course.courseType].branches[courseName.courseName] =
              courseName.branches.map((branch) => branch.branchName);
          });
        });
        setCourseData(formattedData);
      } catch (error) {
        console.error("Error fetching course data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCourses();
  }, [session]);

  // Populate form data when `userData` is available
  useEffect(() => {
    if (userData) {
      setFormData((prevData) => ({
        ...prevData,
        ...userData,
      }));
      setSelectedType(userData.courseType || "");
      setSelectedCourse(userData.courseName || "");
      setSelectedBranch(userData.courseBranch || "");
    }
  }, [userData]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle dropdown changes
  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    setSelectedCourse("");
    setSelectedBranch("");
  };

  const handleCourseChange = (e) => {
    setSelectedCourse(e.target.value);
    setSelectedBranch("");
  };

  const handleBranchChange = (e) => {
    setSelectedBranch(e.target.value);
  };

  const formatDateForBackend = (dateString) => {
    if (!dateString) return "";
    const parts = dateString.split("-"); // Split "2005-04-30" into ["2005", "04", "30"]
    return `${parts[2]}-${parts[1]}-${parts[0]}`; // Convert back to "30-04-2005"
  };
  

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Prepare updated data
      const updatedData = {
        ...formData,
        dob: formData.dob ? formatDateForBackend(formData.dob) : "",
        courseType: selectedType,
        courseName: selectedCourse,
        courseBranch: selectedBranch,
      };

      console.log("Sending updated data to backend:", updatedData);

      // Send PATCH request to backend
      const response = await fetch(
        `https://sssutms.ac.in/apitest/student-details/update/${userData.randomId}`,

        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (response.status === 200) {
        const responseData = await response.json();
        swal({
          title: "Success",
          text: "Student information updated successfully!",
          icon: "success",
          button: "OK",
        });
      } else {
        const errorData = await response.json();
        swal({
          title: "Error",
          text: errorData.message || "Failed to update student information",
          icon: "error",
          button: "OK",
        });
      }
    } catch (error) {
      console.error("Error updating student:", error);
      swal({
        title: "Error",
        text: "An error occurred while updating student information",
        icon: "error",
        button: "OK",
      });
    } finally {
      setLoading(false);
    }
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    const parts = dateString.split("-"); // Split "30-04-2005" into ["30", "04", "2005"]
    return `${parts[2]}-${parts[1]}-${parts[0]}`; // Rearrange to "2005-04-30"
  };

  return (
    <>
      {" "}
      <AdminDashboard />
      <style>
        {`
      *{
            font-family: 'Poppins', sans-serif !important;
           
        }
            
      .custom-label{
        color:#344e41;
     font-weight:600;
        font-family: 'Poppins', sans-serif !important;
      font-size:15px;
        text-transformation: uppercase;
        }


        .custom-input {
  color: black !important; /* Text color */
  font-size: 14px;
  
   font-family: 'Poppins', sans-serif !important;

}
        `}
      </style>
      <div
        className="bg-body rounded"
        style={{ width: "900px", marginTop: "80px", marginLeft: "120px" }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 4, gap: 2 }}>
          <Users size={32} className="text-blue-600" />
          <h4
            sx={{
              fontWeight: 700,
              fontFamily: "'Poppins',Sans-serif",
              color: "#1e293b",
              fontSize: { xs: "1.5rem", sm: "2rem" },
            }}
          >
            {" "}
            Student Details Management{" "}
          </h4>{" "}
        </Box>

        <div className="d-flex flex-column mt-1">
          <label
            className="mb-2"
            style={{
              fontWeight: 700,
              color: "#264653",
              fontFamily: "'Poppins',Sans-serif",
            }}
          >
            Enter Registration Number *
          </label>
          <div className="d-flex">
            <input
              type="text"
              className="form-control me-2"
              value={enteredValue}
              onChange={(e) => setEnteredValue(e.target.value)}
              placeholder="Enter Registration Number"
              style={{ fontFamily: "'Poppins',Sans-serif" }}
            />
            <button
              style={{
                height: "40px",
                width: "100px",
                padding: "10px",
                fontFamily: "'Poppins',Sans-serif",
              }}
              className="btn btn-primary"
              onClick={handleSearchById}
            >
              Search
            </button>
          </div>
        </div>
      </div>
      <div style={{ marginLeft: "120px", marginTop: "20px", width: "85%" }}>
        {showUpdateComponent && userData && (
          <Card className="p-1">
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                {/* Personal Details */}
                <Row className="mb-1">
                  <Col sm={4}>
                    <Form.Group controlId="formName">
                      <Form.Label className="custom-label">
                        Student Name
                      </Form.Label>
                      <Form.Control
                        className="custom-input"
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Enter student's name"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="formFathersName">
                      <Form.Label className="custom-label">
                        Father's Name
                      </Form.Label>
                      <Form.Control
                        className="custom-input"
                        type="text"
                        name="fathersname"
                        value={formData.fathersname}
                        onChange={handleInputChange}
                        placeholder="Enter father's name"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="formMothersName">
                      <Form.Label className="custom-label">
                        Mother's Name
                      </Form.Label>
                      <Form.Control
                        className="custom-input"
                        type="text"
                        name="mothersname"
                        value={formData.mothersname}
                        onChange={handleInputChange}
                        placeholder="Enter mother's name"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* Contact and Personal Info */}
                <Row className="mb-1">
                  <Col sm={4}>
                    <Form.Group controlId="formEmail">
                      <Form.Label className="custom-label">Email</Form.Label>
                      <Form.Control
                        className="custom-input"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Enter email"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <div>
                      <Form.Group controlId="formDob">
                        <Form.Label className="custom-label">
                          Date of Birth
                        </Form.Label>
                        <Form.Control
                          // type="date"
                          name="dob"
                          value={
                            formData.dob ? formatDateForInput(formData.dob) : ""
                          }
                          onChange={handleInputChange}
                          className="custom-input"
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="formGender">
                      <Form.Label className="custom-label">Gender</Form.Label>
                      <Form.Control
                        className="custom-input"
                        type="text"
                        name="gender"
                        value={formData.gender}
                        onChange={handleInputChange}
                        placeholder="Enter gender"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* Course Details */}
                <Row className="mb-1">
                  <Col sm={4}>
                    <Form.Group controlId="formCourseType">
                      <Form.Label className="custom-label">
                        Course Type
                      </Form.Label>
                      <Form.Select
                        className="custom-input"
                        value={selectedType}
                        onChange={handleTypeChange}
                      >
                        <option value="">Select Type</option>
                        {Object.keys(courseData).map((type) => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="formCourse">
                      <Form.Label className="custom-label">
                        Course Name
                      </Form.Label>
                      <Form.Select
                        className="custom-input"
                        value={selectedCourse}
                        onChange={handleCourseChange}
                        disabled={!selectedType}
                      >
                        <option value="">Select Course</option>
                        {selectedType &&
                          courseData[selectedType]?.courses.map((course) => (
                            <option key={course} value={course}>
                              {course}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="formBranch">
                      <Form.Label className="custom-label">Branch</Form.Label>
                      <Form.Select
                        className="custom-input"
                        value={selectedBranch}
                        onChange={handleBranchChange}
                        disabled={!selectedCourse}
                      >
                        <option value="">Select Branch</option>
                        {selectedCourse &&
                          courseData[selectedType]?.branches[
                            selectedCourse
                          ]?.map((branch) => (
                            <option key={branch} value={branch}>
                              {branch}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Academic Details */}
                <Row className="mb-1">
                  <Col sm={4}>
                    <Form.Group controlId="formQualification">
                      <Form.Label className="custom-label">
                        Qualification
                      </Form.Label>
                      <Form.Control
                        className="custom-input"
                        type="text"
                        name="qualification"
                        value={formData.qualification}
                        onChange={handleInputChange}
                        placeholder="Enter Qualification"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="formPassingYear">
                      <Form.Label className="custom-label">
                        Passing Year
                      </Form.Label>
                      <Form.Control
                        className="custom-input"
                        type="text"
                        name="passingYear"
                        value={formData.passingYear}
                        onChange={handleInputChange}
                        placeholder="Enter Passing Year"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="formMobile">
                      <Form.Label className="custom-label">Contact</Form.Label>
                      <Form.Control
                        className="custom-input"
                        type="text"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleInputChange}
                        placeholder="Enter Contact Number"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* Additional Details */}
                <Row className="mb-1">
                  <Col sm={4}>
                    <Form.Group controlId="formAbcId">
                      <Form.Label className="custom-label">ABC ID</Form.Label>
                      <Form.Control
                        className="custom-input"
                        type="text"
                        name="AbcId"
                        value={formData.AbcId}
                        onChange={handleInputChange}
                        placeholder="Enter ABC ID"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="formCategory">
                      <Form.Label className="custom-label">Category</Form.Label>
                      <Form.Control
                        className="custom-input"
                        type="text"
                        name="category"
                        value={formData.category}
                        onChange={handleInputChange}
                        placeholder="Enter Category"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="formDomicile">
                      <Form.Label className="custom-label">Domicile</Form.Label>
                      <Form.Control
                        className="custom-input"
                        type="text"
                        name="domicile"
                        value={formData.domicile}
                        onChange={handleInputChange}
                        placeholder="Enter Domicile"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-1">
                  <Col sm={4}>
                    <Form.Group controlId="formAbcId">
                      <Form.Label className="custom-label">
                        Last Passed Subject
                      </Form.Label>
                      <Form.Control
                        className="custom-input"
                        type="text"
                        name="lastPassedSubject"
                        value={formData.lastPassedSubject}
                        onChange={handleInputChange}
                        placeholder="Enter lastPassedSubject"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="formCategory">
                      <Form.Label className="custom-label">
                        Last Percentage
                      </Form.Label>
                      <Form.Control
                        className="custom-input"
                        type="text"
                        name="LastPercentage"
                        value={formData.LastPercentage}
                        onChange={handleInputChange}
                        placeholder="Enter Last Percentage"
                      />{" "}
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="formDomicile">
                      <Form.Label className="custom-label">College</Form.Label>
                      <Form.Select
                        className="custom-input"
                        name="assignedCollege"
                        value={formData.assignedCollege}
                        onChange={handleInputChange}
                        disabled={
                          loadingColleges || collegeOptions.length === 0
                        }
                      >
                        <option value="">Select College</option>
                        {collegeOptions.map((college) => (
                          <option key={college} value={college}>
                            {college}
                          </option>
                        ))}
                      </Form.Select>
                      {loadingColleges && <div>Loading colleges...</div>}
                    </Form.Group>
                  </Col>
                </Row>
                {/* Submit Button */}
                <Button
                  type="submit"
                  style={{
                    backgroundColor: "#003049",
                    padding: "5px",
                    width: "200px",
                    height: "40px",
                    fontSize: "15px",
                  }}
                >
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        )}
      </div>
    </>
  );
};

export default Updatestudentinfo;
