import React from "react";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";
import { Link } from "react-router-dom";

function AcademicCalender() {
  return (
    <>
      <Nav />
      <style>
        {`

  

.cont{
margin-top:20px;}



h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  .alt-two h1 {
    text-align:center;
  }
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }
  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
.listy{
font-size:21px; 
 margin-left:15px;
 margin-top:8px;
// font-weight:700;
 list-style-type: disc; /* This will add bullets to the list */

  
}

   .llis{
  font-size:17px; 
 margin-left:15px;
 margin-top:18px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



 
  
     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:-18px;
          margin-left:50px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  



.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }


.title-hr {
width: 118px;
border-top: 5px solid #FA812F;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}


 



@media only screen and (max-width: 480px){




.h2 title-main{
margin-left:18px}




h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>

      <div className="cont">
        <h2 className="h2 title-main" style={{ color: "#780000" }}>
          Academic Calender
        </h2>
        <hr className="title-hr"></hr>

        <div className="row dirr">
          <br></br>
          <h5
            style={{
              fontFamily: "'poppins',sansSerif",
              textAlign: "center",
              color: "#FA812F",
              fontFamily: "'poppins',sansSerif",
            }}
          >
            Academic Calender for the Year 2024-25
          </h5>

          <div className="col-lg-9 padding-section">
            <ul className="list-icon" style={{ marginTop: "50px" }}>


            <li className="llis">
                <Link  target="_blank"   to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/Academic_calender/B_PHARMA.pdf">
                  <i class="fa fa-share" aria-hidden="true"></i>
                  <span
                    style={{
                      fontWeight: 700,
                      fontFamily: "'poppins',sansSerif",
                    }}
                  >
                    Academic Calender for B PHARMA
                  </span>
                </Link>
              </li>
              <li className="llis">
                <Link  target="_blank" to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/Academic_calender/UTD.pdf">
                  <i class="fa fa-share" aria-hidden="true"></i>{" "}
                  <span
                    style={{
                      fontWeight: 700,
                      fontFamily: "'poppins',sansSerif",
                    }}
                  >
                    {" "}
                    ACADEMIC CALENDER FOR THE YEAR 2024-25 (UTD){" "}
                  </span>
                </Link>
              </li>

              <li className="llis">
                <Link  target="_blank" to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/Academic_calender/B.A.B.Ed BPED.pdf">
                  <i class="fa fa-share" aria-hidden="true"></i>
                  <span
                    style={{
                      fontWeight: 700,
                      fontFamily: "'poppins',sansSerif",
                    }}
                  >
                    Academic Calender for the year 2024-2025 B.A.B.Ed
                    /B.Ed/B.P.Ed
                  </span>
                </Link>
              </li>
              <li className="llis">
                <Link  target="_blank" to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/Academic_calender/DPHARMA.pdf">
                  <i class="fa fa-share" aria-hidden="true"></i>
                  <span
                    style={{
                      fontWeight: 700,
                      fontFamily: "'poppins',sansSerif",
                    }}
                  >
                    {" "}
                    ACADEMIC CALENDER FOR I & II YEAR 2024-25 FOR D.PHARMA
                  </span>
                </Link>
              </li>
              <li className="llis">
                <Link  target="_blank" to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/Academic_calender/PARAMEDICAL.pdf">
                  <i class="fa fa-share" aria-hidden="true"></i>
                  <span
                    style={{
                      fontWeight: 700,
                      fontFamily: "'poppins',sansSerif",
                    }}
                  >
                    Paramedical UG/PG/DIPLOMA Course (Yearly System)
                  </span>
                </Link>
              </li>
              <li className="llis">
                <Link  target="_blank" to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/Academic_calender/DENG_1YEAR.pdf">
                  <i class="fa fa-share" aria-hidden="true"></i>
                  <span
                    style={{
                      fontWeight: 700,
                      fontFamily: "'poppins',sansSerif",
                    }}
                  >
                    {" "}
                    DIPLOMA ENGG./B. E./M.TECH/MCA/MBA/M. Pharma/B.
                    Pharma/BHMCT(First Year)
                  </span>
                </Link>
              </li>
              <li className="llis">
                <Link  target="_blank" to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/Academic_calender/DENG_2YEAR.pdf">
                  <i class="fa fa-share" aria-hidden="true"></i>
                  <span
                    style={{
                      fontWeight: 700,
                      fontFamily: "'poppins',sansSerif",
                    }}
                  >
                    DIPLOMA ENGG./B. E./M.TECH/MCA/MBA/M. Pharma/B.
                    Pharma/BHMCT(Second Year)
                  </span>
                </Link>
              </li>
              <li className="llis">
                <Link  target="_blank" to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/Academic_calender/DENG_34YEAR.pdf">
                  <i class="fa fa-share" aria-hidden="true"></i>
                  <span
                    style={{
                      fontWeight: 700,
                      fontFamily: "'poppins',sansSerif",
                    }}
                  >
                    DIPLOMA ENGG. (Third Year) /B. E./B. Pharma/BHMCT(Third &
                    Fourth Year)
                  </span>
                </Link>
              </li>
              <li className="llis">
                <Link  target="_blank" to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/Academic_calender/LAW.pdf">
                  <i class="fa fa-share" aria-hidden="true"></i>
                  <span
                    style={{
                      fontWeight: 700,
                      fontFamily: "'poppins',sansSerif",
                    }}
                  >
                    Academic Calender for the year 2024-25 (School of Law)
                  </span>
                </Link>
              </li>
            </ul>

          

       
          </div>
          <div className="col-lg-3">
            <ul className="list-icon">
              <h5
                style={{ fontFamily: "'poppins',sansSerif", color: "#243642" }}
              >
                Academic
              </h5>
              <br></br>{" "}
              <li className="lli">
                <Link   to="/phd-page">
                  <i class="fa fa-adjust" aria-hidden="true"></i>PHD
                </Link>
              </li>
              <li className="lli">
                <Link  to="/event-calender">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Event
                  Calender
                </Link>
              </li>
              <li className="lli">
                <Link to="/academic-calender">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Academic
                  Calender
                </Link>
              </li>
              <li className="lli">
                <Link   to="/scholarship">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Scholarship{" "}
                </Link>
              </li>
              <li className="lli">
                <Link  to="/constituent-units">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Constituent
                  Units
                </Link>
              </li>
              <li className="lli">
                <Link   to="/Hei-handbook">
                  <i class="fa fa-adjust" aria-hidden="true"></i>HEI Handbook{" "}
                </Link>
              </li>
              <li className="lli">
                {" "}
                <Link  to="/iqac_cell">
                  <i class="fa fa-adjust" aria-hidden="true"></i>IQAC Cell
                </Link>
              </li>
              <li className="lli">
                {" "}
                <Link  to="/mandatory_disclosures">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Mandatory
                  Disclosures{" "}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* </section> */}
      <Footerpage />
    </>
  );
}

export default AcademicCalender;
