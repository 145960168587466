// import React, { useState, useEffect } from "react";
// import OnlyHeader from "../../AdminComponent/OnlyHeader";
// import { Button } from "react-bootstrap";
// import { useLocation } from "react-router-dom";

// function E_Payment() {
//   const [loading, setLoading] = useState(false);
//   const [enrollmentFee, setEnrollmentFee] = useState(0);
//   const [registrationFee, setRegistrationFee] = useState(0);
//   const [totalAmount, setTotalAmount] = useState(0);
//   const location = useLocation();
//   const { courseType, courseBranch, courseName, id, name  , email , mobile} = location.state;
    
//   console.log(
//     courseType,
//     courseName,
//     courseBranch,
//     id,
//     name,
//     "courseType,name,id, namwe"
//   );

//   useEffect(() => {
//     fetchFeeInformation();
//   }, []);
//   const fetchFeeInformation = () => {
//     const sessionYear = new Date().getFullYear();

//     const apiUrl =
//       process.env.REACT_APP_STD_PRAVESH_PAY +
//       `sessionYear=${sessionYear}&courseName=${courseName}&branchName=${courseBranch}`;

//     fetch(apiUrl)
//       .then((response) => response.json())
//       .then((data) => {
//         console.log(data[0].registrationFee, "data");
//         const totalAmount = data[0].registrationFee;

//         setRegistrationFee(data[0].registrationFee);
//         setTotalAmount(totalAmount);
//       })
//       .catch((error) => {
//         console.error("Error fetching fee information:", error);
//       });
//   };

//   function isDate(val) {
//     return Object.prototype.toString.call(val) === "[object Date]";
//   }

//   function isObj(val) {
//     return typeof val === "object";
//   }

//   function stringifyValue(val) {
//     if (isObj(val) && !isDate(val)) {
//       return JSON.stringify(val);
//     } else {
//       return val;
//     }
//   }
//   function buildForm({ action, params }) {
//     const form = document.createElement("form");
//     form.setAttribute("method", "post");
//     form.setAttribute("action", action);

//     Object.keys(params)?.forEach((key) => {
//       const input = document.createElement("input");
//       input.setAttribute("type", "hidden");
//       input.setAttribute("name", key);
//       input.setAttribute("value", stringifyValue(params[key]));
//       form.appendChild(input);
//     });
//     return form;
//   }
  
//   function post(details) {
//     const form = buildForm(details);
//     document.body.appendChild(form);
//     setLoading(false);
//     form.submit();
//     form.remove();
//   }

//   const getData = (data) => {
//     setLoading(false);
//     return fetch("https://sssutms.ac.in/apitest/epravesh/payment", {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(data),
//     })
//       .then((response) => response.json())
//       .catch((err) => console.log(err));
//   };

//   const handlePayment = (e) => {
//     <OnlyHeader />;
//     e.preventDefault();
//     setLoading(true);
//     setTimeout(() => {
//       getData({ amount: totalAmount, id , name  , email , mobile}).then((response) => {
//         var information = {
//           action: "https://securegw.paytm.in/order/process",
//           params: response,
//         };

//         post(information);
//       });
//     }, 1000);
//   };
//   return (
//     <>
//       <style>
//         {`
//   .main {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     margin: 20px;
//   }

//   .card {
//     background-color: #fff;
//     border: 1px solid #ddd;
//     border-radius: 8px;
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//     padding: 20px;
//     width: 90%; /* Adjust as needed */
//     height: 90%;
//     max-width: 800px; /* Set a maximum width if needed */
//     margin: 20px auto; /* Center the card horizontally */
//   }

//   .card p {
//     margin: 0; /* Remove the gap between paragraphs */
//     padding: 0; /* Remove padding */
//   }

//   button:hover {
//     opacity: 0.8;
//   }

//   .center {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//   }

//   .loading-container {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }

//   .spinner {
//     border: 4px solid rgba(0, 0, 0, 0.1);
//     border-top: 4px solid #11acf3;
//     border-radius: 50%;
//     width: 30px;
//     height: 30px;
//     animation: spin 1s linear infinite;
//   }

//   @keyframes spin {
//     0% { transform: rotate(0deg); }
//     100% { transform: rotate(360deg); }
//   }
//   `}
//       </style>

//       <OnlyHeader />
//       <div
//         style={{
//           background: "linear-gradient(to right, #A9F1DF , #FFBBBB)",
//           minHeight: "100vh",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <div className="card">
//           <h3>Dear {name}</h3>
//           <span>Must read these points:</span>
//           <p>1. Ensure you choose the correct course.</p>
//           <p>
//             2. Be aware that once you pay the fee, it will be non-refundable.
//           </p>
//           <p>
//             3. After Submit the fees login yourself and fill enrollment form.
//           </p>
//           <p>
//             Your details have been updated successfully. However, before
//             proceeding, you need to pay the registration fee.
          
//           </p>
//           <table
//             style={{
//               width: "100%",
//               marginTop: "20px",
//               borderCollapse: "collapse",
//             }}
//           >
//             <tbody>
//               <tr>
//                 <td style={{ padding: "10px", border: "1px solid #ddd" }}>
//                  EPravesh Registration Fee
//                 </td>
//                 <td style={{ padding: "10px", border: "1px solid #ddd" }}>
//                   ₹ {registrationFee || "0"}
//                 </td>
//               </tr>

//               <tr>
//                 <td
//                   style={{
//                     padding: "10px",
//                     fontFamily: "bold",
//                     border: "1px solid #ddd",
//                   }}
//                 >
//                   Total Amount
//                 </td>
//                 <td
//                   style={{
//                     padding: "5px",
//                     border: "1px solid #ddd",
//                     color: "#023047",
//                   }}
//                 >
//                   <span
//                     style={{
//                       fontWeight: "bolder",
//                       fontSize: "20px",
//                     }}
//                   >
//                     ₹
//                   </span>{" "}
//                   {totalAmount || "0"}
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//           <form onSubmit={handlePayment}>
//             {!loading ? (
//               <div className="col-12 center">
//                 <Button
//                   style={{
//                     margin: "30px auto",
//                     display: "block",
//                     width: "20%",
//                     backgroundImage:
//                       "linear-gradient(96deg, #ff5f6d 0%, #ffc371 100%)",
//                     color: "white",
//                     border: "none",
//                     borderRadius: "5px",
//                     fontSize: "14px !important",
//                   }}
//                   type="submit"
//                 >
//                   Pay Now
//                 </Button>
//               </div>
//             ) : (
//               <div className="loading-container">
//                 <div className="spinner"></div>
//               </div>
//             )}
//           </form>
//         </div>
//       </div>
//     </>
//   );
// }

// export default E_Payment;


import React, { useState, useEffect } from "react";
import OnlyHeader from "../../AdminComponent/OnlyHeader";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Paper,
  Container,
  Alert,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Info, CurrencyRupee, CheckCircle, Warning } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

function E_Payment() {
  const [loading, setLoading] = useState(false);
  const [registrationFee, setRegistrationFee] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const location = useLocation();
  const { courseBranch, courseName, id, name, email, mobile , admissionSession} = location.state;

  useEffect(() => {
    fetchFeeInformation();
  }, []);

  const fetchFeeInformation = () => {
    const sessionYear = new Date().getFullYear();
    // const apiUrl =
    //   process.env.REACT_APP_STD_PRAVESH_PAY +
    //   `sessionYear=${sessionYear}&courseName=${courseName}&branchName=${courseBranch}`;

    const apiUrl = `https://sssutms.ac.in/apitest/admin/course-fees/registration?admissionSession=${admissionSession}&courseName=${courseName}`
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log(data.registrationFee , "data")
        const totalAmount = data.registrationFee;
        setRegistrationFee(data.registrationFee);
        setTotalAmount(totalAmount);
      })
      .catch((error) => {
        console.error("Error fetching fee information:", error);
      });
  };

  function isDate(val) {
    return Object.prototype.toString.call(val) === "[object Date]";
  }

  function isObj(val) {
    return typeof val === "object";
  }

  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val;
    }
  }

  function buildForm({ action, params }) {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", action);

    Object.keys(params)?.forEach((key) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", stringifyValue(params[key]));
      form.appendChild(input);
    });
    return form;
  }

  function post(details) {
    const form = buildForm(details);
    document.body.appendChild(form);
    setLoading(false);
    form.submit();
    form.remove();
  }

  const getData = (data) => {
    setLoading(false);
    return fetch("https://sssutms.ac.in/apitest/epravesh/payment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .catch((err) => console.log(err));
  };

  const handlePayment = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      getData({ amount: totalAmount, id, name, email, mobile }).then(
        (response) => {
          var information = {
            action: "https://securegw.paytm.in/order/process",
            params: response,
          };
          post(information);
        }
      );
    }, 1000);
  };

  return (
    <>
      <OnlyHeader />
      <Box
        sx={{
          minHeight: "100vh",
          pt: 4,
       
          background: "linear-gradient(120deg, #f6f9fc 0%, #eef2f7 100%)",
        }}
      >
       
        <Container
          maxWidth="md"
          sx={{ py: 4, maxHeight: "100vh", overflow: "hidden" }}
        >
          <Card
            elevation={3}
            sx={{
              mt: 2,
              borderRadius: 2,
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Box
              sx={{
                background: "linear-gradient(135deg, #1e3c72 0%, #2a5298 100%)",
                py: 2,
                px: 3,
                color: "white",
              }}
            >
              <Typography variant="h5" fontWeight="500">
                Payment Details
              </Typography>
              <Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
                Welcome, {name}
              </Typography>
            </Box>

            <CardContent sx={{ p: 3 }}>
              <Alert severity="info" sx={{ mb: 1 }}>
                Please review the important information below before proceeding
                with the payment.
              </Alert>

              <List sx={{ padding: 0 }}>
                <ListItem sx={{ paddingY: 0, minHeight: "50px" }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Ensure you choose the correct course" />
                </ListItem>
                <ListItem sx={{ paddingY: 0, minHeight: "50px" }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <Warning color="warning" />
                  </ListItemIcon>
                  <ListItemText primary="Once paid, the fee is non-refundable" />
                </ListItem>
                <ListItem sx={{ paddingY: 0, minHeight: "50px" }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <Info color="info" />
                  </ListItemIcon>
                  <ListItemText primary="After payment, login to fill the enrollment form" />
                </ListItem>
              </List>

              <Paper elevation={1} sx={{ mt: 2, p: 2, bgcolor: "#f8fafc" }}>
                <Table sx={{ borderCollapse: "collapse" }}>
                  <TableBody>
                    <TableRow sx={{ height: "32px" }}>
                      <TableCell sx={{ border: "none", py: 0.5 }}>
                        <Typography variant="subtitle1">
                          EPravesh Registration Fee
                        </Typography>
                      </TableCell>
                      <TableCell align="right" sx={{ border: "none", py: 0.5 }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <CurrencyRupee sx={{ fontSize: 18, mr: 0.3 }} />
                          <Typography variant="h6">
                            {registrationFee || "0"}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ height: "36px" }}>
                      <TableCell sx={{ border: "none", py: 0.5 }}>
                        <Typography variant="subtitle1" fontWeight="600">
                          Total Amount
                        </Typography>
                      </TableCell>
                      <TableCell align="right" sx={{ border: "none", py: 0.5 }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <CurrencyRupee sx={{ fontSize: 20, mr: 0.3 }} />
                          <Typography
                            variant="h5"
                            color="primary"
                            fontWeight="600"
                          >
                            {totalAmount || "0"}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>

              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <Button
                  variant="contained"
                  size="large"
                  disabled={loading}
                  onClick={handlePayment}
                  sx={{
                    minWidth: 200,
                    py: 1.5,
                    background:
                      "linear-gradient(135deg, #1e3c72 0%, #2a5298 100%)",
                    "&:hover": {
                      background:
                        "linear-gradient(135deg, #1e3c72 0%, #1e3c72 100%)",
                    },
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Proceed to Payment"
                  )}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
}

export default E_Payment;
