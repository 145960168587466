///////////////ye shi h  code ///////////////////
import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";


const styles = StyleSheet.create({
  page: { padding: 20, fontSize: 10, flexDirection: "column", width: "100%" },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 3,
  },
  logo: { width: 60, height: 55, marginRight: 5 },
  headerText: {
    textAlign: "center",
    fontSize: 12,
    fontWeight: "bold",
    color: "brown",
    flex: 1,
    marginBottom: -2,
  },
  subHeader: {
    textAlign: "center",
    fontSize: 11,
    marginBottom: 5,
    marginTop: -2,
  },
  infoSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 3,
  },
  infoTextLeft: { fontSize: 9, textAlign: "left", width: "50%" },
  infoTextRight: { fontSize: 9, textAlign: "right", width: "50%" },
  line: { borderBottomWidth: 1, borderBottomColor: "#000", marginBottom: 8 },
  table: {
    display: "table",
    width: "100%",
    borderWidth: 1,
    borderColor: "#000",
    marginTop: 10,
  },
  tableRow: { flexDirection: "row" },
  tableHeader: { backgroundColor: "#ccc", fontWeight: "bold" },
  tableCell: {
    borderWidth: 1,
    borderColor: "#000",
    padding: 4,
    textAlign: "center",
    fontSize: 8,
    flex: 3,
  },
  studentCell: { flex: 3 },
  name: { flex: 6 },
  photoCell: { flex: 2 },
  subjectsCell: { flex: 20 },
  smallCell: {
    borderWidth: 1,
    borderColor: "#000",
    padding: 4,
    textAlign: "center",
    fontSize: 8,
    width: 25,
  },
  nestedCell: {
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    padding: 4,
    textAlign: "center",
    fontSize: 6,
    color: "gray",
  },
  subjectCodeCell: {

    borderBottomWidth: 1,
    borderBottomColor: "#000",
    padding: 4,
    textAlign: "center",
    fontSize: 6,
    color: "black",
  },
  image: { width: 67, height: 45, margin: "auto" },
  footer: {
    position: "absolute",
    bottom: 8,
    width: "100%",
    fontSize: 9,
  },
  footerLine: { borderBottomWidth: 1, borderBottomColor: "#000", marginBottom: 7 },
  footerRow: {
    flexDirection: "row",
    marginBottom: 18,
    paddingHorizontal: 20,
  },
  leftFooter: {
    width: "60%",
  },
  rightFooter: {
    marginRight: 620,
    width: "40%",
  },
  footerText: { fontSize: 9 },
  signatureLines: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: 10,
  },
  signatureLine: {
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    width: 9120,
    marginLeft: 5,
  },
  noteSection: { marginBottom: 5, fontSize: 7 },
});

const AttendancePDF = ({ students, course, branch, Semester, college, examCenter, examTime, startDate, endDate, }) => {

  console.log(students, "students")
  return (
    <Document>
      {Array.from({ length: Math.ceil(students.length / 3) }).map((_, pageIndex) => (

        <Page
          key={pageIndex}
          size={{ width: 842, height: 595 }}
          style={styles.page}
          wrap
        >
          <View style={styles.headerContainer}>
            <Image
              style={styles.logo}
              src="https://cdn.sssutms.ac.in/media/static/images/SSSUTMS_LOGO.png"
            />
            <Text style={styles.headerText}>
              SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE
            </Text>
          </View>
          <Text style={styles.subHeader}>
            RECORD OF ANSWER BOOK AND ATTENDANCE
          </Text>
          <Text style={styles.subHeader}>Regular Exam {Semester} SEMESTER {startDate}</Text>
          <View style={styles.infoSection}>

            <View style={{ width: "90%" }}>
              <Text style={styles.infoTextLeft}>Course/Programme : {course}</Text>
              <Text style={styles.infoTextLeft}>Institute/College Name : {college}</Text>
            </View>
            <Text style={styles.infoTextRight}>Exam Time : {examTime}</Text>
          </View>
          <View style={styles.infoSection}>
            <Text style={styles.infoTextLeft}>
              Branch/Specialization : {branch}
            </Text>
            <Text style={styles.infoTextRight}>
              Examination Center : {examCenter}
            </Text>

          </View>
          <View style={styles.line} />
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeader]}>
              <Text style={styles.smallCell}>S.No</Text>
              <Text style={[styles.tableCell, styles.studentCell]}>
                Student Information
              </Text>
              <Text style={[styles.tableCell, styles.photoCell]}>Photo</Text>

              <Text style={[styles.tableCell, styles.subjectsCell]}>Subjects</Text>
            </View>
            {students
              .slice(pageIndex * 3, (pageIndex + 1) * 3)
              .map((student, index) => (
                <View style={styles.tableRow} key={student.id}>
                  <Text style={styles.smallCell}>{pageIndex * 3 + index + 1}</Text>
                  <Text style={[styles.tableCell, styles.studentCell, styles.name]}>
                    {student.id} {student.name} {student.father}
                  </Text>
                  <Image style={styles.image} src={student.photo} />
                  {Array.from({ length: 11 }).map((_, i) => {
                    const subject = student.subjects?.[i] || null;
                    return (
                      <View style={styles.tableCell} key={i}>
                        <View style={[styles.nestedCell, styles.subjectCodeCell]}>
                          <Text>{subject ? subject.subjectCode : ''}</Text>
                        </View>
                        <View style={styles.nestedCell}>
                          <Text>{subject ? 'Ans. Book No.' : ''}</Text>
                        </View>
                        <View style={styles.nestedCell}>
                          <Text>{subject ? 'Add. Ans. Book No.' : ''}</Text>
                        </View>
                        <View style={styles.nestedCell}>
                          <Text>{subject ? 'Signature with Date' : ''}</Text>
                        </View>
                      </View>
                    );
                  })}
                </View>
              ))}
          </View>
          <View style={styles.footer}>
            <View style={styles.footerRow}>
              <View style={styles.leftFooter}>
                <Text>Signature & Seal of</Text>
                <Text>Centre Superintendent</Text>
              </View>
              <View style={styles.rightFooter}>
                <Text>Invig. Sign.:</Text>
                <Text>Date:</Text>
                <View style={styles.signatureLines}>
                  {[...Array(10)].map((_, i) => (
                    <View key={i} style={styles.signatureLine} />
                  ))}
                </View>
              </View>
            </View>

            <View style={styles.footerLine} />
            <View style={styles.noteSection}>
              <Text>
                Note: 1. Any discrepancy in candidate’s, Father’s, Mother’s,
                Husband’s name etc. should be informed to the office of the
                controller examination immediately.
              </Text>
              <Text>
                2. While taking signature of the candidate, do not make any change
                in the subject.
              </Text>
              <Text>
                3. Put absent with red ink only against those who are absent
                otherwise put cross.
              </Text>
              <Text>4. Do not leave any entry blank.</Text>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
}

const DownloadButton = ({ students, course, branch, Semester, college, examCenter, examTime, startDate, subjects }) => (
  <div style={{ textAlign: "end"}}>
    <span style={{  padding: "10px", background: "#007bff", color: "#fff", borderRadius: "5px"}}>
      <PDFDownloadLink
        document={<AttendancePDF
          students={students}
          course={course}
          branch={branch}
          examCenter={examCenter}
          examTime={examTime}
          startDate={startDate}
          Semester={Semester}
          college={college}
          subjects={subjects}
        />}
        fileName={`${course}_${college}_raba.pdf`}
        style={{ textDecoration: "none", color: "white" }}
      >
        {({ loading }) => (loading ? "Generating PDF..." : "Download Raba PDF")}
      </PDFDownloadLink>
    </span>
  </div>
);

export default DownloadButton;
///////////////////////////////////////////////////////////




