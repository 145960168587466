// import React, { useEffect, useState } from "react";
// import swal from "sweetalert";
// import {
//   Box,
//   CardContent,
//   CircularProgress,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Button,
//   Checkbox,
// } from "@mui/material";
// import { Form, Row, Col } from "react-bootstrap";
// import Modal from "react-bootstrap/Modal";
// import sssutms from "../../../Main_Admin/images/sssutms.jpg";
// import Typography from "@mui/material/Typography";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import StudentDashboard from "../../../AdminComponent/StudentDashboard";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import OnlyHeader from "../../../AdminComponent/OnlyHeader";

// const theme = createTheme({
//   typography: {
//     fontWeightBold: 700,
//   },
// });

// function Std_ExamForm() {
//   const [sortBy, setSortBy] = useState("");
//   const [sortOrder, setSortOrder] = useState("asc");
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(8);
//   const [examForms, setExamForms] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [ischecked, setIschecked] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isApiSuccess, setIsApiSuccess] = useState(false);
//   const [ischecked2, setIschecked2] = useState(false);

//   const [majorSubjects, setMajorSubjects] = useState([]);
//   const [minorSubjects, setMinorSubjects] = useState([]);
//   const [genericElectives, setGenericElectives] = useState([]);
//   const [foundationElectives, setFoundationElectives] = useState([]);
//   const [selectedSubjects, setSelectedSubjects] = useState({
//     theory: null,
//     practical: null,
//   });

//   const [compulsorySubjects, setCompulsorySubjects] = useState([]);
//   const [nonCompulsorySubjects, setNonCompulsorySubjects] = useState([]);
//   const [formData, setFormData] = useState(null);
//   const [formData2, setFormData2] = useState(null);
//   const [selectednepSubjects, setselectednepSubjects] = useState([]);

//   const userData = JSON.parse(sessionStorage.getItem("currentUser"));

//   const handleSaveSubjects = async () => {
//     setIsLoading(true);
//     try {
//       await new Promise((resolve) => setTimeout(resolve, 2000));

//       let { theory, practical } = selectedSubjects;

//       // Get all selected subjects (both theory and practical)
//       const selectedNonCompulsorySubjects = nonCompulsorySubjects
//         .filter(
//           (subject) =>
//             selectedSubjects[subject.subjectType.toLowerCase()]?.subjectCode ===
//             subject.subjectCode
//         )
//         .map((subject) => ({
//           subjectCode: subject.subjectCode,
//           subjectName: subject.subjectName,
//           subjectType: subject.subjectType,
//         }));

//       // Automatically add corresponding Practical if Theory is selected
//       let finalSubjects = [
//         ...compulsorySubjects,
//         ...selectedNonCompulsorySubjects,
//       ];

//       selectedNonCompulsorySubjects.forEach((subject) => {
//         if (subject.subjectType === "Theory") {
//           let correspondingPractical = nonCompulsorySubjects.find(
//             (sub) =>
//               sub.subjectName.replace(" [T]", "").trim() ===
//                 subject.subjectName.replace(" [T]", "").trim() &&
//               sub.subjectType === "Practical"
//           );

//           if (correspondingPractical) {
//             finalSubjects.push({
//               subjectCode: correspondingPractical.subjectCode,
//               subjectName: correspondingPractical.subjectName,
//               subjectType: "Practical",
//             });
//           }
//         }
//       });

//       // Remove duplicate subjects
//       finalSubjects = finalSubjects.filter(
//         (value, index, self) =>
//           index === self.findIndex((t) => t.subjectCode === value.subjectCode)
//       );

//       // API call
//       const response = await fetch(
//         "https://sssutms.ac.in/apitest/student/forward-form",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             studentId: userData._id,
//             examFormId: examId,
//             subjects: finalSubjects,
//           }),
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to save subjects");
//       }

//       const responseData = await response.json();
//       console.log("API response:", responseData);

//       setIsApiSuccess(true);
//     } catch (error) {
//       console.error("Error saving subjects:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   // const handleSaveSubjects = async () => {
//   //   setIsLoading(true);
//   //   try {
//   //     await new Promise((resolve) => setTimeout(resolve, 2000));

//   //     const { theory, practical } = selectedSubjects;
//   //     const selectedNonCompulsorySubjects = nonCompulsorySubjects
//   //       .filter(
//   //         (subject) =>
//   //           (subject.subjectType === "Theory" &&
//   //             selectedSubjects.theory?.subjectCode === subject.subjectCode) ||
//   //           (subject.subjectType === "Practical" &&
//   //             selectedSubjects.practical?.subjectCode === subject.subjectCode)
//   //       )
//   //       .map((subject) => ({
//   //         subjectCode: subject.subjectCode,
//   //         subjectName: subject.subjectName,
//   //         subjectType: subject.subjectType,
//   //       }));

//   //     const updatedSubjects = [
//   //       ...compulsorySubjects,
//   //       ...selectedNonCompulsorySubjects,
//   //     ];

//   //     // API call
//   //     const response = await fetch(
//   //       "https://sssutms.ac.in/apitest/student/forward-form",
//   //       {
//   //         method: "POST",
//   //         headers: {
//   //           "Content-Type": "application/json",
//   //         },
//   //         body: JSON.stringify({
//   //           studentId: userData._id,
//   //           examFormId: examId,
//   //           subjects: updatedSubjects,
//   //         }),
//   //       }
//   //     );

//   //     if (!response.ok) {
//   //       throw new Error("Failed to save subjects");
//   //     }

//   //     const responseData = await response.json();
//   //     console.log("API response:", responseData);

//   //     setIsApiSuccess(true);
//   //   } catch (error) {
//   //     console.error("Error saving subjects:", error);
//   //   } finally {
//   //     setIsLoading(false);
//   //   }
//   // };

//   const handleCheckbox = (e) => {
//     if (e.target.checked) {
//       // Check if there are any non-compulsory subjects
//       const nonCompulsorySubjectsExist = nonCompulsorySubjects.length > 0;
//       const anyNonCompulsorySubjectSelected = nonCompulsorySubjects.some(
//         (subject) =>
//           (subject.subjectType === "Theory" &&
//             selectedSubjects.theory?.subjectCode === subject.subjectCode) ||
//           (subject.subjectType === "Practical" &&
//             selectedSubjects.practical?.subjectCode === subject.subjectCode)
//       );

//       if (nonCompulsorySubjectsExist && !anyNonCompulsorySubjectSelected) {
//         swal({
//           title: "Warning!",
//           text: "Please select elective subjects before proceeding.",
//           icon: "warning",
//           button: "Ok",
//         });

//         setIschecked2(false);
//         return;
//       }
//     }

//     setIschecked2(e.target.checked);
//     if (e.target.checked) {
//       handleSaveSubjects();
//     }
//   };

//   // ////////////////////////////////////////////////////////elective/////////////////////////////////////////////////////////

//   useEffect(() => {
//     // Initialize selectedSubjects with compulsory subjects that are checked by default
//     const checkedCompulsorySubjects = compulsorySubjects
//       .filter((subject) => subject.isChecked) // Filter compulsory subjects that are checked by default
//       .map((subject) => ({
//         subjectName: subject.subjectName,
//         subjectType: subject.subjectType,
//         subjectCode: subject.subjectCode,
//       }));

//     // Update state with the checked compulsory subjects
//     setSelectedSubjects((prev) => ({
//       ...prev,
//       compulsory: checkedCompulsorySubjects,
//     }));
//   }, [compulsorySubjects]); // Runs when compulsorySubjects changes

//   //   const isChecked = event.target.checked;

//   //   if (isChecked) {
//   //     // If the checkbox is checked
//   //     if (subject.subjectType === "Theory") {
//   //       setSelectedSubjects((prev) => ({
//   //         ...prev,
//   //         theory: subject, // Add the theory subject
//   //         practical: {
//   //           subjectName: `${subject.subjectName} Practical`,
//   //           subjectType: "Practical",
//   //           subjectCode: `${subject.subjectCode}`, // Automatically add the corresponding practical subject
//   //         }, // Automatically add the corresponding practical subject
//   //       }));
//   //     } else if (subject.subjectType === "Practical") {
//   //       setSelectedSubjects((prev) => ({
//   //         ...prev,
//   //         practical: subject, // Add the practical subject
//   //         theory: {
//   //           subjectName: `${subject.subjectName} Theory`,
//   //           subjectType: "Theory",
//   //           subjectCode: `${subject.subjectCode}`, // Automatically add the corresponding theory subject
//   //         }, // Automatically add the corresponding theory subject
//   //       }));
//   //     }
//   //   } else {
//   //     // If the checkbox is unchecked
//   //     if (subject.subjectType === "Theory") {
//   //       setSelectedSubjects((prev) => ({
//   //         ...prev,
//   //         theory: null, // Remove the theory subject
//   //         practical:
//   //           prev.practical?.subjectName === `${subject.subjectName} Practical`
//   //             ? null
//   //             : prev.practical, // Remove the practical subject if it's related
//   //       }));
//   //     } else if (subject.subjectType === "Practical") {
//   //       setSelectedSubjects((prev) => ({
//   //         ...prev,
//   //         practical: null, // Remove the practical subject
//   //         theory:
//   //           prev.theory?.subjectName === `${subject.subjectName} Theory`
//   //             ? null
//   //             : prev.theory, // Remove the theory subject if it's related
//   //       }));
//   //     }
//   //   }
//   // };

//   const handleCheckboxChange = (subject, event) => {
//     const isChecked = event.target.checked;
//     setSelectedSubjects((prev) => {
//       let updatedSubjects = { ...prev };

//       if (isChecked) {
//         // Add selected subject
//         if (subject.subjectType === "Theory") {
//           updatedSubjects.theory = subject;
//           // Auto-select practical if exists
//           const correspondingPractical = compulsorySubjects.find(
//             (s) => s.subjectName === `${subject.subjectName} Practical`
//           );
//           if (correspondingPractical) {
//             updatedSubjects.practical = correspondingPractical;
//           }
//         } else if (subject.subjectType === "Practical") {
//           updatedSubjects.practical = subject;
//           // Auto-select theory if exists
//           const correspondingTheory = compulsorySubjects.find(
//             (s) => s.subjectName === `${subject.subjectName} Theory`
//           );
//           if (correspondingTheory) {
//             updatedSubjects.theory = correspondingTheory;
//           }
//         }
//       } else {
//         // Remove subject if unchecked
//         if (subject.subjectType === "Theory") {
//           delete updatedSubjects.theory;
//           delete updatedSubjects.practical; // Unselect practical too
//         } else if (subject.subjectType === "Practical") {
//           delete updatedSubjects.practical;
//         }
//       }

//       return updatedSubjects;
//     });
//   };

//   // Checkbox rendering for both compulsory and non-compulsory subjects
//   // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   useEffect(() => {
//     const fetchExamForms = async () => {
//       try {
//         setLoading(true);
//         const response = await fetch(
//           `https://sssutms.ac.in/apitest/v1/getExamForms?studentId=${userData._id}`
//         );
//         const result = await response.json();
//         if (response.ok) {
//           setExamForms(result.data);
//         } else {
//           console.error(result.message);
//         }
//       } catch (error) {
//         console.error("Error fetching exam forms:", error);
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchExamForms();
//   }, []);

//   ///////////////////////////////////////////////for optional ////////////////////////////////////////////////////////////////

//   const getSortedAndSlicedData = () => {
//     const sortedData = [...examForms].sort((a, b) => {
//       if (sortBy === "name") {
//         return sortOrder === "asc"
//           ? a.branchName.localeCompare(b.branchName)
//           : b.branchName.localeCompare(a.branchName);
//       }
//       return 0;
//     });
//     const startIndex = page * rowsPerPage;
//     return sortedData.slice(startIndex, startIndex + rowsPerPage);
//   };

//   const [show, setShow] = useState(false);
//   const [showNep, setShowNep] = useState(false);

//   // Handlers for Modal
//   const handleClose = () => setShow(false);
//   const handleCloseNep = () => setShowNep(false);

//   const handleShow = async (examId) => {
//     // const fetchSubjects = async () => {
//     try {
//       const response = await fetch(
//         `https://sssutms.ac.in/apitest/v1/student/exam-form5?studentId=${userData._id}&examFormId=${examId._id}&courseName=${userData.courseName}&courseBranch=${userData.courseBranch}&semesterNumber=${userData.currentSemester}`
//       );
//       const result = await response.json();

//       if (response.ok) {
//         // Filter subjects where compulsory is true and false
//         const compulsorySubjects = result.subjects.filter(
//           (subject) => subject.compulsory === true
//         );
//         const nonCompulsorySubjects = result.subjects.filter(
//           (subject) => subject.compulsory === false
//         );
//         // Set the filtered subjects into their respective states
//         setCompulsorySubjects(compulsorySubjects);
//         setNonCompulsorySubjects(nonCompulsorySubjects);

//         // Optionally, you can set other data as well
//         setFormData({ ...result, subjects: compulsorySubjects });
//         setShow(true);
//       } else {
//         console.log(result.message);
//       }
//     } catch (error) {
//       console.log("Error fetching subjects:", error);
//     }
//     // };
//     // fetchSubjects();
//   };

//   // ///////////////////////////nep ki functionality ///////////////////////////////////////////////

//   const handleShowNep = async (examId) => {
//     try {
//       const response = await fetch(
//         `https://sssutms.ac.in/apitest/v1/student/exam-form5?studentId=${userData._id}&examFormId=${examId._id}&courseName=${userData.courseName}&courseBranch=${userData.courseBranch}&semesterNumber=${userData.currentSemester}`,
//         {
//           method: "GET",
//           headers: {
//             "Cache-Control": "no-cache, no-store, must-revalidate",
//             Pragma: "no-cache",
//             Expires: "0",
//           },
//           cache: "no-store",
//         }
//       );

//       if (response.status === 304) {
//         console.log("Data not modified, using previous data if available.");
//         return;
//       }

//       const result = await response.json();

//       if (response.ok) {
//         // Filter and categorize subjects
//         const compulsorySubjects = result.subjects.filter(
//           (subject) => subject.compulsory === true
//         );
//         const majorSubjects = result.subjects.filter(
//           (subject) => subject.TypeOfcourse.toLowerCase() === "major"
//         );
//         const minorSubjects = result.subjects.filter(
//           (subject) => subject.TypeOfcourse.toLowerCase() === "minor"
//         );
//         const genericElectives = result.subjects.filter((subject) =>
//           subject.TypeOfcourse.includes("Generic Elective")
//         );
//         const foundationElectives = result.subjects.filter((subject) =>
//           subject.TypeOfcourse.toLowerCase().includes("foundation")
//         );

//         // Pre-select Major and Minor subjects in selectednepSubjects
//         const initialSelectedSubjects = {};

//         majorSubjects.forEach((subject) => {
//           initialSelectedSubjects["Major"] = subject; // Pre-select Major subject
//         });

//         minorSubjects.forEach((subject) => {
//           initialSelectedSubjects["Minor"] = subject; // Pre-select Minor subject
//         });

//         // Update the state
//         setCompulsorySubjects(compulsorySubjects);
//         setMajorSubjects(majorSubjects);
//         setMinorSubjects(minorSubjects);
//         setGenericElectives(genericElectives);
//         setFoundationElectives(foundationElectives);
//         setselectednepSubjects(initialSelectedSubjects); // Update with pre-selected Major/Minor subjects

//         setShowNep(true);

//         console.log("Major:", majorSubjects);
//         console.log("Minor:", minorSubjects);
//         console.log("Generic Elective:", genericElectives);
//         console.log("Foundation Elective:", foundationElectives);
//       } else {
//         console.log(result.message);
//       }
//     } catch (error) {
//       console.log("Error fetching subjects:", error);
//     }
//   };

//   // const handleCheckboxnepChange2 = (subject, event) => {
//   //   const isChecked = event.target.checked;

//   //   const allSubjects = [
//   //     ...majorSubjects,
//   //     ...minorSubjects,
//   //     ...genericElectives,
//   //     ...foundationElectives,
//   //   ];

//   //   setselectednepSubjects((prev) => {
//   //     let updatedSubjects = { ...prev };
//   //     const category = subject.category;

//   //     if (isChecked) {
//   //       // Clear previous selection in the same category if exists
//   //       if (category && updatedSubjects[category]) {
//   //         delete updatedSubjects[category];
//   //       }

//   //       // Add the selected subject to its category
//   //       updatedSubjects[category] = subject;

//   //       // Auto-select corresponding Practical or Theory based on subjectCode
//   //       const baseCode = subject.subjectCode.replace(/\s*(T|P)\s*$/, ""); // Remove the "T" or "P" from the end

//   //       if (subject.subjectType === "Theory") {
//   //         updatedSubjects.theory = subject;

//   //         // Find corresponding practical by matching subjectCode across all categories
//   //         const correspondingPractical = allSubjects.find(
//   //           (s) =>
//   //             s.subjectType === "Practical" &&
//   //             s.subjectCode.startsWith(baseCode) && // Match based on subjectCode prefix
//   //             s.subjectCode.endsWith("P") // Ensure it's a practical subject
//   //         );

//   //         if (correspondingPractical) {
//   //           // Initialize practical as an array if it doesn't exist
//   //           if (!updatedSubjects.practical) {
//   //             updatedSubjects.practical = [];
//   //           }

//   //           // Add the corresponding practical to the array if not already present
//   //           if (!updatedSubjects.practical.some(p => p.subjectCode === correspondingPractical.subjectCode)) {
//   //             updatedSubjects.practical.push(correspondingPractical);
//   //           }

//   //           // Store in the category as well
//   //           updatedSubjects[correspondingPractical.category] = correspondingPractical;
//   //         }
//   //       } else if (subject.subjectType === "Practical") {
//   //         // Initialize practical as an array if it doesn't exist
//   //         if (!updatedSubjects.practical) {
//   //           updatedSubjects.practical = [];
//   //         }

//   //         // Add the selected practical to the array if not already present
//   //         updatedSubjects.practical.push(subject);

//   //         // Find corresponding theory by matching subjectCode across all categories
//   //         const correspondingTheory = allSubjects.find(
//   //           (s) =>
//   //             s.subjectType === "Theory" &&
//   //             s.subjectCode.startsWith(baseCode) && // Match based on subjectCode prefix
//   //             s.subjectCode.endsWith("T") // Ensure it's a theory subject
//   //         );

//   //         if (correspondingTheory) {
//   //           updatedSubjects.theory = correspondingTheory;
//   //           updatedSubjects[correspondingTheory.category] = correspondingTheory;
//   //         }
//   //       }
//   //     } else {
//   //       // Unselecting a subject
//   //       if (updatedSubjects[category]?.subjectCode === subject.subjectCode) {
//   //         delete updatedSubjects[category];
//   //       }

//   //       // Find and remove corresponding theory/practical pair across categories
//   //       const baseCode = subject.subjectCode.replace(/\s*(T|P)\s*$/, "").trim();

//   //       // Remove from both type-specific keys and categories
//   //       if (subject.subjectType === "Theory") {
//   //         const correspondingPractical = allSubjects.find(
//   //           (s) =>
//   //             s.subjectType === "Practical" &&
//   //             s.subjectCode.startsWith(baseCode) && // Match based on subjectCode
//   //             s.subjectCode.endsWith("P") // Ensure it's a practical subject
//   //         );

//   //         delete updatedSubjects.theory;

//   //         if (correspondingPractical) {
//   //           // Remove the corresponding practical from the array
//   //           if (updatedSubjects.practical) {
//   //             updatedSubjects.practical = updatedSubjects.practical.filter(
//   //               p => p.subjectCode !== correspondingPractical.subjectCode
//   //             );
//   //           }

//   //           delete updatedSubjects[correspondingPractical.category];
//   //         }
//   //       } else if (subject.subjectType === "Practical") {
//   //         // Remove the selected practical from the array
//   //         if (updatedSubjects.practical) {
//   //           updatedSubjects.practical = updatedSubjects.practical.filter(
//   //             p => p.subjectCode !== subject.subjectCode
//   //           );
//   //         }

//   //         const correspondingTheory = allSubjects.find(
//   //           (s) =>
//   //             s.subjectType === "Theory" &&
//   //             s.subjectCode.startsWith(baseCode) && // Match based on subjectCode
//   //             s.subjectCode.endsWith("T") // Ensure it's a theory subject
//   //         );

//   //         delete updatedSubjects.theory;

//   //         if (correspondingTheory) {
//   //           delete updatedSubjects[correspondingTheory.category];
//   //         }
//   //       }
//   //     }

//   //     console.log("Updated Selected Subjects:", updatedSubjects);
//   //     return updatedSubjects;
//   //   });
//   // };

//   // const handleCheckboxnepChange2 = (subject, event) => {
//   //   const isChecked = event.target.checked;

//   //   const allSubjects = [
//   //     ...majorSubjects,
//   //     ...minorSubjects,
//   //     ...genericElectives,
//   //     ...foundationElectives,
//   //   ];

//   //   setselectednepSubjects((prev) => {
//   //     let updatedSubjects = { ...prev };
//   //     const category = subject.category;

//   //     if (isChecked) {
//   //       // Clear previous selection in the same category if exists
//   //       if (category && updatedSubjects[category]) {
//   //         delete updatedSubjects[category];
//   //       }

//   //       // Add the selected subject to its category
//   //       updatedSubjects[category] = subject;

//   //       // Auto-select corresponding Practical or Theory across all categories
//   //       const baseName = subject.subjectName.replace(/\s*(Theory|Practical)\s*$/, "").trim();

//   //       if (subject.subjectType === "Theory") {
//   //         updatedSubjects.theory = subject;

//   //         // Find corresponding practical in ANY category
//   //         const correspondingPractical = allSubjects.find(
//   //           (s) =>
//   //             s.subjectType === "Practical" &&
//   //             s.subjectName.replace(/\s*Practical\s*$/, "").trim() === baseName
//   //         );

//   //         if (correspondingPractical) {
//   //           // Initialize practical as an array if it doesn't exist
//   //           if (!updatedSubjects.practical) {
//   //             updatedSubjects.practical = [];
//   //           }

//   //           // Add the corresponding practical to the array if not already present
//   //           if (!updatedSubjects.practical.some(p => p.subjectName === correspondingPractical.subjectName)) {
//   //             updatedSubjects.practical.push(correspondingPractical);
//   //           }

//   //           // Store in the category as well
//   //           updatedSubjects[correspondingPractical.category] = correspondingPractical;
//   //         }
//   //       } else if (subject.subjectType === "Practical") {
//   //         // Initialize practical as an array if it doesn't exist
//   //         if (!updatedSubjects.practical) {
//   //           updatedSubjects.practical = [];
//   //         }

//   //         // Add the selected practical to the array if not already present
//   //         if (!updatedSubjects.practical.some(p => p.subjectName === subject.subjectName)) {
//   //           updatedSubjects.practical.push(subject);
//   //         }

//   //         // Find corresponding theory in ANY category
//   //         const correspondingTheory = allSubjects.find(
//   //           (s) =>
//   //             s.subjectType === "Theory" &&
//   //             s.subjectName.replace(/\s*Theory\s*$/, "").trim() === baseName
//   //         );

//   //         if (correspondingTheory) {
//   //           // Store in both theory key and category
//   //           updatedSubjects.theory = correspondingTheory;
//   //           updatedSubjects[correspondingTheory.category] = correspondingTheory;
//   //         }
//   //       }
//   //     } else {
//   //       // Unselecting a subject
//   //       if (updatedSubjects[category]?.subjectName === subject.subjectName) {
//   //         delete updatedSubjects[category];
//   //       }

//   //       // Find and remove corresponding theory/practical pair across categories
//   //       const baseName = subject.subjectName.replace(/\s*(Theory|Practical)\s*$/, "").trim();

//   //       // Remove from both type-specific keys and categories
//   //       if (subject.subjectType === "Theory") {
//   //         const correspondingPractical = allSubjects.find(
//   //           (s) =>
//   //             s.subjectType === "Practical" &&
//   //             s.subjectName.replace(/\s*Practical\s*$/, "").trim() === baseName
//   //         );

//   //         delete updatedSubjects.theory;

//   //         if (correspondingPractical) {
//   //           // Remove the corresponding practical from the array
//   //           if (updatedSubjects.practical) {
//   //             updatedSubjects.practical = updatedSubjects.practical.filter(
//   //               p => p.subjectName !== correspondingPractical.subjectName
//   //             );
//   //           }

//   //           delete updatedSubjects[correspondingPractical.category];
//   //         }
//   //       } else if (subject.subjectType === "Practical") {
//   //         // Remove the selected practical from the array
//   //         if (updatedSubjects.practical) {
//   //           updatedSubjects.practical = updatedSubjects.practical.filter(
//   //             p => p.subjectName !== subject.subjectName
//   //           );
//   //         }

//   //         const correspondingTheory = allSubjects.find(
//   //           (s) =>
//   //             s.subjectType === "Theory" &&
//   //             s.subjectName.replace(/\s*Theory\s*$/, "").trim() === baseName
//   //         );

//   //         delete updatedSubjects.theory;

//   //         if (correspondingTheory) {
//   //           delete updatedSubjects[correspondingTheory.category];
//   //         }
//   //       }
//   //     }

//   //     console.log("Updated Selected Subjects:", updatedSubjects);
//   //     return updatedSubjects;
//   //   });
//   // };

//   // const handleSaveSubjects2 = async () => {
//   //   // Get all selected subjects and flatten
//   //   const checkedSubjects = Object.values(selectednepSubjects).flat();

//   //   // Remove duplicate subjects based on subjectCode
//   //   const uniqueSubjects = checkedSubjects.filter(
//   //     (subject, index, self) =>
//   //       index === self.findIndex((s) => s.subjectCode === subject.subjectCode)
//   //   );

//   //   // Ensure both Theory and Practical are properly paired
//   //   const processedSubjects = [];
//   //   const addedSubjects = new Set(); // To track already added subjects

//   //   uniqueSubjects.forEach((subject) => {
//   //     if (!addedSubjects.has(subject.subjectCode)) {
//   //       processedSubjects.push(subject);
//   //       addedSubjects.add(subject.subjectCode);

//   //       if (subject.subjectType === "Theory") {
//   //         // Find corresponding Practical subject
//   //         const correspondingPractical = uniqueSubjects.find(
//   //           (s) =>
//   //             s.subjectType === "Practical" &&
//   //             s.subjectName.replace(/\s*Practical\s*$/, "").trim() ===
//   //               subject.subjectName.replace(/\s*Theory\s*$/, "").trim()
//   //         );

//   //         if (correspondingPractical && !addedSubjects.has(correspondingPractical.subjectCode)) {
//   //           processedSubjects.push(correspondingPractical);
//   //           addedSubjects.add(correspondingPractical.subjectCode);
//   //         }
//   //       }
//   //     }
//   //   });

//   //   // Map to the required format
//   //   const flattenedSubjects = processedSubjects.map((subject) => ({
//   //     subjectName: subject.subjectName,
//   //     subjectCode: subject.subjectCode,
//   //     subjectType: subject.subjectType,
//   //     compulsory: subject.compulsory,
//   //   }));

//   //   console.log(flattenedSubjects, "Saved Checked Subjects (No Duplicate Practicals)");
//   // };

//   //   const handleSaveSubjects2 = async () => {
//   //     // Get all selected subjects and flatten
//   //     const checkedSubjects = Object.values(selectednepSubjects).flat();

//   //     // Remove duplicate subjects based on subjectCode
//   //     const uniqueSubjects = checkedSubjects.filter(
//   //       (subject, index, self) =>
//   //         index === self.findIndex((s) => s.subjectCode === subject.subjectCode)
//   //     );

//   //     // Sort subjects: Theory upar, Practical neeche
//   //     const sortedSubjects = uniqueSubjects.sort((a, b) => {
//   //       if (a.subjectType === "Theory" && b.subjectType !== "Theory") return -1;
//   //       if (a.subjectType !== "Theory" && b.subjectType === "Theory") return 1;
//   //       return 0;
//   //     });

//   //     // Map to the required format
//   //     const flattenedSubjects = sortedSubjects.map((subject) => ({
//   //       subjectName: subject.subjectName,
//   //       subjectCode: subject.subjectCode,
//   //       subjectType: subject.subjectType,
//   //       compulsory: subject.compulsory,
//   //     }));

//   //     console.log(flattenedSubjects, "Saved Checked Subjects (Theory First, Practical Last)");
//   // };

//   //   const handleCheckboxnep = (e) => {
//   //     if (e.target.checked) {
//   //       // Check if there are any non-compulsory subjects
//   //       const nonCompulsorySubjectsExist = nonCompulsorySubjects.length > 0;
//   //       const anyNonCompulsorySubjectSelected = nonCompulsorySubjects.some(
//   //         (subject) =>
//   //           (subject.subjectType === "Theory" &&
//   //             selectedSubjects.theory?.subjectCode === subject.subjectCode) ||
//   //           (subject.subjectType === "Practical" &&
//   //             selectedSubjects.practical?.subjectCode === subject.subjectCode)
//   //       );

//   //       if (nonCompulsorySubjectsExist && !anyNonCompulsorySubjectSelected) {
//   //         swal({
//   //           title: "Warning!",
//   //           text: "Please select elective subjects before proceeding.",
//   //           icon: "warning",
//   //           button: "Ok",
//   //         });

//   //         setIschecked2(false);
//   //         return;
//   //       }
//   //     }

//   //     setIschecked2(e.target.checked);
//   //     if (e.target.checked) {
//   //       handleSaveSubjects2();
//   //     }
//   //   };

//   //////////////////////sahi wlaa h ye jsime subcode se mjor minor k practical cxhoose ho rha h  //////////////////////////////////////////

//   const handleCheckboxnepChange2 = (subject, event) => {
//     const isChecked = event.target.checked;

//     const allSubjects = [
//       ...majorSubjects,
//       ...minorSubjects,
//       ...genericElectives,
//       ...foundationElectives,
//     ];

//     setselectednepSubjects((prev) => {
//       let updatedSubjects = { ...prev };
//       const category = subject.category;

//       if (isChecked) {
//         // Clear previous selection in the same category if exists
//         if (category && updatedSubjects[category]) {
//           delete updatedSubjects[category];
//         }

//         // Add the selected subject to its category
//         updatedSubjects[category] = subject;

//         // Auto-select corresponding Practical or Theory across all categories
//         const baseCode = subject.subjectCode
//           .replace(/\s*(T|P)\s*$/, "")
//           .trim()
//           .normalize(); // Remove "T" or "P" and normalize
//         console.log("Base Code for Matching:", baseCode); // Debugging log to check baseCode

//         if (subject.subjectType === "Theory") {
//           updatedSubjects.theory = subject;

//           // Find corresponding practical in ANY category
//           const correspondingPractical = allSubjects.find(
//             (s) =>
//               s.subjectType === "Practical" &&
//               s.subjectCode.startsWith(baseCode) && // Match based on subjectCode prefix
//               s.subjectCode.endsWith("P") // Ensure it's a practical subject
//           );

//           console.log("Corresponding Practical:", correspondingPractical); // Debugging log to check if corresponding practical is found

//           if (correspondingPractical) {
//             // Initialize practical as an array if it doesn't exist
//             if (!updatedSubjects.practical) {
//               updatedSubjects.practical = [];
//             }

//             // Add the corresponding practical to the array if not already present
//             if (
//               !updatedSubjects.practical.some(
//                 (p) => p.subjectCode === correspondingPractical.subjectCode
//               )
//             ) {
//               updatedSubjects.practical.push(correspondingPractical);
//             }

//             // Store in the category as well
//             updatedSubjects[correspondingPractical.category] =
//               correspondingPractical;
//           }
//         } else if (subject.subjectType === "Practical") {
//           // Initialize practical as an array if it doesn't exist
//           if (!updatedSubjects.practical) {
//             updatedSubjects.practical = [];
//           }

//           // Add the selected practical to the array if not already present
//           if (
//             !updatedSubjects.practical.some(
//               (p) => p.subjectCode === subject.subjectCode
//             )
//           ) {
//             updatedSubjects.practical.push(subject);
//           }

//           // Find corresponding theory in ANY category
//           const correspondingTheory = allSubjects.find(
//             (s) =>
//               s.subjectType === "Theory" &&
//               s.subjectCode.startsWith(baseCode) && // Match based on subjectCode prefix
//               s.subjectCode.endsWith("T") // Ensure it's a theory subject
//           );

//           console.log("Corresponding Theory:", correspondingTheory); // Debugging log to check if corresponding theory is found

//           if (correspondingTheory) {
//             // Store in both theory key and category
//             updatedSubjects.theory = correspondingTheory;
//             updatedSubjects[correspondingTheory.category] = correspondingTheory;
//           }
//         }
//       } else {
//         // Unselecting a subject
//         if (updatedSubjects[category]?.subjectCode === subject.subjectCode) {
//           delete updatedSubjects[category];
//         }

//         // Find and remove corresponding theory/practical pair across categories
//         const baseCode = subject.subjectCode
//           .replace(/\s*(T|P)\s*$/, "")
//           .trim()
//           .normalize();
//         console.log("Base Code for Removal:", baseCode); // Debugging log to check baseCode

//         // Remove from both type-specific keys and categories
//         if (subject.subjectType === "Theory") {
//           const correspondingPractical = allSubjects.find(
//             (s) =>
//               s.subjectType === "Practical" &&
//               s.subjectCode.startsWith(baseCode) && // Match based on subjectCode prefix
//               s.subjectCode.endsWith("P") // Ensure it's a practical subject
//           );

//           delete updatedSubjects.theory;

//           if (correspondingPractical) {
//             // Remove the corresponding practical from the array
//             if (updatedSubjects.practical) {
//               updatedSubjects.practical = updatedSubjects.practical.filter(
//                 (p) => p.subjectCode !== correspondingPractical.subjectCode
//               );
//             }

//             delete updatedSubjects[correspondingPractical.category];
//           }
//         } else if (subject.subjectType === "Practical") {
//           // Remove the selected practical from the array
//           if (updatedSubjects.practical) {
//             updatedSubjects.practical = updatedSubjects.practical.filter(
//               (p) => p.subjectCode !== subject.subjectCode
//             );
//           }

//           const correspondingTheory = allSubjects.find(
//             (s) =>
//               s.subjectType === "Theory" &&
//               s.subjectCode.startsWith(baseCode) && // Match based on subjectCode prefix
//               s.subjectCode.endsWith("T") // Ensure it's a theory subject
//           );

//           delete updatedSubjects.theory;

//           if (correspondingTheory) {
//             delete updatedSubjects[correspondingTheory.category];
//           }
//         }
//       }

//       console.log("Updated Selected Subjects:", updatedSubjects); // Debugging log to check updated subjects
//       return updatedSubjects;
//     });
//   };

//   // const handleCheckboxbaChange = (subject, event, category) => {
//   //   const isChecked = event.target.checked;

//   //   setSelectedSubjects((prev) => {
//   //     let updatedSubjects = { ...prev };

//   //     if (isChecked) {
//   //       updatedSubjects[category] = [subject];

//   //       if (subject.subjectType === "Theory") {
//   //         const correspondingPractical = genericElectives.find(
//   //           (s) =>
//   //             s.subjectType === "Practical" &&
//   //             s.subjectgroup === subject.subjectgroup
//   //         );
//   //         if (correspondingPractical) {
//   //           updatedSubjects[category].push(correspondingPractical);
//   //         }
//   //       }

//   //       if (category === "Major") {
//   //         updatedSubjects["disabledMinorSubjects"] = minorSubjects.filter(
//   //           (s) => s.subjectgroup === subject.subjectgroup
//   //         );
//   //       } else if (category === "Minor") {
//   //         updatedSubjects["disabledMajorSubjects"] = majorSubjects.filter(
//   //           (s) => s.subjectgroup === subject.subjectgroup
//   //         );
//   //       }

//   //       const selectedMajorMinorGroups = [
//   //         ...(updatedSubjects["Major"]?.map((s) => s.subjectgroup) || []),
//   //         ...(updatedSubjects["Minor"]?.map((s) => s.subjectgroup) || []),
//   //       ];

//   //       updatedSubjects["disabledGenericSubjects"] = genericElectives.filter(
//   //         (s) => selectedMajorMinorGroups.includes(s.subjectgroup)
//   //       );
//   //     } else {
//   //       updatedSubjects[category] = [];
//   //     }

//   //     console.log("Selected Subjects:", updatedSubjects);
//   //     return updatedSubjects;
//   //   });
//   // };

//   const handleCheckboxbaChange = (subject, event, category) => {
//     const isChecked = event.target.checked;

//     setSelectedSubjects((prev) => {
//       let updatedSubjects = { ...prev };

//       if (isChecked) {
//         // ✅ Sirf ek subject select hone dena
//         updatedSubjects[category] = [subject];

//         // ✅ Agar Practical exist karta hai to usko bhi select karo
//         if (subject.subjectType === "Theory") {
//           const correspondingPractical = genericElectives.find(
//             (s) =>
//               s.subjectType === "Practical" &&
//               s.subjectgroup === subject.subjectgroup
//           );
//           if (correspondingPractical) {
//             updatedSubjects[category].push(correspondingPractical);
//           }
//         }

//         // ✅ Major select hone par Minor disable
//         if (category === "Major") {
//           updatedSubjects["disabledMinorSubjects"] = minorSubjects.filter(
//             (s) => s.subjectgroup === subject.subjectgroup
//           );
//         } else if (category === "Minor") {
//           updatedSubjects["disabledMajorSubjects"] = majorSubjects.filter(
//             (s) => s.subjectgroup === subject.subjectgroup
//           );
//         }

//         // ✅ Major/Minor select hone par Generic disable
//         const selectedMajorMinorGroups = [
//           ...(updatedSubjects["Major"]?.map((s) => s.subjectgroup) || []),
//           ...(updatedSubjects["Minor"]?.map((s) => s.subjectgroup) || []),
//         ];

//         updatedSubjects["disabledGenericSubjects"] = genericElectives.filter(
//           (s) => selectedMajorMinorGroups.includes(s.subjectgroup)
//         );
//       } else {
//         // ✅ Agar uncheck kare toh reset karo
//         updatedSubjects[category] = [];
//       }

//       console.log("Selected Subjects:", updatedSubjects);
//       return updatedSubjects;
//     });
//   };

//   //////////////////////sahi wlaa h ye jsime subcode se mjor minor k practical cxhoose ho rha h  //////////////////////////////////////////
//   // sahi wala
//   const handleSaveSubjects2 = async () => {
//     setIsLoading(true);

//     try {
//       await new Promise((resolve) => setTimeout(resolve, 2000));
//       let flattenedSubjects = [];
//       if (userData.courseName === "BACHELOR OF ARTS") {
//         const selectedSubjectsArray = Object.keys(selectedSubjects)
//           .filter((key) => !key.startsWith("disabled")) // ✅ Ignore disabled subjects
//           .map((key) => selectedSubjects[key]) // ✅ Get selected subjects only
//           .flat()
//           .filter((s) => s !== null && s !== undefined); // ✅ Remove null/undefined subjects

//         // ✅ Remove duplicate subjects based on subjectCode
//         const uniqueSubjects = Array.from(
//           new Map(
//             selectedSubjectsArray
//               .filter((s) => s?.subjectCode) // ✅ Ensure subjectCode exists before mapping
//               .map((s) => [s.subjectCode, s])
//           ).values()
//         );

//         // ✅ Sort subjects: Theory first, then Practical
//         const sortedSubjects = uniqueSubjects.sort((a, b) => {
//           if (a.subjectType === "Theory" && b.subjectType !== "Theory")
//             return -1;
//           if (a.subjectType !== "Theory" && b.subjectType === "Theory")
//             return 1;
//           return 0;
//         });

//         flattenedSubjects = sortedSubjects.map((subject) => ({
//           subjectName: subject.subjectName,
//           subjectCode: subject.subjectCode,
//           subjectType: subject.subjectType,
//           compulsory: subject.compulsory,
//         }));
//       } else {
//         const checkedSubjects = Object.values(selectednepSubjects).flat();

//         // Remove duplicate subjects based on subjectCode
//         const uniqueSubjects = checkedSubjects.filter(
//           (subject, index, self) =>
//             index ===
//             self.findIndex((s) => s.subjectCode === subject.subjectCode)
//         );

//         // Sort subjects: Theory upar, Practical neeche
//         const sortedSubjects = uniqueSubjects.sort((a, b) => {
//           if (a.subjectType === "Theory" && b.subjectType !== "Theory")
//             return -1;
//           if (a.subjectType !== "Theory" && b.subjectType === "Theory")
//             return 1;
//           return 0;
//         });

//         // Map to the required format
//         flattenedSubjects = sortedSubjects.map((subject) => ({
//           subjectName: subject.subjectName,
//           subjectCode: subject.subjectCode,
//           subjectType: subject.subjectType,
//           compulsory: subject.compulsory,
//         }));
//       }

//       console.log(flattenedSubjects, "subjects for");
//       const response = await fetch(
//         "https://sssutms.ac.in/apitest/student/forward-form",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             studentId: userData._id,
//             examFormId: examId,
//             subjects: flattenedSubjects,
//           }),
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to save subjects");
//       }

//       const responseData = await response.json();
//       console.log("API response:", responseData);

//       setIsApiSuccess(true);
//     } catch (error) {
//       console.error("Error saving subjects:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   // const handleSaveSubjects2 = async () => {
//   //   // Get all selected subjects and flatten
//   //   let flattenedSubjects = []
//   //   if(userData.courseName==="BACHELOR OF ARTS"){
//   //     const selectedSubjectsArray = Object.keys(selectedSubjects)
//   //     .filter((key) => !key.startsWith("disabled")) // ✅ Ignore disabled subjects
//   //     .map((key) => selectedSubjects[key]) // ✅ Get selected subjects only
//   //     .flat()
//   //     .filter((s) => s !== null && s !== undefined); // ✅ Remove null/undefined subjects

//   //   // ✅ Remove duplicate subjects based on subjectCode
//   //   const uniqueSubjects = Array.from(
//   //     new Map(
//   //       selectedSubjectsArray
//   //         .filter((s) => s?.subjectCode) // ✅ Ensure subjectCode exists before mapping
//   //         .map((s) => [s.subjectCode, s])
//   //     ).values()
//   //   );

//   //   // ✅ Sort subjects: Theory first, then Practical
//   //   const sortedSubjects = uniqueSubjects.sort((a, b) => {
//   //     if (a.subjectType === "Theory" && b.subjectType !== "Theory") return -1;
//   //     if (a.subjectType !== "Theory" && b.subjectType === "Theory") return 1;
//   //     return 0;
//   //   });

//   //   flattenedSubjects = sortedSubjects.map((subject) => ({
//   //     subjectName: subject.subjectName,
//   //     subjectCode: subject.subjectCode,
//   //     subjectType: subject.subjectType,
//   //     compulsory: subject.compulsory,
//   //   }));

//   // }else{
//   //   const checkedSubjects = Object.values(selectednepSubjects).flat();

//   //   // Remove duplicate subjects based on subjectCode
//   //   const uniqueSubjects = checkedSubjects.filter(
//   //     (subject, index, self) =>
//   //       index === self.findIndex((s) => s.subjectCode === subject.subjectCode)
//   //   );

//   //   // Sort subjects: Theory upar, Practical neeche
//   //   const sortedSubjects = uniqueSubjects.sort((a, b) => {
//   //     if (a.subjectType === "Theory" && b.subjectType !== "Theory") return -1;
//   //     if (a.subjectType !== "Theory" && b.subjectType === "Theory") return 1;
//   //     return 0;
//   //   });

//   //   // Map to the required format
//   //   flattenedSubjects = sortedSubjects.map((subject) => ({
//   //     subjectName: subject.subjectName,
//   //     subjectCode: subject.subjectCode,
//   //     subjectType: subject.subjectType,
//   //     compulsory: subject.compulsory,
//   //   }));

//   // }

//   // console.log(flattenedSubjects ,"flatten subjects ")
//   // };

//   // const handleSaveSubjects2 = async () => {

//   //   try {
//   //     await new Promise((resolve) => setTimeout(resolve, 2000));
//   //     const checkedSubjects = Object.values(selectednepSubjects).filter(
//   //       (subject, index, self) =>
//   //         index === self.findIndex((s) => s.subjectCode === subject.subjectCode)
//   //     );

//   //     const processedSubjects = checkedSubjects.flatMap((subject) => {
//   //       if (subject.subjectType === "Theory") {
//   //         const correspondingPractical = Object.values(selectednepSubjects).find(
//   //           (s) => s.subjectName === `${subject.subjectName} Practical`
//   //         );
//   //         return correspondingPractical ? [subject, correspondingPractical] : [subject];
//   //       }
//   //       return [subject];
//   //     });

//   //     const flattenedSubjects = processedSubjects.map((subject) => ({
//   //       subjectName: subject.subjectName,
//   //       subjectCode: subject.subjectCode,
//   //       subjectType: subject.subjectType,
//   //       compulsory:true,
//   //     }));

//   //     const response = await fetch(
//   //       "https://sssutms.ac.in/apitest/student/forward-form",
//   //       {
//   //         method: "POST",
//   //         headers: {
//   //           "Content-Type": "application/json",
//   //         },
//   //         body: JSON.stringify({
//   //           studentId: userData._id,
//   //           examFormId: examId,
//   //           subjects: flattenedSubjects,
//   //         }),
//   //       }
//   //     );

//   //     if (!response.ok) {
//   //       throw new Error("Failed to save subjects");
//   //     }

//   //     const responseData = await response.json();
//   //     console.log("API response:", responseData);

//   //     setIsApiSuccess(true);

//   //   } catch (error) {
//   //     console.error("Error saving subjects:", error);
//   //   } finally {
//   //     setIsLoading(false);
//   //   }
//   // };

//   // For handling checkbox change

//   const handleCheckboxnep = (e) => {
//     if (e.target.checked) {
//       // Check if there are any non-compulsory subjects
//       const nonCompulsorySubjectsExist = nonCompulsorySubjects.length > 0;
//       const anyNonCompulsorySubjectSelected = nonCompulsorySubjects.some(
//         (subject) =>
//           (subject.subjectType === "Theory" &&
//             selectedSubjects.theory?.subjectCode === subject.subjectCode) ||
//           (subject.subjectType === "Practical" &&
//             selectedSubjects.practical?.subjectCode === subject.subjectCode)
//       );

//       if (nonCompulsorySubjectsExist && !anyNonCompulsorySubjectSelected) {
//         swal({
//           title: "Warning!",
//           text: "Please select elective subjects before proceeding.",
//           icon: "warning",
//           button: "Ok",
//         });

//         setIschecked2(false);
//         return;
//       }
//     }

//     setIschecked2(e.target.checked);
//     if (e.target.checked) {
//       handleSaveSubjects2();
//     }
//   };

//   // //////////////////////////////nep ki functionalituy///////////////////////////////////////////////
//   // Handlers for Modal
//   const [show1, setShow1] = useState(false);
//   const handleClose1 = () => setShow1(false);

//   const handleShow1 = async (examId) => {
//     try {
//       const response = await fetch(
//         `https://sssutms.ac.in/apitest/v1/student/exam-form2?examFormId=${examId._id}&id=${userData._id}`
//       );
//       const result = await response.json();

//       setFormData2(result);
//       setShow1(true);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };
//   const generatePDF = () => {
//     const doc = new jsPDF();

//     // Add University Logo
//     doc.addImage(sssutms, "PNG", 10, 10, 20, 20);

//     // University Header
//     doc.setFont("helvetica", "bold");
//     doc.setFontSize(11);
//     doc.text(
//       "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
//       35,
//       15
//     );

//     // Add Course Name
//     doc.setFontSize(11);
//     doc.setFont("helvetica");
//     doc.text(`${userData?.courseName?.toUpperCase()}`, 105, 23, {
//       align: "center",
//     });

//     // Add header below course name
//     doc.setFontSize(10);
//     doc.text(`${userData.courseBranch}- EXAM FORM: ${examForms[0]?.examFormId?.sessionName}`, 105, 31, {
//       align: "center",
//     });

//     const infoStartY = 40;

//     // Helper function to handle long text and adjust line breaks
//     const writeText = (text, x, y, maxWidth = 45) => {
//       const lines = doc.splitTextToSize(text, maxWidth);
//       lines.forEach((line, index) => {
//         doc.text(line, x, y + index * 6);
//       });
//     };

//     // Enrollment No.
//     doc.setFont("helvetica", "bold");
//     doc.text("Enrollment No:", 15, infoStartY);
//     doc.setFont("helvetica", "normal");
//     doc.text(`${userData.enrollmentNumber}`, 48.5, infoStartY);

//     // Candidate Name
//     doc.setFont("helvetica", "bold");
//     doc.text("Candidate Name:", 15, infoStartY + 6);
//     doc.setFont("helvetica", "normal");
//     doc.setFontSize(9);
//     writeText(
//       `${userData.name}` || "Name Not Available",
//       48.5,
//       infoStartY + 6,
//       80
//     );
//     doc.setFontSize(11);
//     // Father's Name
//     doc.setFont("helvetica", "bold");
//     doc.text("Father's Name:", 108, infoStartY + 6);
//     doc.setFont("helvetica", "normal");
//     doc.setFontSize(9);
//     writeText(
//       `${userData.fathersname}` || "Father's Name Not Available",
//       140,
//       infoStartY + 6,
//       50
//     );
//     doc.setFontSize(11);

//     // Semester
//     doc.setFont("helvetica", "bold");
//     doc.text("Semester:", 15, infoStartY + 12);
//     doc.setFont("helvetica", "normal");
//     doc.text(`${userData.currentSemester}`, 49, infoStartY + 12);

//     // Status
//     doc.setFont("helvetica", "bold");
//     doc.text("Status:", 108, infoStartY + 12);
//     doc.setFont("helvetica", "bold");
//     doc.text("Regular", 140, infoStartY + 12);

//     // Subjects Table
//     const subjectRows = formData2.subjects.map((subject, index) => [
//       index + 1,
//       subject.subjectCode,
//       subject.subjectName,
//       subject.subjectType,
//     ]);

//     doc.autoTable({
//       startY: infoStartY + 19,
//       head: [["Sr. No.", "Subject Code", "Subject Name", "Subject Type"]],
//       body: subjectRows,
//       styles: { fontSize: 7.5, cellPadding: 2 },
//       theme: "grid",
//     });

//     // Candidate Declaration
//     const declarationStartY = doc.lastAutoTable.finalY + 10;
//     doc.setFont("helvetica", "bold");
//     doc.text("CANDIDATE’S DECLARATION:", 14, declarationStartY);
//     doc.setFont("helvetica", "normal");
//     doc.setFontSize(9);
//     const declaration = [
//       "I certify that the information provided is correct, and I am responsible for its accuracy.",
//       "I am a regular candidate for the examination conducted by Sri Satya Sai University of Technology and Medical Sciences, Sehore.",
//       "I am not enrolled as a regular student at any other institution or appearing in any other examination.",
//       "If the provided information is found false, my exam result will be canceled.",
//       "I assure to complete the required attendance and sessional work for my course.",
//       "I agree to abide by all rules and regulations set by the institution and the university.",
//       "I have read and fulfilled all requirements outlined in the relevant ordinance and have no objection to appearing in the exam.",
//       "I request the university not to disclose my results without my written consent, including under RTI.",
//     ];

//     const splitLines = declaration.map((line) =>
//       doc.splitTextToSize(`• ${line}`, 180)
//     );
//     let currentY = declarationStartY + 6;
//     splitLines.forEach((lines) => {
//       lines.forEach((subLine) => {
//         doc.text(subLine, 18, currentY);
//         currentY += 6;
//       });
//     });

//     doc.setFontSize(11);

//     // Examination Fee Details
//     const feeDetailsRows = formData2.Exam_fee.map((fee, index) => [
//       index + 1,
//       fee.txnId,
//       new Date(fee.txnDate).toLocaleString(),
//       `${fee.txnAmount}`,
//     ]);

//     const feeDetailsStartY = currentY + 3;

//     doc.setFont("helvetica", "bold");
//     doc.text("EXAMINATION FEE DETAILS :", 14, feeDetailsStartY);

//     doc.autoTable({
//       startY: feeDetailsStartY + 3,
//       head: [["Sr. No.", "Transaction No.", "Paid On", "Exam Fee"]],
//       body: feeDetailsRows,
//       styles: { fontSize: 10, cellPadding: 2 },
//       theme: "grid",
//     });

//     currentY = doc.lastAutoTable.finalY + 10;

//     // Add Student Signature (if available)
//     if (userData.applicantSignature) {
//       const img = new Image();
//       img.src = userData.applicantSignature;
//       doc.addImage(img, "PNG", 150, currentY, 40, 20);
//     }

//     // Controller of Examination Signature placeholder
//     doc.setFont("helvetica", "bold");
//     doc.text("Student Signature", 160, currentY + 4);

//     // Save PDF
//     doc.save(`${userData.name} ExaminationForm.pdf`);
//   };

//   //////////////////////////////////////////////////////////////////////

//   function isDate(val) {
//     // Cross realm comptatible
//     return Object.prototype.toString.call(val) === "[object Date]";
//   }

//   function isObj(val) {
//     return typeof val === "object";
//   }

//   function stringifyValue(val) {
//     if (isObj(val) && !isDate(val)) {
//       return JSON.stringify(val);
//     } else {
//       return val;
//     }
//   }
//   function buildForm({ action, params }) {
//     const form = document.createElement("form");
//     form.setAttribute("method", "post");
//     form.setAttribute("action", action);

//     Object.keys(params)?.forEach((key) => {
//       const input = document.createElement("input");
//       input.setAttribute("type", "hidden");
//       input.setAttribute("name", key);
//       input.setAttribute("value", stringifyValue(params[key]));
//       form.appendChild(input);
//     });
//     return form;
//   }
//   function post(details) {
//     const form = buildForm(details);
//     document.body.appendChild(form);
//     setLoading(false);
//     form.submit();
//     form.remove();
//   }

//   const getData = (data) => {
//     setLoading(false);
//     return fetch("https://sssutms.ac.in/apitest/exam-form/payment", {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(data),
//     })
//       .then((response) => response.json())
//       .catch((err) => console.log(err));
//   };

//   const id = userData?.randomId;
//   const id2 = userData._id;
//   const name = userData.name;
//   const email = userData.email;
//   const course = userData.courseName;

//   const examId = examForms[0]?.examFormId._id;

//   const handlePayment = (e) => {
//     <OnlyHeader />;
//     setLoading(true);
//     setTimeout(() => {
//       getData({ amount: 1830, id, id2, name, course, examId }).then(
//         (response) => {
//           var information = {
//             action: "https://securegw.paytm.in/order/process",
//             params: response,
//           };
//           console.log(response);
//           post(information);
//         }
//       );
//     }, 1000);
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <StudentDashboard />
//       <>
//         <Box sx={{ width: "90%", marginLeft: "80px", marginTop: "50px" }}>
//           {loading ? (
//             <CircularProgress
//               color="success"
//               style={{ marginTop: "80px", marginLeft: "50%" }}
//             />
//           ) : (
//             <CardContent>
//               <Typography
//                 variant="h6"
//                 gutterBottom
//                 sx={{ justifyContent: "center", textAlign: "center" }}
//               >
//                 Examination Form
//               </Typography>
//               <Paper sx={{ width: "105%", overflow: "auto" }}>
//                 <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
//                   <Typography
//                     variant="button"
//                     gutterBottom
//                     sx={{ display: "block" }}
//                   >
//                     <strong>Enrollment No:</strong> {userData?.enrollmentNumber}
//                   </Typography>
//                   <Typography
//                     variant="button"
//                     gutterBottom
//                     sx={{ display: "block" }}
//                   >
//                     <b> Candidate Name:</b> {userData?.name}
//                   </Typography>
//                   <Typography
//                     variant="button"
//                     gutterBottom
//                     sx={{ display: "block" }}
//                   >
//                     <b> Father Name: </b>
//                     {userData?.fathersname}
//                   </Typography>
//                 </Box>

//                 <TableContainer sx={{ maxHeight: 450 }}>
//                   <Table stickyHeader aria-label="sticky table">
//                     <TableHead>
//                       <TableRow>
//                         <TableCell
//                           align="left"
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             position: "sticky",
//                             top: 0,
//                             zIndex: 2,
//                             left: 0,
//                             color: "white",
//                           }}
//                         >
//                           S.No.
//                         </TableCell>

//                         <TableCell
//                           align="left"
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             position: "sticky",
//                             top: 0,
//                             zIndex: 2,
//                             minWidth: "100px",
//                             left: 50,
//                             color: "white",
//                           }}
//                         >
//                           Session
//                         </TableCell>
//                         <TableCell
//                           align="left"
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             position: "sticky",
//                             top: 0,
//                             zIndex: 1,
//                             color: "white",
//                           }}
//                         >
//                           Session Name
//                         </TableCell>
//                         <TableCell
//                           align="left"
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             position: "sticky",
//                             top: 0,
//                             zIndex: 1,
//                             color: "white",
//                             minWidth: "70px",
//                           }}
//                         >
//                           Semester/Year
//                         </TableCell>
//                         <TableCell
//                           align="left"
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             position: "sticky",
//                             top: 0,
//                             minWidth: "100px",
//                             zIndex: 1,
//                             color: "white",
//                           }}
//                         >
//                           Category
//                         </TableCell>
//                         <TableCell
//                           align="left"
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             position: "sticky",
//                             top: 0,
//                             zIndex: 1,
//                             minWidth: "200px",
//                             color: "white",
//                           }}
//                         >
//                           Form Status
//                         </TableCell>
//                         <TableCell
//                           align="left"
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             position: "sticky",
//                             top: 0,
//                             zIndex: 1,
//                             minWidth: "200px",
//                             color: "white",
//                           }}
//                         >
//                           ExamForm Payment
//                         </TableCell>

//                         <TableCell
//                           align="left"
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             position: "sticky",
//                             top: 0,
//                             zIndex: 1,
//                             minWidth: "150px",
//                             color: "white",
//                           }}
//                         >
//                           Click to show
//                         </TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>
//                       {getSortedAndSlicedData().map((form, index) => (
//                         <TableRow key={index}>
//                           <TableCell
//                             align="left"
//                             style={{
//                               position: "sticky",
//                               top: 0,
//                               left: 0,
//                               zIndex: 1,
//                               backgroundColor: "white",
//                             }}
//                           >
//                             {index + 1}
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{
//                               position: "sticky",
//                               left: 50,
//                               top: 0,
//                               fontWeight: 700,
//                               zIndex: 1,

//                               backgroundColor: "white",
//                               color: "red",
//                             }}
//                           >
//                             {form.examFormId.session || "N/A"}
//                           </TableCell>

//                           <TableCell align="left">
//                             {form.examFormId.sessionName || "N/A"}
//                           </TableCell>
//                           <TableCell align="left">
//                             {form.examFormId.semester || "N/A"}
//                           </TableCell>

//                           <TableCell
//                             align="left"
//                             sx={{
//                               fontWeight: "bold",
//                               position: "sticky",
//                               left: 210,
//                               backgroundColor: "white",
//                             }}
//                           >
//                             {form.examFormId.category || "N/A"}
//                           </TableCell>

//                           <TableCell align="left">
//                             {form.isForwarded === true
//                               ? "Forwarded"
//                               : "Form Not Forwarded"}
//                           </TableCell>
//                           <TableCell align="left">
//                             {form.isPaid === true ? "Paid" : "Not Paid"}
//                           </TableCell>
//                           <Button
//                             variant="contained"
//                             onClick={() => {
//                               if (!form.isForwarded) {
//                                 // Show SweetAlert if form is not forwarded
//                                 swal({
//                                   icon: "warning",
//                                   title: "Form Not Forwarded",
//                                   text: "Your form has not been forwarded. Please contact to the Department.",
//                                   buttons: "OK",
//                                 });
//                               } else if (form.isFilled && form.isPaid) {
//                                 handleShow1(form.examFormId);
//                               } else {
//                                 if (
//                                   userData.courseName ===
//                                     "BACHELOR OF COMMERCE" ||
//                                   userData.courseName ===
//                                     "BACHELOR OF SCIENCE" ||
//                                   userData.courseName === "BACHELOR OF ARTS"
//                                 ) {
//                                   handleShowNep(form.examFormId);
//                                 } else {
//                                   handleShow(form.examFormId, form.studentId);
//                                 }
//                               }
//                             }}
//                           >
//                             {form.isFilled && form.isPaid
//                               ? "Print Exam Form"
//                               : "Show Form"}
//                           </Button>
//                         </TableRow>
//                       ))}
//                     </TableBody>
//                   </Table>
//                 </TableContainer>
//               </Paper>
//             </CardContent>
//           )}

//           <br></br>
//         </Box>
//         {/* //////////////////////////////////forward form model///////////////////////// */}
//         <Modal
//           show={show}
//           onHide={handleClose}
//           size="lg"
//           centered
//           style={{ marginTop: "40px" }}
//         >
//           <Modal.Header closeButton style={{ color: "rgb(2, 48, 71)" }}>
//             <Modal.Title
//               variant="button"
//               style={{
//                 height: "8px",
//                 marginTop: "2px",
//                 fontFamily: "Poppins,sans-serif",
//               }}
//             >
//               Examination Form
//             </Modal.Title>
//           </Modal.Header>

//           <Modal.Body>
//             <div id="exam-form" style={{ padding: "20px" }}>
//               <div
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   marginBottom: "10px",
//                 }}
//               >
//                 <img
//                   src={sssutms}
//                   alt="Logo"
//                   style={{ width: "100px", marginRight: "20px" }}
//                 />
//                 <div>
//                   <Typography
//                     style={{ margin: 0, fontSize: "15px", fontWeight: "bold" }}
//                   >
//                     SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES,
//                     SEHORE
//                   </Typography>

//                   <Typography
//                     variant="body1"
//                     style={{ margin: 0, marginLeft: "90px" }}
//                   >
//                     ( {userData.courseBranch})
//                   </Typography>
//                   <Typography
//                     variant="body1"
//                     style={{ margin: 0, marginLeft: "160px" }}
//                   >
//                     Session: {examForms[0]?.examFormId?.sessionName}
//                   </Typography>
//                 </div>
//               </div>

//               <Form>
//                 <Row className="mb-2">
//                   <Form.Group as={Col}>
//                     <Form.Label>Enrollment No: </Form.Label>
//                     <span> {userData?.enrollmentNumber}</span>
//                   </Form.Group>
//                   <Form.Group as={Col}>
//                     <Form.Label>Semester/Year: </Form.Label>
//                     <span> {examForms[0]?.examFormId?.semester}</span>
//                   </Form.Group>
//                 </Row>
//                 <Row className="mb-2">
//                   <Form.Group as={Col}>
//                     <Form.Label>Candidate's Name: </Form.Label>
//                     <span> {userData?.name}</span>
//                   </Form.Group>
//                   <Form.Group as={Col}>
//                     <Form.Label>Father's Name:</Form.Label>
//                     <span> {userData?.fathersname}</span>
//                   </Form.Group>
//                 </Row>
//                 <Row className="mb-3">
//                   <Form.Group as={Col}>
//                     <Form.Label>Mother's Name: </Form.Label>
//                     <span> {userData?.mothersname}</span>
//                   </Form.Group>
//                   <Form.Group as={Col}>
//                     <Form.Label>Status: </Form.Label>
//                     <span> {examForms[0]?.examFormId?.category}</span>
//                   </Form.Group>
//                 </Row>

//                 <Table striped bordered hover>
//                   <TableHead
//                     style={{
//                       backgroundColor: "rgba(224, 224, 224, 1)",
//                     }}
//                   >
//                     <TableRow
//                       sx={{
//                         height: "30px",
//                       }}
//                     >
//                       <TableCell
//                         sx={{
//                           padding: "4px 8px",
//                           fontSize: "0.9rem",
//                         }}
//                       >
//                         S.No
//                       </TableCell>
//                       <TableCell
//                         sx={{
//                           padding: "4px 8px",
//                           fontSize: "0.9rem",
//                         }}
//                       >
//                         Subject Code
//                       </TableCell>
//                       <TableCell
//                         sx={{
//                           padding: "4px 8px",
//                           fontSize: "0.9rem",
//                         }}
//                       >
//                         Subject Name
//                       </TableCell>
//                       <TableCell
//                         sx={{
//                           padding: "4px 8px",
//                           fontSize: "0.9rem",
//                         }}
//                       >
//                         Subject Type
//                       </TableCell>
//                       <TableCell
//                         sx={{
//                           padding: "4px 8px",
//                           fontSize: "0.9rem",
//                         }}
//                       >
//                         Elective
//                       </TableCell>
//                     </TableRow>
//                   </TableHead>

//                   <TableBody>
//                     {compulsorySubjects
//                       .concat(nonCompulsorySubjects)
//                       .sort((a, b) => {
//                         if (
//                           a.subjectType === "Theory" &&
//                           b.subjectType !== "Theory"
//                         )
//                           return -1;
//                         if (
//                           a.subjectType !== "Theory" &&
//                           b.subjectType === "Theory"
//                         )
//                           return 1;
//                         return 0;
//                       })
//                       .map((subject, index) => {
//                         // Logic for selecting corresponding subjects automatically based on subjectName
//                         const isTheorySelected =
//                           selectedSubjects.theory?.subjectName ===
//                           subject.subjectName;
//                         const isPracticalSelected =
//                           selectedSubjects.practical?.subjectName ===
//                           subject.subjectName;

//                         const isSelected =
//                           subject.compulsory ||
//                           isTheorySelected ||
//                           isPracticalSelected ||
//                           (subject.subjectType === "Theory" &&
//                             selectedSubjects.practical?.subjectName ===
//                               `${subject.subjectName} Practical`) ||
//                           (subject.subjectType === "Practical" &&
//                             selectedSubjects.theory?.subjectName ===
//                               `${subject.subjectName} Theory`);

//                         return (
//                           <TableRow
//                             key={`subject-${index}`}
//                             sx={{ height: "10px" }}
//                           >
//                             <TableCell
//                               sx={{ padding: "0px 10px", fontSize: "0.85rem" }}
//                             >
//                               {index + 1}
//                             </TableCell>
//                             <TableCell
//                               sx={{ padding: "0px 10px", fontSize: "0.85rem" }}
//                             >
//                               {subject.subjectCode}
//                             </TableCell>
//                             <TableCell
//                               sx={{ padding: "0px 10px", fontSize: "0.85rem" }}
//                             >
//                               {subject.subjectName}
//                             </TableCell>
//                             <TableCell
//                               sx={{ padding: "0px 10px", fontSize: "0.85rem" }}
//                             >
//                               {subject.subjectType}
//                             </TableCell>
//                             <TableCell
//                               sx={{ padding: "0px 10px", fontSize: "0.85rem" }}
//                             >
//                               <Checkbox
//                                 sx={{ transform: "scale(0.7)" }}
//                                 checked={isSelected}
//                                 disabled={subject.compulsory} // Compulsory subjects should be disabled
//                                 color="primary"
//                                 onChange={(e) =>
//                                   handleCheckboxChange(subject, e)
//                                 }
//                               />
//                             </TableCell>
//                           </TableRow>
//                         );
//                       })}
//                   </TableBody>
//                 </Table>

//                 <div style={{ marginTop: "20px" }}>
//                   <Typography variant="h6">CANDIDATE'S DESCRIPTION</Typography>
//                   <hr
//                     style={{
//                       width: "40%",
//                       height: "2px",
//                       backgroundColor: "gray",
//                       margin: "-2px 0",
//                     }}
//                   />
//                   <Typography variant="body2" gutterBottom>
//                     <ul style={{ marginLeft: "20px", marginTop: "10px" }}>
//                       <li>
//                         I certify that this application has been filled by me
//                         and the information given their in is correct and I
//                         shall be provisionally responsible for the same proved
//                         false later on.
//                       </li>
//                       <li>
//                         I also certify that i am apperaing as regularcandidate
//                         only in the examination being conducted by the Sri Satya
//                         Sai University of Technology and Medical Sciences,
//                         Sehore.
//                       </li>
//                       <li>
//                         I further d eclare that i am neither a regular student
//                         of any other Education Instituation on apperaing in any
//                         other examination as regular candidate.
//                       </li>
//                       <li>
//                         I understand that if it is found later on that the
//                         information furnished above is false then my result of
//                         examination will bw cancelled
//                       </li>
//                       <li>
//                         I assure that I will complete the require attendence and
//                         sessional work prescribed for thye course of my
//                         registration. Kindly permit me to appear in this
//                         examination.
//                       </li>
//                       <li>
//                         I accept to abide by all the rules and regulations of
//                         study and examination as prescribed by the institution &
//                         Sri Satya Sai University of Technology and Medical
//                         Sciences, Sehore
//                       </li>
//                       <li>
//                         I have read relevant ordinance applicable to be and have
//                         completed all the requirements as given in it. I have
//                         completed my studies and have no objection in appearing
//                         in examination on the date declared by the university
//                       </li>
//                       <li>
//                         <b>
//                           I also requesting to the University that without my
//                           written concent, do not disclose my result to anybody
//                           in any manner including RTI.
//                         </b>
//                       </li>
//                     </ul>
//                     <Checkbox checked={ischecked2} onChange={handleCheckbox} />{" "}
//                     I accept all above points.
//                   </Typography>
//                   {ischecked2 && (
//                     <div
//                       style={{
//                         padding: "15px 20px",
//                         marginTop: "-4%",
//                         backgroundColor: "#f9f9f9",
//                         border: "1px solid #ddd",
//                         borderRadius: "8px",
//                       }}
//                     >
//                       <Typography variant="h6" gutterBottom>
//                         Total Fees to Pay ₹ 1830 Only{" "}
//                       </Typography>
//                       {isLoading ? (
//                         <div
//                           style={{
//                             display: "flex",
//                             alignItems: "center",
//                             gap: "10px",
//                           }}
//                         >
//                           <CircularProgress size={24} />
//                           <Typography>Loading...</Typography>
//                         </div>
//                       ) : (
//                         isApiSuccess && (
//                           <Button
//                             style={{
//                               backgroundColor: "rgb(2,48,71)",
//                               color: "white",
//                             }}
//                             onClick={handlePayment}
//                           >
//                             PAY NOW
//                           </Button>
//                         )
//                       )}
//                     </div>
//                   )}
//                 </div>
//               </Form>
//             </div>
//           </Modal.Body>
//         </Modal>

//         {/* //////////////////////////////////forward form model///////////////////////// */}
//         {/* //////////////////////////////////NEP forward form model///////////////////////// */}
//         <Modal
//           show={showNep}
//           onHide={handleCloseNep}
//           size="lg"
//           centered
//           style={{ marginTop: "40px" }}
//         >
//           <Modal.Header closeButton style={{ color: "rgb(2, 48, 71)" }}>
//             <Modal.Title
//               variant="button"
//               style={{
//                 height: "8px",
//                 marginTop: "2px",
//                 fontFamily: "Poppins,sans-serif",
//               }}
//             >
//               Examination Form NEP
//             </Modal.Title>
//           </Modal.Header>

//           <Modal.Body>
//             <div id="exam-form" style={{ padding: "20px" }}>
//               <div
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   marginBottom: "10px",
//                 }}
//               >
//                 <img
//                   src={sssutms}
//                   alt="Logo"
//                   style={{ width: "100px", marginRight: "20px" }}
//                 />
//                 <div>
//                   <Typography
//                     style={{ margin: 0, fontSize: "15px", fontWeight: "bold" }}
//                   >
//                     SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES,
//                     SEHORE
//                   </Typography>

//                   <Typography
//                     variant="body1"
//                     style={{ margin: 0, marginLeft: "90px" }}
//                   >
//                     ( {userData.courseBranch})
//                   </Typography>
//                   <Typography
//                     variant="body1"
//                     style={{ margin: 0, marginLeft: "160px" }}
//                   >
//                     {/* Session: {examForms[0]?.examFormId?.sessionName} */}
//                     Session: DEC-2024
//                   </Typography>
//                 </div>
//               </div>

//               <Form>
//                 <Row className="mb-2">
//                   <Form.Group as={Col}>
//                     <Form.Label>Enrollment No: </Form.Label>
//                     <span> {userData?.enrollmentNumber}</span>
//                   </Form.Group>
//                   <Form.Group as={Col}>
//                     <Form.Label>Semester/Year: </Form.Label>
//                     <span> {examForms[0]?.examFormId?.semester}</span>
//                   </Form.Group>
//                 </Row>
//                 <Row className="mb-2">
//                   <Form.Group as={Col}>
//                     <Form.Label>Candidate's Name: </Form.Label>
//                     <span> {userData?.name}</span>
//                   </Form.Group>
//                   <Form.Group as={Col}>
//                     <Form.Label>Father's Name:</Form.Label>
//                     <span> {userData?.fathersname}</span>
//                   </Form.Group>
//                 </Row>
//                 <Row className="mb-3">
//                   <Form.Group as={Col}>
//                     <Form.Label>Mother's Name: </Form.Label>
//                     <span> {userData?.mothersname}</span>
//                   </Form.Group>
//                   <Form.Group as={Col}>
//                     <Form.Label>Status: </Form.Label>
//                     <span> {examForms[0]?.examFormId?.category}</span>
//                   </Form.Group>
//                 </Row>

//                 <Table striped bordered hover>
//                   <TableHead
//                     style={{
//                       backgroundColor: "rgba(224, 224, 224, 1)",
//                     }}
//                   >
//                     <TableRow
//                       sx={{
//                         height: "30px",
//                       }}
//                     >
//                       <TableCell
//                         sx={{
//                           padding: "4px 8px",
//                           fontSize: "0.9rem",
//                         }}
//                       >
//                         S.No
//                       </TableCell>
//                       <TableCell
//                         sx={{
//                           padding: "4px 8px",
//                           fontSize: "0.9rem",
//                         }}
//                       >
//                         Subject Code
//                       </TableCell>
//                       <TableCell
//                         sx={{
//                           padding: "4px 8px",
//                           fontSize: "0.9rem",
//                         }}
//                       >
//                         Subject Name
//                       </TableCell>
//                       <TableCell
//                         sx={{
//                           padding: "4px 8px",
//                           fontSize: "0.9rem",
//                         }}
//                       >
//                         Subject Type
//                       </TableCell>
//                       <TableCell
//                         sx={{
//                           padding: "4px 8px",
//                           fontSize: "0.9rem",
//                         }}
//                       >
//                         Elective
//                       </TableCell>
//                     </TableRow>
//                   </TableHead>
//                   {userData.courseName === "BACHELOR OF ARTS" ? (
//                     <TableBody>
//                       {[
//                         { category: "Major", data: majorSubjects },
//                         { category: "Minor", data: minorSubjects },
//                         { category: "Generic", data: genericElectives },
//                         { category: "Foundation", data: foundationElectives },
//                       ].map((group, groupIndex) => (
//                         <React.Fragment key={groupIndex}>
//                           <TableRow>
//                             <TableCell
//                               colSpan={5}
//                               sx={{
//                                 backgroundColor: "#f0f0f0",
//                                 fontWeight: "bold",
//                                 textAlign: "center",
//                                 padding: "5px 10px",
//                                 fontSize: "0.9rem",
//                               }}
//                             >
//                               {group.category} Subjects
//                             </TableCell>
//                           </TableRow>
//                           {group.data.map((subject, index) => {
//                             // ✅ Improved isSelected Logic (Theory + Practical Pairing)
//                             const isSelected =
//                               selectedSubjects[group.category]?.some(
//                                 (s) => s.subjectCode === subject.subjectCode
//                               ) ||
//                               subject.compulsory ||
//                               (subject.subjectType === "Theory" &&
//                                 selectedSubjects[group.category]?.some(
//                                   (s) =>
//                                     s.subjectName ===
//                                     `${subject.subjectName} Practical`
//                                 )) ||
//                               (subject.subjectType === "Practical" &&
//                                 selectedSubjects[group.category]?.some(
//                                   (s) =>
//                                     s.subjectName ===
//                                     `${subject.subjectName} Theory`
//                                 ));

//                             const isDisabled = selectedSubjects[
//                               `disabled${group.category}Subjects`
//                             ]?.some(
//                               (s) => s.subjectCode === subject.subjectCode
//                             );

//                             return (
//                               <TableRow
//                                 key={`subject-${groupIndex}-${index}`}
//                                 sx={{ height: "10px" }}
//                               >
//                                 <TableCell
//                                   sx={{
//                                     padding: "0px 10px",
//                                     fontSize: "0.85rem",
//                                   }}
//                                 >
//                                   {index + 1}
//                                 </TableCell>
//                                 <TableCell
//                                   sx={{
//                                     padding: "0px 10px",
//                                     fontSize: "0.85rem",
//                                   }}
//                                 >
//                                   {subject.subjectCode}
//                                 </TableCell>
//                                 <TableCell
//                                   sx={{
//                                     padding: "0px 10px",
//                                     fontSize: "0.85rem",
//                                   }}
//                                 >
//                                   {subject.subjectName}
//                                 </TableCell>
//                                 <TableCell
//                                   sx={{
//                                     padding: "0px 10px",
//                                     fontSize: "0.85rem",
//                                   }}
//                                 >
//                                   {subject.subjectType}
//                                 </TableCell>
//                                 <TableCell
//                                   sx={{
//                                     padding: "0px 10px",
//                                     fontSize: "0.85rem",
//                                   }}
//                                 >
//                                   <Checkbox
//                                     sx={{ transform: "scale(0.7)" }}
//                                     checked={isSelected}
//                                     disabled={isDisabled}
//                                     color="primary"
//                                     onChange={(e) =>
//                                       handleCheckboxbaChange(
//                                         subject,
//                                         e,
//                                         group.category
//                                       )
//                                     }
//                                   />
//                                 </TableCell>
//                               </TableRow>
//                             );
//                           })}
//                         </React.Fragment>
//                       ))}
//                     </TableBody>
//                   ) : (
//                     <TableBody>
//                       {[
//                         { category: "Major", data: majorSubjects },
//                         { category: "Minor", data: minorSubjects },
//                         {
//                           category: "Generic Elective",
//                           data: genericElectives,
//                         },
//                         { category: "Foundation", data: foundationElectives },
//                       ].map((group, groupIndex) => (
//                         <React.Fragment key={groupIndex}>
//                           <TableRow>
//                             <TableCell
//                               colSpan={5}
//                               sx={{
//                                 backgroundColor: "#f0f0f0",
//                                 fontWeight: "bold",
//                                 textAlign: "center",
//                                 padding: "5px 10px",
//                                 fontSize: "0.9rem",
//                               }}
//                             >
//                               {group.category} Subjects
//                             </TableCell>
//                           </TableRow>
//                           {group.data.map((subject, index) => {
//                             const subjectWithCategory = {
//                               ...subject,
//                               category: group.category,
//                             };

//                             const isSelected =
//                               selectednepSubjects[group.category]
//                                 ?.subjectName === subject.subjectName ||
//                               subject.compulsory ||
//                               (subject.subjectType === "Theory" &&
//                                 selectednepSubjects.practical?.subjectName ===
//                                   `${subject.subjectName} Practical`) ||
//                               (subject.subjectType === "Practical" &&
//                                 selectednepSubjects.theory?.subjectName ===
//                                   `${subject.subjectName} Theory`);

//                             const isDisabled =
//                               subject.compulsory ||
//                               (userData.courseName === "BACHELOR OF COMMERCE" &&
//                                 (group.category === "Major" ||
//                                   group.category === "Minor") &&
//                                 isSelected);

//                             return (
//                               <TableRow
//                                 key={`subject-${groupIndex}-${index}`}
//                                 sx={{ height: "10px" }}
//                               >
//                                 <TableCell
//                                   sx={{
//                                     padding: "0px 10px",
//                                     fontSize: "0.85rem",
//                                   }}
//                                 >
//                                   {index + 1}
//                                 </TableCell>
//                                 <TableCell
//                                   sx={{
//                                     padding: "0px 10px",
//                                     fontSize: "0.85rem",
//                                   }}
//                                 >
//                                   {subject.subjectCode}
//                                 </TableCell>
//                                 <TableCell
//                                   sx={{
//                                     padding: "0px 10px",
//                                     fontSize: "0.85rem",
//                                   }}
//                                 >
//                                   {subject.subjectName}
//                                 </TableCell>
//                                 <TableCell
//                                   sx={{
//                                     padding: "0px 10px",
//                                     fontSize: "0.85rem",
//                                   }}
//                                 >
//                                   {subject.subjectType}
//                                 </TableCell>
//                                 <TableCell
//                                   sx={{
//                                     padding: "0px 10px",
//                                     fontSize: "0.85rem",
//                                   }}
//                                 >
//                                   <Checkbox
//                                     sx={{ transform: "scale(0.7)" }}
//                                     checked={isSelected}
//                                     disabled={isDisabled}
//                                     color="primary"
//                                     onChange={(e) =>
//                                       handleCheckboxnepChange2(
//                                         subjectWithCategory,
//                                         e
//                                       )
//                                     }
//                                   />
//                                 </TableCell>
//                               </TableRow>
//                             );
//                           })}
//                         </React.Fragment>
//                       ))}
//                     </TableBody>
//                   )}
//                 </Table>

//                 <div style={{ marginTop: "20px" }}>
//                   <Typography variant="h6">CANDIDATE'S DESCRIPTION</Typography>
//                   <hr
//                     style={{
//                       width: "40%",
//                       height: "2px",
//                       backgroundColor: "gray",
//                       margin: "-2px 0",
//                     }}
//                   />
//                   <Typography variant="body2" gutterBottom>
//                     <ul style={{ marginLeft: "20px", marginTop: "10px" }}>
//                       <li>
//                         I certify that this application has been filled by me
//                         and the information given their in is correct and I
//                         shall be provisionally responsible for the same proved
//                         false later on.
//                       </li>
//                       <li>
//                         I also certify that i am apperaing as regularcandidate
//                         only in the examination being conducted by the Sri Satya
//                         Sai University of Technology and Medical Sciences,
//                         Sehore.
//                       </li>
//                       <li>
//                         I further d eclare that i am neither a regular student
//                         of any other Education Instituation on apperaing in any
//                         other examination as regular candidate.
//                       </li>
//                       <li>
//                         I understand that if it is found later on that the
//                         information furnished above is false then my result of
//                         examination will bw cancelled
//                       </li>
//                       <li>
//                         I assure that I will complete the require attendence and
//                         sessional work prescribed for thye course of my
//                         registration. Kindly permit me to appear in this
//                         examination.
//                       </li>
//                       <li>
//                         I accept to abide by all the rules and regulations of
//                         study and examination as prescribed by the institution &
//                         Sri Satya Sai University of Technology and Medical
//                         Sciences, Sehore
//                       </li>
//                       <li>
//                         I have read relevant ordinance applicable to be and have
//                         completed all the requirements as given in it. I have
//                         completed my studies and have no objection in appearing
//                         in examination on the date declared by the university
//                       </li>
//                       <li>
//                         <b>
//                           I also requesting to the University that without my
//                           written concent, do not disclose my result to anybody
//                           in any manner including RTI.
//                         </b>
//                       </li>
//                     </ul>
//                     <Checkbox
//                       checked={ischecked2}
//                       onChange={handleCheckboxnep}
//                     />{" "}
//                     I accept all above points.
//                   </Typography>
//                   {ischecked2 && (
//                     <div
//                       style={{
//                         padding: "15px 20px",
//                         marginTop: "-4%",
//                         backgroundColor: "#f9f9f9",
//                         border: "1px solid #ddd",
//                         borderRadius: "8px",
//                       }}
//                     >
//                       <Typography variant="h6" gutterBottom>
//                         Total Fees to Pay ₹ 1830 Only{" "}
//                       </Typography>
//                       {isLoading ? (
//                         <div
//                           style={{
//                             display: "flex",
//                             alignItems: "center",
//                             gap: "10px",
//                           }}
//                         >
//                           <CircularProgress size={24} />
//                           <Typography>Loading...</Typography>
//                         </div>
//                       ) : (
//                         isApiSuccess && (
//                           <Button
//                             style={{
//                               backgroundColor: "rgb(2,48,71)",
//                               color: "white",
//                             }}
//                             onClick={handlePayment}
//                           >
//                             PAY NOW
//                           </Button>
//                         )
//                       )}
//                     </div>
//                   )}
//                 </div>
//               </Form>
//             </div>
//           </Modal.Body>
//         </Modal>
//         {/* //////////////////////////////////Print form model///////////////////////// */}

//         <Modal
//           show={show1}
//           onHide={handleClose1}
//           size="lg"
//           centered
//           style={{ marginTop: "40px" }}
//         >
//           <Modal.Header closeButton style={{ color: "rgb(2, 48, 71)" }}>
//             <Modal.Title
//               variant="button"
//               style={{
//                 height: "8px",
//                 marginTop: "2px",
//                 fontFamily: "Poppins,sans-serif",
//               }}
//             >
//               Examination Form
//             </Modal.Title>
//           </Modal.Header>

//           <Modal.Body>
//             <div id="exam-form" style={{ padding: "20px" }}>
//               <div
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   marginBottom: "10px",
//                 }}
//               >
//                 <img
//                   src={sssutms}
//                   alt="Logo"
//                   style={{ width: "100px", marginRight: "20px" }}
//                 />
//                 <div>
//                   <Typography
//                     style={{ margin: 0, fontSize: "15px", fontWeight: "bold" }}
//                   >
//                     SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES,
//                     SEHORE
//                   </Typography>

//                   <Typography
//                     variant="body1"
//                     style={{ margin: 0, textAlign: "center" }}
//                   >
//                     ( {userData.courseBranch})
//                   </Typography>
//                   <Typography
//                     variant="body1"
//                     style={{ margin: 0, textAlign: "center" }}
//                   >
//                     Session: {examForms[0]?.examFormId?.sessionName}
//                   </Typography>
//                 </div>
//               </div>

//               <Form>
//                 <Row className="mb-2">
//                   <Form.Group as={Col}>
//                     <Form.Label>Enrollment No: </Form.Label>
//                     <span> {userData?.enrollmentNumber}</span>
//                   </Form.Group>
//                   <Form.Group as={Col}>
//                     <Form.Label>Semester/Year: </Form.Label>
//                     <span> {examForms[0]?.examFormId?.semester}</span>
//                   </Form.Group>
//                 </Row>
//                 <Row className="mb-2">
//                   <Form.Group as={Col}>
//                     <Form.Label>Candidate's Name: </Form.Label>
//                     <span> {userData?.name}</span>
//                   </Form.Group>
//                   <Form.Group as={Col}>
//                     <Form.Label>Father's Name:</Form.Label>
//                     <span> {userData?.fathersname}</span>
//                   </Form.Group>
//                 </Row>
//                 <Row className="mb-3">
//                   <Form.Group as={Col}>
//                     <Form.Label>Mother's Name: </Form.Label>
//                     <span> {userData?.mothersname}</span>
//                   </Form.Group>
//                   <Form.Group as={Col}>
//                     <Form.Label>Status: </Form.Label>
//                     <span> {examForms[0]?.examFormId?.category}</span>
//                   </Form.Group>
//                 </Row>

//                 <Table striped bordered hover>
//                   <TableHead
//                     style={{
//                       backgroundColor: "rgba(224, 224, 224, 1)",
//                     }}
//                   >
//                     <TableRow
//                       sx={{
//                         height: "30px",
//                       }}
//                     >
//                       <TableCell
//                         sx={{
//                           padding: "4px 8px",
//                           fontSize: "0.9rem",
//                         }}
//                       >
//                         Sr.No
//                       </TableCell>
//                       <TableCell
//                         sx={{
//                           padding: "4px 8px",
//                           fontSize: "0.9rem",
//                         }}
//                       >
//                         Subject Code
//                       </TableCell>
//                       <TableCell
//                         sx={{
//                           padding: "4px 8px",
//                           fontSize: "0.9rem",
//                         }}
//                       >
//                         Subject Name
//                       </TableCell>
//                       <TableCell
//                         sx={{
//                           padding: "4px 8px",
//                           fontSize: "0.9rem",
//                         }}
//                       >
//                         Subject Type
//                       </TableCell>
//                     </TableRow>
//                   </TableHead>

//                   <TableBody>
//                     {formData2?.subjects?.map((subject, index) => (
//                       <TableRow key={index} sx={{ height: "30px" }}>
//                         <TableCell
//                           sx={{ padding: "4px 8px", fontSize: "0.85rem" }}
//                         >
//                           {index + 1}
//                         </TableCell>
//                         <TableCell
//                           sx={{ padding: "4px 8px", fontSize: "0.85rem" }}
//                         >
//                           {subject.subjectCode}
//                         </TableCell>
//                         <TableCell
//                           sx={{ padding: "4px 8px", fontSize: "0.85rem" }}
//                         >
//                           {subject.subjectName}
//                         </TableCell>
//                         <TableCell
//                           sx={{ padding: "4px 8px", fontSize: "0.85rem" }}
//                         >
//                           {subject.subjectType}
//                         </TableCell>
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>

//                 <div style={{ marginTop: "20px" }}>
//                   <Typography variant="h6">CANDIDATE'S DESCRIPTION</Typography>
//                   <hr
//                     style={{
//                       width: "40%",
//                       height: "2px",
//                       backgroundColor: "gray",
//                       margin: "-2px 0",
//                     }}
//                   />
//                   <Typography variant="body2" gutterBottom>
//                     <ul style={{ marginLeft: "20px", marginTop: "10px" }}>
//                       <li>
//                         I certify that this application has been filled by me
//                         and the information given their in is correct and I
//                         shall be provisionally responsible for the same proved
//                         false later on.
//                       </li>
//                       <li>
//                         I also certify that i am apperaing as regularcandidate
//                         only in the examination being conducted by the Sri Satya
//                         Sai University of Technology and Medical Sciences,
//                         Sehore.
//                       </li>
//                       <li>
//                         I further d eclare that i am neither a regular student
//                         of any other Education Instituation on apperaing in any
//                         other examination as regular candidate.
//                       </li>
//                       <li>
//                         I understand that if it is found later on that the
//                         information furnished above is false then my result of
//                         examination will bw cancelled
//                       </li>
//                       <li>
//                         I assure that I will complete the require attendence and
//                         sessional work prescribed for thye course of my
//                         registration. Kindly permit me to appear in this
//                         examination.
//                       </li>
//                       <li>
//                         I accept to abide by all the rules and regulations of
//                         study and examination as prescribed by the institution &
//                         Sri Satya Sai University of Technology and Medical
//                         Sciences, Sehore
//                       </li>
//                       <li>
//                         I have read relevant ordinance applicable to be and have
//                         completed all the requirements as given in it. I have
//                         completed my studies and have no objection in appearing
//                         in examination on the date declared by the university
//                       </li>
//                       <li>
//                         <b>
//                           I also requesting to the University that without my
//                           written concent, do not disclose my result to anybody
//                           in any manner including RTI.
//                         </b>
//                       </li>
//                     </ul>
//                   </Typography>
//                 </div>
//               </Form>
//             </div>
//           </Modal.Body>
//           <div
//             style={{
//               padding: "15px 20px",
//               marginTop: "-4%",
//               backgroundColor: "#f9f9f9", // Light background
//               border: "1px solid #ddd", // Border around the box
//               borderRadius: "8px", // Rounded corners
//             }}
//           >
//             <>
//               <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
//                 EXAMINATION FEE DETAILS
//               </Typography>
//               <hr
//                 style={{
//                   width: "30%",
//                   height: "2px",
//                   backgroundColor: "#333",
//                   margin: "-2px 0 10px 0",
//                 }}
//               />
//               {formData2?.Exam_fee?.map((fee, index) => (
//                 <Typography key={index} variant="subtitle2" gutterBottom>
//                   <strong>Transaction No:</strong> {fee.txnId},{" "}
//                   <strong>Paid On:</strong>{" "}
//                   {new Date(fee.txnDate).toLocaleString()},{" "}
//                   <strong>Exam Fee:</strong> ₹{fee.txnAmount}
//                 </Typography>
//               ))}
//             </>
//           </div>

//           {ischecked && (
//             <div
//               style={{
//                 padding: "15px 20px",
//                 marginTop: "-4%",
//                 backgroundColor: "#f9f9f9",
//                 border: "1px solid #ddd",
//                 borderRadius: "8px",
//               }}
//             >
//               <Typography variant="h6" gutterBottom>
//                 Total Fees to Pay ₹ 1830 Only{" "}
//                 <Button
//                   style={{ backgroundColor: "rgb(2,48,71)", color: "white" }}
//                   onClick={() => handlePayment()}
//                 >
//                   PAY NOW
//                 </Button>
//               </Typography>
//             </div>
//           )}
//           <Modal.Footer>
//             <Button variant="text" onClick={handleClose1}>
//               Close
//             </Button>
//             <Button variant="outlined" onClick={generatePDF}>
//               Download as PDF
//             </Button>
//           </Modal.Footer>
//         </Modal>

//         {/* //////////////////////////////////Print form model///////////////////////// */}
//       </>
//     </ThemeProvider>
//   );
// }
// export default Std_ExamForm;

// // import React, { useEffect, useState } from "react";
// // import swal from "sweetalert";
// // import {
// //   Box,
// //   CardContent,
// //   CircularProgress,
// //   Paper,
// //   Table,
// //   TableBody,
// //   TableCell,
// //   TableContainer,
// //   TableHead,
// //   TableRow,
// //   Button,
// //   Checkbox,
// // } from "@mui/material";
// // import { Form, Row, Col } from "react-bootstrap";
// // import Modal from "react-bootstrap/Modal";
// // import sssutms from "../../../Main_Admin/images/sssutms.jpg";
// // import Typography from "@mui/material/Typography";
// // import { createTheme, ThemeProvider } from "@mui/material/styles";
// // import StudentDashboard from "../../../AdminComponent/StudentDashboard";
// // import jsPDF from "jspdf";
// // import "jspdf-autotable";
// // import OnlyHeader from "../../../AdminComponent/OnlyHeader";

// // // Theme Configuration
// // const theme = createTheme({
// //   typography: {
// //     fontWeightBold: 700,
// //   },
// // });

// // // Main Component
// // function Std_ExamForm() {
// //   // State Management
// //   const [sortBy, setSortBy] = useState("");
// //   const [sortOrder, setSortOrder] = useState("asc");
// //   const [page, setPage] = useState(0);
// //   const [rowsPerPage] = useState(8);
// //   const [examForms, setExamForms] = useState([]);
// //   const [loading, setLoading] = useState(false);
// //   const [isChecked, setIsChecked] = useState(false);
// //   const [isLoading, setIsLoading] = useState(false);
// //   const [isApiSuccess, setIsApiSuccess] = useState(false);

// //   // Subject States
// //   const [majorSubjects, setMajorSubjects] = useState([]);
// //   const [minorSubjects, setMinorSubjects] = useState([]);
// //   const [genericElectives, setGenericElectives] = useState([]);
// //   const [foundationElectives, setFoundationElectives] = useState([]);
// //   const [selectedSubjects, setSelectedSubjects] = useState({
// //     theory: null,
// //     practical: null,
// //   });
// //   const [compulsorySubjects, setCompulsorySubjects] = useState([]);
// //   const [nonCompulsorySubjects, setNonCompulsorySubjects] = useState([]);
// //   const [formData, setFormData] = useState(null);
// //   const [formData2, setFormData2] = useState(null);
// //   const [selectedNepSubjects, setSelectedNepSubjects] = useState([]);

// //   // Modal States
// //   const [showRegular, setShowRegular] = useState(false);
// //   const [showNep, setShowNep] = useState(false);
// //   const [showPrint, setShowPrint] = useState(false);
// //   const [selectedExamForm, setSelectedExamForm] = useState(null);

// //   const userData = JSON.parse(sessionStorage.getItem("currentUser"));

// //   // Fetch Exam Forms
// //   useEffect(() => {
// //     const fetchExamForms = async () => {
// //       try {
// //         setLoading(true);
// //         const response = await fetch(
// //           `https://sssutms.ac.in/apitest/v1/getExamForms?studentId=${userData._id}`
// //         );
// //         const result = await response.json();
// //         if (response.ok) {
// //           setExamForms(result.data);
// //         } else {
// //           console.error(result.message);
// //         }
// //       } catch (error) {
// //         console.error("Error fetching exam forms:", error);
// //       } finally {
// //         setLoading(false);
// //       }
// //     };
// //     fetchExamForms();
// //   }, [userData._id]);

// //   // Save Subjects Handler
// //   const handleSaveSubjects = async (isNep = false) => {
// //     setIsLoading(true);
// //     try {
// //       await new Promise((resolve) => setTimeout(resolve, 2000));

// //       let subjectsToSave = [];
// //       if (isNep) {
// //         subjectsToSave = [
// //           ...compulsorySubjects,
// //           ...Object.values(selectedNepSubjects).flat(),
// //         ].filter(
// //           (subject, index, self) =>
// //             index === self.findIndex((s) => s.subjectCode === subject.subjectCode)
// //         );
// //       } else {
// //         const { theory, practical } = selectedSubjects;
// //         const selectedNonCompulsory = nonCompulsorySubjects
// //           .filter(
// //             (subject) =>
// //               (subject.subjectType === "Theory" && theory?.subjectCode === subject.subjectCode) ||
// //               (subject.subjectType === "Practical" && practical?.subjectCode === subject.subjectCode)
// //           )
// //           .map((subject) => ({
// //             subjectCode: subject.subjectCode,
// //             subjectName: subject.subjectName,
// //             subjectType: subject.subjectType,
// //           }));
// //         subjectsToSave = [...compulsorySubjects, ...selectedNonCompulsory];
// //       }

// //       const response = await fetch(
// //         "https://sssutms.ac.in/apitest/student/forward-form",
// //         {
// //           method: "POST",
// //           headers: {
// //             "Content-Type": "application/json",
// //           },
// //           body: JSON.stringify({
// //             studentId: userData._id,
// //             examFormId: selectedExamForm?.examFormId._id, // Use selected exam form ID
// //             subjects: subjectsToSave,
// //           }),
// //         }
// //       );

// //       if (!response.ok) throw new Error("Failed to save subjects");
// //       const responseData = await response.json();
// //       console.log("API response:", responseData);
// //       setIsApiSuccess(true);
// //     } catch (error) {
// //       console.error("Error saving subjects:", error);
// //     } finally {
// //       setIsLoading(false);
// //     }
// //   };

// //   // Checkbox Handlers
// //   const handleCheckbox = (e) => {
// //     if (e.target.checked) {
// //       const nonCompulsoryExist = nonCompulsorySubjects.length > 0;
// //       const anySelected = nonCompulsorySubjects.some(
// //         (subject) =>
// //           (subject.subjectType === "Theory" && selectedSubjects.theory?.subjectCode === subject.subjectCode) ||
// //           (subject.subjectType === "Practical" && selectedSubjects.practical?.subjectCode === subject.subjectCode)
// //       );
// //       if (nonCompulsoryExist && !anySelected) {
// //         swal({
// //           title: "Warning!",
// //           text: "Please select elective subjects before proceeding.",
// //           icon: "warning",
// //           button: "Ok",
// //         });
// //         setIsChecked(false);
// //         return;
// //       }
// //     }
// //     setIsChecked(e.target.checked);
// //     if (e.target.checked) handleSaveSubjects(false);
// //   };

// //   const handleCheckboxChange = (subject, event) => {
// //     const isChecked = event.target.checked;
// //     setSelectedSubjects((prev) => {
// //       let updated = { ...prev };
// //       if (isChecked) {
// //         updated[subject.subjectType.toLowerCase()] = subject;
// //       } else {
// //         delete updated[subject.subjectType.toLowerCase()];
// //       }
// //       return updated;
// //     });
// //   };

// //   const handleCheckboxNepChange = (subject, event, category) => {
// //     const isChecked = event.target.checked;
// //     setSelectedNepSubjects((prev) => {
// //       let updated = { ...prev };
// //       if (isChecked) {
// //         updated[category] = subject;
// //       } else {
// //         delete updated[category];
// //       }
// //       return updated;
// //     });
// //   };

// //   // Modal Handlers
// //   const handleCloseRegular = () => setShowRegular(false);
// //   const handleCloseNep = () => setShowNep(false);
// //   const handleClosePrint = () => setShowPrint(false);

// //   const handleShowRegular = async (examForm) => {
// //     setSelectedExamForm(examForm);
// //     try {
// //       const response = await fetch(
// //         `https://sssutms.ac.in/apitest/v1/student/exam-form5?studentId=${userData._id}&examFormId=${examForm.examFormId._id}&courseName=${userData.courseName}&courseBranch=${userData.courseBranch}&semesterNumber=${examForm.examFormId.semester}`
// //       );
// //       const result = await response.json();
// //       if (response.ok) {
// //         const compulsory = result.subjects.filter((subject) => subject.compulsory);
// //         const nonCompulsory = result.subjects.filter((subject) => !subject.compulsory);
// //         setCompulsorySubjects(compulsory);
// //         setNonCompulsorySubjects(nonCompulsory);
// //         setFormData({ ...result, subjects: compulsory });
// //         setShowRegular(true);
// //       } else {
// //         console.log(result.message);
// //       }
// //     } catch (error) {
// //       console.log("Error fetching subjects:", error);
// //     }
// //   };

// //   const handleShowNep = async (examForm) => {
// //     setSelectedExamForm(examForm);
// //     try {
// //       const response = await fetch(
// //         `https://sssutms.ac.in/apitest/v1/student/exam-form5?studentId=${userData._id}&examFormId=${examForm.examFormId._id}&courseName=${userData.courseName}&courseBranch=${userData.courseBranch}&semesterNumber=${examForm.examFormId.semester}`,
// //         {
// //           method: "GET",
// //           headers: {
// //             "Cache-Control": "no-cache, no-store, must-revalidate",
// //             Pragma: "no-cache",
// //             Expires: "0",
// //           },
// //           cache: "no-store",
// //         }
// //       );
// //       const result = await response.json();
// //       if (response.ok) {
// //         setCompulsorySubjects(result.subjects.filter((subject) => subject.compulsory));
// //         setMajorSubjects(result.subjects.filter((subject) => subject.TypeOfcourse.toLowerCase() === "major"));
// //         setMinorSubjects(result.subjects.filter((subject) => subject.TypeOfcourse.toLowerCase() === "minor"));
// //         setGenericElectives(result.subjects.filter((subject) => subject.TypeOfcourse.includes("Generic Elective")));
// //         setFoundationElectives(result.subjects.filter((subject) => subject.TypeOfcourse.toLowerCase().includes("foundation")));
// //         setShowNep(true);
// //       } else {
// //         console.log(result.message);
// //       }
// //     } catch (error) {
// //       console.log("Error fetching subjects:", error);
// //     }
// //   };

// //   const handleShowPrint = async (examForm) => {
// //     setSelectedExamForm(examForm);
// //     try {
// //       const response = await fetch(
// //         `https://sssutms.ac.in/apitest/v1/student/exam-form2?examFormId=${examForm.examFormId._id}&id=${userData._id}`
// //       );
// //       const result = await response.json();
// //       setFormData2(result);
// //       console.log(result)
// //       setShowPrint(true);
// //     } catch (error) {
// //       console.error("Error fetching data:", error);
// //     }
// //   };

// //   // Table Sorting
// //   const getSortedAndSlicedData = () => {
// //     const sortedData = [...examForms].sort((a, b) => {
// //       if (sortBy === "name") {
// //         return sortOrder === "asc"
// //           ? a.branchName.localeCompare(b.branchName)
// //           : b.branchName.localeCompare(a.branchName);
// //       }
// //       return 0;
// //     });
// //     const startIndex = page * rowsPerPage;
// //     return sortedData.slice(startIndex, startIndex + rowsPerPage);
// //   };

// //   // Payment Handler
// //   const handlePayment = () => {
// //     setLoading(true);
// //     setTimeout(() => {
// //       fetch("https://sssutms.ac.in/apitest/exam-form/payment", {
// //         method: "POST",
// //         headers: {
// //           Accept: "application/json",
// //           "Content-Type": "application/json",
// //         },
// //         body: JSON.stringify({
// //           amount: 1830,
// //           id: userData.randomId,
// //           id2: userData._id,
// //           name: userData.name,
// //           course: userData.courseName,
// //           examId: selectedExamForm?.examFormId._id, // Use selected exam form ID
// //         }),
// //       })
// //         .then((response) => response.json())
// //         .then((response) => {
// //           const form = document.createElement("form");
// //           form.setAttribute("method", "post");
// //           form.setAttribute("action", "https://securegw.paytm.in/order/process");
// //           Object.keys(response).forEach((key) => {
// //             const input = document.createElement("input");
// //             input.setAttribute("type", "hidden");
// //             input.setAttribute("name", key);
// //             input.setAttribute("value", response[key]);
// //             form.appendChild(input);
// //           });
// //           document.body.appendChild(form);
// //           setLoading(false);
// //           form.submit();
// //           form.remove();
// //         })
// //         .catch((err) => console.log(err));
// //     }, 1000);
// //   };

// //   // PDF Generation
// //   const generatePDF = () => {
// //     const doc = new jsPDF();

// //     // Add University Logo
// //     doc.addImage(sssutms, "PNG", 10, 10, 20, 20);

// //     // University Header
// //     doc.setFont("helvetica", "bold");
// //     doc.setFontSize(11);
// //     doc.text(
// //       "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
// //       35,
// //       15
// //     );

// //     // Add Course Name
// //     doc.setFontSize(11);
// //     doc.setFont("helvetica");
// //     doc.text(`${userData?.courseName?.toUpperCase()}`, 105, 23, {
// //       align: "center",
// //     });

// //     // Add header below course name
// //     doc.setFontSize(10);
// //     doc.text(`${userData.courseBranch}- EXAM FORM: DEC-2024`, 105, 31, {
// //       align: "center",
// //     });

// //     const infoStartY = 40;

// //     // Helper function to handle long text and adjust line breaks
// //     const writeText = (text, x, y, maxWidth = 45) => {
// //       const lines = doc.splitTextToSize(text, maxWidth);
// //       lines.forEach((line, index) => {
// //         doc.text(line, x, y + index * 6);
// //       });
// //     };

// //     // Enrollment No.
// //     doc.setFont("helvetica", "bold");
// //     doc.text("Enrollment No:", 15, infoStartY);
// //     doc.setFont("helvetica", "normal");
// //     doc.text(`${userData.enrollmentNumber}`, 48.5, infoStartY);

// //     // Candidate Name
// //     doc.setFont("helvetica", "bold");
// //     doc.text("Candidate Name:", 15, infoStartY + 6);
// //     doc.setFont("helvetica", "normal");
// //     doc.setFontSize(9);
// //     writeText(
// //       `${userData.name}` || "Name Not Available",
// //       48.5,
// //       infoStartY + 6,
// //       80
// //     );
// //     doc.setFontSize(11);
// //     // Father's Name
// //     doc.setFont("helvetica", "bold");
// //     doc.text("Father's Name:", 108, infoStartY + 6);
// //     doc.setFont("helvetica", "normal");
// //     doc.setFontSize(9);
// //     writeText(
// //       `${userData.fathersname}` || "Father's Name Not Available",
// //       140,
// //       infoStartY + 6,
// //       50
// //     );
// //     doc.setFontSize(11);

// //     // Semester
// //     doc.setFont("helvetica", "bold");
// //     doc.text("Semester:", 15, infoStartY + 12);
// //     doc.setFont("helvetica", "normal");
// //     doc.text(`${userData.currentSemester}`, 49, infoStartY + 12);

// //     // Status
// //     doc.setFont("helvetica", "bold");
// //     doc.text("Status:", 108, infoStartY + 12);
// //     doc.setFont("helvetica", "bold");
// //     doc.text("Regular", 140, infoStartY + 12);

// //     // Subjects Table
// //     const subjectRows = formData2.subjects.map((subject, index) => [
// //       index + 1,
// //       subject.subjectCode,
// //       subject.subjectName,
// //       subject.subjectType,
// //     ]);

// //     doc.autoTable({
// //       startY: infoStartY + 19,
// //       head: [["Sr. No.", "Subject Code", "Subject Name", "Subject Type"]],
// //       body: subjectRows,
// //       styles: { fontSize: 7.5, cellPadding: 2 },
// //       theme: "grid",
// //     });

// //     // Candidate Declaration
// //     const declarationStartY = doc.lastAutoTable.finalY + 10;
// //     doc.setFont("helvetica", "bold");
// //     doc.text("CANDIDATE’S DECLARATION:", 14, declarationStartY);
// //     doc.setFont("helvetica", "normal");
// //     doc.setFontSize(9);
// //     const declaration = [
// //       "I certify that the information provided is correct, and I am responsible for its accuracy.",
// //       "I am a regular candidate for the examination conducted by Sri Satya Sai University of Technology and Medical Sciences, Sehore.",
// //       "I am not enrolled as a regular student at any other institution or appearing in any other examination.",
// //       "If the provided information is found false, my exam result will be canceled.",
// //       "I assure to complete the required attendance and sessional work for my course.",
// //       "I agree to abide by all rules and regulations set by the institution and the university.",
// //       "I have read and fulfilled all requirements outlined in the relevant ordinance and have no objection to appearing in the exam.",
// //       "I request the university not to disclose my results without my written consent, including under RTI.",
// //     ];

// //     const splitLines = declaration.map((line) =>
// //       doc.splitTextToSize(`• ${line}`, 180)
// //     );
// //     let currentY = declarationStartY + 6;
// //     splitLines.forEach((lines) => {
// //       lines.forEach((subLine) => {
// //         doc.text(subLine, 18, currentY);
// //         currentY += 6;
// //       });
// //     });

// //     doc.setFontSize(11);

// //     // Examination Fee Details
// //     const feeDetailsRows = formData2.Exam_fee.map((fee, index) => [
// //       index + 1,
// //       fee.txnId,
// //       new Date(fee.txnDate).toLocaleString(),
// //       `${fee.txnAmount}`,
// //     ]);

// //     const feeDetailsStartY = currentY + 3;

// //     doc.setFont("helvetica", "bold");
// //     doc.text("EXAMINATION FEE DETAILS :", 14, feeDetailsStartY);

// //     doc.autoTable({
// //       startY: feeDetailsStartY + 3,
// //       head: [["Sr. No.", "Transaction No.", "Paid On", "Exam Fee"]],
// //       body: feeDetailsRows,
// //       styles: { fontSize: 10, cellPadding: 2 },
// //       theme: "grid",
// //     });

// //     currentY = doc.lastAutoTable.finalY + 10;

// //     // Add Student Signature (if available)
// //     if (userData.applicantSignature) {
// //       const img = new Image();
// //       img.src = userData.applicantSignature;
// //       doc.addImage(img, "PNG", 150, currentY, 40, 20);
// //     }

// //     // Controller of Examination Signature placeholder
// //     doc.setFont("helvetica", "bold");
// //     doc.text("Student Signature", 160, currentY + 4);

// //     // Save PDF
// //     doc.save(`${userData.name} ExaminationForm.pdf`);
// //   };

// //   // Render
// //   return (
// // <>
// // <style>
// //   {
// //     `
// // /* Default table styling for larger screens */
// // *{
// // font-family:'Poppins',sans-serif;
// // }
// // table {
// //   width: 100%;
// //   border-collapse: collapse;
// // }

// // /* Media query for smaller screens (e.g., max-width: 600px for mobile) */
// // @media (max-width: 600px) {
// //   /* Table container adjustments */
// //   .MuiTableContainer-root {
// //     overflow-x: hidden !important;
// //     padding: 8px !important; /* Reduced padding */
// //   }

// //   /* Hide the table head */
// //   thead {
// //     display: none !important;
// //   }

// //   /* Convert table and tbody to block layout */
// //   table,
// //   tbody {
// //     display: block !important;
// //     width: 100%;
// //   }

// //   /* Style each row as a compact card */
// //   tr {
// //     display: block !important;
// //     background: #fff !important;
// //     border-radius: 8px;
// //     box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
// //     margin-bottom: 12px;
// //     padding: 10px;
// //     border: 1px solid rgba(0, 0, 0, 0.03);
// //   }

// //   /* Style each cell as a compact key-value pair row */
// //   td {
// //     display: flex !important;
// //     justify-content: space-between !important;
// //     align-items: center !important;
// //     width: 100% !important;
// //     padding: 6px 0 !important;
// //     position: static !important;
// //     background-color: transparent !important;
// //     font-size: 0.8rem !important; /* Smaller font */
// //     border-bottom: 1px solid rgba(0, 0, 0, 0.03) !important;
// //   }

// //   /* Add labels before each cell content */
// //   td:before {
// //     content: attr(data-label) ": ";
// //     font-weight: 600;
// //     color: #274c77;
// //     flex: 0 0 35%;
// //     white-space: nowrap;
// //     font-size: 0.8rem;
// //   }

// //   /* Style the value part */
// //   td > * {
// //     flex: 1;
// //     text-align: right;
// //     font-size: 0.8rem;
// //   }

// //   /* Remove border from the last cell */
// //   td:last-child {
// //     border-bottom: none !important;
// //   }

// //   /* Compact button styling */
// //   td .MuiButton-root {
// //     width: 80% !important; /* Reduced width */
// //     padding: "4px 8px" !important; /* Smaller padding */
// //     font-size: 0.75rem !important; /* Smaller font */
// //     minHeight: "auto" !important; /* Remove default min height */
// //     margin-top: 4px !important;
// //   }

// //   /* Compact the user info section */
// //   .MuiBox-root {
// //     padding: 8px !important; /* Reduced padding */
// //     margin-bottom: 12px !important; /* Reduced margin */
// //     gap: 8px !important; /* Reduced gap */
// //   }

// //   .MuiTypography-body2 {
// //     font-size: 0.8rem !important; /* Smaller font */
// //     line-height: 1.2 !important; /* Reduced line height */
// //   }
// // }
// //     `
// //   }
// // </style>
// //     <ThemeProvider theme={theme}>
// //       <StudentDashboard />
// //       <Box
// //   sx={{
// //     width: "90%",
// //     marginLeft: { xs: "0", sm: "80px" },
// //     marginTop: "50px",
// //     background: "linear-gradient(135deg, #f0f4f8 0%, #e1e8ed 100%)",
// //     padding: "20px",
// //     borderRadius: "15px",
// //     boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
// //     fontFamily: "'Poppins', sans-serif",
// //     color: "#333",
// //     boxSizing: "border-box",
// //   }}
// // >
// //         {loading ? (
// //           <CircularProgress
// //             color="success"
// //             style={{ marginTop: "80px", marginLeft: "50%" }}
// //           />
// //         ) : (

// // <CardContent sx={{ background: "rgba(255, 255, 255, 0.95)", borderRadius: "10px", padding: "15px" }}>
// //         <Typography
// //           variant="h6"
// //           sx={{
// //             textAlign: "center",
// //             fontSize: "1.6rem",
// //             color: "#0d47a1",
// //             fontWeight: 600,
// //             mb: 2,
// //             fontFamily: "'Poppins', sans-serif",
// //           }}
// //         >
// //           Examination Form
// //         </Typography>
// //         <Box
// //           sx={{
// //             display: "flex",
// //             justifyContent: "space-evenly",
// //             padding: "12px",
// //             background: "rgba(0, 0, 0, 0.05)",
// //             borderRadius: "8px",
// //             marginBottom: "20px",
// //             flexDirection: { xs: "column", sm: "row" },
// //             alignItems: { xs: "center", sm: "flex-start" },
// //             gap: { xs: "12px", sm: "0" },
// //             boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.05)",
// //           }}
// //         >
// //           <Typography variant="body2" sx={{ fontSize: "0.95rem", color: "#274c77", fontFamily: "'Poppins', sans-serif" }}>
// //             <strong style={{ fontWeight: 600 }}>Enrollment No:</strong> {userData?.enrollmentNumber}
// //           </Typography>
// //           <Typography variant="body2" sx={{ fontSize: "0.95rem", color: "#274c77", fontFamily: "'Poppins', sans-serif" }}>
// //             <strong style={{ fontWeight: 600 }}>Candidate Name:</strong> {userData?.name}
// //           </Typography>
// //           <Typography variant="body2" sx={{ fontSize: "0.95rem", color: "#274c77", fontFamily: "'Poppins', sans-serif" }}>
// //             <strong style={{ fontWeight: 600 }}>Father Name:</strong> {userData?.fathersname}
// //           </Typography>
// //         </Box>

// //         <TableContainer
// //           component={Paper}
// //           sx={{
// //             maxHeight: 450,
// //             overflowX: "auto",
// //             borderRadius: "10px",
// //             boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
// //           }}
// //         >
// //           <Table stickyHeader aria-label="sticky table">
// //             <TableHead>
// //               <TableRow>
// //                 {[
// //                   { label: "S.No.", minWidth: "auto", left: 0, zIndex: 2 },
// //                   { label: "Session", minWidth: "100px", left: 50, zIndex: 2 },
// //                   { label: "Session Name", minWidth: "auto" },
// //                   { label: "Semester/Year", minWidth: "70px" },
// //                   { label: "Category", minWidth: "100px" },
// //                   { label: "Form Status", minWidth: "200px" },
// //                   { label: "ExamForm Payment", minWidth: "200px" },
// //                   { label: "Click to Show", minWidth: "150px" },
// //                 ].map((col, idx) => (
// //                   <TableCell
// //                     key={idx}
// //                     align="left"
// //                     sx={{
// //                       backgroundColor: "#0d47a1",
// //                       color: "#fff",
// //                       position: "sticky",
// //                       top: 0,
// //                       left: col.left || "auto",
// //                       zIndex: col.zIndex || 1,
// //                       fontWeight: 600,
// //                       padding: "12px",
// //                       minWidth: col.minWidth,
// //                       fontFamily: "'Poppins', sans-serif",
// //                     }}
// //                   >
// //                     {col.label}
// //                   </TableCell>
// //                 ))}
// //               </TableRow>
// //             </TableHead>
// //             <TableBody>
// //               {getSortedAndSlicedData().map((form, index) => (
// //                 <TableRow key={index} sx={{ "&:hover": { backgroundColor: "rgba(0, 121, 107, 0.1)" } }}>
// //                   <TableCell
// //                     data-label="S.No."
// //                     align="left"
// //                     sx={{
// //                       position: "sticky",
// //                       top: 0,
// //                       left: 0,
// //                       zIndex: 1,
// //                       backgroundColor: "white",
// //                       padding: "10px",
// //                       fontSize: "0.9rem",
// //                       fontFamily: "'Poppins', sans-serif",
// //                     }}
// //                   >
// //                     {index + 1}
// //                   </TableCell>
// //                   <TableCell
// //                     data-label="Session"
// //                     align="left"
// //                     sx={{
// //                       position: "sticky",
// //                       left: 50,
// //                       top: 0,
// //                       fontWeight: 700,
// //                       zIndex: 1,
// //                       backgroundColor: "white",
// //                       color: "red",
// //                       padding: "10px",
// //                       fontSize: "0.9rem",
// //                       fontFamily: "'Poppins', sans-serif",
// //                     }}
// //                   >
// //                     {form.examFormId.session || "N/A"}
// //                   </TableCell>
// //                   <TableCell
// //                     data-label="Session Name"
// //                     sx={{ padding: "10px", fontSize: "0.9rem", fontFamily: "'Poppins', sans-serif" }}
// //                   >
// //                     {form.examFormId.sessionName || "N/A"}
// //                   </TableCell>
// //                   <TableCell
// //                     data-label="Semester/Year"
// //                     sx={{ padding: "10px", fontSize: "0.9rem", fontFamily: "'Poppins', sans-serif" }}
// //                   >
// //                     {`Sem${form.examFormId.semester}` || "N/A"}
// //                   </TableCell>
// //                   <TableCell
// //                     data-label="Category"
// //                     sx={{
// //                       fontWeight: "bold",
// //                       position: "sticky",
// //                       left: 210,
// //                       backgroundColor: "white",
// //                       padding: "10px",
// //                       fontSize: "0.9rem",
// //                       fontFamily: "'Poppins', sans-serif",
// //                     }}
// //                   >
// //                     {form.examFormId.category || "N/A"}
// //                   </TableCell>
// //                   <TableCell
// //                     data-label="Form Status"
// //                     sx={{
// //                       padding: "10px",
// //                       fontSize: "0.9rem",
// //                       color: form.isForwarded ? "#2ecc71" : "#e74c3c",
// //                       fontFamily: "'Poppins', sans-serif",
// //                     }}
// //                   >
// //                     {form.isForwarded ? "Forwarded" : "Form Not Forwarded"}
// //                   </TableCell>
// //                   <TableCell
// //                     data-label="ExamForm Payment"
// //                     sx={{
// //                       padding: "10px",
// //                       fontSize: "0.9rem",
// //                       color: form.isPaid ? "#2ecc71" : "#e74c3c",
// //                       fontFamily: "'Poppins', sans-serif",
// //                     }}
// //                   >
// //                     {form.isPaid ? "Paid" : "Not Paid"}
// //                   </TableCell>
// //                   <TableCell data-label="Click to Show" sx={{ padding: "10px" }}>
// //                     <Button
// //                       variant="outlined"
// //                       sx={{
// //                         padding: "6px 12px",
// //                         border: "2px solid #0d47a1",
// //                         color: "#0d47a1",
// //                         borderRadius: "10px",
// //                         fontSize: "0.85rem",
// //                         background: "none",
// //                         fontFamily: "'Poppins', sans-serif",
// //                         transition: "all 0.3s ease",
// //                         "&:hover": {
// //                           background: "#0d47a1",
// //                           color: "#fff",
// //                           boxShadow: "0 0 15px rgba(0, 121, 107, 0.5)",
// //                           border: "2px solid #0d47a1",
// //                         },
// //                       }}
// //                       onClick={() => {
// //                         if (!form.isForwarded) {
// //                           swal({
// //                             icon: "warning",
// //                             title: "Form Not Forwarded",
// //                             text: "Your form has not been forwarded. Please contact the Department.",
// //                             buttons: "OK",
// //                           });
// //                         } else if (form.isFilled && form.isPaid) {
// //                           handleShowPrint(form);
// //                         } else if (["BACHELOR OF COMMERCE", "BACHELOR OF SCIENCE", "BACHELOR OF ARTS"].includes(userData.courseName)) {
// //                           handleShowNep(form);
// //                         } else {
// //                           handleShowRegular(form);
// //                         }
// //                       }}
// //                     >
// //                       {form.isFilled && form.isPaid ? "Print Exam Form" : "Show Form"}
// //                     </Button>
// //                   </TableCell>
// //                 </TableRow>
// //               ))}
// //             </TableBody>
// //           </Table>
// //         </TableContainer>
// //       </CardContent>
// //         )}

// //         {/* Regular Form Modal */}
// //         <Modal show={showRegular} onHide={handleCloseRegular} size="lg" centered style={{ marginTop: "40px" }}>
// //           <Modal.Header closeButton style={{ color: "rgb(2, 48, 71)" }}>
// //             <Modal.Title variant="button" style={{ height: "8px", marginTop: "2px", fontFamily: "'Poppins',Sans-serif" }}>
// //               Examination Form
// //             </Modal.Title>
// //           </Modal.Header>
// //           <Modal.Body>
// //             <div id="exam-form" style={{ padding: "20px" }}>
// //               <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
// //                 <img src={sssutms} alt="Logo" style={{ width: "100px", marginRight: "20px" }} />
// //                 <div>
// //                   <Typography style={{ margin: 0, fontSize: "15px", fontWeight: "bold", fontFamily: "'Poppins',Sans-serif" }}>
// //                     SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE
// //                   </Typography>
// //                   <Typography variant="body1" style={{ margin: 0, marginLeft: "90px", fontFamily: "'Poppins',Sans-serif" }}>
// //                     ( {userData.courseBranch})
// //                   </Typography>
// //                   <Typography variant="body1" style={{ margin: 0, marginLeft: "160px", fontFamily: "'Poppins',Sans-serif" }}>
// //                     Session: {selectedExamForm?.examFormId?.sessionName}
// //                   </Typography>
// //                 </div>
// //               </div>

// //               <Form>
// //                 <Row className="mb-2">
// //                   <Form.Group style={{fontFamily: "'Poppins',Sans-serif"}} as={Col}><Form.Label>Enrollment No: </Form.Label><span> {userData?.enrollmentNumber}</span></Form.Group>
// //                   <Form.Group style={{fontFamily: "'Poppins',Sans-serif"}} as={Col}><Form.Label>Semester/Year: </Form.Label><span> {selectedExamForm?.examFormId?.semester}</span></Form.Group>
// //                 </Row>
// //                 <Row className="mb-2">
// //                   <Form.Group style={{fontFamily: "'Poppins',Sans-serif"}} as={Col}><Form.Label>Candidate's Name: </Form.Label><span> {userData?.name}</span></Form.Group>
// //                   <Form.Group style={{fontFamily: "'Poppins',Sans-serif"}} as={Col}><Form.Label>Father's Name:</Form.Label><span> {userData?.fathersname}</span></Form.Group>
// //                 </Row>
// //                 <Row className="mb-3">
// //                   <Form.Group style={{fontFamily: "'Poppins',Sans-serif"}} as={Col}><Form.Label>Mother's Name: </Form.Label><span> {userData?.mothersname}</span></Form.Group>
// //                   <Form.Group style={{fontFamily: "'Poppins',Sans-serif"}} as={Col}><Form.Label>Status: </Form.Label><span> {selectedExamForm?.examFormId?.category}</span></Form.Group>
// //                 </Row>

// //                 <Table striped bordered hover>
// //                   <TableHead style={{ backgroundColor: "rgba(224, 224, 224, 1)" }}>
// //                     <TableRow sx={{ height: "30px" }}>
// //                       <TableCell sx={{ padding: "4px 8px", fontSize: "0.9rem", fontFamily: "'Poppins',Sans-serif" }}>S.No</TableCell>
// //                       <TableCell sx={{ padding: "4px 8px", fontSize: "0.9rem", fontFamily: "'Poppins',Sans-serif" }}>Subject Code</TableCell>
// //                       <TableCell sx={{ padding: "4px 8px", fontSize: "0.9rem" , fontFamily: "'Poppins',Sans-serif"}}>Subject Name</TableCell>
// //                       <TableCell sx={{ padding: "4px 8px", fontSize: "0.9rem", fontFamily: "'Poppins',Sans-serif" }}>Subject Type</TableCell>
// //                       <TableCell sx={{ padding: "4px 8px", fontSize: "0.9rem", fontFamily: "'Poppins',Sans-serif" }}>Elective</TableCell>
// //                     </TableRow>
// //                   </TableHead>
// //                   <TableBody>
// //                     {compulsorySubjects.concat(nonCompulsorySubjects).sort((a, b) => a.subjectType === "Theory" ? -1 : 1).map((subject, index) => {
// //                       const isSelected =
// //                         subject.compulsory ||
// //                         selectedSubjects[subject.subjectType.toLowerCase()]?.subjectCode === subject.subjectCode;
// //                       return (
// //                         <TableRow key={`subject-${index}`} sx={{ height: "10px" }}>
// //                           <TableCell sx={{ padding: "0px 10px", fontSize: "0.85rem", fontFamily: "'Poppins',Sans-serif" }}>{index + 1}</TableCell>
// //                           <TableCell sx={{ padding: "0px 10px", fontSize: "0.85rem", fontFamily: "'Poppins',Sans-serif" }}>{subject.subjectCode}</TableCell>
// //                           <TableCell sx={{ padding: "0px 10px", fontSize: "0.85rem", fontFamily: "'Poppins',Sans-serif" }}>{subject.subjectName}</TableCell>
// //                           <TableCell sx={{ padding: "0px 10px", fontSize: "0.85rem" , fontFamily: "'Poppins',Sans-serif"}}>{subject.subjectType}</TableCell>
// //                           <TableCell sx={{ padding: "0px 10px", fontSize: "0.85rem", fontFamily: "'Poppins',Sans-serif" }}>
// //                             <Checkbox
// //                               sx={{ transform: "scale(0.7)" }}
// //                               checked={isSelected}
// //                               disabled={subject.compulsory}
// //                               color="primary"
// //                               onChange={(e) => handleCheckboxChange(subject, e)}
// //                             />
// //                           </TableCell>
// //                         </TableRow>
// //                       );
// //                     })}
// //                   </TableBody>
// //                 </Table>

// //                 <div style={{ marginTop: "20px" }}>
// //                   <Typography variant="h6">CANDIDATE'S DESCRIPTION</Typography>
// //                   <hr style={{ width: "40%", height: "2px", backgroundColor: "gray", margin: "-2px 0" }} />
// //                   <Typography variant="body2" gutterBottom>
// //                     <ul style={{ marginLeft: "20px", marginTop: "10px" , fontFamily: "'Poppins',Sans-serif"}}>
// //                       <li>I certify that this application has been filled by me and the information given therein is correct and I shall be provisionally responsible for the same proved false later on.</li>
// //                       <li>I also certify that I am appearing as regular candidate only in the examination being conducted by the Sri Satya Sai University of Technology and Medical Sciences, Sehore.</li>
// //                       <li>I further declare that I am neither a regular student of any other Education Institution nor appearing in any other examination as regular candidate.</li>
// //                       <li>I understand that if it is found later on that the information furnished above is false then my result of examination will be cancelled.</li>
// //                       <li>I assure that I will complete the required attendance and sessional work prescribed for the course of my registration. Kindly permit me to appear in this examination.</li>
// //                       <li>I accept to abide by all the rules and regulations of study and examination as prescribed by the institution & Sri Satya Sai University of Technology and Medical Sciences, Sehore.</li>
// //                       <li>I have read relevant ordinance applicable to me and have completed all the requirements as given in it. I have completed my studies and have no objection in appearing in examination on the date declared by the university.</li>
// //                       <li><b>I also request the University that without my written consent, do not disclose my result to anybody in any manner including RTI.</b></li>
// //                     </ul>
// //                     <Checkbox checked={isChecked} onChange={handleCheckbox} /> I accept all above points.
// //                   </Typography>
// //                   {isChecked && (
// //                     <div style={{ padding: "15px 20px", marginTop: "-4%", backgroundColor: "#f9f9f9", border: "1px solid #ddd", borderRadius: "8px" }}>
// //                       <Typography variant="h6" gutterBottom>Total Fees to Pay ₹ 1830 Only</Typography>
// //                       {isLoading ? (
// //                         <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
// //                           <CircularProgress size={24} />
// //                           <Typography>Loading...</Typography>
// //                         </div>
// //                       ) : (
// //                         isApiSuccess && (
// //                           <Button style={{ backgroundColor: "rgb(2,48,71)", color: "white" }} onClick={handlePayment}>
// //                             PAY NOW
// //                           </Button>
// //                         )
// //                       )}
// //                     </div>
// //                   )}
// //                 </div>
// //               </Form>
// //             </div>
// //           </Modal.Body>
// //         </Modal>

// //         {/* NEP Form Modal */}
// //         <Modal show={showNep} onHide={handleCloseNep} size="lg" centered style={{ marginTop: "40px" }}>
// //           <Modal.Header closeButton style={{ color: "rgb(2, 48, 71)" }}>
// //             <Modal.Title variant="button" style={{ height: "8px", marginTop: "2px", fontFamily: "'Poppins',Sans-serif" }}>
// //               Examination Form NEP
// //             </Modal.Title>
// //           </Modal.Header>
// //           <Modal.Body>
// //             <div id="exam-form" style={{ padding: "20px" }}>
// //               <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
// //                 <img src={sssutms} alt="Logo" style={{ width: "100px", marginRight: "20px" }} />
// //                 <div>
// //                   <Typography style={{ margin: 0, fontSize: "15px", fontWeight: "bold",fontFamily: "'Poppins',Sans-serif" }}>
// //                     SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE
// //                   </Typography>
// //                   <Typography variant="body1" style={{ margin: 0, marginLeft: "90px",fontFamily: "'Poppins',Sans-serif" }}>
// //                     ( {userData.courseBranch})
// //                   </Typography>
// //                   <Typography variant="body1" style={{ margin: 0, marginLeft: "160px",fontFamily: "'Poppins',Sans-serif" }}>
// //                     Session: {selectedExamForm?.examFormId?.sessionName}
// //                   </Typography>
// //                 </div>
// //               </div>

// //               <Form>
// //                 <Row className="mb-2">
// //                   <Form.Group style={{fontFamily: "'Poppins',Sans-serif"}} as={Col}><Form.Label>Enrollment No: </Form.Label><span> {userData?.enrollmentNumber}</span></Form.Group>
// //                   <Form.Group style={{fontFamily: "'Poppins',Sans-serif"}} as={Col}><Form.Label>Semester/Year: </Form.Label><span> {selectedExamForm?.examFormId?.semester}</span></Form.Group>
// //                 </Row>
// //                 <Row className="mb-2">
// //                   <Form.Group style={{fontFamily: "'Poppins',Sans-serif"}} as={Col}><Form.Label>Candidate's Name: </Form.Label><span> {userData?.name}</span></Form.Group>
// //                   <Form.Group style={{fontFamily: "'Poppins',Sans-serif"}} as={Col}><Form.Label>Father's Name:</Form.Label><span> {userData?.fathersname}</span></Form.Group>
// //                 </Row>
// //                 <Row className="mb-3">
// //                   <Form.Group style={{fontFamily: "'Poppins',Sans-serif"}} as={Col}><Form.Label>Mother's Name: </Form.Label><span> {userData?.mothersname}</span></Form.Group>
// //                   <Form.Group style={{fontFamily: "'Poppins',Sans-serif"}} as={Col}><Form.Label>Status: </Form.Label><span> {selectedExamForm?.examFormId?.category}</span></Form.Group>
// //                 </Row>

// //                 <Table striped bordered hover>
// //                   <TableHead style={{ backgroundColor: "rgba(224, 224, 224, 1)" }}>
// //                     <TableRow sx={{ height: "30px" }}>
// //                       <TableCell sx={{ padding: "4px 8px", fontSize: "0.9rem", fontFamily: "'Poppins',Sans-serif" }}>S.No</TableCell>
// //                       <TableCell sx={{ padding: "4px 8px", fontSize: "0.9rem", fontFamily: "'Poppins',Sans-serif" }}>Subject Code</TableCell>
// //                       <TableCell sx={{ padding: "4px 8px", fontSize: "0.9rem", fontFamily: "'Poppins',Sans-serif" }}>Subject Name</TableCell>
// //                       <TableCell sx={{ padding: "4px 8px", fontSize: "0.9rem" , fontFamily: "'Poppins',Sans-serif"}}>Subject Type</TableCell>
// //                       <TableCell sx={{ padding: "4px 8px", fontSize: "0.9rem", fontFamily: "'Poppins',Sans-serif" }}>Elective</TableCell>
// //                     </TableRow>
// //                   </TableHead>
// //                   <TableBody>
// //                     {[
// //                       { category: "Major", data: majorSubjects },
// //                       { category: "Minor", data: minorSubjects },
// //                       { category: "Generic Elective", data: genericElectives },
// //                       { category: "Foundation", data: foundationElectives },
// //                     ].map((group, groupIndex) => (
// //                       <React.Fragment key={groupIndex}>
// //                         <TableRow>
// //                           <TableCell colSpan={5} sx={{ backgroundColor: "#f0f0f0", fontWeight: "bold", textAlign: "center", padding: "5px 10px", fontSize: "0.9rem" }}>
// //                             {group.category} Subjects
// //                           </TableCell>
// //                         </TableRow>
// //                         {group.data.map((subject, index) => {
// //                           const isSelected =
// //                             selectedNepSubjects[group.category]?.subjectCode === subject.subjectCode || subject.compulsory;
// //                           return (
// //                             <TableRow key={`subject-${groupIndex}-${index}`} sx={{ height: "10px" }}>
// //                               <TableCell sx={{ padding: "0px 10px", fontSize: "0.85rem" , fontFamily: "'Poppins',Sans-serif"}}>{index + 1}</TableCell>
// //                               <TableCell sx={{ padding: "0px 10px", fontSize: "0.85rem", fontFamily: "'Poppins',Sans-serif" }}>{subject.subjectCode}</TableCell>
// //                               <TableCell sx={{ padding: "0px 10px", fontSize: "0.85rem", fontFamily: "'Poppins',Sans-serif" }}>{subject.subjectName}</TableCell>
// //                               <TableCell sx={{ padding: "0px 10px", fontSize: "0.85rem" , fontFamily: "'Poppins',Sans-serif"}}>{subject.subjectType}</TableCell>
// //                               <TableCell sx={{ padding: "0px 10px", fontSize: "0.85rem", fontFamily: "'Poppins',Sans-serif" }}>
// //                                 <Checkbox
// //                                   sx={{ transform: "scale(0.7)" }}
// //                                   checked={isSelected}
// //                                   disabled={subject.compulsory}
// //                                   color="primary"
// //                                   onChange={(e) => handleCheckboxNepChange(subject, e, group.category)}
// //                                 />
// //                               </TableCell>
// //                             </TableRow>
// //                           );
// //                         })}
// //                       </React.Fragment>
// //                     ))}
// //                   </TableBody>
// //                 </Table>

// //                 <div style={{ marginTop: "20px",fontFamily: "'Poppins',Sans-serif" }}>
// //                   <Typography variant="h6">CANDIDATE'S DESCRIPTION</Typography>
// //                   <hr style={{ width: "40%", height: "2px", backgroundColor: "gray", margin: "-2px 0" }} />
// //                   <Typography variant="body2" gutterBottom>
// //                     <ul style={{ marginLeft: "20px", marginTop: "10px",fontFamily: "'Poppins',Sans-serif" }}>
// //                       <li>I certify that this application has been filled by me and the information given therein is correct and I shall be provisionally responsible for the same proved false later on.</li>
// //                       <li>I also certify that I am appearing as regular candidate only in the examination being conducted by the Sri Satya Sai University of Technology and Medical Sciences, Sehore.</li>
// //                       <li>I further declare that I am neither a regular student of any other Education Institution nor appearing in any other examination as regular candidate.</li>
// //                       <li>I understand that if it is found later on that the information furnished above is false then my result of examination will be cancelled.</li>
// //                       <li>I assure that I will complete the required attendance and sessional work prescribed for the course of my registration. Kindly permit me to appear in this examination.</li>
// //                       <li>I accept to abide by all the rules and regulations of study and examination as prescribed by the institution & Sri Satya Sai University of Technology and Medical Sciences, Sehore.</li>
// //                       <li>I have read relevant ordinance applicable to me and have completed all the requirements as given in it. I have completed my studies and have no objection in appearing in examination on the date declared by the university.</li>
// //                       <li><b>I also request the University that without my written consent, do not disclose my result to anybody in any manner including RTI.</b></li>
// //                     </ul>
// //                     <Checkbox checked={isChecked} onChange={handleCheckbox} /> I accept all above points.
// //                   </Typography>
// //                   {isChecked && (
// //                     <div style={{ padding: "15px 20px", marginTop: "-4%", backgroundColor: "#f9f9f9", border: "1px solid #ddd", borderRadius: "8px" }}>
// //                       <Typography variant="h6" gutterBottom>Total Fees to Pay ₹ 1830 Only</Typography>
// //                       {isLoading ? (
// //                         <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
// //                           <CircularProgress size={24} />
// //                           <Typography>Loading...</Typography>
// //                         </div>
// //                       ) : (
// //                         isApiSuccess && (
// //                           <Button style={{ backgroundColor: "rgb(2,48,71)", color: "white" }} onClick={handlePayment}>
// //                             PAY NOW
// //                           </Button>
// //                         )
// //                       )}
// //                     </div>
// //                   )}
// //                 </div>
// //               </Form>
// //             </div>
// //           </Modal.Body>
// //         </Modal>

// //         {/* Print Form Modal */}
// //         <Modal show={showPrint} onHide={handleClosePrint} size="lg" centered style={{ marginTop: "40px" }}>
// //           <Modal.Header closeButton style={{ color: "rgb(2, 48, 71)" }}>
// //             <Modal.Title variant="button" style={{ height: "8px", marginTop: "2px", fontFamily: "'Poppins',Sans-serif" }}>
// //               Examination Form
// //             </Modal.Title>
// //           </Modal.Header>
// //           <Modal.Body>
// //             <div id="exam-form" style={{ padding: "20px" }}>
// //               <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
// //                 <img src={sssutms} alt="Logo" style={{ width: "100px", marginRight: "20px" }} />
// //                 <div>
// //                   <Typography style={{ margin: 0, fontSize: "15px", fontWeight: "bold" ,fontFamily: "'Poppins',Sans-serif"}}>
// //                     SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE
// //                   </Typography>
// //                   <Typography variant="body1" style={{ margin: 0, textAlign: "center" ,fontFamily: "'Poppins',Sans-serif"}}>
// //                     ( {userData.courseBranch})
// //                   </Typography>
// //                   <Typography variant="body1" style={{ margin: 0, textAlign: "center" ,fontFamily: "'Poppins',Sans-serif"}}>
// //                     Session: {selectedExamForm?.examFormId?.sessionName}
// //                   </Typography>
// //                 </div>
// //               </div>

// //               <Form>
// //                 <Row className="mb-2">
// //                   <Form.Group  style={{fontFamily: "'Poppins',Sans-serif"}} as={Col}><Form.Label>Enrollment No: </Form.Label><span> {userData?.enrollmentNumber}</span></Form.Group>
// //                   <Form.Group style={{fontFamily: "'Poppins',Sans-serif"}} as={Col}><Form.Label>Semester/Year: </Form.Label><span> {selectedExamForm?.examFormId?.semester}</span></Form.Group>
// //                 </Row>
// //                 <Row className="mb-2">
// //                   <Form.Group style={{fontFamily: "'Poppins',Sans-serif"}} as={Col}><Form.Label>Candidate's Name: </Form.Label><span> {userData?.name}</span></Form.Group>
// //                   <Form.Group style={{fontFamily: "'Poppins',Sans-serif"}} as={Col}><Form.Label>Father's Name:</Form.Label><span> {userData?.fathersname}</span></Form.Group>
// //                 </Row>
// //                 <Row className="mb-3">
// //                   <Form.Group style={{fontFamily: "'Poppins',Sans-serif"}} as={Col}><Form.Label>Mother's Name: </Form.Label><span> {userData?.mothersname}</span></Form.Group>
// //                   <Form.Group style={{fontFamily: "'Poppins',Sans-serif"}} as={Col}><Form.Label>Status: </Form.Label><span> {selectedExamForm?.examFormId?.category}</span></Form.Group>
// //                 </Row>

// //                 <Table striped bordered hover>
// //                   <TableHead style={{ backgroundColor: "rgba(224, 224, 224, 1)" }}>
// //                     <TableRow sx={{ height: "30px" }}>
// //                       <TableCell sx={{ padding: "4px 8px", fontSize: "0.9rem" , fontFamily: "'Poppins',Sans-serif"}}>Sr.No</TableCell>
// //                       <TableCell sx={{ padding: "4px 8px", fontSize: "0.9rem", fontFamily: "'Poppins',Sans-serif" }}>Subject Code</TableCell>
// //                       <TableCell sx={{ padding: "4px 8px", fontSize: "0.9rem", fontFamily: "'Poppins',Sans-serif" }}>Subject Name</TableCell>
// //                       <TableCell sx={{ padding: "4px 8px", fontSize: "0.9rem" , fontFamily: "'Poppins',Sans-serif"}}>Subject Type</TableCell>
// //                     </TableRow>
// //                   </TableHead>
// //                   <TableBody>
// //                     {formData2?.subjects?.map((subject, index) => (
// //                       <TableRow key={index} sx={{ height: "30px" }}>
// //                         <TableCell sx={{ padding: "4px 8px", fontSize: "0.85rem", fontFamily: "'Poppins',Sans-serif"}}>{index + 1}</TableCell>
// //                         <TableCell sx={{ padding: "4px 8px", fontSize: "0.85rem" , fontFamily: "'Poppins',Sans-serif"}}>{subject.subjectCode}</TableCell>
// //                         <TableCell sx={{ padding: "4px 8px", fontSize: "0.85rem", fontFamily: "'Poppins',Sans-serif" }}>{subject.subjectName}</TableCell>
// //                         <TableCell sx={{ padding: "4px 8px", fontSize: "0.85rem", fontFamily: "'Poppins',Sans-serif" }}>{subject.subjectType}</TableCell>
// //                       </TableRow>
// //                     ))}
// //                   </TableBody>
// //                 </Table>

// //                 <div style={{ marginTop: "20px" }}>
// //                   <Typography variant="h6">CANDIDATE'S DESCRIPTION</Typography>
// //                   <hr style={{ width: "40%", height: "2px", backgroundColor: "gray", margin: "-2px 0" }} />
// //                   <Typography variant="body2" gutterBottom>
// //                     <ul style={{ marginLeft: "20px", marginTop: "10px" ,fontFamily: "'Poppins',sans-serif"}}>
// //                       <li>I certify that this application has been filled by me and the information given therein is correct and I shall be provisionally responsible for the same proved false later on.</li>
// //                       <li>I also certify that I am appearing as regular candidate only in the examination being conducted by the Sri Satya Sai University of Technology and Medical Sciences, Sehore.</li>
// //                       <li>I further declare that I am neither a regular student of any other Education Institution nor appearing in any other examination as regular candidate.</li>
// //                       <li>I understand that if it is found later on that the information furnished above is false then my result of examination will be cancelled.</li>
// //                       <li>I assure that I will complete the required attendance and sessional work prescribed for the course of my registration. Kindly permit me to appear in this examination.</li>
// //                       <li>I accept to abide by all the rules and regulations of study and examination as prescribed by the institution & Sri Satya Sai University of Technology and Medical Sciences, Sehore.</li>
// //                       <li>I have read relevant ordinance applicable to me and have completed all the requirements as given in it. I have completed my studies and have no objection in appearing in examination on the date declared by the university.</li>
// //                       <li><b>I also request the University that without my written consent, do not disclose my result to anybody in any manner including RTI.</b></li>
// //                     </ul>
// //                   </Typography>
// //                 </div>
// //               </Form>
// //             </div>
// //           </Modal.Body>
// //           <div style={{ padding: "15px 20px", marginTop: "-4%", backgroundColor: "#f9f9f9", border: "1px solid #ddd", borderRadius: "8px" }}>
// //             <Typography variant="subtitle2" style={{ fontWeight: "bold",fontFamily: "'Poppins',Sans-serif" }}>
// //               EXAMINATION FEE DETAILS
// //             </Typography>
// //             <hr style={{ width: "30%", height: "2px", backgroundColor: "#333", margin: "-2px 0 10px 0" }} />
// //             {formData2?.Exam_fee?.map((fee, index) => (
// //               <Typography key={index} variant="subtitle2" gutterBottom style={{fontFamily: "'Poppins',Sans-serif"}}>
// //                 <strong>Transaction No:</strong> {fee.txnId}, <strong>Paid On:</strong> {new Date(fee.txnDate).toLocaleString()}, <strong>Exam Fee:</strong> ₹{fee.txnAmount}
// //               </Typography>
// //             ))}
// //           </div>
// //           <Modal.Footer>
// //             <Button variant="text" onClick={handleClosePrint}>Close</Button>
// //             <Button variant="outlined" onClick={generatePDF}>Download as PDF</Button>
// //           </Modal.Footer>
// //         </Modal>
// //       </Box>
// //     </ThemeProvider>
// //     </>
// //   );
// // }

// // export default Std_ExamForm;

import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import {
  Box,
  CardContent,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Checkbox,
} from "@mui/material";
import { Form, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import sssutms from "../../../Main_Admin/images/sssutms.jpg";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import StudentDashboard from "../../../AdminComponent/StudentDashboard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import OnlyHeader from "../../../AdminComponent/OnlyHeader";

// Theme Configuration
const theme = createTheme({
  typography: {
    fontWeightBold: 700,
  },
});

// Main Component
function Std_ExamForm() {
  // State Management
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(8);
  const [examForms, setExamForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isApiSuccess, setIsApiSuccess] = useState(false);

  // Subject States
  const [majorSubjects, setMajorSubjects] = useState([]);
  const [minorSubjects, setMinorSubjects] = useState([]);
  const [genericElectives, setGenericElectives] = useState([]);
  const [foundationElectives, setFoundationElectives] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState({
    theory: null,
    practical: null,
  });
  const [compulsorySubjects, setCompulsorySubjects] = useState([]);
  const [nonCompulsorySubjects, setNonCompulsorySubjects] = useState([]);
  const [formData, setFormData] = useState(null);
  const [formData2, setFormData2] = useState(null);
  const [selectedNepSubjects, setSelectedNepSubjects] = useState([]);

  // Modal States
  const [showRegular, setShowRegular] = useState(false);
  const [showNep, setShowNep] = useState(false);
  const [showPrint, setShowPrint] = useState(false);
  const [selectedExamForm, setSelectedExamForm] = useState(null);

  const userData = JSON.parse(sessionStorage.getItem("currentUser"));

  // Fetch Exam Forms
  useEffect(() => {
    const fetchExamForms = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://sssutms.ac.in/apitest/v1/getExamForms?studentId=${userData._id}`
        );
        const result = await response.json();
        if (response.ok) {
          setExamForms(result.data);
        } else {
          console.error(result.message);
        }
      } catch (error) {
        console.error("Error fetching exam forms:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchExamForms();
  }, [userData._id]);

  // Save Subjects Handler
  const handleSaveSubjects = async (isNep = false) => {
    setIsLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));

      let subjectsToSave = [];
      if (isNep) {
        subjectsToSave = [
          ...compulsorySubjects,
          ...Object.values(selectedNepSubjects).flat(),
        ].filter(
          (subject, index, self) =>
            index ===
            self.findIndex((s) => s.subjectCode === subject.subjectCode)
        );
      } else {
        const { theory, practical } = selectedSubjects;
        const selectedNonCompulsory = nonCompulsorySubjects
          .filter(
            (subject) =>
              (subject.subjectType === "Theory" &&
                theory?.subjectCode === subject.subjectCode) ||
              (subject.subjectType === "Practical" &&
                practical?.subjectCode === subject.subjectCode)
          )
          .map((subject) => ({
            subjectCode: subject.subjectCode,
            subjectName: subject.subjectName,
            subjectType: subject.subjectType,
          }));
        subjectsToSave = [...compulsorySubjects, ...selectedNonCompulsory];
      }

      const response = await fetch(
        "https://sssutms.ac.in/apitest/student/forward-form",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            studentId: userData._id,
            examFormId: selectedExamForm?.examFormId._id, // Use selected exam form ID
            subjects: subjectsToSave,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to save subjects");
      const responseData = await response.json();
      console.log("API response:", responseData);
      setIsApiSuccess(true);
    } catch (error) {
      console.error("Error saving subjects:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Checkbox Handlers
  const handleCheckbox = (e) => {
    if (e.target.checked) {
      const nonCompulsoryExist = nonCompulsorySubjects.length > 0;
      const anySelected = nonCompulsorySubjects.some(
        (subject) =>
          (subject.subjectType === "Theory" &&
            selectedSubjects.theory?.subjectCode === subject.subjectCode) ||
          (subject.subjectType === "Practical" &&
            selectedSubjects.practical?.subjectCode === subject.subjectCode)
      );
      if (nonCompulsoryExist && !anySelected) {
        swal({
          title: "Warning!",
          text: "Please select elective subjects before proceeding.",
          icon: "warning",
          button: "Ok",
        });
        setIsChecked(false);
        return;
      }
    }
    setIsChecked(e.target.checked);
    if (e.target.checked) handleSaveSubjects(false);
  };

  const handleCheckboxChange = (subject, event) => {
    const isChecked = event.target.checked;
    setSelectedSubjects((prev) => {
      let updated = { ...prev };
      if (isChecked) {
        updated[subject.subjectType.toLowerCase()] = subject;
      } else {
        delete updated[subject.subjectType.toLowerCase()];
      }
      return updated;
    });
  };

  const handleCheckboxNepChange = (subject, event, category) => {
    const isChecked = event.target.checked;
    setSelectedNepSubjects((prev) => {
      let updated = { ...prev };
      if (isChecked) {
        updated[category] = subject;
      } else {
        delete updated[category];
      }
      return updated;
    });
  };

  // Modal Handlers
  const handleCloseRegular = () => setShowRegular(false);
  const handleCloseNep = () => setShowNep(false);
  const handleClosePrint = () => setShowPrint(false);

  const handleShowRegular = async (examForm) => {
    setSelectedExamForm(examForm);
    try {
      const response = await fetch(
        `https://sssutms.ac.in/apitest/v1/student/exam-form5?studentId=${userData._id}&examFormId=${examForm.examFormId._id}&courseName=${userData.courseName}&courseBranch=${userData.courseBranch}&semesterNumber=${examForm.examFormId.semester}`
      );
      const result = await response.json();
      if (response.ok) {
        const compulsory = result.subjects.filter(
          (subject) => subject.compulsory
        );
        const nonCompulsory = result.subjects.filter(
          (subject) => !subject.compulsory
        );
        setCompulsorySubjects(compulsory);
        setNonCompulsorySubjects(nonCompulsory);
        setFormData({ ...result, subjects: compulsory });
        setShowRegular(true);
      } else {
        console.log(result.message);
      }
    } catch (error) {
      console.log("Error fetching subjects:", error);
    }
  };

  const handleShowNep = async (examForm) => {
    setSelectedExamForm(examForm);
    try {
      const response = await fetch(
        `https://sssutms.ac.in/apitest/v1/student/exam-form5?studentId=${userData._id}&examFormId=${examForm.examFormId._id}&courseName=${userData.courseName}&courseBranch=${userData.courseBranch}&semesterNumber=${examForm.examFormId.semester}`,
        {
          method: "GET",
          headers: {
            "Cache-Control": "no-cache, no-store, must-revalidate",
            Pragma: "no-cache",
            Expires: "0",
          },
          cache: "no-store",
        }
      );
      const result = await response.json();
      if (response.ok) {
        setCompulsorySubjects(
          result.subjects.filter((subject) => subject.compulsory)
        );
        setMajorSubjects(
          result.subjects.filter(
            (subject) => subject.TypeOfcourse.toLowerCase() === "major"
          )
        );
        setMinorSubjects(
          result.subjects.filter(
            (subject) => subject.TypeOfcourse.toLowerCase() === "minor"
          )
        );
        setGenericElectives(
          result.subjects.filter((subject) =>
            subject.TypeOfcourse.includes("Generic Elective")
          )
        );
        setFoundationElectives(
          result.subjects.filter((subject) =>
            subject.TypeOfcourse.toLowerCase().includes("foundation")
          )
        );
        setShowNep(true);
      } else {
        console.log(result.message);
      }
    } catch (error) {
      console.log("Error fetching subjects:", error);
    }
  };

  const handleShowPrint = async (examForm) => {
    setSelectedExamForm(examForm);
    try {
      const response = await fetch(
        `https://sssutms.ac.in/apitest/v1/student/exam-form2?examFormId=${examForm.examFormId._id}&id=${userData._id}`
      );
      const result = await response.json();
      setFormData2(result);
      setShowPrint(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Table Sorting
  const getSortedAndSlicedData = () => {
    const sortedData = [...examForms].sort((a, b) => {
      if (sortBy === "name") {
        return sortOrder === "asc"
          ? a.branchName.localeCompare(b.branchName)
          : b.branchName.localeCompare(a.branchName);
      }
      return 0;
    });
    const startIndex = page * rowsPerPage;
    return sortedData.slice(startIndex, startIndex + rowsPerPage);
  };

  // Payment Handler
  const handlePayment = () => {
    setLoading(true);
    setTimeout(() => {
      fetch("https://sssutms.ac.in/apitest/exam-form/payment", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: 1830,
          id: userData.randomId,
          id2: userData._id,
          name: userData.name,
          course: userData.courseName,
          examId: selectedExamForm?.examFormId._id, // Use selected exam form ID
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          const form = document.createElement("form");
          form.setAttribute("method", "post");
          form.setAttribute(
            "action",
            "https://securegw.paytm.in/order/process"
          );
          Object.keys(response).forEach((key) => {
            const input = document.createElement("input");
            input.setAttribute("type", "hidden");
            input.setAttribute("name", key);
            input.setAttribute("value", response[key]);
            form.appendChild(input);
          });
          document.body.appendChild(form);
          setLoading(false);
          form.submit();
          form.remove();
        })
        .catch((err) => console.log(err));
    }, 1000);
  };

  // PDF Generation
  const generatePDF = () => {
    const doc = new jsPDF();

    // Add University Logo
    doc.addImage(sssutms, "PNG", 10, 10, 20, 20);

    // University Header
    doc.setFont("helvetica", "bold");
    doc.setFontSize(11);
    doc.text(
      "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
      35,
      15
    );

    // Add Course Name
    doc.setFontSize(11);
    doc.setFont("helvetica");
    doc.text(`${userData?.courseName?.toUpperCase()}`, 105, 23, {
      align: "center",
    });

    // Add header below course name
    doc.setFontSize(10);
    doc.text(
      `${userData.courseBranch}- EXAM FORM: ${selectedExamForm?.examFormId?.sessionName}`,
      105,
      31,
      {
        align: "center",
      }
    );

    const infoStartY = 40;

    // Helper function to handle long text and adjust line breaks
    const writeText = (text, x, y, maxWidth = 45) => {
      const lines = doc.splitTextToSize(text, maxWidth);
      lines.forEach((line, index) => {
        doc.text(line, x, y + index * 6);
      });
    };

    // Enrollment No.
    doc.setFont("helvetica", "bold");
    doc.text("Enrollment No:", 15, infoStartY);
    doc.setFont("helvetica", "normal");
    doc.text(`${userData.enrollmentNumber}`, 48.5, infoStartY);

    // Candidate Name
    doc.setFont("helvetica", "bold");
    doc.text("Candidate Name:", 15, infoStartY + 6);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(9);
    writeText(
      `${userData.name}` || "Name Not Available",
      48.5,
      infoStartY + 6,
      80
    );
    doc.setFontSize(11);
    // Father's Name
    doc.setFont("helvetica", "bold");
    doc.text("Father's Name:", 108, infoStartY + 6);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(9);
    writeText(
      `${userData.fathersname}` || "Father's Name Not Available",
      140,
      infoStartY + 6,
      50
    );
    doc.setFontSize(11);

    // Semester
    doc.setFont("helvetica", "bold");
    doc.text("Semester:", 15, infoStartY + 12);
    doc.setFont("helvetica", "normal");
    doc.text(`${userData.currentSemester}`, 49, infoStartY + 12);

    // Status
    doc.setFont("helvetica", "bold");
    doc.text("Status:", 108, infoStartY + 12);
    doc.setFont("helvetica", "bold");
    doc.text("Regular", 140, infoStartY + 12);

    // Subjects Table
    const subjectRows = formData2.subjects.map((subject, index) => [
      index + 1,
      subject.subjectCode,
      subject.subjectName,
      subject.subjectType,
    ]);

    doc.autoTable({
      startY: infoStartY + 19,
      head: [["Sr. No.", "Subject Code", "Subject Name", "Subject Type"]],
      body: subjectRows,
      styles: { fontSize: 7.5, cellPadding: 2 },
      theme: "grid",
    });

    // Candidate Declaration
    const declarationStartY = doc.lastAutoTable.finalY + 10;
    doc.setFont("helvetica", "bold");
    doc.text("CANDIDATE’S DECLARATION:", 14, declarationStartY);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(9);
    const declaration = [
      "I certify that the information provided is correct, and I am responsible for its accuracy.",
      "I am a regular candidate for the examination conducted by Sri Satya Sai University of Technology and Medical Sciences, Sehore.",
      "I am not enrolled as a regular student at any other institution or appearing in any other examination.",
      "If the provided information is found false, my exam result will be canceled.",
      "I assure to complete the required attendance and sessional work for my course.",
      "I agree to abide by all rules and regulations set by the institution and the university.",
      "I have read and fulfilled all requirements outlined in the relevant ordinance and have no objection to appearing in the exam.",
      "I request the university not to disclose my results without my written consent, including under RTI.",
    ];

    const splitLines = declaration.map((line) =>
      doc.splitTextToSize(`• ${line}`, 180)
    );
    let currentY = declarationStartY + 6;
    splitLines.forEach((lines) => {
      lines.forEach((subLine) => {
        doc.text(subLine, 18, currentY);
        currentY += 6;
      });
    });

    doc.setFontSize(11);

    // Examination Fee Details
    const feeDetailsRows = formData2.Exam_fee.map((fee, index) => [
      index + 1,
      fee.txnId,
      new Date(fee.txnDate).toLocaleString(),
      `${fee.txnAmount}`,
    ]);

    const feeDetailsStartY = currentY + 3;

    doc.setFont("helvetica", "bold");
    doc.text("EXAMINATION FEE DETAILS :", 14, feeDetailsStartY);

    doc.autoTable({
      startY: feeDetailsStartY + 3,
      head: [["Sr. No.", "Transaction No.", "Paid On", "Exam Fee"]],
      body: feeDetailsRows,
      styles: { fontSize: 10, cellPadding: 2 },
      theme: "grid",
    });

    currentY = doc.lastAutoTable.finalY + 10;

    // Add Student Signature (if available)
    if (userData.applicantSignature) {
      const img = new Image();
      img.src = userData.applicantSignature;
      doc.addImage(img, "PNG", 150, currentY, 40, 20);
    }

    // Controller of Examination Signature placeholder
    doc.setFont("helvetica", "bold");
    doc.text("Student Signature", 160, currentY + 4);

    // Save PDF
    doc.save(`${userData.name} ExaminationForm.pdf`);
  };

  // Render
  return (
    <>
      <style>
        {`
/* Default table styling for larger screens */
*{
font-family:'Poppins',sans-serif;
}
table {
  width: 100%;
  border-collapse: collapse;
}

/* Media query for smaller screens (e.g., max-width: 600px for mobile) */
@media (max-width: 600px) {
  /* Table container adjustments */
  .MuiTableContainer-root {
    overflow-x: hidden !important;
    padding: 8px !important; /* Reduced padding */
  }

  /* Hide the table head */
  thead {
    display: none !important;
  }

  /* Convert table and tbody to block layout */
  table,
  tbody {
    display: block !important;
    width: 100%;
  }

  /* Style each row as a compact card */
  tr {
    display: block !important;
    background: #fff !important;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
    margin-bottom: 12px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.03);
  }

  /* Style each cell as a compact key-value pair row */
  td {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 100% !important;
    padding: 6px 0 !important;
    position: static !important;
    background-color: transparent !important;
    font-size: 0.8rem !important; /* Smaller font */
    border-bottom: 1px solid rgba(0, 0, 0, 0.03) !important;
  }

  /* Add labels before each cell content */
  td:before {
    content: attr(data-label) ": ";
    font-weight: 600;
    color: #274c77;
    flex: 0 0 35%;
    white-space: nowrap;
    font-size: 0.8rem;
  }

  /* Style the value part */
  td > * {
    flex: 1;
    text-align: right;
    font-size: 0.8rem;
  }

  /* Remove border from the last cell */
  td:last-child {
    border-bottom: none !important;
  }

  /* Compact button styling */
  td .MuiButton-root {
    width: 80% !important; /* Reduced width */
    padding: "4px 8px" !important; /* Smaller padding */
    font-size: 0.75rem !important; /* Smaller font */
    minHeight: "auto" !important; /* Remove default min height */
    margin-top: 4px !important;
  }

  /* Compact the user info section */
  .MuiBox-root {
    padding: 8px !important; /* Reduced padding */
    margin-bottom: 12px !important; /* Reduced margin */
    gap: 8px !important; /* Reduced gap */
  }

  .MuiTypography-body2 {
    font-size: 0.8rem !important; /* Smaller font */
    line-height: 1.2 !important; /* Reduced line height */
  }
}
    `}
      </style>
      <ThemeProvider theme={theme}>
        <StudentDashboard />
        <Box
          sx={{
            width: "90%",
            marginLeft: { xs: "0", sm: "80px" },
            marginTop: "50px",
            background: "linear-gradient(135deg, #f0f4f8 0%, #e1e8ed 100%)",
            padding: "20px",
            borderRadius: "15px",
            boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
            fontFamily: "'Poppins', sans-serif",
            color: "#333",
            boxSizing: "border-box",
          }}
        >
          {loading ? (
            <CircularProgress
              color="success"
              style={{ marginTop: "80px", marginLeft: "50%" }}
            />
          ) : (
            <CardContent
              sx={{
                background: "rgba(255, 255, 255, 0.95)",
                borderRadius: "10px",
                padding: "15px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  fontSize: "1.6rem",
                  color: "#0d47a1",
                  fontWeight: 600,
                  mb: 2,
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Examination Form
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  padding: "12px",
                  background: "rgba(0, 0, 0, 0.05)",
                  borderRadius: "8px",
                  marginBottom: "20px",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { xs: "center", sm: "flex-start" },
                  gap: { xs: "12px", sm: "0" },
                  boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.05)",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.95rem",
                    color: "#274c77",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  <strong style={{ fontWeight: 600 }}>Enrollment No:</strong>{" "}
                  {userData?.enrollmentNumber}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.95rem",
                    color: "#274c77",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  <strong style={{ fontWeight: 600 }}>Candidate Name:</strong>{" "}
                  {userData?.name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.95rem",
                    color: "#274c77",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  <strong style={{ fontWeight: 600 }}>Father Name:</strong>{" "}
                  {userData?.fathersname}
                </Typography>
              </Box>

              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: 450,
                  overflowX: "auto",
                  borderRadius: "10px",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {[
                        {
                          label: "S.No.",
                          minWidth: "auto",
                          left: 0,
                          zIndex: 2,
                        },
                        {
                          label: "Session",
                          minWidth: "100px",
                          left: 50,
                          zIndex: 2,
                        },
                        { label: "Session Name", minWidth: "auto" },
                        { label: "Semester/Year", minWidth: "70px" },
                        { label: "Category", minWidth: "100px" },
                        { label: "Form Status", minWidth: "200px" },
                        { label: "ExamForm Payment", minWidth: "200px" },
                        { label: "Click to Show", minWidth: "150px" },
                      ].map((col, idx) => (
                        <TableCell
                          key={idx}
                          align="left"
                          sx={{
                            backgroundColor: "#0d47a1",
                            color: "#fff",
                            position: "sticky",
                            top: 0,
                            left: col.left || "auto",
                            zIndex: col.zIndex || 1,
                            fontWeight: 600,
                            padding: "12px",
                            minWidth: col.minWidth,
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          {col.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getSortedAndSlicedData().map((form, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(0, 121, 107, 0.1)",
                          },
                        }}
                      >
                        <TableCell
                          data-label="S.No."
                          align="left"
                          sx={{
                            position: "sticky",
                            top: 0,
                            left: 0,
                            zIndex: 1,
                            backgroundColor: "white",
                            padding: "10px",
                            fontSize: "0.9rem",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          data-label="Session"
                          align="left"
                          sx={{
                            position: "sticky",
                            left: 50,
                            top: 0,
                            fontWeight: 700,
                            zIndex: 1,
                            backgroundColor: "white",
                            color: "red",
                            padding: "10px",
                            fontSize: "0.9rem",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          {form.examFormId.session || "N/A"}
                        </TableCell>
                        <TableCell
                          data-label="Session Name"
                          sx={{
                            padding: "10px",
                            fontSize: "0.9rem",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          {form.examFormId.sessionName || "N/A"}
                        </TableCell>
                        <TableCell
                          data-label="Semester/Year"
                          sx={{
                            padding: "10px",
                            fontSize: "0.9rem",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          {`Sem${form.examFormId.semester}` || "N/A"}
                        </TableCell>
                        <TableCell
                          data-label="Category"
                          sx={{
                            fontWeight: "bold",
                            position: "sticky",
                            left: 210,
                            backgroundColor: "white",
                            padding: "10px",
                            fontSize: "0.9rem",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          {form.examFormId.category || "N/A"}
                        </TableCell>
                        <TableCell
                          data-label="Form Status"
                          sx={{
                            padding: "10px",
                            fontSize: "0.9rem",
                            color: form.isForwarded ? "#2ecc71" : "#e74c3c",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          {form.isForwarded
                            ? "Forwarded"
                            : "Form Not Forwarded"}
                        </TableCell>
                        <TableCell
                          data-label="ExamForm Payment"
                          sx={{
                            padding: "10px",
                            fontSize: "0.9rem",
                            color: form.isPaid ? "#2ecc71" : "#e74c3c",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          {form.isPaid ? "Paid" : "Not Paid"}
                        </TableCell>
                        <TableCell
                          data-label="Click to Show"
                          sx={{ padding: "10px" }}
                        >
                          <Button
                            variant="outlined"
                            sx={{
                              padding: "6px 12px",
                              border: "2px solid #0d47a1",
                              color: "#0d47a1",
                              borderRadius: "10px",
                              fontSize: "0.85rem",
                              background: "none",
                              fontFamily: "'Poppins', sans-serif",
                              transition: "all 0.3s ease",
                              "&:hover": {
                                background: "#0d47a1",
                                color: "#fff",
                                boxShadow: "0 0 15px rgba(0, 121, 107, 0.5)",
                                border: "2px solid #0d47a1",
                              },
                            }}
                            onClick={() => {
                              if (!form.isForwarded) {
                                swal({
                                  icon: "warning",
                                  title: "Form Not Forwarded",
                                  text: "Your form has not been forwarded. Please contact the Department.",
                                  buttons: "OK",
                                });
                              } else if (form.isFilled && form.isPaid) {
                                handleShowPrint(form);
                              } else if (
                                [
                                  "BACHELOR OF COMMERCE",
                                  "BACHELOR OF SCIENCE",
                                  "BACHELOR OF ARTS",
                                ].includes(userData.courseName)
                              ) {
                                handleShowNep(form);
                              } else {
                                handleShowRegular(form);
                              }
                            }}
                          >
                            {form.isFilled && form.isPaid
                              ? "Print Exam Form"
                              : "Show Form"}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          )}

          {/* Regular Form Modal */}
          <Modal
            show={showRegular}
            onHide={handleCloseRegular}
            size="lg"
            centered
            style={{ marginTop: "40px" }}
          >
            <Modal.Header closeButton style={{ color: "rgb(2, 48, 71)" }}>
              <Modal.Title
                variant="button"
                style={{
                  height: "8px",
                  marginTop: "2px",
                  fontFamily: "'Poppins',Sans-serif",
                }}
              >
                Examination Form
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div id="exam-form" style={{ padding: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src={sssutms}
                    alt="Logo"
                    style={{ width: "100px", marginRight: "20px" }}
                  />
                  <div>
                    <Typography
                      style={{
                        margin: 0,
                        fontSize: "15px",
                        fontWeight: "bold",
                        fontFamily: "'Poppins',Sans-serif",
                      }}
                    >
                      SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL
                      SCIENCES, SEHORE
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        margin: 0,
                        marginLeft: "90px",
                        fontFamily: "'Poppins',Sans-serif",
                      }}
                    >
                      ( {userData.courseBranch})
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        margin: 0,
                        marginLeft: "160px",
                        fontFamily: "'Poppins',Sans-serif",
                      }}
                    >
                      Session: {selectedExamForm?.examFormId?.sessionName}
                    </Typography>
                  </div>
                </div>

                <Form>
                  <Row className="mb-2">
                    <Form.Group
                      style={{ fontFamily: "'Poppins',Sans-serif" }}
                      as={Col}
                    >
                      <Form.Label>Enrollment No: </Form.Label>
                      <span> {userData?.enrollmentNumber}</span>
                    </Form.Group>
                    <Form.Group
                      style={{ fontFamily: "'Poppins',Sans-serif" }}
                      as={Col}
                    >
                      <Form.Label>Semester/Year: </Form.Label>
                      <span> {selectedExamForm?.examFormId?.semester}</span>
                    </Form.Group>
                  </Row>
                  <Row className="mb-2">
                    <Form.Group
                      style={{ fontFamily: "'Poppins',Sans-serif" }}
                      as={Col}
                    >
                      <Form.Label>Candidate's Name: </Form.Label>
                      <span> {userData?.name}</span>
                    </Form.Group>
                    <Form.Group
                      style={{ fontFamily: "'Poppins',Sans-serif" }}
                      as={Col}
                    >
                      <Form.Label>Father's Name:</Form.Label>
                      <span> {userData?.fathersname}</span>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group
                      style={{ fontFamily: "'Poppins',Sans-serif" }}
                      as={Col}
                    >
                      <Form.Label>Mother's Name: </Form.Label>
                      <span> {userData?.mothersname}</span>
                    </Form.Group>
                    <Form.Group
                      style={{ fontFamily: "'Poppins',Sans-serif" }}
                      as={Col}
                    >
                      <Form.Label>Status: </Form.Label>
                      <span> {selectedExamForm?.examFormId?.category}</span>
                    </Form.Group>
                  </Row>

                  <Table striped bordered hover>
                    <TableHead
                      style={{ backgroundColor: "rgba(224, 224, 224, 1)" }}
                    >
                      <TableRow sx={{ height: "30px" }}>
                        <TableCell
                          sx={{
                            padding: "4px 8px",
                            fontSize: "0.9rem",
                            fontFamily: "'Poppins',Sans-serif",
                          }}
                        >
                          S.No
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "4px 8px",
                            fontSize: "0.9rem",
                            fontFamily: "'Poppins',Sans-serif",
                          }}
                        >
                          Subject Code
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "4px 8px",
                            fontSize: "0.9rem",
                            fontFamily: "'Poppins',Sans-serif",
                          }}
                        >
                          Subject Name
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "4px 8px",
                            fontSize: "0.9rem",
                            fontFamily: "'Poppins',Sans-serif",
                          }}
                        >
                          Subject Type
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "4px 8px",
                            fontSize: "0.9rem",
                            fontFamily: "'Poppins',Sans-serif",
                          }}
                        >
                          Elective
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {compulsorySubjects
                        .concat(nonCompulsorySubjects)
                        .sort((a, b) => (a.subjectType === "Theory" ? -1 : 1))
                        .map((subject, index) => {
                          const isSelected =
                            subject.compulsory ||
                            selectedSubjects[subject.subjectType.toLowerCase()]
                              ?.subjectCode === subject.subjectCode;
                          return (
                            <TableRow
                              key={`subject-${index}`}
                              sx={{ height: "10px" }}
                            >
                              <TableCell
                                sx={{
                                  padding: "0px 10px",
                                  fontSize: "0.85rem",
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0px 10px",
                                  fontSize: "0.85rem",
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {subject.subjectCode}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0px 10px",
                                  fontSize: "0.85rem",
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {subject.subjectName}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0px 10px",
                                  fontSize: "0.85rem",
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                {subject.subjectType}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0px 10px",
                                  fontSize: "0.85rem",
                                  fontFamily: "'Poppins',Sans-serif",
                                }}
                              >
                                <Checkbox
                                  sx={{ transform: "scale(0.7)" }}
                                  checked={isSelected}
                                  disabled={subject.compulsory}
                                  color="primary"
                                  onChange={(e) =>
                                    handleCheckboxChange(subject, e)
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>

                  <div style={{ marginTop: "20px" }}>
                    <Typography variant="h6">
                      CANDIDATE'S DESCRIPTION
                    </Typography>
                    <hr
                      style={{
                        width: "40%",
                        height: "2px",
                        backgroundColor: "gray",
                        margin: "-2px 0",
                      }}
                    />
                    <Typography variant="body2" gutterBottom>
                      <ul
                        style={{
                          marginLeft: "20px",
                          marginTop: "10px",
                          fontFamily: "'Poppins',Sans-serif",
                        }}
                      >
                        <li>
                          I certify that this application has been filled by me
                          and the information given therein is correct and I
                          shall be provisionally responsible for the same proved
                          false later on.
                        </li>
                        <li>
                          I also certify that I am appearing as regular
                          candidate only in the examination being conducted by
                          the Sri Satya Sai University of Technology and Medical
                          Sciences, Sehore.
                        </li>
                        <li>
                          I further declare that I am neither a regular student
                          of any other Education Institution nor appearing in
                          any other examination as regular candidate.
                        </li>
                        <li>
                          I understand that if it is found later on that the
                          information furnished above is false then my result of
                          examination will be cancelled.
                        </li>
                        <li>
                          I assure that I will complete the required attendance
                          and sessional work prescribed for the course of my
                          registration. Kindly permit me to appear in this
                          examination.
                        </li>
                        <li>
                          I accept to abide by all the rules and regulations of
                          study and examination as prescribed by the institution
                          & Sri Satya Sai University of Technology and Medical
                          Sciences, Sehore.
                        </li>
                        <li>
                          I have read relevant ordinance applicable to me and
                          have completed all the requirements as given in it. I
                          have completed my studies and have no objection in
                          appearing in examination on the date declared by the
                          university.
                        </li>
                        <li>
                          <b>
                            I also request the University that without my
                            written consent, do not disclose my result to
                            anybody in any manner including RTI.
                          </b>
                        </li>
                      </ul>
                      <Checkbox checked={isChecked} onChange={handleCheckbox} />{" "}
                      I accept all above points.
                    </Typography>
                    {isChecked && (
                      <div
                        style={{
                          padding: "15px 20px",
                          marginTop: "-4%",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #ddd",
                          borderRadius: "8px",
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          Total Fees to Pay ₹ 1830 Only
                        </Typography>
                        {isLoading ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <CircularProgress size={24} />
                            <Typography>Loading...</Typography>
                          </div>
                        ) : (
                          isApiSuccess && (
                            <Button
                              style={{
                                backgroundColor: "rgb(2,48,71)",
                                color: "white",
                              }}
                              onClick={handlePayment}
                            >
                              PAY NOW
                            </Button>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </Form>
              </div>
            </Modal.Body>
          </Modal>

          {/* NEP Form Modal */}
          <Modal
            show={showNep}
            onHide={handleCloseNep}
            size="lg"
            centered
            style={{ marginTop: "40px" }}
          >
            <Modal.Header closeButton style={{ color: "rgb(2, 48, 71)" }}>
              <Modal.Title
                variant="button"
                style={{
                  height: "8px",
                  marginTop: "2px",
                  fontFamily: "'Poppins',Sans-serif",
                }}
              >
                Examination Form NEP
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div id="exam-form" style={{ padding: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src={sssutms}
                    alt="Logo"
                    style={{ width: "100px", marginRight: "20px" }}
                  />
                  <div>
                    <Typography
                      style={{
                        margin: 0,
                        fontSize: "15px",
                        fontWeight: "bold",
                        fontFamily: "'Poppins',Sans-serif",
                      }}
                    >
                      SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL
                      SCIENCES, SEHORE
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        margin: 0,
                        marginLeft: "90px",
                        fontFamily: "'Poppins',Sans-serif",
                      }}
                    >
                      ( {userData.courseBranch})
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        margin: 0,
                        marginLeft: "160px",
                        fontFamily: "'Poppins',Sans-serif",
                      }}
                    >
                      Session: {selectedExamForm?.examFormId?.sessionName}
                    </Typography>
                  </div>
                </div>

                <Form>
                  <Row className="mb-2">
                    <Form.Group
                      style={{ fontFamily: "'Poppins',Sans-serif" }}
                      as={Col}
                    >
                      <Form.Label>Enrollment No: </Form.Label>
                      <span> {userData?.enrollmentNumber}</span>
                    </Form.Group>
                    <Form.Group
                      style={{ fontFamily: "'Poppins',Sans-serif" }}
                      as={Col}
                    >
                      <Form.Label>Semester/Year: </Form.Label>
                      <span> {selectedExamForm?.examFormId?.semester}</span>
                    </Form.Group>
                  </Row>
                  <Row className="mb-2">
                    <Form.Group
                      style={{ fontFamily: "'Poppins',Sans-serif" }}
                      as={Col}
                    >
                      <Form.Label>Candidate's Name: </Form.Label>
                      <span> {userData?.name}</span>
                    </Form.Group>
                    <Form.Group
                      style={{ fontFamily: "'Poppins',Sans-serif" }}
                      as={Col}
                    >
                      <Form.Label>Father's Name:</Form.Label>
                      <span> {userData?.fathersname}</span>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group
                      style={{ fontFamily: "'Poppins',Sans-serif" }}
                      as={Col}
                    >
                      <Form.Label>Mother's Name: </Form.Label>
                      <span> {userData?.mothersname}</span>
                    </Form.Group>
                    <Form.Group
                      style={{ fontFamily: "'Poppins',Sans-serif" }}
                      as={Col}
                    >
                      <Form.Label>Status: </Form.Label>
                      <span> {selectedExamForm?.examFormId?.category}</span>
                    </Form.Group>
                  </Row>

                  <Table striped bordered hover>
                    <TableHead
                      style={{ backgroundColor: "rgba(224, 224, 224, 1)" }}
                    >
                      <TableRow sx={{ height: "30px" }}>
                        <TableCell
                          sx={{
                            padding: "4px 8px",
                            fontSize: "0.9rem",
                            fontFamily: "'Poppins',Sans-serif",
                          }}
                        >
                          S.No
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "4px 8px",
                            fontSize: "0.9rem",
                            fontFamily: "'Poppins',Sans-serif",
                          }}
                        >
                          Subject Code
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "4px 8px",
                            fontSize: "0.9rem",
                            fontFamily: "'Poppins',Sans-serif",
                          }}
                        >
                          Subject Name
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "4px 8px",
                            fontSize: "0.9rem",
                            fontFamily: "'Poppins',Sans-serif",
                          }}
                        >
                          Subject Type
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "4px 8px",
                            fontSize: "0.9rem",
                            fontFamily: "'Poppins',Sans-serif",
                          }}
                        >
                          Elective
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[
                        { category: "Major", data: majorSubjects },
                        { category: "Minor", data: minorSubjects },
                        {
                          category: "Generic Elective",
                          data: genericElectives,
                        },
                        { category: "Foundation", data: foundationElectives },
                      ].map((group, groupIndex) => (
                        <React.Fragment key={groupIndex}>
                          <TableRow>
                            <TableCell
                              colSpan={5}
                              sx={{
                                backgroundColor: "#f0f0f0",
                                fontWeight: "bold",
                                textAlign: "center",
                                padding: "5px 10px",
                                fontSize: "0.9rem",
                              }}
                            >
                              {group.category} Subjects
                            </TableCell>
                          </TableRow>
                          {group.data.map((subject, index) => {
                            const isSelected =
                              selectedNepSubjects[group.category]
                                ?.subjectCode === subject.subjectCode ||
                              subject.compulsory;
                            return (
                              <TableRow
                                key={`subject-${groupIndex}-${index}`}
                                sx={{ height: "10px" }}
                              >
                                <TableCell
                                  sx={{
                                    padding: "0px 10px",
                                    fontSize: "0.85rem",
                                    fontFamily: "'Poppins',Sans-serif",
                                  }}
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0px 10px",
                                    fontSize: "0.85rem",
                                    fontFamily: "'Poppins',Sans-serif",
                                  }}
                                >
                                  {subject.subjectCode}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0px 10px",
                                    fontSize: "0.85rem",
                                    fontFamily: "'Poppins',Sans-serif",
                                  }}
                                >
                                  {subject.subjectName}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0px 10px",
                                    fontSize: "0.85rem",
                                    fontFamily: "'Poppins',Sans-serif",
                                  }}
                                >
                                  {subject.subjectType}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0px 10px",
                                    fontSize: "0.85rem",
                                    fontFamily: "'Poppins',Sans-serif",
                                  }}
                                >
                                  <Checkbox
                                    sx={{ transform: "scale(0.7)" }}
                                    checked={isSelected}
                                    disabled={subject.compulsory}
                                    color="primary"
                                    onChange={(e) =>
                                      handleCheckboxNepChange(
                                        subject,
                                        e,
                                        group.category
                                      )
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>

                  <div
                    style={{
                      marginTop: "20px",
                      fontFamily: "'Poppins',Sans-serif",
                    }}
                  >
                    <Typography variant="h6">
                      CANDIDATE'S DESCRIPTION
                    </Typography>
                    <hr
                      style={{
                        width: "40%",
                        height: "2px",
                        backgroundColor: "gray",
                        margin: "-2px 0",
                      }}
                    />
                    <Typography variant="body2" gutterBottom>
                      <ul
                        style={{
                          marginLeft: "20px",
                          marginTop: "10px",
                          fontFamily: "'Poppins',Sans-serif",
                        }}
                      >
                        <li>
                          I certify that this application has been filled by me
                          and the information given therein is correct and I
                          shall be provisionally responsible for the same proved
                          false later on.
                        </li>
                        <li>
                          I also certify that I am appearing as regular
                          candidate only in the examination being conducted by
                          the Sri Satya Sai University of Technology and Medical
                          Sciences, Sehore.
                        </li>
                        <li>
                          I further declare that I am neither a regular student
                          of any other Education Institution nor appearing in
                          any other examination as regular candidate.
                        </li>
                        <li>
                          I understand that if it is found later on that the
                          information furnished above is false then my result of
                          examination will be cancelled.
                        </li>
                        <li>
                          I assure that I will complete the required attendance
                          and sessional work prescribed for the course of my
                          registration. Kindly permit me to appear in this
                          examination.
                        </li>
                        <li>
                          I accept to abide by all the rules and regulations of
                          study and examination as prescribed by the institution
                          & Sri Satya Sai University of Technology and Medical
                          Sciences, Sehore.
                        </li>
                        <li>
                          I have read relevant ordinance applicable to me and
                          have completed all the requirements as given in it. I
                          have completed my studies and have no objection in
                          appearing in examination on the date declared by the
                          university.
                        </li>
                        <li>
                          <b>
                            I also request the University that without my
                            written consent, do not disclose my result to
                            anybody in any manner including RTI.
                          </b>
                        </li>
                      </ul>
                      <Checkbox checked={isChecked} onChange={handleCheckbox} />{" "}
                      I accept all above points.
                    </Typography>
                    {isChecked && (
                      <div
                        style={{
                          padding: "15px 20px",
                          marginTop: "-4%",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #ddd",
                          borderRadius: "8px",
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          Total Fees to Pay ₹ 1830 Only
                        </Typography>
                        {isLoading ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <CircularProgress size={24} />
                            <Typography>Loading...</Typography>
                          </div>
                        ) : (
                          isApiSuccess && (
                            <Button
                              style={{
                                backgroundColor: "rgb(2,48,71)",
                                color: "white",
                              }}
                              onClick={handlePayment}
                            >
                              PAY NOW
                            </Button>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </Form>
              </div>
            </Modal.Body>
          </Modal>

          {/* Print Form Modal */}
          <Modal
            show={showPrint}
            onHide={handleClosePrint}
            size="lg"
            centered
            style={{ marginTop: "40px" }}
          >
            <Modal.Header closeButton style={{ color: "rgb(2, 48, 71)" }}>
              <Modal.Title
                variant="button"
                style={{
                  height: "8px",
                  marginTop: "2px",
                  fontFamily: "'Poppins',Sans-serif",
                }}
              >
                Examination Form
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div id="exam-form" style={{ padding: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src={sssutms}
                    alt="Logo"
                    style={{ width: "100px", marginRight: "20px" }}
                  />
                  <div>
                    <Typography
                      style={{
                        margin: 0,
                        fontSize: "15px",
                        fontWeight: "bold",
                        fontFamily: "'Poppins',Sans-serif",
                      }}
                    >
                      SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL
                      SCIENCES, SEHORE
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        margin: 0,
                        textAlign: "center",
                        fontFamily: "'Poppins',Sans-serif",
                      }}
                    >
                      ( {userData.courseBranch})
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        margin: 0,
                        textAlign: "center",
                        fontFamily: "'Poppins',Sans-serif",
                      }}
                    >
                      Session: {selectedExamForm?.examFormId?.sessionName}
                    </Typography>
                  </div>
                </div>

                <Form>
                  <Row className="mb-2">
                    <Form.Group
                      style={{ fontFamily: "'Poppins',Sans-serif" }}
                      as={Col}
                    >
                      <Form.Label>Enrollment No: </Form.Label>
                      <span> {userData?.enrollmentNumber}</span>
                    </Form.Group>
                    <Form.Group
                      style={{ fontFamily: "'Poppins',Sans-serif" }}
                      as={Col}
                    >
                      <Form.Label>Semester/Year: </Form.Label>
                      <span> {selectedExamForm?.examFormId?.semester}</span>
                    </Form.Group>
                  </Row>
                  <Row className="mb-2">
                    <Form.Group
                      style={{ fontFamily: "'Poppins',Sans-serif" }}
                      as={Col}
                    >
                      <Form.Label>Candidate's Name: </Form.Label>
                      <span> {userData?.name}</span>
                    </Form.Group>
                    <Form.Group
                      style={{ fontFamily: "'Poppins',Sans-serif" }}
                      as={Col}
                    >
                      <Form.Label>Father's Name:</Form.Label>
                      <span> {userData?.fathersname}</span>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group
                      style={{ fontFamily: "'Poppins',Sans-serif" }}
                      as={Col}
                    >
                      <Form.Label>Mother's Name: </Form.Label>
                      <span> {userData?.mothersname}</span>
                    </Form.Group>
                    <Form.Group
                      style={{ fontFamily: "'Poppins',Sans-serif" }}
                      as={Col}
                    >
                      <Form.Label>Status: </Form.Label>
                      <span> {selectedExamForm?.examFormId?.category}</span>
                    </Form.Group>
                  </Row>

                  <Table striped bordered hover>
                    <TableHead
                      style={{ backgroundColor: "rgba(224, 224, 224, 1)" }}
                    >
                      <TableRow sx={{ height: "30px" }}>
                        <TableCell
                          sx={{
                            padding: "4px 8px",
                            fontSize: "0.9rem",
                            fontFamily: "'Poppins',Sans-serif",
                          }}
                        >
                          Sr.No
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "4px 8px",
                            fontSize: "0.9rem",
                            fontFamily: "'Poppins',Sans-serif",
                          }}
                        >
                          Subject Code
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "4px 8px",
                            fontSize: "0.9rem",
                            fontFamily: "'Poppins',Sans-serif",
                          }}
                        >
                          Subject Name
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "4px 8px",
                            fontSize: "0.9rem",
                            fontFamily: "'Poppins',Sans-serif",
                          }}
                        >
                          Subject Type
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formData2?.subjects?.map((subject, index) => (
                        <TableRow key={index} sx={{ height: "30px" }}>
                          <TableCell
                            sx={{
                              padding: "4px 8px",
                              fontSize: "0.85rem",
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "4px 8px",
                              fontSize: "0.85rem",
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            {subject.subjectCode}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "4px 8px",
                              fontSize: "0.85rem",
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            {subject.subjectName}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "4px 8px",
                              fontSize: "0.85rem",
                              fontFamily: "'Poppins',Sans-serif",
                            }}
                          >
                            {subject.subjectType}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <div style={{ marginTop: "20px" }}>
                    <Typography variant="h6">
                      CANDIDATE'S DESCRIPTION
                    </Typography>
                    <hr
                      style={{
                        width: "40%",
                        height: "2px",
                        backgroundColor: "gray",
                        margin: "-2px 0",
                      }}
                    />
                    <Typography variant="body2" gutterBottom>
                      <ul
                        style={{
                          marginLeft: "20px",
                          marginTop: "10px",
                          fontFamily: "'Poppins',sans-serif",
                        }}
                      >
                        <li>
                          I certify that this application has been filled by me
                          and the information given therein is correct and I
                          shall be provisionally responsible for the same proved
                          false later on.
                        </li>
                        <li>
                          I also certify that I am appearing as regular
                          candidate only in the examination being conducted by
                          the Sri Satya Sai University of Technology and Medical
                          Sciences, Sehore.
                        </li>
                        <li>
                          I further declare that I am neither a regular student
                          of any other Education Institution nor appearing in
                          any other examination as regular candidate.
                        </li>
                        <li>
                          I understand that if it is found later on that the
                          information furnished above is false then my result of
                          examination will be cancelled.
                        </li>
                        <li>
                          I assure that I will complete the required attendance
                          and sessional work prescribed for the course of my
                          registration. Kindly permit me to appear in this
                          examination.
                        </li>
                        <li>
                          I accept to abide by all the rules and regulations of
                          study and examination as prescribed by the institution
                          & Sri Satya Sai University of Technology and Medical
                          Sciences, Sehore.
                        </li>
                        <li>
                          I have read relevant ordinance applicable to me and
                          have completed all the requirements as given in it. I
                          have completed my studies and have no objection in
                          appearing in examination on the date declared by the
                          university.
                        </li>
                        <li>
                          <b>
                            I also request the University that without my
                            written consent, do not disclose my result to
                            anybody in any manner including RTI.
                          </b>
                        </li>
                      </ul>
                    </Typography>
                  </div>
                </Form>
              </div>
            </Modal.Body>
            <div
              style={{
                padding: "15px 20px",
                marginTop: "-4%",
                backgroundColor: "#f9f9f9",
                border: "1px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="subtitle2"
                style={{
                  fontWeight: "bold",
                  fontFamily: "'Poppins',Sans-serif",
                }}
              >
                EXAMINATION FEE DETAILS
              </Typography>
              <hr
                style={{
                  width: "30%",
                  height: "2px",
                  backgroundColor: "#333",
                  margin: "-2px 0 10px 0",
                }}
              />
              {formData2?.Exam_fee?.map((fee, index) => (
                <Typography
                  key={index}
                  variant="subtitle2"
                  gutterBottom
                  style={{ fontFamily: "'Poppins',Sans-serif" }}
                >
                  <strong>Transaction No:</strong> {fee.txnId},{" "}
                  <strong>Paid On:</strong>{" "}
                  {new Date(fee.txnDate).toLocaleString()},{" "}
                  <strong>Exam Fee:</strong> ₹{fee.txnAmount}
                </Typography>
              ))}
            </div>
            <Modal.Footer>
              <Button variant="text" onClick={handleClosePrint}>
                Close
              </Button>
              <Button variant="outlined" onClick={generatePDF}>
                Download as PDF
              </Button>
            </Modal.Footer>
          </Modal>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default Std_ExamForm;
