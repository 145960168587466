import React from "react";
import Nav from "../HomePage/Nav";
import { Link } from "react-router-dom";
import Alumnipho from "../images/Nav_Download_img/Alumini.png"
import Footerpage from "../HomePage/Footerpage";

function Alumni() {
  return (
    <>
      <Nav />
      <br />
      <style>
        {`
        
        
        .section_all {
            position: relative;
            padding-top: 20px;
            margin-left:-100px;
            padding-bottom: 80px;
            min-height: 100vh;
        
        }
    
        
        .about_icon i {
            font-size: 22px;
            height: 65px;
            width: 65px;
            line-height: 65px;
            display: inline-block;
            background: #fff;
            border-radius: 35px;
            color: #00bd2a;
            box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
            
        }
        
    
        .about_icon span {
            position: relative;
            top: -10px;
            
        }
        
        .about_content_box_all {
            padding: 28px;
        }
    h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    }


    h1 em {
    font-style: normal;
    font-weight: 600;
    }
    .two h1 {
    text-transform: capitalize;
    }
    .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 2px;
    content: "";
    background-color: #F09319;
    }


    .alt-two h1 {
    text-align:center;
    }

    .uul {
                list-style-type: none;
                padding: 0;
                margin: 0;
                list-style-type: square;
                margin-left: 10px;
            }
    .alt-two h1:before {
    left:50%; margin-left:-30px;
    }


    .lli{
    font-size:16px; 
    margin-left:40px;
    margin-top:10px;
    list-style-type: none; /* Removes bullets */}


    .list-icon li i {
        color: #555555;
        font-size: 14px;
        height: 28px;
        line-height: 28px;
        margin: 5px 10px 5px 0;
        text-align: center;
        width: 28px;
    
    }
    .fa {
        display: inline-block;
        font: normal normal normal 14px / 1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .list-icon {
            list-style-type: none; /* Removes bullets */
            padding: 0; 
            margin: 0;
            display: flex;
            flex-direction: column; /* Default to vertical */
            
        }


    @media (max-width: 480px) {
    .section_all{
    margin-left:6px;
    padding:5px;}
    
        .list-icon {
        margin-left:-50px;
                flex-direction: row; /* Makes the list horizontal */
                flex-wrap: wrap; /* Allows wrapping if items are too wide */
                // justify-content: space-between; /* Adds spacing between items */
                flex: 1 1 50%; /* Makes each item take about 45% width */
                    
            }
    
    }
            
        
        `}
      </style>

      <section className="section_all " id="about">
        <div className="container">
          <div class="two">
            <h1> Alumni</h1>
          </div>
          <br></br>
          <div>
            <Link
              to="https://cdn.sssutms.ac.in/media/static/PDF/society_rules_07122024_0515.pdf"
              target="_blank"
            >
              {" "}
              <h4 style={{ marginLeft: "60%", color: "#540b0e" }}>
                {" "}
                <b>Click Here</b>
              </h4>
            </Link>
          </div>

          <div className="row">
            <img src={Alumnipho} style={{ width: "75%" }} />

            <div className="col-lg-3">
              <ul className="list-icon">
                <h5
                  style={{ textAlign: "center", textDecoration: "underline" }}
                >
                  <b>E - Content</b>
                </h5>
                <br />
               
                <Link to="/forms">
                  <li className="lli">
                    <a href="/forms">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Forms
                    </a>
                  </li>
                </Link>
                <Link to="/E-Content">
                  <li className="lli">
                    <a href="/E-Content">
                      <i class="fa fa-adjust" aria-hidden="true"></i>E-Content
                    </a>
                  </li>
                </Link>
                <Link to="/alumni">
                  <li className="lli">
                    <a href="/E-Content">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Alumni
                    </a>
                  </li>
                </Link>
                <Link to="/rti">
                  <li className="lli">
                    <a href="/E-Content">
                      <i class="fa fa-adjust" aria-hidden="true"></i>RTI
                    </a>
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footerpage/>
    </>
  );
}

export default Alumni;
