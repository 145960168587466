
import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  CircularProgress,
} from "@mui/material";
import OnlyHeader from "../../AdminComponent/OnlyHeader";
import E_eligibility_Form from "./E-Eligibility";

export default function E_Pravesh_Course() {
  const [courseData, setCourseData] = useState({});
  const [selectedType, setSelectedType] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [eligible, setEligible] = useState("");
  const [eligibilityGradPer, setEligibilityGradPer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const session = new Date().getFullYear();

  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://sssutms.ac.in/apitest/v2/admin/courses?session=${session}`
        );
        const data = await response.json();
        const formattedData = {};

        data.forEach((course) => {
          if (!formattedData[course.courseType]) {
            formattedData[course.courseType] = {
              courses: [],
              branches: {},
            };
          }

          course.courseNames.forEach((courseName) => {
            formattedData[course.courseType].courses.push(
              courseName.courseName
            );
            formattedData[course.courseType].branches[courseName.courseName] =
              courseName.branches.map((branch) => branch.branchName);
          });
        });

        setCourseData(formattedData);
      } catch (error) {
        console.error("Error fetching course data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, [session]);

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    setSelectedCourse("");
    setSelectedBranch("");
  };

  const handleCourseChange = (e) => {
    setSelectedCourse(e.target.value);
  };

  const handleBranchChange = (e) => {
    setSelectedBranch(e.target.value);
  };

  const handleSearch = async () => {
    setSearchLoading(true);
    try {
      const response = await fetch(process.env.REACT_APP_STD_ELIGIBILITY, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          courseType: selectedType,
          courseName: selectedCourse,
          courseBranch: selectedBranch,
        }),
      });

      const data = await response.json();
      setEligible(data);
      setEligibilityGradPer(data.eligibilityGradPer);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setSearchLoading(false);
    }
  };

  const isValid = selectedBranch !== "" && selectedCourse !== "";

  return (
    <>
      <style>
        {`
          * {
            font-family: 'Poppins', Sans-serif;
          }
        `}
      </style>
      <OnlyHeader />

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100px",
            mt: 5,
            p:2
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Container
          maxWidth="lg"
          sx={{
            boxShadow: 3,
            p: 5,
            mb: 3,
            bgcolor: "background.paper",
            borderRadius: 1,
            mt: 5,
            maxWidth: { xs: "95%", sm: "90%" }
          }}
        >
          <Box
            sx={{
              bgcolor: "#e3f2fd",
              p: 1,
              color: "#1976d2",
              textAlign: "left",
              borderRadius: 1,
              mb: 3,
            }}
          >
            <Typography variant="body" sx={{ fontWeight: 600 }}>
              Select Course
            </Typography>
          </Box>

          <Grid container spacing={3}>
            {/* Course Type */}
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Course Type</InputLabel>
                <Select
                  value={selectedType}
                  onChange={handleTypeChange}
                  label="Course Type"
                  sx={{
                    height: 45, 
                    fontSize: 14,
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                >
                  <MenuItem value="" sx={{ height: 35, fontSize: 15, padding: "4px 8px" }}>
                    <em>Select Type</em>
                  </MenuItem>
                  {Object.keys(courseData).map((type) => (
                    <MenuItem key={type} value={type} sx={{ height: 35, fontSize: 14, padding: "4px 8px" }}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Course Name */}
            <Grid item xs={12} md={4} >
              <FormControl fullWidth disabled={!selectedType}>
                <InputLabel>Course Name</InputLabel>
                <Select
                  value={selectedCourse}
                  onChange={handleCourseChange}
                  label="Course Name"
                  sx={{
                    height: 45, 
                    fontSize: 14,
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                >
                  <MenuItem value="" sx={{ height: 35, fontSize: 15, padding: "4px 8px" }}>
                    <em>Select Course</em>
                  </MenuItem>
                  {selectedType &&
                    courseData[selectedType].courses
                      .filter(
                        (course) =>
                          course !==
                          "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)"
                      )
                      .map((course) => (
                        <MenuItem key={course} value={course} sx={{ height: 35, fontSize: 14, padding: "4px 8px" }}>
                          {course}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Branch */}
            <Grid item xs={12} md={4}>
              <FormControl fullWidth disabled={!selectedCourse}>
                <InputLabel>Branch</InputLabel>
                <Select
                  value={selectedBranch}
                  onChange={handleBranchChange}
                  label="Branch"
                  sx={{
                    height: 45, 
                    fontSize: 14,
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                >
                  <MenuItem value="" sx={{ height: 35, fontSize: 15, padding: "4px 8px" }}>
                    <em>Select Branch</em>
                  </MenuItem>
                  {selectedCourse &&
                    courseData[selectedType].branches[selectedCourse].map(
                      (branch) => (
                        <MenuItem key={branch} value={branch}sx={{ height: 35, fontSize: 14, padding: "4px 8px" }}>
                          {branch}
                        </MenuItem>
                      )
                    )}
                </Select>
              </FormControl>
            </Grid>

            {/* Search Button */}
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" mt={2}>
                <Button
                  variant="contained"
                  onClick={handleSearch}
                  disabled={!isValid || searchLoading}
                  sx={{
                    minWidth: 100,
                    // backgroundColor: "#264653",
                    // "&:hover": {
                    //   backgroundColor: "#1d3946",
                    // },
                  }}
                >
                  {searchLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Continue"
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}

      {eligible && (
        <E_eligibility_Form
          eligible={eligible}
          eligibilityGradPer={eligibilityGradPer}
          courseType={selectedType}
          courseName={selectedCourse}
          courseBranch={selectedBranch}
        />
      )}
    </>
  );
}