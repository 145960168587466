import React, { useEffect, useState } from "react";
import DownloadButton from "./Rawa";
import SearchIcon from '@mui/icons-material/Search';
import { Container, createTheme, ThemeProvider, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Checkbox, Button, Box, TablePagination } from '@mui/material';
const theme = createTheme();

const DownloadButtonnew = ({ courseType, courseBranch, college, courseName, semester, subBranch }) => {
    const [studentsData, setStudentsData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedStudents, setSelectedStudents] = useState([]);


    useEffect(() => {
        const fetchStudents = async () => {
            try {
                console.log("Fetching students...");
                const response = await fetch(
                    `https://sssutms.ac.in/apitest/admin/rawa/student_list2?courseType=${courseType}&courseBranch=${courseBranch}&courseName=${courseName}&semester=${semester}&subBranch=${subBranch}&college=${college}`
                );
                const data = await response.json();
                console.log("API Response:", data);

                if (data.success) {
                    setStudentsData(data.data);
                } else {
                    setError("Failed to fetch students");
                }
            } catch (err) {
                console.error(err);
                setError("Error fetching data");
            } finally {
                setLoading(false);
            }
        };

        fetchStudents();
    }, [courseType, courseBranch, courseName, semester, subBranch]);

    const handleCheckboxChange = (enrollmentNumber) => {
        setSelectedStudents((prevSelected) =>
            prevSelected.includes(enrollmentNumber)
                ? prevSelected.filter((id) => id !== enrollmentNumber)
                : [...prevSelected, enrollmentNumber]
        );
    };
    const handleSelectAll = () => {
        if (selectedStudents.length === students.length) {
            setSelectedStudents([]);
        } else {
            setSelectedStudents(students.map((student) => student.id));
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;
    if (!studentsData) return <p>No data available</p>;

    // Transform the API data into the format expected by DownloadButton
    const students = studentsData.map((student) => ({
        id: student.enrollmentNumber,
        name: student.name,
        father: student.fathersname,
        photo: student.photo,
        college: student.college,
        subjects: student.subjects
    }));

    const course = courseName;
    const branch = courseBranch;
    const examCenter = studentsData[0]?.examCenter || "Not Assigned";
    const examTime = `${studentsData[0]?.startTime || "N/A"} - ${studentsData[0]?.endTime || "N/A"}`;
    const startDate = "DEC-2024";
    const Semester = studentsData[0]?.semester;

    const filteredStudents = students.filter(
        (s) =>
            s.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            s.id.toString().includes(searchQuery)
    );
    const handleSearchChange = (e) => setSearchQuery(e.target.value);
    return (
        <>
            <style>
                {`
  .custom-tablecell{
    font-family:'Poppins',Sans-serif;
    color: white;
    background-color: #023047;
    font-size:13px;
    padding:5px;

    font-weight:700;
    }`}
            </style>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "100px" }}>
                <ThemeProvider theme={theme}>
                    <Container
                        className="shadow p-3 bg-body rounded"
                        style={{ width: '90%', marginLeft: '9%', backgroundColor: '#00cdac' }}
                    >
                        <DownloadButton style={{ float: 'right', marginRight: '10px' }}
                            students={students.filter((s) => selectedStudents.includes(s.id))}
                            course={course}
                            branch={branch}
                            college={college}
                            examCenter={examCenter}
                            examTime={examTime}
                            startDate={startDate}
                            Semester={Semester}
                        />
                        <Box style={{ float: 'left', marginBottom: '10px' }}>
                            <SearchIcon sx={{ mr: 1 }} />
                            <input
                                type="text"
                                placeholder="Search by ID or Name"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </Box>
                        <TableContainer component={Paper} sx={{ maxHeight: '400px', width: '100%' }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>S.No</TableCell>
                                        <TableCell>
                                            <Checkbox
                                                checked={selectedStudents.length === students.length && students.length > 0}
                                                onChange={handleSelectAll}
                                                color="primary"
                                     
                                            />
                                            All
                                        </TableCell>

                                        <TableCell>Name</TableCell>
                                        <TableCell>Father's Name</TableCell>
                                        <TableCell>Enrollment</TableCell>
                                        <TableCell>Course Branch</TableCell>
                                        <TableCell>Photo</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredStudents.map((student, index) => (
                                        <TableRow key={student.id}>
                                            <TableCell>{index + 1}</TableCell> {/* S.No */}
                                            <TableCell>
                                                <Checkbox
                                                    checked={selectedStudents.includes(student.id)}
                                                    onChange={() => handleCheckboxChange(student.id)}
                                                />
                                            </TableCell>

                                            <TableCell>{student.name}</TableCell>
                                            <TableCell>{student.father}</TableCell>
                                            <TableCell>{student.id}</TableCell>
                                            <TableCell>{student.college}</TableCell>
                                            <TableCell>
                                                <img
                                                    src={student.photo}
                                                    alt="Student"
                                                    style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '5px' }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Container>
                </ThemeProvider>

            </div>
        </>
    );
};

export default DownloadButtonnew;