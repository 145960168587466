import React from "react";
import { Link } from "react-router-dom";
import Nav from "./Nav";
import Footerpage from "./Footerpage";

function Edetails() {
  const eventsData = [
    {
      date: "FEB 22",
      day: "Saturday",
      title: "Monthly Guest Lecture On Legal Topic (School Of Law) ",
      location: "SSSUTMS , Sehore City",
      time: "12:00 PM",
      categoryLink: "/feb Guest Lecture",
      image:
        "https://images.squarespace-cdn.com/content/v1/583c3fe2b3db2bd3af6179f4/1560575911151-BUA0HQK2W6VBHG8MDWB4/guest-lecture.jpg?format=1500w",
    },
    {
      date: "Feb 28",
      day: "Friday",
      title: " National Science Day",
      location: "SSSUTMS , Sehore City",
      time: "11:00 AM",
      categoryLink: "/national",
      image:
        "https://c7.alamy.com/comp/2KYAKFE/national-science-day-february-28-related-to-chemical-liquid-scientific-medical-and-research-in-flat-cartoon-hand-drawn-templates-illustration-2KYAKFE.jpg",
    },
    {
      date: "JAN 12",
      day: "Sunday",
      title: "Awareness Program in Financial Literacy",
      location: "SSSUTMS , Sehore City",
      time: "12:00 PM",
      categoryLink: "/aware",
      image:
        "https://www.shutterstock.com/shutterstock/photos/2457202285/display_1500/stock-vector-financial-literacy-teal-word-concept-family-saving-paying-bills-personal-finance-visual-2457202285.jpg",
    },
    {
      date: "DEC 27",
      day: "Sunday",
      title: "11 Science Fair",
      location: " Bhopal City",
      time: "11:00 AM",
      categoryLink: "/11_science_fair",
      image:
      "https://as1.ftcdn.net/jpg/08/49/73/46/1000_F_849734614_2PzIrhDIDORNdiP3IhWXanc398qvyxxG.jpg",
    },
    {
      date: "DEC 22",
      day: "Saturday",
      title: "National Lok Adalat Day ",
      location: "SSSUTMS , Sehore City",
      time: "10:15 AM",
      categoryLink: "/Lok",
      image:
        "https://www.lloydlawcollege.edu.in/images/LAW/blog_img/national-lok-adalat.webp",
    },
    {
      date: "DEC 10",
      day: "Monday",
      title: "Faculty Development Program",
      location: "SSSUTMS , Sehore City",
      time: "01:00 PM",
      categoryLink: "/Faculty-Development-Program",
      image:
      "https://iper.ac.in/wp-content/uploads/2017/05/IPER-FDP-2024-5.jpg",
    },
    {
      date: "NOV 26 ",
      day: "Thursday",
      title: "Constitution Day",
      location: "SSSUTMS , Sehore City",
      time: "10:15 AM",
      categoryLink: "/Constitution Day",
      image:
        "https://nlcbharat.org/wp-content/uploads/2024/03/Democracy-in-Action-Celebrating-Indias-Constitution-Day-with-a-Look-at-Governance-Milestones.jpeg",
    },
    {
      date: "NOV 11 ",
      day: "Monday  ",
      title: "Event Report Civil Engineering",
      location: "SSSUTMS , Sehore City",
      time: "12:00 AM",
      categoryLink: "/Event Report Civil Engineering",
      image:
        "https://media-cdn.socastsrm.com/wordpress/wp-content/blogs.dir/1785/files/2024/11/construction-generic.jpg",
    },
    {
      date: "NOV 25",
      day: "Friday",
      title: "Transitional Curriculum Program  ",
      location: "SSSUTMS , Sehore City",
      time: "11:00 AM",
      categoryLink: "/Transitional Curriculum Program",
      image:
        "https://www.edufever.com/wp-content/uploads/2024/02/School-of-Ayurveda-and-Siddha-Studies-768x432.webp",
    },

    {
      date: "JUN 21 ",
      day: "Saturday  ",
      title: "International Yoga Day",
      location: "SSSUTMS , Sehore City",
      time: "12:00 AM",
      categoryLink: "/yogaDay",
      image:
        "https://www.ajipt.org/wp-content/uploads/2024/06/yogaday-1024x576.png",
    },
  ];

  return (
    <>
      <Nav />
      <div style={{ paddingBottom: "100px" }}>
        <style>
          {`
            h1 { font-size: 44px; line-height: 1.5; letter-spacing: -0.025em; }
            h2 { margin: 20px 0 10px; font-size: 32px; line-height: 36px; font-weight: 700; }
            h3 { margin: 0 0 5px; font-size: 24px; line-height: 30px; font-weight: 700; }
            h4 { margin: 0 0 5px; font-size: 18px; line-height: 24px; font-weight: 700; }
            p { margin: 0; padding: 0; }
            a { color: #168dd9; }
            a:hover { text-decoration: underline; }
            
            .container {
              width: 95%;
              margin: 0 auto;
              padding: 0 20px;
            }
            .content-column {
              width: 90%;
              max-width: 1200px;
              margin: 0 auto;
            }
            .list-title {
              color: #9f9f9f;
              font-size: 14px;
              text-transform: uppercase;
            }
            .events-list {
              padding: 0;
              list-style-type: none;
            }
            .event-item {
              border-bottom: 1px solid #eceeed;
              margin-bottom: 20px;
              padding: 15px 0;
              display: flex;
              align-items: center;
              gap: 20px;
            }
            .day-column {
              min-width: 80px;
              text-align: left;
            }
            .month-name {
              text-transform: uppercase;
            }
            .day-name {
              text-transform: uppercase;
              font-size: 12px;
              color: #666;
            }
            .title-column {
              flex: 1;
              min-width: 0;
            }
            .title-name {
              margin: 0;
            }
            .event-details-list {
              padding: 0 0 0 20px;
              margin: 5px 0;
              list-style-type: disc;
              color: #666;
            }
            .event-details-item {
              margin: 0;
            }
            .event-category {
              margin-top: 5px;
            }
            .event-category-title {
              color: #9f9f9f;
            }
            .event-category-name {
              display: inline-block;
              transition: all 0.3s ease; /* Smooth transition for View More */
            }
            .event-category-name:hover {
              color: #ff4500; /* Changes color on hover */
              transform: scale(1.1); /* Scales up on hover */
              text-decoration: none; /* Removes underline on hover */
            }
            .image-column {
              flex-shrink: 0;
            }
            .event-image {
              width: 220px;
              height: auto;
              border-radius: 8px;
              object-fit: cover;
              transition: all 0.3s ease; /* Smooth transition for image */
            }
            .event-image:hover {
              transform: scale(1.05); /* Slightly scales up on hover */
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds shadow on hover */
            }
            @media (max-width: 768px) {
              .event-item {
                flex-direction: column;
                align-items: flex-start;
              }
              .event-details-list {
                padding-left: 20px;
              }
              .event-image {
                width: 100%;
                max-width: 150px;
              }
              .event-image:hover {
                transform: scale(1.03); /* Slightly reduced scale for mobile */
              }
            }
          `}
        </style>

        <div className="container">
          <div className="content-column">
            <h2 className="list-title">Upcoming Events</h2>
            <ul className="events-list">
              {eventsData.map((event, index) => (
                <li key={index} className="event-item">
                  <div className="day-column">
                    <h4 className="month-name">{event.date}</h4>
                    <p className="day-name">{event.day}</p>
                  </div>
                  <div className="title-column">
                    <h3 className="title-name">{event.title}</h3>
                    <ul className="event-details-list">
                      <li className="event-details-item">{event.location}</li>
                      <li className="event-details-item">{event.time}</li>
                    </ul>
                    <div className="event-category">
                      <span className="event-category-title">Category:</span>{" "}
                      <Link to={event.categoryLink}>
                        <a className="event-category-name">View More</a>
                      </Link>
                    </div>
                  </div>
                  <div className="image-column">
                    <img
                      src={event.image}
                      alt={`${event.title} image`}
                      className="event-image"
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Footerpage />
    </>
  );
}

export default Edetails;
