import React, { useState } from "react";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";
import { Container } from "react-bootstrap";
import axios from "axios";
import { Users } from 'lucide-react';
import { Box, TableContainer, IconButton, Paper, TableRow, TableHead, TableBody, Table, ThemeProvider, createTheme } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../AdminComponent/Style.jsx";
import EditIcon from '@mui/icons-material/Edit';
import { Link } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00cdac",
    },
    secondary: {
      main: "#e6ecf0",
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

function AdminDocumentSearch() {
  const [enteredValue, setEnteredValue] = useState("");
  const [userData, setUserData] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const handleSearch = async () => {
    if (!enteredValue.trim()) {
      alert("Please enter a valid Registration Number.");
      return;
    }

    try {
      const api = process.env.REACT_APP_DOCUMENT_SEARCH + `${enteredValue}`;
      const response = await axios.get(api);
      setUserData(response.data);
      setShowTable(true);
    } catch (error) {
      console.error("API Error:", error);
      alert("Failed to fetch data. Please try again.");
    }
  };

  return (
    <>
      <AdminDashboard />
      <ThemeProvider theme={theme}>
        <style>
          {`
        *{
            font-family: 'Poppins', sans-serif !important;
        }
        h4 {
            font-family: 'Poppins', sans-serif !important;
            font-weight:700;
        }
        .submit-button {
          background-color: #004e92;
          color: white;
          padding: 10px;
          font-family:'Poppins',Sans-serif;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
        .submit-button:hover {
          background-color: #003b73;
        }
      `}
        </style>

        <Container fluid style={{ marginTop: "90px", marginLeft: "120px", width: '80%' }}>
          <div className="bg-body rounded" style={{ width: "900px" }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4, gap: 2 }}>
              <Users size={32} className="text-blue-600" />
              <h4
                sx={{
                  fontWeight: 700,
                  fontFamily: "'Poppins',Sans-serif",
                  color: '#1e293b',
                  fontSize: { xs: '1.5rem', sm: '2rem' }
                }}
              >
                Student Documents Management
              </h4>
            </Box>

            <div className="d-flex flex-column mt-3">
              <label className="mb-2" style={{ fontWeight: 700, color: "#264653", fontFamily: "'Poppins',Sans-serif" }}>
                Enter Registration Number *
              </label>
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control me-2"
                  value={enteredValue}
                  onChange={(e) => setEnteredValue(e.target.value)}
                  placeholder="Enter Registration Number"
                  style={{ fontFamily: "'Poppins',Sans-serif" }}
                />
                <button
                  style={{ height: "40px", width: "100px", padding: "10px", fontFamily: "'Poppins',Sans-serif" }}
                  className="btn btn-primary"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </div>
            </div>
          </div>

          {showTable && userData.length > 0 && (
            <Container className="py-5" style={{ marginLeft: '-20px', width: '100%' }}>
              <TableContainer component={Paper} sx={{ borderRadius: '5px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', overflowX: 'auto' }}>
                <Table sx={{ minWidth: 650 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left" style={{ backgroundColor: theme.palette.secondary.main, color: "black", fontWeight: "bold" }}>Name</StyledTableCell>
                      <StyledTableCell style={{ backgroundColor: theme.palette.secondary.main, color: "black", fontWeight: "bold" }}>Registration No</StyledTableCell>
                      <StyledTableCell align="left" style={{ backgroundColor: theme.palette.secondary.main, color: "black", fontWeight: "bold" }}>Father's Name</StyledTableCell>
                      <StyledTableCell align="left" style={{ backgroundColor: theme.palette.secondary.main, color: "black", fontWeight: "bold" }}>Course Name</StyledTableCell>
                      <StyledTableCell align="left" style={{ backgroundColor: theme.palette.secondary.main, color: "black", fontWeight: "bold" }}>Course Branch</StyledTableCell>
                      <StyledTableCell align="left" style={{ backgroundColor: theme.palette.secondary.main, color: "black", fontWeight: "bold" }}>Edit</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userData.map((user, index) => (
                      <StyledTableRow key={index}>
                        {user ? (
                          <>
                            <StyledTableCell style={{ minWidth: '150px' }}>{user.name}</StyledTableCell>
                            <StyledTableCell style={{ minWidth: '80px' }} align="left">{user.randomId}</StyledTableCell>
                            <StyledTableCell style={{ minWidth: '150px' }} align="left">{user.fathersname}</StyledTableCell>
                            <StyledTableCell style={{ minWidth: '150px' }} align="left">{user.courseName}</StyledTableCell>
                            <StyledTableCell align="left">{user.courseBranch}</StyledTableCell>
                            <StyledTableCell align="left">
                              <Link
                                to={{
                                  pathname: `/erpdashboard/student/updatedocuments/${user._id}`,
                                  state: { userData },
                                }}
                              >
                                <IconButton aria-label="edit" style={{ backgroundColor: theme.palette.primary.main, color: 'white' }}>
                                  <EditIcon />
                                </IconButton>
                              </Link>
                            </StyledTableCell>
                          </>
                        ) : (
                          <StyledTableCell colSpan={5} align="center">No user data available</StyledTableCell>
                        )}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
          )}
        </Container>
      </ThemeProvider>
    </>);
}

export default AdminDocumentSearch;
