// import React from "react";
// import { Link, useLocation } from "react-router-dom";
// import checked from "../../images/checked.png";
// import logo from "../../AdminComponent/12.png";
// import jsPDF from "jspdf";
// import OnlyHeader from "../../AdminComponent/OnlyHeader";

// function Enrollement_Success() {
//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);

//   const orderId = queryParams.get("orderId");
//   const transactionTime = new Date(queryParams.get("TxDate"));
//   const bankTransactionId = queryParams.get("BankTxnId");
//   const Amount = queryParams.get("Transamount");
//   const paymentMode = queryParams.get("paymentMode");
//   const TxnID = queryParams.get("txnId");
//   const Name = queryParams.get("Name");
//   const FName = queryParams.get("FName");
//   const email = queryParams.get("email");
//   const mobile = queryParams.get("mobile");
//   const courseType = queryParams.get("courseType");
//   const courseBranch = queryParams.get("courseBranch");
//   const courseName = queryParams.get("courseName");

//   const formattedDate = transactionTime.toLocaleDateString("en-IN", {
//     day: "2-digit",
//     month: "2-digit",
//     year: "numeric",
//   });

//   const formattedTime = transactionTime.toLocaleTimeString("en-IN", {
//     hour12: true,
//     hour: "2-digit",
//     minute: "2-digit",
//   });

//   const generatePDF = () => {
//     const doc = new jsPDF();
//     doc.addImage(logo, "JPEG", 3, 5, 30, 28);
//     doc.setFont("helvetica", "bold");
//     doc.setFontSize(16);
//     doc.setTextColor(0, 0, 0);
//     doc.text("SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL ", 118, 10, {
//       align: "center",
//     });
//     doc.text("SCIENCES SEHORE", 118, 17, { align: "center" });
//     doc.setFontSize(13);
//     doc.text(
//       "[Established Under Govt. of (M.P.) & Registered UGC 2(F), 1956]",
//       118,
//       24,
//       { align: "center" }
//     );
//     doc.text(
//       "Sh-18 Bhopal-Indore Road, Opposite Oilfed Plant, Pachama,",
//       118,
//       30,
//       { align: "center" }
//     );
//     doc.setFontSize(14);
//     doc.setTextColor(255, 0, 0); // Set text color to red for the title
//     doc.text("Enrollment Fee Receipt", 118, 40, { align: "center" });

//     doc.setFontSize(12);
//     doc.setTextColor(0, 0, 0); // Set text color back to black

//     // Set the initial y position
//     let yPos = 60;

//     // Define the line height
//     const lineHeight = 10;

//     doc.text(
//       `                                    Name of Candidate  :  ${Name}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Father's Name          :  ${FName}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Email                         :  ${email}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Mobile                       :  ${mobile}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Course Type            :  ${courseType}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Course Name           :  ${courseName}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Course Branch        :  ${courseBranch}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    TXN ID                      :  ${TxnID}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    ORDER ID                :  ${orderId}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    PAYMENT MODE    :  ${paymentMode}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    BANKTXN ID           :  ${bankTransactionId}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Paid Amount           :  ${Amount}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Date & Time            :  ${formattedDate} ${formattedTime}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.save(`${Name} Enrollement Fee`);
//   };

//   return (
//     <>
//       <style>
//         {`
//                 p{
//                     margin-bottom:8px;
//                 }
//       .card {
//         background-color: #fff;
//         border: 1px solid #ddd;
//         border-radius: 8px;
//         box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//         padding: 20px;
//         width: 40%; /* Adjust as needed */
//         max-width: 800px; /* Set a maximum width if needed */
//         height:20%;
//         margin-top:80px;
//         // margin: 20px auto; /* Center the card horizontally */
//         text-align: left; /* Center text within the card */
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//       }

//       img {
//         display: block; /* Ensure the image is a block element */
//         margin: 0 auto; /* Center the image within its container */
//       }

//       h4 {
//         margin-top: 20px; /* Add margin to the top of the heading */
//       }
//       h6 {
//         margin-top: 20px; /* Add margin to the top of the heading */
//       }
//      .top{
//         text-align: center; 
//         margin-bottom:30px;
//      }
//       .bottom {
//         display: flex;
//         justify-content: center;
//         gap:60px;
//       }
//       .animated-button {
//         display: inline-block;
//         padding: 5px 40px;
//         font-size: 16px;
//         text-align: center;
//         cursor: pointer;
//         border: 2px solid transparent;
//         border-radius: 5px;
//         background: green;
//         // background: linear-gradient(to right, #FF5F6D, #FFC371);
//         color: #fff;
//         transition: all 0.3s ease;
//         box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//       }

//       .animated-button:hover {
//         transform: translateY(-2px);
//         box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
//       }
// // .h66{
// // color:black;
// // margin-top:-20px;
// // margin-left:70px;}




// .buttoon{
//  display: flex;
//  margin-top:10px ;
//  gap: 180px;
//  }

// //       .h44{
// //       font-family:sans-serif 'roboto';
// //       margin-right:50px;
// //       flex-grow: 1; /* Allows the text to take up the remaining space */
// //   margin: 0; /* Removes default margin */}

// //  .check{
// //         display:flex;
// //         align-items: left;
// //         margin-left:6px;
// //           flex-shrink: 0; 
// //   margin-right: 10px; 
// //         }

// //        .imagee{height:70px;
// //         width:70px;}


// .h66 {
//   color: black;
//   margin-top: -30px;
//   margin-left: 70px;
//   flex-shrink: 1; /* Allows the text to shrink if needed */
//   margin-right: 10px; /* Add space between the h6 and the edge of the card */
// }

// .h44 {
//   font-family: sans-serif 'Roboto';
//   margin-right: 10px;
//   flex-grow: 1;
//   padding:2px;
//   margin-top: -20px;
//   flex-shrink: 1; /* Allows the text to shrink if needed */
// }

// .check {
//   display: flex;
//   align-items: center; /* Vertically center the items */
//   margin-left: -10px;
//   margin-top:-20px
// }

// .imagee {
//   height: 70px;
//   width: 70px;
//   margin-right: 10px;
//   margin-top:20px
// }
//       @media screen and (max-width: 480px) {
//         .card {
//           width: 100%; /* Adjust width for smaller screens */
//         margin-top:110px;}


//         .h66{
// color:black;
// margin-top:-10px;
// margin-left:30px;
// font-size:13px;}




// .buttoon{
//  display: flex;
//  margin-top:10px ;
//  gap: 100px;
//  }

//       .h44{
//       font-family:sans-serif 'roboto';
//       margin-right:10px;
//       font-weight:700;
//       margin-top:-1px;}

//  .check{
//         display:flex;
//         margin-left:10px;
//          align-items: left;
//           flex-shrink: 0; 
//         }

//        .imagee{

//        height:50px;
//         width:50px;
//         margin-left:-20px}



//         p{
//         font-size:10px}

       
//       }


//        @media screen and (max-width: 600px) {
//     `}
//       </style>

//       <OnlyHeader />
//       <div
//         style={{
//           background: "#F5F5F5",
//           display: "flex",
//           height: "auto",

//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <div className="card">
//           <div className="top">
//             <div className="check">
//               <img src={checked} className="imagee" alt="check" />
//               <h4 className="h44">Thank You {Name}!</h4>
//             </div>
//             <h6 className="h66">
//               Your transaction has been completed successfully.
//             </h6>
//             <h6>
//               Date: {formattedDate} {formattedTime}
//             </h6>
//             <h5 style={{ color: "red" }}>
//               Paid Amount: <b> ₹ {Amount}</b>
//             </h5>
//           </div>
//           <div className="bottom">
//             <div>
//               <p>Father Name </p>
//               <p>Email </p>
//               <p>Conddddtact :</p>
//               <p>TXN ID</p>
//               <p> ORDER ID</p>
//               <p>PAY MODE</p>
//               <p>BANKTXN ID</p>
//               <p>COURSE NAME</p>
//               <p>COURSE BRANCH</p>
//             </div>
//             <div>
//               <p> {FName} </p>
//               <p>{email} </p>
//               <p> {mobile} </p>
//               <p style={{ fontWeight: "700" }}>{TxnID} </p>
//               <p style={{ fontWeight: "700" }}> {orderId} </p>
//               <p>{paymentMode} </p>
//               <p> {bankTransactionId} </p>
//               <p> {courseName} </p>
//               <p> {courseBranch} </p>
//             </div>
//           </div>
//           <div className="buttoon">
//             <button
//               onClick={generatePDF}
//               className="animated-button"
//               style={{ backgroundColor: "#FF7F3E" }}
//             >
//               Print
//             </button>
//             <Link to="/student/erp/studentlogin">
//               <button
//                 className="animated-button"
//                 style={{ backgroundColor: "#6295A2" }}
//               >
//                 Close
//               </button>
//             </Link>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Enrollement_Success;







import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Box,
  CardContent,
  Typography,
  Button,
  Grid,
  Container,
  Paper,
  Avatar,
  useTheme,
  useMediaQuery,
  Stack,
  Grow,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DateRangeIcon from "@mui/icons-material/DateRange";
import logo from "../../AdminComponent/12.png";
import OnlyHeader from "../../AdminComponent/OnlyHeader";
import jsPDF from "jspdf";

function Enrollement_Success() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const orderId = queryParams.get("orderId") || "ORD12345678";
  const transactionTime = new Date(queryParams.get("TxDate") || Date.now());
  const bankTransactionId = queryParams.get("BankTxnId") || "BANKTXN123456";
  const Amount = queryParams.get("Transamount") || "1200";
  const paymentMode = queryParams.get("paymentMode") || "UPI";
  const TxnID = queryParams.get("txnId") || "TXN987654321";
  const Session = queryParams.get("admissonSession")
  
  const Name = queryParams.get("Name") || "John Doe";
  const FName = queryParams.get("FName") || "Robert Doe";
  const email = queryParams.get("email") || "john.doe@example.com";
  const mobile = queryParams.get("mobile") || "9876543210";
  const courseName = queryParams.get("courseName") || "B.Tech Computer Science";
  const courseType = queryParams.get("courseType");
  const courseBranch =
    queryParams.get("courseBranch") || "Software Engineering";

  const formattedDate = transactionTime.toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const formattedTime = transactionTime.toLocaleTimeString("en-IN", {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
  });

  const generatePDF = () => {
    const doc = new jsPDF();

    // Add watermark
    doc.setFontSize(60);
    doc.setTextColor(245, 245, 245);
    doc.text("SSSUTMS", 45, 130, { angle: 45 });

    // Header with logo and university details
    doc.addImage(
      logo,
      "JPEG",
      10,
      10,
      20,
      20
    );
   
    // University name and details
    doc.setFontSize(16);
    doc.setTextColor(0, 48, 135); // Navy blue for university name
    doc.setFont("helvetica", "bold");
    doc.text("SRI SATYA SAI UNIVERSITY OF TECHNOLOGY", 105, 15, {
      align: "center",
    });
    doc.text("AND MEDICAL SCIENCES", 105, 22, { align: "center" });

    doc.setFontSize(10);
    doc.setTextColor(100, 100, 100); // Gray for secondary text
    doc.setFont("helvetica", "normal");
    doc.text(
      "[Established Under Govt. of (M.P.) & Registered UGC 2(F), 1956]",
      105,
      28,
      { align: "center" }
    );
    doc.text(
      "Sh-18 Bhopal-Indore Road, Pin Code - 466001 Pachama, Sehore",
      105,
      33,
      { align: "center" }
    );
    doc.text(
      `Academic Session ${Session || "2025-2026"} `,
      105,
      38,
      { align: "center" }
    );

    // Receipt title
    doc.setFillColor(0, 48, 135);
    doc.rect(0, 45, 210, 10, "F");
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("ENROLLMENT FEE RECEIPT", 105, 51, { align: "center" });

    // Rest of your code remains the same...
    doc.setTextColor(100, 100, 100);
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`Receipt Date: ${formattedDate}`, 15, 65);
    doc.text(`Receipt Time: ${formattedTime}`, 150, 65);

    // Main content
    const startY = 80;
    const lineHeight = 7;
    let currentY = startY;

    // Helper function for drawing rows
    const drawRow = (label, value) => {
      doc.setTextColor(100, 100, 100);
      doc.text(label, 25, currentY);
      doc.setTextColor(0, 0, 0);
      doc.text(": ", 70, currentY);
      doc.text(value, 75, currentY);
      currentY += lineHeight;
    };

    // Student details section
    doc.setFillColor(240, 240, 240);
    doc.rect(15, 70, 180, 8, "F");
    doc.setTextColor(0, 48, 135);
    doc.setFont("helvetica", "bold");
    doc.text("STUDENT DETAILS", 25, 75);
    currentY = 85;

    doc.setFont("helvetica", "normal");
    drawRow("Name", Name);
    drawRow("Father's Name", FName);
    drawRow("Email", email);
    drawRow("Mobile", mobile);
    drawRow("Course Type", courseType || "N/A");
    drawRow("Course Name", courseName);
    drawRow("Course Branch", courseBranch);

    // Payment details section
    currentY += 5;
    doc.setFillColor(240, 240, 240);
    doc.rect(15, currentY - 5, 180, 8, "F");
    doc.setTextColor(0, 48, 135);
    doc.setFont("helvetica", "bold");
    doc.text("PAYMENT DETAILS", 25, currentY);
    currentY += 10;

    doc.setFont("helvetica", "normal");
    drawRow("Transaction ID", TxnID);
    drawRow("Order ID", orderId);
    drawRow("Payment Mode", paymentMode);
    drawRow("Bank Transaction ID", bankTransactionId);

    // Amount section with box
    currentY += 5;
    doc.setFillColor(0, 48, 135);
    doc.rect(15, currentY - 5, 180, 12, "F");
    doc.setTextColor(255, 255, 255);
    doc.setFont("helvetica", "bold");
    doc.text("Amount Paid:", 25, currentY + 3);
    doc.text(`${Amount}/-`, 160, currentY + 3);

    // Footer
    doc.setTextColor(100, 100, 100);
    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text(
      "This is a computer-generated receipt and does not require a physical signature.",
      105,
      280,
      { align: "center" }
    );

    // Save the PDF
    doc.save(`${Name}_Enrollment_Fee_Receipt.pdf`);
};

  return (
    <>
      <OnlyHeader />
      <Box sx={{ minHeight: "100vh", bgcolor: "#f5f5f5", pt: 10 }}>
        <Container maxWidth="md">
          <Paper elevation={3} sx={{ borderRadius: 2, overflow: "hidden" }}>
            <Box
              sx={{
                bgcolor: "#2258a7",
                p: 2,
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <Grow
                in={true}
                timeout={500}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, type: "spring" }}
              >
                <Avatar sx={{ bgcolor: "success.main", width: 56, height: 56 }}>
                  <CheckCircleIcon fontSize="large" />
                </Avatar>
              </Grow>
              <Box>
                <Typography variant="h5" fontWeight="bold">
                  Enrollment Payment Successful!
                </Typography>
                <Typography variant="subtitle1">Thank you, {Name}!</Typography>
              </Box>
              <Box>
                <Typography
                  sx={{ alignItems: "center", justifyContent: "flex-end" }}
                  variant="h5"
                  color="success"
                  fontWeight="bold"
                >
                  ₹{Amount}
                </Typography>
              </Box>
            </Box>

            <CardContent sx={{ p: 3 }}>
              <Stack direction="row" spacing={2} justifyContent="center">
                <DateRangeIcon />
                <Typography variant="body1" color="text.secondary">
                  {formattedDate} {formattedTime}
                </Typography>
              </Stack>

              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Name
                      </Typography>
                      <Typography>{Name}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Father's Name
                      </Typography>
                      <Typography>{FName}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Email
                      </Typography>
                      <Typography>{email}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Contact
                      </Typography>
                      <Typography>{mobile}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Course Name
                      </Typography>
                      <Typography>{courseName}</Typography>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Transaction ID
                      </Typography>
                      <Typography fontWeight="500">{TxnID}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Order ID
                      </Typography>
                      <Typography fontWeight="500">{orderId}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Payment Mode
                      </Typography>
                      <Typography>{paymentMode}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Bank Transaction ID
                      </Typography>
                      <Typography>{bankTransactionId}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Course Branch
                      </Typography>
                      <Typography>{courseBranch}</Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>

              <Stack direction="row" spacing={2} justifyContent="center">
                <Button
                  variant="contained"
                  onClick={generatePDF}
                  sx={{ backgroundColor: "#2258a7" }}
          
                  startIcon={<DownloadIcon />}
                >
                  Print Receipt
                </Button>
                <Link to="/erp/studentlogin" style={{ textDecoration: "none" }}>
                  <Button variant="contained" >
                    Close
                  </Button>
                </Link>
              </Stack>
            </CardContent>
          </Paper>
        </Container>
      </Box>
    </>
  );
}

export default Enrollement_Success;
