import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Footerpage from "../HomePage/Footerpage";
import Nav from "../HomePage/Nav";

const images = [
  "https://cdn.sssutms.ac.in/media/static/Civi/e1.jpg",
  "https://cdn.sssutms.ac.in/media/static/Civi/e2.jpg",
  "https://cdn.sssutms.ac.in/media/static/Civi/e3.jpg",
  "https://cdn.sssutms.ac.in/media/static/Civi/e4.jpg",
];

const Civil = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openModal = (index) => {
    setCurrentIndex(index);
    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <>
      <Nav />
      <h5
        style={{
          color: "red",
          textAlign: "center",
          marginTop: "20px",
          textDecoration: "underline",
        }}
      >
        Event Report Civil Engineering Department
      </h5>
      <br />
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <tbody>
          {images.map((src, index) =>
            index % 6 === 0 ? (
              <tr key={index}>
                {images.slice(index, index + 6).map((imgSrc, imgIndex) => (
                  <td
                    key={imgIndex}
                    style={{ padding: "10px", textAlign: "center" }}
                  >
                    <img
                      src={imgSrc}
                      alt={`Event ${index + imgIndex + 1}`}
                      style={{ width: "100%", height: "180px", cursor: "pointer" }}
                      onClick={() => openModal(index + imgIndex)}
                    />
                  </td>
                ))}
              </tr>
            ) : null
          )}
        </tbody>
      </table>

      <Modal show={showModal} onHide={closeModal} centered size="md">
        <Modal.Body style={{ position: "relative", padding: 0 }}>
          <img
            src={images[currentIndex]}
            alt={`Event ${currentIndex + 1}`}
            style={{
              width: "100%",
              height: "400px", // Increased height
              objectFit: "contain",
            }}
          />
          <Button
            style={{
              position: "absolute",
              top: "50%",
              padding: "10px",
              left: "10px",
              color: "#FFAF00",
              backgroundColor: "black",
              border: "none",
              fontSize: "24px",
              fontWeight: "bolder",
              transform: "translateY(-50%)",
              opacity: 0.8,
            }}
            onClick={handlePrev}
          >
            {"<"}
          </Button>
          <Button
            style={{
              position: "absolute",
              top: "50%",
              padding: "10px",
              right: "10px",
              color: "#FFAF00",
              backgroundColor: "black",
              border: "none",
              fontSize: "24px",
              transform: "translateY(-50%)",
              opacity: 0.8,
            }}
            onClick={handleNext}
          >
            {">"}
          </Button>
          <Button
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              height: "40px",
              width: "40px",
              opacity: 0.8,
              backgroundColor: "black",
              border: "none",
              color: "#FABC3F",
              fontSize: "15px",
            }}
            onClick={closeModal}
          >
            X
          </Button>
        </Modal.Body>
      </Modal>

      <Footerpage />
    </>
  );
};

export default Civil;
