import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  Box,
  CircularProgress,
  Alert,
  Divider,
  Chip
} from '@mui/material';
import { Receipt, School, CreditCard } from '@mui/icons-material';
import OnlyHeader from "../../AdminComponent/OnlyHeader";

function EnrollmentPay() {
  const [loading, setLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);

  const userData = JSON.parse(sessionStorage.getItem("currentUser"));

  const { email, _id: id, mobile, name, courseName, courseBranch } = userData;

  useEffect(() => {
    console.log(totalAmount, "total amount");
  }, [totalAmount]);

  useEffect(() => {
    fetchFeeInformation();
  }, []);

  const calculateTotalAmount = () => {
    let calculatedAmount;

    if (courseName === "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)") {
      calculatedAmount = 11480;
    } else if (courseName === "BACHELOR OF HOMEOPATHY MEDICINE AND SURGERY") {
      calculatedAmount = 5480;
    } else if (courseName === "MD(HOMEOPATHY)") {
      calculatedAmount = 5480;
    } else if (courseName === "BACHELOR OF AYURVEDIC MEDICINE AND SURGERY") {
      calculatedAmount = 480;
    } else {
      calculatedAmount = 1200;
    }

    setTotalAmount(calculatedAmount);
  };

  const fetchFeeInformation = () => {
    const sessionYear = new Date().getFullYear();
    calculateTotalAmount(); // Fallback calculation
  };

  // Helper functions for form submission
  function isDate(val) {
    return Object.prototype.toString.call(val) === "[object Date]";
  }

  function isObj(val) {
    return typeof val === "object";
  }

  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val;
    }
  }

  function buildForm({ action, params }) {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", action);

    Object.keys(params)?.forEach((key) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", stringifyValue(params[key]));
      form.appendChild(input);
    });
    return form;
  }

  function post(details) {
    const form = buildForm(details);
    document.body.appendChild(form);
    setLoading(false);
    form.submit();
    form.remove();
  }

  const getData = (data) => {
    setLoading(false);
    return fetch(`https://sssutms.ac.in/apitest/enrollment/payment`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .catch((err) => console.log(err));
  };

  const handlePayment = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      getData({ amount: totalAmount, email, mobile, id, name }).then((response) => {
        var information = {
          action: "https://securegw.paytm.in/order/process",
          params: response,
        };
        post(information);
      });
    }, 1000);
  };

  return (
    <>
      <OnlyHeader />
      <Box
        sx={{
          minHeight: '100vh',
          background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
          pt: 4
        }}
      >
        <Container maxWidth="md" sx={{ py: 4 }}>
          <Paper
            elevation={3}
            sx={{
              p: 3.5,
              // mt: -1,
              borderRadius: 2,
              background: 'rgba(255, 255, 255, 0.95)'
            }}
          >
            <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
              <School color="primary" sx={{ fontSize: 40 }} />
              <Typography variant="h4" component="h1" color="primary">
                Enrollment Payment
              </Typography>
            </Box>

            <Alert severity="info" sx={{ mb: 3 }}>
              Dear <strong>{name}</strong>, your details have been updated successfully. Please complete the enrollment fee payment to proceed.
            </Alert>

            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Course Details
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Course: <strong>{courseName}</strong>
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Branch: <strong>{courseBranch}</strong>
              </Typography>
            </Box>

            <Divider sx={{ my: 3 }}>
              <Chip icon={<Receipt />} label="Fee Details" />
            </Divider>

            <TableContainer component={Paper} variant="outlined" sx={{ mb: 4 }}>
              <Table>
                <TableBody>
                  {["BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)", "BACHELOR OF HOMEOPATHY MEDICINE AND SURGERY", "BACHELOR OF AYURVEDIC MEDICINE AND SURGERY"].includes(courseName) ? (
                    <>
                      <TableRow>
                        <TableCell>Enrollment Fee</TableCell>
                        <TableCell align="right">
                          ₹ {courseName === "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)" ? "11,000" : "5,000"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Portal Charges</TableCell>
                        <TableCell align="right">₹ 480</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>Total Amount</TableCell>
                        <TableCell align="right" sx={{ fontWeight: 'bold', color: 'primary.main', fontSize: '1.2rem' }}>
                          ₹ {totalAmount.toLocaleString()}
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    <>
                      <TableRow>
                        <TableCell>Enrollment Fee</TableCell>
                        <TableCell align="right">₹ {totalAmount.toLocaleString()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>Total Amount</TableCell>
                        <TableCell align="right" sx={{ fontWeight: 'bold', color: 'primary.main', fontSize: '1.2rem' }}>
                          ₹ {totalAmount.toLocaleString()}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                size="large"
                disabled={loading}
                onClick={handlePayment}
                startIcon={loading ? <CircularProgress size={20} /> : <CreditCard />}
                sx={{
                  px: 4,
                  py: 1.5,
                  background: 'linear-gradient(45deg, #1e88e5 30%, #1976d2 90%)',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #1976d2 30%, #1565c0 90%)',
                  }
                }}
              >
                {loading ? 'Processing...' : 'Pay Now'}
              </Button>
            </Box>
          </Paper>
        </Container>
      </Box>
    </>
  );
}

export default EnrollmentPay;