// import React, { useState } from "react";
// import { Container, Button, Spinner } from "react-bootstrap";
// import { Grid } from "@mui/material";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
// import { useParams } from "react-router-dom";
// import swal from "sweetalert";
// import AdminDashboard from "./Admin_Dashboard/AdminDashboard";
// import "react-datepicker/dist/react-datepicker.css";

// const theme = createTheme();

// function AdminUpdate_Documents() {
//   const { id } = useParams();
//   const userID = id;
//   const [fileUrls, setFileUrls] = useState({
//     applicantPhoto: "",
//     applicantSignature: "",
//     aadharCard: "",
//     marksheet10th: "",
//     marksheet12th: "",
//     domicileCertificate: "",
//     transferCertificate: "",
//     incomeCertificate: "",
//     migrationCertificate: "",
//     undergraduateCertificate: "",

//     otherCertificate: "",
//     postgraduateCertificate: "",
//     diplomaMarksheet: "",
//     ItImarksheet: "",
//   });

//   const [errors7, setErrors7] = useState({
//     applicantPhoto: "",
//     applicantSignature: "",
//     aadharCard: "",
//     marksheet10th: "",
//     marksheet12th: "",
//     undergraduateCertificate: "",
//     ItImarksheet: "",
//     diplomaMarksheet: "",
//     postgraduateCertificate: "",
//     domicileCertificate: "",
//     transferCertificate: "",
//     incomeCertificate: "",
//     postgraduateCertificate: "",
//     migrationCertificate: "",
//     otherCertificate: "",
//   });

//   const [uploading, setUploading] = useState({
//     applicantPhoto: false,
//     applicantSignature: false,
//     aadharCard: false,
//     marksheet10th: false,
//     marksheet12th: false,
//     domicileCertificate: false,
//     transferCertificate: false,
//     incomeCertificate: false,
//     migrationCertificate: false,
//     undergraduateCertificate: false,
//     otherCertificate: false,
//     postgraduateCertificate: false,
//     diplomaMarksheet: false,
//     ItImarksheet: false,
//   });

//   const handleFileChange = async (fieldName, e) => {
//     const file = e.target.files[0];

//     if (!file) return;

    
//     setUploading((prevUploading) => ({
//       ...prevUploading,
//       [fieldName]: true,
//     }));

//     try {
//       const formData = new FormData();
//       formData.append("file", file);
//       formData.append("upload_preset", "upload");

//       const cloudinaryResponse = await fetch(
//         `https://api.cloudinary.com/v1_1/dszawxz7y/image/upload`,
//         {
//           method: "POST",
//           body: formData,
//         }
//       );
//       if (!cloudinaryResponse.ok) {
//         throw new Error("Image upload failed");
//       }
//       const cloudinaryData = await cloudinaryResponse.json();
//       const fileUrl = cloudinaryData.secure_url;

//       setFileUrls((prevFileUrls) => ({
//         ...prevFileUrls,
//         [fieldName]: fileUrl,
//       }));

//       setErrors7((prevErrors) => ({
//         ...prevErrors,
//         [fieldName]: "",
//       }));
//     } catch (error) {
//       console.error("Error handling file change:", error);
//       setErrors7((prevErrors) => ({
//         ...prevErrors,
//         [fieldName]: "Image upload failed. Please try again.",
//       }));
//     } finally {
//       setUploading((prevUploading) => ({
//         ...prevUploading,
//         [fieldName]: false,
//       }));
//     }
//   };

//   const HandleUpdateData = async () => {
//     const allBlank = Object.values(fileUrls).every((url) => url === "");

//     if (allBlank) {
//       swal({
//         title: "Error",
//         text: "No fields are updated. Please select at least one document.",
//         icon: "error",
//         button: "OK",
//       });
//       return;
//     }

//     try {
//       const response = await fetch(
//         "https://sssutms.ac.in/apitest/admin/updatedocuments2",
//         {
//           method: "PUT",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             userID: userID,
//             fileUrls,
//           }),
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Update failed");
//       }

//       const data = await response.json();

//       if (data) {
//         swal({
//           title: "Success",
//           text: "Documents updated successfully!",
//           icon: "success",
//           button: "OK",
//         }).then(() => {
//           // Clear all fields after successful update
//           setFileUrls({
//             applicantPhoto: "",
//             applicantSignature: "",
//             aadharCard: "",
//             marksheet10th: "",
//             marksheet12th: "",
//             domicileCertificate: "",
//             transferCertificate: "",
//             incomeCertificate: "",
//             migrationCertificate: "",
//             undergraduateCertificate: "",
//             otherCertificate: "",
//             postgraduateCertificate: "",
//             diplomaMarksheet: "",
//             ItImarksheet: "",
//           });
//         });
//       }
//     } catch (error) {
//       console.error("Error updating documents:", error);
//       swal({
//         title: "Error",
//         text: "There was an error updating the documents. Please try again.",
//         icon: "error",
//         button: "OK",
//       });
//     }
//   };

//   return (
//     <div>
//       <AdminDashboard />
//       <ThemeProvider theme={theme}>
//         {/* /////////////////////////////////////////////DOCUMENTS & PHOTOS/////////////////////////////////////////// */}
//         <Container
//           className="shadow p-3 bg-body rounded"
//           style={{
//             width: "100%",
//             backgroundColor: "#e8e5d1",
//             marginTop: "80px",
//           }}
//         >
//           <h3
//             style={{
//               fontWeight: "inherit",
//               backgroundImage: "linear-gradient(to right, #004e92, #990033)",
//               borderRadius: "10px",
//               color: "whitesmoke",
//               padding: "5px",
//             }}
//           >
//             Update Documents
//           </h3>
//           <br></br>

//           <Grid container>
//             <Grid item xs={12}>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <div style={{ display: "flex" }}>
//                   <h5>Applicant's Photo</h5>
//                 </div>

//                 <div style={{ display: "flex" }}>
//                   <input
//                     type="file"
//                     placeholder="choose"
//                     onChange={(e) => handleFileChange("applicantPhoto", e)}
//                   ></input>
//                   <h6 style={{ backgroundColor: "#ffe6e6" }}>Maximum 1000KB</h6>
//                 </div>
//               </div>
//               {errors7.applicantPhoto && (
//                 <div style={{ color: "red" }}>{errors7.applicantPhoto}</div>
//               )}
//               <hr></hr>
//             </Grid>
//             <Grid item xs={12}>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <div style={{ display: "flex" }}>
//                   <h5>Applicant's Signature </h5>
//                 </div>
//                 <div style={{ display: "flex" }}>
//                   <input
//                     type="file"
//                     placeholder="choose"
//                     onChange={(e) => handleFileChange("applicantSignature", e)}
//                   ></input>
//                   <h6 style={{ backgroundColor: " #ffe6e6" }}>
//                     Maximum 1000KB
//                   </h6>
//                 </div>
//               </div>
//               {errors7.applicantSignature && (
//                 <div style={{ color: "red" }}>{errors7.applicantSignature}</div>
//               )}
//               <hr></hr>
//             </Grid>
//             <Grid item xs={12}>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <div style={{ display: "flex" }}>
//                   <h5>Aadhar Card</h5>
//                 </div>

//                 <div style={{ display: "flex" }}>
//                   <input
//                     type="file"
//                     placeholder="choose"
//                     onChange={(e) => handleFileChange("aadharCard", e)}
//                   ></input>
//                   <h6 style={{ backgroundColor: "#ffe6e6" }}>Maximum 1000KB</h6>
//                 </div>
//               </div>
//               {errors7.aadharCard && (
//                 <div style={{ color: "red" }}>{errors7.aadharCard}</div>
//               )}
//               <hr></hr>
//             </Grid>
//             <Grid item xs={12}>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <div style={{ display: "flex" }}>
//                   <h5>Marksheet 10th</h5>
//                 </div>

//                 <div style={{ display: "flex" }}>
//                   <input
//                     type="file"
//                     placeholder="choose"
//                     onChange={(e) => handleFileChange("marksheet10th", e)}
//                   ></input>
//                   <h6 style={{ backgroundColor: "#ffe6e6" }}>Maximum 1000KB</h6>
//                 </div>
//               </div>
//               {errors7.marksheet10th && (
//                 <div style={{ color: "red" }}>{errors7.marksheet10th}</div>
//               )}
//               <hr></hr>
//             </Grid>
//             <Grid item xs={12}>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <div style={{ display: "flex" }}>
//                   <h5>Marksheet 12th</h5>
//                 </div>

//                 <div style={{ display: "flex" }}>
//                   <input
//                     type="file"
//                     placeholder="choose"
//                     onChange={(e) => handleFileChange("marksheet12th", e)}
//                   ></input>
//                   <h6 style={{ backgroundColor: "#ffe6e6" }}>Maximum 1000KB</h6>
//                 </div>
//               </div>
//               {errors7.marksheet12th && (
//                 <div style={{ color: "red" }}>{errors7.marksheet12th}</div>
//               )}
//               <hr></hr>
//             </Grid>
//             <Grid item xs={12}>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <div style={{ display: "flex" }}>
//                   <h5>Marksheet UG</h5>
//                 </div>

//                 <div style={{ display: "flex" }}>
//                   <input
//                     type="file"
//                     placeholder="choose"
//                     onChange={(e) =>
//                       handleFileChange("undergraduateCertificate", e)
//                     }
//                   ></input>
//                   <h6 style={{ backgroundColor: "#ffe6e6" }}>Maximum 1000KB</h6>
//                 </div>
//               </div>
//               {errors7.undergraduateCertificate && (
//                 <div style={{ color: "red" }}>
//                   {errors7.undergraduateCertificate}
//                 </div>
//               )}
//               <hr></hr>
//             </Grid>

//             <Grid item xs={12}>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <div style={{ display: "flex" }}>
//                   <h5>Diploma Marksheet</h5>
//                 </div>

//                 <div style={{ display: "flex" }}>
//                   <input
//                     type="file"
//                     placeholder="choose"
//                     onChange={(e) => handleFileChange("diplomaMarksheet", e)}
//                   ></input>
//                   <h6 style={{ backgroundColor: "#ffe6e6" }}>Maximum 1000KB</h6>
//                 </div>
//               </div>
//               {errors7.diplomaMarksheet && (
//                 <div style={{ color: "red" }}>{errors7.diplomaMarksheet}</div>
//               )}
//               <hr></hr>
//             </Grid>

//             <Grid item xs={12}>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <div style={{ display: "flex" }}>
//                   <h5>ITI Marksheet</h5>
//                 </div>

//                 <div style={{ display: "flex" }}>
//                   <input
//                     type="file"
//                     placeholder="choose"
//                     onChange={(e) => handleFileChange("ItImarksheet", e)}
//                   ></input>
//                   <h6 style={{ backgroundColor: "#ffe6e6" }}>Maximum 1000KB</h6>
//                 </div>
//               </div>
//               {errors7.ItImarksheet && (
//                 <div style={{ color: "red" }}>{errors7.ItImarksheet}</div>
//               )}
//               <hr></hr>
//             </Grid>

//             <Grid item xs={12}>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <div style={{ display: "flex" }}>
//                   <h5>Marksheet PG</h5>
//                 </div>

//                 <div style={{ display: "flex" }}>
//                   <input
//                     type="file"
//                     placeholder="choose"
//                     onChange={(e) =>
//                       handleFileChange("postgraduateCertificate", e)
//                     }
//                   ></input>
//                   <h6 style={{ backgroundColor: "#ffe6e6" }}>Maximum 1000KB</h6>
//                 </div>
//               </div>
//               {errors7.postgraduateCertificate && (
//                 <div style={{ color: "red" }}>
//                   {errors7.postgraduateCertificate}
//                 </div>
//               )}
//               <hr></hr>
//             </Grid>
//             <Grid item xs={12}>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <div style={{ display: "flex" }}>
//                   <h5>Domicile Certificate</h5>
//                 </div>

//                 <div style={{ display: "flex" }}>
//                   <input
//                     type="file"
//                     placeholder="choose"
//                     onChange={(e) => handleFileChange("domicileCertificate", e)}
//                   ></input>
//                   <h6 style={{ backgroundColor: "#ffe6e6" }}>Maximum 1000KB</h6>
//                 </div>
//               </div>
//               {errors7.domicileCertificate && (
//                 <div style={{ color: "red" }}>
//                   {errors7.domicileCertificate}
//                 </div>
//               )}
//               <hr></hr>
//             </Grid>
//             <Grid item xs={12}>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <div style={{ display: "flex" }}>
//                   <h5>Transfer Certificate</h5>
//                 </div>

//                 <div style={{ display: "flex" }}>
//                   <input
//                     type="file"
//                     placeholder="choose"
//                     onChange={(e) => handleFileChange("transferCertificate", e)}
//                   ></input>
//                   <h6 style={{ backgroundColor: "#ffe6e6" }}>Maximum 1000KB</h6>
//                 </div>
//               </div>
//               {errors7.transferCertificate && (
//                 <div style={{ color: "red" }}>
//                   {errors7.transferCertificate}
//                 </div>
//               )}
//               <hr></hr>
//             </Grid>
//             <Grid item xs={12}>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <div style={{ display: "flex" }}>
//                   <h5>Income Certificate</h5>
//                 </div>

//                 <div style={{ display: "flex" }}>
//                   <input
//                     type="file"
//                     placeholder="choose"
//                     onChange={(e) => handleFileChange("incomeCertificate", e)}
//                   ></input>
//                   <h6 style={{ backgroundColor: "#ffe6e6" }}>Maximum 1000KB</h6>
//                 </div>
//               </div>
//               {errors7.incomeCertificate && (
//                 <div style={{ color: "red" }}>{errors7.incomeCertificate}</div>
//               )}
//               <hr></hr>
//             </Grid>
//             <Grid item xs={12}>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <div style={{ display: "flex" }}>
//                   <h5>Migration Certificate</h5>
//                 </div>

//                 <div style={{ display: "flex" }}>
//                   <input
//                     type="file"
//                     placeholder="choose"
//                     onChange={(e) =>
//                       handleFileChange("migrationCertificate", e)
//                     }
//                   ></input>
//                   <h6 style={{ backgroundColor: "#ffe6e6" }}>Maximum 1000KB</h6>
//                 </div>
//               </div>
//               {errors7.migrationCertificate && (
//                 <div style={{ color: "red" }}>
//                   {errors7.migrationCertificate}
//                 </div>
//               )}
//               <hr></hr>
//             </Grid>
//             <Grid item xs={12}>
//               <div style={{ display: "flex", justifyContent: "space-between" }}>
//                 <div style={{ display: "flex" }}>
//                   <h5>OtherCertificate</h5>
//                 </div>

//                 <div style={{ display: "flex" }}>
//                   <input
//                     type="file"
//                     placeholder="choose"
//                     onChange={(e) => handleFileChange("otherCertificate", e)}
//                   ></input>
//                   <h6 style={{ backgroundColor: "#ffe6e6" }}>Maximum 1000KB</h6>
//                 </div>
//               </div>
//               {errors7.otherCertificate && (
//                 <div style={{ color: "red" }}>{errors7.otherCertificate}</div>
//               )}

//               <hr></hr>
//             </Grid>

//             <Grid item xs={12}>
//               <Button
//                 style={{
//                   marginLeft: "85%",
//                   padding: "7px",
//                   marginTop: "4px",
//                   backgroundImage:
//                     "linear-gradient(96deg, #ffc371 0%, #ff5f6d 100%)",
//                   color: "white",
//                   width: "15%",
//                   border: "none",
//                   borderRadius: "5px",
//                   fontSize: "14px !important",
//                 }}
//                 type="submit"
//                 onClick={HandleUpdateData}
//               >
//                 Update
//               </Button>
//             </Grid>
//           </Grid>
//         </Container>
//       </ThemeProvider>
//     </div>
//   );
// }

// export default AdminUpdate_Documents;

// /////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////

// // import React, { useState } from "react";
// // import { ThemeProvider, createTheme } from "@mui/material/styles";
// // import swal from "sweetalert";
// // import AdminDashboard from "./Admin_Dashboard/AdminDashboard";
// // import "react-datepicker/dist/react-datepicker.css";
// // import { Grid, Button } from "@mui/material";
// // const theme = createTheme();

// // function AdminUpdate_Documents({ userData }) {

// //   const userID = userData?.id;
// //   const [fileUrls, setFileUrls] = useState({
// //     applicantPhoto: "",
// //     applicantSignature: "",
// //     aadharCard: "",
// //     marksheet10th: "",
// //     marksheet12th: "",
// //     domicileCertificate: "",
// //     transferCertificate: "",
// //     incomeCertificate: "",
// //     migrationCertificate: "",
// //     undergraduateCertificate: "",

// //     otherCertificate: "",
// //     postgraduateCertificate: "",
// //     diplomaMarksheet: "",
// //     ItImarksheet: "",
// //   });

// //   const [errors7, setErrors7] = useState({
// //     applicantPhoto: "",
// //     applicantSignature: "",
// //     aadharCard: "",
// //     marksheet10th: "",
// //     marksheet12th: "",
// //     undergraduateCertificate: "",
// //     ItImarksheet: "",
// //     diplomaMarksheet: "",
// //     postgraduateCertificate: "",
// //     domicileCertificate: "",
// //     transferCertificate: "",
// //     incomeCertificate: "",
// //     postgraduateCertificate: "",
// //     migrationCertificate: "",
// //     otherCertificate: "",
// //   });

// //   const [uploading, setUploading] = useState({
// //     applicantPhoto: false,
// //     applicantSignature: false,
// //     aadharCard: false,
// //     marksheet10th: false,
// //     marksheet12th: false,
// //     domicileCertificate: false,
// //     transferCertificate: false,
// //     incomeCertificate: false,
// //     migrationCertificate: false,
// //     undergraduateCertificate: false,
// //     otherCertificate: false,
// //     postgraduateCertificate: false,
// //     diplomaMarksheet: false,
// //     ItImarksheet: false,
// //   });

// //   const handleFileChange = async (fieldName, e) => {
// //     const file = e.target.files[0];

// //     if (!file) return;

// //     // Set uploading state to true for the specific field
// //     setUploading((prevUploading) => ({
// //       ...prevUploading,
// //       [fieldName]: true,
// //     }));

// //     try {
// //       const formData = new FormData();
// //       formData.append("file", file);
// //       formData.append("upload_preset", "upload");

// //       const cloudinaryResponse = await fetch(
// //         `https://api.cloudinary.com/v1_1/dszawxz7y/image/upload`,
// //         {
// //           method: "POST",
// //           body: formData,
// //         }
// //       );
// //       if (!cloudinaryResponse.ok) {
// //         throw new Error("Image upload failed");
// //       }
// //       const cloudinaryData = await cloudinaryResponse.json();
// //       const fileUrl = cloudinaryData.secure_url;

// //       //  console.log("File URL:", fileUrl);

// //       setFileUrls((prevFileUrls) => ({
// //         ...prevFileUrls,
// //         [fieldName]: fileUrl,
// //       }));

// //       setErrors7((prevErrors) => ({
// //         ...prevErrors,
// //         [fieldName]: "",
// //       }));
// //     } catch (error) {
// //       console.error("Error handling file change:", error);
// //       setErrors7((prevErrors) => ({
// //         ...prevErrors,
// //         [fieldName]: "Image upload failed. Please try again.",
// //       }));
// //     } finally {
// //       // Set uploading state back to false
// //       setUploading((prevUploading) => ({
// //         ...prevUploading,
// //         [fieldName]: false,
// //       }));
// //     }
// //   };


// //   const HandleUpdateData = async () => {

// //   const allBlank = Object.values(fileUrls).every((url) => url === "");

// //   if (allBlank) {
// //     swal({
// //       title: "Error",
// //       text: "No fields are updated. Please select at least one document.",
// //       icon: "error",
// //       button: "OK",
// //     });
// //     return;
// //   }

// //   try {
// //     const response = await fetch(
// //       "https://sssutms.ac.in/apitest/admin/updatedocuments2",
// //       {
// //         method: "PUT",
// //         headers: {
// //           "Content-Type": "application/json",
// //         },
// //         body: JSON.stringify({
// //           userID: userID,
// //           fileUrls,
// //         }),
// //       }
// //     );

// //     if (response.status === 404) {
// //       throw new Error("Student not found");
// //     } else if (!response.ok) {
// //       throw new Error("Update failed");
// //     }

// //     const data = await response.json();

// //     if (data) {
// //       swal({
// //         title: "Success",
// //         text: "Documents updated successfully!",
// //         icon: "success",
// //         button: "OK",
// //       }).then(() => {
// //         setFileUrls({
// //           applicantPhoto: "",
// //           applicantSignature: "",
// //           aadharCard: "",
// //           marksheet10th: "",
// //           marksheet12th: "",
// //           domicileCertificate: "",
// //           transferCertificate: "",
// //           incomeCertificate: "",
// //           migrationCertificate: "",
// //           undergraduateCertificate: "",
// //           otherCertificate: "",
// //           postgraduateCertificate: "",
// //           diplomaMarksheet: "",
// //           ItImarksheet: "",
// //         });
// //       });
// //     }
// //   } catch (error) {
// //     console.error("Error updating documents:", error);
// //     swal({
// //       title: "Error",
// //       text: error.message || "There was an error updating the documents. Please try again.",
// //       icon: "error",
// //       button: "OK",
// //     });
// //   }
// // };



// //   // const HandleUpdateData = async () => {
// //   //   const allBlank = Object.values(fileUrls).every((url) => url === "");

// //   //   if (allBlank) {
// //   //     swal({
// //   //       title: "Error",
// //   //       text: "No fields are updated. Please select at least one document.",
// //   //       icon: "error",
// //   //       button: "OK",
// //   //     });
// //   //     return;
// //   //   }

// //   //   try {
// //   //     const response = await fetch(
// //   //       "https://sssutms.ac.in/apitest/admin/updatedocuments2",
// //   //       {
// //   //         method: "PUT",
// //   //         headers: {
// //   //           "Content-Type": "application/json",
// //   //         },
// //   //         body: JSON.stringify({
// //   //           userID: userID,
// //   //           fileUrls,
// //   //         }),
// //   //       }
// //   //     );

// //   //     if (!response.ok) {
// //   //       throw new Error("Update failed");
// //   //     }

// //   //     const data = await response.json();

// //   //     if (data) {
// //   //       swal({
// //   //         title: "Success",
// //   //         text: "Documents updated successfully!",
// //   //         icon: "success",
// //   //         button: "OK",
// //   //       }).then(() => {
          
// //   //         setFileUrls({
// //   //           applicantPhoto: "",
// //   //           applicantSignature: "",
// //   //           aadharCard: "",
// //   //           marksheet10th: "",
// //   //           marksheet12th: "",
// //   //           domicileCertificate: "",
// //   //           transferCertificate: "",
// //   //           incomeCertificate: "",
// //   //           migrationCertificate: "",
// //   //           undergraduateCertificate: "",
// //   //           otherCertificate: "",
// //   //           postgraduateCertificate: "",
// //   //           diplomaMarksheet: "",
// //   //           ItImarksheet: "",
// //   //         });
// //   //       });
// //   //     }
// //   //   } catch (error) {
// //   //     console.error("Error updating documents:", error);
// //   //     swal({
// //   //       title: "Error",
// //   //       text: "There was an error updating the documents. Please try again.",
// //   //       icon: "error",
// //   //       button: "OK",
// //   //     });
// //   //   }
// //   // };
// //   return (
// //     <>
// //       <style>
// //         {`
// //       .shad{
// //          width: 100%;
// //             background-color: #e8e5d1;
// //             margin-top: 80px;
// //             margin-left:150px
// //             }
            
// //             .form-label1{
// //             color:black;
// //             font-size:18px;
// //             // margin-left:-10px;
// //              font-family: "Lato", sans-serif;

// //             }
// //          .form-control1 {
// //   width: 340px;
// //   // margin-left: -30px;
// //   height:40px;
// //   padding:3px;
// //   border: 1px solid #ced4da; /* Change this color as needed */
// //   border-radius:10px; /* Optional: Adjust border-radius for rounded corners */
// // }

// //             `}
// //       </style>
// //       <AdminDashboard />

// //       <ThemeProvider theme={theme}>
// //         {/* /////////////////////////////////////////////DOCUMENTS & PHOTOS/////////////////////////////////////////// */}
        
// //         <div className="shad p-3 bg-body rounded">
// //           <Grid container columnSpacing={15} style={{ marginTop: "20px" }}>
// //             <Grid item xs={3}>
// //               <div class="mb-3">
// //                 <label for="formFile" class="form-label1">
// //                   Applicant's Photo
// //                 </label>
// //                 <input
// //                   class="form-control1"
// //                   type="file"
// //                   id="formFile"
// //                   onChange={(e) => handleFileChange("applicantPhoto", e)}
// //                 />
// //               </div>
// //               {errors7.applicantPhoto && (
// //                 <div style={{ color: "red" }}>{errors7.applicantPhoto}</div>
// //               )}
// //             </Grid>

// //             <Grid item xs={3}>
// //               <div class="mb-3">
// //                 <label for="formFile" class="form-label1">
// //                   Applicant's Signature
// //                 </label>
// //                 <input
// //                   class="form-control1"
// //                   type="file"
// //                   id="formFile"
// //                   onChange={(e) => handleFileChange("applicantSignature", e)}
// //                 />
// //               </div>
// //               {errors7.applicantSignature && (
// //                 <div style={{ color: "red" }}>{errors7.applicantSignature}</div>
// //               )}
// //             </Grid>

// //             <Grid item xs={3}>
// //               <div class="mb-3">
// //                 <label for="formFile" class="form-label1">
// //                   Marksheet 10th
// //                 </label>
// //                 <input
// //                   class="form-control1"
// //                   type="file"
// //                   id="formFile"
// //                   onChange={(e) => handleFileChange("marksheet10th", e)}
// //                 />
// //               </div>
// //               {errors7.marksheet10th && (
// //                 <div style={{ color: "red" }}>{errors7.marksheet10th}</div>
// //               )}
// //             </Grid>
// //           </Grid>

// //           <Grid container columnSpacing={15}>
// //             <Grid item xs={3}>
// //               <div class="mb-3">
// //                 <label for="formFile" class="form-label1">
// //                   Marksheet 12th
// //                 </label>
// //                 <input
// //                   class="form-control1"
// //                   type="file"
// //                   id="formFile"
// //                   onChange={(e) => handleFileChange("marksheet12th", e)}
// //                 />

// //                 {errors7.marksheet12th && (
// //                   <div style={{ color: "red" }}>{errors7.marksheet12th}</div>
// //                 )}
// //               </div>
// //             </Grid>

// //             <Grid item xs={3}>
// //               <div class="mb-3">
// //                 <label for="formFile" class="form-label1">
// //                   Marksheet UG
// //                 </label>
// //                 <input
// //                   class="form-control1"
// //                   type="file"
// //                   id="formFile"
// //                   onChange={(e) =>
// //                     handleFileChange("undergraduateCertificate", e)
// //                   }
// //                 />
// //               </div>
// //               {errors7.undergraduateCertificate && (
// //                 <div style={{ color: "red" }}>
// //                   {errors7.undergraduateCertificate}
// //                 </div>
// //               )}
// //             </Grid>

// //             <Grid item xs={3}>
// //               <div class="mb-3">
// //                 <label for="formFile" class="form-label1">
// //                   Diploma Marksheet
// //                 </label>
// //                 <input class="form-control1" type="file" id="formFile" />
// //               </div>
// //             </Grid>
// //           </Grid>

// //           <Grid container columnSpacing={15}>
// //             <Grid item xs={3}>
// //               <div class="mb-3">
// //                 <label for="formFile" class="form-label1">
// //                   Marksheet PG
// //                 </label>
// //                 <input class="form-control1" type="file" id="formFile" />
// //               </div>
// //             </Grid>

// //             <Grid item xs={3}>
// //               <div class="mb-3">
// //                 <label for="formFile" class="form-label1">
// //                   Domicile Certificate
// //                 </label>
// //                 <input class="form-control1" type="file" id="formFile" />
// //               </div>
// //             </Grid>

// //             <Grid item xs={3}>
// //               <div class="mb-3">
// //                 <label for="formFile" class="form-label1">
// //                   Transfer Certificate
// //                 </label>
// //                 <input class="form-control1" type="file" id="formFile" />
// //               </div>
// //             </Grid>
// //           </Grid>

// //           <Grid container columnSpacing={15}>
// //             <Grid item xs={3}>
// //               <div class="mb-3">
// //                 <label for="formFile" class="form-label1">
// //                   Aadhar Card
// //                 </label>
// //                 <input
// //                   class="form-control1"
// //                   type="file"
// //                   id="formFile"
// //                   onChange={(e) => handleFileChange("aadharCard", e)}
// //                 />
// //               </div>
// //               {errors7.aadharCard && (
// //                 <div style={{ color: "red" }}>{errors7.aadharCard}</div>
// //               )}
// //             </Grid>
// //             <Grid item xs={3}>
// //               <div class="mb-3">
// //                 <label for="formFile" class="form-label1">
// //                   ITI Marksheet
// //                 </label>
// //                 <input
// //                   type="file"
// //                   placeholder="choose"
// //                   onChange={(e) => handleFileChange("ItImarksheet", e)}
// //                 />
// //               </div>
// //               {errors7.ItImarksheet && (
// //                 <div style={{ color: "red" }}>{errors7.ItImarksheet}</div>
// //               )}
// //             </Grid>
// //             <Grid item xs={3}>
// //               <div class="mb-3">
// //                 <label for="formFile" class="form-label1">
// //                   Income Certificate
// //                 </label>
// //                 <input
// //                   class="form-control1"
// //                   type="file"
// //                   id="formFile"
// //                   onChange={(e) => handleFileChange("migrationCertificate", e)}
// //                 />
// //               </div>
// //               {errors7.incomeCertificate && (
// //                 <div style={{ color: "red" }}>{errors7.incomeCertificate}</div>
// //               )}
// //             </Grid>
// //           </Grid>

// //           <Grid container columnSpacing={15}>
// //             <Grid item xs={3}>
// //               <div class="mb-3">
// //                 <label for="formFile" class="form-label1">
// //                   Migration Certificate
// //                 </label>
// //                 <input
// //                   class="form-control1"
// //                   type="file"
// //                   id="formFile"
// //                   onChange={(e) => handleFileChange("migrationCertificate", e)}
// //                 />
// //               </div>
// //               {errors7.migrationCertificate && (
// //                 <div style={{ color: "red" }}>
// //                   {errors7.migrationCertificate}
// //                 </div>
// //               )}
// //             </Grid>

// //             <Grid item xs={3}>
// //               <div class="mb-3">
// //                 <label for="formFile" class="form-label1">
// //                   Other Certificate
// //                 </label>
// //                 <input
// //                   class="form-control1"
// //                   type="file"
// //                   id="formFile"
// //                   onChange={(e) => handleFileChange("otherCertificate", e)}
// //                 />
// //               </div>
// //               {errors7.otherCertificate && (
// //                 <div style={{ color: "red" }}>{errors7.otherCertificate}</div>
// //               )}
// //             </Grid>

// //             <Grid item xs={3}>
// //               <Button
// //                 style={{
// //                   marginLeft: "100px",
// //                   padding: "7px",
// //                   marginTop: "20px",
// //                   backgroundColor: "#243642",
// //                   color: "white",
// //                   width: "200px",
// //                   border: "none",
// //                   borderRadius: "5px",
// //                   fontSize: "13px !important",
// //                 }}
// //                 type="submit"
// //                 onClick={HandleUpdateData}
// //               >
// //                 Update
// //               </Button>
// //             </Grid>
// //           </Grid>
// //         </div>
// //       </ThemeProvider>
// //     </>
// //   );
// // }

// // export default AdminUpdate_Documents;


///////////////////AdminUpdate_Documents//////////////////

import React, { useState } from "react";
import { Container, Button, Spinner } from "react-bootstrap";
import { Grid } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";
import "react-datepicker/dist/react-datepicker.css";

const theme = createTheme();

function AdminUpdate_Documents() {
  const { id } = useParams();
  const userID = id;
  const [fileUrls, setFileUrls] = useState({
    applicantPhoto: "",
    applicantSignature: "",
    aadharCard: "",
    marksheet10th: "",
    marksheet12th: "",
    domicileCertificate: "",
    transferCertificate: "",
    incomeCertificate: "",
    migrationCertificate: "",
    undergraduateCertificate: "",
    otherCertificate: "",
    postgraduateCertificate: "",
    diplomaMarksheet: "",
    ItImarksheet: "",
  });

  const [errors7, setErrors7] = useState({
    applicantPhoto: "",
    applicantSignature: "",
    aadharCard: "",
    marksheet10th: "",
    marksheet12th: "",
    undergraduateCertificate: "",
    ItImarksheet: "",
    diplomaMarksheet: "",
    postgraduateCertificate: "",
    domicileCertificate: "",
    transferCertificate: "",
    incomeCertificate: "",
    migrationCertificate: "",
    otherCertificate: "",
  });

  const [uploading, setUploading] = useState({
    applicantPhoto: false,
    applicantSignature: false,
    aadharCard: false,
    marksheet10th: false,
    marksheet12th: false,
    domicileCertificate: false,
    transferCertificate: false,
    incomeCertificate: false,
    migrationCertificate: false,
    undergraduateCertificate: false,
    otherCertificate: false,
    postgraduateCertificate: false,
    diplomaMarksheet: false,
    ItImarksheet: false,
  });

  const handleFileChange = async (fieldName, e) => {
    const file = e.target.files[0];

    if (!file) return;

    setUploading((prevUploading) => ({
      ...prevUploading,
      [fieldName]: true,
    }));

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "upload");

      const cloudinaryResponse = await fetch(
        "https://api.cloudinary.com/v1_1/dszawxz7y/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );
      if (!cloudinaryResponse.ok) {
        throw new Error("Image upload failed");
      }
      const cloudinaryData = await cloudinaryResponse.json();
      const fileUrl = cloudinaryData.secure_url;

      setFileUrls((prevFileUrls) => ({
        ...prevFileUrls,
        [fieldName]: fileUrl,
      }));

      setErrors7((prevErrors) => ({
        ...prevErrors,
        [fieldName]: "",
      }));
    } catch (error) {
      console.error("Error handling file change:", error);
      setErrors7((prevErrors) => ({
        ...prevErrors,
        [fieldName]: "Image upload failed. Please try again.",
      }));
    } finally {
      setUploading((prevUploading) => ({
        ...prevUploading,
        [fieldName]: false,
      }));
    }
  };

  const handleUpdateData = async () => {
    const allBlank = Object.values(fileUrls).every((url) => url === "");

    if (allBlank) {
      swal({
        title: "Error",
        text: "No fields are updated. Please select at least one document.",
        icon: "error",
        button: "OK",
      });
      return;
    }

    try {
      const response = await fetch(
        "https://sssutms.ac.in/apitest/admin/updatedocuments2",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userID: userID,
            fileUrls,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Update failed");
      }

      const data = await response.json();

      if (data) {
        swal({
          title: "Success",
          text: "Documents updated successfully!",
          icon: "success",
          button: "OK",
        }).then(() => {
          // Clear all fields after successful update
          setFileUrls({
            applicantPhoto: "",
            applicantSignature: "",
            aadharCard: "",
            marksheet10th: "",
            marksheet12th: "",
            domicileCertificate: "",
            transferCertificate: "",
            incomeCertificate: "",
            migrationCertificate: "",
            undergraduateCertificate: "",
            otherCertificate: "",
            postgraduateCertificate: "",
            diplomaMarksheet: "",
            ItImarksheet: "",
          });
        });
      }
    } catch (error) {
      console.error("Error updating documents:", error);
      swal({
        title: "Error",
        text: "There was an error updating the documents. Please try again.",
        icon: "error",
        button: "OK",
      });
    }
  };


  return (
    <>
    <style>
      {
        `
  *{
            font-family: 'Poppins', sans-serif !important;
           
        }

        `
      }
    </style>
    {/* <div>
      <AdminDashboard />
      <ThemeProvider theme={theme}>
        <Container
          className="p-3 bg-body rounded"
          style={{
            width: "100%",
            backgroundColor: "#f4f7fc",
            marginTop: "80px",
          }}
        >
         

          <Grid container spacing={4} justifyContent="center">
           
            {Object.keys(fileUrls).map((fileField) => (
              <Grid item xs={12} sm={6} md={4} key={fileField}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    padding: "15px",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    margin: "10px 0",
                    textAlign: "center",
                    fontWeight:700,
                 
                  }}
                >
                   <label
        style={{
          fontSize: "16px",
          fontWeight: 700,
          marginBottom: "8px",
        }}
      >
     
        {fileField === 'marksheet10th' ? '10th MARKSHEET' :
          fileField === 'marksheet12th' ? '12th MARKSHEET' :
          fileField.replace(/([A-Z])/g, " $1").toUpperCase()}
      </label>
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(fileField, e)}
                    style={{
                      marginBottom: "10px",
                      padding: "8px",
                      backgroundColor: "#f4f7fc",
                      border: "1px solid #ddd",
                      borderRadius: "5px",
                      fontFamily:"'Poppins',Sans-serif"
                    }}
                  />
                  <span style={{ color: "#780000", fontSize: "12px", fontFamily:"'Poppins',Sans-serif"}}>
                    Maximum 1000KB
                  </span>
                  {errors7[fileField] && (
                    <div style={{ color: "red", fontSize: "12px" ,fontFamily:"'Poppins',Sans-serif"}}>
                      {errors7[fileField]}
                    </div>
                  )}
                </div>
              </Grid>
            ))}
          </Grid>

          <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <Button
              onClick={handleUpdateData}
              style={{
                backgroundColor: "#004e92",
                color: "white",
                padding: "10px 20px",
                border: "none",
                fontSize: "16px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                display: "flex",
                alignItems: "center",
              }}
            >
              {uploading && <Spinner animation="border" size="sm" />}
              <span style={{ marginLeft: "10px" }}>Update Documents</span>
            </Button>
          </div>
        </Container>
      </ThemeProvider>
    </div> */}


<div>
  <AdminDashboard />
  <ThemeProvider theme={theme}>
    <Container
      className="p-3 bg-body rounded"
      style={{
        width: "100%",
        backgroundColor: "#f4f7fc",
        marginTop: "80px",
      }}
    >
      {/* Place the button at the top */}
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
      
      
      <h5 style={{fontWeight:700}}>UPDATE DOCUMENTS</h5>
        <Button
          onClick={handleUpdateData}
          style={{
            backgroundColor: "#004e92",
            color: "white",
            padding: "10px 20px",
            border: "none",
            fontSize: "16px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            display: "flex",
            alignItems: "center",
          }}
        >
         
          <span style={{ fontFamily: "'Poppins',Sans-serif"  }}>Update</span>
        </Button>
      </div>

      <Grid container spacing={4} justifyContent="center">
        {/* Render all the file input sections here */}
        {Object.keys(fileUrls).map((fileField) => (
          <Grid item xs={12} sm={6} md={4} key={fileField}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#fff",
                padding: "15px",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                margin: "10px 0",
                textAlign: "center",
                fontWeight: 700,
              }}
            >
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                  marginBottom: "8px",
                }}
              >
                {/* Modify the label for marksheet10th and marksheet12th */}
                {fileField === 'marksheet10th' ? '10th MARKSHEET' :
                  fileField === 'marksheet12th' ? '12th MARKSHEET' :
                  fileField.replace(/([A-Z])/g, " $1").toUpperCase()}
              </label>
              <input
                type="file"
                onChange={(e) => handleFileChange(fileField, e)}
                style={{
                  marginBottom: "10px",
                  padding: "8px",
                  backgroundColor: "#f4f7fc",
                  border: "1px solid #ddd",
                  borderRadius: "5px",
                  fontFamily: "'Poppins',Sans-serif"
                }}
              />
              <span style={{ color: "#780000", fontSize: "12px", fontFamily: "'Poppins',Sans-serif" }}>
                Maximum 1000KB
              </span>
              {errors7[fileField] && (
                <div style={{ color: "red", fontSize: "12px", fontFamily: "'Poppins',Sans-serif" }}>
                  {errors7[fileField]}
                </div>
              )}
            </div>
          </Grid>
        ))}
      </Grid>
    </Container>
  </ThemeProvider>
</div>

    </>
  );
}

export default AdminUpdate_Documents;