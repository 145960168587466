import React from "react";
import Footerpage from "../HomePage/Footerpage";
import { Link } from "react-router-dom";
import Nav from "../HomePage/Nav";

function E_Content() {
  const publications = [
    {
      title: "Vol 6 Issue 2 16 to 30 November 2023",
      url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS Vol 6 Issue 2 16 to 30 November 2023.pdf"
    },
    {
      title: "Vol 6 Issue 1 01 to 15 November 2023",
      url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS Vol 6 Issue 1 01 to 15 November 2023.pdf"
    },
    {
      title: "Vol 5 Issue 2 16 to 31 October 2023",
      url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/elarning_opt.pdf"
    },
    {
      title: "Vol 5 Issue 1 01 to 15 October 2023",
      url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS Vol 5 Issue 1 01 to 15 October 2023.pdf"
    },
    {
      title: "  Vol 4 Issue 2 16 to 30 Sep 2023 ",
      url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS Vol 4 Issue 2 16 to 30 Sep 2023.pdf"    
    },
    {
      title: " Vol 4 Issue 1 01 to 15 September 2023",
      url:  " https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS Vol 4 Issue 1 01 to 15 September 2023.pdf"  
    },
    {
      title: " Vol 3 Issue 2 16 to 31 August 2023 ",
      url:"https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS Vol 3 Issue 2 01 to 15 August 2023.pdf"     
    },
    {
      title: "    Vol 3 Issue 1 01 to 15 August 2023 ",
      url:  "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS Vol 3 Issue 1 01 to 15 August 2023.pdf"   
    },
    {
      title: "  Vol 2 Issue 2 16 to 31 July 2023 ",
      url:  "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS Vol 2 Issue 2 16 to 31 July 2023.pdf"   
    },
    {
      title: "    Vol 02/ Issue 1/ 01 -15 July 2023",
      url:  "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS Vol 2 Issue 1 01 to 15 July 2023.pdf"   
    },
    {
      title: "  Vol.01/Issue 02/16-30 June2023 ",
      url:  "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS Vol 1 Issue 2 16 to 30 June 2023.pdf"   
    },
    {
      title: "    Vol.01/Issue 1/1-15 June2023 ",
      url:  "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SSSUTMS (1).pdf"   
    },
   
    ];

    const publications2 = [
      {
        title: "Research Methodology",
        url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/definitionandtypesofresearch-100801181630-phpapp02.pdf",
      },
      {
        title: "Research Report Writing",
        url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/research Report.pdf",
      },
      {
        title: "Survey Research",
        url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SURVEY.pdf",
      },
      {
        title: "Women in Sports",
        url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/Women.pdf",
      },
      {
        title: "Physical Fitness",
        url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/3- Physical Fitness.pdf",
      },
      {
        title: "Hypotheses",
        url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/Hypotheses PPT.pdf",
      },
      {
        title: "Sources of Invalidity",
        url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SOURCES_OF_INVALIDITY12.pdf",
      },
      {
        title: "Sampling",
        url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/SAMPLING.pdf",
      },
      {
        title: "Components of Fitness",
        url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/Components of Fitness & Tests TO GO ON.pdf",
      },
      {
        title: "Anatomy and Physiology Notes",
        url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/ANATOMY_PHYSIOLOGY.pdf",
      },
      {
        title: "History, Principles & Foundation of Physical Education",
        url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/Foundation_physical_edcation.pdf",
      },
      {
        title: "Complete Guide to Sports Training",
        url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/Complete Guide to Sports Training.pdf",
      },
      {
        title: "Computer System Architecture Notes",
        url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/Computer  System Architecture.pdf",
      },
      {
        title: "Digital Electronics Notes",
        url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/Digital electronics.pdf",
      },
      {
        title: "Structural Design & Drawing-I (RCC)",
        url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/RCC.pdf",
      },
      {
        title: "Spiritual Management",
        url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/BOOK - SPRITUAL MANAGEMENT.pdf",
      },
    ];


    const publications3=[
      {
        title: "Cultural and Technical",
        url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/Preamble 2.pdf",
      },
    ]


    const publications4=[
      {
        title: "Visit of NASSCOM Regional Head",
        url: "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/E-content/NASSCOM Visit.pdf",
      },
    ]
  






  return (
    <>
      <Nav/>
      <br />
      <style>
        {`
    
    
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;
  padding: 10px;
  margin-left:-60px;
}

.grid-item {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.grid-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.grid-item a {
  text-decoration: none;
 color:black;
  font-weight: 700;
  display: inline-block;
  margin-top: 8px;
}

    .section_all {
        position: relative;
        padding-top: 20px;
        margin-left:-100px;
        padding-bottom: 80px;
        min-height: 100vh;
    
    }
   
    
    .about_icon i {
        font-size: 22px;
        height: 65px;
        width: 65px;
        line-height: 65px;
        display: inline-block;
        background: #fff;
        border-radius: 35px;
        color: #00bd2a;
        box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
         
    }
    
   
    .about_icon span {
        position: relative;
        top: -10px;
        
    }
    
    .about_content_box_all {
        padding: 28px;
    }
h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; margin-left:-30px;
}


.lli{
font-size:16px; 
margin-left:40px;
margin-top:10px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 5px 5px 0;
    text-align: center;
    width: 28px;
 
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


   .llis{
  font-size:17px; 
 margin-left:15px;
 margin-top:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}




   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
        
    }
        


    .lliss{
      font-size:15px; 
     margin-left:15px;
     margin-top:7px;
    
      
       list-style-type: none; /* Removes bullets */}
    
    

   @media (max-width: 480px) {
   .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
                
        }
   
   }
        
    
    `}
      </style>
      <section className="section_all " id="about">
        <div className="container">
          <div class="two">
            <h1>E - Content</h1>
          </div>

          

          <div className="row">
        


      <div className="col-lg-10">
            <h5
                style={{
                  color: "orange",
                  textAlign: "center",
                  fontFamily: "'poppins',sansSerif",
                }}
              >
                SSSUTMS e-Knowledge
              </h5>
              <br></br>
              <div className="grid-container">
  {publications.map((publication, index) => (
    <div className="grid-item" key={index}>
      <Link to={publication.url} target="_blank">
       
        <span style={{fontFamily:"'Poppins',Sans-serif"}}>{publication.title}</span>
      </Link>
    </div>
  ))}
</div>

              <br />

              <h5
                style={{
                  color: "orange",
                  textAlign: "center",
                  fontFamily: "'poppins',sansSerif",
                }}
              >
                SSSUTMS e-Report
              </h5>
              <br></br>

              <div className="grid-container">
  {publications4.map((publication, index) => (
    <div className="grid-item" key={index}>
      <Link to={publication.url} target="_blank">
      
        <span style={{fontFamily:"'Poppins',Sans-serif"}}  className="publication-title">{publication.title}</span>
      </Link>
    </div>
  ))}
</div>

              <br />
              <h5
                style={{
                  color: "orange",
                  textAlign: "center",
                  fontFamily: "'poppins',sansSerif",
                }}
              >
                Students Forum
              </h5>
              <br></br>

              <div className="grid-container">
  {publications3.map((publication, index) => (
    <div className="grid-item" key={index}>
      <Link to={publication.url} target="_blank">
        
        <span  style={{fontFamily:"'Poppins',Sans-serif"}} className="publication-title">{publication.title}</span>
      </Link>
    </div>
  ))}
</div>


              <br />


            </div>

            <div className="col-lg-2">
              <ul className="list-icon">
                <h5
                  style={{ textAlign: "center", textDecoration: "underline" }}
                >
                  E - Content
                </h5>
                <br />
            
                <Link to="/forms">
                  <li className="lli">
                    <a href="/forms">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Forms
                    </a>
                  </li>
                </Link>
                <Link to="/E-Content">
                  <li className="lli">
                    <a href="/E-Content">
                      <i class="fa fa-adjust" aria-hidden="true"></i>E-Content
                    </a>
                  </li>
                </Link>
                <Link to="/alumni">
                  <li className="lli">
                    <a href="/E-Content">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Alumni
                    </a>
                  </li>
                </Link>
                <Link to="/rti">
                  <li className="lli">
                    <a href="/E-Content">
                      <i class="fa fa-adjust" aria-hidden="true"></i>RTI
                    </a>
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footerpage />
    </>
  );
}

export default E_Content;
