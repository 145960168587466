import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Container,
  Button,
  ThemeProvider,
  createTheme,
  Box,
  Checkbox,
} from "@mui/material";
import swal from "sweetalert";
import SearchIcon from "@mui/icons-material/Search";
import * as XLSX from "xlsx";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "'Poppins', Sans-serif",
        },
      },
    },
  },
});


const ExamFormCheckStatus = ({
  courseType,
  courseBranch,
  courseName,
  semester,
  SubBranch,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStudents, setSelectedStudents] = useState([]);


  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://sssutms.ac.in/apitest/v1/admin/forwarded-exam-form-list?courseType=${courseType}&SubBranch=${SubBranch}&courseBranch=${courseBranch}&courseName=${courseName}&semester=${semester}`
      )
      .then((response) => {
        const sortedData = (response.data.data || []).sort((a, b) =>
          a.enrollmentNumber.localeCompare(b.enrollmentNumber)
        );
        setData(response.data.data || []);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [courseType, courseBranch, courseName, semester, SubBranch]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = data.filter(
    (item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.enrollmentNumber.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSelectStudent = (studentId) => {
    setSelectedStudents((prev) =>
      prev.includes(studentId)
        ? prev.filter((id) => id !== studentId)
        : [...prev, studentId]
    );
  };

  const handleCancelExamForm = async () => {
    if (selectedStudents.length === 0) {
      alert("Please select at least one student to cancel the exam form.");
      return;
    }

    try {
      const response = await axios.post(
        "https://sssutms.ac.in/apitest/v1/cancel-exam-form",
        {
          studentIds: selectedStudents,
          examFormId: data[0].formId,
        }
      );

      if (response.status === 200) {
        swal({
          title: "Success",
          text: `Exam form cancelled successfully`,
          icon: "success",
          buttons: "OK",
        });
        // alert("Exam form cancelled successfully.");
        setSelectedStudents([]);

        setLoading(true);
        axios
          .get(
            `https://sssutms.ac.in/apitest/v1/admin/forwarded-exam-form-list?courseType=${courseType}&SubBranch=${SubBranch}&courseBranch=${courseBranch}&courseName=${courseName}&semester=${semester}`
          )
          .then((response) => {
            setData(response.data.data || []);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setLoading(false);
          });
      }
    } catch (error) {
      console.error("Error cancelling exam form:", error);
      alert("Failed to cancel exam form. Please try again.");
    }
  };

  const exportToExcel = () => {
    const filteredData = data.filter(student => student.isFilled); // Exclude students who haven't filled the form
  
    const modifiedData = filteredData.map(({ studentId, formId, isForwarded, isFilled, isPaid, subjects, ...rest }) => {
      const formattedSubjects = {};
  
      // Separate theory and practical subjects only if subjects exist
      const theorySubjects = subjects ? subjects.filter(sub => sub.subjectType.includes("Theory")) : [];
      const practicalSubjects = subjects ? subjects.filter(sub => sub.subjectType.includes("Practical")) : [];
  
      // Sort: first all Theory, then all Practical
      const sortedSubjects = [...theorySubjects, ...practicalSubjects];
  
      // Assign each subject to a separate column dynamically
      sortedSubjects.forEach((sub, index) => {
        formattedSubjects[`Subject ${index + 1} (${sub.subjectType})`] = `${sub.subjectName} (${sub.subjectCode})`;
      });
  
      return {
        ...rest,
        isFilled: isFilled ? "FILLED" : "NOT FILLED",
        isPaid: isPaid ? "PAID" : "NOT PAID",
        isForwarded: isForwarded ? "FORWARDED" : "NOT FORWARDED",
        ...formattedSubjects, // Include subjects (or leave empty if none exist)
      };
    });
  
    const worksheet = XLSX.utils.json_to_sheet(modifiedData);
  
    // Auto-adjust column widths based on the max number of subjects
    const maxSubjects = Math.max(...filteredData.map((item) => item.subjects?.length || 0), 0);
    worksheet["!cols"] = [
      { wch: 10 }, // Example: Name
      { wch: 10 }, // Example: Email
      { wch: 10 }, // Example: Registration No
      ...Array(maxSubjects).fill({ wch: 50 }), // Dynamic subject columns
    ];
  
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Enrolled Students");
  
    // Generate Excel file
    XLSX.writeFile(workbook, "ExamFormStatus_Std.xlsx");
  };







  return (
    <>

      <ThemeProvider theme={theme}>
        <Container
          style={{ marginTop: "80px", marginLeft: "100px", width: "90%" }}
        >
          {loading ? (
            <CircularProgress style={{ margin: "20px auto", display: "block" }} />
          ) : (
            <>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "8px",
                  borderRadius: "4px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <SearchIcon sx={{ mr: 1 }} />
                  <input
                    type="text"
                    placeholder="Search by Name& Enroll"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    style={{
                      padding: "5px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                  />
                </Box>
                <Button
                  variant="outlined"
                  onClick={exportToExcel}
                  style={{
                    float: "right",
                  }}
                >
                  Export to Excel
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCancelExamForm}
                  disabled={selectedStudents.length === 0}
                >
                  Forward Cancel
                </Button>
              </Box>

              <TableContainer
                component={Paper}
                style={{
                  maxHeight: "500px",
                  overflow: "auto",
                  width: "100%",
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor: "#f0f0f0",
                        height: "10px",
                        padding: "2px",
                      }}
                    >
                      <TableCell
                        style={{

                          color: "#fff",
                          backgroundColor: "#023047",
                        }}
                      >

                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: "#fff",
                          backgroundColor: "#023047",
                        }}
                      >
                        S.No.
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: "#fff",
                          backgroundColor: "#023047",
                        }}
                      >
                        Student Name
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: "#fff",
                          backgroundColor: "#023047",
                        }}
                      >
                        Enrollment No.
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: "#fff",
                          backgroundColor: "#023047",
                        }}
                      >
                        Father's Name
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: "#fff",
                          backgroundColor: "#023047",
                        }}
                      >
                        Forwarded
                      </TableCell>

                      <TableCell
                        style={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: "#fff",
                          backgroundColor: "#023047",
                        }}
                      >
                        Form Status
                      </TableCell>

                      <TableCell
                        style={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: "#fff",
                          backgroundColor: "#023047",
                        }}
                      >
                        Fee Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ minWidth: '20px' }}>
                          <Checkbox style={{ height: '5px', width: '5px' }}
                            checked={selectedStudents.includes(item.studentId)}
                            onChange={() => handleSelectStudent(item.studentId)}
                          />
                        </TableCell>
                        <TableCell align="left">
                          {currentPage * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell style={{ fontWeight: 700, fontSize: '14px' }}>{item.name}</TableCell>
                        <TableCell>{item.enrollmentNumber}</TableCell>
                        <TableCell>{item.fathersname}</TableCell>
                        <TableCell
                          style={{
                            color: item.isForwarded ? "#809D3C" : "#A31D1D",
                            fontWeight: 700,
                          }}
                        >
                          {item.isForwarded ? "Forward" : "Not Forward"}
                        </TableCell>

                        <TableCell
                          style={{
                            color: item.isFilled ? "#809D3C" : "#A31D1D",
                            fontWeight: 700,
                          }}
                        >
                          {item.isFilled ? "Filled" : "Not Fill"}
                        </TableCell>
                        <TableCell
                          style={{
                            color: item.isPaid ? "#809D3C" : "#A31D1D",
                            fontWeight: 700,
                          }}
                        >
                          {item.isPaid ? "Paid" : "Unpaid"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Container>
      </ThemeProvider>
    </>);
};

export default ExamFormCheckStatus;