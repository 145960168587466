// ///////////////////Update al std details///////////////////////////////

// import React, { useState, useEffect } from "react";
// import { Container, Button, Form, Row, Col } from "react-bootstrap";
// import { useParams } from "react-router-dom";
// import swal from "sweetalert";
// import useFetch from "../../CUSTOM_HOOK/Usefetch";
// import AdminDashboard from "./Admin_Dashboard/AdminDashboard";
// import DatePicker from "react-datepicker";
// import { FormControl } from "react-bootstrap";

// const AdminUpdtStdt_Detail = ({ userData }) => {
//   const { id } = useParams();
//   const userID = id;

//   const [formData, setFormData] = useState({
//     branch: "",
//     assignedCollege: "",
//     courseName: "",
//     studentId: "",
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       setFormData(userData); 
//     };
//     fetchData();
//   }, [userID]);

//   const [courses, setCourses] = useState([]); // State to store courses
//   const [filteredCourses, setFilteredCourses] = useState([]); // State to store filtered courses based on courseType
//   const [selectedCourse, setSelectedCourse] = useState("");
//   const [selectedBranch, setSelectedBranch] = useState("");
//   const [colleges, setColleges] = useState([]); // State to store colleges

//   const [editableData, setEditableData] = useState({
//     branch: selectedBranch,
//     college: "",
//     studentId: userData._id,
//     courseName: selectedCourse,
//   });
//   // Fetch courses from API
//   const session = new Date().getFullYear();
//   const { data, loading, error } = useFetch(
//     `https://sssutms.ac.in/apitest/v2/admin/courses?session=${session}`
//   );
//   //   ///////////////////// Datepicker ///////////////////
// const [selectedDate, setSelectedDate] = useState(null);

// const handleDateChange = (date) => {
//   setSelectedDate(date);
// };

//   useEffect(() => {
//     if (data && data.length) {
//       setCourses(data); // Store fetched courses
//       // Filter courses based on courseType from userData
//       const filtered = data.filter(
//         (course) => course.courseType === userData.courseType // Assuming userData has courseType
//       );
//       setFilteredCourses(filtered); // Store filtered courses
//     }
//   }, [data, userData.courseType]); // Run when data or courseType changes

//   const handleEditableChange = (e) => {
//     const { name, value } = e.target;
//     setEditableData({ ...editableData, [name]: value });
//   };

//   const handleCourseChange = async (e) => {
//     const { value } = e.target;
//     setSelectedCourse(value);
//     setEditableData({
//       ...editableData,
//       courseName: value,
//       assignedCollege: value,
//     });
//     // Reset the selected branch when course changes
//     setSelectedBranch("");

//     // Fetch colleges based on selected course
//     if (value) {
//       try {
//         const response = await fetch(
//           `https://sssutms.ac.in/apitest/v1/hod/colleges/course?courseName=${value}`
//         );
//         if (response.ok) {
//           const collegeData = await response.json();
//           setColleges(collegeData);
//         } else {
//           // Handle error response
//           setColleges([]); // Clear colleges if there's an error
//         }
//       } catch (error) {
//         console.error("Error fetching colleges:", error);
//         setColleges([]); // Clear colleges on error
//       }
//     } else {
//       setColleges([]); // Clear colleges if no course is selected
//     }
//   };

//   const handleBranchChange = (e) => {
//     const { value } = e.target;
//     setSelectedBranch(value);
//     setEditableData({ ...editableData, branch: value });
//   };

//   const handleSubmit = async () => {
//     try {
//       const response = await fetch(
//         `https://sssutms.ac.in/apitest/admin/update-course`,
//         {
//           method: "PUT",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             userID,
//             formData: editableData,
//             selectedCourse,
//             selectedBranch,
//           }),
//         }
//       );

//       // Check if response status is successful (200)
//       if (response.status === 200) {
//         const responseData = await response.json(); // Parse response data

//         // Check if there are any errors in the response data
//         if (responseData.errors && responseData.errors.length > 0) {
//           swal({
//             icon: "error",
//             title: "Error",
//             text: `Error: ${responseData.errors.join(", ")}`,
//           });
//         } else {
//           swal({
//             title: "Success",
//             icon: "success",
//             text: `Data updated successfully!`,
//             button: "OK",
//           }).then(() => {
//             // Reset formData and editableData after confirmation
//             setFormData({
//               branch: "",
//               assignedCollege: "",
//               courseName: "",
//               studentId: "",
//             });
//             setEditableData({
//               branch: "",
//               college: "",
//               studentId: userData._id,
//               courseName: "",
//             });
//             setSelectedCourse("");
//             setSelectedBranch("");
//             setColleges([]); // Clear colleges
//           });
//         }
//         return;
//       }

//       // Handle other response statuses
//       const errorData = await response.json(); // Parse error data
//       swal({
//         icon: "error",
//         title: "Error",
//         text: `Error: ${
//           errorData.errors ? errorData.errors.join(", ") : "Unknown error"
//         }`,
//       });
//     } catch (error) {
//       swal({
//         title: "Error",
//         text: "An error occurred while updating data.",
//         icon: "error",
//         buttons: "OK",
//       });
//     }
//   };

//   return (
//     <>
//       <style>
//         {`
//         .custom-input{
//         font-family: "Inter", sans-serif;}`}
//       </style>
//       <AdminDashboard />
//       <Container
//         className="shadow p-3 bg-body rounded"
//         style={{ marginTop: "80px", marginLeft: "11%" }}
//       >
//         <hr />
//         <Form>
//           <Row className="mb-3">
//             <Form.Group as={Col} controlId="enrollmentNumber">
//               <Form.Label
//                 style={{ fontFamily: "Lato, sans-serif", fontWeight: 700 }}
//               >
//                 Enrollment No.
//               </Form.Label>
//               <Form.Control
//                 type="text"
//                 name="enrollmentNumber"
//                 value={formData.enrollmentNumber || "Not Generated"}

//                 className="custom-input"
//               />
//             </Form.Group>

//             <Form.Group as={Col} controlId="name">
//               <Form.Label
//                 style={{ fontFamily: "Lato, sans-serif", fontWeight: 700 }}
//               >
//                 Name
//               </Form.Label>
//               <Form.Control
//                 type="text"
//                 name="name"
//                 value={formData.name}

//                 className="custom-input"
//               />
//             </Form.Group>

//             <Form.Group as={Col} controlId="fathersname">
//               <Form.Label
//                 style={{ fontFamily: "Lato, sans-serif", fontWeight: 700 }}
//               >
//                 Father's Name
//               </Form.Label>
//               <Form.Control
//                 type="text"
//                 name="fathersname"
//                 value={formData.fathersname}

//                 className="custom-input"
//               />
//             </Form.Group>
//           </Row>

//           <Row className="mb-3">
//             <Form.Group as={Col} controlId="name">
//               <Form.Label
//                 style={{ fontFamily: "Lato, sans-serif", fontWeight: 700 }}
//               >
//                 Date of Birth
//               </Form.Label>

//               <div>
//                 <DatePicker
//                   dateFormat="dd/MM/yyyy"
//                   selected={
//                     selectedDate ||
//                     (formData?.dob ? new Date(formData?.dob) : null)
//                   }
//                   name="dob"
//                   onChange={(date) => handleDateChange(date)}
//                   required
//                   className="custom-input" // Apply your custom class here
//                   readOnly={true}
//                   customInput={<FormControl style={{ width: "159%" }} />}
//                 />
//               </div>
//             </Form.Group>

//             <Form.Group as={Col} controlId="gender">
//               <Form.Label
//                 style={{ fontFamily: "Lato, sans-serif", fontWeight: 700 }}
//               >
//                 Gender
//               </Form.Label>
//               <Form.Control
//                 type="text"
//                 name="gender"
//                 value={formData?.gender}

//                 className="custom-input"
//               />
//             </Form.Group>

//             <Form.Group as={Col} controlId="mobile">
//               <Form.Label
//                 style={{ fontFamily: "Lato, sans-serif", fontWeight: 700 }}
//               >
//                 Mobile
//               </Form.Label>
//               <Form.Control
//                 type="text"
//                 name="mobile"
//                 value={formData?.mobile}

//                 className="custom-input"
//               />
//             </Form.Group>
//           </Row>

//           {/* Third Row - Non-editable */}
//           <Row className="mb-3">
//             <Form.Group as={Col} controlId="courseName">
//               <Form.Label
//                 style={{ fontFamily: "Lato, sans-serif", fontWeight: 700 }}
//               >
//                 Course Name
//               </Form.Label>
//               <Form.Control
//                 type="text"
//                 name="courseName"
//                 value={formData?.courseName}

//                 className="custom-input"
//               />
//             </Form.Group>

//             <Form.Group as={Col} controlId="branch">
//               <Form.Label
//                 style={{ fontFamily: "Lato, sans-serif", fontWeight: 700 }}
//               >
//                 Course Branch
//               </Form.Label>
//               <Form.Control
//                 type="text"
//                 name="branch"
//                 value={formData?.courseBranch}

//                 className="custom-input"
//               />
//             </Form.Group>

//             <Form.Group as={Col} controlId="assignedCollege">
//               <Form.Label
//                 style={{ fontFamily: "Lato, sans-serif", fontWeight: 700 }}
//               >
//                 College
//               </Form.Label>
//               <Form.Control
//                 type="text"
//                 name="assignedCollege"
//                 value={formData?.assignedCollege}

//                 className="custom-input"
//               />
//             </Form.Group>
//           </Row>


//           <Button
//             style={{
//               backgroundColor: "#1E3E62",
//               fontFamily: "Lato, sans-serif",
//               height: "35px",
//               width: "100px",
//               padding: "5px",
//             }}
//             onClick={handleSubmit}
//           >
//             Update
//           </Button>
//         </Form>
//       </Container>
//     </>
//   );
// };

// export default AdminUpdtStdt_Detail;









////////////////////////////////////////////////



// import React, { useState, useEffect } from "react";
// import { Form, Row, Col, Button, Spinner, Alert, Card } from "react-bootstrap";
// import swal from "sweetalert";
// import DatePicker from "react-datepicker";
// import { FormControl } from "react-bootstrap";
// import { useParams } from "react-router-dom";

// const AdminUpdtStdt_Detail = ({ userData }) => {

//   const [courseData, setCourseData] = useState({});
//   const [selectedType, setSelectedType] = useState("");
//   const [selectedCourse, setSelectedCourse] = useState("");
//   const [selectedBranch, setSelectedBranch] = useState("");
//   const [loading, setLoading] = useState(false);
//   const session = new Date().getFullYear();


//   // Initialize form data with default values
//   const [formData, setFormData] = useState({
//     name: "",
//     fathersname: "",
//     mothersname: "",
//     gender: "",
//     email: "",
//     dob: "",
//     courseType: "",
//     courseName: "",
//     courseBranch: "",
//     qualification: "",
//     passingYear: "",
//     mobile: "",
//     category: "",
//     adhar: "",
//     samargid: "",
//     domicile: "",
//     obtainMarks: "",
//     lastPassedSubject: "",
//     LastPercentage: "",
//     academicDetails: {}, // Initialize as an object
//     abcId: "",
//     assignedCollege: "",
//   });
//   const [selectedDate, setSelectedDate] = useState(null);

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//     setFormData((prevData) => ({
//       ...prevData,
//       dob: date.toISOString().split("T")[0], // YYYY-MM-DD format
//     }));
//   };

//   // Fetch course data on component mount
//   useEffect(() => {
//     const fetchCourses = async () => {
//       setLoading(true);
//       try {
//         const response = await fetch(
//           `https://sssutms.ac.in/apitest/v2/admin/courses?session=${session}`
//         );
//         const data = await response.json();
//         const formattedData = {};
//         data.forEach((course) => {
//           if (!formattedData[course.courseType]) {
//             formattedData[course.courseType] = {
//               courses: [],
//               branches: {},
//             };
//           }
//           course.courseNames.forEach((courseName) => {
//             formattedData[course.courseType].courses.push(
//               courseName.courseName
//             );
//             formattedData[course.courseType].branches[courseName.courseName] =
//               courseName.branches.map((branch) => branch.branchName);
//           });
//         });
//         setCourseData(formattedData);
//       } catch (error) {
//         console.error("Error fetching course data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchCourses();
//   }, [session]);

//   // Populate form data when `userData` is available
//   useEffect(() => {
//     if (userData) {
//       setFormData((prevData) => ({
//         ...prevData,
//         ...userData,
//       }));
//       setSelectedType(userData.courseType || "");
//       setSelectedCourse(userData.courseName || "");
//       setSelectedBranch(userData.courseBranch || "");
//     }
//   }, [userData]);

//   // Handle input changes
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   // Handle dropdown changes
//   const handleTypeChange = (e) => {
//     setSelectedType(e.target.value);
//     setSelectedCourse("");
//     setSelectedBranch("");
//   };

//   const handleCourseChange = (e) => {
//     setSelectedCourse(e.target.value);
//     setSelectedBranch("");
//   };

//   const handleBranchChange = (e) => {
//     setSelectedBranch(e.target.value);
//   };

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     try {
//       // Prepare updated data
//       const updatedData = {
//         ...formData,
//         courseType: selectedType,
//         courseName: selectedCourse,
//         courseBranch: selectedBranch,
//       };

//       console.log("Sending updated data to backend:", updatedData);

//       // Send PATCH request to backend
//       const response = await fetch(
//         `https://sssutms.ac.in/apitest/student-details/update/${userData.randomId}`,
//         {
//           method: "PATCH",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(updatedData),
//         }
//       );

//       if (response.status === 200) {
//         const responseData = await response.json();
//         swal({
//           title: "Success",
//           text: "Student information updated successfully!",
//           icon: "success",
//           button: "OK",
//         });
//       } else {
//         const errorData = await response.json();
//         swal({
//           title: "Error",
//           text: errorData.message || "Failed to update student information",
//           icon: "error",
//           button: "OK",
//         });
//       }
//     } catch (error) {
//       console.error("Error updating student:", error);
//       swal({
//         title: "Error",
//         text: "An error occurred while updating student information",
//         icon: "error",
//         button: "OK",
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="p-4">
//       <Card>
//         <Card.Body>
//           <div
//             style={{
//               backgroundColor: "#124559",
//               width: "100%",
//               height: "40px", // Reduced height
//               borderRadius: "10px",
//             }}
//           >
//             <h6
//               style={{
//                 padding: "10px",
//                 margin: 0,
//                 marginTop: "-3px",
//                 color: "#ffff",
//                 textAlign: "center",
//                 fontFamily: "inherit",
//               }}
//             >
//               Upate Seach Student
//             </h6>
//           </div>
//           <Form onSubmit={handleSubmit} style={{ marginTop: "3%" }}>
//             {/* Row for 3 input boxes in one line */}
//             <Row className="mb-3">
//               <Col sm={4}>
//                 <Form.Group controlId="formName">
//                   <Form.Label>
//                     {" "}
//                     <label
//                       style={{
//                         color: "#370617",
//                         fontWeight: "bold",
//                         fontSize: "17px",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       Student Name *
//                     </label>
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="name"
//                     value={formData.name}
//                     onChange={handleInputChange}
//                     placeholder="Enter student's name"
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//               <Col sm={4}>
//                 <Form.Group controlId="formFathersName">
//                   <Form.Label>
//                     {" "}
//                     <label
//                       style={{
//                         color: "#370617",
//                         fontWeight: "bold",
//                         fontSize: "17px",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       Father Name *
//                     </label>{" "}
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="fathersname"
//                     value={formData.fathersname}
//                     onChange={handleInputChange}
//                     placeholder="Enter father's name"
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//               <Col sm={4}>
//                 <Form.Group controlId="formMothersName">
//                   <Form.Label>
//                     <label
//                       style={{
//                         color: "#370617",
//                         fontWeight: "bold",
//                         fontSize: "17px",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       Mother Name *
//                     </label>{" "}
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="mothersname"
//                     value={formData.mothersname}
//                     onChange={handleInputChange}
//                     placeholder="Enter mother's name"
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//             </Row>

//             {/* Row for 3 input boxes in one line */}
//             <Row className="mb-3">
//               <Col sm={4}>
//                 <Form.Group controlId="formName">
//                   <Form.Label>
//                     <label
//                       style={{
//                         color: "#370617",
//                         fontWeight: "bold",
//                         fontSize: "17px",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       Email *
//                     </label>{" "}
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="email"
//                     value={formData.email}
//                     onChange={handleInputChange}
//                     placeholder="Enter student's email"
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//               <Col sm={4}>
//                 <Form.Group controlId="formFathersName">
//                   <Form.Label>
//                     <label
//                       style={{
//                         color: "#370617",
//                         fontWeight: "bold",
//                         fontSize: "17px",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       DOB *
//                     </label>{" "}
//                   </Form.Label>
//              
//                          <div>
//                 <DatePicker
//                   dateFormat="dd/MM/yyyy"
//                   selected={
//                     selectedDate ||
//                     (formData?.dob ? new Date(formData?.dob) : null)
//                   }
//                   name="dob"
//                   onChange={handleDateChange}
//                   required
//                   className="custom-input" // Apply your custom class here

//                   customInput={<FormControl style={{ width: "159%" }} />}
//                 />
//               </div>
//                 </Form.Group>
//               </Col>
//               <Col sm={4}>
//                 <Form.Group controlId="formMothersName">
//                   <Form.Label>
//                     {" "}
//                     <label
//                       style={{
//                         color: "#370617",
//                         fontWeight: "bold",
//                         fontSize: "17px",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       Gender *
//                     </label>{" "}
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="gender"
//                     value={formData.gender}
//                     onChange={handleInputChange}
//                     placeholder="Enter Gender"
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//             </Row>

//             {/* Row for Course Type, Course, and Branch in one line */}
//             <Row className="mb-3">
//               <Col sm={4}>
//                 <Form.Group controlId="formCourseType">
//                   <Form.Label>
//                     {" "}
//                     <label
//                       style={{
//                         color: "#370617",
//                         fontWeight: "bold",
//                         fontSize: "17px",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       Course Type *
//                     </label>{" "}
//                   </Form.Label>
//                   <Form.Select
//                     value={selectedType}
//                     onChange={handleTypeChange}
//                     required
//                   >
//                     <option value="">Select Type</option>
//                     {Object.keys(courseData).map((type) => (
//                       <option key={type} value={type}>
//                         {type}
//                       </option>
//                     ))}
//                   </Form.Select>
//                 </Form.Group>
//               </Col>
//               <Col sm={4}>
//                 <Form.Group controlId="formCourse">
//                   <Form.Label>
//                     <label
//                       style={{
//                         color: "#370617",
//                         fontWeight: "bold",
//                         fontSize: "17px",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       Course Name *
//                     </label>{" "}
//                   </Form.Label>
//                   <Form.Select
//                     value={selectedCourse}
//                     onChange={handleCourseChange}
//                     disabled={!selectedType}
//                     required
//                   >
//                     <option value="">Select Course</option>
//                     {selectedType &&
//                       courseData[selectedType]?.courses
//                         .filter(
//                           (course) =>
//                             course !==
//                             "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)"
//                         )
//                         .map((course) => (
//                           <option key={course} value={course}>
//                             {course}
//                           </option>
//                         ))}
//                   </Form.Select>
//                 </Form.Group>
//               </Col>
//               <Col sm={4}>
//                 <Form.Group controlId="formBranch">
//                   <Form.Label>
//                     <label
//                       style={{
//                         color: "#370617",
//                         fontWeight: "bold",
//                         fontSize: "17px",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       Branch *
//                     </label>{" "}
//                   </Form.Label>
//                   <Form.Select
//                     value={selectedBranch}
//                     onChange={handleBranchChange}
//                     disabled={!selectedCourse}
//                     required
//                   >
//                     <option value="">Select Branch</option>
//                     {selectedCourse &&
//                       courseData[selectedType]?.branches[selectedCourse]?.map(
//                         (branch) => (
//                           <option key={branch} value={branch}>
//                             {branch}
//                           </option>
//                         )
//                       )}
//                   </Form.Select>
//                 </Form.Group>
//               </Col>
//             </Row>

//             {/* Row for 3 input boxes in one line */}
//             <Row className="mb-3">
//               <Col sm={4}>
//                 <Form.Group controlId="formName">
//                   <Form.Label>
//                     {" "}
//                     <label
//                       style={{
//                         color: "#370617",
//                         fontWeight: "bold",
//                         fontSize: "17px",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       Qualification *
//                     </label>{" "}
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="qualification"
//                     value={formData.qualification}
//                     onChange={handleInputChange}
//                     placeholder="Enter Qualification"
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//               <Col sm={4}>
//                 <Form.Group controlId="formFathersName">
//                   <Form.Label>
//                     {" "}
//                     <label
//                       style={{
//                         color: "#370617",
//                         fontWeight: "bold",
//                         fontSize: "17px",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       Passing Year *
//                     </label>{" "}
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="passingYear"
//                     value={formData.passingYear}
//                     onChange={handleInputChange}
//                     placeholder="Enter passingYear"
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//               <Col sm={4}>
//                 <Form.Group controlId="formMothersName">
//                   <Form.Label>
//                     <label
//                       style={{
//                         color: "#370617",
//                         fontWeight: "bold",
//                         fontSize: "17px",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       Mobile No *
//                     </label>{" "}
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="mobile"
//                     value={formData.mobile}
//                     onChange={handleInputChange}
//                     placeholder="Enter mobile"
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//             </Row>

//             {/* Row for 3 input boxes in one line */}
//             <Row className="mb-3">
//               <Col sm={4}>
//                 <Form.Group controlId="formName">
//                   <Form.Label>
//                     <label
//                       style={{
//                         color: "#370617",
//                         fontWeight: "bold",
//                         fontSize: "17px",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       ABC ID *
//                     </label>{" "}
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="AbcId"
//                     value={formData.AbcId}
//                     onChange={handleInputChange}
//                     placeholder="Enter AbcId"
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//               <Col sm={4}>
//                 <Form.Group controlId="formFathersName">
//                   <Form.Label>
//                     <label
//                       style={{
//                         color: "#370617",
//                         fontWeight: "bold",
//                         fontSize: "17px",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       Categroy *
//                     </label>{" "}
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="category"
//                     value={formData.category}
//                     onChange={handleInputChange}
//                     placeholder="Enter category"
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//               <Col sm={4}>
//                 <Form.Group controlId="formMothersName">
//                   <Form.Label>
//                     {" "}
//                     <label
//                       style={{
//                         color: "#370617",
//                         fontWeight: "bold",
//                         fontSize: "17px",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       Domicile *
//                     </label>{" "}
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="domicile"
//                     value={formData.domicile}
//                     onChange={handleInputChange}
//                     placeholder="Enter domicile"
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//             </Row>

//             {/* Row for 3 input boxes in one line */}
//             <Row className="mb-3">
//               <Col sm={4}>
//                 <Form.Group controlId="formName">
//                   <Form.Label>
//                     {" "}
//                     <label
//                       style={{
//                         color: "#370617",
//                         fontWeight: "bold",
//                         fontSize: "17px",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       Last Passed Subject *
//                     </label>{" "}
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="lastPassedSubject"
//                     value={formData.lastPassedSubject}
//                     onChange={handleInputChange}
//                     placeholder="Enter lastPassedSubject"
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//               <Col sm={4}>
//                 <Form.Group controlId="formFathersName">
//                   <Form.Label>
//                     {" "}
//                     <label
//                       style={{
//                         color: "#370617",
//                         fontWeight: "bold",
//                         fontSize: "17px",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       Last Percentage *
//                     </label>{" "}
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="LastPercentage"
//                     value={formData.LastPercentage}
//                     onChange={handleInputChange}
//                     placeholder="Enter Last Percentage"
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//               <Col sm={4}>
//                 <Form.Group controlId="formMothersName">
//                   <Form.Label>
//                     {" "}
//                     <label
//                       style={{
//                         color: "#370617",
//                         fontWeight: "bold",
//                         fontSize: "17px",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       Assigned College*
//                     </label>{" "}
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="Assigned College"
//                     value={formData.assignedCollege}
//                     onChange={handleInputChange}
//                     placeholder="Enter AssignedCollege"
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//             </Row>

//             {/* Row for 3 input boxes in one line */}
//             {/* <Row className="mb-3">
//             <Col sm={4}>
//             <Form.Group controlId="formDomicileUpload" className="mt-2">
//     <Form.Label> <b>Student Photo Upload *</b></Form.Label>
//     <Form.Control 
//       type="file" 
//       name="domicileFile" 
//       accept=".pdf,.jpg,.png" 
//       required 
//     />
//   </Form.Group>
//             </Col>
//             <Col sm={4}>
//             <Form.Group controlId="formDomicileUpload" className="mt-2">
//     <Form.Label> <b>Student Sign Upload *</b></Form.Label>
//     <Form.Control 
//       type="file" 
//       name="domicileFile" 
//       accept=".pdf,.jpg,.png" 
//       required 
//     />
//   </Form.Group>
//             </Col>
//             <Col sm={4}>
//             <Form.Group controlId="formDomicileUpload" className="mt-2">
//     <Form.Label> <b>Student Adhar Card Upload *</b></Form.Label>
//     <Form.Control 
//       type="file" 
//       name="domicileFile" 
//       accept=".pdf,.jpg,.png" 
//       required 
//     />
//   </Form.Group>
//             </Col>
//           </Row> */}

//             {/* Submit Button */}
//             <Row className="justify-content-end">
//               <Col sm={10}>
//                 <Button
//                   variant="primary"
//                   type="submit"
//                   disabled={loading}
//                   style={{ marginLeft: "30%" }}
//                 >
//                   {loading ? (
//                     <>
//                       <Spinner
//                         as="span"
//                         animation="border"
//                         size="sm"
//                         role="status"
//                         aria-hidden="true"
//                         className="me-2"
//                       />
//                       Updating...
//                     </>
//                   ) : (
//                     "Submit"
//                   )}
//                 </Button>
//               </Col>
//             </Row>
//           </Form>
//         </Card.Body>
//       </Card>
//     </div>
//   );
// };

// export default AdminUpdtStdt_Detail;



import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Spinner, Alert, Card } from "react-bootstrap";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import { FormControl } from "react-bootstrap";
import { useParams } from "react-router-dom";

const AdminUpdtStdt_Detail = ({ userData }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [courseData, setCourseData] = useState({});
  const [selectedType, setSelectedType] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [loading, setLoading] = useState(false);
  const session = new Date().getFullYear();
  const { randomId } = useParams();

  // Initialize form data with default values
  const [formData, setFormData] = useState({
    name: "",
    fathersname: "",
    mothersname: "",
    gender: "",
    email: "",
    dob: "",
    courseType: "",
    courseName: "",
    courseBranch: "",
    qualification: "",
    passingYear: "",
    mobile: "",
    category: "",
    adhar: "",
    samargid: "",
    domicile: "",
    obtainMarks: "",
    lastPassedSubject: "",
    LastPercentage: "",
    academicDetails: {}, // Initialize as an object
    abcId: "",
    assignedCollege: "",
  });

  const [selectedDate, setSelectedDate] = useState(null);
  const [collegeOptions, setCollegeOptions] = useState([]);
  const [loadingColleges, setLoadingColleges] = useState(false);
  useEffect(() => {
    const fetchColleges = async () => {
      if (!selectedCourse) {
        setCollegeOptions([]);
        return;
      }
      setLoadingColleges(true);
      try {
        const response = await fetch(
          `https://sssutms.ac.in/apitest/admin/search-colleges?courseName=${selectedCourse}`
        );
        const data = await response.json();
        setCollegeOptions(data.college);
      } catch (error) {
        console.error("Error fetching colleges:", error);
      } finally {
        setLoadingColleges(false);
      }
    };

    fetchColleges();
  }, [selectedCourse]);


  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFormData((prevData) => ({
      ...prevData,
      dob: date.toISOString().split("T")[0], // YYYY-MM-DD format
    }));
  };
  // Fetch course data on component mount
  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://sssutms.ac.in/apitest/v2/admin/courses?session=${session}`
        );
        const data = await response.json();
        const formattedData = {};
        data.forEach((course) => {
          if (!formattedData[course.courseType]) {
            formattedData[course.courseType] = {
              courses: [],
              branches: {},
            };
          }
          course.courseNames.forEach((courseName) => {
            formattedData[course.courseType].courses.push(
              courseName.courseName
            );
            formattedData[course.courseType].branches[courseName.courseName] =
              courseName.branches.map((branch) => branch.branchName);
          });
        });
        setCourseData(formattedData);
      } catch (error) {
        console.error("Error fetching course data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCourses();
  }, [session]);

  // Populate form data when `userData` is available
  useEffect(() => {
    if (userData) {
      setFormData((prevData) => ({
        ...prevData,
        ...userData,
      }));
      setSelectedType(userData.courseType || "");
      setSelectedCourse(userData.courseName || "");
      setSelectedBranch(userData.courseBranch || "");
    }
  }, [userData]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle dropdown changes
  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    setSelectedCourse("");
    setSelectedBranch("");
  };

  const handleCourseChange = (e) => {
    setSelectedCourse(e.target.value);
    setSelectedBranch("");
  };

  const handleBranchChange = (e) => {
    setSelectedBranch(e.target.value);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Prepare updated data
      const updatedData = {
        ...formData,
        courseType: selectedType,
        courseName: selectedCourse,
        courseBranch: selectedBranch,
      };

      console.log("Sending updated data to backend:", updatedData);

      // Send PATCH request to backend
      const response = await fetch(
        `https://sssutms.ac.in/apitest/student-details/update/${userData.randomId}`,

        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (response.status === 200) {
        const responseData = await response.json();
        swal({
          title: "Success",
          text: "Student information updated successfully!",
          icon: "success",
          button: "OK",
        });
      } else {
        const errorData = await response.json();
        swal({
          title: "Error",
          text: errorData.message || "Failed to update student information",
          icon: "error",
          button: "OK",
        });
      }
    } catch (error) {
      console.error("Error updating student:", error);
      swal({
        title: "Error",
        text: "An error occurred while updating student information",
        icon: "error",
        button: "OK",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4">
      <Card>
        <Card.Body>
          <div
            style={{
              backgroundColor: "#124559",
              width: "100%",
              height: "40px",
              borderRadius: "10px",
            }}
          >
            <h6
              style={{
                padding: "10px",
                margin: 0,
                marginTop: "-3px",
                color: "#ffff",
                textAlign: "center",
                fontFamily: "inherit",
              }}
            >
              Upate Search Student
            </h6>
          </div>
          <Form onSubmit={handleSubmit} style={{ marginTop: "3%" }}>
            {/* Row for 3 input boxes in one line */}
            <Row className="mb-3">
              <Col sm={4}>
                <Form.Group controlId="formName">
                  <Form.Label>
                    {" "}
                    <label
                      style={{
                        color: "#370617",
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "inherit",
                      }}
                    >
                      Student Name *
                    </label>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Enter student's name"

                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="formFathersName">
                  <Form.Label>
                    {" "}
                    <label
                      style={{
                        color: "#370617",
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "inherit",
                      }}
                    >
                      Father Name *
                    </label>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="fathersname"
                    value={formData.fathersname}
                    onChange={handleInputChange}
                    placeholder="Enter father's name"

                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="formMothersName">
                  <Form.Label>
                    <label
                      style={{
                        color: "#370617",
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "inherit",
                      }}
                    >
                      Mother Name *
                    </label>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="mothersname"
                    value={formData.mothersname}
                    onChange={handleInputChange}
                    placeholder="Enter mother's name"

                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Row for 3 input boxes in one line */}
            <Row className="mb-3">
              <Col sm={4}>
                <Form.Group controlId="formName">
                  <Form.Label>
                    <label
                      style={{
                        color: "#370617",
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "inherit",
                      }}
                    >
                      Email *
                    </label>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Enter student's email"

                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="formFathersName">
                  <Form.Label>
                    <label
                      style={{
                        color: "#370617",
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "inherit",
                      }}
                    >
                      DOB *
                    </label>{" "}
                  </Form.Label>

                  <div>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={
                        selectedDate ||
                        (formData?.dob ? new Date(formData?.dob) : null)
                      }
                      // readOnly
                      name="dob"
                      onChange={handleDateChange}
                      required
                      className="custom-input" // Apply your custom class here

                      customInput={<FormControl style={{ width: "159%" }} />}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="formMothersName">
                  <Form.Label>
                    {" "}
                    <label
                      style={{
                        color: "#370617",
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "inherit",
                      }}
                    >
                      Gender *
                    </label>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    placeholder="Enter Gender"

                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Row for Course Type, Course, and Branch in one line */}
            <Row className="mb-3">
              <Col sm={4}>
                <Form.Group controlId="formCourseType">
                  <Form.Label>
                    {" "}
                    <label
                      style={{
                        color: "#370617",
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "inherit",
                      }}
                    >
                      Course Type *
                    </label>{" "}
                  </Form.Label>
                  <Form.Select
                    value={selectedType}
                    onChange={handleTypeChange}

                  >
                    <option value="">Select Type</option>
                    {Object.keys(courseData).map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="formCourse">
                  <Form.Label>
                    <label
                      style={{
                        color: "#370617",
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "inherit",
                      }}
                    >
                      Course Name *
                    </label>{" "}
                  </Form.Label>
                  <Form.Select
                    value={selectedCourse}
                    onChange={handleCourseChange}
                    disabled={!selectedType}

                  >
                    <option value="">Select Course</option>
                    {selectedType &&
                      courseData[selectedType]?.courses
                        .filter(
                          (course) =>
                            course !==
                            "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)"
                        )
                        .map((course) => (
                          <option key={course} value={course}>
                            {course}
                          </option>
                        ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="formBranch">
                  <Form.Label>
                    <label
                      style={{
                        color: "#370617",
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "inherit",
                      }}
                    >
                      Branch *
                    </label>{" "}
                  </Form.Label>
                  <Form.Select
                    value={selectedBranch}
                    onChange={handleBranchChange}
                    disabled={!selectedCourse}

                  >
                    <option value="">Select Branch</option>
                    {selectedCourse &&
                      courseData[selectedType]?.branches[selectedCourse]?.map(
                        (branch) => (
                          <option key={branch} value={branch}>
                            {branch}
                          </option>
                        )
                      )}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            {/* Row for 3 input boxes in one line */}
            <Row className="mb-3">
              <Col sm={4}>
                <Form.Group controlId="formName">
                  <Form.Label>
                    {" "}
                    <label
                      style={{
                        color: "#370617",
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "inherit",
                      }}
                    >
                      Qualification *
                    </label>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Qualification"
                    value={formData.qualification}
                    onChange={handleInputChange}
                    placeholder="Enter Qualification"
                    required
                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="formFathersName">
                  <Form.Label>
                    {" "}
                    <label
                      style={{
                        color: "#370617",
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "inherit",
                      }}
                    >
                      Passing Year *
                    </label>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="passingYear"
                    value={formData.passingYear}
                    onChange={handleInputChange}
                    placeholder="Enter passingYear"

                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="formMothersName">
                  <Form.Label>
                    <label
                      style={{
                        color: "#370617",
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "inherit",
                      }}
                    >
                      Mobile No *
                    </label>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleInputChange}
                    placeholder="Enter mobile"
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Row for 3 input boxes in one line */}
            <Row className="mb-3">
              <Col sm={4}>
                <Form.Group controlId="formName">
                  <Form.Label>
                    <label
                      style={{
                        color: "#370617",
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "inherit",
                      }}
                    >
                      ABC ID *
                    </label>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="AbcId"
                    value={formData.AbcId}
                    onChange={handleInputChange}
                    placeholder="Enter AbcId"

                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="formFathersName">
                  <Form.Label>
                    <label
                      style={{
                        color: "#370617",
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "inherit",
                      }}
                    >
                      Categroy *
                    </label>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="category"
                    value={formData.category}
                    onChange={handleInputChange}
                    placeholder="Enter category"

                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="formMothersName">
                  <Form.Label>
                    {" "}
                    <label
                      style={{
                        color: "#370617",
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "inherit",
                      }}
                    >
                      Domicile *
                    </label>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="domicile"
                    value={formData.domicile}
                    onChange={handleInputChange}
                    placeholder="Enter domicile"

                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Row for 3 input boxes in one line */}
            <Row className="mb-3">
              <Col sm={4}>
                <Form.Group controlId="formName">
                  <Form.Label>
                    {" "}
                    <label
                      style={{
                        color: "#370617",
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "inherit",
                      }}
                    >
                      Last Passed Subject *
                    </label>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="lastPassedSubject"
                    value={formData.lastPassedSubject}
                    onChange={handleInputChange}
                    placeholder="Enter lastPassedSubject"

                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="formFathersName">
                  <Form.Label>
                    {" "}
                    <label
                      style={{
                        color: "#370617",
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "inherit",
                      }}
                    >
                      Last Percentage *
                    </label>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="LastPercentage"
                    value={formData.LastPercentage}
                    onChange={handleInputChange}
                    placeholder="Enter Last Percentage"

                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="formAssignedCollege">
                  <Form.Label>
                    <label
                      style={{
                        color: "#370617",
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "inherit",
                      }}
                    >
                      Assigned College *
                    </label>
                  </Form.Label>
                  <Form.Select
                    name="assignedCollege"
                    value={formData.assignedCollege}
                    onChange={handleInputChange}
                    disabled={loadingColleges || collegeOptions.length === 0}
                  >
                    <option value="">Select College</option>
                    {collegeOptions.map((college) => (
                      <option key={college} value={college}>
                        {college}
                      </option>
                    ))}
                  </Form.Select>
                  {loadingColleges && <div>Loading colleges...</div>}
                </Form.Group>
              </Col>

            </Row>


            <Row className="justify-content-end">
              <Col sm={10}>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={loading}
                  style={{ marginLeft: "30%" }}
                >
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      Updating...
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AdminUpdtStdt_Detail;