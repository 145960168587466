
import React, { useEffect, useState } from "react";
import {
  TableCell,
  Box,
  CircularProgress
} from "@mui/material";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CardContent from "@mui/material/CardContent";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";
import DatePicker from "react-datepicker";


const SearchVer = ({
  session,
  courseType,
  courseName,
  branch
}) => {
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const [studentdata1, setStudentData] = useState({ students: [] });
  const [dataNotFound, setDataNotFound] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const url = process.env.REACT_APP_STD_APPROVE_LIST;

        const requestData = {
          admissionSession: session,
          courseType,
          courseName,
          courseBranch: branch,
        };

        const response = await axios.post(url, requestData);

        const data = response.data;
        // console.log(data, "data")
        if (!data.students || data.students.length === 0) {
          setDataNotFound(true);
        } else {
          setDataNotFound(false);
          setStudentData(data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setDataNotFound(true);
      } finally {
        setLoading(false)
      }
    };

    fetchData();
  }, [session,
    courseType,
    courseName,
    branch,]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  /////////////////////datepicker/////////////////
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <AdminDashboard />
      <Box sx={{ width: "93%", marginLeft: "90px", marginTop: "80px" }}>
        {loading ? (<CircularProgress color="inherit" style={{ marginLeft: "45%" }} />) : (
          <CardContent>
            {dataNotFound ? (
              <div>
                <h2>Oops! No Student Available in this Course!!!!</h2>
              </div>
            ) : (

              <Paper sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: "440px" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: '#001d3d', color: 'white', position: 'sticky', left: '0', top: 0, zIndex: 2 }}
                        >

                          S.No.

                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: '#001d3d', color: 'white', position: 'sticky', top: 0, zIndex: 1 }}
                        >

                          Session

                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: '#001d3d', color: 'white', minWidth: '100px', position: 'sticky', top: 0, zIndex: 1 }}>


                          Registration ID.

                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: '#001d3d', color: 'white', minWidth: '200px', position: 'sticky', top: 0, left: 50, zIndex: 2 }}>


                          Student Name

                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ backgroundColor: '#001d3d', color: 'white', position: 'sticky', top: 0, zIndex: 1 }}
                        >

                          Email

                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: '#001d3d', color: 'white', minWidth: '20px', position: 'sticky', top: 0, zIndex: 1 }}
                        >

                          Date of Birth

                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: '#001d3d', color: 'white', minWidth: '150px', position: 'sticky', top: 0, zIndex: 1 }}>


                          Father's Name

                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: '#001d3d', color: 'white', minWidth: '150px', top: 0, zIndex: 1, position: 'sticky' }}>


                          Mother's Name

                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: '#001d3d', color: 'white', minWidth: '140px', zIndex: 1, top: 0, position: 'sticky' }}
                        >

                          Contact

                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: '#001d3d', color: 'white', minWidth: '170px', position: 'sticky', top: 0, zIndex: 1 }}
                        >

                          Nationality

                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: '#001d3d', color: 'white', top: 0, zIndex: 1, position: 'sticky' }}
                        >

                          Last Qualification

                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: '#001d3d', color: 'white', minWidth: '80px', position: 'sticky', top: 0, zIndex: 1 }}>


                          Qualification %

                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: '#001d3d', color: 'white', position: 'sticky', top: 0, zIndex: 1 }}
                        >

                          Course Type

                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: '#001d3d', color: 'white', minWidth: '200px', position: 'sticky', top: 0, zIndex: 1 }}>


                          Course Name

                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: '#001d3d', color: 'white', minWidth: '200px', top: 0, zIndex: 1, position: 'sticky' }}>


                          Course Branch

                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: '#001d3d', color: 'white', minWidth: '200px', top: 0, zIndex: 1, position: 'sticky' }}>


                          Assigned College

                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {studentdata1 && studentdata1.students && studentdata1.students.map((student, index) => (
                        <TableRow key={index}>
                          <TableCell align="left" style={{ position: 'sticky', top: 0, left: 0, backgroundColor: 'white', zIndex: 1 }}>{index + 1}</TableCell>

                          <TableCell align="left">{student.admissionSession}</TableCell>
                          <TableCell align="left">
                            {student.randomId}
                          </TableCell>
                          <TableCell align="left" style={{
                            color: '#e85d04', fontWeight: 700, backgroundColor: "#fff",
                            position: 'sticky', top: 0, zIndex: '1', left: 50
                          }}>{student.name}</TableCell>
                          <TableCell align="left">{student.email}</TableCell>

                          <TableCell align="left" style={{ minWidth: '20px' }}>
                            <DatePicker
                              selected={selectedDate || new Date(student.dob)}
                              readOnly
                              dateFormat="dd/MM/yyyy"
                              className="text-left"
                            />
                          </TableCell>
                          <TableCell align="left">
                            {student.fathersname}
                          </TableCell>
                          <TableCell align="left">
                            {student.mothersname}
                          </TableCell>
                          <TableCell align="left">{student.mobile}</TableCell>
                          <TableCell align="left">{student.domicile}</TableCell>
                          <TableCell align="left">
                            {student.qualification}
                          </TableCell>
                          <TableCell align="left">
                            {student.LastPercentage}
                          </TableCell>
                          <TableCell align="left">{student.courseType}</TableCell>
                          <TableCell align="left">{student.courseName}</TableCell>
                          <TableCell align="left">
                            {student.courseBranch}
                          </TableCell>
                          <TableCell align="left">
                            {student.assignedCollege || "Not Assigned"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            )
            }
          </CardContent>
        )}
        <br></br>
      </Box>
    </>

  );
};

export default SearchVer;