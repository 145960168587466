import React from "react";
import Nav from "../../../HomePage/Nav";
import Footerpage from "../../../HomePage/Footerpage";
import { Link } from "react-router-dom";

const Approval = () => {
  return (
    <>
      <Nav />
      <style>
        {`
    
    .about_container{
    margin-left:20px
    ;}
    .section_all {
        position: relative;
        padding-top: 20px;
        // margin-left:50px;
        padding-bottom: 80px;
        min-height: 100vh;
    }
   
    
    // ///////////////////////////////////////////////////////////////////////////////////////////////////

    h1 {
      position: relative;
      padding: 0;
      margin: 0;
      font-family: "Raleway", sans-serif;
      font-weight: 700;
      font-size: 30px;
      color: #243642;
      -webkit-transition: all 0.4s ease 0s;
      -o-transition: all 0.4s ease 0s;
      transition: all 0.4s ease 0s;
    }
    
    
    h1 em {
      font-style: normal;
      font-weight: 900;
    }
    .two h1 {
      text-transform: capitalize;
    }
    .two h1:before {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 90px;
      height: 2px;
      content: "";
      background-color: #F09319;
    }
    

    .title-main{
      margin-left:30px;
      
       font-family: "Poppins", serif;
        font-weight: 700;
        font-style: normal;
        }
      
      
      .title-hr {
      width: 120px;
      border-top: 5px solid #FF0000;
      margin-left: 30px;
      margin-top: -5px;
      margin-bottom:2px;
      }
      

.alt-two h4 {
  text-align:center;
}

.alt-two h4:before {
    left:50%;
  }


  h4 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 23px;
  color: #243642;
  margin-left:30px;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h4 em {
  font-style: normal;
  font-weight: 600;
}
.two h4 {
  text-transform: capitalize;
}
  .two h4:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


// //////////////////////////////////////////////////////////////////////////////////////////////////


              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }
  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
.listy{
font-size:21px; 
 margin-left:15px;
 margin-top:8px;
// font-weight:700;
 list-style-type: disc; /* This will add bullets to the list */

  
}

   .llis{
  font-size:17px; 
 margin-left:15px;
 margin-top:18px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



 
  
     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:-18px;
          margin-left:50px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  
  
    .title-hr {
      width: 120px;
      border-top: 5px solid #FF9D23;
      margin-left: 30px;
      margin-top: 5px;
      margin-bottom:2px;
      }

      .table-container {
display: flex;
margin-left:80px;
flex-direction: row; /* Align tables in a row */
gap: 20px; /* Space between tables */
flex-wrap: wrap; /* Allows wrapping on smaller screens */
} 

.cus_link{
color:#001A6E;
}
table {
width: 48%; /* Set width to 48% to fit both tables side by side */
border-collapse: collapse;
} 


td {
padding: 5px;
border: 1px solid #999;
text-align: left;
font-family: 'poppins',sansSerif;
margin-left:10px;
}  

th{
text-align: center; 
padding: 5px;
font-weight:700;
width:300px;
border: 1px solid #999;
}
@media screen and (max-width: 768px) {
.table-container {
flex-direction: column; /* Stack tables on smaller screens */
}      table {
width: 100%; /* Make tables full-width on smaller screens */
}
}
   }
    
    `}
      </style>
      {/* <section className="section_all " id="about" > */}
      <div className="about_container">
        <div
          className="h2 title-main"
          style={{ color: "#780000", marginTop: "10px" }}
        >
          Approval
        </div>
        <hr className="title-hr"></hr>

        <div className="row">
          <div className="col-lg-9">
            <p
              className="mt-3"
              style={{
                textAlign: "justify",
                color: "black",
                fontFamily: "'poppins',sansSerif",
              }}
            >
              Own recommendations of Madhya Pradesh Niji Vishwavidyalaya Niyamak
              Aayog, the State Legislature of Madhya Pradesh has accorded
              approval to Sri Satya Sai University of Technology & Medical
              Sciences (SSSUTMS) as State Private University with main campus at
              Sehore from Academic session 2014-15 vide Gazette Notification of
              State Government of M.P., Act no. 06 No.80 dated 12th February
              2014 [The Madhya Pradesh Niji Vishwavidyalaya (Sthapana &
              Sanchalan) Sansodhan Adhiniyam 2014]”.
            </p>
            <table
              style={{
                width: "90%",
                marginLeft: "20px",
                marginTop: "10px",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <th rowSpan={3}>
                    MP PRIVATE UNIVERSITIES REGULATORY COMMISSION
                  </th>
                </tr>

                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/MPPURC_SCHOOL_OF_MEDICAL_SCIENCES"
                    >
                      {" "}
                      School of Medical Sciences{" "}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/MPPURC_23.pdf"
                    >
                      {" "}
                      MPPURC 2023-24{" "}
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                width: "90%",
                marginLeft: "20px",
                marginTop: "10px",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <th style={{ width: "300px" }} rowSpan={7}></th>
                </tr>
                {/* <tr> <td> <Link className='cus_link' target='_blank'  to='https://cdn.sssutms.ac.in/media/static/PDF/Approvals/MPPURC_SCHOOL_OF_MEDICAL_SCIENCES.pdf'>MP PRIVATE UNIVERSITIES REGULATORY COMMISSION (SOMS)</Link></td></tr> */}
                {/* <tr><td> <Link className='cus_link' target='_blank'  to='https://cdn.sssutms.ac.in/media/static/PDF/Approvals/MPPURC_23.pdf'> MP PRIVATE UNIVERSITIES REGULATORY COMMISSION   </Link></td></tr> */}
                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/EOA_Paramedical_2022-23.pdf"
                    >
                      {" "}
                      MP PARAMEDICAL COUNCIL{" "}
                    </Link>
                  </td>
                </tr>{" "}
                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/Approvalugc_07122024_0352.pdf"
                    >
                      {" "}
                      UGC 2F{" "}
                    </Link>
                  </td>
                </tr>{" "}



                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/notification/by email.pdf"
                    >
                      {" "}
                      UGC Section 12-B{" "}
                    </Link>
                  </td>
                </tr>{" "}


                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/ugcn.pdf"
                    >
                      {" "}
                      UGC{" "}
                    </Link>
                  </td>
                </tr>{" "}
                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/AIUN.pdf"
                    >
                      {" "}
                      AIUN{" "}
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                width: "90%",
                marginLeft: "20px",
                marginTop: "10px",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <th rowSpan={8}>AICTE</th>
                </tr>

                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/EOA Report 2025-2026 School of Design.pdf"
                    >
                      {" "}
                      School of Design{" "}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/EOA Report 2025-2026 School of Engineering.pdf"
                    >
                      School of Engineering{" "}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/EOA Report 2025-2026 School of Computer Application.pdf  "
                    >
                      {" "}
                      School of Computer Application{" "}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/EOA Report 2025-2026 Hotel Management.pdf"
                    >
                      {" "}
                      School of Hotel Management{" "}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/EOA Report 2025-2026 Management Studies.pdf"
                    >
                      {" "}
                      School of Management Studies{" "}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/EOA Report 2025-2026 POLY ENGINEERING.pdf"
                    >
                      Polytechnic (Engineering){" "}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/EOA Report 2025-2026 Faculty of Education.pdf"
                    >
                      Faculty Of Education {" "}
                    </Link>
                  </td>
                </tr>
                {/* <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/LOA Report FOE 24-25.pdf"
                    >
                      {" "}
                      Polytechnic (Engineering){" "}
                    </Link>
                  </td>
                </tr> */} 
              </tbody>
            </table>
            <table
              style={{
                width: "90%",
                marginLeft: "20px",
                marginTop: "10px",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <th rowSpan={5}>NCTE</th>
                </tr>

                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/SOE_NCTE.pdf"
                    >
                      {" "}
                      Faculty of Education -- (School of Education){" "}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/SOTE_NCTE.pdf"
                    >
                      Faculty of Education-- (School Teachers Education){" "}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/SPE_NCTE.pdf"
                    >
                      {" "}
                      Faculty of Education-- (School of Physical Education){" "}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/SPE_NCTE.pdf"
                    >
                      {" "}
                      Faculty of Education
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                width: "90%",
                marginLeft: "20px",
                marginTop: "10px",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <th rowSpan={11}>PCI</th>
                </tr>
                <tr>
  <td>2024-2025</td>
</tr>

                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/COP PCI APPROVAL LETTER 2024-25.pdf"
                    >
                      {" "}
                      Faculty of Pharmacy (College of Pharmacy){" "} 
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/approval 2024-25 sop.pdf"
                    >
                      {" "}
                      Faculty of Pharmacy(School of Pharmacy) 
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/diploma 2024-25.pdf"
                    >
                      {" "}
                      Faculty of Pharmacy (Polytechnic (Pharmacy)){" "}
                    </Link>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/approval 2024-25 pci rnk.pdf"
                    >
                      {" "}
                      SRI RAMNATH KAPOOR MEMORIAL SCHOOL OF PHARMACY
                    </Link>
                  </td>
                </tr>






<br></br>















<tr>
  <td>2023-2024</td>
</tr>



                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/PCI APRROVAL 2023-24.pdf"
                    >
                      {" "}
                      Faculty of Pharmacy (College of Pharmacy){" "} 
                    </Link>
                  </td>
                </tr>
              
              </tbody>
            </table>
            <table
              style={{
                width: "90%",
                marginLeft: "20px",
                marginTop: "10px",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <th rowSpan={2}>COA</th>
                </tr>

                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/MP17_20240521140843.pdf"
                    >
                      {" "}
                      School of Design{" "}
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                width: "90%",
                marginLeft: "20px",
                marginTop: "10px",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <th rowSpan={3}>AYUSH</th>
                </tr>

                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/Approval_12122022_0221.pdf"
                    >
                      {" "}
                      School of Homoeopathy{" "}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/School of Ayurveda Conditional Permssion MP.pdf"
                    >
                      School of Ayurveda & Siddha Studies{" "}
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                width: "90%",
                marginLeft: "20px",
                marginTop: "10px",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <th rowSpan={2}>BAR COUNCIL OF INDIA</th>
                </tr>

                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/BCI 2024-2025.pdf"
                    >
                      {" "}
                      School of Law{" "}
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                width: "90%",
                marginLeft: "20px",
                marginTop: "10px",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <th rowSpan={5}>MP NURSES REGISTRATION COUNCIL</th>
                </tr>

                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/Nursing_2019_20_22072022_0121.pdf"
                    >
                      {" "}
                      School of Nursing 2019-20{" "}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/Nursing_2020_21_22072022_0121.pdf"
                    >
                      School of Nursing 2020-21{" "}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/Nursing_2021-22.pdf"
                    >
                      School of Nursing 2021-22{" "}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link
                      className="cus_link"
                      target="_blank"
                      to="https://cdn.sssutms.ac.in/media/static/PDF/Approvals/MPNRC 22-23.pdf"
                    >
                      School of Nursing 2022-23{" "}
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
            <br></br>{" "}
          </div>
          <div className="col-lg-3">
            <ul className="list-icon">
              <h5
                style={{ fontFamily: "'poppins',sansSerif", color: "#243642" }}
              >
                Approvals
              </h5>
              <hr className="title-hr"></hr>
              <li className="lli">
                <a href="/approval">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Approval
                </a>
              </li>
              <li className="lli">
                <a href="/ordiances">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Ordinances
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* </section> */}
      <Footerpage />
    </>
  );
};

export default Approval;