import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  Checkbox,
  Typography,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const E_eligibility_Form = ({
  courseName,
  courseType,
  courseBranch,
  eligible,
  eligibilityGradPer,
}) => {
  const navigate = useNavigate();
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [timer, setTimer] = useState(180);
  const [disableSendOTP, setDisableSendOTP] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ email: "", otp: "" });

  useEffect(() => {
    let interval;
    if (disableSendOTP) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            setDisableSendOTP(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [disableSendOTP]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sendOTP = () => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      return alert("Please enter a valid email address.");
    }
    setLoading(true);
    axios
      .post(process.env.REACT_APP_STD_OTP, { email: formData.email })
      .then(() => {
        swal("Success", "OTP sent successfully", "success");
        setShowOtpInput(true);
        setDisableSendOTP(true);
        setTimer(180);
      })
      .catch(() => {
        swal(
          "Error",
          "Failed to send OTP ! This Email is already registered.",
          "error"
        );
      })
      .finally(() => setLoading(false));
  };

  const verifyOTP = () => {
    axios
      .post(process.env.REACT_APP_VERIFY_OTP, {
        email: formData.email,
        otp: formData.otp.trim(),
      })
      .then(() => {
        swal("Success", "OTP verified successfully", "success");
        setShowOtpInput(false);
        setOtpVerified(true);
        setDisableSendOTP(false);
      })
      .catch(() => {
        swal("Error", "Incorrect OTP", "error");
      });
  };

  const FormSubmit = () => {
    if (!formData.email || !otpVerified) {
      return swal("Error", "Fill the Email & OTP first!", "error");
    }
     
    if (!checked){   
      return swal("Error", "Check the checkbox first!", "error");
    }
     
    navigate("/Epravesh/Student/RegisterForm", {
      state: { courseType, courseName, courseBranch, email: formData.email },
    });
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 2,
        p: 2,
        boxShadow: 3,
        borderRadius: 1,
        bgcolor: "background.paper",
        maxWidth: { xs: "95%",sm:"90%" },
      }}
    >
      <Box
        sx={{
          bgcolor: "#e3f2fd",
          p: 1,
          color: "#1976d2",
          textAlign: "left",
          borderRadius: 1,
        }}
      >
        <Typography variant="body"  sx={{ fontWeight: 600 }}>
          Check Eligibility
        </Typography>
      </Box>

      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Eligibility</TableCell>
            <TableCell sx={{ color: "#e63946", fontWeight: "bold" }}>
              {eligible.eligibility}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Eligibility %</TableCell>
            <TableCell>
              <Grid container spacing={2}>
                {Object.entries(eligibilityGradPer)
                  .filter(([key]) => key !== "_id")
                  .map(([key, value]) => (
                    <Grid item xs={6} md={2} key={key}>
                      {`${key.replace(/_ID$/, "").toUpperCase()}: ${value}`}
                    </Grid>
                  ))}
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* Row for Email, Send OTP, OTP Input & Verify OTP */}
      <Grid container spacing={2} alignItems="center" mt={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            // fullWidth
            size="small"
            label="Enter Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            autoComplete="off"
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs="auto">
          <Button
            variant="contained"
            color="primary"
            onClick={sendOTP}
            disabled={!formData.email || disableSendOTP || loading}
          >
            {loading
              ? "Sending OTP..."
              : disableSendOTP
              ? `Resend(${timer}s)`
              : "Send OTP"}
          </Button>
        </Grid>
        {showOtpInput && (
          <>
            <Grid item xs={12} sm={3}>
              <TextField
                size="small"
                // fullWidth
                label="Enter OTP"
                name="otp"
                value={formData.otp}
                onChange={handleChange}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs="auto">
              <Button variant="contained" color="primary" onClick={verifyOTP}>
                Verify OTP
              </Button>
            </Grid>
          </>
        )}
      </Grid>

      <Box display="flex" alignItems="center">
        <Checkbox
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />
        <Typography>I have read all the information</Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          variant="contained"
          sx={{  alignItems: "center" }}
          onClick={FormSubmit}
        >
          Continue
        </Button>
      </Box>
    </Container>
  );
};

export default E_eligibility_Form;
