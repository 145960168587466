import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { TableCell, Box } from "@mui/material";
import { Button, CircularProgress } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import AdminDashboard from "./Id_Sidebar";
import jsPDF from "jspdf";
import JSZip from "jszip";
import Reg_Sign from "../images/Reg_Sign.png";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const theme = createTheme({
  typography: {
    fontWeightBold: 700,
  },
});

const Id_Card_Table = React.memo(function Id_Card_Table({studentdata}) {
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newAdmissionDate, setAdmissionDate] = useState("");


  useEffect(() => {
    filterData();
  }, [searchQuery, studentdata, newAdmissionDate]);

  const filterData = () => {
    if (!Array.isArray(studentdata)) {
      setFilteredData([]);
      return;
    }
    const filtered = studentdata.filter(
      (student) =>
        student.randomId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.courseBranch.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.randomId.toLowerCase().includes(searchQuery.toLowerCase()) 
        // student.enrollmentNumber.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const generateIDCardPDF = (student) => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: [85.6, 53.9],
    });

    doc.setFillColor(255, 245, 110);
    doc.rect(0, 0, 125, 15, "F");

    const universityLogo =
      "https://cdn.sssutms.ac.in/media/static/images/SSSUTMS_LOGO.png";
    doc.addImage(universityLogo, "PNG", 2, 2, 12, 10);

    doc.setFontSize(14);
    doc.setTextColor(128, 0, 0);
    doc.setFont("sans-serif", "bold");
    doc.text("SRI SATYA SAI UNIVERSITY", 15, 6);
    doc.setFontSize(9);
    doc.text("OF TECHNOLOGY & MEDICAL SCIENCES", 16, 10);
    doc.setFontSize(6);
    doc.setTextColor(0, 0, 0);
    doc.text(
      "[Established Under Section 2f Of 1956 By Govt. Of Madhya Pradesh]",
      17,
      13
    );
    doc.setFontSize(3);
    doc.setTextColor(0, 0, 0); 
    doc.setFont("sans-serif", "italic");
    doc.text("Where Talent meets Opportunity...", 2, 14.5);

    doc.setFillColor(255, 0, 0);
    doc.rect(26, 15, 23, 4, "F");
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text("IDENTITY CARD", 38, 17.5, { align: "center" });

    doc.setTextColor(0, 0, 0);
    doc.setFontSize(5);

    const details = [
      { label: "NAME OF STUDENT", value: student.name, highlight: true },
      { label: "FATHER'S NAME", value: student.fathersname },
      {
        label: "ENROLLMENT NO.",
        value: student.enrollmentNumber || "Not Generated",
      },
      { label: "INSTITUTE", value: student.assignedCollege },
      { label: "COURSE", value: student.courseName },
      { label: "BRANCH", value: student.courseBranch },

      {
        label: "DATE OF BIRTH",
        value: new Date(student.dob).toLocaleDateString("en-GB"),
      },
      {
        label: "ADDRESS",
        value: `${student.address.address2}`,
      },
    ];
    const maxAddressLength = 70;

    details.forEach((detail, index) => {
      const labelPosition = 2;
      const valuePosition = 20;
      const yOffset = 23 + index * 3;

      doc.setFont("helvetica", "bold");
      doc.text(`${detail.label}`, labelPosition, yOffset);

      if (detail.label === "ADDRESS") {
        const addressLines = detail.value.split(" ");
        let currentLine = "";
        let currentY = yOffset;

        addressLines.forEach((word) => {
          if (currentLine.length + word.length + 1 <= maxAddressLength) {
            currentLine += (currentLine.length ? " " : "") + word;
          } else {
            // Draw the current line and move to the next line
            doc.setFont("helvetica", "normal");
            doc.setFontSize(5);
            doc.text(`:  ${currentLine}`, valuePosition, currentY);
            currentLine = word;
            currentY += 3; // Move down for the next line
          }
        });

        // Draw the last line if any
        if (currentLine) {
          doc.setFont("helvetica", "normal");
          doc.setFontSize(5);
          doc.text(`:  ${currentLine}`, valuePosition, currentY);
        }

        return; // Skip the rest of the forEach loop for address handling
      }

      if (detail.reducedFont) {
        doc.setFont("helvetica", "normal");
        doc.setFontSize(4);
        doc.text(`:  ${detail.value}`, valuePosition, yOffset);
      } else {
        doc.setFont("helvetica", "normal");
        doc.setFontSize(5);
        doc.text(`:  ${detail.value}`, valuePosition, yOffset);
      }
    });

    doc.addImage(student.Documents.applicantPhoto, "JPEG", 68, 15, 15, 20);

    doc.setFontSize(6);
    doc.setFont("helvetica", "bold");
    const idCode = `ID CODE: ${student.randomId}`;
    const pageWidth = doc.internal.pageSize.getWidth();
    const idTextWidth =
      (doc.getStringUnitWidth(idCode) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const idXPosition = (pageWidth - idTextWidth) / 2;

    doc.text(idCode, idXPosition, 51);
    doc.text("Student Sign.", 70, 51);

    doc.addImage(Reg_Sign, "PNG", 2, 44, 18, 6);

    // Dy. Reg
    doc.text("Dy. Reg", 2, 51); // Adjusted position

    doc.save(`${student.name}_id_card.pdf`);

    return doc.output("blob");
  };
  const generateIDCardPDF2 = (student, universityLogo) => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: [85.6, 53.9],
    });

    doc.setFillColor(255, 245, 110);
    doc.rect(0, 0, 125, 15, "F");

    // const universityLogo =
    //   "https://cdn.sssutms.ac.in/media/static/images/SSSUTMS_LOGO.png";
    doc.addImage(universityLogo, "PNG", 2, 2, 12, 10);

    doc.setFontSize(14);
    doc.setTextColor(128, 0, 0);
    doc.setFont("sans-serif", "bold");
    doc.text("SRI SATYA SAI UNIVERSITY", 15, 5);
    doc.setFontSize(9);
    doc.text("OF TECHNOLOGY & MEDICAL SCIENCES", 16, 9);
    doc.setFontSize(6);
    doc.setTextColor(0, 0, 0);
    doc.text(
      "[Established Under Section 2f Of 1956 By Govt. Of Madhya Pradesh]",
      17,
      12
    );

    // Slogan
    doc.setFontSize(3);
    doc.setTextColor(0, 0, 0); // Black color
    doc.setFont("sans-serif", "italic");
    doc.text("Where Talent meets Opportunity...", 2, 14.5);

    doc.setFillColor(255, 0, 0);
    doc.rect(26, 15, 23, 4, "F");
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text("IDENTITY CARD", 38, 17.5, { align: "center" });

    doc.setTextColor(0, 0, 0); // Black text
    doc.setFontSize(5);

    const details = [
      { label: "NAME OF STUDENT", value: student.name, highlight: true },
      { label: "FATHER'S NAME", value: student.fathersname },
      {
        label: "ENROLLMENT NO.",
        value: student.enrollmentNumber || "Not Generated",
      },
      { label: "INSTITUTE", value: student.assignedCollege },
      { label: "COURSE", value: student.courseName },
      { label: "BRANCH", value: student.courseBranch },

      {
        label: "DATE OF BIRTH",
        value: new Date(student.dob).toLocaleDateString("en-GB"),
      },
      {
        label: "ADDRESS",
        value: `${student.address.address2}`,
      },
    ];
    const maxAddressLength = 70;

    details.forEach((detail, index) => {
      const labelPosition = 2;
      const valuePosition = 20;
      const yOffset = 23 + index * 3;

      doc.setFont("helvetica", "bold");
      doc.text(`${detail.label}`, labelPosition, yOffset);

      if (detail.label === "ADDRESS") {
        const addressLines = detail.value.split(" ");
        let currentLine = "";
        let currentY = yOffset;

        addressLines.forEach((word) => {
          if (currentLine.length + word.length + 1 <= maxAddressLength) {
            currentLine += (currentLine.length ? " " : "") + word;
          } else {
            // Draw the current line and move to the next line
            doc.setFont("helvetica", "normal");
            doc.setFontSize(5);
            doc.text(`:  ${currentLine}`, valuePosition, currentY);
            currentLine = word;
            currentY += 3;
          }
        });

        // Draw the last line if any
        if (currentLine) {
          doc.setFont("helvetica", "normal");
          doc.setFontSize(5);
          doc.text(`:  ${currentLine}`, valuePosition, currentY);
        }

        return;
      }

      if (detail.reducedFont) {
        doc.setFont("helvetica", "normal");
        doc.setFontSize(4);
        doc.text(`:  ${detail.value}`, valuePosition, yOffset);
      } else {
        doc.setFont("helvetica", "normal");
        doc.setFontSize(5);
        doc.text(`:  ${detail.value}`, valuePosition, yOffset);
      }
    });

    doc.addImage(student.Documents.applicantPhoto, "JPEG", 68, 15, 15, 20);

    doc.setFontSize(6);
    doc.setFont("helvetica", "bold");
    const idCode = `ID CODE: ${student.randomId}`;
    const pageWidth = doc.internal.pageSize.getWidth();
    const idTextWidth =
      (doc.getStringUnitWidth(idCode) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const idXPosition = (pageWidth - idTextWidth) / 2;

    doc.text(idCode, idXPosition, 51);
    doc.text("Student Sign.", 70, 51);

    doc.addImage(Reg_Sign, "PNG", 2, 44, 18, 6);

    doc.text("Dy. Reg", 2, 51);

    return doc.output("blob");
  };
  const loadImage = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Failed to load image:", error);
      return null;
    }
  };

  /////////////////////////name sort ////////////////////////////

  // Download all ID cards individually
  const handleDownloadAllIDCards = async () => {
    const universityLogo = await loadImage("https://cdn.sssutms.ac.in/media/static/images/SSSUTMS_LOGO.png");
    const regSignImage = await loadImage("path/to/Reg_Sign.png");

    const batchSize = 20;
    const totalStudents = filteredData.length;

    for (let start = 0; start < totalStudents; start += batchSize) {
      const zip = new JSZip();
      const end = Math.min(start + batchSize, totalStudents);

      for (let i = start; i < end; i++) {
        const student = filteredData[i];
        const pdfBlob = generateIDCardPDF2(student, universityLogo, regSignImage);
        const filename = `${student.enrollmentNumber}_id_card.pdf`;
        zip.file(filename, pdfBlob);
      }


      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, `ID_Cards_${start / batchSize + 1}.zip`);

      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };
  
  const handleSort = (column) => {
    if (column === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const ExportToExcel = () => {
    if (Array.isArray(studentdata)) {
      const Excel = studentdata.map((student) => ({
        Admission_Session: student.admissionSession,
        Enrollment_No: student.enrollmentNumber || "Not Generated",
        Enrollement_Status: student.isEnrolled,
        Random_Id: student.randomId,
        Random_Password: student.randomPassword,
        Admitted_Date: new Date(student.createdAt).toLocaleDateString("en-IN", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
        Name: student.name,
        Fathers_Name: student.fathersname,
        Mothers_Name: student.mothersname,
        Email: student.email,
        Mobile: student.mobile,
        Course_Type: student.courseType,
        Course: student.courseName,
        Branch: student.courseBranch,
        StudentType: student.StudentType || "Normal",
      }));

      const worksheet = XLSX.utils.json_to_sheet(Excel);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Enrolled Student");
      XLSX.writeFile(workbook, "Std_ID_Card_Data.xlsx");
    } else {
      console.error("Student data is not an array. Cannot export to Excel.");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <AdminDashboard />

      <>
        <Box
          sx={{
            width: "83%",
            marginLeft: "100px",
            marginTop: "90px",
            overflow: "hidden",
          }}
        >
          <Box style={{ float: "left", marginBottom: "5px" }}>
            <SearchIcon sx={{ mr: 1 }} />
            <input
              type="text"
              placeholder="Search by ID, Name"
              onChange={handleSearchChange}
              value={searchQuery}
            />
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: 1, mb: 1 }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownloadAllIDCards}
            >
              Download All
            </Button>
            <Button variant="outlined" color="primary" onClick={ExportToExcel}>
              Export to Excel
            </Button>
          </Box>
          {dataNotFound ? (
            <div style={{ marginTop: "50px", marginLeft: "20px" }}>
              <h2>Oops! No Student Available in this Course!!!!</h2>
            </div>
          ) : (
            <Paper sx={{ width: "100%", marginBottom: "0px" }}>
              {loading ? (
                <CircularProgress
                  color="success"
                  style={{ marginTop: "80px", marginLeft: "50%" }}
                />
              ) : (
                <TableContainer sx={{ maxHeight: "420px" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: "#023047" }}
                        >
                          <h1
                            style={{
                              fontSize: "15px",
                              fontWeight: "bolder",
                              color: "white",
                              fontFamily: "-moz-initial",
                            }}
                          >
                            S.No.
                          </h1>
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{ backgroundColor: "#023047" }}
                        >
                          <h1
                            style={{
                              fontSize: "15px",
                              fontWeight: "bolder",
                              color: "white",
                              fontFamily: "-moz-initial",
                            }}
                          >
                            Admission Session
                          </h1>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: "#023047" }}
                        >
                          <h1
                            style={{
                              fontSize: "15px",
                              fontWeight: "bolder",
                              color: "white",
                              fontFamily: "-moz-initial",
                            }}
                          >
                           Enrollment No.
                          </h1>
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{ backgroundColor: "#023047" }}
                        >
                          <h1
                            style={{
                              fontSize: "15px",
                              fontWeight: "bolder",
                              color: "white",
                              fontFamily: "-moz-initial",
                            }}
                            // /////////////for sorting name//////////////////
                            onClick={() => handleSort("name")}
                          >
                            Random Id
                            {sortBy === "name" && (
                              <span>{sortOrder === "asc" ? " ↑" : " ↓"}</span>
                            )}
                          </h1>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: "#023047" }}
                          onClick={() => handleSort("name")}
                        >
                          <h1
                            style={{
                              fontSize: "15px",
                              fontWeight: "bolder",
                              color: "white",
                              fontFamily: "-moz-initial",
                              minWidth: "170px",
                            }}
                          >
                            Student Name
                            {sortBy === "name" && (
                              <span>{sortOrder === "asc" ? " ↑" : " ↓"}</span>
                            )}
                          </h1>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: "#023047" }}
                        >
                          <h1
                            style={{
                              fontSize: "15px",
                              fontWeight: "bolder",
                              color: "white",
                              fontFamily: "-moz-initial",
                            }}
                          >
                            Father's Name
                          </h1>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: "#023047" }}
                        >
                          <h1
                            style={{
                              fontSize: "15px",
                              fontWeight: "bolder",
                              color: "white",
                              fontFamily: "-moz-initial",
                              minWidth: "180px",
                            }}
                          >
                            Branch
                          </h1>
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{ backgroundColor: "#023047" }}
                        >
                          <h1
                            style={{
                              fontSize: "15px",
                              fontWeight: "bolder",
                              color: "white",
                              fontFamily: "-moz-initial",
                              minWidth: "180px",
                            }}
                          >
                            Course Name
                          </h1>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: "#023047" }}
                        >
                          <h1
                            style={{
                              fontSize: "15px",
                              fontWeight: "bolder",
                              color: "white",
                              fontFamily: "-moz-initial",
                              minWidth: "180px",
                            }}
                          >
                            College Name
                          </h1>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ backgroundColor: "#023047" }}
                        >
                          <h1
                            style={{
                              fontSize: "15px",
                              fontWeight: "bolder",
                              color: "white",
                              fontFamily: "-moz-initial",
                            }}
                          >
                            Download ID Card
                          </h1>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(filteredData) &&
                        filteredData.map((student, index) => (
                          <TableRow key={student.randomId}>
                            <TableCell>
                              {index + 1 + page * rowsPerPage}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontWeight: "bold" }}
                            >
                              {student?.admissionSession}
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ fontWeight: "bold", color: "red" }}
                            >
                              {student?.enrollmentNumber || "not generated"}
                            </TableCell>
                            <TableCell align="center">
                              {student.randomId}
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{
                                color: "#6a040f",
                                fontWeight: "bold",
                              }}
                            >
                              {student.name}
                            </TableCell>
                            <TableCell align="center">
                              {student.fathersname}
                            </TableCell>
                            <TableCell align="center">
                              {student.courseBranch}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: "rgb(106, 4, 15)",
                                fontWeight: "bold",
                              }}
                            >
                              {student.courseName}
                            </TableCell>
                            <TableCell align="center">
                              {student.assignedCollege}
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                variant="contained"
                                color="success"
                                onClick={() => generateIDCardPDF(student)}
                              >
                                Download
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              <TablePagination
                rowsPerPageOptions={[10, 100, 500,2000]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
          <br />
        </Box>
      </>
    </ThemeProvider>
  );
});

export default Id_Card_Table;
