import React from "react";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";
import Trans from "../../images/Amemities/Transprotation.jpg";
import { Link } from "react-router-dom";


function  Forms() {
  return (
    <>
      <Nav />
      <br/>
      <style>
        {`
    
    
    .section_all {
        position: relative;
        padding-top: 20px;
        margin-left:-100px;
        padding-bottom: 80px;
        min-height: 100vh;
    }
   
    
    .about_icon i {
        font-size: 22px;
        height: 65px;
        width: 65px;
        line-height: 65px;
        display: inline-block;
        background: #fff;
        border-radius: 35px;
        color: #00bd2a;
        box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
    }
    
   
    .about_icon span {
        position: relative;
        top: -10px;
    }
    
    .about_content_box_all {
        padding: 28px;
    }
h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; margin-left:-30px;
}


.lli{
font-size:16px; 
margin-left:50px;
margin-top:10px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


   @media (max-width: 480px) {
   .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>
      <section className="section_all " id="about">
        <div className="container">
          <div class="two">
            <h1>Download Forms SSSUTMS</h1>
          </div>
          <br></br>

          <div className="row">
          <div className="col-lg-9">
              <p
                className="mt-0"
                style={{ textAlign: "justify", color: "black" }}
              >
        <style>
              {`
    .table-container {
      display: flex;
      margin-left:80px;
      flex-direction: row; /* Align tables in a row */
      gap: 20px; /* Space between tables */
      flex-wrap: wrap; /* Allows wrapping on smaller screens */
    } 
      .table_mca{
      width: 90%;
       margin-left: 70px;
        margin-top: 10px;
         border-collapse: collapse;
          }
    
    table {
      width: 100%; /* Set width to 48% to fit both tables side by side */
      border-collapse: collapse;
    } 
      
    
    th, td {
      padding: 5px;
      border: 1px solid #999;
      text-align: left;
      font-family: 'poppins',sansSerif;
    } 
      


  `}
            </style>

            <div className="tann">
  <table className="responsive-table">
  
    <thead>
      <tr>
        <th scope="col">S. No.</th>
        <th scope="col">Form</th>
        <th scope="col">Link</th> 
      
      
      </tr>
    </thead>
   
    <tbody>
   
      <tr>
        <td >1</td>
        <td style={{textAlign:'initial'}}> Registration Form</td>
        <td style={{color:'#2a6f97'}}><Link target='_blank' 
        to ='https://cdn.sssutms.ac.in/media/static/Forms/registrationform.pdf'><b>Download</b></Link></td>    
      </tr>
      <tr>
        <td >2</td>
        <td style={{textAlign:'initial'}}>
        Enrolment Form</td>
        <td style={{color:'#2a6f97'}}><Link target='_blank' to ='https://cdn.sssutms.ac.in/media/static/Forms/enrollmentform.pdf '><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >3</td>
        <td style={{textAlign:'initial'}}>Hostel Admission Form</td>
        <td style={{color:'#2a6f97'}}><Link target='_blank' to ='https://cdn.sssutms.ac.in/media/static/Forms/HOSTEL.pdf '><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >4</td>
        <td style={{textAlign:'initial'}}> Entrance Examination Form</td>
        <td style={{color:'#2a6f97'}}><Link target='_blank' to ='https://cdn.sssutms.ac.in/media/static/Forms/ENTRANCEFORM.pdf '><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >5</td>
        <td style={{textAlign:'initial'}}>
        Exam Form</td>
        <td style={{color:'#2a6f97'}}><Link target='_blank' to ='https://cdn.sssutms.ac.in/media/static/Forms/EXAMFORM.pdf '><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >6</td>
        <td style={{textAlign:'initial'}}>
        Revaluation forms</td>
        <td style={{color:'#2a6f97'}}><Link target='_blank' to ='https://cdn.sssutms.ac.in/media/static/Forms/REVAL.pdf '><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >7</td>
        <td style={{textAlign:'initial'}}>
        Degree Form (in English)</td>
        <td style={{color:'#2a6f97'}}><Link target='_blank' to ='https://cdn.sssutms.ac.in/media/static/Forms/DEGREE_Eng.pdf '><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >8</td>
        <td style={{textAlign:'initial'}}> Degree Form (in Hindi)</td>
        <td style={{color:'#2a6f97'}}><Link target='_blank' to ='https://cdn.sssutms.ac.in/media/static/Forms/DEGREE_FORM_R.pdf '><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >9</td>
        <td style={{textAlign:'initial'}}>Migration Form (In English)</td>
        <td style={{color:'#2a6f97'}}><Link target='_blank' to ='https://cdn.sssutms.ac.in/media/static/Forms/MIGRATION_Eng.pdf'><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >10</td>
        <td style={{textAlign:'initial'}}>Migration Form (In Hindi)</td>
        <td style={{color:'#2a6f97'}}><Link target='_blank' to ='https://cdn.sssutms.ac.in/media/static/Forms/MIGRATION FORM.pdf '><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >11</td>
        <td style={{textAlign:'initial'}}>Alumni Form</td>
        <td style={{color:'#2a6f97'}}><Link target='_blank' to ='https://cdn.sssutms.ac.in/media/static/Forms/alumni-form.pdf '><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >12</td>
        <td style={{textAlign:'initial'}}>Transfer Certificate Form</td>
        <td style={{color:'#2a6f97'}}><Link target='_blank' to ='https://cdn.sssutms.ac.in/media/static/Forms/TC.pdf '><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >13</td>
        <td style={{textAlign:'initial'}}>Registration In Higher Semester/Year</td>
        <td style={{color:'#2a6f97'}}><Link target='_blank' to ='https://cdn.sssutms.ac.in/media/static/Forms/Registration_higher_Sem_year_form.pdf '><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >14</td>
        <td style={{textAlign:'initial'}}>Transcript form</td>
        <td style={{color:'#2a6f97'}}><Link target='_blank' to ='https://cdn.sssutms.ac.in/media/static/Forms/Transcript.pdf '><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >15</td>
        <td style={{textAlign:'initial'}}>Mark-sheet Correction Form</td>
        <td style={{color:'#2a6f97'}}><Link target='_blank' to ='https://cdn.sssutms.ac.in/media/static/Forms/Marksheet_Correction_Form.pdf '><b>Download</b></Link></td>
      </tr>
  
    
    </tbody>
  </table>
</div>
              </p>
              <br/>
          
            </div>
            <div className="col-lg-3">
              <ul className="list-icon">

                <Link to = "/phd-notofication">
                <li className="lli">
                
                </li>
                </Link>
                <Link to = "/forms">
                <li className="lli">
                  <Link to ="/forms">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Forms
                  </Link>
                </li>
                </Link>
                <Link to = "/E-Content">
                <li className="lli">
                  <Link to ="/E-Content">
                    <i class="fa fa-adjust" aria-hidden="true"></i>E-Content
                  </Link>
                </li>
                </Link>
             
              </ul>
            </div>
          </div>
         
        </div>
      </section>
      <Footerpage />
    </>
  );
}

export default Forms;

