
// import React from "react";
// import { Link } from "react-router-dom";
// import "./Erp.css";
// import OnlyHeader from "../../AdminComponent/OnlyHeader";
// import student from "./../../images/IMG_ERP/student.png";
// import library from "./../../images/IMG_ERP/ErpLibrary.png";
// import adminn from "./../../images/IMG_ERP/adminn.png"
// import Hod from "./../../images/IMG_ERP/employee.png"
// import sodBuilding from '../../images/SOD-BUILDING.png';

// const Card = ({ image, title, description, link }) => (

//   <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3 col-xs-12">
//     <Link to={link} style={{ textDecoration: "none" }}>
//       <div className="card" style={{ marginTop: "20px" }}>
//         <div className="card-body">
//           <div className="card-image" style={{ color: "#213661" }}>
//             <img
//               src={image}
//               style={{ width: "100px", height: "100px", textAlign: "center" }}
//               alt={title}
//             />
//           </div>
//           <p className="card-text">{title}</p>
//           <p style={{ color: "black", fontSize: "14px", textAlign: "center" }}>
//             {description}
//           </p>
//         </div>
//       </div>
//     </Link>
//   </div>
// );

// function Erp() {
//   return (
//     <>
//       <OnlyHeader />
//       <div className="cardCont" style={{ position: 'relative', height: '100vh' }}>
//         <div
//           style={{
//             position: 'absolute',
//             top: 0,
//             left: 0,
//             right: 0,
//             bottom: 0,
//             backgroundImage: `url(${sodBuilding})`,
//             backgroundSize: 'cover',
//             backgroundPosition: 'center',
//             backgroundRepeat: 'no-repeat',
//             filter: 'blur(8px)', // Use filter for blur on the background
//             zIndex: -1, // Make sure it's behind the content
//           }}
//         />
//         <div className="container" style={{ paddingTop: '150px', position: 'relative', zIndex: 1 }}>
//           <div className="row">
//             <Card
//               image={student}
//               title="STUDENT LOGIN"
//               description="Click here for Student Login"
//               link="/erp/studentlogin"
//             />
//             <Card
//               image={Hod}
//               title="HOD LOGIN"
//               description="Click here for Hod Login"
//               link="/erp/hodlogin"
//             />
//             <Card
//               image={adminn}
//               title="ADMIN LOGIN"
//               description="Click here for Admin Login"
//               link="/adminlogin"
//             />
//             <Card
//               image={library}
//               title="WEB MAIL LOGIN"
//               description="Click here for Web Mail Login"
//               link="https://sssutms.ac.in:2096/"
//             />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Erp;

import React from 'react';
import { GraduationCap, Users, ShieldCheck, Mail } from 'lucide-react';
import OnlyHeader from '../../AdminComponent/OnlyHeader';


const LoginCard = ({ icon: Icon, title, description, link }) => {
  const cardStyle = {
    height: '100%',
    background: 'white',
    borderRadius: '1rem',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
    padding: '1.5rem',
    transition: 'all 0.3s ease',
    position: 'relative',
    overflow: 'hidden'
  };

  const gradientBarStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: 'linear-gradient(to right, #1e3c72, #2a5298)'
  };

  const decorativeCircleStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '10rem',
    height: '10rem',
    borderRadius: '50%',
    background: 'linear-gradient(135deg, rgba(30, 60, 114, 0.1), rgba(42, 82, 152, 0.05))',
    transform: 'translate(5rem, -5rem)',
    transition: 'transform 0.5s ease'
  };

  const iconContainerStyle = {
    position: 'relative',
    zIndex: 1
  };

  const arrowStyle = {
    position: 'absolute',
    bottom: '1rem',
    right: '1rem',
    padding: '0.5rem',
    borderRadius: '50%',
    background: '#1e3c72',
    color: 'white',
    opacity: 0,
    transform: 'translateX(1rem)',
    transition: 'all 0.3s ease'
  };

  return (
    <a
      href={link}
      style={{ display: 'block', textDecoration: 'none' }}
      onMouseOver={(e) => {
        e.currentTarget.querySelector('.card').style.transform = 'translateY(-0.5rem)';
        e.currentTarget.querySelector('.card').style.boxShadow = '0 20px 25px -5px rgba(0, 0, 0, 0.1)';
        e.currentTarget.querySelector('.circle').style.transform = 'translate(5rem, -5rem) scale(1.5)';
        e.currentTarget.querySelector('.arrow').style.opacity = '1';
        e.currentTarget.querySelector('.arrow').style.transform = 'translateX(0)';
      }}
      onMouseOut={(e) => {
        e.currentTarget.querySelector('.card').style.transform = 'translateY(0)';
        e.currentTarget.querySelector('.card').style.boxShadow = '0 4px 6px -1px rgba(0, 0, 0, 0.1)';
        e.currentTarget.querySelector('.circle').style.transform = 'translate(5rem, -5rem)';
        e.currentTarget.querySelector('.arrow').style.opacity = '0';
        e.currentTarget.querySelector('.arrow').style.transform = 'translateX(1rem)';
      }}
    >
      <div className="card" style={cardStyle}>
        <div style={gradientBarStyle} />
        <div className="circle" style={decorativeCircleStyle} />
        
        <div style={iconContainerStyle}>
          <Icon style={{ width: '3rem', height: '3rem', color: '#1e3c72', marginBottom: '1rem' }} />
          <h3 style={{ fontSize: '1.25rem', fontWeight: 600, color: '#1e3c72', marginBottom: '0.5rem' }}>
            {title}
          </h3>
          <p style={{ color: '#4B5563', marginBottom: '1rem' }}>
            {description}
          </p>
          
          <div className="arrow" style={arrowStyle}>
            <svg width="20" height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </div>
        </div>
      </div>
    </a>
  );
};

function Erp() {
  const cards = [
    {
      icon: GraduationCap,
      title: "Student Login",
      description: "Access your academic portal, view grades, and manage your course materials",
      link:  "/erp/studentlogin"
    },
    {
      icon: Users,
      title: "HOD Login",
      description: "Manage department activities, faculty, and academic programs",
      link: "/erp/hodlogin"
    },
    {
      icon: ShieldCheck,
      title: "Admin Login",
      description: "Access administrative controls and system management",
      link: "/adminlogin"
    },
    {
      icon: Mail,
      title: "Web Mail",
      description: "Access your university email and communications",
      link: "https://sssutms.ac.in:2096/"
    }
  ];

  const containerStyle = {
    minHeight: '100vh',
    background: 'linear-gradient(135deg, #f8fafc, #f1f5f9)'
  };

  const contentStyle = {
    position: 'relative',
    paddingTop: '5rem',
    paddingBottom: '3rem'
  };

  const decorativeElementStyle1 = {
    position: 'absolute',
    top: '10rem',
    left: 0,
    width: '18rem',
    height: '18rem',
    borderRadius: '50%',
    background: 'rgba(30, 60, 114, 0.05)',
    filter: 'blur(40px)'
  };

  const decorativeElementStyle2 = {
    position: 'absolute',
    bottom: '5rem',
    right: 0,
    width: '24rem',
    height: '24rem',
    borderRadius: '50%',
    background: 'rgba(42, 82, 152, 0.05)',
    filter: 'blur(40px)'
  };

  const mainContentStyle = {
    position: 'relative',
    maxWidth: '80rem',
    margin: '0 auto',
    padding: '0 1rem',
    paddingTop: '5rem',
    
  };

  const headerStyle = {
    textAlign: 'center',
    marginBottom: '2rem'
  };

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
    gap: '2rem'
  };

  const footerStyle = {
    background: 'rgba(255, 255, 255, 0.3)',
    backdropFilter: 'blur(8px)',
    borderTop: '1px solid rgba(229, 231, 235, 1)'
  };

  return (
    <div style={containerStyle}>
     <OnlyHeader/>
      
      <div style={contentStyle}>
        <div style={decorativeElementStyle1} />
        <div style={decorativeElementStyle2} />
        
        <div style={mainContentStyle}>
          <div style={headerStyle}>
            <h1 style={{
              fontSize: '2rem',
              fontWeight: 'bold',
              color: '#1e3c72',
              // marginBottom: '1rem'
            }}>
              Enterprise Resource Portal
            </h1>
            <p style={{
              fontSize: '1.25rem',
              color: '#4B5563',
              maxWidth: '48rem',
              margin: '0 auto'
            }}>
              Access all university resources through our integrated portal system
            </p>
          </div>

          <div style={gridStyle}>
            {cards.map((card, index) => (
              <LoginCard key={index} {...card} />
            ))}
          </div>
        </div>
      </div>

      <footer style={footerStyle}>
        <div style={{
          maxWidth: '80rem',
          margin: '0 auto',
          padding: '1.5rem 1rem',
          textAlign: 'center',
          color: '#4B5563'
        }}>
          <p>
            © {new Date().getFullYear()} Sri Satya Sai University of Technology and Medical Sciences. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Erp;