import React from "react";
import { Button } from "@mui/material";
import { Container, Form, Row, Col } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
import StudentDashboard from "../../../AdminComponent/StudentDashboard";



const Std_Select_Slip = () => {

  return (
    <>
      <StudentDashboard />
      <Container
        className="shadow p-3 bg-body rounded"
        style={{ marginTop: "80px", marginLeft: "11%" }}
      >
        <h3
          style={{
            fontWeight: "inherit",
            background: "#003049",
            borderRadius: "10px",
            color: "whitesmoke",
            padding: "5px",
          }}
        >
          Download EPravesh Fees Receipt
        </h3>
        <Form style={{ marginTop: "3%" }} >
          <Row>
            <Form.Group as={Col} controlId="admissionSession">
              <Form.Label></Form.Label>
              <div>
                <Form.Control
                  type="text"
                  name="admissionSession"

                />
              </div>
            </Form.Group>
            <Form.Group as={Col} controlId="courseName">
              <Form.Label></Form.Label>
              <div>
                <Form.Control
                  type="text"
                  name="courseName"

                />
              </div>
            </Form.Group>
            <Form.Group as={Col} controlId="searchButton">
              <Button
                variant="success"
                type="submit"
                style={{ marginLeft: "10px" }}
              >
                Search
              </Button>
            </Form.Group>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default Std_Select_Slip;


