
import {React,useEffect} from 'react';
import $ from "jquery";
import "magnific-popup/dist/magnific-popup.css";
import "magnific-popup";
import Nav from '../HomePage/Nav'
import SSSUTMS1 from '../images/SSSUTMS_Building(8).jpg'
import sssutms2 from '../images/SSSUTMS_Building(7).jpg'
import sssutms3 from '../images/img-31.jpg'
import sssutms4 from '../images/SSSUTMS_Building(6).jpg'
import sssutms5 from '../images/img-24.jpg'
import sssutms6 from '../images/img-25.jpg'
import sssutms7 from '../images/img-28.jpg'

import lib1 from '../images/img-10.jpg'
import lib2 from '../images/img-11.jpg'
import lib3 from '../images/img-12.jpg'
import lib4 from '../images/img-13.jpg'
import lib7 from '../images/img-16.jpg'
import lib8 from '../images/img-17.jpg'
import lib9 from '../images/img-18.jpg'
import lib10 from '../images/img-19.jpg'

import lab1 from '../images/img-20.jpg'
import lab2 from '../images/img-22.jpg'
import lab3 from '../images/img-26.jpg'


import hostel from '../images/img-27.jpg'
const Gallery = () => {
    useEffect(() => {
        $(".popup-gallery").magnificPopup({
          delegate: "a",
          type: "image",
          tLoading: "Loading image #%curr%...",
          mainClass: "mfp-img-mobile",
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [1, 2], // Preload previous and next images
          },
          image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function (item) {
              return item.el.attr("title") || "";
            },
          },
        });
      }, []);
    
      return (
        <>
        <style>
            {
                `
                .popup-gallery-container {
  text-align: left;
  padding: 20px;

  margin-top:10px;
}

.popup-gallery {
  display: flex;
  gap: 10px;
  margin-top:10px;
  justify-content: left;
  margin-left:80px;
  flex-wrap: wrap;
}

.popup-gallery a {
  position: relative;
  display: inline-block;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.popup-gallery a:hover {
  transform: scale(1.1);
}

.popup-gallery img {
  width: 260px;
  height: 180px;
  border-radius: 8px;
  transition: opacity 0.3s ease;
}

.popup-gallery a:hover img {
  opacity: 0.7;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.popup-gallery a:hover .overlay {
  opacity: 1;
}

.plus-icon {
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.heading {
  font-family: 'Poppins', Sans-serif;
  font-size: 25px;
  margin-left:50px;
  position: relative;
  display: inline-block;
}

.heading::after {
  content: "";
  display: block;
  width: 50px;
  height: 3px;
  background-color:brown; /* Change color as needed */
  margin: 5px auto 0;
  border-radius: 2px;
}

`
            }
        </style>
       <div style={{marginTop:'-20px'}}>
       <Nav/>
       </div>
        <div className="popup-gallery-container"  >
        <h2 className="heading">Building</h2>

          <div className="popup-gallery">
            <a href={SSSUTMS1} title="Image 1">
              <img src={SSSUTMS1}alt="Image 1" />
              <div className="overlay">
                <span className="plus-icon">+</span>
              </div>
            </a>
            <a href={sssutms6} title="Image 2">
              <img src={sssutms6} alt="Image 2" />
              <div className="overlay">
                <span className="plus-icon">+</span>
              </div>
            </a>

            <a href={sssutms3} title="Image 2">
              <img src={sssutms3} alt="Image 2" />
              <div className="overlay">
                <span className="plus-icon">+</span>
              </div>
            </a>

            <a href={sssutms4} title="Image 2">
              <img src={sssutms4} alt="Image 2" />
              <div className="overlay">
                <span className="plus-icon">+</span>
              </div>
            </a>
         
          </div>


          <div className="popup-gallery">
            <a href={sssutms5} title="Image 1">
              <img src={sssutms5}alt="Image 1" />
              <div className="overlay">
                <span className="plus-icon">+</span>
              </div>
            </a>
          
            <a href={sssutms7} title="Image 2">
              <img src={sssutms7} alt="Image 2" />
              <div className="overlay">
                <span className="plus-icon">+</span>
              </div>
            </a>
            <a href={sssutms2} title="Image 2">
              <img src={sssutms2} alt="Image 2" />
              <div className="overlay">
                <span className="plus-icon">+</span>
              </div>
            </a>
         
          
          </div>
        </div>
        <br>
        </br>

  <div className="popup-gallery-container" >
        <h2 className="heading">Library</h2>

          <div className="popup-gallery">
            <a href={lib1} title="Image 1">
              <img src={lib1 } alt="Image 1" />
              <div className="overlay">
                <span className="plus-icon">+</span>
              </div>
            </a>
            <a href={lib2 } title="Image 2">
              <img src={lib2 } alt="Image 2" />
              <div className="overlay">
                <span className="plus-icon">+</span>
              </div>
            </a>

            <a href={lib3} title="Image 2">
              <img src={lib3} alt="Image 2" />
              <div className="overlay">
                <span className="plus-icon">+</span>
              </div>
            </a>

            <a href={lib4} title="Image 2">
              <img src={lib4} alt="Image 2" />
              <div className="overlay">
                <span className="plus-icon">+</span>
              </div>
            </a>
         
          </div>


          <div className="popup-gallery">
            <a href={lib10} title="Image 1">
              <img src={lib10}alt="Image 1" />
              <div className="overlay">
                <span className="plus-icon">+</span>
              </div>
            </a>
          
            <a href={lib9} title="Image 2">
              <img src={lib9} alt="Image 2" />
              <div className="overlay">
                <span className="plus-icon">+</span>
              </div>
            </a>
            <a href={lib7} title="Image 2">
              <img src={lib7} alt="Image 2" />
              <div className="overlay">
                <span className="plus-icon">+</span>
              </div>
            </a>
         
            <a href={lib8} title="Image 2">
              <img src={lib8} alt="Image 2" />
              <div className="overlay">
                <span className="plus-icon">+</span>
              </div>
            </a>
          </div>


        </div>
        <br>
        </br>

 <div className="popup-gallery-container" >
        <h2 className="heading">Laboratory</h2>

          <div className="popup-gallery">
            <a href={lab1} title="Image 1">
              <img src={lab1 } alt="Image 1" />
              <div className="overlay">
                <span className="plus-icon">+</span>
              </div>
            </a>
            <a href={lab2 } title="Image 2">
              <img src={lab2 } alt="Image 2" />
              <div className="overlay">
                <span className="plus-icon">+</span>
              </div>
            </a>

            <a href={lab3} title="Image 2">
              <img src={lab3} alt="Image 2" />
              <div className="overlay">
                <span className="plus-icon">+</span>
              </div>
            </a>

         
         
          </div>



        </div>


        <br></br>
        <div className="popup-gallery-container" >
        <h2 className="heading">Hostel</h2>

          <div className="popup-gallery">
            <a href={hostel} title="Image 1">
              <img src={hostel } alt="Image 1" />
              <div className="overlay">
                <span className="plus-icon">+</span>
              </div>
            </a>
        
          
         
         
          </div>



        </div>
        </>
      );
    };
    
export default Gallery
