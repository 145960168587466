import React, { useEffect, useState } from "react";
import { TableCell } from "@mui/material";
import DatePicker from "react-datepicker";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow"
import HodDashboard from "./HodDashboard";
import { Container } from "react-bootstrap";


const TotalRegisStdtHod = () => {

  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [studentdata, setStudentData] = useState([]);

  /////////////////////datepicker/////////////////
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const userData = JSON.parse(sessionStorage.getItem("AnkitHOD"));
  const branch1 = userData?.Branch || "";
  const courseName = userData?.courseName || "";
  const college = userData?.college || "";
  const Associated = userData?.Associate_branch
  const user = userData?.user
  useEffect(() => {
    const fetchData = async () => {
      try {
        let apiUrl = Array.isArray(Associated) && Associated.length === 0 ? `${process.env.REACT_APP_HOD_TOTAL_REGISTERED_STD}?branch=${userData.branch}&course=${courseName}&college=${college}`
          : `https://sssutms.ac.in/apitest/hod/today-list?hodId=${user}`


        const response = await fetch(apiUrl);
        const data = await response.json();


        setStudentData(data.students);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Fetch data whenever dependencies (session, courseType, etc.) change
    fetchData();
  }, [userData.branch, courseName, college]);
  // console.log(studentdata);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // console.log(studentdata, "data of student from use state");

  const branchname = studentdata.courseBranch;
  const coursename = studentdata.coursename;
  const studentId = studentdata._id;
  const assignedCollege = studentdata.assignedCollege;

  return (
    <>
      <HodDashboard />

      <Container
        className="shadow p-3  bg-body rounded"
        style={{
          marginTop: "80px",
          width: "90%",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <h4 style={{ textAlign: "center" }}>TODAY'S REGISTERED</h4>
        <br></br>
        <Paper sx={{ width: "100%", overflow: "auto" }}>
          <TableContainer sx={{ maxHeight: 500 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{ backgroundColor: "rgb(2, 48, 71)" }}
                  >
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: "bolder",
                        color: "white",
                        fontFamily: "-moz-initial",
                      }}
                    >
                      S.No.
                    </h1>
                  </TableCell>

                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: "rgb(2, 48, 71)",
                      minWidth: "200px",
                    }}
                  >
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: "bolder",
                        color: "white",
                        minWidth: "250px",
                        fontFamily: "-moz-initial",
                      }}
                    >
                      Course Name
                    </h1>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: "rgb(2, 48, 71)",
                      minWidth: "200px",
                    }}
                  >
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: "bolder",
                        color: "white",
                        fontFamily: "-moz-initial",
                      }}
                    >
                      Course Branch
                    </h1>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ backgroundColor: "rgb(2, 48, 71)" }}
                  >
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: "bolder",
                        color: "white",

                        fontFamily: "-moz-initial",
                      }}
                    >
                      Registration ID
                    </h1>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ backgroundColor: "rgb(2, 48, 71)" }}
                  >
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: "bolder",
                        color: "white",
                        fontFamily: "-moz-initial",
                      }}
                    >
                      Registration Password
                    </h1>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ backgroundColor: "rgb(2, 48, 71)" }}
                  >
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: "bolder",
                        color: "white",
                        fontFamily: "-moz-initial",
                      }}
                    >
                      Registered Date
                    </h1>
                  </TableCell>

                  <TableCell
                    align="left"
                    style={{ backgroundColor: "rgb(2, 48, 71)" }}
                  >
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: "bolder",
                        color: "white",
                        minWidth: "150px",
                        fontFamily: "-moz-initial",
                      }}
                    >
                      Student Name
                    </h1>
                  </TableCell>

                  <TableCell
                    align="left"
                    style={{ backgroundColor: "rgb(2, 48, 71)" }}
                  >
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: "bolder",
                        color: "white",
                        minWidth: "150px",
                        fontFamily: "-moz-initial",
                      }}
                    >
                      Father's Name
                    </h1>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ backgroundColor: "rgb(2, 48, 71)" }}
                  >
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: "bolder",
                        color: "white",
                        minWidth: "150px",
                        fontFamily: "-moz-initial",
                      }}
                    >
                      Mother's Name
                    </h1>
                  </TableCell>

                  <TableCell
                    align="left"
                    style={{ backgroundColor: "rgb(2, 48, 71)" }}
                  >
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: "bolder",
                        color: "white",
                        fontFamily: "-moz-initial",
                      }}
                    >
                      Contact
                    </h1>
                  </TableCell>

                  <TableCell
                    align="left"
                    style={{ backgroundColor: "rgb(2, 48, 71)" }}
                  >
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: "bolder",
                        color: "white",
                        minWidth: "200px",
                        fontFamily: "-moz-initial",
                      }}
                    >
                      Email
                    </h1>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentdata &&
                  studentdata?.map((student, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      {/* <TableCell align="center">2023</TableCell>
                        <TableCell align="center">{student.name}</TableCell>
                        <TableCell align="center">{student.email}</TableCell>
                        {/* Replace the line below with the DatePicker component */}

                      <TableCell
                        align="left"
                        style={{ color: "#921A40", fontWeight: "700" }}
                      >
                        {student?.courseName}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ color: "#914F1E", fontWeight: "700" }}
                      >
                        {student?.courseBranch}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{ color: "#921A40", fontWeight: "700" }}
                      >
                        {student?.randomId}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{ color: "#173B45", fontWeight: "700" }}
                      >
                        {student?.randomPassword}
                      </TableCell>

                      <TableCell align="center">
                        <DatePicker
                          selected={
                            selectedDate || new Date(student?.createdAt)
                          }
                          readOnly
                          dateFormat="dd/MM/yyyy HH:mm"
                          className="text-center"
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ color: "#914F1E", fontWeight: "700" }}
                      >
                        {student?.name}
                      </TableCell>

                      <TableCell align="left" style={{ fontWeight: "700" }}>
                        {student?.fathersname}
                      </TableCell>

                      <TableCell align="left">
                        {student?.mothersname}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{ fontWeight: "700", color: "#1679AB" }}
                      >
                        {student?.mobile}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{ fontWeight: "700", color: "#973131" }}
                      >
                        {student?.email}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[2, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Container>

    </>
  );
};

export default TotalRegisStdtHod;
