import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Button,
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
  CircularProgress,
  IconButton,
  InputAdornment,
  Container,
  useTheme,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import { Visibility, VisibilityOff, School, Email, Lock } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserData } from "../REDUX/userSlice";
import swal from "sweetalert";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  Password: yup.string().required("Password is required"),
});

export default function HodLogin() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const formik = useFormik({
    initialValues: {
      email: "",
      Password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await fetch("https://sssutms.ac.in/apitest/hod/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });
        
        if (response.ok) {
          const data = await response.json();
          dispatch(setUserData(data.UserResponse));
          navigate("/dashboard");
        } else {
          swal({
            icon: "error",
            title: "Error",
            text: "Invalid Credentials",
          });
        }
      } catch (error) {
        swal({
          icon: "error",
          title: "Error",
          text: "Something Went Wrong!",
        });
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const handlePopState = () => {
      window.history.pushState(null, document.title, window.location.href);
    };

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <>
    <Box
      sx={{
        minHeight: '100vh',
        // background: 'linear-gradient(135deg, #1e3c72 0%, #2a5298 100%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: { xs: 2, sm: 4 }
      }}
    >
      <Card
        sx={{
          maxWidth: 1000,
          width: '100%',
          borderRadius: 3,
          overflow: 'hidden',
          boxShadow: '0 10px 40px rgba(0,0,0,0.25)',
        }}
      >
        <Grid container>
        <Grid
            item
            xs={12}
            md={6}
            sx={{
              background: 'linear-gradient(rgba(26, 35, 126, 0.8), rgba(13, 71, 161, 0.9)), url(https://images.pexels.com/photos/267885/pexels-photo-267885.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: { xs: 'none', md: 'flex' },
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              p: 6,
              color: 'white',
              position: 'relative'
            }}
          >
            <Box
              sx={{
                textAlign: 'center',
                zIndex: 1
              }}
            >
              <Typography variant="h3" fontWeight="bold" mb={3}>
                Welcome Back
              </Typography>
              <Typography variant="h6" sx={{ opacity: 0.9, maxWidth: '80%', mx: 'auto' }}>
                Access your department's dashboard and manage academic resources efficiently
              </Typography>
            </Box>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <CardContent sx={{ p: { xs: 3, md: 5 } }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  mb: 4
                }}
              >
                <Box
                  component="img"
                  src="https://cdn.sssutms.ac.in/media/static/images/SSSUTMS_LOGO.png"
                  alt="University Logo"
                  sx={{
                    width: 95,
                    height: 80,
                    mb: 2,
                    filter: 'drop-shadow(0 2px 5px rgba(0,0,0,0.2))'
                  }}
                />
                <Typography variant="h5" fontWeight="bold" gutterBottom>
                  HOD Login Portal
                </Typography>
                <Typography variant="body2" color="text.secondary" textAlign="center">
                  Sign in to access your department's dashboard
                </Typography>
              </Box>

              <form onSubmit={formik.handleSubmit}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email Address"
                  variant="outlined"
                  margin="normal"
                  autoComplete="off"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email color="action" />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  name="Password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  autoComplete="off"
                  value={formik.values.Password}
                  onChange={formik.handleChange}
                  error={formik.touched.Password && Boolean(formik.errors.Password)}
                  helperText={formik.touched.Password && formik.errors.Password}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock color="action" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mb: 3 }}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  disabled={loading}
                  sx={{
                    py: 1.5,
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    background: 'linear-gradient(45deg, #1e3c72 30%, #2a5298 90%)',
                    boxShadow: '0 3px 15px rgba(30,60,114,0.3)',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #1e3c72 60%, #2a5298 90%)',
                    }
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Sign In"
                  )}
                </Button>

                <Box sx={{ mt: 3, textAlign: 'center' }}>
                  <Button
                    variant="text"
                    onClick={() => {
                      swal({
                        title: "Contact Admin",
                        text: "Please contact your administrator to reset your password.",
                        icon: "info",
                      });
                    }}
                    sx={{
                      color: theme.palette.text.secondary,
                      '&:hover': {
                        color: theme.palette.primary.main,
                      }
                    }}
                  >
                    Forgot Password?
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Box>
    </>
  );
}
