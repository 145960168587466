import { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Box,
  Container,
  Paper,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from '@mui/material';
import {
  School,
  CheckCircle,
  Email,
  Payment,
  VerifiedUser,
  SupervisorAccount
} from '@mui/icons-material';
import OnlyHeader from '../OnlyHeader';

const E_Waiting = () => {
  const navigate = useNavigate();



  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      navigate("/student/erp/studentlogin", { replace: true });
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: '#f5f5f5' }}>
      <OnlyHeader />

      <Container maxWidth="md" sx={{ mt: 8 }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: 2,
            background: 'linear-gradient(to bottom right, #ffffff, #f8f9fa)'
          }}
        >
          {/* Success Header */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
            <Box
              sx={{
                bgcolor: '#e8f5e9',
                borderRadius: '50%',
                p: 2,
                mr: 2,
                display: 'flex'
              }}
            >
              <CheckCircle sx={{ fontSize: 40, color: '#2e7d32' }} />
            </Box>
            <Box>
              <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
                Congratulations, Candidate! 🎉
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Email sx={{ fontSize: 20, color: '#666', mr: 1 }} />
                <Typography variant="subtitle1" color="text.secondary">
                  Check your email for login credentials
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Main Content */}
          <Typography variant="body1" paragraph>
          Your registration has been successfully completed. We have sent your ID and password to your registered email address.
          </Typography>

          {/* Requirements List */}
          <Paper sx={{ bgcolor: '#f3f8ff', p: 3, mb: 2, borderLeft: 4, borderColor: 'primary.main' }}>
            <Typography variant="h6" gutterBottom>
              Please note: Your admission is confirmed now next process will be:
            </Typography>
            <List>
            <ListItem>
                <ListItemIcon>
                  <SupervisorAccount color="primary" />
                </ListItemIcon>
                <ListItemText primary="Approval from the Head of Department (HOD)" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <VerifiedUser color="primary" />
                </ListItemIcon>
                <ListItemText primary="Verification of original documents at the department" />
              </ListItem>

              <ListItem>
                
                <ListItemIcon>
                  <Payment color="primary" />
                </ListItemIcon>
                <ListItemText primary="Submission of your annual fee" />
              </ListItem>
          
           
            </List>
          </Paper>

          <Typography variant="body1" paragraph>
            Once your documents are verified and approval is granted, you'll receive a confirmation email.
            After receiving the email, you can log in and proceed with the registration fee payment.
          </Typography>

          <Divider sx={{ my: 3 }} />

          {/* <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Admission Cell
            <br />
            SSSUTMS
          </Typography> */}

          {/* Action Button */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2}}>
            <Link to="/erp/studentlogin" style={{ textDecoration: 'none' }}>
              <Button
                variant="contained"
                size="large"
                sx={{
                  background: 'linear-gradient(45deg, #1976d2 30%, #42a5f5 90%)',
                  color: 'white',
                  px: 4,
                  py: 1.5,
                  '&:hover': {
                    background: 'linear-gradient(45deg, #1565c0 30%, #1976d2 90%)'
                  }
                }}
              >
                Proceed to Login
              </Button>
            </Link>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default E_Waiting;