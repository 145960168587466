import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import {
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme();

const ExamCenterAllocate = () => {
  const [centers, setCenters] = useState([]);
  const [search, setSearch] = useState("");

  // Fetch data from API
  useEffect(() => {
    const fetchCenters = async () => {
      try {
        const response = await fetch(
          "https://sssutms.ac.in/apitest/admin/get-centers"
        );
        const data = await response.json();
        setCenters(data.centers);
      } catch (error) {
        console.error("Error fetching centers:", error);
      }
    };

    fetchCenters();
  }, []);

  // Filtered data based on search
  const filteredCenters = centers.filter(
    (center) =>
      center.courseName.toLowerCase().includes(search.toLowerCase()) ||
      center.CenterName.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      <style>
        {`
        *
        {
                font-family:'Poppins',Sans-serif;
                }`}
      </style>
      <ThemeProvider theme={theme}>
        <div
          className="shadow p-3 bg-body rounded"
          style={{
            width: "90%",
            backgroundColor: "#00cdac",
            marginTop: "-10px",
            marginLeft: "100px",
          }}
        >
          <Box sx={{ p: 2, width: "300px", ml: "auto", marginTop: "-20px" }}>
      
          </Box>
          <Paper sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: "440px" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {[
                      "S.No.",
                      "Course Name",
                      "Branch Name",
                      "Session",
                      "Semester",
                      "Center Code",
                      "Center Name",
                      "Start Time",
                      "End Time",
                    ].map((header) => (
                      <TableCell
                        key={header}
                        align="left"
                        style={{
                          backgroundColor: "#023047",
                          color: "white",
                          position: "sticky",
                          top: 0,
                          height: "50px",
                          zIndex: 1,
                          fontFamily: "'Poppins',Sans-serif",
                          padding: "5px",
                          fontSize: "14px",
                          fontStyle: "normal",
                        }}
                      >
                        <b>{header}</b>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredCenters.map((center, index) => (
                    <TableRow key={center._id}>
                      <TableCell style={{ width: "40px" }}>
                        {index + 1}
                      </TableCell>
                      <TableCell style={{ width: "200px" }}>
                        {center.courseName}
                      </TableCell>
                      <TableCell style={{ width: "200px" }}>
                        {center.courseBranch}
                      </TableCell>
                      <TableCell style={{ width: "80px" }}>
                        {center.Exam_Session}
                      </TableCell>
                      <TableCell style={{ width: "80px" }}>
                        {center.Semester}
                      </TableCell>
                      <TableCell style={{ width: "100px" }}>
                        {center.centerCode}
                      </TableCell>
                      <TableCell style={{ width: "130px" }}>
                        {center.CenterName}
                      </TableCell>
                      <TableCell style={{ width: "80px" }}>
                        {center.StartTime}
                      </TableCell>
                      <TableCell style={{ width: "80px" }}>
                        {center.EndTime}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </ThemeProvider>{" "}
    </>
  );
};

export default ExamCenterAllocate;
