import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";
import axios from "axios";
import SearchPen from "./SearchPen";

const AdminPenSearch = () => {
  const [sessions, setSessions] = useState([]); // Array of session objects
  const [selectedSession, setSelectedSession] = useState("");
  const [courseType, setCourseType] = useState("");
  const [course, setCourse] = useState("");
  const [branch, setBranch] = useState("");
  const [college, setCollege] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  const handleSearch = () => {
    if (!sessions || !courseType || !course || !branch) {
      alert("please fill all fields first");
      return;
    }
    setShowTable(true);
  };

  useEffect(() => {
    axios
      .get("https://sssutms.ac.in/apitest/v2/admin/courses2")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  useEffect(() => {
    setLoading(true);
    fetch("https://sssutms.ac.in/apitest/v2/admission-sessions")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Fetched sessions data:", data);
        setSessions(Array.isArray(data.data) ? data.data : []); // Access data.data
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching sessions:", error);
        setSessions([]); // Fallback to empty array
        setLoading(false);
      });
  }, []);
  const getCourseTypes = () => {
    const types = new Set(data.map((item) => item.courseType));
    return Array.from(types);
  };

  const getCourses = () => {
    if (!courseType) return [];
    const courses = data
      .filter((item) => item.courseType === courseType)
      .flatMap((item) => item.courseNames.map((course) => course.courseName));
    return courses;
  };

  const getBranches = () => {
    if (!course) return [];
    const branches = data
      .flatMap((item) => item.courseNames)
      .filter((courseItem) => courseItem.courseName === course)
      .flatMap((courseItem) =>
        courseItem.branches.map((branch) => branch.branchName)
      );
    return branches;
  };

  return (
    <>
      <AdminDashboard />
      <style>
        {`

      .custom-dropdown select {
     font-family: "Rubik", sans-serif;
  font-size: 14px;
  color: black; /* default color for the select element */
  }

  .custom-dropdown option {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight:700
  }
             .shadow-box {
         box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
         border-radius: 10px;
         padding: 20px;
         margin-top: 70px;
         width: 100%; /* Adjusted width */
         margin-left:130px;
         height:170px;
         width:1180px
        
      }
         
  
            .header {
        height: 50px;
        // background: linear-gradient(#004e92, #990033);
        border-radius: 10px;
        color: #003049;
        font-weight:700;
        display: flex;
        margin-top:-50px;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        
      }`}
      </style>

      <div
        className="shadow-box"
        fluid
        style={{ paddingTop: "50px", height: "170px" }}
      >
        <div className="header">PENDING STUDENT </div>

        <Row style={{ marginTop: "10px" }}>
          <Col>
            <div className="custom-dropdown">
              <select
                className="form-select"
                required={true}
                onChange={(e) => setSelectedSession(e.target.value)}                style={{ width: "250px", height: "45px" }}
                disabled={loading} // Disable while loading
              >
                <option value="">Select</option>
                {loading ? (
                  <option>Loading...</option>
                ) : (
                  sessions.map((sess, index) => (
                    <option key={index} value={sess.sessionName}>
                      {sess.sessionName}
                    </option>
                  ))
                )}
              </select>
            </div>
          </Col>
          <Col>
            <div className="custom-dropdown">
              <select
                className="form-select"
                required={true}
                onChange={(e) => setCourseType(e.target.value)}
                style={{ width: "250px", height: "45px" }}
              >
                <option selected>Course Type</option>
                {getCourseTypes().map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          </Col>
          <Col>
            <div className="custom-dropdown">
              <select
                className="form-select"
                required={true}
                onChange={(e) => setCourse(e.target.value)}
                style={{ width: "250px", height: "45px" }}
              >
                <option selected>Course</option>
                {getCourses().map((courseName, index) => (
                  <option key={index} value={courseName}>
                    {courseName}
                  </option>
                ))}
              </select>
            </div>
          </Col>
          <Col>
            <div className="custom-dropdown">
              <select
                className="form-select"
                required={true}
                onChange={(e) => setBranch(e.target.value)}
                autoFocus={true}
                style={{ width: "250px", height: "45px" }}
              >
                <option selected>Branch</option>
                {getBranches().map((branchName, index) => (
                  <option key={index} value={branchName}>
                    {branchName}
                  </option>
                ))}
              </select>
            </div>
          </Col>
        </Row>

        <Row>
          <Button
            style={{
              marginLeft: "50px",
              backgroundColor: "#001d3d",
              width: "110px",
              marginTop: "10px",
              height: "40px",
              padding: "5px",
            }}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Row>
      </div>

      <div style={{ marginTop: "-50px", width: "95%", marginLeft: "30px" }}>
        {showTable && (
          <div>
            <SearchPen
              session={selectedSession}
              courseType={courseType}
              courseName={course}
              branch={branch}
              college={college}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default AdminPenSearch;
