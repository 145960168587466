import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userData: JSON.parse(sessionStorage.getItem('AnkitHOD')) || null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
      sessionStorage.setItem('AnkitHOD', JSON.stringify(action.payload));
    },
    clearUserData: (state) => {
      state.userData = null;
      sessionStorage.removeItem('AnkitHOD');
    },
  },
});

export const { setUserData, clearUserData } = userSlice.actions;

export default userSlice.reducer;
