import React from "react";
import Footerpage from "../../HomePage/Footerpage";
import Nav from "../../HomePage/Nav";

function N_LokAdaltDay() {
  const images = ["ns1.jpg", "ns2.jpg", "ns3.jpg", "ns5.jpg"];

  return (
    <>
      <Nav />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "8px",
          padding: "10px",
        }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            style={{
              flex: "1 1 calc(25% - 16px)",
              maxWidth: "calc(25% - 16px)",
              minWidth: "150px",
            }}
          >
            <a
              href={`https://cdn.sssutms.ac.in/media/static/nastional lok aadalt day Press media/${image}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={`https://cdn.sssutms.ac.in/media/static/nastional lok aadalt day Press media/${image}`}
                alt={`Ashwagandha News ${index + 1}`}
                style={{
                  width: "100%",
                  height: "auto",
                  border: "2px solid #009999",
                  display: "block",
                }}
              />
            </a>
          </div>
        ))}
      </div>

      <Footerpage />
    </>
  );
}

export default N_LokAdaltDay;
