// import React, { useState } from "react";
// import AdminDashboard from "../HodDashboard"
// import Form from "react-bootstrap/Form";
// import { FormControl } from "@mui/material";
// import { Button, Row, Col, Container } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";

// function Provisional_Pay_Search() {
//   const [selectedField, setSelectedField] = useState("option1");
//   const [enteredValue, setEnteredValue] = useState("");
//   const [userData, setUserData] = useState([]);
//   const [showTable, setShowTable] = useState(false);
//   const navigate = useNavigate();

//   const handleSearch = async () => {
//     if (selectedField === "option1" || !enteredValue) {
//       alert("Please select a valid search field and enter a value.");
//       return;
//     }

//     setShowTable(false);
//     try {
//       const api = `https://sssutms.ac.in/apitest/v1/search?randomId=${enteredValue}`;
//       const response = await axios.get(api);

//       if (response.data) {
//         const student = response.data;

//         navigate("/student/provisional/payment", { state: { student } });
//       } else {
//         alert("No student found with this ID");
//       }
//     } catch (error) {
//       console.error("API Error:", error);
//       alert("Student not found.");
//     }
//     // console.log()
//   };
//   return (
//     <>
//       <style>
//         {`
//           .shadow-box {
//             box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
//             border-radius: 10px;
//             padding: 10px;
//             width:300px;
//             margin-Top:10%;

//           }

//           .header {
//             height: 50px;
//             background: linear-gradient(#004e92, #990033);
//             border-radius: 10px;
//             color: white;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             font-size: 20px;
//             font-family: italic;
//           }

//           .scroll-container {
//             overflow-y: auto;
//             margin-top: 10px;
//           }

//           .login-input {
//             width: 100%;
//             border: none;
//             border-bottom: 2px solid rgba(0, 0, 0, 0.2);
//             background: transparent;
//             font-size: 1rem;
//             font-family: 'Roboto', sans-serif;
//             font-weight: 500;
//             transition: border-bottom 0.2s;
//           }

//           .login-input:focus {
//             outline: none;
//             border-bottom: 2px solid #344e41;
//           }

//           @media (max-width: 768px) {
//             .shadow-box {
//               padding: 10px;
//               margin-top: 10px;
//             }

//             .header {
//               font-size: 16px;
//               height: 40px;
//             }

//             .login-input {
//               font-size: 0.9rem;
//             }
//           }

//           @media (max-width: 576px) {
//             .shadow-box {
//               margin: 10px;
//               margin-Left:76px;
//             }

//             .container-fluid {
//               width: 100%;
//               padding-right: 0;
//               padding-left: 0;
//             }
//           }
//           @media (min-width: 992px) {
//     .custom-laptop-style {
//        margin-left:5%;
//     }
// }

//         `}
//       </style>

//       <AdminDashboard />

//       <div style={{ marginLeft: "5%", display: "flex" }}>
//         <div>
//           <Container fluid style={{ paddingTop: "80px" }}>
//             <Row>
//               <Col
//                 xs={12}
//                 sm={12}
//                 md={10}
//                 lg={9}
//                 xl={8}
//                 className="custom-laptop-style"
//               >
//                 <div className="shadow-box">
//                   <div className="header">Provisional Payment Verification</div>
//                   <div className="scroll-container" style={{ height: "290px" }}>
//                     <form style={{ padding: "30px" }}>
//                       <Row>
//                         <FormControl>
//                           <label>
//                             <h1
//                               style={{
//                                 color: "#264653",

//                                 fontSize: "18px",

//                                 fontWeight: "bold",

//                                 fontFamily: "sans-serif",
//                               }}
//                             >
//                               Search by *
//                             </h1>
//                           </label>
//                           <Form.Select
//                             className="login-input"
//                             value={selectedField}
//                             onChange={(e) => setSelectedField(e.target.value)}
//                             required={true}
//                             autoFocus={true}
//                           >
//                             <option value="option1">Select</option>
//                             <option value="Regestration No">
//                               Registration No
//                             </option>
//                           </Form.Select>
//                         </FormControl>
//                       </Row>
//                       <br></br>
//                       <Row>
//                         <FormControl>
//                           <label>
//                             <h1
//                               style={{
//                                 color: "#264653",

//                                 fontSize: "18px",

//                                 fontWeight: "bold",

//                                 fontFamily: "sans-serif",
//                               }}
//                             >
//                               Enter *
//                             </h1>
//                           </label>
//                           <Form.Control
//                             className="login-input"
//                             type="text"
//                             value={enteredValue}
//                             onChange={(e) => setEnteredValue(e.target.value)}
//                           />
//                           <br />
//                         </FormControl>
//                       </Row>
//                       <Button
//                         onClick={handleSearch}
//                         style={{
//                           marginLeft: "40px",
//                           backgroundColor: "#004e92",
//                           width: "110px",
//                           height: "40px",
//                           padding: "5px",
//                         }}
//                       >
//                         Search
//                       </Button>
//                     </form>
//                   </div>
//                 </div>
//               </Col>
//             </Row>
//           </Container>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Provisional_Pay_Search;

import React, { useState } from "react";
import axios from "axios";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AdminDashboard from "../HodDashboard";

function Provisional_Pay_Search() {
  const [enteredValue, setEnteredValue] = useState("");
  const [studentData, setStudentData] = useState(null);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    reciept_No: "",
    paymentMode: "",
    Amount: "",
    Remark: "Student Admitted through Provisional Amount",
    payment_date: "",
    status: "",
    studentId: "",
    name: "",
    randomId: "",
    email: "",
    mobile: "",
    fathersname: "",
    mothersname: "",
    courseName: "",
    courseType: "",
    courseBranch: "",
    session: "",
  });

  const handleSearch = async () => {
    if (!enteredValue) {
      alert("Please enter a Registration Number.");
      return;
    }

    try {
      const api = `https://sssutms.ac.in/apitest/v1/search?randomId=${enteredValue}`;
      const response = await axios.get(api);

      if (response.data) {
        const student = response.data;
        setStudentData(student);
        setFormData((prev) => ({
          ...prev,
          studentId: student._id,
          name: student.name,
          randomId: student.randomId,
          email: student.email,
          mobile: student.mobile,
          fathersname: student.fathersname,
          mothersname: student.mothersname,
          courseName: student.courseName,
          courseType: student.courseType,
          courseBranch: student.courseBranch,
          session: student.session,
        }));
      } else {
        alert("No student found with this ID.");
      }
    } catch (error) {
      console.error("API Error:", error);
      alert("Student not found.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://sssutms.ac.in/apitest/provisional-payment",
        formData
      );

      const payment = response.data.payment;
      navigate("/student/provisional/receipt", {
        state: { student: formData, payment },
      });
    
    } catch (error) {
      console.error(error);
      alert("Failed to save payment information");
    }
  };

  return (
    <>
      <style>
        {`
*{
font-family:'Poppins',Sans-serif;
}
.payment-form-container {
  width: 100%;
  max-width: 1200px;
  margin-left: -10px;
  margin-top:-30px;
  
}.payment-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-row {
  display: flex;
  gap: 15px;
  justify-content: space-between;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.form-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-button {
  margin-top: 15px;
  background-color: #004e92;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #003b73;
}
`}
      </style>
      <AdminDashboard />
      <Container fluid style={{ marginTop: "70px", marginLeft: "90px" }}>
        <div className="bg-body rounded" style={{ width: "1100px" }}>
          <h4 className="text-center" style={{ color: "#16404D" }}>
            Provisional Payment Verification
          </h4>
          <div className="d-flex flex-column mt-3">
            <label
              className="mb-2"
              style={{ fontWeight: "bold", color: "#264653" }}
            >
              Enter Registration Number *
            </label>
            <div className="d-flex">
              <input
                type="text"
                className="form-control me-2"
                value={enteredValue}
                onChange={(e) => setEnteredValue(e.target.value)}
                placeholder="Enter Registration Number"
              />
              <button
                style={{ height: "40px", width: "100px", padding: "5px" }}
                className="btn btn-primary"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        {studentData && (
          <div
            className="payment-form-container mt-2"
            style={{ height: "400px" }}
          >
            <form onSubmit={handleSubmit} className="payment-form">
              {/* Row 1: Session, Receipt No, Payment Mode, Amount */}
              <div className="form-row">
                {["session", "reciept_No", "paymentMode", "Amount"].map(
                  (key) => (
                    <div key={key} className="form-group">
                      <label className="form-label">
                        {key.replace(/([A-Z])/g, " $1")}
                      </label>
                      <input
                        name={key}
                        value={formData[key]}
                        onChange={handleChange}
                        className="form-input"
                        placeholder={`Enter ${key.replace(/([A-Z])/g, " $1")}`}
                        disabled={
                          key !== "reciept_No" &&
                          key !== "Amount" &&
                          formData[key] !== "" &&
                          key !== "payment_date" &&
                          key !== "session" &&
                          key !== "paymentMode"
                        }
                        required
                      />
                    </div>
                  )
                )}
              </div>

              {/* Row 2: Random ID, Name, Father's Name, Date */}
              <div className="form-row">
                {["randomId", "name", "fathersname", "payment_date"].map(
                  (key) => (
                    <div key={key} className="form-group">
                      <label className="form-label">
                        {key.replace(/([A-Z])/g, " $1")}
                      </label>
                      <input
                        name={key}
                        value={formData[key]}
                        onChange={handleChange}
                        className="form-input"
                        placeholder={
                          key === "payment_date"
                            ? "12-05-2024 01:23 pm"
                            : `Enter ${key.replace(/([A-Z])/g, " $1")}`
                        }
                        // disabled={
                        //   key === "payment_date" && formData.payment_date
                        // } // Disable if date is entered
                        required
                      />
                    </div>
                  )
                )}
              </div>

              {/* Row 3: Course Type, Course Name, Course Branch */}
              <div className="form-row">
                {["courseType", "courseName", "courseBranch"].map((key) => (
                  <div key={key} className="form-group">
                    <label className="form-label">
                      {key.replace(/([A-Z])/g, " $1")}
                    </label>
                    <input
                      name={key}
                      value={formData[key]}
                      onChange={handleChange}
                      className="form-input"
                      placeholder={`Enter ${key.replace(/([A-Z])/g, " $1")}`}
                      disabled
                      required
                    />
                  </div>
                ))}
              </div>

              {/* Row 4: Email, Remark */}
              <div className="form-row">
                {["email", "remark"].map((key) => (
                  <div key={key} className="form-group">
                    <label className="form-label">
                      {key.replace(/([A-Z])/g, " $1")}
                    </label>
                    <input
                      name={key}
                      value={key === "remark" ? formData.Remark : formData[key]} // Ensure remark has default value
                      onChange={handleChange}
                      className="form-input"
                      placeholder={`Enter ${key.replace(/([A-Z])/g, " $1")}`}
                      disabled={key === "remark"} // Disable remark field
                      required
                    />
                  </div>
                ))}
              </div>
              <button type="submit" className="submit-button">
                Save Payment
              </button>
            </form>
          </div>
        )}
      </Container>
    </>
  );
}

export default Provisional_Pay_Search;
