import React from 'react';
import Nav from  '../../HomePage/Nav';
import Footerpage from '../../HomePage/Footerpage';


function N_ScienceDAy() {
  const images = [
    "ns1.jpg", "ns2.jpg", "ns3.jpg",  "ns4.jpg",  "ns5.jpg", "ns6.jpg","ns7.jpg",
  ];

  return (
    <>
      <Nav />

      <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '8px', padding: '10px' }}>
        {images.map((image, index) => (
          <div key={index} style={{ flex: '1 1 calc(25% - 16px)', maxWidth: 'calc(25% - 16px)', minWidth: '150px' }}>
            <a href={`https://cdn.sssutms.ac.in/media/static/national science day press media/${image}`} target="_blank" rel="noopener noreferrer">
              <img 
                src={`https://cdn.sssutms.ac.in/media/static/national science day press media/${image}`} 
                alt={`Ashwagandha News ${index + 1}`} 
                style={{ width: '100%', height: 'auto', border: '2px solid #009999', display: 'block' }}
              />
            </a>
          </div>
        ))}
      </div>

     <Footerpage/>
    </>
  );
}

export default N_ScienceDAy;
