// import React, { useState, useEffect } from "react";
// import swal from "sweetalert";
// import Box from "@mui/material/Box";
// import { Grid, Button, Tab, Tabs } from "@mui/material";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import logo from "../../AdminComponent/12.png";
// import StudentDashboard from "../../AdminComponent/StudentDashboard";
// import { useNavigate } from "react-router-dom";

// const theme = createTheme();

// function StudentAllDetail() {
//   const [isEnrollGenerated, setIsEnrollGenerated] = useState(false);
//   const [tabValue, setTabValue] = useState(0);

//   const handleTabChange = (event, newValue) => {
//     if (newValue === 1 && !(isRegistered && isEnrolled)) return;
//     if (newValue === 2 && !(isRegistered && isEnrolled)) return;
//     if (newValue === 3 && !(isRegistered && isEnrolled)) return;

//     setTabValue(newValue);
//   };

//   const navigate = useNavigate();

//   const userData = JSON.parse(sessionStorage.getItem("currentUser"));
//   const isEnrolled = userData?.isEnrolled;
//   const isRegistered = userData?.isRegistered;
//   const courseName = userData?.courseName;

//   const isProfessionalTabEnabled = isRegistered && isEnrolled;
//   const isAcademicTabEnabled = isRegistered && isEnrolled;
//   const isDocsTabEnabled = isRegistered && isEnrolled;

//   useEffect(() => {
//     const userData = JSON.parse(sessionStorage.getItem("currentUser"));
//     setIsEnrollGenerated(userData?.IsEnrollGenerated);

//     if (userData?.courseName) {
//       if (userData.isEnrolled === false) {
//         swal({
//           icon: "warning",
//           title: "Enrollment Required",
//           text: "Please fill the enrollment form first.",
//           buttons: {
//             cancel: "Cancel",
//             confirm: "OK",
//           },
//         }).then((value) => {
//           if (value) {
//             navigate("/student/Enrollement/Form");
//           }
//         });
//       } else if (userData.isEnrollPaid === false) {
//         swal({
//           icon: "warning",
//           title: "Payment Pending",
//           text: "Please complete the payment for enrollment.",
//           buttons: {
//             cancel: "Cancel",
//             confirm: "OK",
//           },
//         }).then((value) => {
//           if (value) {
//             navigate("/Epravesh/Student/EnrollementFee");
//           }
//         });
//       }
//     }
//   }, []);

//   const handleDownloadClick = () => {
//     if (userData.isEnrollPaid === true) {
//       generatePDF();
//     } else {
//       swal({
//         icon: "error",
//         title: "Error",
//         text: "Please Fill Enrollment Form First!!",
//       });
//     }
//   };

//   const Admision_slip = () => {
//     generatePDF1();
//   };
//   const shouldDisplaySection = (qualification1, qualification) => {
//     const qualificationOrder = {
//       "10th": 1,
//       "12th": 2,
//       ITI: 3,
//       Diploma: 4,
//       UG: 5,
//       Graduation: 6,
//       BSC: 7,
//       PG: 8,
//     };

//     return (
//       qualificationOrder[qualification1] <= qualificationOrder[qualification]
//     );
//   };

//   const getDocumentsByQualification = () => {
//     const qualification = userData?.qualification;

//     switch (qualification) {
//       case "PG":
//         return [
//           "applicantPhoto",
//           "aadharCard",
//           "applicantSignature",
//           "domicileCertificate",
//           "marksheet10th",
//           "marksheet12th",
//           "undergraduateCertificate",
//           "migrationCertificate",
//           "transferCertificate",
//           "incomeCertificate",
//           "otherCertificate",
//         ];
//       case "UG":
//         return [
//           "applicantPhoto",
//           "aadharCard",
//           "applicantSignature",
//           "domicileCertificate",
//           "marksheet10th",
//           "marksheet12th",
//           "undergraduateCertificate",
//           "migrationCertificate",
//           "transferCertificate",
//           "incomeCertificate",
//           "otherCertificate",
//         ];

//       case "12th":
//         return [
//           "applicantPhoto",
//           "aadharCard",
//           "applicantSignature",
//           "marksheet10th",
//           "marksheet12th",
//           "migrationCertificate",
//           "transferCertificate",
//           "domicileCertificate",
//           "incomeCertificate",
//           "otherCertificate",
//         ];
//       case "10th":
//         return [
//           "applicantPhoto",
//           "aadharCard",
//           "applicantSignature",
//           "marksheet10th",
//           "domicileCertificate",
//           "migrationCertificate",
//           "transferCertificate",
//           "incomeCertificate",
//           "otherCertificate",
//         ];
//       case "Diploma":
//         return [
//           "applicantPhoto",
//           "aadharCard",
//           "applicantSignature",
//           "marksheet10th",
//           "diplomaMarksheet",
//           "domicileCertificate",
//           "migrationCertificate",
//           "transferCertificate",
//           "incomeCertificate",
//           "otherCertificate",
//         ];
//       case "Graduation":
//         return [
//           "applicantPhoto",
//           "aadharCard",
//           "applicantSignature",
//           "marksheet10th",
//           "undergraduateCertificate",
//           "diplomaMarksheet",
//           "domicileCertificate",
//           "migrationCertificate",
//           "transferCertificate",
//           "incomeCertificate",
//           "otherCertificate",
//         ];
//       case "BSC":
//         return [
//           "applicantPhoto",
//           "aadharCard",
//           "applicantSignature",
//           "marksheet10th",
//           "marksheet12th",
//           "undergraduateCertificate",
//           "domicileCertificate",
//           "migrationCertificate",
//           "transferCertificate",
//           "incomeCertificate",
//           "otherCertificate",
//         ];
//       case "ITI":
//         return [
//           "applicantPhoto",
//           "applicantSignature",
//           "aadharCard",
//           "marksheet10th",
//           "ItImarksheet",
//           "domicileCertificate",
//           "migrationCertificate",
//           "transferCertificate",
//           "incomeCertificate",
//           "otherCertificate",
//         ];
//       default:
//         return [];
//     }
//   };

//   const relevantDocuments = getDocumentsByQualification();
//   const formatDate = (inputDate) => {
//     const date = new Date(inputDate);
//     const day = date.getDate().toString().padStart(2, "0");
//     const month = (date.getMonth() + 1).toString().padStart(2, "0");
//     const year = date.getFullYear().toString();
//     return `${day}/${month}/${year}`;
//   };

//   const generatePDFf = async () => {
//     const pdf = new jsPDF();

//     // Add A4 page border
//     pdf.setDrawColor(0); // Black color
//     pdf.rect(5, 5, 200, 287);

//     // Add University Logo
//     pdf.addImage(logo, "JPEG", 8, 10, 20, 20);

//     // Header Section
//     pdf.setFont("helvetica", "sans-serif");
//     pdf.setFontSize(12);
//     pdf.text(
//       "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
//       32,
//       15
//     );

//     pdf.setFont("helvetica", "serif");
//     pdf.setFontSize(10);
//     pdf.text("BACHELOR OF AYURVEDIC MEDICINE AND SURGERY", 60, 22);
//     pdf.text("(AYURVEDIC MEDICINE AND SURGERY) EXAM : DEC-2024", 55, 28);

//     pdf.setFontSize(13);
//     pdf.setFont("helvetica", "Georgia");
//     pdf.text("ADMIT CARD", 85, 34);

//     // Draw underline under "ADMIT CARD"
//     const admitCardX = 79;
//     const admitCardY = 35;
//     const admitCardWidth = 38;
//     pdf.line(admitCardX, admitCardY, admitCardX + admitCardWidth, admitCardY);

//     // Draw line under the header
//     pdf.line(5, 45, 205, 45);

//     // Second Box: Student Details
//     const details = [
//       { label: "Enrollment No", value: "741852963852" },
//       { label: "Candidate Name", value: "Sxxjxn Sharma Sharma Sharma" },
//       { label: "Father's Name", value: "Xjxjh Sharma Xhsu gfd Sharma" },
//       { label: "Mother's Name", value: "Xjxjh Sharma Xhsu dfb  Sharma" },
//       { label: "Semester/Year", value: "3" },
//       { label: "Status", value: "Regular" },
//     ];

//     // Draw the enclosing box
//     const boxStartX = 10;
//     const boxStartY = 50;
//     const boxWidth = 160;
//     const boxHeight = 50;
//     // pdf.rect(boxStartX, boxStartY, boxWidth, boxHeight);

//     // Create table-like rows for details
//     let yPos = boxStartY + 10;
//     pdf.setFont("helvetica", "normal");
//     pdf.setFontSize(10);

//     details.forEach((detail, index) => {
//       const columnX = index < 3 ? boxStartX + 5 : boxStartX + 85; // Left or right column
//       if (index === 3) yPos = boxStartY + 10; // Reset for the right column
//       const wrappedText = pdf.splitTextToSize(
//         `${detail.label}: ${detail.value}`,
//         55
//       ); // Wrap text if too long
//       pdf.text(wrappedText, columnX, yPos);
//       yPos += 12; // Adjust spacing between rows
//     });

//     // Add applicant photo to the right of the details box
//     const photoX = boxStartX + boxWidth + 5; // Positioned just right of the box
//     const photoY = boxStartY + 5; // Centered vertically with the box
//     // pdf.rect(photoX, boxStartY, 30, boxHeight); // Placeholder for the photo border
//     const studentImage = userData?.Documents?.applicantPhoto;
//     pdf.addImage(studentImage, "JPEG", photoX, photoY, 27, 35);
//     pdf.text("Student's Sig.", photoX + 5, photoY + 45);

//     // Add student's signature below the photo
//     const signatureImage = userData?.Documents?.applicantSignature; // Signature image
//     const signatureY = photoY + 10 + 25; // Positioned below the photo
//     pdf.addImage(signatureImage, "JPEG", photoX, signatureY, 27, 10); // Adjusted dimensions
//     // pdf.text("Student's Signature", photoX + 2, signatureY + 15);

//     // Third Box: Subjects
//     pdf.setFont("helvetica", "bold");
//     pdf.setFontSize(12);
//     pdf.text(
//       "Name of the Papers in Which Candidate is Appearing in the Examination",
//       25,
//       115
//     );

//     pdf.setFont("helvetica", "normal");
//     pdf.setFontSize(11);
//     pdf.text("Exam Time: 11:00 AM - 02:00 PM", 57, 122);

//     // Subject Table
//     const headers = ["SNo", "Subject Code", "Subject Type", "Subject Name"];
//     const subjects = [
//       ["1", "MCA-301", "Theory", "Artificial Intelligence"],
//       ["2", "MCA-302", "Theory", "Data Mining"],
//       ["3", "MCA-303", "Practical", "Web Technology"],
//       ["4", "MCA-304", "Practical", "Soft Computing"],
//       ["5", "MCA-305", "Theory", "Advanced Databases"],
//       ["1", "MCA-301", "Theory", "Artificial Intelligence"],
//       ["2", "MCA-302", "Theory", "Data Mining"],
//       ["3", "MCA-303", "Practical", "Web Technology"],
//       ["4", "MCA-304", "Practical", "Soft Computing"],
//       ["5", "MCA-305", "Theory", "Advanced Databases"],
//     ];
//     pdf.autoTable({
//       head: [headers],
//       body: subjects,
//       startY: 130,
//       styles: { fontSize: 10, textColor: 0 },
//       headStyles: { fillColor: [230, 230, 230] }, // Light gray header background
//       margin: { left: 15 },
//       theme: "grid",
//     });

//     // Footer Instructions
//     const footerY = pdf.autoTable.previous.finalY + 10;
//     pdf.setFont("helvetica", "normal");
//     pdf.setFontSize(10);
//     pdf.text("Instructions:", 15, footerY);
//     const instructions = [
//       "1. Bring your examination admit card and original college ID card to the examination hall.",
//       "2. Arrive at least 30 minutes before the start of the examination.",
//       "3. Follow all instructions given by the invigilators during the examination.",
//     ];
//     instructions.forEach((instruction, i) => {
//       pdf.text(instruction, 15, footerY + (i + 1) * 5);
//     });

//     // Exam Controller Signature
//     const examControllerSignatureImage =
//       "https://res.cloudinary.com/dszawxz7y/image/upload/v1708076754/sssutms/x8k947snao4btnzzuxnp.jpg";
//     pdf.addImage(
//       examControllerSignatureImage,
//       "JPEG",
//       150,
//       footerY + 26,
//       40,
//       10
//     ); // Adjust position and size as necessary
//     pdf.text("Signature of Exam Controller", 150, footerY + 40);

//     // Save PDF
//     pdf.save("Admit_Card.pdf");
//   };

//   function addHeader(doc) {
//     doc.addImage(logo, "JPEG", 6, 3, 18, 18);
//     doc.setFontSize(11);
//     doc.setTextColor(165, 42, 42);
//     doc.setFont("helvetica", "bold");
//     doc.text(
//       "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
//       105,
//       10,
//       { align: "center" }
//     );
//     doc.setFont("helvetica", "normal");
//     doc.text("RECORD OF ANSWER BOOK AND ATTENDANCE", 105, 16, {
//       align: "center",
//     });
//     doc.text("Regular Exam V SEMESTER DEC-2024", 105, 22, { align: "center" });

//     doc.setFontSize(9);
//     doc.text("Course/Programme : Bachelor of Engineering (Lateral)", 14, 30);
//     doc.text("Branch/Specialization : Mining Engineering", 14, 36);
//     doc.text("Exam Time : 11:00AM - 2:00PM", 160, 30);
//     doc.setFontSize(8);
//     doc.text("Examination Center : [1301]- School of Engineering", 140, 35);
//     doc.line(10, 40, 200, 40);
//   }

//   function addFooter(doc, pageNum) {
//     doc.setFontSize(10);
//     doc.text(`Page ${pageNum}`, 105, 290, { align: "center" });
//     doc.line(10, 285, 200, 285);
//     doc.text("Signature & Seal of Centre Superintendent", 12, 295);
//     doc.text("Invig. Sign.: ____________________  Date: __________", 130, 295);
//   }

//   function addContent(doc, yStart) {
//     const subjects = [
//       "MIA-501 [T]",
//       "MIA-502 [T]",
//       "MIA-503 [T]",
//       "MIA-504 [A]",
//       "MIA-505 [A]",
//     ];

//     // Students ke liye data (yahan ek example diya gaya hai)
//     const students = [
//       {
//         id: "230101115001",
//         name: "AKSHAY KUMAR KOIRI",
//         father: "LALBAHADUR KOIRI",
//         photo:
//           "https://res.cloudinary.com/dszawxz7y/image/upload/v1729070928/sssutms/fmicfp2sliqd9vtbelus.jpg",
//         // subjects ko alag se define karne ki zarurat nahi, header me hi use ho raha hai
//       },
//       // aap aur students bhi add kar sakte hain
//     ];

//     // Body row: 8 cells – SNo, Student Information, Photo, aur 5 subject columns (blank cells)
//     const tableData = students.map((student, index) => [
//       index + 1,
//       `${student.id}\n${student.name}\n${student.father}`,
//       { image: student.photo, width: 15, height: 15 },
//       "",
//       "",
//       "",
//       "",
//       "",
//     ]);

//     const head = [
//       [
//         "SNo",
//         "Student Information",
//         "Photo",
//         subjects[0],
//         subjects[1],
//         subjects[2],
//         subjects[3],
//         subjects[4],
//       ],
//       [
//         "",
//         "",
//         "",
//         "Ans. Book No.",
//         "Ans. Book No.",
//         "Ans. Book No.",
//         "Ans. Book No.",
//         "Ans. Book No.",
//       ],
//       [
//         "",
//         "",
//         "",
//         "Add. Ans. Book No.",
//         "Add. Ans. Book No.",
//         "Add. Ans. Book No.",
//         "Add. Ans. Book No.",
//         "Add. Ans. Book No.",
//       ],
//       [
//         "",
//         "",
//         "",
//         "Signature with Date",
//         "Signature with Date",
//         "Signature with Date",
//         "Signature with Date",
//         "Signature with Date",
//       ],
//     ];

//     doc.autoTable({
//       head: head,
//       body: tableData,
//       startY: yStart,
//       styles: { fontSize: 8, cellPadding: 1 },
//       // Adjusted column widths (8 columns total)
//       columnStyles: {
//         0: { cellWidth: 10 },
//         1: { cellWidth: 35 },
//         2: { cellWidth: 20 },
//         3: { cellWidth: 27 },
//         4: { cellWidth: 27 },
//         5: { cellWidth: 27 },
//         6: { cellWidth: 27 },
//         7: { cellWidth: 27 },
//       },
//       theme: "grid",
//       didDrawCell: (data) => {
//         if (data.cell.raw && data.cell.raw.image) {
//           doc.addImage(
//             data.cell.raw.image,
//             "JPEG",
//             data.cell.x + 2,
//             data.cell.y + 2,
//             data.cell.raw.width,
//             data.cell.raw.height
//           );
//         }
//       },
//     });
//   }

//   function generatePDF1() {
//     const doc = new jsPDF();
//     let pageNum = 1;

//     addHeader(doc);
//     addContent(doc, 45);
//     addFooter(doc, pageNum);

//     doc.save("attendance_record.pdf");
//   }

  // const generatePDF = async () => {
  //   const pdf = new jsPDF();

  //   // Add the university logo
  //   pdf.addImage(logo, "JPEG", 8, 5, 20, 20);

  //   // Header text
  //   pdf.setTextColor(165, 42, 42);
  //   pdf.setFont("helvetica", "bold");
  //   pdf.setFontSize(12);
  //   pdf.text(
  //     "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
  //     115,
  //     10,
  //     { align: "center" }
  //   );
  //   pdf.setFont("helvetica", "normal");
  //   pdf.setFontSize(11);
  //   pdf.text(
  //     "[Established Under Govt. of (M.P.) & Registered UGC 2(F),1956]",
  //     110,
  //     16,
  //     { align: "center" }
  //   );
  //   pdf.text("Sh-18 Bhopal-Indore Road, Pin Code - 466001 Pachama, Sehore", 110, 22, {
  //     align: "center",
  //   });
  //   pdf.text("Sehore (M.P.) Pin Code - 466001", 110, 28, { align: "center" });

  //   // Academic year
  //   const nextYear = parseInt(userData?.admissionSession) + 1;
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text(
  //     `Academic Year ${userData?.admissionSession} - ${nextYear}`,
  //     115,
  //     34,
  //     { align: "center" }
  //   );

  //   // Enrollment form title
  //   pdf.setDrawColor(0);
  //   pdf.setFillColor(230, 230, 230);
  //   pdf.rect(65, 38, 85, 10, "F");
  //   pdf.setFontSize(12);
  //   pdf.setFont("helvetica", "bold");
  //   pdf.setTextColor(0, 0, 0);
  //   pdf.text("Enrollment Form", 105, 45, { align: "center" });

  //   // Enrollment details
  //   const detailsData = [
  //     ["Enrollment No.", userData?.enrollmentNumber || "Not Generated"],
  //     ["Name", userData.name],
  //     ["Father's Name", userData.fathersname],
  //     ["Mother's Name", userData.mothersname],
  //     ["Gender", userData.gender],
  //     ["Date of Birth", formatDate(userData.dob)],
  //     ["Domicile", userData.domicile],
  //     ["Category", userData.category],
  //     ["Mobile No.", userData.mobile],
  //     ["Email", userData.email],
  //     ["Course Name", userData.courseName],
  //     ["Course Type", userData.courseType],
  //     ["Specialization", userData.courseBranch],
  //     ["Institute", userData.assignedCollege || "Not assigned"],
  //     ["ABC ID", userData.AbcId || "Not Upload"],
  //     ["Last Passed Qualafication", userData.qualification || "Not Upload"],
  //     [
  //       "Last Passed Subject",
  //       userData.lastPassedSubject || userData.QualifiedCourse || "Not Upload",
  //     ],
  //   ];

  //   if (courseName === "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)") {
  //     detailsData.push(
  //       ["Neet Qualified Percentage", `${userData?.NeetPercentage}%` || ""],
  //       ["Admission Category", userData?.Concession || ""]
  //     );
  //   }

  //   let currentY = 50;
  //   pdf.autoTable({
  //     body: detailsData,
  //     startY: currentY,
  //     margin: { left: 10 },
  //     tableWidth: "wrap",
  //     columnStyles: {
  //       0: { cellWidth: 80 },
  //       1: { cellWidth: 110 },
  //     },
  //   });

  //   // Add student image without background lines
  //   const studentImage = userData?.Documents?.applicantPhoto;
  //   if (studentImage) {
  //     pdf.addImage(studentImage, "JPEG", 162, 50, 28, 34);
  //   }

  //   // Address Details with reduced space
  //   currentY = pdf.lastAutoTable.finalY + 10; // Reduced from 10 to 5
  //   // pdf.setFont("helvetica", "bold");
  //   // pdf.setFontSize(12);
  //   pdf.text("Address Details", 12, currentY);

  //   const addressData = [
  //     ["Local Address", userData?.address?.address1 || ""],
  //     ["Permanent Address", userData?.address?.address2 || ""],
  //     ["Country", userData?.address?.country || ""],
  //     ["State", userData?.address?.state || ""],
  //     ["District", userData?.address?.district || ""],
  //     ["Pin Code", userData?.address?.pinCode || ""],
  //   ];

  //   pdf.autoTable({
  //     body: addressData,
  //     startY: currentY + 5,
  //     margin: { left: 12 },
  //     tableWidth: "wrap",
  //     columnStyles: {
  //       0: { cellWidth: 80 },
  //       1: { cellWidth: 110 },
  //     },
  //   });

  //   // Professional Details with reduced space
  //   currentY = pdf.lastAutoTable.finalY + 5; // Reduced from 10 to 5
  //   pdf.setFont("helvetica", "bold");
  //   pdf.setFontSize(12);
  //   pdf.text("Professional Details", 12, currentY);

  //   const professionalData = [
  //     ["Handicapped", userData?.professional?.Handicapped || "No"],
  //     ["Medium", userData?.professional?.Medium || ""],
  //     ["Father's Occupation", userData?.professional?.FathersOccupation || ""],
  //     ["Mother's Income", userData?.professional?.MothersIncome || ""],
  //     ["Aadhar Number", userData?.professional?.AadharNumber || ""],
  //     ["Parent Mobile", userData?.professional?.ParentMobile || ""],
  //   ];

  //   pdf.autoTable({
  //     body: professionalData,
  //     startY: currentY + 5,
  //     margin: { left: 12 },
  //     tableWidth: "wrap",
  //     columnStyles: {
  //       0: { cellWidth: 80 },
  //       1: { cellWidth: 110 },
  //     },
  //   });

  //   // Academic Details with only 10th and 12th if available
  //   currentY = pdf.lastAutoTable.finalY + 5; // Reduced from 15 to 5
  //   pdf.setFont("helvetica", "bold");
  //   pdf.setFontSize(12);
  //   pdf.text("Academic Details", 13, currentY);

  //   const academicData = [];
  //   // Add 10th details if available
  //   if (userData?.academicDetails?.tenthPassingYear) {
  //     academicData.push([
  //       "Tenth Details",
  //       `${userData?.academicDetails?.tenthPassingYear}`,
  //       `${userData?.academicDetails?.tenthRollNo || "N/A"}`,
  //       `${userData?.academicDetails?.tenthBoard || "N/A"}`,
  //       `${userData?.academicDetails?.tenthMarksObtain || "CGPA"}`,
  //       `${userData?.academicDetails?.tenthMaxMarks || "CGPA"}`,
  //       `${
  //         userData?.academicDetails?.tenthPercentage
  //           ? userData?.academicDetails?.tenthPercentage
  //           : userData?.academicDetails?.cgpa || "N/A"
  //       }`,
  //     ]);
  //   }
  //   // Add 12th details if available
  //   if (userData?.academicDetails?.twelfthPassingYear) {
  //     academicData.push([
  //       "Twelfth/Diploma Details",
  //       `${userData?.academicDetails?.twelfthPassingYear}`,
  //       `${userData?.academicDetails?.twelfthRollNo || "N/A"}`,
  //       `${userData?.academicDetails?.twelfthBoard || "N/A"}`,
  //       `${userData?.academicDetails?.twelfthMarksObtain || "N/A"}`,
  //       `${userData?.academicDetails?.twelfthMaxMarks || "CGPA"}`,
  //       `${
  //         userData?.academicDetails?.twelfthPercentage
  //           ? userData?.academicDetails?.twelfthPercentage
  //           : userData?.academicDetails?.cgpa || "N/A"
  //       }`,
  //     ]);
  //   }

  //   pdf.autoTable({
  //     head: [
  //       [
  //         "Qualification",
  //         "Passing Year",
  //         "Roll No.",
  //         "Board",
  //         "Obtained Marks",
  //         "Max Marks",
  //         "Percentage/CGPA",
  //       ],
  //     ],
  //     body: academicData,
  //     startY: currentY + 5,
  //     theme: "grid",
  //     styles: { fontSize: 9 },
  //     margin: { left: 14 },
  //   });

  //   // New Documents Section
  //   currentY = pdf.lastAutoTable.finalY + 5;
  //   pdf.setFont("helvetica", "bold");
  //   pdf.setFontSize(12);
  //   pdf.text("Documents", 13, currentY);

  //   const documentData = [];
  //   if (userData?.Documents) {
  //     Object.entries(userData.Documents).forEach(([key, value]) => {
  //       if (value && key !== "_id") {
  //         documentData.push([key, "Uploaded"]);
  //       }
  //     });
  //   }

  //   pdf.autoTable({
  //     body: documentData,
  //     startY: currentY + 5,
  //     margin: { left: 14 },
  //     tableWidth: "wrap",
  //     columnStyles: {
  //       0: { cellWidth: 80 },
  //       1: { cellWidth: 110 },
  //     },
  //   });

  //   // Declaration and rest of the code remains unchanged
  //   currentY = pdf.lastAutoTable.finalY + 10;
  //   pdf.setFont("helvetica", "normal");
  //   pdf.setFontSize(10);
  //   pdf.text("Declaration:", 12, currentY);
  //   currentY += 5;
  //   const declarationText = [
  //     "I hereby declare that the information provided in this form and its attachments is accurate to the best of my knowledge.",
  //     "I understand that any false information may result in disqualification.",
  //     "If selected for admission, I agree to abide by the rules and regulations of the university.",
  //     "I understand that the admission is provisional, subject to confirmation by the concerned authorities, and that the fee paid",
  //     " is non-refundable in case of cancellation.",
  //   ];
  //   declarationText.forEach((line, index) => {
  //     pdf.text(line, 12, currentY + index * 5);
  //   });

  //   currentY += 25;
  //   pdf.text("Certificate ………", 12, currentY);

  //   currentY += 10;
  //   pdf.setFont("helvetica", "italic");
  //   pdf.setFontSize(10);
  //   pdf.text(
  //     "Certified that the above student has been admitted to this institution as per the procedure laid down by regulatory authorities.",
  //     12,
  //     currentY
  //   );
  //   currentY += 10;
  //   pdf.text(
  //     "The entries above have been checked and verified by me from the records.",
  //     12,
  //     currentY
  //   );
  //   currentY += 10;
  //   pdf.text(
  //     "Therefore, I recommend that he/she should be enrolled as requested by the student.",
  //     12,
  //     currentY
  //   );

  //   const applicantSignature = userData?.Documents?.applicantSignature;
  //   if (applicantSignature) {
  //     pdf.addImage(applicantSignature, "JPEG", 20, currentY + 20, 40, 15);
  //     pdf.text("Applicant's Signature", 20, currentY + 40);
  //   }

  //   pdf.text("Signature of the Dean with seal", 140, currentY + 40);

  //   pdf.save(`${userData?.name} Enrollment Form.pdf`);
  // };
//   return (
//     <>
//       <style>
//         {`

//          @import url("https://fonts.googleapis.com/css?family=Josefin+Sans|Mountains+of+Christmas&display=swap");

// .table th, .table td {
// font-size: 14px;
// padding: 2px 5px;
// color: #000;
// }
// .theadd{

//                                   color: #2E236C;
//                                   font-size: 15px;
//                                   font-weight: bold;
//                              }
// .carad{
//  height: 70%;
//   border-radius: 20px;
//    margin-left: 60px;
//     margin-top: 50px;
//     }

//     .info-row {
//   display: flex;
//   justify-content: space-between;
//   margin-bottom: 5px;
// }

// .info-row strong {
//   flex: 0 0 40%; /* Adjust the width as needed */
// }

// .info-row span {
//   flex: 1;
//   text-align: left;
// }
// .tabu{
// margin-top: 25px;
//  margin-left: 20px;
//  }
//     .diss{
// display: flex;
//  gap: 30px;
//  position: sticky;
//   top: 550px;
//  margin-top: 10px;
//   margin-left: 60px;
//   }
//           .student-profile .card {
//             border-radius: 10px;
//             height:80px;
//             width:80px;
//           }

//           .infoo{
//           margin-left:20px;
//           font-size:15px}

// .card {
//               width: 80%;
//               margin-Top:40px;
//               height:auto;
//            }

//           .student-profile .card h3 {
//             font-size: 20px;
//             font-weight: 700;
//           }

//           .student-profile .card p {
//             font-size: 26px;
//             color: #000;
//           }

//           .strong{
//     font-size:15px;
//     margin-left:-8px
//     }
// .boox{

//             margin-top: 3%;
//             // background-color: #b0c4b1;
//             box-shadow: 2px solid black;
//             margin-left: 30px;
//           }

//           .profile_img{

//                       width: 120px;
//                       height: 120px;
//                       object-fit: cover;
//                       border-radius: 50%;
//                     }
//           .student-profile .table th,
//           .student-profile .table td {
//             font-size: 40px;
//             padding: 0px 10px;
//             color: #000;
//           }

//           @media (max-width: 800px) {
//             .card {
//               width: 100%;
//               margin-Top:75px;
//             }
//           }

//           @media screen and (max-width: 480px) {

//          .card {
//               width: 80%;
//               margin-Top:40px;
//            }

//     .diss{
// display: flex;
//  gap: 30px;

//  position:sticky;

//  margin-top: 50px;
//   margin-left:-2px;
//   }

//   .tabu{
// margin-top: 25px;
//  margin-left: -30px;
//  }

//    .profile_img{

//                       // width: 80px;
//                       // height: 80px;
//                       // object-fit: cover;
//                       // border-radius: 50%;
//                       display:none
//                     }

//   .student-profile .table th,
//           .student-profile .table td {
//             font-size: 25px;
//             padding: 0px 5px;
//             color: #000;
//           }

//              .card-body{
//              margin-left:10px}

//         .carad{
//  height: auto;
//  width:auto;
//   border-radius: 20px;
//     // margin-left: -5px;
//     margin-left:-40px;
//     margin-top: 50px;
//     }

//      .card-header {
//       flex-direction: row;
//       justify-content: space-between;
//       align-items: center;
//     }
//     .card-header img {
//       margin-right: 10px;
//       margin-right: auto; /* Pushes the image to the right */
//     }
//     .card-header h3 {
//       margin-top: -50px;
//       text-align: right;

//     }

//        . table-bordered{
//        margin-left:-10px}
//           .bunnn{
//           height:30px;
//           width:160px;
//            margin-top:-20px;
//             font-size: 9px; /* Reduce the font size for smaller screens */
//     padding: 5px 10px; /* Optionally adjust padding for a better fit */
//           }
//   .MuiTab-root {
//     width: 80%; /* Make each tab take up the full width */

//     font-size: 11px; /* Reduce the font size */
//     padding: 0px ; /* Adjust padding to match the smaller text */

//   }

//   .MuiTabs-flexContainer {
//     flex-direction: row; /* Stack tabs vertically */
//   }

//   .MuiTabs-root {
//     width: 100%; /* Ensure the Tabs container takes up the full width */
//   }
// }

//         `}
//       </style>

//       <ThemeProvider theme={theme}>
//         <StudentDashboard />
//         <Box className="boox">
//           <Grid container sx={{ justifyContent: "center" }}>
//             <Grid item xs={12} sm={5}>
//               <div
//                 class="card shadow-lg carad"
//                 style={{
//                   position: "sticky",
//                   top: "70px",
//                   zIndex: 10,
//                 }}
//               >
//                 <div
//                   className="card-header bg-transparent text-right"
//                   style={{
//                     display: "flex",
//                     flexDirection: "row",
//                     alignItems: "center",
//                   }}
//                 >
//                   <img
//                     className="profile_img"
//                     src={userData?.Documents?.applicantPhoto}
//                     alt="img"
//                     style={{ marginRight: "10px" }}
//                   />
//                   <h3 style={{ color: "#921A40", margin: 0 }}>
//                     {userData?.name}
//                   </h3>
//                 </div>

//                 <div class="card-body">
//                   <div class="info-row">
//                     <strong className="strong">Admission Session:</strong>
//                     <span className="infoo">{userData?.admissionSession}</span>
//                   </div>
//                   <div class="info-row">
//                     <strong className="strong">Student Type:</strong>
//                     <span className="infoo">
//                       {userData?.StudentType || "Normal Admission"}
//                     </span>
//                   </div>
//                   <div class="info-row">
//                     <strong className="strong">Course Type:</strong>
//                     <span className="infoo">{userData?.courseType}</span>
//                   </div>
//                   <div class="info-row">
//                     <strong className="strong">Course Name:</strong>
//                     <span className="infoo">{userData?.courseName}</span>
//                   </div>
//                   <div class="info-row">
//                     <strong className="strong">Branch:</strong>
//                     <span className="infoo">{userData?.courseBranch}</span>
//                   </div>
//                   <div class="info-row">
//                     <strong className="strong">Fee Status:</strong>
//                     <span
//                       className="infoo"
//                       style={{ color: userData?.isPaid ? "green" : "red" }}
//                     >
//                       {userData?.isPaid ? "Paid" : "Not Paid"}
//                     </span>
//                   </div>
//                   <div class="info-row">
//                     <strong className="strong">Enrollment Form Status:</strong>
//                     <span
//                       className="infoo"
//                       style={{ color: userData?.isEnrolled ? "green" : "red" }}
//                     >
//                       {userData?.isEnrolled ? "Filled" : "Pending"}
//                     </span>
//                   </div>
//                   {/* <div class="info-row">
//                     <strong className="strong">
//                       Enrollment Form Fee Status:
//                     </strong>
//                     <span
//                       className="infoo"
//                       style={{
//                         color: userData?.isEnrollPaid ? "green" : "red",
//                       }}
//                     >
//                       {userData?.isEnrollPaid ? "Paid" : "Pending"}
//                     </span>
//                   </div> */}
//                   <div class="info-row">
//                     <strong className="strong">
//                       Enrollment Form Fee Status:
//                     </strong>
//                     <span
//                       className="infoo"
//                       style={{
//                         color: userData?.isEnrollPaid ? "green" : "red",
//                       }}
//                     >
//                       {userData?.isEnrollPaid ? "Paid" : "Pending"}
//                     </span>
//                   </div>
//                   <div class="info-row">
//                     <strong className="strong">Enrollment Number</strong>
//                     <span
//                       className="infoo"
//                       style={{
//                         color: "red",
//                       }}
//                     >
//                       {userData?.enrollmentNumber || "Not Generated"}
//                     </span>
//                   </div>
//                 </div>
//               </div>

//               <div className="diss">
//                 <Button
//                   className="bunnn"
//                   style={{ backgroundColor: "#405D72" }}
//                   variant="contained"
//                   color="success"
//                   onClick={handleDownloadClick}
//                   // disabled={true}
//                 >
//                   Enrollment Details
//                   <span style={{ marginLeft: "8px" }}>
//                     <i className="fa-solid fa-download"></i>
//                   </span>
//                 </Button>
//                 <Button
//                   className="bunnn"
//                   style={{ backgroundColor: "#405D72" }}
//                   variant="contained"
//                   color="success"
//                   disabled={true}
//                   // onClick={Admision_slip}
//                 >
//                   Admission Slip
//                   <span style={{ marginLeft: "8px" }}>
//                     <i className="fa-solid fa-download"></i>
//                   </span>
//                 </Button>
//               </div>
//             </Grid>

//             <Grid item xs={12} sm={7}>
//               <Box className="tabu">
//                 <Tabs
//                   value={tabValue}
//                   onChange={handleTabChange}
//                   indicatorColor="primary"
//                   textColor="primary"
//                   variant="fullWidth"
//                 >
//                   <Tab
//                     label={
//                       window.innerWidth <= 480
//                         ? "Personal"
//                         : "Personal Information"
//                     }
//                   />
//                   <Tab
//                     label={
//                       window.innerWidth <= 480
//                         ? "Professional"
//                         : "Professional Information"
//                     }
//                   />
//                   <Tab
//                     label={
//                       window.innerWidth <= 480
//                         ? "Academic"
//                         : "Academic Information"
//                     }
//                   />
//                   <Tab
//                     label={window.innerWidth <= 480 ? "Docs" : "Documents"}
//                   />
//                 </Tabs>

//                 {tabValue === 0 && (
//                   <Box p={1}>
//                     <hr></hr>

//                     <div className="card-body pt-0">
//                       <table className="table table-bordered">
//                         <tbody>
//                           <tr>
//                             <th
//                               style={{
//                                 color: "#16423C",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Gender
//                             </th>
//                             <td width="2%">:</td>
//                             <td>{userData?.gender}</td>
//                           </tr>
//                           <tr>
//                             <th
//                               style={{
//                                 color: "#16423C",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Category
//                             </th>
//                             <td width="2%">:</td>
//                             <td>{userData?.category}</td>
//                           </tr>
//                           <tr>
//                             <th
//                               style={{
//                                 color: "#16423C",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Father's Name
//                             </th>
//                             <td width="2%">:</td>
//                             <td>{userData?.fathersname}</td>
//                           </tr>
//                           <tr>
//                             <th
//                               style={{
//                                 color: "#16423C",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Mother's Name
//                             </th>
//                             <td width="2%">:</td>
//                             <td>{userData?.mothersname}</td>
//                           </tr>
//                           <tr>
//                             <th
//                               style={{
//                                 color: "#16423C",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Contact
//                             </th>
//                             <td width="2%">:</td>
//                             <td>{userData?.mobile}</td>
//                           </tr>{" "}
//                           <tr>
//                             <th
//                               width="30%"
//                               style={{
//                                 color: "#16423C",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Nationality
//                             </th>
//                             <td width="2%">:</td>
//                             <td>{userData?.nationality || "Not Uploaded"}</td>
//                           </tr>
//                           <tr>
//                             <th
//                               style={{
//                                 color: "#16423C",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               DOB
//                             </th>
//                             <td width="2%">:</td>
//                             <td>
//                               {/* <DatePicker
//                                 dateFormat="dd/MM/yyyy"
//                                 selected={
//                                   userData?.dob ? new Date(userData?.dob) : null
//                                 }
//                                 readOnly
//                               /> */}
//                               <td>{userData?.dob}</td>
//                             </td>
//                           </tr>
//                           <tr>
//                             <th
//                               style={{
//                                 color: "#16423C",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Email
//                             </th>
//                             <td width="2%">:</td>
//                             <td>{userData?.email}</td>
//                           </tr>
//                           <tr>
//                             <th
//                               style={{
//                                 color: "#16423C",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Domicile
//                             </th>
//                             <td width="2%">:</td>
//                             <td>{userData?.domicile || "Not Uploaded"}</td>
//                           </tr>
//                           <tr>
//                             <th
//                               style={{
//                                 color: "#16423C",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Last Exam Type
//                             </th>
//                             <td width="2%">:</td>
//                             <td>{userData?.lastExamType}</td>
//                           </tr>
//                           <tr>
//                             <th
//                               style={{
//                                 color: "#16423C",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Scholarship Required
//                             </th>

//                             <td width="2%">:</td>

//                             <td>
//                               {userData?.professional?.ScholarshipRequired
//                                 ? userData.professional.ScholarshipRequired
//                                 : "Not Eligible"}
//                             </td>
//                           </tr>{" "}
//                           <tr>
//                             <th
//                               width="30%"
//                               style={{
//                                 color: "#16423C",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Last Qualification
//                             </th>
//                             <td width="2%">:</td>
//                             <td>{userData?.qualification}</td>
//                           </tr>
//                           <tr>
//                             <th
//                               width="30%"
//                               style={{
//                                 color: "#16423C",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Last Percentage/ CGPA
//                             </th>
//                             <td width="2%">:</td>
//                             <td>
//                               {userData?.qualificationPercentage ||
//                                 userData?.LastPercentage ||
//                                 userData?.cgpa ||
//                                 "Not Uploaded"}
//                             </td>
//                           </tr>
//                           <tr>
//                             <th
//                               width="30%"
//                               style={{
//                                 color: "#16423C",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Last Passed Subject
//                             </th>
//                             <td width="2%">:</td>
//                             <td>
//                               {userData?.lastPassedSubject || "Not Uploaded"}
//                             </td>
//                           </tr>
//                           <tr>
//                             <th
//                               width="30%"
//                               style={{
//                                 color: "#16423C",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               Last Passing Year
//                             </th>
//                             <td width="2%">:</td>
//                             <td>{userData?.passingYear}</td>
//                           </tr>
//                           <tr>
//                             <th
//                               width="30%"
//                               style={{
//                                 color: "#16423C",
//                                 fontSize: "15px",
//                                 fontWeight: 700,
//                               }}
//                             >
//                               ABC Id
//                             </th>
//                             <td width="2%">:</td>
//                             <td>{userData?.AbcId || "Not Available"}</td>
//                           </tr>
//                         </tbody>
//                       </table>
//                     </div>
//                   </Box>
//                 )}

//                 {tabValue === 1 && isProfessionalTabEnabled && (
//                   <Box p={3}>
//                     <div className="card-header bg-transparent border-0 ">
//                       <hr style={{ marginTop: "-15px" }}></hr>

//                       <div className="card-body pt-0 ">
//                         <table className="table table-bordered">
//                           <tbody>
//                             <tr>
//                               <th
//                                 width="50%"
//                                 style={{
//                                   color: "#16423C",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Handicapped
//                               </th>
//                               <td width="2%">:</td>
//                               <td>{userData?.professional?.Handicapped}</td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#16423C",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Medium
//                               </th>
//                               <td width="2%">:</td>
//                               <td>{userData?.professional?.Medium}</td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#16423C",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Aadhar Number
//                               </th>
//                               <td width="2%">:</td>
//                               <td>{userData?.professional?.AadharNumber}</td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#16423C",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Samagra ID
//                               </th>
//                               <td width="2%">:</td>
//                               <td>
//                                 {userData?.professional?.SamagraId
//                                   ? userData.professional.SamagraId
//                                   : "Not Available"}
//                               </td>
//                             </tr>

//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#16423C",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Father's Occupation
//                               </th>
//                               <td width="2%">:</td>
//                               <td>
//                                 {userData?.professional?.FathersOccupation}
//                               </td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#16423C",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Mother's Occupation
//                               </th>
//                               <td width="2%">:</td>
//                               <td>
//                                 {userData?.professional?.MothersOccupation}
//                               </td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#16423C",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Father's Income
//                               </th>
//                               <td width="2%">:</td>
//                               <td>{userData?.professional?.FathersIncome}</td>
//                             </tr>

//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#16423C",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Mother's Income
//                               </th>
//                               <td width="2%">:</td>
//                               <td>{userData?.professional?.MothersIncome}</td>
//                             </tr>

//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#16423C",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Parent Mobile
//                               </th>
//                               <td width="2%">:</td>
//                               <td>{userData?.professional?.ParentMobile}</td>
//                             </tr>

//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#16423C",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Current Address{" "}
//                               </th>
//                               <td width="2%">:</td>
//                               <td>
//                                 {userData?.address?.address1
//                                   ? userData?.address?.address1
//                                   : "Not Fill"}
//                               </td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#16423C",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Permanent Address{" "}
//                               </th>
//                               <td width="2%">:</td>
//                               <td>{userData?.address?.address2}</td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#16423C",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Country
//                               </th>
//                               <td width="2%">:</td>
//                               <td>{userData?.address?.country}</td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#16423C",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 State
//                               </th>
//                               <td width="2%">:</td>
//                               <td>{userData?.address?.state}</td>
//                             </tr>

//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#16423C",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 District
//                               </th>
//                               <td width="2%">:</td>
//                               <td>{userData?.address?.district}</td>
//                             </tr>
//                             <tr>
//                               <th
//                                 width="30%"
//                                 style={{
//                                   color: "#16423C",
//                                   fontSize: "15px",
//                                   fontWeight: 700,
//                                 }}
//                               >
//                                 Pin Code
//                               </th>
//                               <td width="2%">:</td>
//                               <td>{userData?.address?.pinCode}</td>
//                             </tr>
//                           </tbody>
//                         </table>
//                       </div>
//                     </div>
//                   </Box>
//                 )}

//                 {tabValue === 2 && isAcademicTabEnabled && (
//                   <Box p={3}>
//                     <div className="card-header bg-transparent border-0">
//                       <hr style={{ marginTop: "-10px" }}></hr>
//                       <div className="card-body pt-0">
//                         <table className="table table-bordered">
//                           {shouldDisplaySection(
//                             "10th",
//                             userData?.qualification
//                           ) && (
//                             <>
//                               <th style={{ fontSize: "20px" }}>
//                                 <b>10th</b>
//                               </th>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   School
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails?.tenthSchool}
//                                 </td>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Board
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>{userData?.academicDetails?.tenthBoard}</td>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Passing Year
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails?.tenthPassingYear}
//                                 </td>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Max. Marks
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails?.tenthMaxMarks}
//                                 </td>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Marks Obtained
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails?.tenthMarksObtain}
//                                 </td>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Percentage
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails?.tenthPercentage}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Roll No
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails?.tenthRollNo}
//                                 </td>
//                               </tr>
//                               <br></br>

//                               {/* Add other 10th fields here */}
//                             </>
//                           )}

//                           {shouldDisplaySection(
//                             "12th" || "Diploma" || "ITI",
//                             userData?.qualification
//                           ) && (
//                             <>
//                               <tr style={{ fontSize: "20px" }}>
//                                 <b>
//                                   {" "}
//                                   {userData?.qualification === "ITI"
//                                     ? "ITI"
//                                     : "12th"}
//                                 </b>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   School
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails?.twelfthSchool}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Board
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails?.twelfthBoard}
//                                 </td>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Passing Year
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {
//                                     userData?.academicDetails
//                                       ?.twelfthPassingYear
//                                   }
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Max. Marks
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails?.twelfthMaxMarks}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Marks Obtained
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {
//                                     userData?.academicDetails
//                                       ?.twelfthMarksObtain
//                                   }
//                                 </td>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Percentage
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails?.twelfthPercentage}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Roll No
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails?.twelfthRollNo}
//                                 </td>
//                               </tr>
//                               <br></br>
//                             </>
//                           )}

//                           {shouldDisplaySection(
//                             "UG",
//                             userData?.qualification
//                           ) && (
//                             <>
//                               <tr style={{ fontSize: "20px" }}>
//                                 <b>Graduation</b>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   College
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails
//                                     ?.graduationCollege || "Not Uploaded"}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   University
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails
//                                     ?.graduationUniversity || "Not Uploaded"}
//                                 </td>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Passing Year
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails
//                                     ?.graduationPassingYear || "Not Uploaded"}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Max. Marks
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails
//                                     ?.graduationMaxMarks || "Not Uploaded"}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Marks Obtained
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails
//                                     ?.graduationMarksObtain || "Not Uploaded"}
//                                 </td>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Percentage
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails
//                                     ?.graduationPercentage || "Not Uploaded"}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Roll No
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails
//                                     ?.graduationRollNo || "Not Uploaded"}
//                                 </td>
//                               </tr>
//                               <br></br>
//                             </>
//                           )}

//                           {shouldDisplaySection(
//                             "PG",
//                             userData?.qualification
//                           ) && (
//                             <>
//                               <tr style={{ fontSize: "20px" }}>
//                                 <b>Post Graduation</b>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   College
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails
//                                     ?.postGraduationCollege || "Not Uploaded"}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   University
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails
//                                     ?.postGraduationUniversity ||
//                                     "Not Uploaded"}
//                                 </td>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Passing Year
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails
//                                     ?.postGraduationPassingYear ||
//                                     "Not Uploaded"}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Max. Marks
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails
//                                     ?.postGraduationMaxMarks || "Not Uploaded"}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Marks Obtained
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails
//                                     ?.postGraduationMarksObtain ||
//                                     "Not Uploaded"}
//                                 </td>
//                               </tr>

//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Percentage
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails
//                                     ?.postGraduationPercentage ||
//                                     "Not Uploaded"}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <th
//                                   width="30%"
//                                   style={{
//                                     color: "#16423C",
//                                     fontSize: "15px",
//                                     fontWeight: 700,
//                                   }}
//                                 >
//                                   Roll No
//                                 </th>
//                                 <td width="2%">:</td>
//                                 <td>
//                                   {userData?.academicDetails
//                                     ?.postGraduationRollNo || "Not Uploaded"}
//                                 </td>
//                               </tr>
//                               <br></br>
//                             </>
//                           )}
//                         </table>
//                       </div>
//                     </div>
//                   </Box>
//                 )}

//                 {tabValue === 3 && isDocsTabEnabled && (
//                   <Box p={3}>
//                     <div className="card-header bg-transparent border-0 ">
//                       <hr />

//                       <div class="card-body pt-0">
//                         <table className="table table-bordered">
//                           <tbody>
//                             {relevantDocuments.includes("applicantPhoto") && (
//                               <tr>
//                                 <th>Applicant Photo</th>
//                                 <td>:</td>
//                                 <td>
//                                   {userData?.Documents?.applicantPhoto ? (
//                                     <a
//                                       href={userData.Documents.applicantPhoto}
//                                       target="_blank"
//                                       rel="noopener noreferrer"
//                                     >
//                                       View Photo
//                                     </a>
//                                   ) : (
//                                     "Not Uploaded"
//                                   )}
//                                 </td>
//                               </tr>
//                             )}
//                             {relevantDocuments.includes(
//                               "applicantSignature"
//                             ) && (
//                               <tr>
//                                 <th>Applicant Signature</th>
//                                 <td>:</td>
//                                 <td>
//                                   {userData?.Documents?.applicantSignature ? (
//                                     <a
//                                       href={
//                                         userData.Documents.applicantSignature
//                                       }
//                                       target="_blank"
//                                       rel="noopener noreferrer"
//                                     >
//                                       View Signature
//                                     </a>
//                                   ) : (
//                                     "Not Uploaded"
//                                   )}
//                                 </td>
//                               </tr>
//                             )}

//                             {relevantDocuments.includes("marksheet10th") && (
//                               <tr>
//                                 <th>10th Marksheet</th>
//                                 <td>:</td>
//                                 <td>
//                                   {userData?.Documents?.marksheet10th ? (
//                                     <a
//                                       href={userData.Documents.marksheet10th}
//                                       target="_blank"
//                                       rel="noopener noreferrer"
//                                     >
//                                       View Marksheet
//                                     </a>
//                                   ) : (
//                                     "Not Uploaded"
//                                   )}
//                                 </td>
//                               </tr>
//                             )}
//                             {relevantDocuments.includes("marksheet12th") && (
//                               <tr>
//                                 <th>12th Marksheet</th>
//                                 <td>:</td>
//                                 <td>
//                                   {userData?.Documents?.marksheet12th ? (
//                                     <a
//                                       href={userData.Documents.marksheet12th}
//                                       target="_blank"
//                                       rel="noopener noreferrer"
//                                     >
//                                       View Marksheet
//                                     </a>
//                                   ) : (
//                                     "Not Uploaded"
//                                   )}
//                                 </td>
//                               </tr>
//                             )}
//                             {relevantDocuments.includes(
//                               "undergraduateCertificate"
//                             ) && (
//                               <tr>
//                                 <th>Undergraduate Certificate</th>
//                                 <td>:</td>
//                                 <td>
//                                   {userData?.Documents
//                                     ?.undergraduateCertificate ? (
//                                     <a
//                                       href={
//                                         userData.Documents
//                                           .undergraduateCertificate
//                                       }
//                                       target="_blank"
//                                       rel="noopener noreferrer"
//                                     >
//                                       View Certificate
//                                     </a>
//                                   ) : (
//                                     "Not Uploaded"
//                                   )}
//                                 </td>
//                               </tr>
//                             )}

//                             {relevantDocuments.includes("ItImarksheet") && (
//                               <tr>
//                                 <th>ITI Marksheet</th>
//                                 <td>:</td>
//                                 <td>
//                                   {userData?.Documents?.ItImarksheet ? (
//                                     <a
//                                       href={userData.Documents.ItImarksheet}
//                                       target="_blank"
//                                       rel="noopener noreferrer"
//                                     >
//                                       View Marksheet
//                                     </a>
//                                   ) : (
//                                     "Not Uploaded"
//                                   )}
//                                 </td>
//                               </tr>
//                             )}
//                             {relevantDocuments.includes("diplomaMarksheet") && (
//                               <tr>
//                                 <th>Diploma Marksheet</th>
//                                 <td>:</td>
//                                 <td>
//                                   {userData?.Documents?.diplomaMarksheet ? (
//                                     <a
//                                       href={userData.Documents.diplomaMarksheet}
//                                       target="_blank"
//                                       rel="noopener noreferrer"
//                                     >
//                                       View Marksheet
//                                     </a>
//                                   ) : (
//                                     "Not Uploaded"
//                                   )}
//                                 </td>
//                               </tr>
//                             )}

//                             {relevantDocuments.includes("aadharCard") && (
//                               <tr>
//                                 <th>Aadhar Card</th>
//                                 <td>:</td>
//                                 <td>
//                                   {userData?.Documents?.aadharCard ? (
//                                     <a
//                                       href={userData.Documents.aadharCard}
//                                       target="_blank"
//                                       rel="noopener noreferrer"
//                                     >
//                                       View Aadhar
//                                     </a>
//                                   ) : (
//                                     "Not Uploaded"
//                                   )}
//                                 </td>
//                               </tr>
//                             )}
//                             {relevantDocuments.includes(
//                               "migrationCertificate"
//                             ) && (
//                               <tr>
//                                 <th>Migration</th>
//                                 <td>:</td>
//                                 <td>
//                                   {userData?.Documents?.migrationCertificate ? (
//                                     <a
//                                       href={
//                                         userData.Documents.migrationCertificate
//                                       }
//                                       target="_blank"
//                                       rel="noopener noreferrer"
//                                     >
//                                       View Migration
//                                     </a>
//                                   ) : (
//                                     "Not Uploaded"
//                                   )}
//                                 </td>
//                               </tr>
//                             )}
//                             {relevantDocuments.includes(
//                               "domicileCertificate"
//                             ) && (
//                               <tr>
//                                 <th>Domicile Certificate</th>
//                                 <td>:</td>
//                                 <td>
//                                   {userData?.Documents?.domicileCertificate ? (
//                                     <a
//                                       href={
//                                         userData.Documents.domicileCertificate
//                                       }
//                                       target="_blank"
//                                       rel="noopener noreferrer"
//                                     >
//                                       View Certificate
//                                     </a>
//                                   ) : (
//                                     "Not Uploaded"
//                                   )}
//                                 </td>
//                               </tr>
//                             )}
//                             {relevantDocuments.includes(
//                               "transferCertificate"
//                             ) && (
//                               <tr>
//                                 <th>Transfer Certificate</th>
//                                 <td>:</td>
//                                 <td>
//                                   {userData?.Documents?.transferCertificate ? (
//                                     <a
//                                       href={
//                                         userData.Documents.transferCertificate
//                                       }
//                                       target="_blank"
//                                       rel="noopener noreferrer"
//                                     >
//                                       View Marksheet
//                                     </a>
//                                   ) : (
//                                     "Not Uploaded"
//                                   )}
//                                 </td>
//                               </tr>
//                             )}
//                             {relevantDocuments.includes(
//                               "incomeCertificate"
//                             ) && (
//                               <tr>
//                                 <th>Income Certificate</th>
//                                 <td>:</td>
//                                 <td>
//                                   {userData?.Documents?.incomeCertificate ? (
//                                     <a
//                                       href={
//                                         userData.Documents.incomeCertificate
//                                       }
//                                       target="_blank"
//                                       rel="noopener noreferrer"
//                                     >
//                                       View Certificate
//                                     </a>
//                                   ) : (
//                                     "Not Uploaded"
//                                   )}
//                                 </td>
//                               </tr>
//                             )}
//                             {relevantDocuments.includes("otherCertificate") && (
//                               <tr>
//                                 <th>Other Certificate</th>
//                                 <td>:</td>
//                                 <td>
//                                   {userData?.Documents?.otherCertificate ? (
//                                     <a
//                                       href={userData.Documents.otherCertificate}
//                                       target="_blank"
//                                       rel="noopener noreferrer"
//                                     >
//                                       View Certificate
//                                     </a>
//                                   ) : (
//                                     "Not Uploaded"
//                                   )}
//                                 </td>
//                               </tr>
//                             )}
//                           </tbody>
//                         </table>
//                       </div>
//                     </div>
//                   </Box>
//                 )}
//               </Box>
//             </Grid>
//           </Grid>
//         </Box>
//       </ThemeProvider>
//     </>
//   );
// }

// export default StudentAllDetail;

// // {
// //   ["12th", "Diploma", "ITI"].includes(userData?.qualification) && (
// //     <>
// //       <tr style={{ fontSize: "20px" }}>
// //         <b>
// //           {userData?.qualification === "ITI"
// //             ? "ITI"
// //             : userData?.qualification === "Diploma"
// //             ? "Diploma"
// //             : "12th"}
// //         </b>
// //       </tr>
// //     </>
// //   );
// // }

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import logo from "../../AdminComponent/12.png";
import StudentDashboard from "../../AdminComponent/StudentDashboard";

const StudentAllDetail = () => {
  const [activeTab, setActiveTab] = useState("personal");
  const [isEnrollGenerated, setIsEnrollGenerated] = useState(false);
  const navigate = useNavigate();
  const userData = JSON.parse(sessionStorage.getItem("currentUser") || "{}");

  const {
    isEnrolled = false,
    isRegistered = false,
    isEnrollPaid = false,
    courseName = "",
    name = "",
    enrollmentNumber = "",
    Documents = {},
    professional = {},
    academicDetails = {},
    address = {},
    qualification = "",
  } = userData;

  const isRestricted = !(isRegistered && isEnrolled);

  useEffect(() => {
    setIsEnrollGenerated(userData?.IsEnrollGenerated);
    if (courseName) {
      if (!isEnrolled) {
        swal({
          icon: "warning",
          title: "Enrollment Required",
          text: "Please fill the enrollment form first.",
          buttons: { cancel: "Cancel", confirm: "OK" },
        }).then((value) => value && navigate("/student/Enrollement/Form"));
      } else if (!isEnrollPaid) {
        swal({
          icon: "warning",
          title: "Payment Pending",
          text: "Please complete the payment for enrollment.",
          buttons: { cancel: "Cancel", confirm: "OK" },
        }).then(
          (value) => value && navigate("/Epravesh/Student/EnrollementFee")
        );
      }
    }
  }, [courseName, isEnrolled, isEnrollPaid, navigate]);

  const formatDate = (inputDate) =>
    inputDate
      ? new Date(inputDate).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
      : "N/A";

      const generatePDF = async () => {
        const pdf = new jsPDF();
    
        // Add the university logo
        pdf.addImage(logo, "JPEG", 8, 5, 20, 20);
    
        // Header text
        pdf.setTextColor(165, 42, 42);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(12);
        pdf.text(
          "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
          115,
          10,
          { align: "center" }
        );
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(11);
        pdf.text(
          "[Established Under Govt. of (M.P.) & Registered UGC 2(F),1956]",
          110,
          16,
          { align: "center" }
        );
        pdf.text("Sh-18 Bhopal-Indore Road, Pin Code - 466001 Pachama, Sehore", 110, 22, {
          align: "center",
        });
        pdf.text("Sehore (M.P.) Pin Code - 466001", 110, 28, { align: "center" });
    
        // Academic year
        const nextYear = parseInt(userData?.admissionSession) + 1;
        pdf.setFont("helvetica", "bold");
        pdf.text(
          `Academic Year ${userData?.admissionSession} - ${nextYear}`,
          115,
          34,
          { align: "center" }
        );
    
        // Enrollment form title
        pdf.setDrawColor(0);
        pdf.setFillColor(230, 230, 230);
        pdf.rect(65, 38, 85, 10, "F");
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        pdf.setTextColor(0, 0, 0);
        pdf.text("Enrollment Form", 105, 45, { align: "center" });
    
        // Enrollment details
        const detailsData = [
          ["Enrollment No.", userData?.enrollmentNumber || "Not Generated"],
          ["Name", userData.name],
          ["Father's Name", userData.fathersname],
          ["Mother's Name", userData.mothersname],
          ["Gender", userData.gender],
          ["Date of Birth", formatDate(userData.dob)],
          ["Domicile", userData.domicile],
          ["Category", userData.category],
          ["Mobile No.", userData.mobile],
          ["Email", userData.email],
          ["Course Name", userData.courseName],
          ["Course Type", userData.courseType],
          ["Specialization", userData.courseBranch],
          ["Institute", userData.assignedCollege || "Not assigned"],
          ["ABC ID", userData.AbcId || "Not Upload"],
          ["Last Passed Qualafication", userData.qualification || "Not Upload"],
          [
            "Last Passed Subject",
            userData.lastPassedSubject || userData.QualifiedCourse || "Not Upload",
          ],
        ];
    
        if (courseName === "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)") {
          detailsData.push(
            ["Neet Qualified Percentage", `${userData?.NeetPercentage}%` || ""],
            ["Admission Category", userData?.Concession || ""]
          );
        }
    
        let currentY = 50;
        pdf.autoTable({
          body: detailsData,
          startY: currentY,
          margin: { left: 10 },
          tableWidth: "wrap",
          columnStyles: {
            0: { cellWidth: 80 },
            1: { cellWidth: 110 },
          },
        });
    
        // Add student image without background lines
        const studentImage = userData?.Documents?.applicantPhoto;
        if (studentImage) {
          pdf.addImage(studentImage, "JPEG", 162, 50, 28, 34);
        }
    
        // Address Details with reduced space
        currentY = pdf.lastAutoTable.finalY + 10; // Reduced from 10 to 5
        // pdf.setFont("helvetica", "bold");
        // pdf.setFontSize(12);
        pdf.text("Address Details", 12, currentY);
    
        const addressData = [
          ["Local Address", userData?.address?.address1 || ""],
          ["Permanent Address", userData?.address?.address2 || ""],
          ["Country", userData?.address?.country || ""],
          ["State", userData?.address?.state || ""],
          ["District", userData?.address?.district || ""],
          ["Pin Code", userData?.address?.pinCode || ""],
        ];
    
        pdf.autoTable({
          body: addressData,
          startY: currentY + 5,
          margin: { left: 12 },
          tableWidth: "wrap",
          columnStyles: {
            0: { cellWidth: 80 },
            1: { cellWidth: 110 },
          },
        });
    
        // Professional Details with reduced space
        currentY = pdf.lastAutoTable.finalY + 5; // Reduced from 10 to 5
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(12);
        pdf.text("Professional Details", 12, currentY);
    
        const professionalData = [
          ["Handicapped", userData?.professional?.Handicapped || "No"],
          ["Medium", userData?.professional?.Medium || ""],
          ["Father's Occupation", userData?.professional?.FathersOccupation || ""],
          ["Mother's Income", userData?.professional?.MothersIncome || ""],
          ["Aadhar Number", userData?.professional?.AadharNumber || ""],
          ["Parent Mobile", userData?.professional?.ParentMobile || ""],
        ];
    
        pdf.autoTable({
          body: professionalData,
          startY: currentY + 5,
          margin: { left: 12 },
          tableWidth: "wrap",
          columnStyles: {
            0: { cellWidth: 80 },
            1: { cellWidth: 110 },
          },
        });
    
        // Academic Details with only 10th and 12th if available
        currentY = pdf.lastAutoTable.finalY + 5; // Reduced from 15 to 5
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(12);
        pdf.text("Academic Details", 13, currentY);
    
        const academicData = [];
        // Add 10th details if available
        if (userData?.academicDetails?.tenthPassingYear) {
          academicData.push([
            "Tenth Details",
            `${userData?.academicDetails?.tenthPassingYear}`,
            `${userData?.academicDetails?.tenthRollNo || "N/A"}`,
            `${userData?.academicDetails?.tenthBoard || "N/A"}`,
            `${userData?.academicDetails?.tenthMarksObtain || "CGPA"}`,
            `${userData?.academicDetails?.tenthMaxMarks || "CGPA"}`,
            `${
              userData?.academicDetails?.tenthPercentage
                ? userData?.academicDetails?.tenthPercentage
                : userData?.academicDetails?.cgpa || "N/A"
            }`,
          ]);
        }
        // Add 12th details if available
        if (userData?.academicDetails?.twelfthPassingYear) {
          academicData.push([
            "Twelfth/Diploma Details",
            `${userData?.academicDetails?.twelfthPassingYear}`,
            `${userData?.academicDetails?.twelfthRollNo || "N/A"}`,
            `${userData?.academicDetails?.twelfthBoard || "N/A"}`,
            `${userData?.academicDetails?.twelfthMarksObtain || "N/A"}`,
            `${userData?.academicDetails?.twelfthMaxMarks || "CGPA"}`,
            `${
              userData?.academicDetails?.twelfthPercentage
                ? userData?.academicDetails?.twelfthPercentage
                : userData?.academicDetails?.cgpa || "N/A"
            }`,
          ]);
        }
    
        pdf.autoTable({
          head: [
            [
              "Qualification",
              "Passing Year",
              "Roll No.",
              "Board",
              "Obtained Marks",
              "Max Marks",
              "Percentage/CGPA",
            ],
          ],
          body: academicData,
          startY: currentY + 5,
          theme: "grid",
          styles: { fontSize: 9 },
          margin: { left: 14 },
        });
    
        // New Documents Section
        currentY = pdf.lastAutoTable.finalY + 5;
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(12);
        pdf.text("Documents", 13, currentY);
    
        const documentData = [];
        if (userData?.Documents) {
          Object.entries(userData.Documents).forEach(([key, value]) => {
            if (value && key !== "_id") {
              documentData.push([key, "Uploaded"]);
            }
          });
        }
    
        pdf.autoTable({
          body: documentData,
          startY: currentY + 5,
          margin: { left: 14 },
          tableWidth: "wrap",
          columnStyles: {
            0: { cellWidth: 80 },
            1: { cellWidth: 110 },
          },
        });
    
        // Declaration and rest of the code remains unchanged
        currentY = pdf.lastAutoTable.finalY + 10;
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(10);
        pdf.text("Declaration:", 12, currentY);
        currentY += 5;
        const declarationText = [
          "I hereby declare that the information provided in this form and its attachments is accurate to the best of my knowledge.",
          "I understand that any false information may result in disqualification.",
          "If selected for admission, I agree to abide by the rules and regulations of the university.",
          "I understand that the admission is provisional, subject to confirmation by the concerned authorities, and that the fee paid",
          " is non-refundable in case of cancellation.",
        ];
        declarationText.forEach((line, index) => {
          pdf.text(line, 12, currentY + index * 5);
        });
    
        currentY += 25;
        pdf.text("Certificate ………", 12, currentY);
    
        currentY += 10;
        pdf.setFont("helvetica", "italic");
        pdf.setFontSize(10);
        pdf.text(
          "Certified that the above student has been admitted to this institution as per the procedure laid down by regulatory authorities.",
          12,
          currentY
        );
        currentY += 10;
        pdf.text(
          "The entries above have been checked and verified by me from the records.",
          12,
          currentY
        );
        currentY += 10;
        pdf.text(
          "Therefore, I recommend that he/she should be enrolled as requested by the student.",
          12,
          currentY
        );
    
        const applicantSignature = userData?.Documents?.applicantSignature;
        if (applicantSignature) {
          pdf.addImage(applicantSignature, "JPEG", 20, currentY + 20, 40, 15);
          pdf.text("Applicant's Signature", 20, currentY + 40);
        }
    
        pdf.text("Signature of the Dean with seal", 140, currentY + 40);
    
        pdf.save(`${userData?.name} Enrollment Form.pdf`);
      };

  const generatePDF1 = async () => {
    const pdf = new jsPDF();
    console.log(userData,"kya haiiii")

    // Add header logo
    pdf.addImage(logo, "JPEG", 8, 5, 20, 20);

    // Add header text
    pdf.setTextColor(165, 42, 42);
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(12);
    pdf.text(
      "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
      115,
      10,
      { align: "center" }
    );
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(11);
    pdf.text(
      "[Established Under Govt. of (M.P.) & Registered UGC 2(F),1956]",
      110,
      16,
      { align: "center" }
    );
    pdf.text("SH-18, Bhopal-Indore Road, Pachama,", 110, 22, {
      align: "center",
    });
    pdf.text("Sehore (M.P.) Pin Code - 466001", 110, 28, { align: "center" });

    // Academic year
    pdf.setFont("helvetica", "bold");
    const nextYear = parseInt(userData?.admissionSession) + 1;
    pdf.text(
      `Academic Year ${userData?.admissionSession}`,
      115,
      34,
      { align: "center" }
    );

    // Title with a gray box
    pdf.setDrawColor(0);
    pdf.setFillColor(230, 230, 230);
    pdf.rect(65, 38, 85, 10, "F");
    pdf.setFontSize(14);
    pdf.setFont("helvetica", "bold");
    pdf.setTextColor(0, 0, 0);
    pdf.text("Admission Slip", 105, 45, { align: "center" });

    // Fields setup
    let y = 60; // Starting y position
    const lineSpacing = 8;
    const fieldValueStartX = 100;

    // Function to add fields with address wrapping logic
    const addField = (label, value, isMultiline = false) => {
      pdf.setFontSize(10);
      pdf.setFont("helvetica", "bold");
      pdf.text(`${label}`, 20, y);
      pdf.setFont("helvetica", "normal");

      if (isMultiline && value) {
        const splitValue = pdf.splitTextToSize(value, 80); // Wrap text to fit within 80 units
        splitValue.forEach((line) => {
          pdf.text(line, fieldValueStartX, y);
          y += lineSpacing; // Add spacing for each wrapped line
        });
      } else {
        const fieldValue = value ? String(value) : "Not Provided";
        pdf.text(fieldValue, fieldValueStartX, y);
        y += lineSpacing;
      }
    };

    const formattedDOB = userData?.dob
      ? new Date(userData.dob).toLocaleDateString("en-GB")
      : "Not Provided";

    const admissionDate = userData?.admissionDate || "Not Assigned";

    const formFields = [
      ["Enrollment Number", userData?.enrollmentNumber || ""],
      ["Name of Candidate", userData?.name || ""],
      ["Father's Name", userData?.fathersname || ""],
      ["Mother's Name", userData?.mothersname || ""],
      ["Gender", userData?.gender || ""],
      ["Date of Birth", formattedDOB || ""],
      // ["Address", userData?.address.address1  || "", true], 
      ["Address", `${userData?.address?.address1 || ""} ${userData?.address?.address2 || ""}`, true],
      ["Domicile", userData?.domicile || ""],
      ["Category", userData?.category || ""],
      ["Mode of Admission", userData?.admissionMode || "Regular"],
      ["Email", userData?.email || ""],
      ["Course Type", userData.courseType || ""],
      ["Course Name", userData.course || ""],
      ["Specialization", userData.branch || ""],
      ["Institute", userData?.assignedCollege || ""],
      ["Mobile Number", userData?.mobile || ""],
      ["Date of Admission", admissionDate],
    ];

    formFields.forEach(([label, value, isMultiline]) =>
      addField(label, value, isMultiline)
    );

    // userData image
    const userDataImage = userData?.Documents?.applicantPhoto;
    if (userDataImage) {
      pdf.addImage(userDataImage, "JPEG", 162, 60, 30, 35);
    }

    // Notes section
    y += 10;
    pdf.setFont("helvetica", "normal");
    pdf.text(
      "- I have checked all the original documents and verified the eligibility of the candidate.",
      20,
      y
    );
    y += 6;
    pdf.text(
      "- If it found that candidate hide any information or provide any incorrect information at the time of admission,",
      20,
      y
    );
    y += 6;
    pdf.text(
      "University has right to cancel the admission at the stage.",
      20,
      y
    );

    // Signatures
    y += 30;
    const applicantSignature = userData?.Documents?.applicantSignature;
    if (applicantSignature) {
      pdf.addImage(applicantSignature, "JPEG", 20, y - 5, 40, 15);
      pdf.text("Applicant's Signature", 20, y + 15);
    }

    const collegeName = `${userData?.assignedCollege}`;
    const textStart = 130;
    pdf.text("Name & Signature With Seal", textStart, y);
    pdf.text("HOD/Principal/Dean", textStart, y + 7);

    if (collegeName.length > 45) {
      pdf.text(collegeName.substring(0, 45), textStart, y + 14);
      pdf.text(collegeName.substring(45), textStart, y + 21);
    } else {
      pdf.text(collegeName, textStart, y + 14);
    }

    // Save the PDF
    pdf.save(`${userData?.name}_Admission_Slip.pdf`);
  };

  const shouldDisplaySection = (qualification1) => {
    const qualificationOrder = {
      "10th": 1,
      "12th": 2,
      ITI: 3,
      Diploma: 4,
      UG: 5,
      Graduation: 6,
      BSC: 7,
      PG: 8,
    };
    return (
      qualificationOrder[qualification1] <= qualificationOrder[qualification]
    );
  };

  const getDocumentsByQualification = () => {
    const docMap = {
      PG: [
        "applicantPhoto",
        "aadharCard",
        "applicantSignature",
        "domicileCertificate",
        "marksheet10th",
        "marksheet12th",
        "undergraduateCertificate",
        "migrationCertificate",
        "transferCertificate",
        "incomeCertificate",
        "otherCertificate",
      ],
      UG: [
        "applicantPhoto",
        "aadharCard",
        "applicantSignature",
        "domicileCertificate",
        "marksheet10th",
        "marksheet12th",
        "undergraduateCertificate",
        "migrationCertificate",
        "transferCertificate",
        "incomeCertificate",
        "otherCertificate",
      ],
      "12th": [
        "applicantPhoto",
        "aadharCard",
        "applicantSignature",
        "marksheet10th",
        "marksheet12th",
        "migrationCertificate",
        "transferCertificate",
        "domicileCertificate",
        "incomeCertificate",
        "otherCertificate",
      ],
      "10th": [
        "applicantPhoto",
        "aadharCard",
        "applicantSignature",
        "marksheet10th",
        "domicileCertificate",
        "migrationCertificate",
        "transferCertificate",
        "incomeCertificate",
        "otherCertificate",
      ],
      Diploma: [
        "applicantPhoto",
        "aadharCard",
        "applicantSignature",
        "marksheet10th",
        "diplomaMarksheet",
        "domicileCertificate",
        "migrationCertificate",
        "transferCertificate",
        "incomeCertificate",
        "otherCertificate",
      ],
      Graduation: [
        "applicantPhoto",
        "aadharCard",
        "applicantSignature",
        "marksheet10th",
        "undergraduateCertificate",
        "diplomaMarksheet",
        "domicileCertificate",
        "migrationCertificate",
        "transferCertificate",
        "incomeCertificate",
        "otherCertificate",
      ],
      BSC: [
        "applicantPhoto",
        "aadharCard",
        "applicantSignature",
        "marksheet10th",
        "marksheet12th",
        "undergraduateCertificate",
        "domicileCertificate",
        "migrationCertificate",
        "transferCertificate",
        "incomeCertificate",
        "otherCertificate",
      ],
      ITI: [
        "applicantPhoto",
        "applicantSignature",
        "aadharCard",
        "marksheet10th",
        "ItImarksheet",
        "domicileCertificate",
        "migrationCertificate",
        "transferCertificate",
        "incomeCertificate",
        "otherCertificate",
      ],
    };
    return docMap[qualification] || [];
  };

  const relevantDocuments = getDocumentsByQualification();

  return (
    <>
      <StudentDashboard />
      <div className="student-container">
        {/* Enhanced Profile Card */}
        <div className="profile-card">
          <div className="profile-header">
            <div className="profile-img-wrapper">
              <img
                src={
                  Documents?.applicantPhoto || "https://via.placeholder.com/150"
                }
                alt="Profile"
                className="profile-img"
              />
            </div>
            <h2>{name || "N/A"}</h2>
            {/* <span className="profile-subtitle"><strong>Course Type: </strong>{userData?.courseType}</span> */}
            <span className="profile-subtitle">
              <strong>Course: </strong>
              {courseName || "Course Not Assigned"}
            </span>
            <span className="profile-subtitle">
              <strong>Branch: </strong>
              {userData?.courseBranch || "Branch Not Assigned"}
            </span>
          </div>
          <div className="profile-details">
            <ProfileItem
              label="Admission Session"
              value={userData?.admissionSession}
            />
            <ProfileItem
              label="Admission Type"
              value={userData?.StudentType || "Normal Admission"}
            />
            <ProfileItem
              label="Fee Status"
              value={userData?.isPaid ? "Paid" : "Not Paid"}
              status={userData?.isPaid ? "active" : "pending"}
            />
            <ProfileItem
              label="Enrollment Form Status"
              value={isEnrolled ? "Filled" : "Not Filled"}
              status={isEnrolled ? "active" : "pending"}
            />
            <ProfileItem
              label="Enrollment Fee"
              value={userData?.isEnrollPaid ? "Paid" : "Not Paid"}
              status={userData?.isEnrollPaid ? "active" : "pending"}
            />
            <ProfileItem
              label="Enrollment No"
              value={enrollmentNumber || "Not Generated"}
              status={enrollmentNumber ? "active" : "pending"}
            />
          </div>
          <div className="button-group">
            <button className="neon-button" onClick={generatePDF}>
              <i className="fa-solid fa-download"></i> Enrollment Details
            </button>
            <button className="neon-button" onClick={generatePDF1} disabled={!userData?.admissionDate}>
              <i className="fa-solid fa-download"></i> Admission Slip
            </button>
          </div>
        </div>

        {/* Tabs Section */}
        <div className="tabs-container">
          <div className="tabs-header">
            {[
              { id: "personal", label: "Personal Info" },
              {
                id: "professional",
                label: "Professional Info",
                restricted: isRestricted,
              },
              {
                id: "academic",
                label: "Academic Info",
                restricted: isRestricted,
              },
              { id: "documents", label: "Documents", restricted: isRestricted },
            ].map((tab) => (
              <div
                key={tab.id}
                className={`tab-item ${activeTab === tab.id ? "active" : ""} ${
                  tab.restricted ? "disabled" : ""
                }`}
                onClick={() => !tab.restricted && setActiveTab(tab.id)}
              >
                {tab.label}
              </div>
            ))}
          </div>
          <div className="tabs-content">
            {activeTab === "personal" && (
              <div className="data-grid">
                <DataItem label="Gender" value={userData?.gender} />
                <DataItem label="Category" value={userData?.category} />
                <DataItem label="Father's Name" value={userData?.fathersname} />
                <DataItem label="Mother's Name" value={userData?.mothersname} />
                <DataItem label="Contact" value={userData?.mobile} />
                <DataItem
                  label="Nationality"
                  value={userData?.nationality || "Not Uploaded"}
                />
                <DataItem
                  label="DOB"
                  value={userData.dob}
                  // value={
                  //   <DatePicker
                  //     dateFormat="dd/MM/yyyy"
                  //     selected={userData?.dob ? new Date(userData.dob) : null}
                  //     readOnly
                  //   />
                  // }
                />
                <DataItem label="Email" value={userData?.email} />
                <DataItem
                  label="Domicile"
                  value={userData?.domicile || "Not Uploaded"}
                />
                <DataItem
                  label="Last Exam Type"
                  value={userData?.lastExamType}
                />
                <DataItem
                  label="Scholarship Required"
                  value={professional?.ScholarshipRequired || "Not Eligible"}
                />
                <DataItem label="Last Qualification" value={qualification} />
                <DataItem
                  label="Last Percentage/CGPA"
                  value={
                    userData?.qualificationPercentage ||
                    userData?.LastPercentage ||
                    userData?.cgpa ||
                    "Not Uploaded"
                  }
                />
                <DataItem
                  label="Last Passed Subject"
                  value={userData?.lastPassedSubject ||userData?.QualifiedCourse }
                />
                <DataItem
                  label="Last Passing Year"
                  value={userData?.passingYear}
                />
                <DataItem
                  label="ABC Id"
                  value={userData?.AbcId || "Not Available"}
                />
              </div>
            )}
            {activeTab === "professional" && !isRestricted && (
              <div className="data-grid">
                <DataItem
                  label="Handicapped"
                  value={professional?.Handicapped}
                />
                <DataItem label="Medium" value={professional?.Medium} />
                <DataItem
                  label="Aadhar Number"
                  value={professional?.AadharNumber}
                />
                <DataItem
                  label="Samagra ID"
                  value={professional?.SamagraId || "Not Available"}
                />
                <DataItem
                  label="Father's Occupation"
                  value={professional?.FathersOccupation}
                />
                <DataItem
                  label="Mother's Occupation"
                  value={professional?.MothersOccupation}
                />
                <DataItem
                  label="Father's Income"
                  value={professional?.FathersIncome}
                />
                <DataItem
                  label="Mother's Income"
                  value={professional?.MothersIncome}
                />
                <DataItem
                  label="Parent Mobile"
                  value={professional?.ParentMobile}
                />
                <DataItem
                  label="Current Address"
                  value={address?.address1 || "Not Filled"}
                />
                <DataItem label="Permanent Address" value={address?.address2} />
                <DataItem label="Country" value={address?.country} />
                <DataItem label="State" value={address?.state} />
                <DataItem label="District" value={address?.district} />
                <DataItem label="Pin Code" value={address?.pinCode} />
              </div>
            )}
            {activeTab === "academic" && !isRestricted && (
              <div className="academic-sections">
                {["10th", "12th", "UG", "PG"].map(
                  (level) =>
                    shouldDisplaySection(level) && (
                      <div key={level} className="academic-section">
                        <h3>
                          {level === "UG"
                            ? "Graduation"
                            : level === "PG"
                            ? "Post Graduation"
                            : level}
                        </h3>
                        <div className="data-grid">
                          {level === "10th" && (
                            <>
                              <DataItem
                                label="School"
                                value={academicDetails?.tenthSchool}
                              />
                              <DataItem
                                label="Board"
                                value={academicDetails?.tenthBoard}
                              />
                              <DataItem
                                label="Passing Year"
                                value={academicDetails?.tenthPassingYear}
                              />
                              <DataItem
                                label="Max. Marks"
                                value={academicDetails?.tenthMaxMarks}
                              />
                              <DataItem
                                label="Marks Obtained"
                                value={academicDetails?.tenthMarksObtain}
                              />
                              <DataItem
                                label="Percentage"
                                value={academicDetails?.tenthPercentage}
                              />
                              <DataItem
                                label="Roll No"
                                value={academicDetails?.tenthRollNo}
                              />
                            </>
                          )}
                          {level === "12th" && (
                            <>
                              <DataItem
                                label="School"
                                value={academicDetails?.twelfthSchool}
                              />
                              <DataItem
                                label="Board"
                                value={academicDetails?.twelfthBoard}
                              />
                              <DataItem
                                label="Passing Year"
                                value={academicDetails?.twelfthPassingYear}
                              />
                              <DataItem
                                label="Max. Marks"
                                value={academicDetails?.twelfthMaxMarks}
                              />
                              <DataItem
                                label="Marks Obtained"
                                value={academicDetails?.twelfthMarksObtain}
                              />
                              <DataItem
                                label="Percentage"
                                value={academicDetails?.twelfthPercentage}
                              />
                              <DataItem
                                label="Roll No"
                                value={academicDetails?.twelfthRollNo}
                              />
                            </>
                          )}
                          {level === "UG" && (
                            <>
                              <DataItem
                                label="College"
                                value={
                                  academicDetails?.graduationCollege ||
                                  "Not Uploaded"
                                }
                              />
                              <DataItem
                                label="University"
                                value={
                                  academicDetails?.graduationUniversity ||
                                  "Not Uploaded"
                                }
                              />
                              <DataItem
                                label="Passing Year"
                                value={
                                  academicDetails?.graduationPassingYear ||
                                  "Not Uploaded"
                                }
                              />
                              <DataItem
                                label="Max. Marks"
                                value={
                                  academicDetails?.graduationMaxMarks ||
                                  "Not Uploaded"
                                }
                              />
                              <DataItem
                                label="Marks Obtained"
                                value={
                                  academicDetails?.graduationMarksObtain ||
                                  "Not Uploaded"
                                }
                              />
                              <DataItem
                                label="Percentage"
                                value={
                                  academicDetails?.graduationPercentage ||
                                  "Not Uploaded"
                                }
                              />
                              <DataItem
                                label="Roll No"
                                value={
                                  academicDetails?.graduationRollNo ||
                                  "Not Uploaded"
                                }
                              />
                            </>
                          )}
                          {level === "PG" && (
                            <>
                              <DataItem
                                label="College"
                                value={
                                  academicDetails?.postGraduationCollege ||
                                  "Not Uploaded"
                                }
                              />
                              <DataItem
                                label="University"
                                value={
                                  academicDetails?.postGraduationUniversity ||
                                  "Not Uploaded"
                                }
                              />
                              <DataItem
                                label="Passing Year"
                                value={
                                  academicDetails?.postGraduationPassingYear ||
                                  "Not Uploaded"
                                }
                              />
                              <DataItem
                                label="Max. Marks"
                                value={
                                  academicDetails?.postGraduationMaxMarks ||
                                  "Not Uploaded"
                                }
                              />
                              <DataItem
                                label="Marks Obtained"
                                value={
                                  academicDetails?.postGraduationMarksObtain ||
                                  "Not Uploaded"
                                }
                              />
                              <DataItem
                                label="Percentage"
                                value={
                                  academicDetails?.postGraduationPercentage ||
                                  "Not Uploaded"
                                }
                              />
                              <DataItem
                                label="Roll No"
                                value={
                                  academicDetails?.postGraduationRollNo ||
                                  "Not Uploaded"
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>
                    )
                )}
              </div>
            )}
            {activeTab === "documents" && !isRestricted && (
              <div className="data-grid">
                {relevantDocuments.map((doc) => (
                  <DataItem
                    key={doc}
                    label={doc.replace(/([A-Z])/g, " $1").trim()}
                    value={
                      Documents[doc] ? (
                        <a
                          href={Documents[doc]}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View
                        </a>
                      ) : (
                        "Not Uploaded"
                      )
                    }
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <style>{`
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

        .student-container {
          min-height: 50vh;
          background: linear-gradient(135deg, #f0f4f8 0%, #e1e8ed 100%);
          padding: 20px;
          margin-top: 54px; /* Adjusted to account for navbar height */
          margin-left: 80px;
          display: flex;
          gap: 20px;
          font-family: 'Poppins', sans-serif;
          color: #333;
          flex-wrap: wrap;
          width: calc(100% - 80px);
          box-sizing: border-box;
        }

        /* Enhanced Profile Card */
        .profile-card {
          width: 320px;
          background: linear-gradient(145deg, #ffffff, #f0f4f8);
          border-radius: 15px;
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
          padding: 15px;
          transition: transform 0.3s ease;
          height: fit-content;
          max-height: calc(100vh - 85px);
          overflow-y: hidden;
        }

        @media (min-width: 769px) {
          .profile-card {
            position: sticky;
            top: 75px;
          }
        }

        .profile-card:hover {
          transform: translateY(-5px);
        }

        .profile-header {
          text-align: center;
          border-bottom: 1px solid rgba(0, 121, 107, 0.2);
          padding-bottom: 10px;
          margin-bottom: 10px;
        }

        .profile-img-wrapper {
          position: relative;
          width: 100px;
          height: 100px;
          margin: 0 auto 8px;
        }

        .profile-img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
          border: 4px solid #fff;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          position: relative;
          z-index: 1;
        }

        .profile-img-wrapper::before {
          content: '';
          position: absolute;
          top: -8px;
          left: -8px;
          width: 116px;
          height: 116px;
          border-radius: 50%;
          background: #013a63;
          z-index: 0;
          animation: spinGradient 6s infinite linear;
        }

        @keyframes spinGradient {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }

        .profile-header h2 {
          font-size: 1.4rem;
          color: #0d47a1;
          margin: 0;
        }

        .profile-subtitle {
          font-size: 0.85rem;
          color: #666;
          text-align: left;
          display: block;
          margin-top: 4px;
        }

        .profile-details {
        color:#1b4332;
          font-size: 0.85rem;
        }

        .profile-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 6px 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }

        .profile-item strong {
          color: #0d47a1;
          font-weight: 600;
        }

        .status-active {
          font-weight: 600;
        }

        .status-pending {
          color: #bc4749;
          font-weight: 600;
        }

        .button-group {
          margin-top: 15px;
          display: flex;
          flex-direction: row;
          gap: 8px;
          justify-content: center;
          flex-wrap: wrap;
          align-items: center;
        }

        .neon-button {
          padding: 4px 8px;
          background: none;
          border: 2px solid #0d47a1;
          color: #0d47a1;
          border-radius: 10px;
          font-size: 0.85rem;
          cursor: pointer;
          transition: all 0.3s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 6px;
          width: 100%;
        }

        .neon-button:disabled {
          border-color: #999;
          color: #999;
          cursor: not-allowed;
        }

        .neon-button:hover:not(:disabled) {
          background: #0d47a1;
          color: #fff;
          box-shadow: 0 0 15px rgba(0, 121, 107, 0.5);
        }

        /* Tabs */
        .tabs-container {
          flex: 1;
          max-width: 900px;
          width: 100%;
          min-width: 0;
        }

        .tabs-header {
          display: flex;
          background: rgba(0, 0, 0, 0.05);
          border-radius: 10px 10px 0 0;
          overflow-x: auto;
          flex-wrap: nowrap;
          white-space: nowrap;
        }

        .tab-item {
          flex: 1;
          padding: 15px;
          text-align: center;
          font-size: 1.1rem;
          font-weight: 600;
          color: #666;
          cursor: pointer;
          transition: all 0.3s ease;
          min-width: 80px;
          position: relative;
        }

        .tab-item.active {
          background: #0d47a1;
          color: #fff;
        }

        .tab-item.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        .tab-item:hover:not(.disabled):not(.active) {
          background: rgba(0, 121, 107, 0.1);
          color: #0d47a1;
        }

        .tabs-content {
          background: rgba(255, 255, 255, 0.9);
          border-radius: 0 0 10px 10px;
          padding: 20px;
          min-height: 450px; /* Fixed height based on Personal Info tab */
          display: flex;
          flex-direction: column;
          overflow-x: hidden;
        }

        .data-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          gap: 7px;
          width: 100%;
        }

        .data-item {
          background: rgba(0, 0, 0, 0.03);
          padding: 10px;
          border-radius: 8px;
          font-size: 0.9rem;
        }

        .data-item strong {
          color: #274c77;
          display: block;
          margin-bottom: 5px;
        }

        .academic-sections {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }

        .academic-section h3 {
          font-size: 1.2rem;
          color: #274c77;
          margin-bottom: 10px;
        }

        .react-datepicker__input-container input {
          color: #333 !important;
          background: transparent !important;
          border: none !important;
          font-family: 'Poppins', sans-serif;
        }

        .react-datepicker {
          color: #333 !important;
        }

        .react-datepicker__day,
        .react-datepicker__day-name,
        .react-datepicker__time-list-item {
          color: #333 !important;
        }

        /* Media Queries */
        @media (max-width: 1024px) {
          .student-container {
            margin-left: 70px;
            width: calc(100% - 70px);
            padding: 15px;
          }

          .profile-card {
            width: 300px;
          }

          .tabs-container {
            max-width: 100%;
          }

          .tabs-content {
            min-height: 400px; /* Slightly reduced for smaller screens */
          }
        }

        @media (max-width: 768px) {
          .student-container {
            flex-direction: column;
            margin-left: 0;
            width: 100%;
            padding: 10px;
            align-items: center;
          }

          .profile-card {
            width: 100%;
            max-width: 400px;
            position: static;
            margin-bottom: 20px;
          }

          .button-group {
            flex-direction: column;
            gap: 15px;
          }

          .tabs-container {
            width: 100%;
            max-width: 100%;
          }

          .tabs-header {
            flex-direction: column;
          }

          .tab-item {
            width: 100%;
            min-width: 0;
          }

          .data-grid {
            grid-template-columns: 1fr;
          }

          .tabs-content {
            min-height: 500px; /* Adjusted for single-column layout */
          }
        }

        @media (max-width: 480px) {
          .student-container {
            padding: 8px;
          }

          .profile-card {
            width: 100%;
            max-width: 100%;
            padding: 12px;
          }

          .profile-img-wrapper {
            width: 90px;
            height: 90px;
          }

          .profile-img-wrapper::before {
            width: 106px;
            height: 106px;
          }

          .profile-header h2 {
            font-size: 1.3rem;
          }

          .profile-subtitle {
            font-size: 0.8rem;
          }

          .button-group {
            flex-direction: column;
            gap: 10px;
          }

          .neon-button {
            width: 100%;
            padding: 6px 10px;
          }

          .tabs-header {
            flex-direction: row;
            overflow-x: auto;
            padding: 5px 0;
          }

          .tab-item {
            flex: 1;
            padding: 10px;
            font-size: 0.8rem;
            min-width: 70px;
            overflow: hidden;
          }

          .tab-item:nth-child(1)::after {
            content: "Personal";
          }
          .tab-item:nth-child(2)::after {
            content: "Professional";
          }
          .tab-item:nth-child(3)::after {
            content: "Academic";
          }
          .tab-item:nth-child(4)::after {
            content: "Documents";
          }

          .tab-item {
            text-indent: -9999px;
            white-space: nowrap;
          }
          .tab-item::after {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            text-indent: 0;
            color: inherit;
            font-size: 0.8rem;
            font-weight: 600;
          }

          .tab-item.active::after {
            color: #fff;
          }

          .data-item {
            font-size: 0.85rem;
          }

          .tabs-content {
            min-height: 600px; /* Adjusted for smaller screens with single column */
          }
        }
      `}</style>
    </>
  );
};

const ProfileItem = ({ label, value, status }) => (
  <div className="profile-item">
    <strong>{label}:</strong>
    <span className={status ? `status-${status}` : ""}>{value || "N/A"}</span>
  </div>
);

const DataItem = ({ label, value }) => (
  <div className="data-item">
    <strong>{label}:</strong>
    <span>{value || "N/A"}</span>
  </div>
);

export default StudentAllDetail;
