import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Sidebar from "../AdminComponent/HodDashboard";
import {
  Button,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
  TableCell,
  Box,
} from "@mui/material/";
import swal from "sweetalert";
import { Table, Spinner } from "react-bootstrap";
import * as XLSX from "xlsx";
import SearchIcon from "@mui/icons-material/Search";

const HodMainDetails = () => {
  const [approvedStudents, setApprovedStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [branches, setBranches] = useState([]);
  const [allBranches, setAllBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedMainOption, setSelectedMainOption] = useState("");
  const [selectedStudentListOption, setSelectedStudentListOption] =
    useState("");
  const [selectedSession, setSelectedSession] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState({});
  const [colleges, setColleges] = useState([]);

  const userData = JSON.parse(sessionStorage.getItem("AnkitHOD"));
  const navigate = useNavigate();
  const branch1 = userData?.Branch || "";
  const course1 = userData?.courseName || "";
  const college = userData?.college || "";
  const associate = userData?.Associated_branch || [];
  const hodId = userData?.user || "";
  const name = userData?.name || "";

  const fetchApprovedStudents = async (
    branch = selectedBranch,
    course = selectedCourse,
    registrationType = selectedMainOption,
    studentListType = selectedStudentListOption,
    session = selectedSession
  ) => {
    setLoading(true);
    setError(null);
    try {
      let url = `https://sssutms.ac.in/apitest/admin/v1/epravesh/approve?branch=${branch}&course=${course}&college=${college}&session=${session}`;
      if (registrationType === "E-Pravesh") {
        url =
          studentListType === "Pending Student List"
            ? `https://sssutms.ac.in/apitest/admin/v1/epravesh/pending?branch=${branch}&course=${course}&college=${college}&admissionSession=${session}`
            : `https://sssutms.ac.in/apitest/admin/v1/epravesh/approve?branch=${branch}&course=${course}&college=${college}&admissionSession=${session}`;
      } else if (registrationType === "Student Registration") {
        url =
          studentListType === "Pending Student List"
            ? `https://sssutms.ac.in/apitest/admin/v1/student/pending?branch=${branch}&course=${course}&college=${college}&admissionSession=${session}`
            : `https://sssutms.ac.in/apitest/admin/v1/student/approve?branch=${branch}&course=${course}&college=${college}&admissionSession=${session}`;
      }
      const response = await axios.get(url);
      setApprovedStudents(response.data.students || []);
      setIsSearched(true);
    } catch (error) {
      console.error(error);
      setError("Failed to fetch students");
      setApprovedStudents([]);
      setIsSearched(true);
    } finally {
      setLoading(false);
    }
  };

  const fetchColleges = async (courseName) => {
    try {
      const response = await axios.get(
        `https://sssutms.ac.in/apitest/v1/hod/colleges/course?courseName=${courseName}`
      );
      setColleges(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      approvedStudents.length > 0 &&
      selectedStudentListOption === "Pending Student List"
    ) {
      const courseName = approvedStudents[0]?.courseName;
      fetchColleges(courseName);
    }
  }, [approvedStudents, selectedStudentListOption]);

  const handleChangePage = (newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (column) => {
    if (column === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const filteredStudents = approvedStudents.filter((student) => {
    const studentName = student.name || "";
    const studentCollege = student.assignedCollege || "";
    return (
      studentCollege.toLowerCase().includes(searchQuery.toLowerCase()) ||
      studentName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (student.StudentType || "")
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  });

  const sortedStudents = [...filteredStudents].sort((a, b) => {
    if (sortBy === "name") {
      const nameA = a.name || "";
      const nameB = b.name || "";
      return sortOrder === "asc"
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    } else if (sortBy === "assignedCollege") {
      const collegeA = a.assignedCollege || "";
      const collegeB = b.assignedCollege || "";
      return sortOrder === "asc"
        ? collegeA.localeCompare(collegeB)
        : collegeB.localeCompare(collegeA);
    }
    return 0;
  });

  useEffect(() => {
    const associatedBranches = JSON.parse(
      sessionStorage.getItem("Associated_branch")
    );
    if (associatedBranches) setAllBranches(associatedBranches);
  }, []);

  const handleChangeCourse = (e) => {
    const courseName = e.target.value;
    setSelectedCourse(courseName);
    const filteredCourse = associate.find(
      (course) => course.courseName === courseName
    );
    const filteredBranches = filteredCourse
      ? filteredCourse.branches.map((branch) => branch.branchName)
      : [];
    setBranches(filteredBranches);
    setSelectedBranch("");
  };

  const handleChangeBranch = (e) => setSelectedBranch(e.target.value);
  const handleMainOptionChange = (e) => setSelectedMainOption(e.target.value);
  const handleStudentListOptionChange = (e) =>
    setSelectedStudentListOption(e.target.value);
  const handleSessionChange = (e) => setSelectedSession(e.target.value);

  const handleSearch = () => {
    if (
      selectedBranch &&
      selectedCourse &&
      selectedMainOption &&
      selectedStudentListOption &&
      selectedSession
    ) {
      fetchApprovedStudents();
    }
  };

  const handleClick = async (studentId) => {
    if (selectedCourses[studentId]) {
      try {
        const response = await axios.put(
          "https://sssutms.ac.in/apitest/approve",
          {
            hodId: hodId,
            studentId,
            assignedCollege: selectedCourses[studentId],
          }
        );

        swal({
          title: "Success",
          text: `${name} Approved Successfully!`,
          icon: "success",
          buttons: "OK",
        });

        setApprovedStudents((prevStudents) =>
          prevStudents.filter((student) => student._id !== studentId)
        );
      } catch (error) {
        console.error(error);
        swal({
          title: "Error",
          text: "Failed to approve student!",
          icon: "error",
          buttons: "OK",
        });
      }
    } else {
      swal({
        title: "Error",
        text: "Please assign college first!",
        icon: "error",
        buttons: "OK",
      });
    }
  };

  const handleCancel = async (studentId) => {
    try {
      const confirm = await swal({
        title: "Are you sure?",
        text: "Are you sure you want to cancel this student?",
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      });

      if (confirm) {
        const response = await axios.post(
          process.env.REACT_APP_REQ_CANCEL_STD ||
            "https://sssutms.ac.in/apitest/cancel",
          {
            studentId,
          }
        );

        swal({
          title: "Success",
          text: "Request Cancelled Successfully!",
          icon: "success",
          buttons: "OK",
        });

        setApprovedStudents((prevStudents) =>
          prevStudents.filter((student) => student._id !== studentId)
        );
      }
    } catch (error) {
      console.error(error);
      swal({
        title: "Error",
        text: "There was an issue cancelling the student.",
        icon: "error",
        buttons: "OK",
      });
    }
  };

  const handleChangeCourse1 = (studentId, event) => {
    setSelectedCourses({
      ...selectedCourses,
      [studentId]: event.target.value,
    });
  };

  const ExportToExcel = () => {
    const Excel = approvedStudents.map((student) => ({
      Student_ID: student.randomId,
      Student_Password: student.randomPassword,
      Admitted_Date: new Date(student.createdAt).toLocaleDateString("en-IN", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
      Name: student.name,
      Fathers_Name: student.fathersname,
      Mothers_Name: student.mothersname,
      Email: student.email,
      Mobile: student.mobile,
      Course_Type: student.courseType,
      Course: student.courseName,
      Branch: student.courseBranch,
      College: student.college,
    }));
    const worksheet = XLSX.utils.json_to_sheet(Excel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Enrolled Student");
    XLSX.writeFile(workbook, "Student_Data.xlsx");
  };

  const totalPages = Math.ceil(sortedStudents.length / rowsPerPage);

  return (
    <>
      <Sidebar />
      <style>
        {`
          .app {
            display: flex;
            min-height: 100vh;
            background: #f5f6fa;
            color: #2d3436;
            font-family: 'Poppins', sans-serif;
          }
          .main {
            flex: 1;
            padding: 25px;
            margin-top: 45px;
            margin-left: 55px;
          }
          .panel {
            background: #fff;
            border-radius: 12px;
            padding: 20px;
            max-width: 1250px;
            margin: 0 auto;
            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
          }
          .filter-grid {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            margin-bottom: 20px;
            justify-content: center;
          }
          .filter-box {
            flex: 1;
            min-width: 200px;
            max-width: 250px;
          }
          .filter-label {
            font-size: 13px;
            font-weight: 500;
            color: #636e72;
            margin-bottom: 5px;
          }
          .filter-select {
            width: 100%;
            height: 38px;
            background: #fff;
            border: 1px solid #b2bec3;
            border-radius: 6px;
            padding: 8px;
            color: #2d3436;
            font-size: 13px;
            transition: border-color 0.3s, box-shadow 0.3s;
          }
          .filter-select:focus {
            border-color: #00b894;
            box-shadow: 0 0 5px rgba(0, 184, 148, 0.3);
            outline: none;
          }
          .control-bar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 15px;
            margin-bottom: 20px;
          }
          .search-container {
            display: flex;
            align-items: center;
            background: #fff;
            border: 1px solid #b2bec3;
            border-radius: 6px;
            padding: 5px 10px;
            width: 100%;
            max-width: 500px;
          }
          .search-field {
            background: transparent;
            border: none;
            outline: none;
            color: #2d3436;
            font-size: 13px;
            flex: 1;
            padding: 5px;
          }
          .btn {
            height: 38px;
            border-radius: 6px;
            font-size: 13px;
            font-weight: 600;
            text-transform: none;
            padding: 0 18px;
            transition: transform 0.2s, background 0.2s;
          }
          .btn:hover {
            transform: translateY(-2px);
          }
          .btn-search {
            background: #00b894;
            color: #fff;
          }
          .btn-export {
            background: transparent;
            border: 1px solid #e17055;
            color: #e17055;
          }
          .table-box {
            background: #fff;
            border-radius: 8px;
            overflow-x: auto;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
          }
          .table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0;
          }
          .th {
            background: #2d3436 !important;
            color: #dfe6e9 !important;
            font-size: 13px;
            font-weight: 600;
            padding: 12px;
            text-align: left;
            position: sticky;
            top: 0;
            zIndex: 2;
            border-bottom: 2px solid #dfe6e9;
          }
          .th:hover {
            background: #40444b !important;
            cursor: pointer;
          }
       
          .th-fixed {
  position: sticky;
  background: #2d3436 !important;
  z-index: 3;
  top: 0;  /* Added to ensure header stays at top when scrolling vertically */
}
          .th-fixed:hover {
            background: #40444b !important;
          }
       .th-sno {
  left: 0;
  min-width: 50px;
  top: 0;  /* Added */
}
          .th-name {
  left: 50px;
  min-width: 200px;
  top: 0;  /* Added */
}

.th-enroll {
  left: 250px;
  min-width: 120px;
  top: 0;  /* Added */
}
          .tr {
            transition: background 0.2s;
          }
          .tr:nth-child(even) {
            background: #f9fbfc;
          }
          .tr:hover {
            background: #e6ecef;
          }
          .td {
            padding: 12px;
            font-size: 12px;
            text-align: left;
            color: #2d3436;
            border-bottom: 1px solid #dfe6e9;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .td-fixed {
            position: sticky;
            background: inherit;
            zIndex: 1;
          }
          .td-sno {
            left: 0;
            min-width: 50px;
          }
          .td-name {
            left: 50px;
            max-width: 200px;
            min-width: 200px;
          }
          .td-enroll {
            left: 250px;
            min-width: 120px;
          }
          .td-email {
            max-width: 180px;
          }
          .td-college {
            max-width: 200px;
          }
          .status-green {
            color: #00b894;
            font-weight: 600;
          }
          .status-red {
            color: #e17055;
            font-weight: 600;
          }
          .empty {
            text-align: center;
            padding: 40px;
            font-size: 14px;
            color: #636e72;
          }
          .pagination {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            padding: 10px 0;
            background: #fff;
            border-top: 1px solid #dfe6e9;
            position: sticky;
            bottom: 0;
            zIndex: 2;
          }
          .pagination-btn {
            padding: 6px 12px;
            border: 1px solid #b2bec3;
            border-radius: 4px;
            background: #fff;
            color: #2d3436;
            cursor: pointer;
            transition: background 0.2s, color 0.2s;
          }
          .pagination-btn:hover {
            background: #00b894;
            color: #fff;
          }
          .pagination-btn:disabled {
            background: #dfe6e9;
            color: #636e72;
            cursor: not-allowed;
          }
          .pagination-select {
            padding: 6px;
            border: 1px solid #b2bec3;
            border-radius: 4px;
            font-size: 13px;
          }


          .action-btn {
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  transition: transform 0.2s;
}

.approve-btn {
  background: #28a745;
  color: #fff;
  border: none;
}

.approve-btn:hover {
  background: #28a745;  /* Maintain original color */
  transform: translateY(-2px);
}

.cancel-btn {
  background: #dc3545;
  color: #fff;
  border: none;
}

.cancel-btn:hover {
  background: #dc3545;  /* Maintain original color */
  transform: translateY(-2px);
}

/* Remove the old hover comment block */
.action-btn:hover {
  transform: translateY(-2px);
  opacity: 0.9;  /* Remove this if you don't want opacity change */
}
          .college-select {
  height: 40px;
  width: 200px;
  padding: 8px;
  border: 2px solid #b2bec3;
  border-radius: 6px;
  background: #fff;  /* Changed from transparent to white */
  font-size: 12px;
  color: #2d3436;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.college-select:focus {
  border-color: #00b894;
  box-shadow: 0 0 5px rgba(0, 184, 148, 0.3);
  outline: none;
  background: #fff;  /* Added to maintain white background */
}

.college-select:hover {
  border-color: #00b894;
  background: #fff;  /* Added to maintain white background */
}
          .college-select option {
            padding: 5px;
          }
          .college-select:not(:placeholder-shown) {
            border-color: #28a745;
            // background: #f0fff0;
          }
          @media (max-width: 768px) {
            .main {
              margin-left: 0;
              padding: 15px;
            }
            .filter-grid {
              flex-direction: column;
              align-items: stretch;
            }
            .filter-box {
              max-width: 100%;
            }
            .control-bar {
              flex-direction: column;
              align-items: stretch;
            }
            .search-container {
              max-width: 100%;
              margin-bottom: 10px;
            }
            .table-box {
              overflow-x: auto;
            }
            .pagination {
              flex-wrap: wrap;
            }
          }
        `}
      </style>

      <div className="app">
        <div className="main">
          <div className="panel">
            <div className="filter-grid">
              <div className="filter-box">
                <div className="filter-label">Registration Type</div>
                <select
                  className="filter-select"
                  value={selectedMainOption}
                  onChange={handleMainOptionChange}
                >
                  <option value="">Select Type</option>
                  <option value="E-Pravesh">E-Pravesh</option>
                  <option value="Student Registration">
                    Student Registration
                  </option>
                </select>
              </div>
              <div className="filter-box">
                <div className="filter-label">Student List</div>
                <select
                  className="filter-select"
                  value={selectedStudentListOption}
                  onChange={handleStudentListOptionChange}
                >
                  <option value="">Select List</option>
                  <option value="Pending Student List">Pending</option>
                  <option value="Verified Student List">Verified</option>
                </select>
              </div>
              <div className="filter-box">
                <div className="filter-label">Course</div>
                <select
                  className="filter-select"
                  value={selectedCourse}
                  onChange={handleChangeCourse}
                >
                  <option value="">Select Course</option>
                  {associate.map((course, index) => (
                    <option key={index} value={course.courseName}>
                      {course.courseName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="filter-box">
                <div className="filter-label">Branch</div>
                <select
                  className="filter-select"
                  value={selectedBranch}
                  onChange={handleChangeBranch}
                  disabled={!selectedCourse}
                >
                  <option value="">Select Branch</option>
                  {branches.map((branch, index) => (
                    <option key={index} value={branch}>
                      {branch}
                    </option>
                  ))}
                </select>
              </div>
              <div className="filter-box">
                <div className="filter-label">Session</div>
                <select
                  className="filter-select"
                  value={selectedSession}
                  onChange={handleSessionChange}
                >
                  <option value="">Select Session</option>
                  <option value="2023-2024">2023-2024</option>
                  <option value="2024-2025">2024-2025</option>
                  <option value="2025-2026">2025-2026</option>
                </select>
              </div>
            </div>

            <div className="control-bar">
              <div className="search-container">
                <SearchIcon style={{ color: "#636e72" }} />
                <input
                  className="search-field"
                  type="text"
                  placeholder="Search name/college"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div>
                <Button
                  className="btn btn-search"
                  variant="contained"
                  style={{ fontFamily: "'Poppins', sansSerif" }}
                  onClick={handleSearch}
                  disabled={
                    !selectedBranch ||
                    !selectedMainOption ||
                    !selectedStudentListOption ||
                    !selectedSession
                  }
                >
                  Search
                </Button>
                <Button
                  className="btn btn-export"
                  variant="outlined"
                  onClick={ExportToExcel}
                  style={{
                    marginLeft: "10px",
                    fontFamily: "'Poppins', sansSerif",
                  }}
                >
                  Export
                </Button>
              </div>
            </div>

            <div className="table-box">
              {loading ? (
                <div className="empty">
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ color: "#00b894" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : !isSearched ? (
                <div className="empty">
                  Select filters and click Search to load data.
                </div>
              ) : error || approvedStudents.length === 0 ? (
                <div className="empty">No data available.</div>
              ) : selectedStudentListOption === "Pending Student List" ? (
                <TableContainer sx={{ maxHeight: "500px" }}>
                  <Table stickyHeader className="table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="th th-fixed th-sno">
                          S.No
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "100px" }}>
                          Session
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "100px" }}>
                          Type
                        </TableCell>
                        <TableCell
                          className="th th-fixed th-name"
                          onClick={() => handleSort("name")}
                        >
                          Student Name{" "}
                          {sortBy === "name" &&
                            (sortOrder === "asc" ? " ↑" : " ↓")}
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "180px" }}>
                          Email
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "100px" }}>
                          DOB
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "150px" }}>
                          Father's Name
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "150px" }}>
                          Mother's Name
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "100px" }}>
                          Contact
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "100px" }}>
                          Nationality
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "110px" }}>
                          Qualification
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "110px" }}>
                          Qualification%/CGPA
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "80px" }}>
                          Course Type
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "250px" }}>
                          Course Name
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "250px" }}>
                          Course Branch
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "250px" }}>
                          Assign College
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "200px" }}>
                          Check Marksheet
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "200px" }}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedStudents
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((student, index) => (
                          <TableRow key={index} className="tr">
                            <TableCell className="td td-fixed td-sno">
                              {page * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell className="td">
                              {student.admissionSession || "-"}
                            </TableCell>
                            <TableCell className="td">
                              {student.StudentType || "-"}
                            </TableCell>
                            <TableCell
                              className="td td-fixed td-name"
                              title={student.name || ""}
                            >
                              {student.name || "-"}
                            </TableCell>
                            <TableCell
                              className="td td-email"
                              title={student.email || ""}
                            >
                              {student.email || "-"}
                            </TableCell>
                            <TableCell className="td">
                              {student.dob || "-"}
                            </TableCell>
                            <TableCell className="td">
                              {student.fathersname || "-"}
                            </TableCell>
                            <TableCell className="td">
                              {student.mothersname || "-"}
                            </TableCell>
                            <TableCell className="td">
                              {student.mobile || "-"}
                            </TableCell>
                            <TableCell className="td">
                              {student.nationality || "-"}
                            </TableCell>
                            <TableCell className="td">
                              {student.qualification || "-"}
                            </TableCell>
                            <TableCell className="td">
                              {student.LastPercentage
                              ?`${student.LastPercentage}%`
                              : student.cgpa
                                ? `${student.cgpa}`
                                : "no upload"}
                            </TableCell>
                   

                            <TableCell className="td">
                              {student.courseType || "-"}
                            </TableCell>
                            <TableCell className="td">
                              {student.courseName || "-"}
                            </TableCell>
                            <TableCell className="td">
                              {student.courseBranch || "-"}
                            </TableCell>
                            <TableCell className="td">
                              <select
                                className="college-select"
                                value={selectedCourses[student._id] || ""}
                                onChange={(event) =>
                                  handleChangeCourse1(student._id, event)
                                }
                              >
                                <option value="">Select College</option>
                                {colleges.map((college) => (
                                  <option
                                    key={college._id}
                                    value={college.name}
                                  >
                                    {college.name}
                                  </option>
                                ))}
                              </select>
                            </TableCell>
                            <TableCell className="td">
                              {student && student.Documents ? (
                                student.Documents.marksheet12th ? (
                                  <a
                                    href={student.Documents.marksheet12th}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#00b894" }}
                                  >
                                    View 12th Marksheet
                                  </a>
                                ) : student.Documents
                                    .undergraduateCertificate ? (
                                  <a
                                    href={
                                      student.Documents.undergraduateCertificate
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#00b894" }}
                                  >
                                    View Undergraduate Marksheet
                                  </a>
                                ) : student.Documents.ItImarksheet ? (
                                  <a
                                    href={student.Documents.ItImarksheet}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#00b894" }}
                                  >
                                    View ITI Marksheet
                                  </a>
                                ) : student.Documents
                                    .postgraduateCertificate ? (
                                  <a
                                    href={
                                      student.Documents.postgraduateCertificate
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#00b894" }}
                                  >
                                    View Postgraduate Marksheet
                                  </a>
                                ) : student.Documents.marksheet10th ? (
                                  <a
                                    href={student.Documents.marksheet10th}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#00b894" }}
                                  >
                                    View 10th Marksheet
                                  </a>
                                ) : student.Documents.diplomaMarksheet ? (
                                  <a
                                    href={student.Documents.diplomaMarksheet}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#00b894" }}
                                  >
                                    View Diploma Marksheet
                                  </a>
                                ) : (
                                  "No Marksheet Available"
                                )
                              ) : (
                                "No Marksheet Available"
                              )}
                            </TableCell>
                            <TableCell
                              className="td"
                              style={{ display: "flex", gap: "10px" }}
                            >
                              <Button
                                className="action-btn approve-btn"
                                onClick={() => handleClick(student._id)}
                              >
                                Approve
                              </Button>
                              <Button
                                className="action-btn cancel-btn"
                                onClick={() => handleCancel(student._id)}
                              >
                                Cancel
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <TableContainer sx={{ maxHeight: "500px" }}>
                  <Table stickyHeader className="table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="th th-fixed th-sno">
                          S.No
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "100px" }}>
                          Session
                        </TableCell>
                        <TableCell
                          className="th th-fixed th-name"
                          onClick={() => handleSort("name")}
                        >
                          Student Name{" "}
                          {sortBy === "name" &&
                            (sortOrder === "asc" ? " ↑" : " ↓")}
                        </TableCell>
                        <TableCell
                          className="th th-fixed th-enroll"
                          style={{ minWidth: "120px" }}
                        >
                          Enrollment No.
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "100px" }}>
                          Type
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "180px" }}>
                          Email
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "100px" }}>
                          DOB
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "120px" }}>
                          Father
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "120px" }}>
                          Mother
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "100px" }}>
                          Contact
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "100px" }}>
                          Nationality
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "100px" }}>
                          Qualification
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "80px" }}>
                          %
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "100px" }}>
                          C-Type
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "120px" }}>
                          C-Name
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "120px" }}>
                          C-Branch
                        </TableCell>
                        <TableCell
                          className="th"
                          onClick={() => handleSort("assignedCollege")}
                          style={{ minWidth: "200px" }}
                        >
                          College{" "}
                          {sortBy === "assignedCollege" &&
                            (sortOrder === "asc" ? " ↑" : " ↓")}
                        </TableCell>
                        <TableCell className="th" style={{ minWidth: "80px" }}>
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedStudents
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((student, index) => (
                          <TableRow key={index} className="tr">
                            <TableCell className="td td-fixed td-sno">
                              {page * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell className="td">
                              {student.admissionSession || "-"}
                            </TableCell>
                            <TableCell
                              className="td td-fixed td-name"
                              title={student.name || ""}
                            >
                              {student.name || "-"}
                            </TableCell>
                            <TableCell className="td td-fixed td-enroll">
                              {student.enrollmentNumber || "N/A"}
                            </TableCell>
                            <TableCell className="td">
                              {student.StudentType || "-"}
                            </TableCell>
                            <TableCell
                              className="td td-email"
                              title={student.email || ""}
                            >
                              {student.email || "-"}
                            </TableCell>
                            <TableCell className="td">
                              {student.dob || "-"}
                            </TableCell>
                            <TableCell className="td">
                              {student.fathersname || "-"}
                            </TableCell>
                            <TableCell className="td">
                              {student.mothersname || "-"}
                            </TableCell>
                            <TableCell className="td">
                              {student.mobile || "-"}
                            </TableCell>
                            <TableCell className="td">
                              {student.nationality || "-"}
                            </TableCell>
                            <TableCell className="td">
                              {student.qualification || "-"}
                            </TableCell>
                            <TableCell className="td">
                              {student.LastPercentage
                                ? `${student.LastPercentage}%`
                                : "-"}
                            </TableCell>
                            <TableCell className="td">
                              {student.courseType || "-"}
                            </TableCell>
                            <TableCell className="td">
                              {student.courseName || "-"}
                            </TableCell>
                            <TableCell className="td">
                              {student.courseBranch || "-"}
                            </TableCell>
                            <TableCell
                              className="td td-college"
                              title={student.assignedCollege || ""}
                            >
                              {student.assignedCollege || "N/A"}
                            </TableCell>
                            <TableCell
                              className={`td ${
                                student?.isEnrollPaid
                                  ? "status-green"
                                  : "status-red"
                              }`}
                            >
                              {student?.isEnrollPaid ? "Yes" : "No"}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              <div className="pagination">
                <button
                  className="pagination-btn"
                  onClick={() => handleChangePage(page - 1)}
                  disabled={page === 0}
                >
                  Previous
                </button>
                <span>
                  Page {page + 1} of {totalPages}
                </span>
                <button
                  className="pagination-btn"
                  onClick={() => handleChangePage(page + 1)}
                  disabled={page >= totalPages - 1}
                >
                  Next
                </button>
                <select
                  className="pagination-select"
                  value={rowsPerPage}
                  onChange={handleChangeRowsPerPage}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HodMainDetails;
