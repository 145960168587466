import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Footerpage from "../HomePage/Footerpage";
import Nav from "../HomePage/Nav";

const images = [
  "https://cdn.sssutms.ac.in/media/static/11_science_fair/img1.jpg",
  "https://cdn.sssutms.ac.in/media/static/11_science_fair/img2.jpg",
  "https://cdn.sssutms.ac.in/media/static/11_science_fair/img3.jpg",
  "https://cdn.sssutms.ac.in/media/static/11_science_fair/img4.jpg",
  "https://cdn.sssutms.ac.in/media/static/11_science_fair/img5.jpg",
  "https://cdn.sssutms.ac.in/media/static/11_science_fair/img6.jpg",
  "https://cdn.sssutms.ac.in/media/static/11_science_fair/img7.jpg",
  "https://cdn.sssutms.ac.in/media/static/11_science_fair/img8.jpg",
  "https://cdn.sssutms.ac.in/media/static/11_science_fair/img9.jpg",
  "https://cdn.sssutms.ac.in/media/static/11_science_fair/img11.jpg",
  "https://cdn.sssutms.ac.in/media/static/11_science_fair/img12.jpg",
  "https://cdn.sssutms.ac.in/media/static/11_science_fair/img13.jpg",
  "https://cdn.sssutms.ac.in/media/static/11_science_fair/img14.jpg",
  "https://cdn.sssutms.ac.in/media/static/11_science_fair/img15.jpg",
  "https://cdn.sssutms.ac.in/media/static/11_science_fair/img16.jpg",
  "https://cdn.sssutms.ac.in/media/static/11_science_fair/img17.jpg",
  "https://cdn.sssutms.ac.in/media/static/11_science_fair/img18.jpg",
  "https://cdn.sssutms.ac.in/media/static/11_science_fair/img10.jpg",
];

const Sciencefair = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false); // For fade animation in modal

  const openModal = (index) => {
    setCurrentIndex(index);
    setFade(true); // Trigger fade animation
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setFade(false); // Reset fade on close
  };

  const handlePrev = () => {
    setFade(false); // Fade out
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
      setFade(true); // Fade in new image
    }, 200); // Match this with transition duration
  };

  const handleNext = () => {
    setFade(false); // Fade out
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
      setFade(true); // Fade in new image
    }, 200); // Match this with transition duration
  };

  return (
    <>
      <Nav />
      <h4 style={{ color: "red", textAlign: "center", marginTop: "20px", textDecoration:"underline" }}>
        11 Science Fair
      </h4>
      <br />
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <tbody>
          {images.map((src, index) =>
            index % 6 === 0 ? (
              <tr key={index}>
                {images.slice(index, index + 6).map((imgSrc, imgIndex) => (
                  <td
                    key={imgIndex}
                    style={{ padding: "10px", textAlign: "center" }}
                  >
                    <img
                      src={imgSrc}
                      alt={`Event ${index + imgIndex + 1}`}
                      style={{
                        width: "200px", // Fixed width for gallery images
                        height: "150px", // Fixed height for gallery images
                        objectFit: "cover", // Maintain aspect ratio
                        cursor: "pointer",
                        transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth hover animation
                      }}
                      onClick={() => openModal(index + imgIndex)}
                      onMouseEnter={(e) => {
                        e.target.style.transform = "scale(1.05)"; // Slight zoom on hover
                        e.target.style.boxShadow = "0 4px 12px rgba(0,0,0,0.2)"; // Shadow on hover
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.transform = "scale(1)"; // Back to normal
                        e.target.style.boxShadow = "none"; // Remove shadow
                      }}
                    />
                  </td>
                ))}
              </tr>
            ) : null
          )}
        </tbody>
      </table>

      <Modal show={showModal} onHide={closeModal} centered size="md">
        <Modal.Body style={{ position: "relative", padding: 0 }}>
          <img
            src={images[currentIndex]}
            alt={`Event ${currentIndex + 1}`}
            style={{
              width: "500px", // Fixed width for modal image
              height: "400px", // Fixed height for modal image
              objectFit: "cover", // Maintain aspect ratio
              opacity: fade ? 1 : 0, // Fade effect
              transition: "opacity 0.2s ease-in-out", // Smooth fade animation
            }}
          />
          <Button
            style={{
              position: "absolute",
              top: "50%",
              padding: "10px",
              left: "-60px", // Position outside the modal
              color: "#FFAF00",
              backgroundColor: "black",
              border: "none",
              fontSize: "24px",
              fontWeight: "bolder",
              transform: "translateY(-50%)",
              opacity: 0.8,
              transition: "transform 0.2s ease", // Button animation
            }}
            onClick={handlePrev}
            onMouseEnter={(e) => (e.target.style.transform = "translateY(-50%) scale(1.1)")}
            onMouseLeave={(e) => (e.target.style.transform = "translateY(-50%) scale(1)")}
          >
            {"<"}
          </Button>
          <Button
            style={{
              position: "absolute",
              top: "50%",
              padding: "10px",
              right: "-60px", // Position outside the modal
              color: "#FFAF00",
              backgroundColor: "black",
              border: "none",
              fontSize: "24px",
              fontWeight: "bolder",
              transform: "translateY(-50%)",
              opacity: 0.8,
              transition: "transform 0.2s ease", // Button animation
            }}
            onClick={handleNext}
            onMouseEnter={(e) => (e.target.style.transform = "translateY(-50%) scale(1.1)")}
            onMouseLeave={(e) => (e.target.style.transform = "translateY(-50%) scale(1)")}
          >
            {">"}
          </Button>
          <Button
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              height: "40px",
              width: "40px",
              opacity: 0.8,
              backgroundColor: "black",
              border: "none",
              color: "#FABC3F",
              padding: "10px",
              fontSize: "15px",
              transition: "transform 0.2s ease", // Close button animation
            }}
            onClick={closeModal}
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
          >
            X
          </Button>
        </Modal.Body>
      </Modal>

      <Footerpage />
    </>
  );
};

export default Sciencefair;